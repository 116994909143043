/** import Angular Component, OnInit */
import { Component, OnInit } from '@angular/core';
/**
 * IntroductionComponent
 */
@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit {
  /**
   * slides
   * Declaración del Arreglo slide
   */
  slides = [
    {id: 1,tittle:'Un buen principi para tu salud.', txt: 'Potencia tus habilidades'},
  ];

  /** constructor*/
  constructor() { }

  /** ngOnInit*/
  ngOnInit() {
  }

}
