/** Angular Core */
import { Injectable, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { tap } from 'rxjs';

/** INTERFACES */
import { IModalOptions } from 'src/app/interfaces/modal.options';
import { ModalGeneralComponent } from 'src/app/shared/modal-general/modal-general.component';

/**
 * Servicio de modal genérico
 *
 * @export
 * @class ModalService
 */
@Injectable({ providedIn: 'root' })
export class ModalService {
  /** Toma de referencia el modal activo */
  reference: BsModalRef;

  /**
   * Crea una instancia de ModalService.
   * @param {NgbModal} modal
   * @memberof ModalService
   */
  constructor(private modal: BsModalService) { }

  /**
   * Lanzar modal con template de componente
   * @param {object} component
   * @param options
   */
  open(component: TemplateRef<any>, options: IModalOptions = {}, center: boolean = false): BsModalRef {
    options.centered = !center;
    this.reference = this.modal.show(component, options);
    return this.reference;
  }

  /**
   * Mostrar modal genérico para errores
   * @param {string} message
   * @param {string} [title]
   * @returns
   * @memberof ModalService
   */
  errorModal(message: string, title?: string) {
    this.reference = this.modal.show(ModalGeneralComponent, {
      keyboard: false, backdrop: 'static', class: 'modal-dialog-centered', initialState: {
        modalIcon: 'assets/img/modal/ambulancia_error.png',
        modalTitle: title || '¡Ups! Algo ha salido mal, intente más tarde',
        modalMessage: message || '',
        button: new EventEmitter<boolean>().subscribe(() => this.reference.hide())
      }
    });
    return this.reference;
  }

  isHide(): boolean {
    return this.reference !== undefined && this.reference !== null;
  }

  /**
   * Mostrar modal genérico para éxito
   * @param {string} message
   * @param {string} [title]
   * @returns
   * @memberof ModalService
   */
  successModal(message: string) {
    this.reference = this.modal.show(ModalGeneralComponent, {
      keyboard: false, backdrop: 'static', class: 'modal-dialog-centered', initialState: {
        modalIcon: 'assets/img/modal/mano_exito.png',
        modalMessage: message,
        button: new EventEmitter<boolean>().subscribe(() => this.reference.hide())
      }
    });
    return this.reference;
  }

  /**
   * Cerrar modal dentro del componente activo
   */
  close() {
    this.reference.hide();
  }
}
