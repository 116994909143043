<div class="general-footer d-none d-md-block">
    <div class="first-section-color">
        <div class="container">
            <div class="row d-flex align-items-center first-section-size">
               <div class="col-4">
                    <img src="assets/images/AK_blanco_.png" class="img-logo-axa" alt="">
               </div>
               <div class="col-4 text-center">
                   <span class="text-footer"><a href="https://axakeralty.mx/cancellation-policy" style="color: #FFFFFF;font-size: 12px;font-weight: 600;">Política de cancelación</a></span>
                </div>
                
                <div class="col-4 d-flex justify-content-end align-items-center">
                    <a href="https://www.instagram.com/axa.keralty/" style="text-decoration: none;"><span class="ak-icon icon-instagram  icon-social"></span></a>
                    <a href="https://www.facebook.com/axakeralty/" style="text-decoration: none;"><span class="ak-icon icon-facebook icon-social"></span></a>
                    <a href="https://www.youtube.com/channel/UC9mLJLdrpG1jQ0ng0X_LMjQ" style="text-decoration: none;"><span class="ak-icon icon-youtube icon-social"></span></a>
                    <a href="https://www.linkedin.com/company/axa-keralty" style="text-decoration: none;"><span class="ak-icon icon-LinkedIn icon-social" style="font-size: 15px;margin-left: 12px;"></span></a>
                 </div>

            </div>
        </div>
    </div>
    <div class="second-section-color">
       <div class="container">
        <div class="row d-flex align-items-center first-section-size">
            <div class="col-4">
                    <span class="text-footer"><a href="https://axakeralty.mx/politicas-de-uso"  style="color: #FFFFFF;font-size: 12px;font-weight: 600;">Condiciones generales</a></span>
            </div>
            <div class="col-4 text-center">
                <span class="ak-icon icon-ambulance-footer"></span>
             </div>

             <div class="col-4 d-flex justify-content-end align-items-center">
                <span class="text-footer">AXA Keralty {{year}}©</span>
             </div>
             
         </div>
       </div>
    </div>
</div>


<div class="general-footer d-block d-md-none">
    <div class="first-section-color">
        <div class="container">
            <div class="row d-block align-items-center first-section-size">
                <div class="col-12 text-center p-4">
                    <img src="assets/images/AK_blanco_.png" class="img-logo-axa" alt="">
                </div>
                <div class="col-12 text-center justify-content-end align-items-center p-4">
                    <a href="https://www.instagram.com/axa.keralty/" style="text-decoration: none;"><span class="ak-icon icon-instagram  icon-social m-3"></span></a>
                    <a href="https://www.facebook.com/axakeralty/" style="text-decoration: none;"><span class="ak-icon icon-facebook icon-social m-3" ></span></a>
                    <a href="https://www.youtube.com/channel/UC9mLJLdrpG1jQ0ng0X_LMjQ" style="text-decoration: none;"><span class="ak-icon icon-youtube icon-social m-3"></span></a>
                    <a href="https://www.linkedin.com/company/axa-keralty" style="text-decoration: none;"><span class="ak-icon icon-LinkedIn icon-social" style="font-size: 29px;margin-left: 17px;"></span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="second-section-color">
       <div class="container">
        <div class="row d-flex align-items-center first-section-size">
            <div class="col-12 text-center">
                <span class="text-footer"> <a href="https://axakeralty.mx/cancellation-policy">Política de cancelación</a></span>
            </div>
            <div class="col-12 text-center">
                <span class="text-footer"> <a href="https://axakeralty.mx/politicas-de-uso">Condiciones generales</a></span>
            </div>
            <div class="col-12 text-center">
                <span class="text-footer axa-year">AXA Keralty {{year}}©</span>
            </div>
            <div class="col-12 text-center">
                <span class="ak-icon icon-ambulance-footer" style="color: #FFFFFF;"></span>
             </div>
             
         </div>
       </div>
    </div>
</div>
