<div class="general-content">
    <div class="container-full" style="background-color: #DDEAEA;">
        <div class="row py-5">
            <div class="col-12 col-lg-8 m-auto">
                <div class="row m-0">
                    <div class="col-2 px-0 d-flex justify-content-center">
                        <div class="circle-number circle-1 text-center d-flex justify-content-center align-items-center">
                            1
                        </div>
                        <div class="custom-line"></div>
                    </div>
                    <div class="col-10 col-md-6 px-0">
                        <div class="justify-content-center align-items-center mx-auto my-auto" style="margin-left: auto; margin-right: auto;">
                            <div class="col-12 mb-4text" style="margin-left: auto; margin-right: auto; max-width: 500px;">
                                <span style="color: #177172;"><strong>Tokeniza tu tarjeta</strong></span>
                                <br>
                                <span style="color: #177172;" class="text-description">Captura los datos de la tarjeta a la que deseas que realicemos los cargos automatico</span>
                            </div>
                            <div class="col-12 my-4 d-flex d-md-none m-auto">
                                <img src="../../../assets/img/token/tokeniza.png" class="img-option-1 m-auto" alt="">
                            </div>
                            <div class="col-12 mt-2" style="margin-left: auto; margin-right: auto; max-width: 500px;">
                                <div class="principal-header__button-act-logo_first m-auto m-md-0" (click)="goTokenizer()"> Tokenizar </div>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-4 text-center justify-content-center d-none d-md-block">
                        <img src="../../../assets/img/token/tokeniza.png" class="img-option-1" alt="">
                    </div>
                </div>
              
            </div>
        </div>
    </div>
    <div class="container-full" style="background-color: #E6EBF0;">
        <div class="row py-5">
            <div class="col-12 col-lg-8 m-auto">
                <div class="row m-0">
                    <div class="col-2 col-md-2 px-0 d-flex justify-content-center">
                        <div class="circle-number-2 text-center d-flex justify-content-center align-items-center">
                            2
                        </div>
                        <div class="custom-line"></div>
                    </div>
                    <div class="col-10 col-md-6 px-0">
                        <div class="justify-content-center align-items-center mx-auto my-auto" style="margin-left: auto; margin-right: auto;">
                            <div class="col-12 mb-4" style="margin-left: auto; margin-right: auto; max-width: 500px;">
                                <span style="color: #164074;"><strong>Descarga y llena el formato de autorización</strong></span>
                                <br>
                                <span style="color: #164074;" class="text-description">Descarga la autorización de pago domiciliado y llénala con tus datos y el token que generaste en el paso 1.</span>
                            </div>
                            <div class="col-12 mt-4 d-flex d-md-none">
                                <img src="../../../assets/img/token/Descarga.png" class="img-option-2 m-auto" alt="">
                            </div>
                            <div class="col-12 mt-2" style="margin-left: auto; margin-right: auto; max-width: 500px;">
                                <span class="principal-header__button-act-logo_second m-auto m-md-0" (click)="downloadFormat()"> Descargar formato </span>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-4 text-center justify-content-center d-none d-md-block">
                        <img src="../../../assets/img/token/Descarga.png" class="img-option-2" alt="">
                    </div>
                </div>
            </div>
           
        </div>
    </div>
    <div class="container-full" style="background-color: #E9F1E5;">
        <div class="row py-5">
            <div class="col-12 col-lg-8 m-auto">
                <div class="row m-0">
                    <div class="col-2 col-md-2 px-0 d-flex justify-content-center">
                        <div class="circle-number circle-3 text-center d-flex justify-content-center align-items-center">
                            3
                        </div>.
                        <img src="../../../assets/img/token/validar_.png" class="img-tree d-none d-lg-block" alt="" *ngIf="fileDocIdFormBoolean && fileDocReqFormBoolean">
                        <img src="../../../assets/img/token/error_.png" class="img-tree d-none d-lg-block" alt="" *ngIf="errorReq === true || errorId === true">
                        <img src="../../../assets/img/token/validar_.png" class="img-tree-mobile d-block d-lg-none" alt="" *ngIf="fileDocIdFormBoolean && fileDocReqFormBoolean">
                        <img src="../../../assets/img/token/error_.png" class="img-tree-mobile d-block d-lg-none" alt="" *ngIf="errorReq === true || errorId === true">
                        <div class="custom-line"></div>
                    </div>
                    <div class="col-10 col-md-6 px-0">
                        <div class="justify-content-center align-items-center mx-auto my-auto" style="margin-left: auto; margin-right: auto;">
                            <div class="col-12 mt-2 mb-4" style="margin-left: auto; margin-right: auto; max-width: 500px;">
                                <span style="color: #417322;"><strong>Adjunta los siguientes documentos</strong></span>
                                <p style="color: #417322;" class="text-description">
                                    - Autorización de pago domiciliado, es el documento que descargaste en el paso 2 y que llenaste con tus datos y token
                                       
                                </p>
                                <p style="color: #417322;" class="text-description">
                                    - Identificación oficial (INE o licencia de conducir) 
                                </p>
                                <p  style="color: #0F713F; font-style: italic;" class="text-description">
                                    - Los archivos no deben superar los 5MB, pueden ser en formato JPG, PNG o PDF
                                </p>
                            </div>
                            <div class="col-12 mt-4 d-flex d-md-none">
                                <img src="../../../assets/img/token/Adjunta.png" class="img-option-3 m-auto" alt="">
                            </div>
                            <div class="col-12 mt-2" style="margin-left: auto; margin-right: auto; max-width: 500px;">
                                <div class="row">
                                    <div class="col-12 col-lg-6 text-center mb-4 mb-lg-0">
                                       <div>
                                        <input #fileInputDocReq hidden type="file" accept="application/pdf,.jpg,.png" (change)="selectedFileReq($event, 'fileInputDocReq')" />
                                        <span class="principal-header__button-act-logo_third m-auto m-md-0" (click)="uploadDocReq()" [ngClass]="errorReq === true ? 'error-button' : ''"> Adjuntar solicitud </span>
                                        <div *ngIf="fileDocReqFormBoolean === true" class="content-check d-flex justify-content-center align-items-center">
                                            <em class="axa-icon axa-check"></em>
                                        </div>
                                       </div>
                                    </div>
                                    <div class="col-12 col-lg-6 text-center " >
                                       <div >
                                        <input #fileInputDocId hidden type="file" accept="application/pdf,.jpg,.png" (change)="selectedFileId($event, 'fileInputDocId')" />
                                        <span class="principal-header__button-act-logo_third m-auto m-md-0" (click)="uploadDocId()" [ngClass]="errorId === true ? 'error-button' : ''"> Adjuntar identificación </span>
                                        <div *ngIf="fileDocIdFormBoolean === true" class="content-check-second d-flex justify-content-center align-items-center">
                                            <em class="axa-icon axa-check"></em>
                                        </div>
                                       </div>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <p class="text-error" *ngIf="errorReq === true && errorId === true">
                                            <img src="../../../assets/img/token/danger_.png" class="img-warning" alt=""> Debes adjuntar los documentos solicitados para poder enviar tu solicitud de cambio de tarjeta.
                                        </p>
                                        <p class="text-error" *ngIf="errorId === true && errorReq === false">
                                            <img src="../../../assets/img/token/danger_.png" class="img-warning" alt=""> Debes adjuntar tu identificación para poder enviar tu solicitud de cambio de tarjeta.
                                        </p>
                                        <p class="text-error" *ngIf="errorReq === true && errorId === false">
                                            <img src="../../../assets/img/token/danger_.png" class="img-warning" alt=""> Debes adjuntar los documentos solicitados para poder enviarla.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 justify-content-center text-center d-none d-md-block">
                        <img class="img-responsive" src="../../../assets/img/token/Adjunta.png" class="img-option-3" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-full">
        <div class="row pt-5">
            <div class="col-12 col-lg-8 m-auto">
                <div class="row m-0">
                    <div class="col-2 col-md-2 px-0 d-flex justify-content-center">
                        <div class="circle-number circle-4 text-center d-flex justify-content-center align-items-center">
                            4
                        </div>
                    </div>
                    <div class="col-10 col-md-6 px-0">
                        <div class="justify-content-center align-items-center mx-auto my-auto" style="margin-left: auto; margin-right: auto;">
                            <div class="col-12 mt-2 mb-4" style="margin-left: auto; margin-right: auto; max-width: 500px;">
                                <span style="color: #597A8B;"><strong>Envía tu solicitud de cambio de token</strong></span>
                                <br>
                                <span style="color: #4F9AAF;" class="text-description">En breve enviaremos la confirmación a tu correo electrónico.</span>
                            </div>
                            <div class="col-12 mt-2 d-flex" style="margin-left: auto; margin-right: auto; max-width: 500px;">
                               <button class="d-flex custom-button m-auto m-md-0"  (click)="fileDocIdFormBoolean && fileDocReqFormBoolean ? sendInfo() : showError()" [ngClass]="!fileDocIdFormBoolean || !fileDocReqFormBoolean ? 'disabled-button disabled-send': ''">
                                <div class="send-button d-flex justify-content-center align-items-center" [ngClass]="!fileDocIdFormBoolean || !fileDocReqFormBoolean ? 'disabled-send': ''">    
                                    <img class="img-responsive" src="../../../assets/img/token/send_.png" style="height: 25px; width: 25px;" alt="">
                                </div>
                                <div class="principal-header__button-act-logo_four d-flex align-items-center" [ngClass]="!fileDocIdFormBoolean || !fileDocReqFormBoolean ? 'disabled-last': ''">Enviar solicitud </div>
                               </button>
                            </div>
                        
                        </div>
                    </div>
                    <div class="col-4 text-left justify-content-center d-none d-md-block">
                        <img src="../../../assets/img/token/Enviar_.png" style="height: 300px;" alt="">
                    </div>
                </div>
            </div>
            <div class="col-12 mt-4 d-block d-md-none">
                <img src="../../../assets/img/token/Enviar_.png" style=" width: 90%;" alt="">
            </div>
        </div>
    </div>
</div>