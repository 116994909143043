import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

/** SERVICIOS */
import {LoaderService} from './../../services/loader/loader.service';

/**
 * Componente "Loader"
 *
 * @export
 * @class LoaderComponent
 * @implements {OnInit}
 */
@Component({selector: 'app-loader', templateUrl: './loader.component.html', styleUrls: ['./loader.component.scss']})
export class LoaderComponent implements OnInit {
  /** Mostrar/Ocultar el loader */
  loader: boolean = true;
  /** Suscripción para obtener el estatus del cargador */
  loaderSubs: Subscription;

  /**
   * Crea una instancia de LoaderComponent
   */
  constructor(private loaderService: LoaderService) {}

  /**
   * Un hook de ciclo de vida que se llama después de que Angular haya
   * inicializado todas las propiedades vinculadas a datos de una directiva
   *
   * @memberof MedPassComponent
   */
  ngOnInit() {
    this.statusLoader();
  }

  /**
   * Obtener el valor asignado al cargador para mostrar/ocultar
   */
  statusLoader() {
    this.loaderSubs = this.loaderService.status.subscribe(status => {
      this.loader = status;
    });
  }

  /**
   * Destruir suscripción o valores asignados
   */
  ngOnDestroy() {
    this.loaderSubs.unsubscribe();
  }
}
