<div class="container">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="searchForm" (submit)="findPolicy()" id="policy-form">
        <p class="title d-none d-md-block">Consulta de saldo pendiente</p>
        <label>Ingresa tu número de póliza para consultar tu saldo</label>
        <input 
          type="text" 
          class="form-control"
          [class]="{error: policyNotfound && searchForm.controls['policy'].value.length}" 
          formControlName="policy"
          placeholder="Número de poliza" />

        <div class="col-12 warning-accept-terms pl-0 mt-5" *ngIf="policyNotfound">
          <div class="external-circle">
            <div class="internal-circle">
              <img src="assets/searchpolicy/danger.svg" alt="">
            </div>
          </div>
          El número de poliza que ingresaste no existe, verifícalo y vuelve a intentarlo
        </div>

        <p class="mt-5 button-wrapper">
          <button type="submit" [disabled]="searchForm.invalid"class="btn">Consultar</button>
        </p>
      </form>

    </div>
  </div>
</div>