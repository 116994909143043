import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
/** import LoaderService*/
import { LoaderService } from "src/app/sharedv2/loader/loader.service";
import { RenovationPolicyService } from "src/app/services/renovation-policy/renovationPolicy.service";
import { DocumentTypeService } from "src/app/services/document-type/documentType.service";
import { ModalService } from "src/app/services/modal/modal.service-v2";
// INTERFACES
import { IPolicyAgreement, IPolicyContractingData, IPolicyResponse } from "src/app/interfaces";
// PIPES
import { DocumentTypePipe } from "src/app/pipes";

@Component({
  selector: "app-payment-success",
  templateUrl: "./payment-success.component.html",
  styleUrls: ["./payment-success.component.scss"],
})
export class PaymentSuccessComponent implements OnInit {
  policy: string;
  folio: string;
  amount: number;
  identificationNuber: string;
  identificationType: number;
  fullName: string;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private renovationPolicyService: RenovationPolicyService,
    private documentTypeService: DocumentTypeService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      this.policy = params.external_reference;
      this.folio = params.collection_id;

      this.findPolicyStatus();
    });
  }

  async findPolicyStatus() {
    this.loaderService.start();
    this.renovationPolicyService.getPolicyStatus(this.policy).subscribe(
      (response: any) => {
        if (response.data.saldoAlaFecha) {
          this.amount = Number(response.data.saldoAlaFecha);
          this.findPolicy();
        } else {
          this.loaderService.stop();
        }
      },
      (error) => {
        this.loaderService.stop();
        this.modalService.closeBehavior.subscribe((response) => {
          if (response) {
            this.returnToHome(null);
          }
        });
        this.modalService.errorModal(
          "Hubo un error al recuperar los datos de su póliza"
        );
      }
    );
  }

  findPolicy() {
    
    this.renovationPolicyService.getFullInfoByContract(this.policy).subscribe(
      (response: IPolicyResponse) => {
        // Validar respuesta de las pólizas del usuario
        if (response.axaHeaderRes.resultado.subcodigo === '200') {
          // Obtener número de póliza
          const policyNumber: string = this.policy.replace(/[^0-9]+/g, "");
          // Búsqueda de póliza
          const policy: IPolicyAgreement = response.data.find(
            (agreement: IPolicyAgreement) => policyNumber === String(agreement.datosContratoDTO.contUsr).substring(4)
          );
          const contractor: IPolicyContractingData = policy.datosContratoDTO.datosContratante;
          // Búsqueda de tipo contrato
          const contractorIdentification = this.documentTypeService.documents.find(
            (type: any) => type.shortName === contractor.tipoIdentificacion
          );
          this.identificationType = contractorIdentification.idBH;
          this.identificationNuber =  policy.datosContratoDTO.datosContratante.numIdentificacion;
          // Nombre de contratante
          const {primerNombre, segundoNombre, apellidoPaterno, apellidoMaterno} = contractor;
          // Nombre completo
          this.fullName =
            primerNombre + " " +
            (segundoNombre ? segundoNombre + " " : "") +
            (apellidoPaterno ? apellidoPaterno + " " : "") +
            (apellidoMaterno ? apellidoMaterno + " " : "");

          this.validatePayment();
        } else {
          this.loaderService.stop();
          this.modalService.closeBehavior.subscribe((response) => {
            if (response) {
              this.returnToHome(null);
            }
          });
          this.modalService.errorModal(
            "Hubo un error al recuperar los datos de su póliza"
          );
        }
      },
      (error) => {
        this.loaderService.stop();
        this.modalService.closeBehavior.subscribe((response) => {
          if (response) {
            this.returnToHome(null);
          }
        });
        this.modalService.errorModal(
          "Hubo un error al recuperar los datos de su póliza"
        );
      }
    );
  }

  async validatePayment() {
    this.renovationPolicyService
      .validatePayment(
        this.policy,
        this.identificationNuber,
        this.identificationType,
        this.fullName,
        this.amount,
        this.folio
      )
      .subscribe(
        (response) => {
          this.loaderService.stop();
        },
        (response) => {
          this.loaderService.stop();
          this.modalService.closeBehavior.subscribe((response) => {
            if (response) {
              this.returnToHome(null);
            }
          });
          this.modalService.errorModal(
            response.error.axaHeaderRes.resultado.mensaje,
            "Hubo un error al recuperar los datos de su póliza"
          );
        }
      );
  }

  returnToHome(event) {
    this.router.navigate(["/home"]);
  }
}
