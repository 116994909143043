<div class="chatwindow" *ngIf="band">

    <div class="fadeClose" [ngStyle]="{ display: fadeOf === false ? '' : 'none' }">
        <div class="icono_lupita">
            <img src="assets/icons/Lupita_Menu.png" alt="" />
        </div>
        <div class="icono">
            <img src="assets/icons/Salir_.png" alt="" />
        </div>
        <div class="warning-text">
            <div class="salirText">
                <strong>¿Estás seguro</strong>
                <p> de querer terminar la conversación?</p>
            </div>

        </div>
        <div class="button-option" (click)="endConv()">
            <a>Sí</a>
        </div>
        <div class="button-option" (click)="cancell()">
            <a>No</a>
        </div>
    </div>

    <div class="menu" [ngStyle]="{ display: menu === true ? '' : 'none' }">
        <div class="close_button" (click)="closeMenu()">
            <img class="mr-2 mt-2" src="assets/icons/cerrarMenu.png" alt="Cerrar"/>
        </div>
        <div class="close_button" (click)="minimize()">
            <img class="mr-2 mt-2" src="assets/icons/minimizarMenu.png" alt="Minimizar"/>
        </div>
        <div class="icono">
            <img src="assets/icons/Lupita_Menu.png" alt="Menú" />
        </div>
        <div class="warning-text">
            <p class="hola-soy-lupita mb-1"><strong>¡Hola! Soy Lupita,</strong></p>
            <p class="tu-asistente-virtual">tu asistente virtual.</p>
            <p class="hola-soy-lupita"><strong>¿Cómo puedo ayudarte?</strong></p>
        </div>
        <div class="buttonTest">
            <p>Quiero realizar el <em>test de COVID-19</em></p>
            <div class="button-option" (click)="convOption()">
                <a>Ir al test</a>
            </div>
        </div>
        <hr class="hrButton">
        <div class="buttonOpc">
            <p>Quiero información de prevención ante el Coronavirus <em>COVID-19</em></p>
            <div class="button-option" (click)="info()">
                <a>Más información</a>
            </div>
        </div>

    </div>

    <div class="chatheader">
        <div class="close_button" (click)="closeTry()">
            <img class="mr-2 mt-2" src="assets/icons/cerrar.png" alt="Cerrar"/>
        </div>
        <div class="close_button" (click)="minimize()">
            <img class="mr-2 mt-2" src="assets/icons/minimizar.png" alt="Minimizar"/>
        </div>
        <div class="axa_logo">
            <div class="imgLupita"><img src="assets/icons/Lupita4_CU.png" alt="Lupita" /></div>
            <div class="textLupita">
                <p><em>Lupita</em>, tu asistente virtual</p>
            </div>


        </div>
    </div>
    <div class="contenedor">
        <div #scrollMe [scrollTop]="scrollMe.scrollHeight" id="scrollMe" class="messages-cont align-items-center pt-2">
            <ng-container *ngFor="let message of history">
                <div class="contain-box mb-2" [ngClass]="message.sentBy === 'user' ? 'right' : 'align-left'">
                    <div *ngIf="message.sentBy === 'bot'" class="decoratorL"><img src="\assets\icons\CornerBeforeLeft.png" alt=""></div>
                    <div class="message" [ngClass]="{ from: message.sentBy === 'bot', to: message.sentBy === 'user' }" [innerHTML]="message.content"></div>
                    <div *ngIf="message.sentBy === 'user'" class="decoratorR"><img src="\assets\icons\CornerBeforeRight.png" alt=""></div>
                </div>
            </ng-container>
        </div>
        <div class="typing-box ng-star-inserted" *ngIf="typing">
            <img src="assets/typing3m.gif" alt=""/>
        </div>
        <hr class="section_sep" />
        <div class="replySection">
            <form autocomplete="off">
                <input #text type="text" name="formValue" [(ngModel)]="formValue" name="reply" class="reply" placeholder="Escribe aquí tu mensaje…" [autofocus]="true" [disabled]="typing || end" />
                <button (click)="sendMessage()" class="send">
                <img src="assets/icons/send.png" alt="" />
                </button>
            </form>
        </div>
    </div>

</div>
<div class="open_window">
    <a (click)="toggle()">
        <img *ngIf="!band && !minimized" class="window_image d-none d-sm-block" src="assets/icons/Lupita_V4.png" alt=""/>
        <img *ngIf="!band && !minimized" class="lupita-mobile d-block d-sm-none" src="assets/icons/Burbuja_mobilev4.png" alt=""/>
        <img *ngIf="band" class="window_image2 d-none d-sm-block" src="assets/icons/minimizarGreen.png" alt=""/>
        <img *ngIf="band" class="window_image2 d-block d-sm-none" src="assets/icons/minimizarGreen.png" alt=""/>
        <img *ngIf="minimized" class="window_image3 d-none d-sm-block" src="assets/icons/Lupita4_Notification.png" alt=""/>
        <img *ngIf="minimized" class="lupita-mobile d-block d-sm-none" src="assets/icons/Burbuja_mobilev4.png" alt=""/>
    </a>
</div>