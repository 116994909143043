import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// OPERATORS
import { map, concatMap } from 'rxjs/operators';
import { CipherService } from '../cipher/cipher.service';
import {TokenService}  from '../token/token.service';


@Injectable({
  providedIn: 'root'
})
export class RenovationPolicyService {

	url: string = `${window.location.protocol}//${window.location.host}/`;

	currentUserValue = {
		name: 'portal-keralty',
		user: 'portal-keralty',
		mx: '111111',
		email: 'hola@axakeralty.mx',
		rol: 'portal-keralty',
		sid: '0000000000000111111'
	};

    constructor(private http: HttpClient, private cipher: CipherService, private token: TokenService) {}

    getPolicyStatus<Any>(numPolicy : string){
      let data = {
        "axaHeaderReq": {
        "ramo": "SALUD",
        "medio": "Internet",
        "segmento": "O",
        "rol": "Agente",
        "usuario": "MXS00101826A",
        "sistemaId": "SALUD",
        "version": "1.0",
        "UUID": "f1a9f044-0a60-0030-0750-0a95f5580115",
        "fechaHora": "2021-06-28T13:49:56"
        },
        "data": {
          "numContrato": numPolicy
        }
      };

      return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/account-status/getAccountStatus',data)
      .pipe(
        map((response: Any) => {
          return response;
        })
      );
    }

    /**
	 * Realiza consulta de coincidencias con BH para obtener usuarios por contrato (version completa)
	 * @param data
	 * @param searchType
	 */
	getFullInfoByContract(dataInfo: string) {
        let body = {
			axaHeaderReq: {
				ramo: 'Salud',
				medio: 'string',
				segmento: 'Salud',
				rol: 'string',
				usuario: 'MXI00000123A',
				sistemaId: 'string',
				version: 'string',
				uuid: 'f1a9f044-0a60-0030-0750-0a95f5580114',
				fechaHora: new Date()
			},
			data: {
				numContrato: dataInfo,
				codigoPlan: dataInfo.substring(2,5),
				tipoConsulta:"renovacion",
				origen: "PAGO_SERVICIOS"
			}
        };

        // return body;

		return this.token.getAuthorize()
    .pipe(
      map(token => 
        new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token.access_token}`,
          'x-channel': 'home-page'
          
        })),
      concatMap(headers => this.http.post( "https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/validador-derechos/datosBeneficiarios"  , body, { headers }).pipe(
		map((res: any) => {
			if (res) {
				return res;
			}
			return false;
		})
	))
    )
	
	
      /* return this.http.post( "https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/validador-derechos/datosBeneficiarios"  , body, { headers }).pipe(
			map((res: any) => {
				if (res) {
					return res;
				}
				return false;
			})
      ); */
	
  	}

	async getMercadoPagoReference(data){
		let sim_key = this.cipher.cr_UUID();
    
		let encr_body = await this.cipher.encrBody(data, sim_key);

		let encr_key = await this.cipher.setEnc(sim_key);

		const headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'X-Key-Sim': `${btoa(encr_key)}`
		});

		return await this.http.post(`https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/pago/pago-servicios-policy`,{ data: encr_body }, { headers }).pipe(
			map((response: any) => {
			  let JSONResponse = JSON.parse(atob(response.data));
			  return JSONResponse;
			})
		).toPromise();
	}

	validatePayment(policy: string,identificationNumber: string,identificationType: number, fullName: string, amount: number, voucherNumber: string){
		let data = {​​​​​​​​
			"data": {​​​​​​​​
				"product": "AS",
				"paymentOrigin": "9",
				//"applicationNumber": "15589",
				"contractNumber": policy,
				"voucherNumber": voucherNumber,
				"paymentDate": new Date(),
				"amount": amount,
				"paymentMethod": {​​​​​​​​
					"code": "1",
					"bankingEntityCode": ""
				}​​​​​​​​,
				"payer": {​​​​​​​​
					"identificationNumber": identificationNumber,
					"identificationType": identificationType,
					"fullName": fullName,
				}​​​​​​​​,
				"familyNumber": "",
				"applicationCode": ""
			}​​​​​​​​
		}​​​​​​​

		return this.http.post( "https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/issuance/aplicar-pago"  , data, /*{ headers }/**/).pipe(
			map((res: any) => {
				if (res) {
					return res;
				}
				return false;
			})
        );
	}


}