/** import Angular Component, OnInit*/
import { Component, OnInit, Inject } from '@angular/core';
/** import LoaderService*/
import { LoaderService } from '../../services/loader/loader.service';
/** import Angular Meta*/
import { Meta } from '@angular/platform-browser';
/** import Angular Router*/
import { Router } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
/**
 * AppComponent
 */
@Component({
  selector: 'app-root',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  /** Variable title*/
  title = 'axa-keralty2';
  rutaActiva= '';
  showNewFooter = false;

  /**
   * Constructor
   * @param loaderService //LoaderService
   * @param meta //Meta
   * @param router //Router
   */
  constructor(
    private loaderService: LoaderService,
    private meta: Meta,
    @Inject(DOCUMENT)
    private document: any,
    private scrollService: PageScrollService,
    public router:Router,
    ) {
      this.meta.addTags([
        { name: 'description', content: 'El nuevo sistema integral para el cuidado de tu salud donde por un costo accesible tendrás acceso a atención médica de calidad y programas de salud hechos para ti' },
        { name: 'author', content: 'AXA Keralty' }
      ]);
  }

  /**
   * ngOnInit
   * Inicializacion loader
   */
  ngOnInit() {
    if (typeof window.localStorage !== 'undefined') {
      let value =  localStorage.getItem('showMap');
      console.log(value);
     if (value === 'true') {
       this.scrollService.scroll({
         document: this.document,
         scrollTarget: '#map-redirection',
       }); 
        
     } 
       }
    this.router.events.subscribe((reponse: any) => {
      this.rutaActiva = reponse.url !== undefined ? reponse.url : this.rutaActiva;
      const activeRoute = this.rutaActiva.split('/');
      // if (this.rutaActiva === '/') {
      //   window.location.reload();
      // }
      if (this.rutaActiva === '/servicios' || this.rutaActiva === '/infografia' ||  this.rutaActiva === '/servicios-pago' ||  this.rutaActiva === '/servicios-checkout' ||  this.rutaActiva === '/servicios-exito' ||  this.rutaActiva === '/consulta-terminos-y-condiciones' ) {
        this.showNewFooter = true;
      } else {
        this.showNewFooter = false;
      }
    });
    this.loaderService.star()
    setTimeout(() => {
      this.loaderService.stop();
    }, 1500);
  
  }

}
