import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable} from 'rxjs';
import { IUserData} from '../interfaces/data-user/data-user.interface';
// OPERATORS
import { map } from 'rxjs/operators';

/**
 * Servicio encargado de la estado del usuario activo
 * @export
 * @class ConsultService
 */
@Injectable({
    providedIn: 'root'
  })
  export class ConsultService {

    /** Información del usuario */
    public userInfo = new BehaviorSubject<IUserData>(null);
      /** Información del tipo de usuario */
      public typeService = new BehaviorSubject<any>('');
      public stateService = new BehaviorSubject<string>('');
         /** BANDERA PARA MOSTRAR CAM YIJUANA */
         public showTijuana = new BehaviorSubject<any>(false);
      /** Informacion de precio solicitado */
      public rateConsult = new BehaviorSubject<any>(null);
        /** Informacion de precio solicitado */
        public showMap = new BehaviorSubject<any>(null);
         /** Bandera de mosttrar CAM MTY */
         public showMapMTY = new BehaviorSubject<any>(null);
              /** Bandera que guarda si requiere factura */
              public obDeclareNoBill = new BehaviorSubject<any>(null);

    /**
     * Creates an instance of ConsultService.
     * @param {HttpClient} http
     * @memberof ConsultService
     */
    constructor(private http: HttpClient) {}

    sendInfo(data: IUserData): Observable<any> {
      return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/teleconsultas/save-free', {...data, mobilePhone: data.phone });
    }


    getConsultPrices(data: IUserData): Observable<any> {
      return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/teleconsultas/list-consulting-prices', {});
    }

    /**
     * Realizar emisión de pago de servicio
     * @param data
     */
    emmitPaymentService(data: any) {
      return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/teleconsultas/emmit-payment-service', data).pipe(
        map(response => {
          return response;
        })
      );
    }
    /**
     * Obtiene precios de planmeds
     */
    getPrices(){	
      return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/prices/pricesList', {});
    }
  }
