<div class="col-12 how-it-works mt-4" id="hiw" >
  <p class="how-it-works__title">¿Cómo funciona<span class=" ml-2 fs-16 ak-icon icon-logo-planmed" style="color: #4F9AAF;"></span>?</p>
  <p class="how-it-works__description text-center px-4">Todos tenemos distintas necesidades. Por ello, con Planmed® te ayudamos para que puedas<br> continuar con tus planes sin sentirte preocupado por tu salud. </p>
  <p class="how-it-works__know-how-title text-center my-5 px-4"><strong>¡</strong>Conoce cómo <span class=" ml-2 fs-16 ak-icon icon-logo-planmed" style="color: #4F9AAF;"></span>ayuda a nuestros usuarios<strong>!</strong></p>
</div>
<div class="container">
  <div class="row">
    <div class="col-12 col-lg-3 toolt journeys"
         [ngClass]="isShowTwo && isShowThree && isShowFour ? 'visible-effect': 'hide-effect'"
        >
      <div class="row">
        <div class="col-12">
          <span class="journeys__tittle journeys__tittle--yellow">Yolanda</span>
        </div>
        <div class="col-12">
          <span class="journeys__sub-tittle">
            Ha decidido, con Pedro, tener un bebé y serán padres primerizos.
          </span>
        </div>
      </div>
      <div class="row justify-content-center">
        <img src="assets/img/journeys/embarazada.jpg" alt="" class="imgss pt-2 pt-xl-0 mx-auto mx-lg-0" (click)=hideElements()>
        <img src="assets/carousel/mano_cursor.png" class="hand" *ngIf='isShow' alt="">
      </div>
      <div class="toolt__tooltiptxt text-left" *ngIf='!isShow'>
        <div class="float-button__close--icon" (click)=hideElements()>
          <span class="icon-Cerrar"></span>
        </div>
        <ul class="pl-3">
          <li class="yellow pl-2">
            <p>
              Contrató <img src="assets/logos/Planmed logo.png" height="11px"  width="52.89px" class="mt-n1" alt="">, asistió a una consulta previa a su embarazo con uno de
              nuestros 	ginecólogos y le pidieron un examen físico completo. </p> </li>
              <li class="yellow pl-2"><p>Se hizo estos estudios con uno
              de nuestros proveedores en convenio.</p></li>
              <li class="yellow pl-2"><p>
           Al sospechar que estaba embarazada, asistió con nosotros donde confirmó su embarazo y comenzó sus
          controles prenatales.</p></li>
          <li class="yellow pl-2"><p> Yolanda hoy cumple 3 meses de 	embarazo y se encuentra en su segundo control de ginecoobstetricia.</p></li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-lg-3 toolt" [ngClass]="isShow && isShowThree && isShowFour ? 'visible-effect': 'hide-effect'">
      <div class="row">
        <div class="col-12">
          <span class="journeys__tittle journeys__tittle--red">Amanda</span>
        </div>
        <div class="col-12">
         <span class="journeys__sub-tittle">
            Le va bien en el trabajo, pero notó que le dedica poco tiempo a su salud, la cual
           en ocasiones le ha impedido realizar los viajes que planea.
          </span>
        </div>
      </div>
      <div class="row justify-content-center">
        <img src="assets/img/journeys/ejecutiva.jpg" alt="" class="imgss pt-2 pt-xl-0 mx-auto mx-lg-0" (click)=hideElementsTwo() alt="">
        <img src="assets/carousel/mano_cursor.png" class="hand" *ngIf='isShowTwo' alt="">
      </div>
      <div class="toolt__tooltiptxt text-left" *ngIf='!isShowTwo'>
        <div class="float-button__close--icon" (click)=hideElementsTwo()>
          <span class="icon-Cerrar"></span>
        </div>
        <ul class="pl-3">
          <li class="red pl-2">
            <p>
              Agendó su cita para su chequeo médico inicial de
              <img src="assets/logos/Planmed logo.png" height="11px"  width="52.89px" class="mt-n1" alt="">
            </p>
          </li>
          <li class="red pl-2">
            <p>
            Acudió a realizarse los estudios y posteriormente tuvo una cita con nuestro médico 	general. </p></li>
            <li class="red pl-2">
              <p>
            Juntos decidieron el mejor plan de salud que se ajustara a su ritmo de vida.
            </p>
          </li>
          <li class="red pl-2">
            <p>
              Acude a citas con nutrición y psicología de forma regular y en dos semanas tendrá un 	viaje con sus amigas.
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-lg-3 toolt" [ngClass]="isShowTwo && isShow && isShowFour ?
    'visible-effect visible-effect__left': 'hide-effect'">
      <div class="row">
        <div class="col-12">
          <span class="journeys__tittle journeys__tittle--green">Ricardo</span>
        </div>
        <div class="col-12">
         <span class="journeys__sub-tittle">
           Tiene 36 años, recientemente, sufrió una lesión en la espalda y le recomendaron contratar Planmed® para usarlo de inmediato.
          </span>
        </div>
      </div>
      <div class="row justify-content-center">
        <img src="assets/img/journeys/joven.jpg" alt="" class="imgss pt-2 pt-xl-0 mx-auto mx-lg-0" (click)=hideElementsThree() alt="">
        <img src="assets/carousel/mano_cursor.png" class="hand" *ngIf='isShowThree' alt="">
      </div>
      <div class="toolt__tooltiptxt text-left" *ngIf='!isShowThree'>
        <div class="float-button__close--icon" (click)=hideElementsThree()>
          <span class="icon-Cerrar"></span>
        </div>
        <ul class="pl-3">
          <li class="green pl-2">
            <p>
             Ricardo notó un dolor agudo y llamó al 5169 3080 para recibir orientación médica 	telefónica.</p></li>
             <li class="green pl-2">
              <p>
             Le recomendaron acudir al Centro de Atención Médica para ser evaluado.
            </p></li>
            <li class="green pl-2">
              <p>
             Fue atendido y diagnosticado por uno de nuestros especialistas.</p></li>
             <li class="green pl-2">
              <p>
              Para recuperarse de la espalda, acude a terapias físicas en el Centro de Atención
              Médica que le queda de camino al trabajo.
              </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-lg-3 toolt" [ngClass]="isShowTwo && isShowThree && isShow ? 'visible-effect visible-effect__left': 'hide-effect'">
      <div class="row">
        <div class="col-12">
          <span class="journeys__tittle journeys__tittle--grey">Vicente</span>
        </div>
        <div class="col-12">
            <span class="journeys__sub-tittle">
            Es abuelo de cuatro pequeños que le demandan mucha actividad física.
          </span>
        </div>
      </div>
      <div class="row justify-content-center">
        <img src="assets/img/journeys/abuelo.jpg" alt="" class="imgss pt-2 pt-xl-0 mx-auto mx-lg-0" (click)=hideElementsFour() alt="">
        <img src="assets/carousel/mano_cursor.png" class="hand" *ngIf='isShowFour' alt="">
      </div>
      <div class="toolt__tooltiptxt text-left" *ngIf='!isShowFour'>
        <div class="float-button__close--icon" (click)=hideElementsFour()>
          <span class="icon-Cerrar"></span>
        </div>
        <ul class="pl-3">
          <li class="purple pl-2">
            <p>
              Su hija Maribel contrató <img src="assets/logos/Planmed logo.png" height="11px"  width="52.89px" class="mt-n1" alt="">,
              y él es uno de sus beneficiarios.</p></li>
              <li class="purple pl-2">
                <p>
              Jugando con sus nietos, Vicente se rompió un brazo y acudió inmediatamente al Centro de Atención Médica para ser evaluado.
            </p>
              </li>
              <li class="purple pl-2">
                <p>
             Fue atendido por nuestro ortopedista y le hicieron un estudio de Rayos X. </p></li>
             <li class="purple pl-2">
              <p>	Inmovilizaron el brazo de Vicente, le aplicaron un yeso, y tuvo una buena 	recuperación. Hoy puede seguir jugando con sus nietos en el parque.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
