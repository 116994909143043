<div class="users mt-5 pt-5" >
    <p class="users__title">¿Qué opinan nuestros<br /> 
      usuarios de AXA Keralty?</p>
</div>
<div class="container" >
    <div class="col-12 my-3 py-5">
    <img src="assets/opinions/left-mark.png" class="users__left-mark" alt="comilla izquierda">   
        <ngx-slick-carousel class="carousel"
        #slickModal="slick-carousel"
        (click)="getModal(slickModal)"
        [config]="slideConfig">
      <div ngxSlickItem *ngFor="let opinion of opinions" class="slide">
        <p class="users__text">{{opinion.opinion}}</p>
        <p class="users__name font-weight-bold" [innerHTML]="opinion.name"></p>
        <p class="users__ocupation mt-2">{{opinion.city}}</p>
      </div>
      </ngx-slick-carousel>

    <img src="assets/opinions/right-mark.png" class="users__right-mark" alt="comilla derecha">
    </div>
</div>
