/**
 * Constante que almacena las opiniones de los usuarios
 */

export const OPINIONS = {
  USERS: [
    //1
    {
      opinion:
        "“A los médicos y al personal que me atiende en cada consulta, me buscan las citas en horario y día que he solicitado y me dan respuesta a las dudas que he tenido…”.",
      name: " Cliente AXA Keralty ",
      ocupation: "",
      city: "",
    },
    //2
    {
      opinion:
        "“Muy buena atención con personal muy calificado, persona e instalaciones de muy alta calidad”.",
      name: " Cliente AXA Keralty ",
      ocupation: "",
      city: "",
    },
    //3
    {
      opinion:
        "“A todo el personal en general, ¡excelente trabajo el que dan desde limpieza, seguridad y recepción, los médicos ni se diga! Felicidades”.",
      name: " Cliente AXA Keralty ",
      ocupation: "",
      city: "",
    },
    //4
    {
      opinion:
        "“…Es un placer contar con la protección de un producto como lo es Planmed y no tengan la menor duda de que lo recomendaré ampliamente las veces que sea necesario...”.",
      name: " Cliente AXA Keralty ",
      ocupation: "",
      city: "",
    },
    //5
    {
      opinion:
        "“Me atendieron muy bien. Me atendieron a tiempo, con amabilidad y atención”.",
      name: " Cliente AXA Keralty ",
      ocupation: "",
      city: "",
    },
    //6
    {
      opinion:
        "“A toda la Clínica y a Axa Keralty. Por el servicio que es bueno, eficiente y puntual”.",
      name: " Cliente AXA Keralty ",
      ocupation: "",
      city: "",
    },
    //7
    {
      opinion:
        "“Ofrecen servicios profesionales, de calidad. Estoy muy satisfecha con el trato que dan. Atienden puntualmente las citas y dan buen trato”.",
      name: " Cliente AXA Keralty ",
      ocupation: "",
      city: "",
    },
    //8
    {
      opinion:
        "“…Hablando exclusivamente de AXA Keralty me parece de las mejores opciones que existen hoy en el área de salud. Y he corroborado con fortuna que tienen excelentes profesionales en el área. En mi experiencia personal y viviendo en el estado de Querétaro, he quedado muy contento con la Clínica y los doctores que ahí trabajan…”.",
      name: " Cliente AXA Keralty ",
      ocupation: "",
      city: "",
    },
    //9
    {
      opinion:
        '“…Solo deseo manifestar la excelente atención que me brindó la Srita. Sabrina Rodríguez, de Atención a Clientes sucursal “Parques Plaza", ya que me brindó apoyo y orientación de forma puntual y oportuna para poder agilizar un trámite de cambio de datos de mi Póliza y seguimiento a la renovación de esta”.',
      name: " Cliente AXA Keralty ",
      ocupation: "",
      city: "",
    },

  ],
};
