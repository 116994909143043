/**
 * Constante de iconos para el footer.
 */

export const DIALOGFLOWCONFIGDEV = {

  DF_API_BASE_URL: 'https://dialogflow.googleapis.com/v2',
  DF_ACCOUNT_KEYS: {
    type: "service_account",
    project_id: "axasaluddev",
    private_key_id: "b02cf784f964a427612b0f90bc084dd4ba31db1f",
    private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDOOIedVJcwrdmY\nkU3Lsmk9T3AP0hTcXwJdxZ45Y1CVbCho13h93NBFWEitqXL0OmJa/vSZ8JZ+BqhG\nrs8paXVUpGooxqehjBWjhc8i6TjriJmpE/JYWFoksmU09hhwIwBm35qXktn2mVHA\nU5q5mFBJK6O4cXkM+Sb1pjk0XREn+drRukGNXPeXMIXgOWgJ2PLL7G/jljgbvxZA\n4L6qYRh2WkNsob8wchCN8Y8QRjXzxS2H8KbBHRROOL8ya9V777+IgzgPle8XGcJy\n1jx4PAH13SocWJey9/mBLw6V/BiIyHD1H5hIoekVQFBLy9gWmXYOUxM26aPBHsBR\n1frN3yqDAgMBAAECggEADmG133yHQL9XFgNILCUIntx74baSet3rYdPG2ZS6CXmu\nBcXFIu3z3mouQ2I2YJ7zvgna0CONs0NOmANX8jjnFFyVBLv589O7rJhH3lTqgc6Y\nfMil1ZBv1PBWC5AAG03tmL8OXnTA26hMob+S4k+jhb7T36iWOxfjJi2N/r9g02tC\nx/ONYcygf4F8QBjg13XxsB8vJb5acRuA/klIce6O7dA6jzQQkVcssV1puih8uUlP\nr6rvE5XTBT24lwEmW5EYgcEBNnzk5zuSYjEt07fCKWr2tvOaCivqqg6jtW8436EP\nJwbbpdbT79H8YCk5rohvgcFkh/Ah+gZklW+ruh2sLQKBgQDxOFvhynEV2HsUUGW2\n0ugjHDWjlV9Iimdpjl4lDTLUGzfQFwNJuK+SE9ZEoNW6iubH7xJ4kAYsDLl4Q41p\n6E3mLbVSo5L1iniAV50tQ7B1H5d+zAi8AtnBVSHAX3l2xCH3Ym3YYCDpbHLXK2QX\nzfnvby6nEI1c4yXPqKuJiAhSdQKBgQDa2zD8dihF/3V22au0G7QAqiXo2O0+EeRB\n7GHnirJgrUtfu/kKxX1uHpP9eMDWRdTni3JJ8b8E/GvvQzyOGHc/jk5cMcgkFTcN\n3GcSWQD07J0YdOXNFOm3y6OuYVzkGA4UhhAsz7E1gYsBMq/1ZWDf+iNtHIyeknLJ\naAnOhAR6FwKBgQDMCqxOFfwKSo/x4v/m4ghSiq4krVln9wr6kt634K46FLvlXVH+\nriZ1HiqE6rdfj9cfD+9D5DAUWr8YJ6wbBdG+/rL+MDdDU9oSVgoyG6vLpuly7AwH\nXTHtA6IFI72O5OEGbVAVU1sDmCScNav5JtZf3mBMGQg6pjl5qmdPcq33cQKBgQCQ\nI8q4i61spJodg7D1d0VvJqC/IZfmyGvYCzJUB+IBkEDTNX8JXBaHHzaCuzPuK6HU\nOeA02TXgt8rykwrf3zcysavkRpk1Qo+23lE4IUxjAy9X+y2ai0p+1WzhHZjLVRh4\n5e7jRIrG3aHKUTLYYUOTYDBS3XmzumpFQfMrg4FbUwKBgQDdoZDXYjWBZD9QaH6F\nIh0wOXFtOe98Hv7HHGBD+zX6WeN4jB1T6vgd+4n9bSL81XFeLrSKL2Tk2MVhbF0y\nVEO8de3zFMTUK3MOHabZvTPMQU7Ed8wwILk7e21M2j8DhO4REIYYiRq5DtrQ/Z6f\nDgnC7Ktg6DV9AX/oxguVSHcDXw==\n-----END PRIVATE KEY-----\n",
    client_email: "id-bcofirebase-2bcore-com@axasaluddev.iam.gserviceaccount.com",
    client_id: "109472810783293210767",
    auth_uri: "https://accounts.google.com/o/oauth2/auth",
    token_uri: "https://oauth2.googleapis.com/token",
    auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
    client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/id-bcofirebase-2bcore-com%40axasaluddev.iam.gserviceaccount.com"
  },
  LANGUAGE: 'es'
}

export const DIALOGFLOWCONFIGPRE = {
  DF_API_BASE_URL: 'https://dialogflow.googleapis.com/v2',
  DF_ACCOUNT_KEYS: {
    "type": "service_account",
    "project_id": "axachatbotpre",
    "private_key_id": "7308b174c6ffcb9ff782e15aaaa62ba41d95294d",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCeWpPkbhZHRHYa\n7p7gbfvxXn6LgaEQNH/f5O1B9dRVvZkXU7IfiJ2lqcUnolGlOph/SVcqCZdbs+DD\nGuRUcctvno1vXqB0Df7rDh4PPM+9PeT+5mW9QVuLQ5yXPdjb2+z+NDf4Exjwr+2E\nmYIV+fG5otmmgwPnckHOP0BwFExN4bdiH00Bjc3oTcCZLIsOikPwQLt+R7LKtSgR\nXQRMf5mNztfWKN75CQouxGqcrtkwzov822vSnCXjgrSxfeLFL2848XpulU+7/K6n\nTAu6g9+hQiGJn5tL3YfuOWHgTUNuIJVmqFZ4UXp7Vy7emy8zKUqM3gFSsxDsoIve\nNvdTWCknAgMBAAECggEABjgsgtZIDbiEov4oQ0xOjuZVNgl7V/WX6CVHpEWRd2Sg\n9KeW4WIuPulyEuJvLnwkrSsmP0JtLW7dLwyJVz+468NgbrVau/QoxPai+5o51obt\nzY0jBdHhVJDtP4twTwJORpWqdRXa7ukZ/RFCaLaOZeodfkq7BoYYS490anKBpLVe\n7axWjuG8Rs6cwVL+3+p1sPkOKoHh6FjHdEm+OaWkzIp7s3nrPHw1iMSGLDwVLjan\nJAo9Pz82xXhWU+qD9w9xlCc7pGS0On6m5jHTdpvGT7J+AyYOrTeYh8SWS1KvaLWP\nM+qTZvDmB3yq/Joi4UCUoInKrnUSvauEi8H1aJCzcQKBgQDaxKnl8K3hPbTPfw5V\n8BSQfug3QjoORDrkskqnLQx0YCxCPr9UUeKKup1Pg6jDiHr4wtDiVGjuPKIaGr6F\nSHbLT3/jsDy3sfULT1P91YHqydPDe5FMRxfoJjDy6jSKoVe+KX45Hq1LuSC2usWD\nxpR7yMnnUQNrOPGcgWl/omlntQKBgQC5TcSL8TftaHhdHh77vdrJlBTF60pFBk9R\nY1QnFFLgiQHW1rRDAR0lpa0UQl9BY4T4NIiE+BZfkLAW4kASvQdYkHMAZzAnIwFV\nSS1lklYBOgL70/dzfeHlEOwuhdALznp6ZimV8GMKt5ng1yRKnV5RVwhxgNC/FrdE\nkam/DGve6wKBgQC4nAzjRV72HCRglU97pcU0ymMbfn+PWnR4WRw2RwWjjyTKIjlC\n5nbNqvzlBK19k8Zx9bRfyetiXzhL3xW3U5crqkK52RbjzQCEMsUmBu5DO13AMJ5E\nSBochV35HzqHYe+xQUKK4SBMLkU07hePRBw4+Q+oa+z+pmb9Mxfb5SodoQKBgQCy\nn1plI6MrwNa1vEFVnc93HL1hg8vV+Z0f8UW/XgH921Pgipq9NCeqkKfkrY4w9APL\nVqh6tMKsYEa96F6nMXwRTTzuGIn8T+wLED0oegVZrVgO9GbF94R3UPa6adcAaor5\nzd0sYcq3HADDEeAz5Lp0YhcLgoa2jOkgueEM/qZE/QKBgCfQpCG2qzySxIo49gmI\nZUz9R6r6KG0vym5wz03kPx7MxVFuRb46pdNonRbj+Nssufh00szKQmHR1uTZQGNU\nqQyo8GtvrGOUGaJ99VdBl05oR6teNtERW+/HHvv9EuwapNBPExGm85nkC6wL9QlJ\nM6/2scGS6bKXHr/B43grseGg\n-----END PRIVATE KEY-----\n",
    "client_email": "id-bcofirebase-2bcore-com@axachatbotpre.iam.gserviceaccount.com",
    "client_id": "104551124701648261900",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/id-bcofirebase-2bcore-com%40axachatbotpre.iam.gserviceaccount.com"
  },
  LANGUAGE: 'es'
}

export const DIALOGFLOWCONFIGPROD = {
  DF_API_BASE_URL: 'https://dialogflow.googleapis.com/v2',
  DF_ACCOUNT_KEYS: {
    "type": "service_account",
    "project_id": "axachatbot",
    "private_key_id": "c374990c6eff8b9d8bc1282e10ffb7a5a45e641d",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDAYkJu/YHWiEUF\nEogcKPxbUIBWFpOSRi9nWrDht4chTgwyhI5v0fi/VWFxOE098120Vmi8xNbbZTsJ\nrwjvNvaFFjGb86zHA9O7t58fV9eM08Aa79Nxp+UkK/1nikHjdxi4Z6EqL6+W8jC3\nqADJHsYSiJvM8ryREecaHjuGmfUHOIu8kpvGDArmWmgWF2/VezwmMkczGrbCjJ9+\nzgceZvy6Uk6oK8OZRsZaxQAFpZNCVCxEnb+tqFZSlNx8MoU448ezM9C3PKaeBZmb\ntqF/laVwE2TQIVAyrPkHZIhQ9etWhQdEXaOWf752Cpkru9Hz74fnwzxkvKchXYfq\nzpSYp5OJAgMBAAECggEABkooGx4XZLrJi+IIWGhYIMmj2nysODHqepXu1sBT7wkw\n0aV/DSWXnPTHBec18kauu3bAP5ytXBimCpniAEUjH+SC/HLl5Q9JWLenAIS0D778\nBr1sw6heL9Wu2Bffcdb61EuSC7Gpq1URde2skea7Sc+UzWlptBH6ZOKUZmEJ72GR\nsZdlc3Vh0IiYrF9PhbofnqSNq29las2xy5cDBz14t96tB+iy7e4qjPut1kmPTJ00\nlUxv00hQGMMm0M9jS48ij0QqXEZwGLRaER6N7CV8Uq2JyivaxUTUEvFC+9lYbRL+\nKYHYLd97gQ93vBnKrl4sVEzAVSo+D/sflqsp7OV5kQKBgQDycmj9N6MeBKYmBM56\nLYe13wI7pvS3eeqZwxlSUt1oqOKfQGO9Zx8a/r7FBuoKIsbjg7pLhNZzZiC6vzt8\njTbaCQZ4qosETP56IQD8W/TnPf6FI7FZ1dDA+klYGYkwfIsUlS6MwAhIKbY1Hbrw\nEVqkDK6C3I+swMoQP3XTMZWtcQKBgQDLI2kaT0jqvbi2vjvQgiIrdOmqBgolbwCb\ny6ZLeTsdeUMC/HYQyMcHDqJDLvR1EyAdsg5B6DHboFw5/0B4rJR3NmeD74FVtov/\nJgsz0ZJCfB2hJVGL+zQlFNE9QaVM7MYzNAtjKMTPWaPatGfPa9278BmIa77Zs+fi\n7VgOp2gbmQKBgFmWj1cqbPsVqrOXyRh6pJA/BNs4GWuHAHqrDjtOZYRcC8nfCDwf\n0+2svp+dq3PtX+MIdRmcz4W68lMvgPLIV8F/3kVOd6oyLULB2VRnQnsEfH2EL+/L\nQ6VblyiX3wrsqpQDgD0AZM8NosfD4aT+NKk0CnROvkSOxNv2JzrQMsfxAoGBALSw\nHK7wUxWqCAFUmPPQrQAmkClSEfhWEh6COLrtbK28zOR9F6+6R355f89ulrcEeg6v\n8IqjNKX8r8xOxVQRfkXDuLPceBh7tW8Qseo3wXct21UZUr3Ghg1txOhN2fDgszOc\nWXysKNOfEmZZDfWsJqEi4jM8U5hltB9fqnHuamYRAoGAV+wo+ulnUB5dsQ6EVc/L\nKOGvV9YSSQNlGoCPJG0qg4wwEv0Iap1HLiClQgvgKXnuEhUryWr2HrBId2GhL3j/\nl/qvZugHzL8EmOuiOi+aibwVTVmC5Av/BeIkGmoRPhSbBPitjDPMlPCtRKhHfDeQ\nZz85NP5q7P9Ir30WennVzVY=\n-----END PRIVATE KEY-----\n",
    "client_email": "id-bcofirebase-2bcore-com@axachatbot.iam.gserviceaccount.com",
    "client_id": "114264700888313841847",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/id-bcofirebase-2bcore-com%40axachatbot.iam.gserviceaccount.com"
  },
  LANGUAGE: 'es'
}
