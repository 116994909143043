import { IEventGTM } from './../../interfaces/gtmEvent.interface';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ChatService, Message } from '../../services/chat/chat.service';
import { Observable, timer, Subject } from 'rxjs';
import { scan, takeUntil } from 'rxjs/operators'
/**
 * Componente de chatDialog
 */
@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss']
})
export class ChatDialogComponent implements OnInit {
  /** Declaración Observable messages chat */
  messages: Observable<Message[]>;
  /** Declaración del formulario */
  formValue: string;
  /** inicialización de showOptions */
  showOptions: string;
  /**Declaración de variable para mensajes */
  message: string;
  /**Declaración de variable para asignar el titulo */
  title = "2B Bot";
  /**Declaración de variable para las options de tipo boolean */
  band_options: boolean;
  /**Declaración de variable para las band  */
  public band: boolean;
  /** Declaración de variable history de tipo array */
  history: Message[] = [];
  /** inicialización de variable boolean en true para desactivar efectos*/
  fadeOf = true;
  /** Declaración de variable boolean para escribir mensaje */
  writingMessage: boolean;
  /** Declaración de variable privada para finalizar */
  end: boolean;
  /** Declaración de variable privada para obtenerel elemento texto */
  @ViewChild("text") private _inputElement: ElementRef;
  /** Declaración de variable para saber si esta escribiendo  */
  typing: boolean;
  /** Declaración de variable privada para saber si acaba de entrar */
  firstTime: boolean;
  /** Declaracion de variable para timer de inicio */
  timer: Subject<any>;
  /** Declaracion de variable minimizar */
  minimized: boolean = false;
  /** Declaracion de variable para el menu */
  menu: boolean = true;
  /** Declaracion de variable para el side */
  side: boolean = false;
  /** Declaracion de variable para la inactividad */
  inactivity_couter;
  inactivityband;

  reload;
  /**
   * Constructor de la clase
   * @param chatService chatService
   */
  constructor(private chatService: ChatService) {
    this.timer = new Subject();
  }
  /** Código que se ejecuta al inicializar el componente. Para inicar con su objetivo*/
  ngOnInit() {
    this.firstTime = true;
    this.inactivityband = true;
    this.initConv(false);
    this.chatService.toggle.asObservable().subscribe((res) => {
      this.band = res;
    });
    this.chatService.reload.asObservable().subscribe((res) => {
      this.reload = res;
      if (this.reload == true) {
        clearTimeout(this.inactivity_couter)
        this.messages = new Observable<Message[]>();
        this.initConv(true)
        this.timer.next(false);
        this.chatService.changeReload(false);
        this.chatService.welcome();
      }
    })
    this.chatService.writing.asObservable().subscribe((res) => {
      this.typing = res;
      if (this.typing == false && this._inputElement != undefined) {
        setTimeout(() => {
          if (this._inputElement != undefined) {
            this._inputElement.nativeElement.focus();
          }
        }, 10);
      }
    })
    this.chatService.endConv.asObservable().subscribe((res) => {
      this.end = res;
      if (this.end == true) {
        timer(80000).pipe(takeUntil(this.timer)).subscribe((x) => {
          this.endConv();
        })
      }
    })
  }
  /**
   * Método para validar el formulario
   * y enviar message
   */
  sendMessage() {
    clearTimeout(this.inactivity_couter)
    if (this.formValue != undefined) {
      if (this.formValue.length >= 1) {
        this.formValue = this.formValue.replace(/[<>=^{}/()|[\]\\]/g, '');
        this.chatService.converse(this.formValue);
      }
    }
    this.formValue = '';
    this.detectInactivity()
  }
  /** toggle minimizar */
  toggle() {
    if (this.firstTime) {

      //this.chatService.welcome()
      this.menu = true;
      this.firstTime = false;
    }
    this.minimized = true;
    this.chatService.changetoggle(!this.band);
    if (this.band == true) {
      this.detectInactivity()
    }
    /** Envío de evento a dataLayer para taggeo */
    const eventGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'floatButtons',
      eventAction: 'chat Lupita'
    }
    window.dataLayer.push(eventGtm);
  }

  minimize() {
    this.minimized = true;
    this.chatService.changetoggle(!this.band);
  }

  detectInactivity() {
    this.inactivity_couter = setTimeout(() => {
      this.endConv()
    }, 600000)
  }

  closeMenu() {
    clearTimeout(this.inactivity_couter)
    this.menu = true;
    this.messages = new Observable<Message[]>();
    this.initConv(true)
    this.timer.next(false);
    this.toggle();
    this.minimized = false;
    this.cancell();
    this.chatService.changeCancell(true);
  }

  /** closeTry*/
  closeTry() {
    this.minimized = false;
    this.fadeOf = false;
  }
  /** conv option */
  convOption() {
    this.chatService.welcome();
    this.menu = false;
  }
  /** Info option */
  info() {
    this.chatService.info();
    this.menu = false;
    this.end = true;
  }

  /**
   * initConv
   * iniciar conversación
   * @param band band
   */
  initConv(band) {
    let i = 0;
    this.messages = this.chatService.conversation.asObservable()
      .pipe(scan((acc, val) => acc.concat(val)));
    if (!band) {
      this.messages.forEach(msg => {
        this.history = msg.slice(0, msg.length)
      })
    } else {
      this.messages.forEach(msg => {
        this.history = msg.slice(1, msg.length)
      })
    }
  }

  /** Método endConv finalizar conversación*/
  endConv() {
    clearTimeout(this.inactivity_couter)
    this.menu = true;
    this.messages = new Observable<Message[]>();
    this.initConv(true)
    this.timer.next(false);
    this.toggle();
    this.minimized = false;
    this.cancell();
    this.chatService.changeCancell(true);
    this.chatService.changeReload(false);
    //this.chatService.welcome();
  }
  /**
   * Método Cancelar
   */
  cancell() {
    this.fadeOf = true;
  }

  sendTag() {

  }

}
