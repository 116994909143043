<div class="full-container alert-banner  " *ngIf="showBanner">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-1 d-flex align-items-center justify-content-center">
        <span class="ak-icon icon-cookie" style="font-size: 60px;"><span class="path1"></span><span class="path2"></span></span>
      </div>
      <div class="col-12 col-md-9">
        <p class="alert-banner__title col-10 px-0 mr-3 mb-1">Uso de cookies</p>
        <p class="alert-banner__message">
          Utilizamos cookies para brindarte una mejor experiencia en línea, algunas de las cookies que utilizamos identifican tus hábitos de navegación. Al continuar en este sitio, aceptas el uso de dichas cookies.
           Para conocer más acerca de nuestra política de uso de cookies da <a rel="noopener noreferrer" style="text-decoration: underline;font-weight: 600;cursor: pointer;" (click)="goToHome()" id="link_cookies_usePolicies">clic aquí.</a>
        </p>
      </div>
      <div class="col-12 col-md-2 text-center d-flex justify-content-center align-items-center">
        <button class="btn-accept" (click)="close()" id="btn_cookies_acept">Aceptar</button>
      </div>
    </div>
  </div>
</div>
