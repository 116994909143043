import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { BasicInfoComponent } from './components/planmed-policy/basic-info/basic-info.component';
import { ApprovedPaymentComponent } from './components/planmed-policy/approved-payment/approved-payment.component';
import { PaymentConfirmationComponent } from './components/planmed-policy/payment-confirmation/payment-confirmation.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { FrequentQuestionsComponent } from './components/frequent-questions/frequent-questions.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { CancellationPolicyComponent } from './components/cancellation-policy/cancellation-policy.component'
import { AxaCOVIDComponent } from './components/axa-covid/axa-covid.component'
import { GeneralPoliciesComponent } from './components/general-policies/general-policies.component';
import { PrivacyNoticeComponent } from './components/privacy-notice/privacy-notice.component';
import { CovidMapComponent } from './components/covid-map/covid-map.component';
import { VisitUsComponent } from './components/landing/visit-us/visit-us.component';
import { BenefitsComponent } from './components/landing/benefits/benefits.component';
import { JobExchangeComponent } from './components/job-exchange/job-exchange.component';
import { DataPaymentComponent } from './components/services/data-payment-services/data-payment.component';
import { CheckoutPaymentComponent } from './components/services/checkout-payment-services/checkout-payment.component';
import { SuccessPaymentComponent } from './components/services/success-payment-services/success-payment.component';
import { LandingPaymentComponent } from './components/services/landing-payment-services/landing-payment.component';
import { ConsultConditionsComponent } from './components/services/consult-conditions/consult-conditions.component';
import { ConsultInfographicComponent } from './components/services/consult-infographic/consult-infographic.component';
import { TokenComponent } from './components/token/token.component';
import { PayPolicyComponent } from './components/pay-policy/pay-policy.component';
import { PaymentSuccessComponent } from './components/pay-policy/payment-success/payment-success.component';

import { MainLayoutComponent } from './corev2/layout/main-layout/main-layout.component';
import { LayoutComponent } from './core/layout/layout.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: LandingComponent },
      { path: 'payment-confirmation', component: PaymentConfirmationComponent },
      { path: 'user-information', component: BasicInfoComponent },
      { path: 'approved-payment', component: ApprovedPaymentComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'faqs', component: FrequentQuestionsComponent },
      { path: 'politicas-de-uso', component: GeneralPoliciesComponent },
      { path: 'aviso-keralty', component: PrivacyNoticeComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'sign-in', component: SignInComponent },
      { path: 'cancellation-policy', component: CancellationPolicyComponent },
      { path: 'covid-19', component: AxaCOVIDComponent },
      { path: 'covid-map', component: CovidMapComponent },
      { path: 'visitUs', component: VisitUsComponent },
      { path: 'get-plan', component: BenefitsComponent },
      { path: 'bolsa-trabajo', component: JobExchangeComponent },
      { path: 'servicios', component: LandingPaymentComponent },
      { path: 'servicios-pago', component: DataPaymentComponent },
      { path: 'servicios-checkout', component: CheckoutPaymentComponent },
      { path: 'servicios-exito', component: SuccessPaymentComponent },
      { path: 'consulta-terminos-y-condiciones', component: ConsultConditionsComponent },
      { path: 'infografia', component: ConsultInfographicComponent },
      { path: 'tokenizacion', component: TokenComponent },
    ]
  },

  {
    path: '', component: MainLayoutComponent,
    children: [
      {
        path: 'pay-policy',
        component: PayPolicyComponent
      },
      { path: 'pay-policy-success', component: PaymentSuccessComponent },
    ]
  },

  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
