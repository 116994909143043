import { Router } from '@angular/router';
/** import Angular Component, OnInit*/
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
/**
 * AxaCOVIDComponent
 */
@Component({
  selector: 'app-axa-covid',
  templateUrl: './axa-covid.component.html',
  styleUrls: ['./axa-covid.component.scss']
})
export class AxaCOVIDComponent implements OnInit {

  clientID: string;

  /** constructor*/
  constructor(private router: Router, private deviceService: DeviceDetectorService) { }

  /** ngOnInit*/
  ngOnInit() {
    this.replaceScript();
  }

  replaceScript() {
    try{
      if(document.cookie.indexOf('_ga=')>-1){
          const gaCookie = document.cookie.split('_ga=')[1].split(';')[0].split('.');
          this.clientID = gaCookie[2] + '.' + gaCookie[3];
      }
    } catch(e){
      return 'Error al obtener el id de Google Analytics';
    }

    if (this.router.url === '/covid-19'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        login: 'no',
        clientId: `${this.clientID}`,
        pageType: 'home',
        pageCategory: `${this.router.url}`,
        pageURLCategory: `${window.location.href}`,
        device: `${this.getDevice()}`,
        numeroCotizacion: 'NA',
        folioPago: 'NA',
        numeroSolicitud: 'NA',
        numeroPoliza: 'NA'
      });
    }
  }

  getDevice() {
    if(this.deviceService.isDesktop()) {
       return 'desktop';
    } else if(this.deviceService.isMobile()) {
      return 'mobile';
    } else if(this.deviceService.isTablet()) {
      return 'tablet';
    } else {
      return 'other';
    }
  }

}
