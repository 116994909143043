import { NAVIGATION_OPTIONS } from './../navigation/navigation.const';
import { NavigationService } from './../navigation/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsub } from 'src/app/utilsv2/ng-on-destroy';

@AutoUnsub()
@Component({
  selector: 'ak-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  allowedNavigation: boolean;

  constructor(private route: ActivatedRoute, private navigationService: NavigationService) {
    this.navigationService.initSteps(NAVIGATION_OPTIONS);
    this.allowedNavigation = this.allowNavigation();
  }

  ngOnInit(): void {
  }

  allowNavigation(): boolean {
    let allowed = false;
    let url = location.href.split('://')[1].split('/')[1];

    switch (true) {
      case url.includes('tu-informacion'):
      case url.includes('tu-direccion'):
      case url.includes('integrantes-del-plan'):
      case url.includes('revisa-tu-informacion'):
        allowed = true;
        break;
      default:
        allowed = false;
        break;
    }
    return allowed;
  }

  ngOnDestroy(): void{

  }

}
