import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable} from 'rxjs';
// OPERATORS
import { map } from 'rxjs/operators';

/**
 * Servicio encargado de la estado del usuario activo
 * @export
 * @class visitUsService
 */
@Injectable({
    providedIn: 'root'
})
export class visitUsService {

    /**
     * param: {
     *      location: int
     * }
     */
    public changeLocations = new BehaviorSubject<any>(null);
    /**
     * param: {
     *      cam: int
     * }
     */
    public changeCamShow = new BehaviorSubject<any>(null);
}