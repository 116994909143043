import { HttpClient } from '@angular/common/http';
import { map, filter, concatMap } from 'rxjs/operators';
import { ReCaptchaV3Service } from 'ngx-captcha';

import { IReCaptcha } from '../../interfaces/recaptcha.interface';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

declare const grecaptcha: any;

// OPERATORS
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
	providedIn: 'root'
})
export class RecaptchaService {
	/** Clave web para petición de token */
	public webKey: string = '6LdvjAEVAAAAAKdz-BQvVBJl6TBWuO-QsH9QZ7a0';
	/** Token generado */
	public token = new Subject<string>();
	/** Agregar url para la build */
	url: string = `${window.location.protocol}//${window.location.host}/`;

	/**
	 * Crear instancias de clases a utilizar
	 * dentro del servicio
	 */
	constructor(private reCaptcha: ReCaptchaV3Service, private http: HttpClient,
		@Inject(PLATFORM_ID) private platformId: Object
	) { }

	/**
	 * Crea token de soncronización con captcha de google
	 */
	tokenGenerate() {
		if (isPlatformBrowser(this.platformId)) {
			this.reCaptcha.execute(
				this.webKey,
				'/',
				(token: string) => {
					if (token) {
						this.token.next(token);
					} else {
						this.token.next('');
					}
				},
				{ useGlobalDomain: true }
			);
		}
	}

	/**
	 * Validar el token activo del captcha
	 */
	tokenVerify() {
		if (isPlatformBrowser(this.platformId)) {
			// Generar nuevo token para validación de formulario
			this.tokenGenerate();
			// Validar token generado con captcha de Google
			return this.token.pipe(
				map((getToken: string) => getToken),
				filter(validToken => validToken !== undefined),
				concatMap(generedToken =>
					this.http
						.post(`https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/captcha`, {
							token: generedToken,
							V3: true
						})
						.pipe(map((response: IReCaptcha) => response))
				)
			);
		}
	}

	/**
	 * Obtener token generado con captcha
	 * @param {string} token
	 */
	success(token: string) {
		if (isPlatformBrowser(this.platformId)) {
			this.token.next(token);
		}
	}

	/**
	 * Borrar token generado
	 * @param {string} token
	 */
	destroy() {
		if (isPlatformBrowser(this.platformId)) {
			this.token.next('');
		}
	}
}
