import { IEventGTM } from 'src/app/interfaces/gtmEvent.interface';
/** import core */
import { Component, OnInit } from '@angular/core';
/** import constants */
// import { urlsLoc } from 'src/app/constants/urls.constants';
/** import ngx-bootstrap/modal*/
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { ExperienceService } from 'src/app/services/experience-service/experience.service';
import { Router } from '@angular/router';
/**
 * Component FooterComponent
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /**Titulo del footer  */
  titleFooter = 'AXA Keralty 2020 ©'
  /** Año del footer */
  year: number;
  /** Variable bsModalRef  */
  bsModalRef: BsModalRef;
  /** Sección n del collapse */
  firstSectionActive = false;
  /** Sección n del collapse */
  secondSectionActive = false;
  /** Sección n del collapse */
  thirdSectionActive = false;
  /** Sección n del collapse */
  fourthSectionActive = false;
  /** Sección n del collapse */
  fifthSectionActive = false;
  rutaActiva= '';
  showNewFooter = false
  urlServer: string;
  /** constructor */
  constructor(
    private modalService: NgbModal,
    private experienceService: ExperienceService,
    private router: Router
  ) {
    this.urlServer = 'https://preprod.axakeralty.mx';
  }
  /** ngOnInit */
  ngOnInit() {
    this.router.events.subscribe((reponse: any) => {
      this.rutaActiva = reponse.url !== undefined ? reponse.url : this.rutaActiva;
      const activeRoute = this.rutaActiva.split('/');
      // if (this.rutaActiva === '/') {
      //   window.location.reload();
      // }
      /*if ( this.rutaActiva === '/servicios-pago' ||  this.rutaActiva === '/servicios-checkout' ||  this.rutaActiva === '/servicios-exito' ||  this.rutaActiva === '/consulta-terminos-y-condiciones' ) {
        this.showNewFooter = true;
      } else {
        this.showNewFooter = false;
      }/** */
    });
    this.year = (new Date()).getFullYear();
  }
  /**
   * Funcion para lanzar modal informativo
   */
  openModalInfo() {
    const modalRef = this.modalService.open(ModalMessageComponent);
    modalRef.componentInstance.button.subscribe(() => {
      modalRef.close();
    });

   }
   /** Cambio de sección activa */
  toggleSection(section:number) {
    switch (section) {
      case 1:
        this.firstSectionActive = !this.firstSectionActive;
        this.secondSectionActive = false;
        this.thirdSectionActive = false;
        this.fourthSectionActive = false;
        this.fifthSectionActive = false;
        break;
      case 2:
        this.firstSectionActive = false;
        this.secondSectionActive = !this.secondSectionActive;
        this.thirdSectionActive = false;
        this.fourthSectionActive = false;
        this.fifthSectionActive = false;
        break;
      case 3:
        this.firstSectionActive = false;
        this.secondSectionActive = false;
        this.thirdSectionActive = !this.thirdSectionActive;
        this.fourthSectionActive = false;
        this.fifthSectionActive = false;
        break;
      case 4:
        this.firstSectionActive = false;
        this.secondSectionActive = false;
        this.thirdSectionActive = false;
        this.fourthSectionActive = !this.fourthSectionActive;
        this.fifthSectionActive = false;
        break;
      case 5:
        this.firstSectionActive = false;
        this.secondSectionActive = false;
        this.thirdSectionActive = false;
        this.fourthSectionActive = false;
        this.fifthSectionActive = !this.fifthSectionActive;
        break;
      default:
        this.firstSectionActive = false;
        this.secondSectionActive = false;
        this.thirdSectionActive = false;
        this.fourthSectionActive = false;
        this.fifthSectionActive = false;
        break;
    }
  }
  /**
   * Metodo para ir al intranet
   */
  goToIntranet() {

    /** Evento enviado a dataLayer para taggeo */
    const eventGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'footerLinks',
      eventAction: 'intranet',
      eventLabel: 'link_footer_intranet'
    }

    window.dataLayer.push(eventGtm);

    window.location.replace('/sso/');
  }
  /**
   * Metodo para ir a soy agente
   */
  goToNet() {
    const eventGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'footerLinks',
      eventAction: 'soyDistribuidor'
    }

    window.dataLayer.push(eventGtm);

    window.location.replace('/sso/');
  }
  
  /** Redirecciona por ambiente */
  redirect(): void {
    location.href = this.urlServer + '/cotizador-planmed/#/formulario/referidos'
  }

  sendTags(section: string) {
    let gtm: IEventGTM;

    switch (section) {
      case 'condiciones':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'Condiciones generales',
          eventLabel: 'link_footer_conditionsGral'
        }

        window.dataLayer.push(gtm);
        break;

      case 'trabajo':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'Bolsa de trabajo',
          eventLabel: 'link_footer_work'
        }

        window.dataLayer.push(gtm);
        break;

      case 'faqs':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'Preguntas frecuentes',
          eventLabel: 'link_footer_faqs'
        }

        window.dataLayer.push(gtm);
        break;

      case 'contacto':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'Contáctanos',
          eventLabel: 'link_footer_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'referidos':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'referidos',
          eventLabel: 'link_footer_referidos'
        }

        window.dataLayer.push(gtm);
        break;

      default:
        this.tagsContinue(section);
        break;
    }
  }

  /**
   * Abre el dialogo de sugerencias
   */
  openSuggestions(): void {
    this.experienceService.opennedDialog.next(true);
  }

  tagsContinue(element: string) {
    let gtm: IEventGTM;

    switch (element) {

      // case 'opinion':
      //   gtm = {
      //     event: 'eventgtm',
      //     eventCategory: 'footerLinks',
      //     eventAction: 'Cuéntanos tu opinión',
      //     eventLabel: 'link_footer_opinion'
      //   }

      //   window.dataLayer.push(gtm);
      //   break;

      case 'une':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'UNE/CONDUSEF',
          eventLabel: 'link_footer_UNE_CONDUSEF'
        }

        window.dataLayer.push(gtm);
        break;

      case 'axa':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'AXA',
          eventLabel: 'link_footer_axa'
        }

        window.dataLayer.push(gtm);
        break;

      case 'keralty':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'Keralty',
          eventLabel: 'link_footer_keralty'
        }

        window.dataLayer.push(gtm);
        break;

        case 'fb':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'contacto facebook',
          eventLabel: 'link_footer_social'
        }

        window.dataLayer.push(gtm);
        break;

      default:
        this.tagsFinal(element);
        break;
    }
  }

  tagsFinal(action: string) {
    let gtm: IEventGTM;

    switch (action) {

      case 'insta':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'contacto instagram',
          eventLabel: 'link_footer_social'
        }

        window.dataLayer.push(gtm);
        break;

      case 'privacidadAxa':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'Aviso de privacidad AXA Salud',
          eventLabel: 'link_footer_privacy'
        }

        window.dataLayer.push(gtm);
        break;

      case 'privacidadKeralty':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'Aviso de privacidad Keralty',
          eventLabel: 'link_footer_privacy'
        }

        window.dataLayer.push(gtm);
        break;

      case 'politicaUso':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'Políticas de uso',
          eventLabel: 'link_footer_policies'
        }

        window.dataLayer.push(gtm);
        break;

      case 'politicaCancela':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'footerLinks',
          eventAction: 'Políticas de cancelación',
          eventLabel: 'link_footer_cancellation'
        }

        window.dataLayer.push(gtm);
        break;
    }
  }

}
