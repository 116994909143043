<div *ngIf="loader" class="loader__wrapper">
    <svg
    width="108px"
    height="128px"
    viewBox="0 0 54 64"
  >
    <defs></defs>
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
      class="loader__beat"
      d="M0.5,38.5 L16,38.5 L19,25.5 L24.5,57.5 L31.5,7.5 L37.5,46.5 L43,38.5 L53.5,38.5"
      stroke-width="2"
      ></path>
    </g>
  </svg>
</div>
