<div class="sticky-header">
<div class="container">
    <div class="row">
        <div class="col-12 content-header d-flex align-items-center justify-content-between">
            <div>
                <img src="assets/images/ak-logo.png" class="img-logo-axa" alt="">
            </div>
            <div class="d-flex align-items-center">
               <div class="">
                    <a rel="noopener noreferrer" href="tel:55 5169 3080" class="phone-link d-none d-md-block">
                      <span class=" ak-icon-40 ak-phone phone-icon-contact" style=" font-size: 12px;"></span> Llámanos: (<b>55</b>) 5169 3080 
                    </a>
                    <img src="assets/images/logos_tarjetas_no_months.png" class="img-payment" alt="">
               </div>

                <div class="d-flex d-md-none">
                    <div class=" animation">
                        <a rel="noopener noreferrer" href="tel:55 5169 3080">
                          <div class="animation-phone">
                            <span class=" ak-icon icon-phone1" style="color: #FFFFFF; font-size: 12px;"></span>
                          </div>
                        </a>
                      </div>
                </div>

            </div>
        </div>
    </div>
</div>
</div>
