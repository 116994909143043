<div class="container">
    <div class="row">
        <div class="col-12">

            <p class="title d-none d-md-block">Consulta de saldo pendiente</p>

            <p class="policy-info mb-0"><b>No. de póliza:</b></p>
            <p class="policy-info">{{policy}}</p>

            <p class="policy-info mb-0"><b>Fecha de consulta:</b></p>
            <p>{{currentDate | date:'dd/MM/yyyy'}}</p>

            <p class="policy-info mb-0"><b>Vigencia:</b></p>
            <p class="policy-info">Del {{dateStart | date:'dd/MM/yyyy'}} al {{dateEnd | date:'dd/MM/yyyy'}}</p>

            <div class="warning-balance mb-4">
                <p><img src="assets/searchpolicy/danger.svg" alt=""><span></span>La póliza presenta un saldo pendiente de pago</p>
            </div>

            <p class="pending-balance"><span>Saldo pendiente:</span></p>
            <p class="amount"><span>{{balance | currency}} MXN</span></p>
            <p class="tax-advertisement"><span>IVA incluido</span></p>

            <form [formGroup]="checkForm" id="check-form" >

                <div class="check-wrapper d-flex mb-4">
                    <div class="check">
                        <input type="checkbox" formControlName="checkCardData" id="checkCardData" [class]="{error: checkForm.get('checkCardData').invalid && formSubmited }"  required>
                    </div>
                    <div class="text">
                       <label for="checkCardData">Asegúrate, antes de realizar tu pago, que el nombre del contratante sea el mismo de la tarjeta de crédito o débito con la que vas a pagar.</label>
                    </div>
                </div>

                <div class="check-wrapper d-flex mb-4">
                    <div class="check">
                        <input type="checkbox" formControlName="checkPersonalInfo" id="checkPersonalInfo" [class]="{error: checkForm.get('checkPersonalInfo').invalid && formSubmited}"  required>
                    </div>
                    <div class="text">
                       <label for="checkPersonalInfo">AXA Salud, S.A. de C.V. (AXA), con domicilio en Avenida Félix Cuevas número 366, piso 3, Colonia Tlacoquemécatl, Alcaldía Benito Juárez, C.P. 03200, Ciudad de México, llevará a cabo el tratamiento de sus datos personales para el cumplimiento del contrato de seguro y demás finalidades contempladas en el aviso de privacidad integral, el cual puede consultar en axakeralty.mx en la sección Aviso de Privacidad. Autorizo el tratamiento y transferencia de mis datos personales financieros (bancarios) a los bancos, con objeto de que sea cobrada la prima del seguro.</label>
                    </div>
                </div>

                <div class="check-wrapper d-flex mb-4">
                    <div class="check"> 
                        <input type="checkbox" formControlName="checkAuthorization" id="checkAuthorization" [class]="{error: checkForm.get('checkAuthorization').invalid && formSubmited}" required>
                    </div>
                    <div class="text">
                       <label for="checkAuthorization"> Autorizo a AXA Salud para que la documentación contractual del producto de seguro que deseo contratar me sea entregada a través del correo electrónico que he indicado al momento de contratar.</label>
                    </div>
                </div>

                <div class="col-12 warning-accept-terms pl-0" *ngIf="checkForm.invalid && formSubmited">
                    <div class="external-circle">
                        <div class="internal-circle">
                            <img src="assets/searchpolicy/danger.svg" alt="">
                        </div>
                    </div> 
                    Debes aceptar los términos para poder continuar
                </div>

                <p class="recommendation">Te recomendamos tener a la mano los datos de tu tarjeta para completar tu pago. El cargo se visualizará como “Mercado Pago” en el historial de cargos de tu tarjeta.</p>

                <p class="mt-5 button-wrapper">
                    <button type="button" 
                        (click)="sendCheckout($event)"
                        [class]="{disabled: checkForm.invalid}" 
                        class="btn">
                        Pagar
                    </button>
                </p>

            </form>

        </div>
    </div>
</div>