<div class="container-full d-flex align-items-center banner-map" id="head-visit-us">
  <div class="container">
    <div class="col-12 d-flex">
      <div class="mr-0 mr-sm-3">
        <span class=" ak-icon icon-location-marker img-locator" style="color: #E6A547;"></span>
      </div>
      <div>
        <p class="banner-title">Conoce <br class="separador-mobile"> nuestras ubicaciones</p>
        <p class="color-text">
          ¡Cada vez estamos más cerca de ti!
        </p>
        <div>
          <img src="assets/map/magnifying_glass_blue2.png" class="search-img" alt="">

          <input type="text" class="input-maps" placeholder="Busca tu ciudad" (input)="logEvent(search.value)" #search
            [(ngModel)]="searchVideo">
          <img src="assets/img/modal/close_modal.png" class="close-img" alt="" (click)="showCarrusel()"
            *ngIf="usingFinder">
        </div>
      </div>
    </div>
  </div>
  <img src="assets/map/red-nacional-desktop/Banner Mapa/Mapa_Nacional_desktop_3170.png" class="img-map" alt="">
  <img src="assets/map/red-nacional-desktop/Banner Mapa/Mapa_Nacional_mobile_3170.png" class="img-map-mobile" alt="">
  <span class=" ak-icon icon-location-marker img-locator-mobile" style="color: #E6A547;"></span>
</div>
<div class="container pt-md-3 px-5" *ngIf="usingFinder">
  <div class="row d-flex align-items-center finder-container" *ngIf="!notFind">
    <div *ngFor="let slide of slidesMap; index as i" class="slide mr-3 d-flex align-items-center justify-content-center"
      (click)="changeActive(i,slide)">
      <img src="{{ slide?.img }}" class="slide-map-finder" alt="" [ngClass]="slide?.active ? 'active-img-finder': ''">
      <p class="text-carru-finder">{{slide?.name}}</p>
    </div>
    <div class="col-12 text-center my-3">
      <img src="assets/map/return_real.png" class="back-icon" alt="" (click)="showCarrusel()">
    </div>
  </div>
  <div *ngIf="notFind" class="text-center py-3">
    <p class="working-tittle mb-0">Muy pronto estaremos en tu ciudad</p>
    <p class="working-sub">En AXA Keralty, tu salud es nuestra prioridad</p>
    <img src="assets/map/working.png" class="img-working" alt=""><br>
    <img src="assets/map/return_real.png" class="back-icon" alt="" (click)="showCarrusel()">
  </div>
</div>
<div class="container-full pt-3" *ngIf="!usingFinder && !notFind">
  <ngx-slick-carousel class="carousel visit-us-carousel" #slickModal="slick-carousel" [config]="slideConfigMap">
    <div ngxSlickItem *ngFor="let slide of slidesMap.slice(0, getDifferentsCodeCity().length); index as i"
      class="slide d-flex" id="slick-map">
      <div style="width: 249px;height: 166px;" [ngClass]="slide.class"
        class="d-flex justify-content-center align-items-center" (click)="changeActiveClic(i,slide)">
        <img src="{{ slide?.img }}" class="" alt="" [ngClass]="slide?.active ? 'active-img': 'slide-map'">
        <p class="text-carru">{{slide?.name}}</p>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
<div class="container-full" style="margin-top: -26px;" *ngIf="!hideGeneralMap && !notFind">
  <div class="col-12 map-container">
    <div class="map-general mr-0 mr-lg-5 mask5" [ngClass]="showCard ? isMobile ? 'd-none' : '' : ''">
      <svg height="0" width="0">
        <defs>
          <clipPath id="svgPath">
            <path
              d="M781.52,62.41c-59.36-12.39-233-13.38-382-15L384,47.23V0h-.1V47.23c-262.37-3-313.63-6.88-359.37,10.18-24.31,9.06-27,154-23,229s25,121,43,145,197,38,374,32,359-19,375-56,16-90,16-160S805.05,67.32,781.52,62.41Z">
            </path>
          </clipPath>
        </defs>
      </svg>
      <svg height="0" width="0">
        <defs>
          <clipPath id="svgPathMobile">
            <path
              d="M244.08,25.49c14,5.89,16.35,259.41,3.37,351.69-9.7,68.95-211.64,72.21-228.08,31C-4.32,348.8-5.93,52.42,12.62,19,20.11,5.47,138.26-19,244.08,25.49Z">
            </path>
          </clipPath>
        </defs>
      </svg>
      <svg height="0" width="0">
        <defs>
          <clipPath id="svgPathTablet">
            <path
              d="M467.18,34.28c26.82,7.92,31.3,348.86,6.46,473C455.08,600,68.54,604.35,37.07,548.94-8.28,469.08-11.34,70.49,24.16,25.5,38.49,7.35,264.63-25.53,467.18,34.28Z">
            </path>
          </clipPath>
        </defs>
      </svg>
      <google-map class="agm-map" *ngIf="this.isBrowser"
        [center]="{lat: optionsGeneralMap.lat, lng: optionsGeneralMap.lng}" [zoom]="optionsGeneralMap.zoom"
        (mapReady)="onMapReady($event)" [options]="{styles: styles}">
        <map-marker (mouseOver)="window.open()" (mouseOut)="window.close()" *ngFor="let marker of markers"
          [position]="{lat: marker.latitude, lng: marker.longitude, iconGeneral: iconGeneral}">
          <map-info-window class="tooltip" #window>
            <h6 class="location-name">{{marker.title}} </h6>
          </map-info-window>
        </map-marker>
      </google-map>
      <img src="assets/map/zoom_in_.png" class="button-zoom-in" (click)="zoomIn('general')" alt="">
      <img src="assets/map/zoom_out_.png" class="button-zoom-out" (click)="zoomOut('general')" alt="">
    </div>
  </div>
</div>
<div class="container" *ngIf="hideGeneralMap && !notFind">
  <div class="col-12">
    <div class="row">
      <div class="col-12 col-md-3 mx-auto mt-3" *ngIf="isMobile">
        <ul class="list-group text-center mx-auto">
          <li class="list-group-item px-2" *ngFor="let item of markersMobile; index as i; let isLast = last"
            (click)="expandItem(item,i)" [ngClass]="!isLast ? ' border-mobile' : ''">
            <div class="d-flex">
              <img src="assets/map/flecha_azul_grande_.png" style="width: 15px; height: 29px;" class="mr-3" alt=""
                *ngIf="item.expanded === false">
              <img src="assets/map/flecha_verde_grande_.png" style="width: 29px; height: 15px;" class="mr-3 mt-3" alt=""
                *ngIf="item.expanded === true">
              <div>
                <p class="mb-1"> {{item?.title}}</p>
                <div class="position-relative">
                  <p class="text-resp-mobile m-0 mt-2" style="font-weight: bold;" *ngIf="item.square">
                    {{item?.square}}<sup
                      *ngIf="item.title === 'Mexicali' || item.title === 'Ciudad Juárez' || item.title === 'León' || item.asterisk">*</sup>
                    <sup *ngIf="item.doubleAsterisk">*</sup><sup *ngIf="item.doubleAsterisk">*</sup>:</p>
                  <p class="text-resp-mobile m-0 mb-3" style="margin-bottom: 0px !important;">{{item?.description}}</p>
                </div>

                <!-- <p class="marker-description">{{markerSelected?.help_information}} </p> -->
                <span *ngIf="item.laboratoryIcon"
                  class="position-absolute axa-icon axa-microscope spn-microscope mobile" style="top: 12px; right: 0;">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
              </div>
            </div>


            <div *ngIf="item.expanded" class="mt-3 ">
              <p class="marker-description" style="padding-left: 43px;">{{markerSelected?.help_information}} </p>
              <div class="d-flex" style="padding-left: 43px;">
                <div>

                  <div class="d-flex mb-3" *ngIf="item.onlyLaboratory">
                    <div class="d-flex justify-content-center" style="min-width: 24px;">
                      <span class="axa-icon axa-lab top: 3px;"></span>
                    </div>
                    <p class="ml-2 card-text places__text card-text-map"
                      style="color: #004883 !important;font-weight: 200;">En esta sucursal solo se realizan laboratorios
                      y estudios de gabinete <br>
                    </p>
                  </div>

                  <div class="d-flex my-2" *ngIf="item.onlyLaboratory">
                    <div class="d-flex justify-content-center" style="min-width: 24px;">
                      <span class="axa-icon axa-phone top: 3px;"></span>
                    </div>
                    <p class="ml-2 card-text places__text card-text-map"
                      style="color: #004883 !important;font-weight: 200;" [innerHTML]="markerSelected.hour"> <br>
                    </p>
                  </div>

                </div>


                <img class="mr-1" src="assets/map/pin_locator-clock.png" width="15px" height="15px" alt="iconMap"
                  *ngIf="markerSelected.title !== 'Toluca' && markerSelected.title !== 'Otzacatipan' && markerSelected.title !== 'Metepec'">
                <p *ngIf="!item.onlyLaboratory" class="ml-2 card-text text-info-mobile"
                  [innerHTML]="markerSelected.hour"><strong>Horarios:</strong> <br></p>
              </div>
              <div class="d-flex my-3" style="padding-left: 43px;">
                <img class="mr-1" src="assets/map/estacionamiento_no_.png" width="21px" height="15px" alt="iconMap"
                  *ngIf="markerSelected.title === 'Roma' || markerSelected.title === 'León'">
                <img class="mr-1" src="assets/map/estacionamiento_.png" width="21px" height="15px" alt="iconMap"
                  *ngIf="markerSelected.title !== 'Roma' && markerSelected.title !== 'León' && markerSelected.title !== 'Toluca' && markerSelected.title !== 'Otzacatipan' && markerSelected.title !== 'Metepec'">
                <p class="ml-2 card-text text-info-mobile">{{markerSelected.parking}}
                  <br *ngIf="markerSelected.parking_description" />
                  <span *ngIf="markerSelected.parking_description">{{markerSelected.parking_description}}</span>
                  <a *ngIf="markerSelected.title === 'Clínica Roma'" [href]="ConstParking"
                    style="color: #004883; text-decoration: underline;font-weight: 600;" target="_blank">aquí</a>
                </p>
              </div>
              <div class="d-flex my-3" style="padding-left: 43px;"
                *ngIf="(markerSelected.title !== 'Hermosillo' || (markerSelected.title === 'Hermosillo' && markerSelected.square==='Clínica Plaza Boulevard')) && markerSelected.square!=='Centro Médico Premier' && markerSelected.square!='Hospital Aranda de la Parra* – Puerto Interior' && markerSelected.square!='Star Médica Aguascalientes*' && markerSelected.square!=='Star Médica San Luis Potosí*' && markerSelected.square !== 'Hospital H+' && ( markerSelected.square !=='Hospital Terranova' ) && markerSelected.title !== 'Mexicali' && markerSelected.title !== 'Ciudad Juárez' && markerSelected.title !== 'León' && markerSelected.title !=='Aranda de la Parra' && markerSelected.title !=='Aranda de la Parra – Puerto Interior' && markerSelected.title !== 'Saltillo' && markerSelected.title !== 'Torreón' && markerSelected.title !== 'Mérida' && markerSelected.square !=='Sanatorio Florencia*' && markerSelected.square !=='CHRISTUS MUGUERZA Centro de Medicina Ambulatoria Juventud' && (markerSelected.square !== 'Christus Muguerza Betania') && markerSelected.square !=='Christus Muguerza UPAEP' && markerSelected.hasResponsibleHealth!==false">
                <span class="axa-icon axa-man" style="color: #4F9AAF;"></span>
                <p class="ml-2 card-text text-info-mobile"><strong>Responsable sanitario: </strong> <br>
                  {{markerSelected?.responsable_name}} <br> {{markerSelected?.responsable_education}} <br>
                  {{markerSelected?.responsable_number}}</p>
              </div>



              <div class="d-flex justify-content-center">
                <!-- Boton servicios responsive -->
                <div class="d-flex justify-content-center my-0">
                  <button *ngIf="item.buttons2_information" class="button-services-responsive" (click)="information()"
                    type="button">
                    Consulta de servicios
                  </button>
                </div>

                <!-- Boton visita responsive-->
                <!-- <div class="d-flex justify-content-center ">
          <button *ngIf="item.buttons_information" class="button-services-responsive" type="button">
            Información para tu visita
          </button>
	      </div>-->
                <a [href]="item.url_button" target="_blank" *ngIf="item.buttons_information"
                  class="button-services-responsive" style="text-decoration:none;">
                  <p class="text-a">Información para tu visita</p>
                </a>
              </div>
              <div class="container px-0">
                <div class=" my-3 row m-0">
                  <div class="col-12 pl-0 d-flex justify-content-center align-items-center">

                    <div class="d-flex  justify-content-center align-items-center mx-2">
                      <a class="btn places__btn p-1 d-flex align-items-center justify-content-center"
                        style="height: 40px; width: 40px;background-color: #164074;" href="tel:+525551693080"
                        (click)="sendTagCall(markerSelected.title)"><img src="assets/map/phone_white_.png" width="25px"
                          height="25px" alt=""></a>
                    </div>
                    <div class="flex-column d-flex  justify-content-center align-items-center mx-2 ">
                      <a class="btn places__btn p-1  d-flex align-items-center justify-content-center"
                        style="height: 40px; width: 40px; background-color: #164074;" href="mailto:hola@axakeralty.mx"
                        target="_top" (click)="sendTagMail(markerSelected.title)"><img src="assets/map/mail_white_.png"
                          width="25px" height="23px" alt=""></a>
                    </div>
                    <div class="flex-column d-flex  justify-content-center align-items-center mx-2 ">
                      <a [href]="item?.location_waze" (click)="sendTagMaps(item.title)" target="_blank"
                        class=" p-1"><img src="assets/map/Waze_mobile.png" width="40px" height="40px" alt=""></a>
                    </div>
                    <div class="flex-column d-flex  justify-content-center align-items-center mx-1 ">
                      <a (click)="sendTagMaps(item.title);sendToMap(item.location)" target="_blank" class=" p-1"><img
                          src="assets/map/Maps_mobile.png" width="40px" height="40px" alt=""></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="item.expanded" class="mt-3 ">
              <div class="d-flex justify-content-center mb-3" style="padding-left: 30px; padding-right: 30px;">
                <div *ngIf="markerSelected.hasClarification ===  true ">
                  <p class="card-text text-info-mobile" style="font-style: italic; text-align: center;"><sup>*</sup><sup
                      *ngIf="markerSelected.doubleAsterisk">*</sup>{{markerSelected.clarification}} </p>
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <img [src]="markerSelected.img" class="card-img-top mt-3" style="height: 195px;" alt="">
              </div>

              <div class="d-flex mb-3"
                *ngIf="(markerSelected.title !== 'Hermosillo' || (markerSelected.title === 'Hermosillo' && markerSelected.square==='Clínica Plaza Boulevard')) && markerSelected.square!=='Centro Médico Premier' && markerSelected.square!=='Star Médica Aguascalientes*' && markerSelected.square!=='Star Médica San Luis Potosí*' && markerSelected.square !== 'Hospital H+' && ( markerSelected.square !=='Hospital Terranova' ) && markerSelected.title !== 'Mexicali'  && markerSelected.title !== 'Ciudad Juárez' && markerSelected.title !== 'León' && markerSelected.title !=='Aranda de la Parra' && markerSelected.title !=='Aranda de la Parra – Puerto Interior' && markerSelected.title !== 'Saltillo' && markerSelected.title !=='Chihuahua' && markerSelected.title !== 'Torreón' && markerSelected.title !== 'Mérida' && markerSelected.square !=='Sanatorio Florencia*' && markerSelected.square !=='CHRISTUS MUGUERZA Centro de Medicina Ambulatoria Juventud' && (markerSelected.square !== 'Christus Muguerza Betania') && markerSelected.square !=='Christus Muguerza UPAEP' && markerSelected.square !=='Star Médica' && (markerSelected.hasResponsibleHealth!==false ) ">


              </div>
              <div class="d-flex mb-3">
                <div>
                  <!--   <img class="mr-1" src="assets/map/estacionamiento_no_.png" width="21px" height="15px" alt="iconMap" *ngIf="markerSelected.title === 'Roma' || markerSelected.title === 'León'">  -->
                  <!-- <img class="mr-1" src="assets/map/estacionamiento_.png" width="21px" height="15px" alt="iconMap" *ngIf="markerSelected.title !== 'Roma' && markerSelected.title !== 'León' && markerSelected.title !== 'Toluca' && markerSelected.title !== 'Otzacatipan' && markerSelected.title !== 'Metepec'">   -->
                </div>
                <!--p class="ml-2 card-text text-info-mobile" *ngIf="markerSelected.title === 'Monterrey'">
            <span>El hospital cuenta con estacionamiento independiente a AXA Keralty.</span> <br>
            <span>
              La entrada es por Cuauhtémoc.
            </span>
                      </p-->


              </div>
              <div
                *ngIf="((markerSelected.title === 'Hermosillo' && markerSelected.square!=='Clínica Plaza Boulevard') || markerSelected.square === 'Hospital H+' || item.title === 'Mexicali' || item.title === 'Ciudad Juárez' || item.title === 'León' || markerSelected.title === 'Saltillo' || markerSelected.title === 'Torreón' || markerSelected.title === 'Mérida' || markerSelected.square ==='Sanatorio Florencia*' || markerSelected.square === 'Star Médica San Luis Potosí*'  || markerSelected.square === 'Christus Muguerza Betania'  || markerSelected.square === 'Christus Muguerza UPAEP' || markerSelected.square === 'Star Médica Aguascalientes*' || markerSelected.square === 'Christus Muguerza Hospital Reynosa*' || item.square === 'Hospital Terranova') && (markerSelected.hasClarification !==  true)">
                <p class="card-text text-info-mobile" style="font-style: italic;"><sup>*</sup>Proveedores externos en
                  convenio que brindan el servicio. </p>
              </div>


              <!-- <div class="container px-0">
      <div class=" my-3 row m-0">
        <div class="col-12 pl-0 d-flex justify-content-center align-items-center">
      
        <div class="flex-column d-flex  justify-content-center align-items-center mr-2">
          <a class="btn places__btn p-1 d-flex align-items-center justify-content-center"  style="height: 55px; width: 55px;background-color: #164074;"  href="tel:+525551693080" (click)="sendTagCall(markerSelected.title)" ><img src="assets/map/phone_white_.png" width="25px" height="25px" alt=""></a>
          <p style="font-size: 9px; color: #254C7D; font-style: italic;font-weight: normal;">
            Llámanos
          </p>
      
        </div>
        <div class="flex-column d-flex  justify-content-center align-items-center ">
          <a class="btn places__btn p-1  d-flex align-items-center justify-content-center" style="height: 55px; width: 55px; background-color: #164074;" href="mailto:hola@axakeralty.mx" target="_top" (click)="sendTagMail(markerSelected.title)" ><img src="assets/map/mail_white_.png" width="25px" height="23px" alt=""></a>
          <p style="font-size: 9px; color: #254C7D; font-style: italic; font-weight: normal;">  Escríbenos </p>

        </div>
        
        </div>
      </div>
    </div> -->
            </div>
          </li>
          <div class="line-text"></div>
          <div class="px-2" *ngFor="let description of getDescriptions(); ">
            <p class="text-responsable m-0 text-left mt-2" style="font-style: italic;" [innerHtml]="description"></p>
          </div>
        </ul>
      </div>
      <div class="col-12 col-md-3 mx-auto" *ngIf="!isMobile">
        <p class="ciudad-titulo mb-0 mt-4 pl-2">{{activeCity}}:</p>
        <ul class="list-group mx-auto" *ngIf="!showCard">
          <li class="list-group-item px-2" *ngFor="let item of markers; index as i" (click)="showCardFunction(item)"
            [ngClass]="i !== 12 ? 'border-map' : ''">
            {{item.title}} <br>
            <p class="text-responsable m-0 mt-2" style="font-weight: bold;" *ngIf="item.square">{{item.square}}<sup
                *ngIf="item.title === 'Mexicali' || item.title === 'Ciudad Juárez' || item.title === 'León' || item.asterisk ">*</sup>
              <sup *ngIf="item.doubleAsterisk">*</sup><sup *ngIf="item.doubleAsterisk">*</sup>:</p>
            <p class="text-responsable m-0">{{item.description}}</p>
            <div>
              <button class="buttton-info mt-2" (click)="showCardFunction(item)">Más información</button>

              <span *ngIf="item.laboratoryIcon" class="axa-icon axa-microscope spn-microscope">
                <span class="path1"></span>
                <span class="path2"></span>
              </span>
            </div>
          </li>
          <div class="px-2"
            *ngIf="activeCity !== 'Jalisco' && activeCity !== 'San Luis Potosí' && activeCity !=='Sinaloa' && activeCity !== 'Aguascalientes' && activeCity !== 'Yucatán' && activeCity !== 'Michoacán'  && activeCity !== 'Tamaulipas' && activeCity !== 'Sonora' && activeCity !== 'Querétaro' && activeCity !== 'Puebla' && activeCity !== 'Coahuila' && activeCity !=='Estado de México' && activeCity !== 'Ciudad de México' && activeCity !=='Baja California' && activeCity !== 'Nuevo León' && activeCity!=='Veracruz' && activeCity!=='Chihuahua' && activeCity!=='Guanajuato' || markerSelected.title === 'Saltillo' || markerSelected.title === 'Mérida' || markerSelected.square === 'Clínica Plaza San Ángel'">
            <p class="text-responsable m-0 text-left" style="font-style: italic;"><sup>*</sup>Proveedores externos en
              convenio que brindan el servicio.</p>
          </div>

          <div class="px-2" *ngFor="let description of getDescriptions(); ">
            <p class="text-responsable m-0 text-left" style="font-style: italic;" [innerHtml]="description"></p>
          </div>
        </ul>
        <div *ngIf="showCard" class="card places">
          <div class="d-flex justify-content-center mt-3">

            <div class="card-body">
              <h5 class="card-text-map places__title">{{markerSelected.title}}</h5>
              <div class="places__close position-absolute text-right">
                <a (click)="closeCardFunction()" class="d-inline-block mt-2 mr-3 pointer"><span
                    class="icon-Cerrar"></span></a>
              </div>
              <div class="d-flex mt-3">
                <div class="d-flex justify-content-center" style="min-width: 24px;">
                  <img class="mr-1" src="assets/map/pin_locator_white_.png" width="13px" height="17px" alt="iconMap">
                </div>
                <p class="ml-2 card-text places__text card-text-map"><strong>{{markerSelected.square}}<sup
                      *ngIf=" markerSelected.title === 'Chihuahua' || markerSelected.title === 'Mexicali' || markerSelected.title === 'Ciudad Juárez' || markerSelected.title === 'Aranda de la Parra' || markerSelected.title === 'Aranda de la Parra – Puerto Interior' || markerSelected.asterisk">*</sup>:<sup
                      *ngIf="markerSelected.doubleAsterisk">*</sup><sup *ngIf="markerSelected.doubleAsterisk">*</sup>
                  </strong> {{markerSelected.description}} <br><span
                    style="font-style: italic;">{{markerSelected.help_information}}</span> </p>
              </div>
              <div class="d-flex mb-2 mt-2" *ngIf="markerSelected.onlyLaboratory">
                <div class="d-flex justify-content-center" style="min-width: 24px;">
                  <span class="axa-icon axa-lab c-white top: 3px;"></span>
                </div>
                <p class="ml-2 card-text places__text card-text-map">En esta sucursal solo se realizan laboratorios y
                  estudios
                  de gabinete <br>
                </p>
              </div>
              <div class="d-flex mb-3 mt-2">

                <div class="d-flex justify-content-center" style="min-width: 24px;">
                  <img class="mr-1" src="assets/map/phone_white_.png" width="20px" height="20px" alt="iconMap"
                    *ngIf="markerSelected.title === 'Toluca' || markerSelected.title === 'Otzacatipan' || markerSelected.title === 'Metepec'">
                  <img class="mr-1" src="assets/map/clock_white_.png" width="15px" height="15px" alt="iconMap"
                    *ngIf="markerSelected.title !== 'Toluca' && markerSelected.title !== 'Otzacatipan' && markerSelected.title !== 'Metepec' ">
                </div>
                <p class="ml-2 card-text places__text card-text-map" [innerHTML]="markerSelected.hour">
                  <strong>Horarios:</strong> <br>
                </p>
              </div>
              <div class="d-flex mb-3">
                <div class="d-flex justify-content-center" style="min-width: 24px;">
                  <img class="mr-1" src="assets/map/parking_no_white_.png" width="21px" height="15px" alt="iconMap"
                    *ngIf="markerSelected.title === 'Roma' || markerSelected.title === 'León'">
                  <img class="mr-1" src="assets/map/parking_ok_.png" width="21px" height="15px" alt="iconMap"
                    *ngIf="markerSelected.title !== 'Roma' && markerSelected.title !== 'León' && markerSelected.title !== 'Toluca' && markerSelected.title !== 'Otzacatipan' && markerSelected.title !== 'Metepec' ">
                </div>
                <p class="ml-2 card-text places__text card-text-map">{{markerSelected.parking}} <br
                    *ngIf="markerSelected.parking_description"> <span *ngIf="markerSelected.parking_description"
                    style="font-style: italic;">{{markerSelected.parking_description}}</span>
                  <a *ngIf="markerSelected.title === 'Roma'" [href]="ConstParking"
                    style="color: #FFFFFF; text-decoration: underline;font-weight: 600;" target="_blank">aquí</a>

                </p>
              </div>
              <div class="d-flex mb-1"
                *ngIf="(markerSelected.title !== 'Hermosillo' || (markerSelected.title === 'Hermosillo' && markerSelected.square==='Clínica Plaza Boulevard')) && markerSelected.square!=='Centro Médico Premier' && markerSelected.square!='Hospital Aranda de la Parra* – Puerto Interior' && markerSelected.square!='Star Médica Aguascalientes*' && markerSelected.square!=='Star Médica San Luis Potosí*' && markerSelected.square !== 'Hospital H+' && ( markerSelected.square !=='Hospital Terranova' ) && markerSelected.title !== 'Mexicali' && markerSelected.title !== 'Ciudad Juárez' && markerSelected.title !== 'León' && markerSelected.title !=='Aranda de la Parra' && markerSelected.title !=='Aranda de la Parra – Puerto Interior' && markerSelected.title !== 'Saltillo' && markerSelected.title !== 'Torreón' && markerSelected.title !== 'Mérida' && markerSelected.square !=='Sanatorio Florencia*' && markerSelected.square !=='CHRISTUS MUGUERZA Centro de Medicina Ambulatoria Juventud' && (markerSelected.square !== 'Christus Muguerza Betania') && markerSelected.square !=='Christus Muguerza UPAEP' && markerSelected.hasResponsibleHealth!==false">
                <div class="d-flex justify-content-center" style="min-width: 24px;">
                  <span class="axa-icon axa-man" style="color: #FFFFFF;"></span>
                </div>
                <p class="ml-2 card-text places__text card-text-map"><strong>Responsable sanitario:</strong> <br>
                  {{markerSelected.responsable_name}} <br> {{markerSelected.responsable_education}} <br>
                  {{markerSelected.responsable_number}}</p>
              </div>
              <div
                *ngIf="((markerSelected.title === 'Hermosillo' && markerSelected.square!=='Clínica Plaza Boulevard') || markerSelected.square === 'Hospital H+' || markerSelected.title === 'Mexicali' || markerSelected.title === 'Ciudad Juárez' || markerSelected.title === 'Aranda de la Parra – Puerto Interior' || markerSelected.title === 'Aranda de la Parra' || markerSelected.title === 'Saltillo' || markerSelected.title === 'Torreón'  || markerSelected.title === 'Mérida' || markerSelected.square ==='Sanatorio Florencia*' || markerSelected.square === 'Star Médica San Luis Potosí*' || markerSelected.square === 'Christus Muguerza Betania' || markerSelected.square === 'Christus Muguerza UPAEP' || markerSelected.square === 'Christus Muguerza UPAEP'  || markerSelected.square === 'Star Médica Aguascalientes*' || markerSelected.square === 'Hospital Terranova') && (markerSelected.hasClarification !==  true) ">
                <p class="card-text places__text card-text-map" style="font-style: italic; font-size: 11px;">
                  <sup>*</sup>Proveedores externos en convenio que brindan el servicio.
                </p>
              </div>

              <!-- <div *ngIf="markerSelected.hasClarification">
        <p class="card-text places__text card-text-map" style="font-style: italic; font-size: 11px;"><sup>*</sup><sup *ngIf="markerSelected.doubleAsterisk">*</sup>{{markerSelected.clarification}} </p>
        </div> -->
            </div>

          </div>


          <!-- Boton servicios -->
          <div class="d-flex justify-content-center mt-2">
            <button *ngIf="markerSelected.buttons2_information" class="button-services" (click)="information()"
              type="button">
              Consulta de servicios
            </button>
          </div>

          <!-- Boton visita -->
          <div class="d-flex justify-content-center ">
            <!-- <button *ngIf="markerSelected.buttons_information" class="button-services" type="button">
              Información para tu visita
            </button> -->
            <a [href]="markerSelected.url_button" target="_blank" *ngIf="markerSelected.buttons_information"
              class="button-services" style="text-decoration:none;">
              <p class="text-a">Información para tu visita</p>
            </a>
          </div>


          <div class="d-flex my-2" style="padding-left: 55px;">
            <a class="btn places__btn mr-2 p-1 d-flex align-items-center justify-content-center mail-button"
              href="mailto:hola@axakeralty.mx" target="_top" (click)="sendTagMail(markerSelected.title)"><img
                src="assets/map/mail_.png" class="" width="20px" height="18px" alt=""></a><span
              class="text-link mail-hover my-auto">Escríbenos</span>
            <a class="btn places__btn mr-2 p-1 d-flex align-items-center justify-content-center phone-button"
              href="tel:+525551693080" (click)="sendTagCall(markerSelected.title)"><img src="assets/map/phone_.png"
                class="" width="20px" height="20px" alt=""></a><span class="text-link phone-hover">Llámanos: <br>
              (55) 5169 3080</span>
            <a class="btn places__btn mr-2 p-1 d-flex align-items-center justify-content-center map-button"
              (click)="sendTagMaps(markerSelected.title);sendToMap(markerSelected.location)" target="_blank"><img
                src="assets/map/Maps_mobile.png" class="" width="40px" height="40px" style="border-radius: 7px;"
                alt=""></a><span class="text-link map-hover my-auto">Google maps</span>
          </div>
          <div class="d-flex justify-content-center" style="padding-left: 17px; padding-right: 17px;">
            <div *ngIf="markerSelected.hasClarification">
              <p class="card-text places__text card-text-map"
                style="font-style: italic; font-size: 11px; text-align: center;"><sup>*</sup><sup
                  *ngIf="markerSelected.doubleAsterisk">*</sup>{{markerSelected.clarification}} </p>
            </div>
          </div>


          <!-- <div class="d-flex justify-content-center position-relative" style="padding-left: 60px; padding-right: 60px;">
                  <div *ngIf="markerSelected.hasClarification">
                    <p class="card-text places__text card-text-map" style="font-style: italic; font-size: 11px; text-align: center;"><sup>*</sup><sup *ngIf="markerSelected.doubleAsterisk">*</sup>{{markerSelected.clarification}} </p>
                  </div>
                </div> -->


          <img [src]="markerSelected.img" class="card-img-top position-relative" alt="" style="top: 8px;">
        </div>
      </div>
      <div class="col-12 col-md-9 map-container" *ngIf="!isMobile">

        <div class="map mr-0 mr-lg-5 mask5" [ngClass]="showCard ? isMobile ? 'd-none' : '' : ''">
          <svg height="0" width="0">
            <defs>
              <clipPath id="svgPath">
                <path
                  d="M781.52,62.41c-59.36-12.39-233-13.38-382-15L384,47.23V0h-.1V47.23c-262.37-3-313.63-6.88-359.37,10.18-24.31,9.06-27,154-23,229s25,121,43,145,197,38,374,32,359-19,375-56,16-90,16-160S805.05,67.32,781.52,62.41Z">
                </path>
              </clipPath>
            </defs>
          </svg>
          <svg height="0" width="0">
            <defs>
              <clipPath id="svgPathMobile">
                <path
                  d="M244.08,25.49c14,5.89,16.35,259.41,3.37,351.69-9.7,68.95-211.64,72.21-228.08,31C-4.32,348.8-5.93,52.42,12.62,19,20.11,5.47,138.26-19,244.08,25.49Z">
                </path>
              </clipPath>
            </defs>
          </svg>
          <svg height="0" width="0">
            <defs>
              <clipPath id="svgPathTablet">
                <path
                  d="M467.18,34.28c26.82,7.92,31.3,348.86,6.46,473C455.08,600,68.54,604.35,37.07,548.94-8.28,469.08-11.34,70.49,24.16,25.5,38.49,7.35,264.63-25.53,467.18,34.28Z">
                </path>
              </clipPath>
            </defs>
          </svg>
          <google-map class="agm-map" *ngIf="this.isBrowser" [center]="{lat: lat, lng: lng}" [zoom]="zoom"
            (mapReady)="onMapReady($event)" [options]="{styles: styles}" #map>
            <map-marker (mouseOver)="window.open()" (mouseOut)="window.close()" *ngFor="let marker of markers"
              [position]="{lat: marker.latitude, lng: marker.longitude, iconGeneral: iconGeneral}">
              <map-info-window class="tooltip" #window>
                <h6 class="location-name">{{marker.title}} </h6>
              </map-info-window>
            </map-marker>
          </google-map>
          <img src="assets/map/zoom_in_.png" class="buttom-cam-in" (click)="zoomIn('normal')" alt="">
          <img src="assets/map/zoom_out_.png" class="buttom-cam-out" (click)="zoomOut('normal')" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
</div>
<div class="container mt-5 carousel-space">
  <div id="carouselExampleControls2" class="carousel slide d-block d-lg-none px-3" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item mobile active">
        <div class="row">
          <div class="col-6 pr-1"><img class="d-block img-mobile ml-auto" src="assets/visitus/_EK_0533-ok.jpg"
              alt="Instalaciones limpias y seguras con atención personalizada en Centro de Atención Médica Plaza Sentura">
          </div>
          <div class="col-6 pl-1"><img class="d-block img-mobile" src="assets/visitus/_EK_1507-ok.jpg"
              alt="Sala de espera con juegos para niños en área de pediatría de Planmed"></div>
          <div class="col-6 pr-1 mt-2"><img class="d-block img-mobile ml-auto" src="assets/visitus/_EK_1569-ok.jpg"
              alt="Consultorios equipados en CAM Sentura"></div>
          <div class="col-6 mt-2 pl-1"><img class="d-block img-mobile" src="assets/visitus/_EK_1707-ok.jpg"
              alt="Consultorios de especialidades de alta calidad en AXA Keralty"></div>
        </div>
      </div>
      <div class="carousel-item mobile">
        <div class="row">
          <div class="col-6 pr-1"><img class="d-block img-mobile ml-auto" src="assets/visitus/_EK_2175-ok.jpg"
              alt="Sala de espera cómodas en Centros de Atención Médica de AXA Keralty"></div>
          <div class="col-6 pl-1"><img class="d-block img-mobile" src="assets/visitus/_EK_2593-ok.jpg"
              alt='Vinil en la pared con la leyenda "Nuestro compromiso es cuidarte para que disfrutes la vida en Planmed®"'>
          </div>
          <div class="col-6 pr-1 mt-2"><img class="d-block img-mobile ml-auto" src="assets/visitus/_EK_3183_1-ok.jpg"
              alt="Vinil autoadherible decorativo en pasillo de Centro de Atención Médica de AXA Keralty"></div>
          <div class="col-6 mt-2 pl-1"><img class="d-block img-mobile" src="assets/visitus/_EK_3273-ok.jpg"
              alt="Consultorio pediádrico en AXA Keralty"></div>
        </div>
      </div>
      <div class="carousel-item mobile">
        <div class="row">
          <div class="col-6 pr-1"><img class="d-block img-mobile ml-auto" src="assets/visitus/_EK_9705-ok.jpg"
              alt="Vinil adherible con forma de mono en una selva para recibir a los pacientes más pequeños en clínica de AXA Keralty">
          </div>
          <div class="col-6 pl-1"><img class="d-block img-mobile" src="assets/visitus/_EK_3938_1-ok.jpg"
              alt="Interior del Centro de Atención Médica Roma con acceso a consultorios pediátricos"></div>
          <div class="col-6 pr-1 mt-2"><img class="d-block img-mobile ml-auto" src="assets/visitus/_EK_4042-ok.jpg"
              alt=" Pasilllo CAM Roma de AXA Keralty"></div>
          <div class="col-6 mt-2 pl-1"><img class="d-block img-mobile " src="assets/visitus/_EK_5159-ok.jpg"
              alt="Instalaciones de vanguardia para atención médica especializada en la contratación de tu Planmed®">
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <div class="col-xl-10 mx-auto" id="mapa-aqui">
    <ngx-slick-carousel class="carousel d-none d-lg-block visit-us-carousel" #slickModal="slick-carousel"
      [config]="slideConfig">
      <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <img src="{{ slide.img }}" alt="{{slide.alt}} sanitario" class="img-slide">
      </div>
    </ngx-slick-carousel>
  </div>
  <div class="row">
    <div class="col-12 text-center mt-4">
      <img src="assets/map/cierre_big_.png" class="img-cierre" alt="">
    </div>
  </div>
</div>