import { Router } from '@angular/router';
import { IEventGTM } from './../../../interfaces/gtmEvent.interface';
/** import Angular Component, OnInit */
import { Component, OnInit } from '@angular/core';
/** import urls.constants*/
import { urlsLoc } from 'src/app/constants/urls.constants';
/** import benefits.constants*/
import { BENEFITS } from 'src/app/constants/benefits.constants';
import { DeviceDetectorService } from 'ngx-device-detector';
/**
 * BenefitsComponent
 */
@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit {
  /** Declaración de variable selected utilizado para el tab de beneficios */
  selected: boolean = true;
  /** Declaración de arreglo beneficios */
  beneficios = [];

  /** Variable que identifica el tipo de plan seleccionado */
  planSelected: string ;
  /** Url del servidor actual */
  urlServer: string = '';
  /**
   * folderDataset
   * Declaracion del Arreglo de objectos folderDataset
   */
  folderDataset = [
    {img: 'ak-icon icon-health', txt: 'Chequeo médico inicial', superi: undefined},
    {img: 'ak-icon icon-lugage', txt: 'Consultas de atención primaria ilimitadas', superi: '1'},
    {img: 'ak-icon icon-call-emergency', txt: 'Orientación médica telefónica y domiciliaria 24/7', superi: '2'},
    {img: 'ak-icon icon-ambulance-2', txt: 'Servicio de ambulancia por emergencia', superi: '3'},
    {img: 'ak-icon icon-heartbeat', txt: 'Atención inmediata', superi: undefined},
  ];

  /**
   * folderDataset2
   * Declaración del Arreglo de objectos folderDataset2
   */
  folderDataset2 = [
    {img: 'ak-icon icon-nurse', txt: 'Consultas médicas de especialidad', superi: '4,5'},
    {img: 'ak-icon icon-mircroscope', txt: 'Estudios de laboratorio', superi: '5'},
    {img: 'ak-icon icon-lungs', txt: 'Terapias físicas, inhaloterapia y oxigenoterapia', superi: '5'},
  ];

  /**
   * Items
   */
  adds = ['1 Medicina general, pediatría y ginecología.',
    '2 El costo de la atención médica domiciliaria es de hasta $300 por evento.',
    '3 Dos servicios incluidos sin costo al año. Nuestros médicos coordinarán el envío en caso de ser necesario.',
    '4 Medicina interna, psicología, nutrición, psiquiatría, consulta externa de cirugía general, ortopedia, otorrinolaringología, urología y dermatología.',
    '5 Con referencia médica.',
  ];

  clientID: string;

  /** constructor*/
  constructor(private deviceService: DeviceDetectorService, private router: Router) {
    this.urlServer = 'https://preprod.axakeralty.mx';
  }

  /**
   * ngOnInit
   * inicialización de beneficios
   */
  ngOnInit() {
    this.planSelected = 'intermedio';
    this.beneficios = BENEFITS;
    this.replaceScript();
  }

  /**
   * addToCartEvent
   * Metodo para agrear al carrito
   */
  addToCartEvent() {

    /** Envío de evento a dataLayer para taggeo */
    let Egtm:IEventGTM;

    if(this.planSelected === 'esencial') {
      Egtm = {
        event: 'eventgtm',
        eventCategory: 'homeButtons',
        eventAction: 'homePage_GoCotizamedEsencial',
        eventLabel: 'btn_homePage_goToCotizamed'
      }

      window.dataLayer.push(Egtm);
    } else {
      Egtm = {
        event: 'eventgtm',
        eventCategory: 'homeButtons',
        eventAction: 'homePage_GoCotizamedIntermedio',
        eventLabel: 'btn_homePage_goToCotizamed'
      }

      window.dataLayer.push(Egtm);
    }

    location.href = this.urlServer + urlsLoc.cotizador;
  }

  /**
   * Método que envía evento a dataLayer
   * de acuerdo con el plan seleccionado
   * @param {string} plan
   * @memberof BenefitsComponent
   */
  sendTag(plan: string) {
    this.planSelected = plan;
    let eventGtm: IEventGTM;

    if (this.planSelected === 'esencial') {
      eventGtm = {
        event: 'eventgtm',
        eventCategory: 'pricingButtons',
        eventAction: 'Planmed Esencial',
        eventLabel: 'btn_pricing_pesencial'
      }

      window.dataLayer.push(eventGtm);
    } else {
      eventGtm = {
        event: 'eventgtm',
        eventCategory: 'pricingButtons',
        eventAction: 'Planmed Intermedio',
        eventLabel: 'btn_pricing_pintermedio'
      }

      window.dataLayer.push(eventGtm);
    }
  }

  replaceScript() {
    try{
      if(document.cookie.indexOf('_ga=')>-1){
          const gaCookie = document.cookie.split('_ga=')[1].split(';')[0].split('.');
          this.clientID = gaCookie[2] + '.' + gaCookie[3];
      }
    } catch(e){
      return 'Error al obtener el id de Google Analytics';
    }

    if (this.router.url === '/get-plan'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        login: 'no',
        clientId: `${this.clientID}`,
        pageType: 'home',
        pageCategory: `${this.router.url}`,
        pageURLCategory: `${window.location.href}`,
        device: `${this.getDevice()}`,
        numeroCotizacion: 'NA',
        folioPago: 'NA',
        numeroSolicitud: 'NA',
        numeroPoliza: 'NA'
      });
    }
  }

  getDevice() {
    if(this.deviceService.isDesktop()) {
       return 'desktop';
    } else if(this.deviceService.isMobile()) {
      return 'mobile';
    } else if(this.deviceService.isTablet()) {
      return 'tablet';
    } else {
      return 'other';
    }
  }
}
