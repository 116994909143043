/** import angular core */
import { Component, OnInit } from '@angular/core';
/**
 * Component FloatCovidComponent
 */
@Component({
  selector: 'app-float-covid',
  templateUrl: './float-covid.component.html',
  styleUrls: ['./float-covid.component.scss']
})
export class FloatCovidComponent implements OnInit {

  /** constructor */
  constructor() { }

  /** inicio ngOnInit */
  ngOnInit(): void {
  }

}
