<div class="container-xl">
    <div class="row justify-content-center py-4">
        <span class="cancel-policy__titleBlue">
            Cancelación
        </span>
    </div>
    <div class="row justify-content-center ">
        <div class="col-md-8 col-lg-7 col-12 px-4">
            <p class="cancel-policy__txt">La vigencia del Contrato concluirá en la fecha de terminación indicada en la
                Carátula de Póliza o antes si se presenta el siguiente evento:</p>
            <p class="cancel-policy__txt--blue">Cancelación a solicitud del Contratante.</p>
            <p class="cancel-policy__txt">Si el motivo de la cancelación es por fallecimiento o cambio de residencia del
                Asegurado titular, la companía devolverá el 70% de la prima no devengada, en los demás casos no habrá
                devolución de prima.</p>
        </div>
    </div>
        <div class="row my-5 justify-content-center">
            <div class="mb-2 col-12 text-center">
                <button class="cancel-policy__bttn" [routerLink]="['/home']">Aceptar</button>
            </div>
        </div>
</div>
