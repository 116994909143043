import { Component, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { IEventGTM } from 'src/app/interfaces/gtmEvent.interface';

/**
 * Modal General para presentar información
 * @export
 * @class ModalGeneralComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-modal-general',
  templateUrl: './modal-general.component.html',
  styleUrls: ['./modal-general.component.scss']
})
export class ModalGeneralComponent {
  /** Icono a presentar arriba de título */
  modalIcon: string;
  /** Titulo de modal */
  modalTitle: string;
  /** Información a presentar en modal */
  modalMessage: string;
  /** Suscripción para cerrar modal */
  button: any | EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Texto en botón de modal */
  btnText = 'Aceptar';

  /**
   * Acción de botón
   * @memberof ModalGeneralComponent
   */
  actionButton() {
    const gtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'modalButtons',
      eventAction: 'acept',
      eventLabel: 'btn_modal_acept'
    }

    window.dataLayer.push(gtm);

    this.button?.next(true);
  }
}
