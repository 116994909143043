/** Variables tels */
const tels = '+52 (55) 5169-3080';
/** Variables hour */
const hour = `Lunes a Viernes de 07:00 a 20:00 hrs.<br>Sábados de 08:00 a 14:00 hrs.`;
/** Variables  email*/
const email = 'contacto@axakeralty.mx';

/**
 * Constantes para mapa de google en la sección de contacto
 */
export const MAP = {
  /**
   * Personalizar estilos del mapa
   */
  CAROUSEL_OPTIONS: [

    {lat: 19.418993, long: -99.177687, zoom: 10, img: "assets/map/red-nacional-desktop/carrusel-estados/CDMX.jpg", name:"CDMX",nameDetail: 'Ciudad de México', active: false, codeCity:'CDMX'},
    {lat: 19.400865, long: -99.4304277, zoom: 11, img: "assets/map/red-nacional-desktop/carrusel-estados/EDO-MEX.jpg", name:"EDOMEX",nameDetail: 'Estado de México',active: false, codeCity:'EDOMEX'},
    {lat: 32.66527940816968, long: -116.17660034472453, zoom: 9, img: "assets/map/red-nacional-desktop/carrusel-estados/BajaCalifornia.jpg", name:"Baja California", nameDetail: 'Baja California', active: false, codeCity:'BAJA_CALIFORNIA'},
    {lat: 31.714086837489063, long: -106.41965186028588, zoom: 6, img: "assets/map/red-nacional-desktop/carrusel-estados/Chihuahua.jpg", name:"Chihuahua", nameDetail:"Chihuahua",active: false, codeCity:'CHIHUAHUA'},
    {lat: 25.655322, long: -102.10017, zoom: 6, img: "assets/map/red-nacional-desktop/carrusel-estados/coahuila.jpg", name:"Coahuila",nameDetail: 'Coahuila', active: false, codeCity:'COAHUILA'},
    {lat: 21.1347643, long: -101.6254175, zoom: 11, img: "assets/map/red-nacional-desktop/carrusel-estados/Guanajuato.jpg", name:"Guanajuato", nameDetail:"Guanajuato", active: false, codeCity:'GUANAJUATO'},
    {lat: 20.685403630143337, long: -103.38676483121571, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Jalisco.jpg", name:"Jalisco", nameDetail:"Jalisco",active: false, codeCity:'JALISCO'},
    {lat: 19.6826667, long: -101.1939679, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/estado_michoacan_desktop.jpg", name:"Michoacán", nameDetail:"Michoacán", active: false, codeCity:'MICHOACAN'},

    {lat: 25.66778569644115, long: -100.32000844344641, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Nuevo-Leon.jpg", name:"Nuevo León",nameDetail:"Nuevo León",active: false, codeCity:'NUEVO_LEON'},
    {lat: 19.030628791897815, long: -98.2283793423151, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Puebla.jpg", name:"Puebla", nameDetail:"Puebla", active: false, codeCity:'PUEBLA'},
    {lat: 20.58431543072927, long: -100.40002580688736, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Queretaro.jpg", name:"Querétaro", nameDetail:"Querétaro", active: false, codeCity:'QUERETARO'},
    {lat: 22.1515849, long: -100.9849644, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/estado_slp_desktop.png", name:"San Luis Potosí", nameDetail:"San Luis Potosí", active: false, codeCity:'SAN_LUIS'},
    {lat: 24.7429946, long: -109.6647276, zoom: 6, img: "assets/map/red-nacional-desktop/carrusel-estados/estado_Sinaloa_desktop.jpg", name:"Sinaloa", nameDetail:"Sinaloa", active: false, codeCity:'SINALOA'},
    {lat: 29.081648192106265, long: -110.95060649851261, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Sonora.jpg", name:"Sonora", nameDetail:"Sonora", active: false, codeCity:'SONORA'},
    {lat: 24.9262373, long: -100.8889474, zoom: 5, img: "assets/map/red-nacional-desktop/carrusel-estados/estado_Tamaulipas_desktop.jpg", name:"Tamaulipas", nameDetail: 'Tamaulipas', active: false, codeCity:'TAMAULIPAS'},
    {lat: 19.1719409, long: -96.1902646, zoom: 6, img: "assets/map/red-nacional-desktop/carrusel-estados/estado_veracruz_desktop.jpg", name:"Veracruz", nameDetail:"Veracruz", active: false, codeCity:'VERACRUZ'},
    {lat: 21.04390778529833, long: -89.59987538802856, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/yucatan.jpg", name:"Yucatán", nameDetail:"Yucatán", active: false, codeCity:'YUCATAN'},
    {lat: 21.8980688, long: -102.3065369, zoom: 9, img: "assets/map/red-nacional-desktop/carrusel-estados/estado_aguascalientes_desktop.png",class:"aguascalientes-wrapper", name:"Aguascalientes", nameDetail: 'Aguascalientes', active: false, codeCity:'AGUASCALIENTES'},

    {lat: 19.418993, long: -99.177687, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/CDMX.jpg", name:"CDMX",nameDetail: 'CDMX', active: false, codeCity:'CDMX'},
    {lat: 19.540274, long: -99.215217, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/EDO-MEX.jpg", name:"EDOMEX",nameDetail: 'EDOMEX',active: false, codeCity:'EDOMEX'},
    {lat: 19.418993, long: -99.177687, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/CDMX.jpg", name:"CDMX",nameDetail: 'Parques Plaza', active: false, codeCity:'CDMX'},
    {lat: 19.418993, long: -99.177687, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/CDMX.jpg", name:"CDMX",nameDetail: 'Puerta Altavista', active: false, codeCity:'CDMX'},
    {lat: 19.418993, long: -99.177687, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/CDMX.jpg", name:"CDMX",nameDetail: 'Roma', active: false, codeCity:'CDMX'},
    {lat: 19.418993, long: -99.177687, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/CDMX.jpg", name:"CDMX",nameDetail: 'Vía 515', active: false, codeCity:'CDMX'},
    {lat: 19.3046543, long: -99.165357, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/CDMX.jpg", name:"CDMX",nameDetail: 'Gran Sur', active: false, codeCity:'CDMX'},
    {lat: 19.540274, long: -99.215217, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/EDO-MEX.jpg", name:"EDOMEX",nameDetail: 'EDOMEX',active: false, codeCity:'EDOMEX'},
    //{lat: 19.4152044, long: -99.5423285, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/EDO-MEX.jpg", name:"EDOMEX",nameDetail: 'Toluca',active: false, codeCity:'EDOMEX'},
    {lat: 21.1347643, long: -101.6254175, zoom: 11, img: "assets/map/red-nacional-desktop/carrusel-estados/Guanajuato.jpg", name:"Guanajuato", nameDetail:"León", active: false, codeCity:'GUANAJUATO'},
    {lat: 32.66527940816968, long: -116.17660034472453, zoom: 9, img: "assets/map/red-nacional-desktop/carrusel-estados/BajaCalifornia.jpg", name:"Baja California", nameDetail: 'Mexicali', active: false, codeCity:'BAJA_CALIFORNIA'},
    {lat: 32.66527940816968, long: -116.17660034472453, zoom: 9, img: "assets/map/red-nacional-desktop/carrusel-estados/BajaCalifornia.jpg", name:"Baja California", nameDetail: 'Tijuana', active: false, codeCity:'BAJA_CALIFORNIA'},
    {lat: 31.714086837489063, long: -106.41965186028588, zoom: 9, img: "assets/map/red-nacional-desktop/carrusel-estados/Chihuahua.jpg", name:"Chihuahua", nameDetail:"Ciudad Juárez",active: false, codeCity:'CHIHUAHUA'},
    {lat: 20.685403630143337, long: -103.38676483121571, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Jalisco.jpg", name:"Jalisco", nameDetail:"Guadalajara",active: false, codeCity:'JALISCO'},
    {lat: 25.66778569644115, long: -100.32000844344641, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Nuevo-Leon.jpg", name:"Nuevo León",nameDetail:"Monterrey",active: false, codeCity:'NUEVO_LEON'},
    {lat: 24.7429946, long: -109.6647276, zoom: 6, img: "assets/map/red-nacional-desktop/carrusel-estados/estado_Sinaloa_desktop.jpg", name:"Sinaloa", nameDetail:"Culiacán", active: false, codeCity:'SINALOA'},
    {lat: 29.081648192106265, long: -110.95060649851261, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Sonora.jpg", name:"Sonora", nameDetail:"Hermosillo", active: false, codeCity:'SONORA'},
    {lat: 24.9262373, long: -100.8889474, zoom: 5, img: "assets/map/red-nacional-desktop/carrusel-estados/estado_Tamaulipas_desktop.jpg", name:"Tamaulipas", nameDetail: 'Reynosa', active: false, codeCity:'TAMAULIPAS'},
    {lat: 28.6562672, long: -106.1381368, zoom: 9, img: "assets/map/red-nacional-desktop/carrusel-estados/Chihuahua.jpg", name:"Chihuahua", nameDetail:"Chihuahua", active: false, codeCity:'CHIHUAHUA'},

    {lat: 19.030628791897815, long: -98.2283793423151, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Puebla.jpg", name:"Puebla", nameDetail:"Puebla", active: false, codeCity:'PUEBLA'},
    {lat: 19.030628791897815, long: -98.2283793423151, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Puebla.jpg", name:"Puebla", nameDetail:"Puebla", active: false, codeCity:'PUEBLA'},
    {lat: 25.655322, long: -102.10017, zoom: 6, img: "assets/map/red-nacional-desktop/carrusel-estados/coahuila.jpg", name:"COAHUILA",nameDetail: 'Saltillo', active: false, codeCity:'COAHUILA'},
    {lat: 25.655322, long: -102.10017, zoom: 6, img: "assets/map/red-nacional-desktop/carrusel-estados/coahuila.jpg", name:"COAHUILA",nameDetail: 'Torreón', active: false, codeCity:'COAHUILA'},
    {lat: 25.655322, long: -102.10017, zoom: 6, img: "assets/map/red-nacional-desktop/carrusel-estados/coahuila.jpg", name:"COAHUILA",nameDetail: 'Torreón', active: false, codeCity:'COAHUILA'},
    {lat: 21.04390778529833, long: -89.59987538802856, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/yucatan.jpg", name:"YUCATAN", nameDetail:"Mérida", active: false, codeCity:'YUCATAN'},
    {lat: 22.1515849, long: -100.9849644, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/estado_slp_desktop.png", name:"San Luis Potosí", nameDetail:"San Luis Potosí", active: false, codeCity:'SAN_LUIS'},
    {lat: 20.58431543072927, long: -100.40002580688736, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/Queretaro.jpg", name:"Querétaro", nameDetail:"Querétaro", active: false, codeCity:'QUERETARO'},
    {lat: 19.6826667, long: -101.1939679, zoom: 12, img: "assets/map/red-nacional-desktop/carrusel-estados/estado_michoacan_desktop.jpg", name:"Michoacán", nameDetail:"Morelia", active: false, codeCity:'MICHOACAN'},
    
  ],
  STYLES: [
    {featureType: 'all', elementType: 'geometry', stylers: [{color: '#E2E2E2'}]},
    /** Caminos */
    {featureType: 'road', elementType: 'geometry', stylers: [{color: '#ffffff'}]},
    /** Contorno de calles */
    {featureType: 'road', elementType: 'geometry.stroke', stylers: [{color: '#e8e8e8'}]},
    {featureType: 'road', elementType: 'labels.text.fill', stylers: [{color: '#9ca5b3'}]},
    /** Carreteras */
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{color: '#DEDEDE'}]
    },
    /** Contorno de carretera */
    {featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{color: '#D2D2D2'}]},
    {featureType: 'road.highway', elementType: 'labels.text.fill', stylers: [{color: '#8D8D8D'}]},
    /** Agua */
    {featureType: 'water', elementType: 'geometry', stylers: [{color: '#D0CFD0'}]},
    {elementType: 'labels.icon', stylers: [{visibility: 'off'}]}
  ],
  /**
   * Ubicaciónes a implementar dentro del mapa
   */
  MARKERS_RED: {
    CDMX: {descriptions:[],
      cams: [
      {
        id_number: 0,
        latitude:19.438993,
        longitude:-99.177687,
        title: 'Ciudad de México',
        parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
        square: 'Clínica Parques Plaza',
        description: `Parques Plaza Nuevo Polanco. Lago Alberto 442, Anáhuac I Secc, Alcaldía Miguel Hidalgo, CP 11320, CDMX.`,
        help_information: 'Dentro de Parques Plaza, planta baja, entrada principal sobre Bahía de San Hipólito',
        location: 'https://goo.gl/maps/fteTFneTCa528BKB7',
        location_apple: 'maps://goo.gl/maps/fteTFneTCa528BKB7',
        location_waze: 'https://ul.waze.com/ul?place=ChIJoRLNwrH40YUR6DC4QX6cFow&ll=19.43879590%2C-99.17805180&navigate=yes',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Domingo de 9:00 a 15:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Parques-Plaza.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Parques Plaza',
        responsable_name: 'Carlos Alberto López García',
        responsable_education: "Universidad Michoacana de San Nicolás de Hidalgo",
        responsable_number: "Cédula profesional no. 9696628",
        closed:'',
        buttons2_information: true,
        expanded: false
      },
      {
        id_number: 1,
        latitude: 19.349974,
        longitude: -99.187695,
        title: 'Ciudad de México',
        parking: 'Estacionamiento con convenio AXA Keralty presentando boleto sellado en recepción',
        square: 'Clínica Puerta Altavista',
        description: `Centro Comercial Puerta Altavista. Av. Desierto de los Leones 24, San Ángel, Alcaldía Álvaro Obregón, C.P. 01000, CDMX.`,
        help_information: ' Planta baja, a dos calles del Parque de la Bombilla.',
        location: 'https://goo.gl/maps/UcBerEAC89p',
        location_apple: 'maps://goo.gl/maps/UcBerEAC89p',
        location_waze: 'https://ul.waze.com/ul?place=ChIJGc08o_f_0YUR9Bi_3d7V870&ll=19.34977200%2C-99.18765170&navigate=yes',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Domingo de 9:00 a 15:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Puerta-Altavista.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Puerta Altavista',
        responsable_name: 'Dr. Alonso Gómez Negrete',
        responsable_education: "UNAM",
        responsable_number: "Cédula profesional no. 3257052",
        closed:'',
        buttons2_information: true,
        expanded: false
      },
      {
        id_number: 2,
        latitude: 19.405932,
        longitude:  -99.166482,
        title: 'Ciudad de México',
        parking: 'Consulta los estacionamientos más cercanos, independientes a AXA Keralty',
        square: 'Clínica Roma',
        description: `Av. Baja California 200, Roma Sur, Alcaldía Cuauhtémoc, C.P. 06760, CDMX.`,
        help_information: ' Planta baja, a unas calles de Metro Chilpancingo.',
        location: 'https://goo.gl/maps/u4ZxoD9jhg12',
        location_apple: 'maps://goo.gl/maps/u4ZxoD9jhg12',
        location_waze: 'https://ul.waze.com/ul?place=ChIJ4-Y5IhX_0YUR3TqklmqNEu4&ll=19.40565860%2C-99.16652450&navigate=yes',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Roma.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Roma',
        responsable_name: 'Dr. Ricardo Flores Galicia',
        responsable_education: "UNAM",
        responsable_number: "Cédula profesional no. 4784731",
        closed:'',
        buttons2_information: true,
        expanded: false
      },
      {
        id_number: 3,
        latitude: 19.405668,
        longitude: -99.110441,
        title: 'Ciudad de México',
        parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
        square: 'Clínica Vía 515',
        description: `Centro Comercial Vía 515. Viad. Río de la Piedad 515, Granjas México, Alcaldía Iztacalco, CP 08400, CDMX.`,
        help_information: ' Piso 2, a un costado de las escaleras eléctricas. A dos minutos del Palacio de los Deportes.',
        location: 'https://goo.gl/maps/HX5VdW5piHPVgPFT7',
        location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
        location_waze: 'https://ul.waze.com/ul?place=El5WaWFkLiBSw61vIGRlIGxhIFBpZWRhZCA1MTUsIEdyYW5qYXMgTcOpeGljbywgSXp0YWNhbGNvLCAwODQwMCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWCwgTWV4aWNvIjESLwoUChIJSdqnlJn-0YUR-A8VbeV0pPkQgwQqFAoSCcMbBnXm_tGFERY9Qh4WbuP6&ll=19.40582930%2C-99.11123710&navigate=yes',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Via-515.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Vía 515',
        responsable_name: 'Dra. Selene Lydia Lozano Villalobos',
        responsable_education: "Instituto Politécnico Nacional",
        responsable_number: "Cédula profesional no. 5879192",
        buttons2_information: true,
        expanded: false
      },
      {
        id_number: 4,
        latitude:19.3046543,
        longitude:-99.165357,
        title: 'Ciudad de México',
        parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
        square: 'Clínica Gran Sur',
        description: `Centro Comercial Gran Sur. Periférico Sur 5550,  Local A2-98, Pedregal de Carrasco, Alcaldía Coyoacán, CP 04700 Ciudad de México, CDMX.`,
        help_information: "Nivel 1, frente al restaurante Italianni's y a un lado del restaurante Chili’s.",
        location: 'https://goo.gl/maps/n6ZrhZoHGLUiVPK98',
        location_apple: 'maps://goo.gl/maps/n6ZrhZoHGLUiVPK98',
        location_waze: 'https://ul.waze.com/ul?preview_venue_id=170918081.1709377419.294245&navigate=yes',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/gran_sur_v1.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Gran Sur',
        responsable_name: 'Raúl Segura Córdova',
        responsable_education: "UNAM",
        responsable_number: "Cédula profesional no. 7817645",
        closed:'',
        buttons2_information: true,
        expanded: false
      }
    ]},
    AGUASCALIENTES: {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®."
      ],cams: [
      {
        id_number: 0,
        latitude: 21.8982288,
        longitude: -102.3050549,
        title: 'Aguascalientes',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        square: 'Star Médica Aguascalientes*',
        description: `Avenida Universidad 101, Villas de la Universidad, CP 20020, Aguascalientes, Aguascalientes.`,
        help_information: '',
        location: 'https://goo.gl/maps/vRV66oiugobKEKG99',
        location_apple: 'maps://goo.gl/maps/vRV66oiugobKEKG99',
        location_waze: 'https://ul.waze.com/ul?ll=21.89821650%2C-102.30445147&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/card_aguascalientes_star.jpg',
        alt: 'Star Médica Aguascalientes*',
        closed:'',
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/d867c258-09af-4c58-ac13-2306f681f74d.pdf',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
      },
    ]},
    TAMAULIPAS: {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®."
      ],cams: [
      {
        id_number: 0,
        latitude: 24.9262373,
        longitude: -100.8889474,
        title: 'Reynosa',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        square: 'Christus Muguerza Hospital Reynosa',
        description: `Tiburcio Garza Zamora, Kilómetro 5.5, Rancho Grande, C.P.88610 Reynosa, Tamaulipas.`,
        help_information: '',
        location: 'https://goo.gl/maps/AaFEQPUDPyEEXDdXA',
        location_apple: 'maps://goo.gl/maps/AaFEQPUDPyEEXDdXA',
        location_waze: 'https://ul.waze.com/ul?place=ChIJeVMb3y4PZYYRcHZM92KPoUA&ll=26.11051960%2C-98.32996200&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'' ,
        hour:'Llamar al 55 5169 3080 (opción 4) para consultar los horarios de acuerdo al servicio que se requiera.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_reynosa.jpg',
        alt: 'Christus Muguerza Hospital Reynosa',
        closed:'',
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/2aa3a409-2876-46a9-9f26-96f62a50540e.pdf',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        asterisk: true
      },
      {
        id_number: 1,
        latitude: 22.235637,
        longitude: -97.8716132,
        title: 'Tampico',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        square: 'MAC Hospitales Tampico',
        description: `Av. Miguel Hidalgo 1900, Altavista 89240 Tampico, Tamaulipas.`,
        help_information: '',
        location: 'https://goo.gl/maps/yPDkwKFTxNtd9BcX9',
        location_apple: 'maps://goo.gl/maps/yPDkwKFTxNtd9BcX9',
        location_waze: 'https://ul.waze.com/ul?ll=22.23465500%2C-97.86765933&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\nPara consultar horarios de acuerdo al servicio que se requiera, llamar al 55 5169 3080, opción 4.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_tampico_tamaulipas.jpg',
        alt: 'MAC Hospitales Tampico',
        closed:'',
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/06301705-b486-4955-a2e6-36b536e7a366.pdf  ',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        asterisk: true
        
      },
    ]},
    COAHUILA : {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®."
      ],
      cams:[
      {
        id_number: 0,
        latitude:  25.5402243,
        longitude: -103.4430572,
        title: 'Torreón',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        square: 'Hospital Andalucía*',
        description: `Avenida Matamoros 542 Oriente, Primero de Cobián Centro, CP 27000 Torreón, Coahuila.`,
        //help_information: 'Piso 1, a un costado de Centauro. Frente al Rodeo Santa Fe.',
        location: 'https://goo.gl/maps/KU9uzU9B5JqxU3cq5',
        location_apple: 'maps://goo.gl/maps/KU9uzU9B5JqxU3cq5',
        location_waze: 'https://ul.waze.com/ul?ll=25.54012786%2C-103.44284534&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_generica_andalucia.jpg',
        alt: 'Hospital Andalucía*',
        closed:'',
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
      },
      {
        id_number: 1,
        latitude: 25.4771573,
        longitude: -100.9775868,
        title: 'Saltillo',
        square: 'CHRISTUS MUGUERZA Hospital Saltillo',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
        description: `Blvd. Luis Donaldo Colosio, Sin Nombre de Col 25, C.P. 25204, Saltillo, Coahuila.`,
        location: 'https://goo.gl/maps/CCq5JDsGk85Uhdv4A',
        location_apple: 'maps://goo.gl/maps/CCq5JDsGk85Uhdv4A',
        location_waze: 'https://ul.waze.com/ul?ll=25.47771144%2C-100.97776651&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Para consultar horarios de acuerdo con el servicio requerido, llamar al 55 5169 3080, opción 4.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_coahuila_saltillo_muguerza.jpg',
        alt: 'CHRISTUS MUGUERZA Hospital Saltillo',
        expanded: false,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/3bd8597b-a896-445e-a98f-3a6ae2ed07cd.pdf',
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
        asterisk: true
      }
    ]},

    EDOMEX : {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®."
      ],
      cams:[
      /*{
        id_number: 0,
        latitude: 19.2842197,
        longitude: -99.6743071,
        title: 'Toluca',
        square: 'Sanatorio Florencia*',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Paseo General Vicente Guerrero 205, Morelos Primera Secc , CP 50080, Toluca, Estado de México.`,
        location: 'https://goo.gl/maps/zrLwvtz5kbx6qm2dA',
        location_apple: 'maps://goo.gl/maps/zrLwvtz5kbx6qm2dA',
        location_waze: 'https://ul.waze.com/ul?preview_venue_id=170590401.1706100616.11464322&navigate=yes',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Toluca.jpg',
        alt: 'Sanatorio Florencia',
        expanded: false
      },*/
      {
        id_number: 1,
        latitude: 19.540274,
        longitude: -99.215217,
        title: 'Tlanepantla',
        parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
        square: 'Clínica Sentura',
        description: `Centro Comercial Sentura. Blvd. Manuel Ávila Camacho 1434, San Andrés Atenco, Tlalnepantla de Baz, C.P. 54040, Estado de México. `,
        help_information: 'Piso 1, a un costado de Centauro. Frente al Rodeo Santa Fe.',
        location: 'https://goo.gl/maps/ZSZkVe5iJCr',
        location_apple: 'maps://goo.gl/maps/ZSZkVe5iJCr',
        location_waze: 'https://ul.waze.com/ul?place=ChIJ3dXN2Tgd0oURauavlq6nrX4&ll=19.54008160%2C-99.21520650&navigate=yes',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Sentura.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Plaza Sentura',
        responsable_name: 'Dr. Jairo Antonio Pérez',
        responsable_education: "UNAM",
        responsable_number: "Cédula profesional: 09177523",
        closed:'',
        buttons2_information: true,
        expanded: false
      },
      {
        id_number: 2,
        latitude: 19.2833794,
        longitude: -99.6755577,
        title: 'Toluca',
        square: 'ARQUIMEDES & DORSCH LABORATORIOS',
        description: `Vicente Guerrero. Paseo Gral Vicente Guerrero 484, Federal, CP. 50080, Toluca de Lerdo, Estado de México.`,
        help_information: '',
        location: 'https://goo.gl/maps/rM7nQtG4wQJQBVK39',
        location_apple: 'maps://goo.gl/maps/rM7nQtG4wQJQBVK39',
        location_waze: 'https://ul.waze.com/ul?place=ChIJFU-9IbSJzYURNAueDYli-Pw&ll=19.28337940%2C-99.67336900&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels:'',
        email,
        closedIcon:'' ,
        hour:'Para laboratorios y estudios de gabinete, llama al:\n 722 167 0240 \n\n Para consultas y otros servicios, llama al:\n 55 5169 3080',
        img: 'assets/map/red-nacional-desktop/card-cam/card_generica_laboratorios.jpg',
        alt: 'ARQUIMEDES & DORSCH LABORATORIOS',
        responsable_name: '',
        responsable_education: "",
        responsable_number: "",
        closed:'',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/80e1ee11-8f46-4083-98f1-89f789903182.pdf',
        laboratoryIcon: true,
        onlyLaboratory: true,
        asterisk: true,

      },
      {
        id_number: 3,
        latitude: 19.2880877,
        longitude: -99.6486614,
        title: 'Toluca',
        square: 'ARQUIMEDES & DORSCH LABORATORIOS',
        description: `Centro. Alejandro Von Humbolt No. 303, Colonia San Sebastian, CP. 50150,Toluca de Lerdo, Estado de México.`,
        help_information: '',
        location: 'https://goo.gl/maps/5EZFuvuzYgsHrKxt9',
        location_apple: 'maps://goo.gl/maps/5EZFuvuzYgsHrKxt9',
        location_waze: 'https://ul.waze.com/ul?ll=19.28824869%2C-99.64852810&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels:'',
        email,
        closedIcon:'' ,
        hour:'Para laboratorios y estudios de gabinete, llama al:\n 722 214 2784 \n\n Para consultas y otros servicios, llama al:\n 55 5169 3080',
        img: 'assets/map/red-nacional-desktop/card-cam/card_generica_laboratorios.jpg',
        alt: 'ARQUIMEDES & DORSCH LABORATORIOS',
        responsable_name: '',
        responsable_education: "",
        responsable_number: "",
        closed:'',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®',
        hasClarification: true,
        hasResponsibleHealth: false,
        laboratoryIcon: true,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/80e1ee11-8f46-4083-98f1-89f789903182.pdf',
        onlyLaboratory: true,
        asterisk: true,
      },
      {
        id_number: 4,
        latitude: 19.3530853,
        longitude: -99.6072996,
        title: 'Otzacatipan',
        square: 'ARQUIMEDES & DORSCH LABORATORIOS',
        description: `Los Sauces. Matamoros 127, Locales D y E, Colonia San José Guadalupe Otzacatipan, CP. 50200, Otzacatipan, Estado de México`,
        help_information: '',
        location: 'https://goo.gl/maps/Zf8DMbhaejrusPTB7',
        location_apple: 'maps://goo.gl/maps/Zf8DMbhaejrusPTB7',
        location_waze: 'https://ul.waze.com/ul?ll=19.35394708%2C-99.59998012&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels:'',
        email,
        closedIcon:'' ,
        hour:'Para laboratorios y estudios de gabinete, llama al:\n 722 223 7604 \n\n Para consultas y otros servicios, llama al:\n 55 5169 3080',
        img: 'assets/map/red-nacional-desktop/card-cam/card_generica_laboratorios.jpg',
        alt: 'ARQUIMEDES & DORSCH LABORATORIOS',
        responsable_name: '',
        responsable_education: "",
        responsable_number: "",
        closed:'',
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/80e1ee11-8f46-4083-98f1-89f789903182.pdf',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        laboratoryIcon: true,
        onlyLaboratory: true,
        asterisk: true,
      },
      {
        id_number: 5,
        latitude: 19.2605875,
        longitude: -99.6132003,
        title: 'Metepec',
        square: 'ARQUIMEDES & DORSCH LABORATORIOS',
        description: `Plaza Rubí. Avenida Leona Vicario No. 501 Barrio de Coaxustenco, Plaza Rubí Locales 9,10 y 11, CP. 52140, Metepec, Estado de México`,
        help_information: '',
        location: 'https://goo.gl/maps/S5RAXVxaWsFVXd5h7',
        location_apple: 'maps://goo.gl/maps/S5RAXVxaWsFVXd5h7',
        location_waze: 'https://ul.waze.com/ul?ll=19.26162366%2C-99.61907744&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels:'',
        email,
        closedIcon:'' ,
        hour:'Para laboratorios y estudios de gabinete, llama al:\n 722 238 2182 \n\n Para consultas y otros servicios, llama al:\n 55 5169 3080',
        img: 'assets/map/red-nacional-desktop/card-cam/card_generica_laboratorios.jpg',
        alt: 'ARQUIMEDES & DORSCH LABORATORIOS',
        responsable_name: '',
        responsable_education: "",
        responsable_number: "",
        closed:'',
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/80e1ee11-8f46-4083-98f1-89f789903182.pdf',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        laboratoryIcon: true,
        onlyLaboratory: true,
        asterisk: true,
      },
    ]},
    NUEVO_LEON: {
      descriptions: [
        "**Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®."
      ],
      cams:[
      {
        id_number: 0,
        latitude: 25.66778569644115,
        longitude: -100.32000844344641,
        title: 'Monterrey',
        square: 'Clínica Centro Cuauhtémoc',
        parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty.',
        parking_description: 'La entrada es por Cuauhtémoc.',
        description: `Miguel Hidalgo 330, Col. Centro, C.P. 64070, Monterrey, N.L.`,
        help_information: 'Planta baja, a cinco minutos de la Macroplaza.',
        location: 'https://goo.gl/maps/9vbGzixZnEFbzgRm7',
        location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
        location_waze: 'https://ul.waze.com/ul?place=ChIJ5-AAPCS-YoYREcDIZ3CMpVY&ll=25.66770480%2C-100.31998070&navigate=yes',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Domingo de 9:00 a 15:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Monterrey.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
        responsable_name: 'María Corazón García Pérez',
        responsable_education: "Universidad Autónoma de Nuevo León",
        responsable_number: "Cédula profesional no. 8979061",
        buttons2_information: true,
        expanded: false
      },
      {
        id_number: 1,
        latitude: 25.6744838,
        longitude: -100.3380428,
        title: 'Monterrey',
        square: 'Christus Muguerza Hospital Conchita',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
        description: `15 de Mayo 1822, Maria Luisa, Nuevo Obispado, 64040 Monterrey, N.L.  `,
        help_information: '',
        location: 'https://goo.gl/maps/JXMVXKn4t5eUv5K18',
        location_apple: 'maps://goo.gl/maps/JXMVXKn4t5eUv5K18',
        location_waze: 'https://ul.waze.com/ul?ll=25.67529709%2C-100.33801675&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
        img: '/assets/map/red-nacional-desktop/card-cam/card_monterrey_conchita.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        doubleAsterisk: true,
      },
      {
        id_number: 2,
        latitude: 25.7186697,
        longitude: -100.2444989,
        title: 'Monterrey',
        square: 'Christus Muguerza Hospital San Nicolás',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
        description: `Av. Rómulo Garza 1100, Hacienda los Morales 3er Sector, 66495 San Nicolás de los Garza, N.L. `,
        help_information: '',
        location: 'https://g.page/muguerzasannicolas?share',
        location_apple: 'maps://goo.gl/maps/muguerzasannicolas',
        location_waze: 'https://ul.waze.com/ul?ll=25.71886962%2C-100.24232626&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
        img: '/assets/map/red-nacional-desktop/card-cam/card_monterrey_san_nicolas.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        doubleAsterisk: true,
      },
      {
        id_number: 4,
        latitude: 25.6521959,
        longitude: -100.3713063,
        title: 'Monterrey',
        square: 'Christus Muguerza Clínica San Pedro',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
        description: `Calz San Pedro #325, Col. del Valle, Del Valle, 66220 San Pedro Garza García, N.L. `,
        help_information: '',
        location: 'https://g.page/christusmuguerzasanpedro?share',
        location_apple: 'maps://goo.gl/maps/christusmuguerzasanpedro',
        location_waze: 'https://ul.waze.com/ul?ll=25.65243617%2C-100.37107229&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
        img: '/assets/map/red-nacional-desktop/card-cam/card_monterrey_san_pedro.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        doubleAsterisk: true,
      },
      {
        id_number: 5,
        latitude: 25.5894239,
        longitude: -100.2598844,
        title: 'Monterrey',
        square: 'Christus Muguerza Hospital Sur',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
        description: `Carr. Nacional 6501, La Estanzuela, 64988 Monterrey, N.L.  `,
        help_information: '',
        location: 'https://goo.gl/maps/njsruzgRa38c2h8N6',
        location_apple: 'maps://goo.gl/maps/njsruzgRa38c2h8N6',
        location_waze: 'https://ul.waze.com/ul?ll=25.58961380%2C-100.25779724&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
        img: '/assets/map/red-nacional-desktop/card-cam/card_monterrey_hospital_sur.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        doubleAsterisk: true,
      },
      {
        id_number: 6,
        latitude: 25.6872832,
        longitude: -100.3087642,
        title: 'Monterrey',
        square: 'Christus Muguerza  Hospital Vidriera',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
        description: `Mariano Escobedo 1405, Treviño, 64570 Monterrey, N.L.`,
        help_information: '',
        location: 'https://goo.gl/maps/1cgrKffwhankbFKWA',
        location_apple: 'maps://goo.gl/maps/1cgrKffwhankbFKWA',
        location_waze: 'https://ul.waze.com/ul?ll=25.68757674%2C-100.30831933&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
        img: '/assets/map/red-nacional-desktop/card-cam/card_monterrey_vidriera.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        doubleAsterisk: true,
      },
    ]},
    YUCATAN: {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®."
      ],
      cams:[
      {
        id_number: 0,
        latitude:  21.0425329,
        longitude: -89.6035045,
        title: 'Mérida',
        square: 'Hospital Faro del Mayab*',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
        description: `Calle 24 S/N, Temozón Norte, Santa Gertrudis Copo, C.P. 97305 Mérida, Yucatán.`,
        location: 'https://g.page/farodelmayab?share',
        location_apple: 'https://g.page/farodelmayab?share',
        location_waze: 'https://ul.waze.com/ul?ll=21.04391178%2C-89.60179925&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_merida.jpg',
        alt: 'Hospital Faro del Mayab',
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/8dc65ba1-e7bb-48d3-937b-91108e12bb71.pdf',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
      },
    ]},
    SONORA: {
      descriptions: [
        "**Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®."
      ],cams:[
      {
        id_number: 0,
        latitude: 29.0959824,
        longitude: -110.9559221,
        title: 'Hermosillo',
        square: 'Clínica Plaza Boulevard',
        parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty ',
        description: `Plaza Boulevard, local L15. Boulevard Abelardo L. Rodríguez 190, San Benito, CP 83190 Hermosillo, Sonora.`,
        location: 'https://goo.gl/maps/MsqD8CMHbzZLTNFm6',
        location_apple: 'https://goo.gl/maps/MsqD8CMHbzZLTNFm6',
        location_waze: 'https://ul.waze.com/ul?place=ChIJCyjkXjKEzoYRpxglyOJr0ng&ll=29.09597770%2C-110.95373340&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_generica_hermosillo.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Hermosillo',
        responsable_name: 'Dra. María Zulema Cázares Ramos.',
        responsable_education: "Universidad Autónoma de Sinaloa",
        responsable_number: "Cédula profesional no. 12411524",
        buttons2_information: true,
        expanded: false
      },
      {
        id_number: 1,
        latitude: 29.081648192106265,
        longitude: -110.95060649851261,
        title: 'Hermosillo',
        square: 'Clínica del Noroeste',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Av. Luis Donaldo Colosio 23, Col. Centro, C.P. 83000, Hermosillo, Sonora`,
        location: 'https://goo.gl/maps/GEtLpHmcrfEAHWJJ9',
        location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
        location_waze: 'https://ul.waze.com/ul?place=ChIJfW8orbSFzoYRf4yyFAQJS1U&ll=29.08157320%2C-110.95075670&navigate=yes&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Hermosillo.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Hermosillo',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        hasClarification: true,
        doubleAsterisk: true,
      },
    ]},
    QUERETARO: {
      descriptions: [
        "**Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®."
      ],cams:[
      {
        id_number: 0,
        latitude: 20.5862428,
        longitude: -100.3822634,
        title: 'Querétaro',
        square: 'Clínica Puerta La Victoria',
        parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
        description: `Centro Comercial Puerta La Victoria, local LO-R11. Av. Constituyentes Oriente 40, Colonia Villas del Sol, CP 76046, Querétaro, Querétaro.`,
        location: 'https://goo.gl/maps/rQFExuq53KZXvbQ96',
        location_apple: 'maps://goo.gl/maps/rQFExuq53KZXvbQ96',
        location_waze: 'https://ul.waze.com/ul?ll=20.58542510%2C-100.38080335&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/queretaro2_generica.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Querétaro',
        responsable_name: 'Dra. Katyanna Quiñonez Ortiz',
        responsable_education: "Universidad de Guadalajara",
        responsable_number: "Cédula profesional no. 11508793",
        expanded: false,
        buttons2_information: true,
      },
      {
        id_number: 1,
        latitude: 20.58431543072927,
        longitude: -100.40002580688736,
        title: 'Querétaro',
        square: 'Hospital H+',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Calle Privada Ignacio Zaragoza 16-B, Centro, C.P. 76000, Santiago de Querétaro, Querétaro`,
        location: 'https://goo.gl/maps/LMDkQCwoMwPMEu4TA',
        location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
        location_waze: 'https://ul.waze.com/ul?place=ChIJDZ9EJS1b04UR72pJm5bh1MI&ll=20.58428530%2C-100.40004190&navigate=yes&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Queretaro.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Querétaro',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        hasClarification: true,
        doubleAsterisk: true,
      },
    ]},
    JALISCO: {
      descriptions: [
        "**Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®."
      ],
      cams:[
      {
        id_number: 0,
        latitude:  20.7016465,
        longitude: -103.3768648,
        title: 'Guadalajara',
        square: 'Clínica Punto Sao Paulo',
        parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty.',
        description: `Centro Comercial Punto Sao Paulo, Local J21. São Paulo 1912, Providencia, CP 44630, Guadalajara, Jalisco. `,
        location: 'https://goo.gl/maps/7wsQvpAFZefszbiz7',
        location_apple: 'maps://goo.gl/maps/7wsQvpAFZefszbiz7',
        location_waze: 'https://ul.waze.com/ul?ll=20.70219961%2C-103.37675571&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_generica_guadalajara.jpg',
        alt: 'Clínica Punto Sao Paulo',
        responsable_name: 'Dra. Gabriela Ruiz Segura',
        responsable_education: "Universidad de Guadalajara",
        responsable_number: "Cédula: 10914166",
        expanded: false,
        buttons2_information: true,
      },
      {
        id_number: 1,
        latitude:  20.685403630143337,
        longitude: -103.38676483121571,
        title: 'Guadalajara',
        square: 'Hospital Terranova',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Av. Terranova 556 Prados De Providencia Jalisco 44670, Guadalajara, Jalisco.`,
        location: 'https://goo.gl/maps/iQxEkKR4JCZL3rKY8',
        location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
        location_waze: 'https://ul.waze.com/ul?place=ChIJwZoiEGquKIQRI1r2OvSygek&ll=20.68520290%2C-103.38675410&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Jalisco.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Guadalajara',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        doubleAsterisk: true,
        hasClarification: true,
      },
    ]},
    PUEBLA: {
      descriptions: [
        "**Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®."
      ],
      cams:[
      {
        id_number: 0,
        latitude:  19.0505852,
        longitude: -98.2168324,
        title: 'Puebla',
        square: 'Clínica Juárez',
        parking: 'Estacionamiento público enfrente con el que hay convenio para los pacientes',
        description: `Avenida Juárez 2307, Zona Esmeralda, CP 72090 Puebla, Puebla.`,
        location: 'https://goo.gl/maps/7vUs6RP6o1H4QtdL6',
        location_apple: 'maps://goo.gl/maps/7vUs6RP6o1H4QtdL6',
        location_waze: 'https://ul.waze.com/ul?ll=19.05071955%2C-98.21685076&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_puebla_ak.jpg',
        alt: 'Clínica Juárez',
        responsable_name: 'Dra. Ivonne Audiffred González.',
        responsable_education: "UPAEP",
        responsable_number: "Cédula profesional no. 4881794",
        expanded: false,
        buttons2_information: true,
      },
      {
        id_number: 1,
        latitude:  19.0346056,
        longitude: -98.1887241,
        title: 'Puebla',
        square: 'Christus Muguerza Betania',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Avenida 11 Oriente 1826, Azcarate, CP 72501, Puebla, Pue.`,
        location: 'https://g.page/hospitalbetania?share',
        location_apple: 'maps://goo.gl/maps/hospitalbetania',
        location_waze: 'https://ul.waze.com/ul?ll=19.03422911%2C-98.18829060&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_puebla_betania_ext.jpg',
        alt: 'Christus Muguerza Betania',
        responsable_name: 'Dra. Ivonne Audiffred González.',
        responsable_education: "UPAEP",
        responsable_number: "Cédula profesional no. 4881794",
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        hasClarification: true,
        doubleAsterisk: true,
      },
      {
        id_number: 2,
        latitude:  19.0457258,
        longitude: -98.2050069,
        title: 'Puebla',
        square: 'Christus Muguerza UPAEP',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Avenida 5 Poniente 715, Centro, CP 72000, Puebla, Pue.`,
        location: 'https://goo.gl/maps/igSZWNCid3PBjWdK6',
        location_apple: 'maps://goo.gl/maps/igSZWNCid3PBjWdK6',
        location_waze: 'https://ul.waze.com/ul?ll=19.04565900%2C-98.20517778&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_puebla_upaep_ext.jpg',
        alt: 'Christus Muguerza UPAEP',
        button2_information: true,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        hasClarification: true,
        doubleAsterisk: true,
      },
      
    ]},
    SAN_LUIS: {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®."
      ],cams:[
      {
        id_number: 0,
        latitude:  22.1517165,
        longitude: -100.9840202,
        title: 'San Luis Potosí',
        square: 'Star Médica San Luis Potosí*',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Mariano Arista 735, Tequisquiapan, CP 78250 San Luis, San Luis Potosí.`,
        location: 'https://goo.gl/maps/RTw8JX2kS3qeQm7M6',
        location_apple: 'maps://goo.gl/maps/RTw8JX2kS3qeQm7M6',
        location_waze: 'https://ul.waze.com/ul?place=ChIJi6Lc1KqYKoQRckipRPyUyeY&ll=22.15205630%2C-100.98338970&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_san_luis_star.jpg',
        alt: 'Star Médica San Luis Potosí*',
        expanded: false,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/7446c217-d2fd-48af-a37e-bc686cd68a8a.pdf',
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
      }
    ]},
    SINALOA: {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®."
      ],cams:[
      {
        id_number: 0,
        latitude:  24.8039634,
        longitude: -107.3909895,
        title: 'Culiacán',
        square: 'Nueva Clínica Santa María',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Calle Francisco Villa 291 Oriente, Col. Centro, CP 80000, Culiacán, Sinaloa.`,
        location: 'https://goo.gl/maps/YWRaxJQuqK66ke9y5',
        location_apple: 'maps://goo.gl/maps/YWRaxJQuqK66ke9y5',
        location_waze: 'https://ul.waze.com/ul?ll=24.80415896%2C-107.39006996&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\nPara consultar horarios de acuerdo al servicio que se requiera, llamar al 55 5169 3080, opción 4.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_sinaloa_culiacan.jpg',
        alt: 'Nueva Clínica Santa María',
        expanded: false,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/3/d68e185d-7120-4728-a1f9-8a9593b16004.pdf',
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
        hasResponsibleHealth: false,
        asterisk: true,
        
      }
    ]},
    CHIHUAHUA: {
      descriptions: [
      "*Proveedor en convenio que brinda el servicio para usuarios Planmed®.",
      "**Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®."
    ],
      cams:[
        {
          id_number: 0,
          latitude:  28.6516751,
          longitude: -106.1312183,
          title: 'Chihuahua',
          square: 'Clínica Plaza San Ángel',
          parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
          description: `Plaza San Ángel, local 4101-1. Periférico de la Juventud 4101, Valle del Ángel, CP 31115 Chihuahua, Chihuahua.`,
          location: 'https://goo.gl/maps/SwCrgnXGiWGbXjUd9',
          location_apple: 'maps://goo.gl/maps/SwCrgnXGiWGbXjUd9',
          location_waze: 'https://ul.waze.com/ul?ll=28.65189882%2C-106.13120198&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
          tels,
          email,
          closedIcon:'' ,
          hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
          img: 'assets/map/red-nacional-desktop/card-cam/card_generica_chihuahua_3.jpg',
          alt: 'Clínica Plaza San Ángel',
          responsable_name: 'Dr. Octavio Alejandro Lerma Corral',
          responsable_education: "Universidad Autónoma de Ciudad de Juárez",
          responsable_number: "Cédula: 9554865",
          expanded: false,
          buttons2_information: true,
          clarification: '',
          hasClarification: false,
        },
        {
          id_number: 1,
          latitude:  28.659189044141442,
          longitude: -106.12952983487372,
          title: 'Chihuahua',
          square: 'CHRISTUS MUGUERZA Centro de Medicina Ambulatoria Juventud',
          parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
          description: `Ave. Valle del Ángel 3201, esq. Perif. de la Juventud, Desarrollo el Saucito, Chihuahua, Chih.`,
          location: 'https://g.page/christusmuguerzajuventud?share',
          location_apple: 'maps://goo.gl/maps/iDE6ejr7T4wCSVcv5',
          location_waze: 'https://ul.waze.com/ul?place=ChIJGYk59-1C6oYRr6o27TbkSgU&ll=28.65917940%2C-106.12953880&navigate=yes&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location',
          tels,
          email,
          closedIcon:'/assets/map/red-nacional-desktop/card-cam/leon.png' ,
          hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
          img: 'assets/map/red-nacional-desktop/card-cam/Chihuahua.jpg',
          alt: 'Centro de Atención Médica de AXA Keralty en León',
          expanded: false,
          buttons_information: true,
          url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/7de7e38e-93cd-4f6a-a1c7-4f391fe3f7d1.pdf',
          clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
          hasClarification: true,
          doubleAsterisk: true,
          hasResponsibleHealth: false, 
          
        },
      {
        id_number: 2,
        latitude:  31.6837815,
        longitude: -106.4070093,
        title: 'Ciudad Juárez',
        square: 'Star Médica Ciudad Juárez',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Av. Paseo de la Victoria 4370, Partido Iglesias, 32618 Cd Juárez, Chih.`,
        location: 'https://goo.gl/maps/3Fs3NtXYPg4N8vfJ8',
        location_apple: 'maps://goo.gl/maps/3Fs3NtXYPg4N8vfJ8',
        location_waze: 'https://ul.waze.com/ul?ll=31.68418122%2C-106.40648246&navigate=yes&zoom=17&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/leon.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_star_medica_cd_juarez.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en León',
        expanded: false,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/5d9f696d-eb36-40c5-8cba-0c9df965117d.pdf',
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®',
        asterisk: true,
        hasClarification: true,
      },
    ]},
    GUANAJUATO: {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®.",
      ],
      cams:[
      /*{
        id_number: 0,
        latitude:  21.15036710819777,
        longitude: -101.68731708746883,
        title: 'León',
        square: 'Hospital Médica Campestre',
        parking: 'Consulta los estacionamientos más cercanos, independientes a AXA Keralty',
        description: `Manantial 106, Futurama Monterrey, CP. 37180, León, Gto.`,
        location: 'https://goo.gl/maps/k9aMiGnwt3q8V9EUA',
        location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
        location_waze: 'https://ul.waze.com/ul?place=ChIJC5JZPka_K4QRxG1dU-yez1o&ll=21.15014610%2C-101.68735500&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/leon.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/leon.png',
        alt: 'Centro de Atención Médica de AXA Keralty en León',
        expanded: false
      },*/
      {
        id_number: 0,
        latitude:  21.1347643,
        longitude: -101.6254175,
        title: 'León',
        square: 'CHRISTUS MUGUERZA ALTAGRACIA',
        asterisk: 1,
        parking: 'El hospital cuenta con estacionamiento.',
        description: `Blvd. Jorge Vértiz Campero 761, Fracciones Cañada de Alfaro, C.P. 37238 León, Guanajuato`,
        location: 'https://goo.gl/maps/7RnuqzvupqJxKbrQ6',
        location_apple: 'maps://goo.gl/maps/7RnuqzvupqJxKbrQ6',
        location_waze: 'https://ul.waze.com/ul?ll=21.13477456%2C-101.62342787&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/leon.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs',
        img: '/assets/map/red-nacional-desktop/card-cam/card_leon_hospital.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en León',
        expanded: false,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/cac8004d-95dc-44cf-9571-22f63e6e235c.pdf',
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
      },
      /*{
        id_number: 1,
        latitude:   21.004588597490407,
        longitude: -101.50975474470178,
        title: 'Silao',
        square: 'Hospital Aranda de la Parra* – Puerto Interior',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Blvd. Mineral de Cata 901 Esq Mineral de Rayas Puerto Interior, Silao de la Victoria, Guanajuato.`,
        location: 'https://g.page/HARANDA?share',
        location_apple: 'maps://goo.gl/maps/7tDJA3uYGg154hi76',
        location_waze: 'https://ul.waze.com/ul?place=ChIJKaJgR-G_K4QRDowj1gG9LsY&ll=21.00451850%2C-101.50977620&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/leon.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Guanajuato-2.jpg', 
        alt: 'Centro de Atención Médica de AXA Keralty en León',
        expanded: false,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/cac8004d-95dc-44cf-9571-22f63e6e235c.pdf',
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
        asterisk: true,
      },/** */
    ]},
    BAJA_CALIFORNIA: {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®.",
        "**Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®."
      ],
      cams:[
      {
        id_number: 0,
        latitude: 32.514068,
        longitude: -117.0096431,
        title: 'Tijuana',
        square: 'Clínica Salinas',
        parking: 'El lugar cuenta con estacionamiento',
        description: `Boulevard Salinas 10857 Fraccionamiento Aviación C.P. 22014 Tijuana, Baja California.`,
        location: 'https://goo.gl/maps/6tr2jecTq4nGdoTm7',
        location_apple: 'maps://goo.gl/maps/6tr2jecTq4nGdoTm7',
        location_waze: 'https://ul.waze.com/ul?ll=32.51420401%2C-117.00706601&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/Tijuana.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Tijuana',
        responsable_name: 'Francisco Javier Pérez Basave',
        responsable_education: "Universidad Autónoma Metropolitana",
        responsable_number: "Cédula profesional no. 1779122",
        expanded: false,
        buttons2_information: true,
      },
      {
        id_number: 1,
        latitude: 32.524912,
        longitude: -117.0209571,
        title: 'Tijuana',
        square: 'Centro Médico Premier',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
        description: `Antonio Caso 2055, Zona Urbana Río Tijuana, C.P. 22010, Tijuana, B.C.`,
        location: 'https://goo.gl/maps/s4mdVbjM1ExN6nCAA',
        location_apple: 'maps://goo.gl/maps/s4mdVbjM1ExN6nCAA',
        location_waze: 'https://ul.waze.com/ul?ll=32.52476145%2C-117.02084184&navigate=yes&zoom=17&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/red-nacional-desktop/card_generica_centro_medico_premier.jpg' ,
        hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_generica_centro_medico_premier.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Tijuana',
        expanded: false,
        clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty para usuarios Planmed®.',
        hasClarification: true,
        doubleAsterisk: true,
      },
      {
        id_number: 2,
        latitude:  32.6642191,
        longitude: -115.4860231,
        title: 'Mexicali',
        square: 'Hospital Hispano Americano',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Sucursal Reforma. Av. Reforma 1000, Segunda, C.P. 21100, Mexicali, B.C.`,
        location: 'https://goo.gl/maps/62DfP8SxTvi114cW8',
        location_apple: 'maps://goo.gl/maps/62DfP8SxTvi114cW8',
        location_waze: 'https://ul.waze.com/ul?ll=32.66381002%2C-115.47746658&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Para consultar horarios de acuerdo con el servicio requerido, llamar al 55 5169 3080, opción 4.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_bc_mexicalli_reforma.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Mexicali',
        expanded: false,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/5447dc15-92cb-41db-86d2-9e6495232384.pdf',
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
      },
      {
        id_number: 3,
        latitude:  32.6031092,
        longitude: -115.4076559,
        title: 'Mexicali',
        square: 'Hospital Hispano Americano',
        parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
        description: `Sucursal Carranza. Boulevard Venustiano Carranza 1471, González Ortega Poniente, C.P. 21397, Mexicali, B.C.`,
        location: 'https://goo.gl/maps/1aeJjChGVw4s1kje6',
        location_apple: 'maps://goo.gl/maps/1aeJjChGVw4s1kje6',
        location_waze: 'https://ul.waze.com/ul?ll=32.60326549%2C-115.40552974&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Para consultar horarios de acuerdo con el servicio requerido, llamar al 55 5169 3080, opción 4.',
        img: 'assets/map/red-nacional-desktop/card-cam/card_bc_mexicalli_carranza.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en Mexicali',
        expanded: false,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/5447dc15-92cb-41db-86d2-9e6495232384.pdf',
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
      },
  
    ]},
    VERACRUZ: {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®.", 
      ],
      cams:[
        {
          id_number: 0,
          latitude: 19.1719409,
          longitude: -96.1902646,
          title: 'Veracruz',
          square: 'Hospital Star Médica Veracruz',
          parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
          description: `Av. Circuito Nuevo Veracruz 835 Col. El Laurelal de Buenavista, C.P. 91698 Veracruz, Veracruz.`,
          location: 'https://goo.gl/maps/vWzimwe9qwjrcEBk8',
          location_apple: 'maps://goo.gl/maps/vWzimwe9qwjrcEBk8',
          location_waze: 'https://ul.waze.com/ul?place=ChIJxUbs8YNDw4URykAVU4c5UBQ&ll=19.17194090%2C-96.19026460&navigate=yes&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location',
          tels,
          email,
          closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
          hour:'Llama al 55 5169 3080 (opción 4) para consultar los horarios disponibles de acuerdo al servicio que requieras.',
          img: 'assets/map/red-nacional-desktop/card-cam/card_veracruz_star_medica.jpg',
          alt: 'Hospital Star Médica Veracruz',
          expanded: false,
          buttons_information: true,
          url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/7c1978f4-9ed0-44ae-896d-b1a32a81df2f.pdf',
          clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
          hasClarification: true,
          doubleAsterisk: false,
          hasResponsibleHealth: false, 
          asterisk: true
        }
      ]
    },
    MICHOACAN: {
      descriptions: [
        "*Proveedor en convenio que brinda el servicio para usuarios Planmed®."
      ],
      cams: [
        {
          id_number: 0,
          latitude: 19.6826667,
          longitude: -101.1939679,
          title: 'Morelia',
          parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
          square: 'Star Médica Morelia',
          description: `Av. Virrey de Mendoza No. 2000 Col. Félix Ireta, C.P. 58070, Morelia, Michoacán.`,
          help_information: '',
          location: 'https://goo.gl/maps/THYzaL5U8PYNe3Em6',
          location_apple: 'maps://goo.gl/maps/THYzaL5U8PYNe3Em6',
          location_waze: 'https://ul.waze.com/ul?place=ChIJm_TNb9sNLYQRuwg5Ev4IUkU&ll=19.68278870%2C-101.19183890&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
          tels,
          email,
          closedIcon:'' ,
          hour:'Llamar al 55 5169 3080 (Opción 4) para consultar los horarios de acuerdo al servicio que se requiera',
          img: 'assets/map/red-nacional-desktop/card-cam/card_morelia_star_medica_2771.jpg',
          alt: 'Star Médica Aguascalientes*',
          closed:'',
          expanded: false,
          buttons_information: true,
          url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/4bc01fc4-888f-458c-83c3-fceb1ea20388.pdf',
          clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
          hasClarification: true,
          doubleAsterisk: false,
          hasResponsibleHealth: false, 
          asterisk: true,
        }
      ]
    }
  },
  MARKERS: [
    {
      id_number: 0,
      latitude:19.438993,
      longitude:-99.177687,
      title: 'Clínica Parques Plaza',
      parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
      square: 'Clínica Parques Plaza',
      description: `Lago Alberto 442, Anáhuac I Secc, Alcaldía Miguel Hidalgo, CP 11320, CDMX.`,
      help_information: 'Dentro de Parques Plaza, planta baja, entrada principal sobre Bahía San Hipólito.',
      location: 'https://goo.gl/maps/fteTFneTCa528BKB7',
      location_apple: 'maps://goo.gl/maps/fteTFneTCa528BKB7',
      location_waze: 'https://ul.waze.com/ul?place=ChIJoRLNwrH40YUR6DC4QX6cFow&ll=19.43879590%2C-99.17805180&navigate=yes',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Domingo de 9:00 a 15:00 hrs.',
      img: 'assets/map/CAM-Parques Plaza.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Parques Plaza',
      responsable_name: 'Carlos Alberto López García',
      responsable_education: "Universidad Michoacana de San Nicolás de Hidalgo",
      responsable_number: "Cédula profesional no. 9696628",
      closed:'',
      buttons2_information: true,
      expanded: false,
      cams: [],
    },
    {
      id_number: 1,
      latitude: 19.349974,
      longitude: -99.187695,
      title: 'Clínica Puerta Altavista',
      parking: 'Estacionamiento con convenio AXA Keralty presentando boleto sellado en recepción',
      square: 'Clínica Puerta Altavista',
      description: `Av. Desierto de los Leones 24, San Ángel, Alcaldía Álvaro Obregón, C.P. 01000, CDMX.`,
      help_information: ' Planta baja, a dos calles del Parque de la Bombilla.',
      location: 'https://goo.gl/maps/UcBerEAC89p',
      location_apple: 'maps://goo.gl/maps/UcBerEAC89p',
      location_waze: 'https://ul.waze.com/ul?place=ChIJGc08o_f_0YUR9Bi_3d7V870&ll=19.34977200%2C-99.18765170&navigate=yes',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs, Domingo de 9:00 a 15:00 hrs.',
      img: 'assets/map/CAM-Puerta Altavista.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Puerta Altavista',
      responsable_name: 'Dr. Alonso Gómez Negrete',
      responsable_education: "UNAM",
      responsable_number: "Cédula profesional no. 3257052",
      closed:'',
      buttons2_information: true,
      expanded: false,
      cams: [],
    },
    {
      id_number: 2,
      latitude: 19.405932,
      longitude:  -99.166482,
      title: 'Clínica Roma',
      parking: 'Consulta los estacionamientos más cercanos, independientes a AXA Keralty',
      description: `Av. Baja California 200, Roma Sur, Alcaldía Cuauhtémoc, C.P. 06760, CDMX.`,
      help_information: ' Planta baja, a unas calles de Metro Chilpancingo.',
      location: 'https://goo.gl/maps/u4ZxoD9jhg12',
      location_apple: 'maps://goo.gl/maps/u4ZxoD9jhg12',
      location_waze: 'https://ul.waze.com/ul?place=ChIJ4-Y5IhX_0YUR3TqklmqNEu4&ll=19.40565860%2C-99.16652450&navigate=yes',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/CAM-Roma.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Roma',
      responsable_name: 'Dr. Ricardo Flores Galicia',
      responsable_education: "UNAM",
      responsable_number: "Cédula profesional no. 4784731",
      closed:'',
      buttons2_information: true,
      expanded: false,
      cams: [],
    },
    {
      id_number: 3,
      latitude: 19.540274,
      longitude: -99.215217,
      title: 'Clínica Sentura',
      parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
      square: 'Clínica Sentura',
      description: `Blvd. Manuel Ávila Camacho 1434, San Andrés Atenco, Tlalnepantla de Baz, C.P. 54040, Estado de México. `,
      help_information: 'Piso 1, a un costado de Centauro. Frente al Rodeo Santa Fe.',
      location: 'https://goo.gl/maps/ZSZkVe5iJCr',
      location_apple: 'maps://goo.gl/maps/ZSZkVe5iJCr',
      location_waze: 'https://ul.waze.com/ul?place=ChIJ3dXN2Tgd0oURauavlq6nrX4&ll=19.54008160%2C-99.21520650&navigate=yes',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/CAM-Sentura.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Plaza Sentura',
      responsable_name: 'Dr. Jairo Antonio Pérez',
      responsable_education: "UNAM",
      responsable_number: "Cédula profesional: 09177523",
      closed:'',
      buttons2_information: true,
      expanded: false,
      cams: [],
    },
    {
      id_number: 4,
      latitude: 19.405668,
      longitude: -99.110441,
      title: 'Clínica Vía 515',
      parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
      square: 'Centro Comercial Vía 515',
      description: `Viad. Río de la Piedad 515, Granjas México, Alcaldía Iztacalco, CP 08400, CDMX.`,
      help_information: ' Piso 2, a un costado de las escaleras eléctricas. A dos minutos del Palacio de los Deportes.',
      location: 'https://goo.gl/maps/HX5VdW5piHPVgPFT7',
      location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
      location_waze: 'https://ul.waze.com/ul?place=El5WaWFkLiBSw61vIGRlIGxhIFBpZWRhZCA1MTUsIEdyYW5qYXMgTcOpeGljbywgSXp0YWNhbGNvLCAwODQwMCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWCwgTWV4aWNvIjESLwoUChIJSdqnlJn-0YUR-A8VbeV0pPkQgwQqFAoSCcMbBnXm_tGFERY9Qh4WbuP6&ll=19.40582930%2C-99.11123710&navigate=yes',
      tels,
      email,
      closedIcon:'/assets/map/candado.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/CAM-Centro Vía 515.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Vía 515',
      responsable_name: 'Dra. Selene Lydia Lozano Villalobos',
      responsable_education: "Instituto Politécnico Nacional",
      responsable_number: "Cédula profesional no. 5879192",
      expanded: false,
      buttons2_information: true,
      cams: [],
    },
     {
      id_number: 5,
      latitude: 25.66778569644115,
      longitude: -100.32000844344641,
      title: 'Monterrey',
      square: 'Clínica Centro Cuauhtémoc',
      parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty.',
      description: `Miguel Hidalgo 330, Col. Centro, C.P. 64070, Monterrey, N.L.`,
      help_information: 'Planta baja, a cinco minutos de la Macroplaza.',
      location: 'https://goo.gl/maps/9vbGzixZnEFbzgRm7',
      location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
      location_waze: 'https://ul.waze.com/ul?place=ChIJ5-AAPCS-YoYREcDIZ3CMpVY&ll=25.66770480%2C-100.31998070&navigate=yes',
      tels,
      email,
      closedIcon:'/assets/map/candado.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Domingo de 9:00 a 15:00 hrs.',
      img: 'assets/map/_EK_3273.png',
      alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
      responsable_name: 'María Corazón García Pérez',
      responsable_education: "Universidad Autónoma de Nuevo León",
      responsable_number: "Cédula profesional no. 8979061",
      expanded: false,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      cams: [],
    },
    {
      id_number: 6,
      latitude: 29.081648192106265,
      longitude: -110.95060649851261,
      title: 'Hermosillo',
      square: 'Clínica del Noroeste',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Av. Luis Donaldo Colosio 23, Col. Centro, C.P. 83000, Hermosillo, Sonora`,
      location: 'https://goo.gl/maps/GEtLpHmcrfEAHWJJ9',
      location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
      location_waze: 'https://ul.waze.com/ul?place=ChIJfW8orbSFzoYRf4yyFAQJS1U&ll=29.08157320%2C-110.95075670&navigate=yes&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/candado.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/clinica_noroeste.png',
      alt: 'Centro de Atención Médica de AXA Keralty en Hermosillo',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty.',
      hasClarification: true,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      cams: [],
    },
    {
      id_number: 7,
      latitude: 29.0959824,
      longitude: -110.9559221,
      title: 'Hermosillo',
      square: 'Clínica Plaza Boulevard',
      parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty ',
      description: `Plaza Boulevard, local L15. Boulevard Abelardo L. Rodríguez 190, San Benito, CP 83190 Hermosillo, Sonora.`,
      location: 'https://goo.gl/maps/MsqD8CMHbzZLTNFm6',
      location_apple: 'https://goo.gl/maps/MsqD8CMHbzZLTNFm6',
      location_waze: 'https://ul.waze.com/ul?place=ChIJCyjkXjKEzoYRpxglyOJr0ng&ll=29.09597770%2C-110.95373340&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_generica_hermosillo.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Hermosillo',
      responsable_name: 'Dra. María Zulema Cázares Ramos.',
      responsable_education: "Universidad Autónoma de Sinaloa",
      responsable_number: "Cédula profesional no. 12411524",
      expanded: false,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      cams: [],
    },
    {
      id_number: 8,
      latitude: 20.58431543072927,
      longitude: -100.40002580688736,
      title: 'Querétaro',
      square: 'Hospital H+',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Calle Privada Ignacio Zaragoza 16-B, Centro, C.P. 76000, Santiago de Querétaro, Querétaro`,
      location: 'https://goo.gl/maps/LMDkQCwoMwPMEu4TA',
      location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
      location_waze: 'https://ul.waze.com/ul?place=ChIJDZ9EJS1b04UR72pJm5bh1MI&ll=20.58428530%2C-100.40004190&navigate=yes&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/candado.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/hmas_queretaro_.png',
      alt: 'Centro de Atención Médica de AXA Keralty en Querétaro',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty.',
      hasClarification: true,
      buttons2_information: true,
      cams: [],
    },
    {
      id_number: 9,
      latitude:  20.685403630143337,
      longitude: -103.38676483121571,
      title: 'Guadalajara',
      square: 'Hospital Terranova*',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Av. Terranova 556 Prados De Providencia Jalisco 44670, Guadalajara, Jalisco.`,
      location: 'https://goo.gl/maps/iQxEkKR4JCZL3rKY8',
      location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
      location_waze: 'https://ul.waze.com/ul?place=ChIJwZoiEGquKIQRI1r2OvSygek&ll=20.68520290%2C-103.38675410&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/terranova.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/terranova.png',
      alt: 'Centro de Atención Médica de AXA Keralty en Guadalajara',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty.',
      hasClarification: true,
      button2_information: true,
      cams: [],
    },
    {
      id_number: 12,
      latitude:  19.0505852,
      longitude: -98.2168324,
      title: 'Puebla',
      square: 'Clínica Juárez',
      parking: 'Estacionamiento público enfrente con el que hay convenio para los pacientes',
      description: `Avenida Juárez 2307, Zona Esmeralda, CP 72090 Puebla, Puebla.`,
      location: 'https://goo.gl/maps/7vUs6RP6o1H4QtdL6',
      location_apple: 'maps://goo.gl/maps/7vUs6RP6o1H4QtdL6',
      location_waze: 'https://ul.waze.com/ul?ll=19.05071955%2C-98.21685076&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_puebla_ak.jpg',
      alt: 'Clínica Juárez',
      responsable_name: 'Dra. Ivonne Audiffred González.',
      responsable_education: "UPAEP",
      responsable_number: "Cédula profesional no. 4881794",
      expanded: false,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      cams: [],
    },
    {
      id_number: 11,
      latitude:  32.66527940816968,
      longitude: -115.47660034472453,
      title: 'Mexicali',
      square: 'Hospital Almater',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Av. Fco. I. Madero 1060, Segunda, 21100 Mexicali, B.C.`,
      location: 'https://goo.gl/maps/aeV5L18Xg588n2cF6',
      location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
      location_waze: 'https://ul.waze.com/ul?place=ChIJ04Pw-6h614AR21MWB_YdqMg&ll=32.66518580%2C-115.47661110&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/mexicali.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/mexicali.png',
      alt: 'Centro de Atención Médica de AXA Keralty en Mexicali',
      expanded: false,
      buttons2_information: true,
      cams: [],
    },

    {
      id_number: 13,
      latitude: 32.514068,
      longitude: -117.0096431,
      title: 'Tijuana',
      square: 'Clínica Salinas',
      parking: 'El lugar cuenta con estacionamiento',
      description: `Boulevard Salinas 10857 Fraccionamiento Aviación C.P. 22014 Tijuana, Baja California.`,
      location: 'https://goo.gl/maps/6tr2jecTq4nGdoTm7',
      location_apple: 'maps://goo.gl/maps/6tr2jecTq4nGdoTm7',
      location_waze: 'https://ul.waze.com/ul?ll=32.51420401%2C-117.00706601&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/candado.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/tijuana_.png',
      alt: 'Centro de Atención Médica de AXA Keralty en Tijuana',
      responsable_name: 'Francisco Javier Pérez Basave',
      responsable_education: "Universidad Autónoma Metropolitana",
      responsable_number: "Cédula profesional no. 1779122",
      expanded: false,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      cams: [],
    },


    {

      id_number: 14,
        latitude:  21.1347643,
        longitude: -101.6254175,
        title: 'León',
        square: 'CHRISTUS MUGUERZA ALTAGRACIA',
        asterisk: 1,
        parking: 'El hospital cuenta con estacionamiento.',
        description: `Blvd. Jorge Vértiz Campero 761, Fracciones Cañada de Alfaro, C.P. 37238 León, Guanajuato`,
        location: 'https://goo.gl/maps/7RnuqzvupqJxKbrQ6',
        location_apple: 'maps://goo.gl/maps/7RnuqzvupqJxKbrQ6',
        location_waze: 'https://ul.waze.com/ul?ll=21.13477456%2C-101.62342787&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
        tels,
        email,
        closedIcon:'/assets/map/leon.png' ,
        hour:'Lunes a Sábado de 8:00 a 20:00 hrs',
        img: '/assets/map/red-nacional-desktop/card-cam/card_leon_hospital.jpg',
        alt: 'Centro de Atención Médica de AXA Keralty en León',
        expanded: false,
        buttons_information: true,
        url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/cac8004d-95dc-44cf-9571-22f63e6e235c.pdf',
        clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
        hasClarification: true,
      buttons2_information: true,
      cams: [],
    },
    /*{
      id_number: 15,
      latitude:  21.0082805,
      longitude: -101.5169491,
      title: 'León',
      square: 'Hospital Aranda de la Parra* – Puerto Interior:',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Blvd. Mineral de Cata 901 Esq Mineral de Rayas Puerto Interior, Silao de la Victoria, Guanajuato.`,
      location: 'https://goo.gl/maps/7tDJA3uYGg154hi76',
      location_apple: 'maps://goo.gl/maps/7tDJA3uYGg154hi76',
      location_waze: 'https://ul.waze.com/ul?ll=21.00825692%2C-101.51468039&navigate=yes&zoom=17&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/leon.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/guanajuato.png', 
      alt: 'Centro de Atención Médica de AXA Keralty en León',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio',
      hasClarification: true,
      hasAsterisk: true,
      button2_information: true,
      cams: [],
    },/** */
    {
      id_number: 0,
      latitude:  31.6837815,
      longitude: -106.4070093,
      title: 'Star Médica Ciudad Juárez',
      square: 'Star Médica Ciudad Juárez',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Av. Paseo de la Victoria 4370, Partido Iglesias, 32618 Cd Juárez, Chih.`,
      location: 'https://goo.gl/maps/3Fs3NtXYPg4N8vfJ8',
      location_apple: 'maps://goo.gl/maps/3Fs3NtXYPg4N8vfJ8',
      location_waze: 'https://ul.waze.com/ul?ll=31.68418122%2C-106.40648246&navigate=yes&zoom=17&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/leon.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_star_medica_cd_juarez.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en León',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio',
      hasClarification: true,
      button2_information: true,
      cams: [],
    },
    {
      id_number: 1,
      latitude:  28.659189044141442,
      longitude: -106.12952983487372,
      title: 'Chihuahua',
      square: 'CHRISTUS MUGUERZA Centro de Medicina Ambulatoria Juventud',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Ave. Valle del Ángel 3201, esq. Perif. de la Juventud, Desarrollo el Saucito, Chihuahua, Chih.`,
      location: 'https://g.page/christusmuguerzajuventud?share',
      location_apple: 'maps://goo.gl/maps/iDE6ejr7T4wCSVcv5',
      location_waze: 'https://ul.waze.com/ul?place=ChIJGYk59-1C6oYRr6o27TbkSgU&ll=28.65917940%2C-106.12953880&navigate=yes&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/leon.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/Chihuahua.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en León',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio',
      hasClarification: true,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      cams: [],
    },
    {
      id_number: 1,
      latitude:  19.0346056,
      longitude: -98.1887241,
      title: 'Puebla',
      square: 'Christus Muguerza Betania',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Avenida 11 Oriente 1826, Azcarate, CP 72501, Puebla, Pue.`,
      location: 'https://g.page/hospitalbetania?share',
      location_apple: 'maps://goo.gl/maps/hospitalbetania',
      location_waze: 'https://ul.waze.com/ul?ll=19.03422911%2C-98.18829060&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_puebla_betania_ext.jpg',
      alt: 'Christus Muguerza Betania',
      responsable_name: 'Dra. Ivonne Audiffred González.',
      responsable_education: "UPAEP",
      responsable_number: "Cédula profesional no. 4881794",
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty.',
      hasClarification: true,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      cams: [],
    },
    {
      id_number: 18,
      latitude:  19.0457258,
      longitude: -98.2050069,
      title: 'Puebla',
      square: 'Christus Muguerza UPAEP',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Avenida 5 Poniente 715, Centro, CP 72000, Puebla, Pue.`,
      location: 'https://goo.gl/maps/igSZWNCid3PBjWdK6',
      location_apple: 'maps://goo.gl/maps/igSZWNCid3PBjWdK6',
      location_waze: 'https://ul.waze.com/ul?ll=19.04565900%2C-98.20517778&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_puebla_upaep_ext.jpg',
      alt: 'Christus Muguerza UPAEP',
      clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty.',
      hasClarification: true,
      button2_information: true,
      cams: [],
    },
    {
      id_number: 19,
      latitude:  20.7016465,
      longitude: -103.3768648,
      title: 'Guadalajara',
      square: 'Clínica Punto Sao Paulo',
      parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty.',
      description: `Centro Comercial Punto Sao Paulo, Local J21. São Paulo 1912, Providencia, CP 44630, Guadalajara, Jalisco. `,
      location: 'https://goo.gl/maps/7wsQvpAFZefszbiz7',
      location_apple: 'maps://goo.gl/maps/7wsQvpAFZefszbiz7',
      location_waze: 'https://ul.waze.com/ul?ll=20.70219961%2C-103.37675571&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_generica_guadalajara.jpg',
      alt: 'Clínica Punto Sao Paulo',
      responsable_name: 'Dra. Gabriela Ruiz Segura',
      responsable_education: "Universidad de Guadalajara",
      responsable_number: "Cédula: 10914166",
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      expanded: false,
      cams: [],
    },
    {
      id_number: 0,
      latitude: 20.5862428,
      longitude: -100.3822634,
      title: 'Querétaro',
      square: 'Clínica Puerta La Victoria',
      parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
      description: `Centro Comercial Puerta La Victoria, local LO-R11. Av. Constituyentes Oriente 40, Colonia Villas del Sol, CP 76046, Querétaro, Querétaro.`,
      location: 'https://goo.gl/maps/rQFExuq53KZXvbQ96',
      location_apple: 'maps://goo.gl/maps/rQFExuq53KZXvbQ96',
      location_waze: 'https://ul.waze.com/ul?ll=20.58542510%2C-100.38080335&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/queretaro2_generica.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Querétaro',
      responsable_name: 'Dra. Katyanna Quiñonez Ortiz',
      responsable_education: "Universidad de Guadalajara",
      responsable_number: "Cédula profesional no. 11508793",
      expanded: false,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      cams: [],
    },
    {
      id_number: 18,
      latitude:  21.0425329,
      longitude: -89.6035045,
      title: 'Mérida',
      square: 'Hospital Faro del Mayab*',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
      description: `Calle 24 S/N, Temozón Norte, Santa Gertrudis Copo, C.P. 97305 Mérida, Yucatán.`,
      location: 'https://g.page/farodelmayab?share',
      location_apple: 'https://g.page/farodelmayab?share',
      location_waze: 'https://ul.waze.com/ul?ll=21.04391178%2C-89.60179925&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_merida.jpg',
      alt: 'Hospital Faro del Mayab',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio',
      hasClarification: true,
      buttons2_information: true,
      cams: [],
    },
    {
      id_number: 20,
      latitude:  25.5402243,
      longitude: -103.4430572,
      title: 'Torreón',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      square: 'Hospital Andalucía*',
      description: `Avenida Matamoros 542 Oriente, Primero de Cobián Centro, CP 27000 Torreón, Coahuila.`,
      //help_information: 'Piso 1, a un costado de Centauro. Frente al Rodeo Santa Fe.',
      location: 'https://goo.gl/maps/KU9uzU9B5JqxU3cq5',
      location_apple: 'https://goo.gl/maps/KU9uzU9B5JqxU3cq5',
      location_waze: 'https://ul.waze.com/ul?ll=25.54012786%2C-103.44284534&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_generica_andalucia.jpg',
      alt: 'Hospital Andalucía*',
      closed:'',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio',
      hasClarification: true,
      buttons2_information: true,
      cams: [],
    },
    {
      id_number: 21,
      latitude: 25.4771573,
      longitude: -100.9775868,
      title: 'Saltillo',
      square: 'CHRISTUS MUGUERZA Hospital Saltillo',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
      description: `Blvd. Luis Donaldo Colosio, Sin Nombre de Col 25, C.P. 25204, Saltillo, Coahuila.`,
      location: 'https://goo.gl/maps/CCq5JDsGk85Uhdv4A',
      location_apple: 'maps://goo.gl/maps/CCq5JDsGk85Uhdv4A',
      location_waze: 'https://ul.waze.com/ul?ll=25.47771144%2C-100.97776651&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Para consultar horarios de acuerdo con el servicio requerido, llamar al 55 5169 3080, opción 4.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_coahuila_saltillo_muguerza.jpg',
      alt: 'CHRISTUS MUGUERZA Hospital Saltillo',
      expanded: false,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/3bd8597b-a896-445e-a98f-3a6ae2ed07cd.pdf',
      clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
      hasClarification: true,
      asterisk: true
    },
    {
      id_number: 22,
      latitude:  22.1517165,
      longitude: -100.9840202,
      title: 'San Luis Potosí',
      square: 'Star Médica San Luis Potosí*',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Mariano Arista 735, Tequisquiapan, CP 78250 San Luis, San Luis Potosí.`,
      location: 'https://goo.gl/maps/RTw8JX2kS3qeQm7M6',
      location_apple: 'maps://goo.gl/maps/RTw8JX2kS3qeQm7M6',
      location_waze: 'https://ul.waze.com/ul?place=ChIJi6Lc1KqYKoQRckipRPyUyeY&ll=22.15205630%2C-100.98338970&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/terranova.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_san_luis_star.jpg',
      alt: 'Star Médica San Luis Potosí*',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio',
      hasClarification: true,
      buttons2_information: true,
      cams: [],
    },
    {
      id_number: 23,
      latitude: 21.8982288,
      longitude: -102.3050549,
      title: 'Aguascalientes',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      square: 'Star Médica Aguascalientes*',
      description: `Avenida Universidad 101, Villas de la Universidad, CP 20020, Aguascalientes, Aguascalientes.`,
      help_information: '',
      location: 'https://goo.gl/maps/vRV66oiugobKEKG99',
      location_apple: 'maps://goo.gl/maps/vRV66oiugobKEKG99',
      location_waze: 'https://ul.waze.com/ul?ll=21.89821650%2C-102.30445147&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/card_aguascalientes_star.jpg',
      alt: 'Star Médica Aguascalientes*',
      closed:'',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio',
      hasClarification: true,
      buttons2_information: true,
      cams: [],
    },
    {
      id_number: 24,
      latitude:19.3046543,
      longitude:-99.165357,
      title: 'Clínica Gran Sur Centro Comercial Gran Sur.',
      parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
      square: 'Centro Comercial Gran Sur',
      description: `Periférico Sur 5550,  Local A2-98, Pedregal de Carrasco, Alcaldía Coyoacán, CP 04700 Ciudad de México, CDMX.`,
      help_information: '',
      location: 'https://goo.gl/maps/n6ZrhZoHGLUiVPK98',
      location_apple: 'maps://goo.gl/maps/n6ZrhZoHGLUiVPK98',
      location_waze: 'https://ul.waze.com/ul?preview_venue_id=170918081.1709377419.294245&navigate=yes',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/gran_sur_v1.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Gran Sur',
      responsable_name: 'Raúl Segura Córdova',
      responsable_education: "UNAM",
      responsable_number: "Cédula profesional no. 7817645",
      closed:'',
      buttons2_information: true,
      expanded: false,
      cams: [],
    },
    {
      id_number: 25,
      latitude:  31.6837815,
      longitude: -106.4070093,
      title: 'Chihuahua',
      square: 'Star Médica',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Av. Paseo de la Victoria 4370, Partido Iglesias, 32618 Cd Juárez, Chih.`,
      location: 'https://goo.gl/maps/3Fs3NtXYPg4N8vfJ8',
      location_apple: 'maps://goo.gl/maps/3Fs3NtXYPg4N8vfJ8',
      location_waze: 'https://ul.waze.com/ul?ll=31.68418122%2C-106.40648246&navigate=yes&zoom=17&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/leon.png' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_star_medica_cd_juarez.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en León',
      expanded: false,
      buttons2_information: true,
      cams: [],
    },
    {
      id_number: 26,
      latitude: 32.524912,
      longitude: -117.0209571,
      title: 'Tijuana',
      square: 'Centro Médico Premier*',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
      description: `Antonio Caso 2055, Zona Urbana Río Tijuana, C.P. 22010, Tijuana, B.C.`,
      location: 'https://goo.gl/maps/s4mdVbjM1ExN6nCAA',
      location_apple: 'maps://goo.gl/maps/s4mdVbjM1ExN6nCAA',
      location_waze: 'https://ul.waze.com/ul?ll=32.52476145%2C-117.02084184&navigate=yes&zoom=17&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card_generica_centro_medico_premier.jpg' ,
      hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_generica_centro_medico_premier.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Tijuana',
      expanded: false,
      button2_information: true,
      cams: [],
    },
    {
      id_number: 27,
      latitude:  28.6516751,
      longitude: -106.1312183,
      title: 'Chihuahua',
      square: 'Clínica Plaza San Ángel',
      parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
      description: `Plaza San Ángel, local 4101-1. Periférico de la Juventud 4101, Valle del Ángel, CP 31115 Chihuahua, Chihuahua.`,
      location: 'https://goo.gl/maps/SwCrgnXGiWGbXjUd9',
      location_apple: 'maps://goo.gl/maps/SwCrgnXGiWGbXjUd9',
      location_waze: 'https://ul.waze.com/ul?ll=28.65189882%2C-106.13120198&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_generica_chihuahua_3.jpg',
      alt: 'Clínica Plaza San Ángel',
      responsable_name: 'Dr. Octavio Alejandro Lerma Corral',
      responsable_education: "Universidad Autónoma de Ciudad de Juárez",
      responsable_number: "Cédula: 9554865",
      expanded: false,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      clarification: '',
      hasClarification: true,
    },
    {
      id_number: 28,
      latitude:  32.6642191,
      longitude: -115.4860231,
      title: 'Mexicali',
      square: 'Hospital Hispano Americano',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Sucursal Reforma. Av. Reforma 1000, Segunda, C.P. 21100, Mexicali, B.C.`,
      location: 'https://goo.gl/maps/62DfP8SxTvi114cW8',
      location_apple: 'maps://goo.gl/maps/62DfP8SxTvi114cW8',
      location_waze: 'https://ul.waze.com/ul?ll=32.66381002%2C-115.47746658&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Para consultar horarios de acuerdo con el servicio requerido, llamar al 55 5169 3080, opción 4.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_bc_mexicalli_reforma.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Mexicali',
      expanded: false,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/5447dc15-92cb-41db-86d2-9e6495232384.pdf',
      clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
      hasClarification: true,
    },
    {
      id_number: 29,
      latitude:  32.6031092,
      longitude: -115.4076559,
      title: 'Mexicali',
      square: 'Hospital Hispano Americano',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Sucursal Carranza. Boulevard Venustiano Carranza 1471, González Ortega Poniente, C.P. 21397, Mexicali, B.C.`,
      location: 'https://goo.gl/maps/1aeJjChGVw4s1kje6',
      location_apple: 'maps://goo.gl/maps/1aeJjChGVw4s1kje6',
      location_waze: 'https://ul.waze.com/ul?ll=32.60326549%2C-115.40552974&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\n Para consultar horarios de acuerdo con el servicio requerido, llamar al 55 5169 3080, opción 4.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_bc_mexicalli_carranza.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Mexicali',
      expanded: false,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/5447dc15-92cb-41db-86d2-9e6495232384.pdf',
      clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
      hasClarification: true,
    },

    {
      id_number: 0,
      latitude: 25.6744838,
      longitude: -100.3380428,
      title: 'Monterrey',
      square: 'Christus Muguerza Hospital Conchita',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
      description: `15 de Mayo 1822, Maria Luisa, Nuevo Obispado, 64040 Monterrey, N.L.  `,
      help_information: '',
      location: 'https://goo.gl/maps/JXMVXKn4t5eUv5K18',
      location_apple: 'maps://goo.gl/maps/JXMVXKn4t5eUv5K18',
      location_waze: 'https://ul.waze.com/ul?ll=25.67529709%2C-100.33801675&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
      hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
      img: '/assets/map/red-nacional-desktop/card-cam/card_monterrey_conchita.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty.',
      hasClarification: true,
      buttons2_information: true,
      doubleAsterisk: true,
      cams: [],
    },
    {
      id_number: 0,
      latitude: 25.7186697,
      longitude: -100.2444989,
      title: 'Monterrey',
      square: 'Christus Muguerza Hospital San Nicolás',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
      description: `Av. Rómulo Garza 1100, Hacienda los Morales 3er Sector, 66495 San Nicolás de los Garza, N.L. `,
      help_information: '',
      location: 'https://g.page/muguerzasannicolas?share',
      location_apple: 'maps://goo.gl/maps/muguerzasannicolas',
      location_waze: 'https://ul.waze.com/ul?ll=25.71886962%2C-100.24232626&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
      hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
      img: '/assets/map/red-nacional-desktop/card-cam/card_monterrey_san_nicolas.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty.',
      hasClarification: true,
      buttons2_information: true,
      doubleAsterisk: true,
      cams: [],
    },
    {
      id_number: 0,
      latitude: 25.6521959,
      longitude: -100.3713063,
      title: 'Monterrey',
      square: 'Christus Muguerza Clínica San Pedro',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
      description: `Calz San Pedro #325, Col. del Valle, Del Valle, 66220 San Pedro Garza García, N.L. `,
      help_information: '',
      location: 'https://g.page/christusmuguerzasanpedro?share',
      location_apple: 'maps://goo.gl/maps/christusmuguerzasanpedro',
      location_waze: 'https://ul.waze.com/ul?ll=25.65243617%2C-100.37107229&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
      hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
      img: '/assets/map/red-nacional-desktop/card-cam/card_monterrey_san_pedro.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty.',
      hasClarification: true,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/b5dd1160-390c-4326-8cf5-bc6e5bb11110.pdf',
      doubleAsterisk: true,
      cams: [],
    },
    {
      id_number: 0,
      latitude: 25.5894239,
      longitude: -100.2598844,
      title: 'Monterrey',
      square: 'Christus Muguerza Hospital Sur',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
      description: `Carr. Nacional 6501, La Estanzuela, 64988 Monterrey, N.L.  `,
      help_information: '',
      location: 'https://goo.gl/maps/njsruzgRa38c2h8N6',
      location_apple: 'maps://goo.gl/maps/njsruzgRa38c2h8N6',
      location_waze: 'https://ul.waze.com/ul?ll=25.58961380%2C-100.25779724&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
      hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
      img: '/assets/map/red-nacional-desktop/card-cam/card_monterrey_hospital_sur.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty.',
      hasClarification: true,
      buttons2_information: true,
      cams: [],
    },
    {
      id_number: 0,
      latitude: 25.6872832,
      longitude: -100.3087642,
      title: 'Monterrey',
      square: 'Christus Muguerza  Hospital Vidriera',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
      description: `Mariano Escobedo 1405, Treviño, 64570 Monterrey, N.L.`,
      help_information: '',
      location: 'https://goo.gl/maps/1cgrKffwhankbFKWA',
      location_apple: 'maps://goo.gl/maps/1cgrKffwhankbFKWA',
      location_waze: 'https://ul.waze.com/ul?ll=25.68757674%2C-100.30831933&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card-cam/candado.png' ,
      hour:'Lunes a sábado de 8:00 a 20:00 hrs.',
      img: '/assets/map/red-nacional-desktop/card-cam/card_monterrey_vidriera.jpg',
      alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio con referencia de los médicos de Clínica AXA Keralty.',
      hasClarification: true,
      buttons2_information: true,
      doubleAsterisk: true,
      cams: [],
    },
    {
      id_number: 0,
      latitude: 19.1719409,
      longitude: -96.1902646,
      title: 'Veracruz',
      square: 'Hospital Star Médica Veracruz',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty.',
      description: `Av. Circuito Nuevo Veracruz 835 Col. El Laurelal de Buenavista, C.P. 91698 Veracruz, Veracruz.`,
      location: 'https://goo.gl/maps/vWzimwe9qwjrcEBk8',
      location_apple: 'maps://goo.gl/maps/vWzimwe9qwjrcEBk8',
      location_waze: 'https://ul.waze.com/ul?place=ChIJxUbs8YNDw4URykAVU4c5UBQ&ll=19.17194090%2C-96.19026460&navigate=yes&utm_campaign=waze_website&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'/assets/map/red-nacional-desktop/card_veracruz_star_medica.jpg' ,
      hour:'Llama al 55 5169 3080 (opción 4) para consultar los horarios disponibles de acuerdo al servicio que requieras.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_generica_centro_medico_premier.jpg',
      alt: 'Hospital Star Médica Veracruz',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio.',
      hasClarification: true,
      button2_information: true,
      doubleAsterisk: false,
    },
    {
      id_number: 0,
      latitude: 19.6826667,
      longitude: -101.1939679,
      title: 'Morelia',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      square: 'Star Médica Morelia',
      description: `Av. Virrey de Mendoza No. 2000 Col. Félix Ireta, C.P. 58070, Morelia, Michoacán.`,
      help_information: '',
      location: 'https://goo.gl/maps/THYzaL5U8PYNe3Em6',
      location_apple: 'maps://goo.gl/maps/THYzaL5U8PYNe3Em6',
      location_waze: 'https://ul.waze.com/ul?place=ChIJm_TNb9sNLYQRuwg5Ev4IUkU&ll=19.68278870%2C-101.19183890&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'' ,
      hour:'Llamar al 55 5169 3080 (Opción 4) para consultar los horarios de acuerdo al servicio que se requiera',
      img: 'assets/map/red-nacional-desktop/card-cam/card_morelia_star_medica_2771.jpg',
      alt: 'Star Médica Morelia',
      closed:'',
      button2_information: true,
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio.',
      hasClarification: true,
    },
    {
      id_number: 0,
      latitude: 24.9262373,
      longitude: -100.8889474,
      title: 'Reynosa',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      square: 'Christus Muguerza Hospital Reynosa*',
      description: `Tiburcio Garza Zamora, Kilómetro 5.5, Rancho Grande, C.P.88610 Reynosa, Tamaulipas.`,
      help_information: '',
      location: 'https://goo.gl/maps/AaFEQPUDPyEEXDdXA',
      location_apple: 'maps://goo.gl/maps/AaFEQPUDPyEEXDdXA',
      location_waze: 'https://ul.waze.com/ul?place=ChIJeVMb3y4PZYYRcHZM92KPoUA&ll=26.11051960%2C-98.32996200&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'' ,
      hour:'Llamar al 55 5169 3080 (opción 4) para consultar los horarios de acuerdo al servicio que se requiera.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_reynosa.jpg',
      alt: 'Christus Muguerza Hospital Reynosa*',
      closed:'',
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/2aa3a409-2876-46a9-9f26-96f62a50540e.pdf',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
      hasClarification: true,
    },
    {
      id_number: 0,
      latitude: 22.235637,
      longitude: -97.8716132,
      title: 'Tampico',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      square: 'MAC Hospitales Tampico',
      description: `Av. Miguel Hidalgo 1900, Altavista 89240 Tampico, Tamaulipas.`,
      help_information: '',
      location: 'https://goo.gl/maps/yPDkwKFTxNtd9BcX9',
      location_apple: 'maps://goo.gl/maps/yPDkwKFTxNtd9BcX9',
      location_waze: 'https://ul.waze.com/ul?ll=22.23465500%2C-97.86765933&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\nPara consultar horarios de acuerdo al servicio que se requiera, llamar al 55 5169 3080, opción 4.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_tampico_tamaulipas.jpg',
      alt: 'MAC Hospitales Tampico',
      closed:'',
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/06301705-b486-4955-a2e6-36b536e7a366.pdf  ',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
      hasClarification: true,
      hasResponsibleHealth: false,
      asterisk: true
      
    },
    {
      id_number: 0,
      latitude:  24.8039634,
      longitude: -107.3909895,
      title: 'Culiacán',
      square: 'Nueva Clínica Santa María',
      parking: 'El hospital cuenta con estacionamiento independiente a AXA Keralty',
      description: `Calle Francisco Villa 291 Oriente, Col. Centro, CP 80000, Culiacán, Sinaloa.`,
      location: 'https://goo.gl/maps/YWRaxJQuqK66ke9y5',
      location_apple: 'maps://goo.gl/maps/YWRaxJQuqK66ke9y5',
      location_waze: 'https://ul.waze.com/ul?ll=24.80415896%2C-107.39006996&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels,
      email,
      closedIcon:'' ,
      hour:'Lunes a Sábado de 8:00 a 20:00 hrs.\nPara consultar horarios de acuerdo al servicio que se requiera, llamar al 55 5169 3080, opción 4.',
      img: 'assets/map/red-nacional-desktop/card-cam/card_sinaloa_culiacan.jpg',
      alt: 'Nueva Clínica Santa María',
      expanded: false,
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/3/d68e185d-7120-4728-a1f9-8a9593b16004.pdf',
      clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
      hasClarification: true,
      hasResponsibleHealth: false,
      asterisk: true,
      
    },
    
    {
      id_number: 2,
      latitude: 19.2833794,
      longitude: -99.6755577,
      title: 'Toluca',
      square: 'ARQUIMEDES & DORSCH LABORATORIOS*:',
      description: `Vicente Guerrero. Paseo Gral Vicente Guerrero 484, Federal, CP. 50080, Toluca de Lerdo, Estado de México.`,
      help_information: '',
      location: 'https://goo.gl/maps/rM7nQtG4wQJQBVK39',
      location_apple: 'maps://goo.gl/maps/rM7nQtG4wQJQBVK39',
      location_waze: 'https://ul.waze.com/ul?place=ChIJFU-9IbSJzYURNAueDYli-Pw&ll=19.28337940%2C-99.67336900&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels:'',
      email,
      closedIcon:'' ,
      hour:'Para laboratorios y estudios de gabinete, llama al:\n 722 167 0240 \n\n Para consultas y otros servicios, llama al:\n 55 5169 3080',
      img: 'assets/map/red-nacional-desktop/card-cam/card_generica_laboratorios.jpg',
      alt: 'ARQUIMEDES & DORSCH LABORATORIOS',
      responsable_name: '',
      responsable_education: "",
      responsable_number: "",
      closed:'',
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/80e1ee11-8f46-4083-98f1-89f789903182.pdf',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
      hasClarification: true,
      hasResponsibleHealth: false,
    
    },
    {
      id_number: 3,
      latitude: 19.2880877,
      longitude: -99.6486614,
      title: 'Toluca',
      square: 'ARQUIMEDES & DORSCH LABORATORIOS*:',
      description: `Centro. Alejandro Von Humbolt No. 303, Colonia San Sebastian, CP. 50150,Toluca de Lerdo, Estado de México.`,
      help_information: '',
      location: 'https://goo.gl/maps/5EZFuvuzYgsHrKxt9',
      location_apple: 'maps://goo.gl/maps/5EZFuvuzYgsHrKxt9',
      location_waze: 'https://ul.waze.com/ul?ll=19.28824869%2C-99.64852810&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels:'',
      email,
      closedIcon:'' ,
      hour:'Para laboratorios y estudios de gabinete, llama al:\n 722 167 0240 \n\n Para consultas y otros servicios, llama al:\n 55 5169 3080',
      img: 'assets/map/red-nacional-desktop/card-cam/card_generica_laboratorios.jpg',
      alt: 'ARQUIMEDES & DORSCH LABORATORIOS',
      responsable_name: '',
      responsable_education: "",
      responsable_number: "",
      closed:'',
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/80e1ee11-8f46-4083-98f1-89f789903182.pdf',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
      hasClarification: true,
      hasResponsibleHealth: false,
    
    },
    {
      id_number: 4,
      latitude: 19.3530853,
      longitude: -99.6072996,
      title: 'Otzacatipan',
      square: 'ARQUIMEDES & DORSCH LABORATORIOS*:',
      description: `Los Sauces. Matamoros 127, Locales D y E, Colonia San José Guadalupe Otzacatipan, CP. 50200, Otzacatipan, Estado de México`,
      help_information: '',
      location: 'https://goo.gl/maps/Zf8DMbhaejrusPTB7',
      location_apple: 'maps://goo.gl/maps/Zf8DMbhaejrusPTB7',
      location_waze: 'https://ul.waze.com/ul?ll=19.35394708%2C-99.59998012&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels:'',
      email,
      closedIcon:'' ,
      hour:'Para laboratorios y estudios de gabinete, llama al:\n 722 223 7604 \n\n Para consultas y otros servicios, llama al:\n 55 5169 3080',
      img: 'assets/map/red-nacional-desktop/card-cam/card_generica_laboratorios.jpg',
      alt: 'ARQUIMEDES & DORSCH LABORATORIOS',
      responsable_name: '',
      responsable_education: "",
      responsable_number: "",
      closed:'',
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/80e1ee11-8f46-4083-98f1-89f789903182.pdf',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
      hasClarification: true,
      hasResponsibleHealth: false,
    
    },
    {
      id_number: 5,
      latitude: 19.2605875,
      longitude: -99.6132003,
      title: 'Metepec',
      square: 'ARQUIMEDES & DORSCH LABORATORIOS*:',
      description: `Plaza Rubí. Avenida Leona Vicario No. 501 Barrio de Coaxustenco, Plaza Rubí Locales 9,10 y 11, CP. 52140, Metepec, Estado de México`,
      help_information: '',
      location: 'https://goo.gl/maps/S5RAXVxaWsFVXd5h7',
      location_apple: 'maps://goo.gl/maps/S5RAXVxaWsFVXd5h7',
      location_waze: 'https://ul.waze.com/ul?ll=19.26162366%2C-99.61907744&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
      tels:'',
      email,
      closedIcon:'' ,
      hour:'Para laboratorios y estudios de gabinete, llama al:\n 722 238 2182 \n\n Para consultas y otros servicios, llama al:\n 55 5169 3080',
      img: 'assets/map/red-nacional-desktop/card-cam/card_generica_laboratorios.jpg',
      alt: 'ARQUIMEDES & DORSCH LABORATORIOS',
      responsable_name: '',
      responsable_education: "",
      responsable_number: "",
      closed:'',
      buttons_information: true,
      url_button:'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/80e1ee11-8f46-4083-98f1-89f789903182.pdf',
      expanded: false,
      clarification: 'Proveedor en convenio que brinda el servicio para usuarios Planmed®.',
      hasClarification: true,
      hasResponsibleHealth: false,
    
    },
  ],
  CONFIG: {
    latitude: 19.4654423,
    longitude: -99.2416361,
    zoom: 11,
    iconLocation: {url: '/assets/images/pin_locator-Cerulean.png', scaledSize: {width: 36, height: 47}},
    cooperativeZoom: 24,
    noneZoom: 15,
    resolutionMedium: 768,
    maxHeight: 500,
    minHeight: 200
  }
};
