import { IEventGTM } from './../../../interfaces/gtmEvent.interface';
/** import Angular Component, OnInit */
import { Component, OnInit } from '@angular/core';
/**
 * HowItWorksComponent
 */
@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

   /** variable booleana de estado: decide si los elementos se muestran o no*/
  isShow = true;
   /** variable booleana de estado: decide si los elementos se muestran o no*/
  isShowTwo = true;
   /** variable booleana de estado: decide si los elementos se muestran o no*/
  isShowThree = true;
   /** variable booleana de estado: decide si los elementos se muestran o no*/
  isShowFour = true;


  /** constructor*/
  constructor() { }

  /** inicio de componente*/
  ngOnInit() {
  }

 /**
 * Función para esconder elementos haciendo click en otro elemento
 */
  hideElements() {

    /** Envía evento al dataLayer para taggeo */
    const eventGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'helpUsers',
      eventAction: 'usuario Yolanda',
      eventLabel: 'position1'
    }

    window.dataLayer.push(eventGtm);

    this.isShow = !this.isShow;
  }
/**
 * Función para esconder elementos haciendo click en otro elemento
 */
  hideElementsTwo() {
    /** Envía evento al dataLayer para taggeo */
    const eventGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'helpUsers',
      eventAction: 'usuario Amanda',
      eventLabel: 'position2'
    }

    window.dataLayer.push(eventGtm);

    this.isShowTwo = !this.isShowTwo;
  }
/**
 * Función para esconder elementos haciendo click en otro elemento
 */
  hideElementsThree() {
    /** Envía evento al dataLayer para taggeo */
    const eventGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'helpUsers',
      eventAction: 'usuario Ricardo',
      eventLabel: 'position3'
    }

    window.dataLayer.push(eventGtm);

    this.isShowThree = !this.isShowThree;
  }
/**
 * Función para esconder elementos haciendo click en otro elemento
 */
  hideElementsFour() {
    /** Envía evento al dataLayer para taggeo */
    const eventGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'helpUsers',
      eventAction: 'usuario Vicente',
      eventLabel: 'position4'
    }

    window.dataLayer.push(eventGtm);
    
    this.isShowFour = !this.isShowFour;
  }

}
