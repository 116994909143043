<a class="float-up" id="btn_scrollTop" *ngIf="onTop">
  <a (click)="scrollToTop()" rel="noopener noreferrer" id="btn_scrollTop"
    ><img
      src="assets/top.png"
      id="btn_scrollTop"
      class="img-footer"
      alt="go-to-up"
      width="38px"

  /></a>
</a>
