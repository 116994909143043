<div class="background pb-5">
<div class="container mb-5 px-4 px-lg-4">
  <div class="row">
    <div class="col-12 text-center my-5 contact-us_title">
      <p>Contáctanos</p>
    </div>
  </div>
  <div class="row contact-us_card">
      <div class="col-lg-8 pb-lg-5 pt-3">
          <div class="text-center mb-lg-4 mb-4 contact-us_form-title">
            <p class="mb-0">¿Deseas más información?</p>
            <p>¡Déjanos tus datos y te contactaremos!</p>
          </div>
          <div class="row">
              <div class="col-12 px-5">
                <form [formGroup]="contactForm" id="form_contact">
                  <div class="row">
                    <div class="form-group col-12 col-lg-6">
                        <label [ngClass]="contactForm.get('name').hasError('pattern') && nameBlur ? 'input-error' : ''">
                      Nombre(s)</label>
                        <input type="text" class="form-control" formControlName="name" placeholder="Nombre(s)" maxlength="85"
                               (blur)=" contactForm.get('name').value ? nameBlur = true : nameBlur = false"
                               [ngClass]="contactForm.get('name').hasError('pattern') && nameBlur ? 'input-error' : ''" (click)="sendTag('nombre')">
                    </div>
                    <div class="form-group col-12 col-lg-6">
                      <label [ngClass]="contactForm.get('lastName').hasError('pattern') && lastNameBlur ? 'input-error' : ''">
                        Primer apellido
                      </label>
                      <input type="text" class="form-control" placeholder="Apellido paterno" (click)="sendTag('appat')" formControlName="lastName"
                      (blur)=" contactForm.get('lastName').value ? lastNameBlur = true : lastNameBlur = false"
                      [ngClass]="contactForm.get('lastName').hasError('pattern') && lastNameBlur ? 'input-error' : ''">
                  </div>

                  <div class="form-group col-12 col-lg-6">
                    <label for="" [ngClass]="contactForm.get('lastNameSecond').hasError('pattern') && lastNameSecondBlur ? 'input-error' : ''">
                      Segundo apellido
                    </label>
                    <input type="text" class="form-control" placeholder="Apellido materno" formControlName="lastNameSecond"
                    (blur)=" contactForm.get('lastNameSecond').value ? lastNameSecondBlur = true : lastNameSecondBlur = false"
                    [ngClass]="contactForm.get('lastNameSecond').hasError('pattern') && lastNameSecondBlur ? 'input-error' : ''" (click)="sendTag('apmat')">
                </div>

                  <div class="form-group col-12 col-lg-6">
                    <label>Sexo</label>
                    <select class="form-control select-form" formControlName="gender" (click)="sendTag('sexo')" [ngClass]="selectedOptionTwo ? 'selected-option' : ''" (change)="clickEvents()">
                      <option class="select-text d-none" value="" disabled selected>Selecciona tu sexo</option>
                      <option value="H">Hombre</option>
                      <option value="M">Mujer</option>
                    </select>
                  </div>

                  <div class="form-group col-12 col-lg-6">
                    <label>Estado/entidad de nacimiento</label>
                    <select class="form-control select-form" formControlName="bornState" (click)="sendTag('estadoNac')"
                            [ngClass]="selectedOption ? 'selected-option' : ''" (change)="clickEvent()">
                      <option class="select-text d-none" value="" disabled selected>Selecciona tu estado</option>
                      <option *ngFor="let states of STATES" value="{{states.id}}">{{states.name}}</option>
                    </select>
                  </div>

                  <div class="form-group col-12 col-lg-6">
                    <label [ngClass]="contactForm.get('birthday').invalid && dateBlur ? 'input-error' : ''">Fecha de nacimiento</label>
                    <input
                     (click)="sendTag('fechaNac')"
                      class="form-control"
                      bsDatepicker
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                      type="text"
                      placeholder="DD-MM-AA"
                      minlength="8"
                      maxlength="8"
                      [maxDate]="dateToday"
                      (blur)=" contactForm.get('birthday').value ? dateBlur = true : dateBlur = false"
                      [ngClass]="contactForm.get('birthday').invalid && dateBlur ? 'input-error' : ''"
                      formControlName="birthday">
                </div>

                    <div class="form-group col-12 col-lg-6">
                        <label [ngClass]="contactForm.get('email').hasError('pattern') && emailBlur ? 'input-error' : ''">
                          Correo electrónico</label>
                          <input type="email" class="form-control" oninput="this.value = this.value.toLowerCase()" placeholder="ejemplo@ejemplo.com.mx" formControlName="email" (click)="sendTag('correo')"
                          [ngClass]="contactForm.get('email').hasError('pattern') && emailBlur ? 'input-error' : ''"
                          (blur)=" contactForm.get('email').value ? emailBlur = true : emailBlur = false">
                    </div>
                   
                    <div class="form-group col-12 col-lg-6">
                        <label [ngClass]="contactForm.get('phone').invalid && phoneBlur ? 'input-error' : ''">Teléfono</label>
                        <input type="tel" class="form-control" placeholder="(55) 5555-5555" formControlName="phone" mask="(00) 00 000 000"
                               [ngClass]="contactForm.get('phone').invalid && phoneBlur ? 'input-error' : ''"
                               (blur)=" contactForm.get('phone').value ? phoneBlur = true : phoneBlur = false" (click)="sendTag('tel')">
                    </div>
                  
                   
                    <div class="col-12 col-lg-6 text-center contact-us_legal mt-3">
                      <p class=" text-justify">AXA Salud, S.A. de C.V., con domicilio en Félix Cuevas No. 366, Piso 3, Colonia Tlacoquemécatl, C.P. 03200,
                        Alcaldía Benito Juárez, Ciudad de México, México, llevará a cabo el tratamiento de sus datos personales para contactarlo con objeto de ofrecerle sus productos y para realizar su cotización, así como para las demás finalidades contempladas en el aviso de privacidad integral, el cual puede consultar en axa.mx en la sección Aviso de Privacidad.
                      </p>
                    </div>
                    <div class="col-12 col-lg-6 form-group my-3 text-center  text-lg-left alineation-mobile">
                      <div class="check-container cursor-pointer mb-2 d-flex">
                        <label class=" fs-12 text-justify text-check form-check-label input-check-mobile" id="chk_cotizaP_tdcContractorData">
                            <input class="check" type="checkbox"
                            id="policyCheck" formControlName="privacyNotice"
                            (click)="sendTag('chkPrivacidad')" 
                                 />
                            <span class="checkmark"  id="chk_cotizaP_tdcContractorData"></span>
                            <a id="link_privacy" (click)="sendTag('linkPrivacidad')" style="color: #4f9aaf;"  [href]="PRIVACY" target="_blank" rel="noopener noreferrer">Acepto el Aviso de Privacidad</a>
                        </label>
                    </div>
                    <div class="check-container cursor-pointer mb-2 d-flex">
                      <label class=" fs-12 text-justify text-check form-check-label" id="chk_cotizaP_tdcContractorData">
                          <input class="check" type="checkbox"
                          id="termsCheck" formControlName="termsConditions"
                          (click)="sendTag('chkTerminos')"
                               />
                          <span class="checkmark"  id="chk_cotizaP_tdcContractorData"></span>
                          <a (click)="sendTag('linkTerminos')" style="color: #4f9aaf;" [routerLink]="TERMS" target="_blank" rel="noopener noreferrer">Acepto los Términos y Condiciones del Servicio</a>
                      </label>
                  </div>
                      <div class="contact-us_legal">
                        <p class="pt-3">*Todos los campos del formulario son obligatorios</p>
                        <p class="fs-10 cl-blue-keralty m-0">
                          Protegido por reCAPTCHA
                          <a href="https://policies.google.com/privacy?hl=es-419" target="_blank" id="link_cotizaP_privacyCaptcha" rel="noopener noreferrer"
                            >Privacidad</a
                          >
                          -
                          <a href="https://policies.google.com/terms?hl=es-419" target="_blank" id="link_cotizaP_conditionsCaptcha" rel="noopener noreferrer"
                            >Condiciones</a
                          >
                        </p>
                      </div>
                    </div>
                    <div class="order-lg-10 order-11 my-3 col-12 offset-lg-3 col-lg-6 px-lg-0" >
                      <div class="row text-center">
                        <div class="col-12 text-center">
                          <p *ngIf="sendingForm" >Enviando...</p>
                          <button *ngIf="!sendingForm" id="btn_contact" class="btn btn-second mt-3" [disabled]="contactForm.invalid" #inputForm (click)="sendContact(contactForm.value)">
                            Enviar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
          </div>
      </div>
      <div class="col-lg-4 col-12 contact-us_info px-4">
            <p class="mb-1 contact-us_info__bold"><b>Si requieres asistencia</b> o tienes alguna duda
              o pregunta, márcanos al</p>
            <p class="mb-5 text-lg-left">
              <a href="tel:+525551693080" (click)="sendTag('llamadaContacto')" rel="noopener noreferrer"><b>(55) 5169 3080, opción 5.</b></a>
            </p>
            <p class="mb-1 contact-us_info__bold"><b>O envíanos un correo a:</b></p>
            <p class="text-lg-left">
              <a href="mailto:hola@axakeralty.mx" (click)="sendTag('mailContacto')" rel="noopener noreferrer" target="_blank">hola@axakeralty.mx</a></p>
      </div>
  </div>
</div>
</div>
