<div class="general-content" [style.margin-top]="changeByRefresh? '-153px':'0'">
    
    <div class="container">

        <div class="row d-flex">
          <div class="col-12">
    
            <div class="row m-0 mb-3">
              <div class="col-12">
                <p class="mt-4" 
                  style="font-family: 'Source Sans Pro SemiBold';font-size: 17px;color: #1094B9; font-weight: 600;">
    
                  <span style="cursor: pointer;" (click)="goServices()">
                    <span class="circle-arrow">
                      <span class="arrow-span">&#10140;</span> 
                    </span>
                    <span class="back-label"> Regresar a servicios</span>
                  </span>
                  
                </p>
              </div>
            </div>
    
            
          </div>
        </div>
    
      </div>

    <div class="container">
       <form>
        <div class="row d-flex justify-content-center py-4">

            <!--
            <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-center">
                </div>
                <div class="col-12 d-flex align-items-center justify-content-lg-between justify-content-center">
                    <img class="active-img" src="assets/img/breadcrumb/datos_activo_.png" alt="">
                    <img class="dotted-line" src="assets/img/breadcrumb/punteadas_.png" alt="">
                    <img class="img-inactive" src="assets/img/breadcrumb/checkout_inactivo_.png" alt="">
                    <img class="dotted-line" src="assets/img/breadcrumb/punteadas_.png" alt="">
                    <img class="img-inactive" src="assets/img/breadcrumb/pago_inactivo_.png" alt="">
                    <img class="dotted-line" src="assets/img/breadcrumb/punteadas_.png" alt="">
                    <img class="img-inactive" src="assets/img/breadcrumb/completar_inactivo_.png" alt="">
                </div>
            </div>
            -->

            

            <div class="col-lg-6 forms-container" *ngIf="serviceSelected == 'prueba-antigenos' || serviceSelected == 'prueba-covid'" >

                <div class="col-12 d-none d-lg-block" style="min-height: 105px;" > 
                    
                </div>

                <div class="col-12" *ngIf="serviceSelected == 'prueba-antigenos' ">
                    <div class="title-scheduled align-items-center text-center">
                      Antígeno SARS-CoV-2
                    </div>
                    <div class="body-scheduled text-center c-blue row">
                      <div class="col-6">
                        <p><b>Horarios</b></p>
                      </div>
                      <div class="col-6">
                        <p><b>Ciudades</b></p>
                      </div>
                      <div class="col-6 align-text-bottom">
                        <p>
                          Lunes a sábado
                          <br />
                          de 8:00 a 19:30 hrs.
                        </p>
                      </div>
                      <div class="col-6 text-left">
                        <p>
                          CDMX y Área Metropolitana, Guadalajara, Hermosillo, Monterrey, Querétaro y Tijuana.
                        </p>
                      </div>
                      <div class="col-6 align-text-bottom">
                        <p>
                          Domingo
                          <br />
                          de 9:00 a 14:30 hrs.
                        </p>
                      </div>
                      <div class="col-6 text-left">
                        <p>
                          Clínica Altavista, Clínica Parques Plaza y Clínica Cuauhtémoc (Monterrey).
                        </p>
                      </div>
      
                    </div>
                </div>


                <div class="col-12 second-schedule" *ngIf="serviceSelected == 'prueba-covid'">

                    <div class="title-scheduled align-items-center text-center">
                      PCR
                    </div>
                    <div class="body-scheduled text-center c-blue row d-block">
                      <div class="col-6 d-inline-block">
                        <p><b>Horarios</b></p>
                      </div>
                      <div class="col-6 d-inline-block">
                        <p><b>Ciudades</b></p>
                      </div>
                      <div class="col-6 d-inline-block align-text-top">
                        <p>
                          Lunes a sábado
                          <br />
                          de 8:00 a 12:30 hrs.
                        </p>
                      </div>
                      <div class="col-6 text-left d-inline-block align-text-top">
                        <p>
                          CDMX y Área Metropolitana, Guadalajara, Hermosillo, Monterrey y Querétaro.
                        </p>
                      </div>
                      <div class="col-6 d-inline-block align-text-top">
                        <p>
                          Domingo
                          <br />
                          de 9:00 a 14:30 hrs.
                        </p>
                      </div>
                      <div class="col-6 text-left d-inline-block align-text-top">
                        <p>
                          Clínica Altavista, Clínica Parques Plaza y Clínica Cuauhtémoc (Monterrey).
                        </p>
                      </div>
                    </div>
      
                </div>


            </div>

            <div class="col-lg-6 forms-container" style="max-width: 720px !important;">
                
                <!-- Progress Bar-->
                <div style="min-height: 105px;">


                    <ol class="ProgressBar">
                        <li class="ProgressBar-step is-complete">
                          
                        </li>
                        <li class="ProgressBar-step is-complete">
                          <svg class="ProgressBar-icon"><use xlink:href="#checkmark-bold"/></svg>
                          <span class="ProgressBar-stepLabel">Datos</span>
                        </li>
                        <li class="ProgressBar-step">
                          <svg class="ProgressBar-icon"><use xlink:href="#checkmark-bold"/></svg>
                          <span class="ProgressBar-stepLabel">Checkout</span>
                        </li>
                        <li class="ProgressBar-step">
                          <svg class="ProgressBar-icon"><use xlink:href="#checkmark-bold"/></svg>
                          <span class="ProgressBar-stepLabel">Éxito</span>
                        </li>
                        <li class="ProgressBar-step">
                          
                        </li>
                      </ol>


                </div>
                <!-- End Progress Bar-->



                <div class="p-4" style="background-color: rgba(215, 233, 250, 0.5);border-radius: 20px;">
                
                <div class="row pb-3">
                    <div class="col-12 text-center">
                            <div class="text-main m-auto">
                                <strong>Ingresa tus datos</strong>
                                
                            </div>
                            <div class="horizontal-line"></div>
                    </div>
                </div>
                <div class="row form-input">
                    <div class="text-center col-12 mt-3">

                        <p style="font-size: 16px;color: #164074;">¿Tienes Cuidado Integral+®, Planmed®, Flex Plus® o algún producto de GMM AXA? </p>
                        <p class="m-0 fs-11 form-group d-flex justify-content-center" >
                            <label class="radio" >
                                <input type="radio" value="Planmed" [(ngModel)]="typeUser" name="typeUser" (click)="validateTypeUser();"
                                />
                                <span  style="color: #597a8c;">Sí</span>
                            </label>
                            <label class="radio" >
                                <input type="radio" value="Externo" [(ngModel)]="typeUser" name="typeUser" (click)="validateTypeUser();"
                                />
                                <span  style="color: #597a8c;">No</span>
                            </label>
                        </p>
                        

                        <!--
                        <select class="custom-select plan-select select-bold" [(ngModel)]="typeUser" name="typeUser" style="border-radius: 6px;" 
                            [ngClass]="validTypeUser  === false ? 'invalidRFC' : ''" (change)="validateTypeUser()">
                            <option value="" disabled selected >Selecciona una opción</option>
                        <option *ngFor="let item of optionsSelect" [value]="item.value"> {{item.name}}</option>
                        </select>
                        -->
                        <div class=" ml-2 axa-icon axa-danger error-text" *ngIf="validTypeUser  === false"> El tipo de usuario es necesario para continuar, por favor seleccionalo.</div>
                    
                    </div> 
                    <div class="col-12 col-sm-6 group-input">
                        <span class="axa-icon axa-pencil"></span>
                        <input type="text" class="input-names input-bold new-imput" placeholder="Primer nombre" [(ngModel)]="firstName" (ngModelChange)="firstName = $event.toUpperCase()" name="firstName" [ngClass]="validFirstName  === false ? 'invalidRFC' : ''"  (keyup)="validateFirstName()" [patterns]="namePatterns" mask="B{40}" [specialCharacters]="['space']">
                        <div class="ml-2 axa-icon axa-danger d-flex" style="color: #DA4B4F;" *ngIf="validFirstName === false"> <div class="error-text ml-2">El primer nombre es requerido (mínimo 3 caracteres).</div></div>
                    </div> 
                    <div class="col-12 col-sm-6 group-input" *ngIf="!secondNameFlag">
                            <p class="cursor-pointer" (click)="showHideSecondName($event)"> 
                                <span class="circle"><span class="ak-icon icon-add add"></span></span>
                                <span class="spn-add">
                                    Agregar más nombres
                                </span> 
                            </p>    
                    </div> 
                    <div class="col-12 col-sm-6 group-input" *ngIf="secondNameFlag">
                        <!-- <span class="text-factura">Agregar más nombres</span> -->
                        <span class="axa-icon axa-cross cross-lnk cursor-pointer" (click)="showHideSecondName($event)"></span>
                        <input type="text" class="input-names input-bold new-imput" placeholder="Segundo nombre" [(ngModel)]="secondName" (ngModelChange)="secondName = $event.toUpperCase()" name="secondName" [patterns]="namePatterns" mask="B{40}" [specialCharacters]="['space']">
                        </div> 
                    <div class="col-12 col-sm-6 group-input">
                        <span class="axa-icon axa-pencil"></span>
                        <input type="text" class="input-names input-bold new-imput" placeholder="Primer Apellido" [(ngModel)]="fatherSurname" (ngModelChange)="fatherSurname = $event.toUpperCase()" [ngClass]="validFatherSurname  === false ? 'invalidRFC' : ''" [patterns]="namePatterns" mask="B{40}" name="fatherSurname" (keyup)="validateFatherSurname()"  [specialCharacters]="['space']">
                        <div class="ml-2 axa-icon axa-danger d-flex" *ngIf="validFatherSurname  === false"> <div class="error-text ml-2">El primer apellido es necesario para poder continuar, por favor ingrésalo.</div></div>
                    </div>
                    <div class="col-12 col-sm-6 group-input">
                        <span class="axa-icon axa-pencil"></span>
                        <input type="text" class="input-names input-bold new-imput" placeholder="Segundo Apellido" [(ngModel)]="motherSurname" (ngModelChange)="motherSurname = $event.toUpperCase()" name="motherSurname" [patterns]="namePatterns" mask="B{40}"  [specialCharacters]="['space']">
                    </div>
                    <div class="col-12 col-sm-6 group-input">
                        <span class="axa-icon axa-pencil"></span>
                        <input type="text" class="input-names input-bold new-imput" placeholder="Ingresa tu número de teléfono" [(ngModel)]="phone" [ngClass]="validPhone  === false ? 'invalidRFC' : ''" name="phone"placement="left" ngbTooltip="Esta información es necesaria para poder
                        adquirir tu servicio" (keyup)="validatePhone()" mask="(00) 0000 0000"  >
                        <div class="ml-2 axa-icon axa-danger d-flex" *ngIf="validPhone  === false"> <div class="error-text ml-2">El teléfono que ingresaste es incorrecto, por favor verifícalo y vuelve a ingresarlo.</div></div>
                        <div class="ml-2 axa-icon axa-danger d-flex"  *ngIf="invalidStrPhone  === true"> <div class="error-text ml-2"> El teléfono no es válido, favor de introducir otro.</div></div>
                    </div>
                    <div class="col-12 col-sm-6 group-input">
                        <span class="axa-icon axa-pencil"></span>
                        <input type="text" class="email-input input-bold new-imput" placeholder="Ingresa tu correo electrónico" [(ngModel)]="email" name="email" [ngClass]="validEmail  === false ? 'invalidRFC' : ''" (keyup)="validateMail()" [patterns]="emailMask" [dropSpecialCharacters]="false" mask="B{60}"placement="left" ngbTooltip="Esta información es necesaria para poder
                        adquirir tu servicio" >
                        <div class="ml-2 axa-icon axa-danger d-flex" *ngIf="validEmail  === false"> <div class="error-text ml-2"> El correo que ingresaste no es válido, verifícalo y vuelve a ingresarlo.</div></div>
                    </div>
                    <div class="text-center col-12 mt-3">
                        <p class="text-factura">¿Requieres factura? </p>
                        <p class="m-0 fs-11 form-group d-flex justify-content-center" >
                            <label class="radio" >
                            <input type="radio" [value]="true" [(ngModel)]="requireBill" name="requireBill" (click)="validateData();resetValidate()"
                                />
                            <span  style="color: #597a8c;">Sí</span>
                            </label>
                            <label class="radio" >
                            <input type="radio" [value]="false" [(ngModel)]="requireBill" name="requireBill" (click)="validateData();resetValidate()"
                                />
                            <span  style="color: #597a8c;">No</span>
                            </label>
                        </p>
                    </div>
                    <div class="col-12" *ngIf="requireBill === false">
                        <div class="check-container cursor-pointer mb-2 d-flex">
                            <label class=" fs-12 cl-blue-keralty text-justify text-check" >
                                <input
                                    class="check"
                                    type="checkbox"
                                    [(ngModel)]="declareNoBill"
                                    (click)="checkData()"
                                    name="declareNoBill"
                                    [ngModelOptions]="{ standalone: true }"
                                />
                                <span class="checkmark" 
                                ></span>
                                Declaro no requerir factura y estoy consciente de que me será imposible solicitarla al término de la compra.
                            </label>
                        </div>
                    </div>
                    <div class="w-100 mt-4 row m-0" *ngIf="requireBill">
                        <!--
                        <div class="col-12 text-center">
                            <img src="/assets/Linea_grande.png" class="ico-line mb-2" alt="">
                        </div>
                        -->

                        <div class="container-valid-rfc text-center w-100" *ngIf="validRFC">

                            <div *ngIf="validRFC" class="col-12 d-flex text-center position-relative justify-content-center"
                                style="    margin-top: 5px;
                                font-weight: 600;"
                                >
                                    <div  class="content-check d-flex justify-content-center align-items-center" >
                                        <em class="axa-icon axa-check"></em>
                                    </div>
                            </div>
                            <div *ngIf="validRFC" class="col-12 d-flex text-center position-relative justify-content-center"
                                style="    margin-top: 15px;
                                font-weight: 600;">
                                
                                <span class="fs-14 info-rfc"> RFC Validado</span>
                            </div>

                            <div *ngIf="validRFC" class="col-12 group-input text-center"
                                style="    
                                text-transform: uppercase;
                                margin-top: 10px;">
                                    {{RFC}}
                            </div>

                        </div>

                        

                        <!-- RFC --> 
                        <div *ngIf="!validRFC" class="m-md-2">
                            <div class="rfc-wrapper">
                                <div class="col-12 text-center">
                                    <p class="fs-16 info-rfc mb-4">
                                    <strong class="d-block">Ingresa tu RFC con homoclave y valídalo:</strong>
                                    <span class="d-block mt-1">
                                        Sólo se permite RFC de personas físicas, 
                                        si deseas facturar a nombre de tu empresa escríbenos a 
                                        <a href="mailto:facturacion@axakeralty.mx" target="_blank">facturacion@axakeralty.mx</a>
                                    </span>
                                    </p>
                                    <p class="info-rfc text-left">
                                        <span>RFC con homoclave:</span>
                                    </p>
                                </div>
                                <!-- INPUT RFC -->
                                <div class="col-12 d-flex">
                                <input 
                                    name="RFC" 
                                    type="text" 
                                    placeholder="Ingresa tu RFC con homoclave" 
                                    class="rfc-input" 
                                    (keyup)="disabledButton()" 
                                    [(ngModel)]="RFC" 
                                    upperCase mask="SSSS000000AAA"  
                                    [ngClass]="(RFC.length && RFC.length<13)? (validRFC ? 'validatedRFC' : 'invalidRFC') : (RFC.length == 13? 'validatedRFC':'') "
                                    [dropSpecialCharacters]="false">
                                <button 
                                    class="button-rfc button-validity-rfc" 
                                    (click)="validateRFC()" 
                                    [disabled]="RFC.length < 13" 
                                    [ngClass]="RFC.length > 13 ? '': 'inactive-button'"
                                    [ngClass]="(RFC.length && RFC.length<13)? (validRFC ? 'validatedRFC' : 'invalidRFC') : (RFC.length == 13? 'validatedRFC':'')">
                                    Validar
                                </button>
                                </div>
                                <!-- VALLIDACIONES RFC -->
                                <div class="col-12 mt-2">
                                <div *ngIf="!validRFC && checkedRFC" class="ml-2"> 
                                    <div class="input-error-rfc ml-2 text-justify" *ngIf="failedRFC">
                                    <strong>El RFC que ingresaste es incorrecto</strong>, por favor, verifícalo y vuelve a ingresarlo. 
                                    No olvides incluir tu homoclave y validarlo para poder continuar
                                    </div>
                                </div>
                                <div *ngIf="validRFC === !RFC && RFC.length < 13 && !checkedRFC" class="d-flex align-items-center">
                                    <div class="input-error-alert"></div>
                                    <div class="input-error-rfc ml-2 "> El RFC debe contar con 13 caracteres.</div>
                                </div>
                                </div>
                            </div>
                        </div>
    
                    <div class="row" *ngIf="requireBill && validRFC">
                    <!-- <div class="row"> -->
                        <div class="col-12  my-3">
                            <p class="fs-16 text-center info-rfc">
                                <strong>Ingresa los datos del receptor de la factura:</strong>
                            </p>
                            <span class="invoice-rfc mb-2">Los datos de facturación deben coincidir con los del titular del medio de pago.
                                En caso de que los datos fiscales que nos sean proporcionados no coincidan con los
                                registrados en el Sistema de Administración Tributaria (SAT), la factura quedará emitida
                                con datos genéricos.</span>
                                <br>
                                <br>
                            <p class="required-info">*Campos obligatorios</p>
                        </div>
                        <div class="col-12">
                            <span class="name-person"><strong>*Nombre de la persona física:</strong></span>
                        </div>
                        <div class="col-12">
                            <span class="name-person">{{fullName}}</span>
                        </div>
                        <div class="col-12 group-input">
                            <select class="custom-select arrow-select select-bold new-imput" [(ngModel)]="taxRegime" (change)="getCFDIUse($event.srcElement.value); validateData();"
                                name="taxRegime" style="border-radius: 6px;">
                                <option value="" disabled class="option-default" selected>*Selecciona tu régimen fiscal</option>
                                <option *ngFor="let item of cfdiTaxRegime" [value]="item.c_RegimenFiscal"><span>{{item.c_RegimenFiscal}} - {{item.descripcion}}</span></option>
                            </select>
                        </div>
                        <div class="col-12 group-input">
                            <select class="custom-select arrow-select select-bold new-imput" [(ngModel)]="useRegime" (change)="validateData();"
                                name="useRegime" style="border-radius: 6px;">
                                <option value="" disabled class="option-default" selected>Selecciona un uso de CFDI</option>
                                <option *ngFor="let item of cfdiUseArray" [value]="item.c_UsoCFDI"> <span>{{item.c_UsoCFDI}} - {{item.uso_CFDI}}</span></option>
                            </select>
                        </div>
                        <div class="col-12">
                            <span class="name-person"><strong>*Correo electrónico:</strong></span>
                        </div>
                        <div class="col-12">
                            <span class="name-person">{{email}}</span>
                        </div>
                        <div class="col-12 group-input">
                            <span class="axa-icon axa-pencil"></span>
                            <input [disabled]="isZipCodeDisabled" type="text" class="input-names input-bold new-imput" mask="0{5}" (input)="validatePostalCode(); postalCodeValid($event.srcElement.value)" [(ngModel)]="zipCodeInvoice" name="zipCodeInvoice" placeholder="*Código Postal">
                        </div>
                        <div class="col-12" [ngClass]="{'name-opacity': !validCP}">
                            <span class="name-person"><strong>Estado: </strong></span>
                            <span class="name-person">{{stateInvoice}}</span>
                        </div>
                        <div class="col-12" [ngClass]="{'name-opacity': !validCP}">
                            <span class="name-person"><strong>Alcaldía/Municipio: </strong></span>
                            <span class="name-person">{{municipalityInvoice}} </span>
                        </div>
                        <div class="col-12 group-input" [ngClass]="{'name-opacity': !validCP}">
                            <span class="axa-icon axa-pencil"></span>
                            <input [disabled]="isColoniesDisabled" type="text" class="input-names input-bold new-imput" placeholder="Ciudad (opcional)" [(ngModel)]="cityInvoice" (input)="validateData()" name="cityInvoice"
                                [patterns]="addressPatterns" mask="B{40}" [specialCharacters]="['space']">
                            <!-- <div class="ml-2 axa-icon axa-danger d-flex" style="color: #DA4B4F;" *ngIf="validCity === false"> <div class="error-text ml-2">Ingresa la ciudad para continuar</div></div> -->
                        </div>
                        <div *ngIf="entity === undefined" class="col-12 group-input" [ngClass]="{'name-opacity': !validCP}">
                            <span class="axa-icon axa-pencil"></span>
                            <input disabled type="text" class="input-names input-bold new-imput" placeholder="Colonia (opcional)" [(ngModel)]="colonyInvoice" (input)="validateData()"
                                name="colonyInvoice"
                                [patterns]="addressPatterns" mask="B{40}" [specialCharacters]="['space']">
                            <!-- <div class="ml-2 axa-icon axa-danger d-flex" style="color: #DA4B4F;" *ngIf="validSuburb === false"> <div class="error-text ml-2">Ingresa la colonia para continuar</div></div> -->
                        </div>
                        <div *ngIf="entity && entity.municipio[0].asentamientos.length === 1" class="col-12 group-input" [ngClass]="{'name-opacity': !validCP}">
                            <span class="axa-icon axa-pencil"></span>
                            <input disabled type="text" class="input-names input-bold new-imput" placeholder="Colonia (opcional)" [(ngModel)]="colonyInvoice" (input)="validateData()"
                                (keyup)="validateSuburb()"  name="colonyInvoice"
                                [patterns]="addressPatterns" mask="B{40}" [specialCharacters]="['space']">
                            <!-- <div class="ml-2 axa-icon axa-danger d-flex" style="color: #DA4B4F;" *ngIf="validSuburb === false"> <div class="error-text ml-2">Ingresa la colonia para continuar</div></div> -->
                        </div>
                        <div *ngIf="entity && entity.municipio[0].asentamientos.length > 1" class="col-12 group-input" [ngClass]="{'name-opacity': !validCP}">

                            <select class="custom-select arrow-select select-bold new-imput" [(ngModel)]="colonyInvoice" (change)="validateSuburb(); validateData()"
                            name="colonyInvoice" style="border-radius: 6px;">
                                <option value="" disabled class="option-default" selected>Selecciona tu colonia</option>
                                <option *ngFor="let colony of entity.municipio[0].asentamientos" [value]="colony.descripcionAsentamiento"><span>{{colony.descripcionAsentamiento}}</span></option>
                            </select>
                        </div>
                        <div class="col-12 group-input" [ngClass]="{'name-opacity': !validCP}">
                            <span class="axa-icon axa-pencil"></span>
                            <input 
                            type="text" 
                            name="streetInvoice"
                            class="input-names input-bold new-imput" 
                            [disabled]="isColoniesDisabled" 
                            placeholder="Calle (opcional)" 
                            [(ngModel)]="streetInvoice" 
                            (input)="validateData()"
                            [patterns]="addressPatternsBilling" 
                            mask="B{165}" 
                            [specialCharacters]="['space']">
                            <!-- <div class="ml-2 axa-icon axa-danger d-flex" style="color: #DA4B4F;" *ngIf="validStreet === false"> <div class="error-text ml-2">Ingresa la calle para continuar</div></div> -->
                        </div>
                        <div class="col-12 col-sm-6 group-input" [ngClass]="{'name-opacity': !validCP}">
                            <span class="axa-icon axa-pencil"></span>
                            <input [disabled]="isColoniesDisabled" type="text" class="input-names input-bold new-imput" placeholder="No.Ext. (opcional)" [(ngModel)]="numExtInvoice" (input)="validateData()"
                                name="numExtInvoice" [patterns]="streetPatterns"
                                mask="BBBBBB" [specialCharacters]="['space']">
                            <!-- <div class="ml-2 axa-icon axa-danger d-flex" style="color: #DA4B4F;" *ngIf="validNumExt === false"> <div class="error-text ml-2">Ingresa el número exterior para continuar</div></div> -->
                        </div>
                        <div class="col-12 col-sm-6 group-input" [ngClass]="{'name-opacity': !validCP}">
                            <span class="axa-icon axa-pencil"></span>
                            <input [disabled]="isColoniesDisabled" type="text" class="input-names input-bold new-imput" placeholder="No.Int. (opcional)" [(ngModel)]="numIntInvoice" (input)="validateData()" name="numIntInvoice" [patterns]="streetPatterns"
                            mask="BBBBBB" [specialCharacters]="['space']">
                        </div>
                    </div>

                    </div>
                </div>
                <div class="row text-center pt-4">
                        <div class="col">
                            <button class="button-custom" (click)="submitForm()" [disabled]="!validForm" [ngClass]="!validForm ? 'inactive-button': ''">
                                Siguiente
                        </button>
                        </div>
                        <!--
                        <div class="col-12 pt-5 text-recaptcha">
                            Protegido por reCAPTCHA - <span>Privacidad</span> - <span>Condiciones</span>
                        </div>
                        -->
                </div>
                </div>


                <div class="col-12 text-center text-conditions mt-5" style="color:#333333">
                    Esta información será utilizada para completar el proceso de tu videoconsulta. 
                </div>
                <div class="col-12 text-center text-conditions mb-3 mt-2" style="color:#333333">
                    <a href="" style="color: #2B3D43; text-decoration: underline; font-weight: bold;" [routerLink]="['/aviso-keralty']" target="_blank">Ver Aviso de Privacidad</a>
                </div>
                <div class="col-12 text-center text-conditions" style="color:#333333">
                    Consulta nuestros Términos y Condiciones <a [routerLink]="['/consulta-terminos-y-condiciones']" target="_blank" style="color: #2B3D43; text-decoration: underline; font-weight: bold;">aquí</a>
                </div>
                <div class="row text-center" style="color: #597A8B">
                    <div class="col-12 py-3">
                        <span class="text-bottom">Conoce todos los beneficios de <span class="planmed Planmed ml-1">
                            <span class="planmed Registered"></span></span><a [routerLink]="['/infografia']" target="_blank" class="ml-2 pl-1 text-home">aquí.</a></span>
                    </div>
                </div>
                




            </div>
        </div>
       </form>
    </div>
</div>