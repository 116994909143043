/** import Component, OnInit*/
import { Component, OnInit } from '@angular/core';
/** declare const AdobeAn */
declare const AdobeAn;
/** declare const createjs */
declare const createjs;
/**
 * AnimationComponent
 */
@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss']
})
export class AnimationComponent implements OnInit {

  /** Variable canvas */
  canvas; 
  /** Variable stage*/
  stage;
  /** Variable*/
  exportRoot;
  /** Variable*/
  anim_container;
  /** Variable*/
  dom_overlay_container;
  /** Variable lib*/
  lib;
  /** Variable comp*/
  comp= AdobeAn.getComposition("BC0B87B5347A422FBEAC7CE7BD44F28E");

  /** constructor */
  constructor() { }

  /**
   * ngOnInit
   * Inicialización de variables
   */
  ngOnInit(): void {
    this.canvas = document.getElementById("canvas");
    this.anim_container = document.getElementById("animation_container");
    this.dom_overlay_container = document.getElementById("dom_overlay_container");
    this.lib = this.comp.getLibrary();
    this.exportRoot = new this.lib.ANIMACION_HOME_V7();
    this.stage = new this.lib.Stage(this.canvas);
    this.init()
  }

  /**
   * init
   * Inicialización de variables
   */
  init(){ 
    const loader = new createjs.LoadQueue(false);
    loader.addEventListener("fileload", evt =>{this.handleFileLoad(evt,this.comp)});
    loader.addEventListener("complete", evt => {this.handleComplete(evt,this.comp)});
    this.lib= this.comp.getLibrary();
    loader.loadManifest(this.lib.properties.manifest);
  }

  /**
   * handleFileLoad
   * @param evt //type the evt
   * @param comp //resource
   */
  handleFileLoad(evt, comp) {
    let images=comp.getImages();
    if (evt && (evt.item.type == "image")) { images[evt.item.id] = evt.result; }
  }

  /**
   * handleComplete
   * This function is always called, irrespective of the content. 
   * You can use the variable "stage" after it is created in token create_stage.this.lib=comp.getLibrary();
   * @param evt
   * @param comp 
   */
  handleComplete(evt,comp) {
    let ss=comp.getSpriteSheet();
    let queue = evt.target;
    let ssMetadata = this.lib.ssMetadata;
    for(let i=0; i<ssMetadata.length; i++) {
      ss[ssMetadata[i].name] = new createjs.SpriteSheet( {"images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames} )
    }
    //Registers the "tick" event listener.
    /**
     * Code to support hidpi screens and responsive scaling.
     */
    AdobeAn.makeResponsive(true,'both',false,1,[this.canvas,this.anim_container,this.dom_overlay_container]);
    AdobeAn.compositionLoaded(this.lib.properties.id);
    this.fnStartAnimation();
  }

  /**
   * fnStartAnimation
   */
  fnStartAnimation (){
    this.stage.addChild(this.exportRoot);
    createjs.Ticker.framerate = this.lib.properties.fps;
    createjs.Ticker.addEventListener("tick", this.stage);
  }	
}
