import { IEventGTM } from './../../interfaces/gtmEvent.interface';
/** import angular/core */
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
/**
 * Component: FloatButtonActionsComponent
 */
@Component({
  selector: 'app-float-button-actions',
  templateUrl: './float-button-actions.component.html',
  styleUrls: ['./float-button-actions.component.scss']
})
export class FloatButtonActionsComponent implements OnInit {

  /** constructor  */
  constructor(private coockieServ: CookieService) { }

  /** ngOnInit */
  ngOnInit(): void {
  }
/** addToCartEvent*/
addToCartEvent() {
  /** Envío de evento al dataLayer para taggeo */
  const evgtm: IEventGTM = {
    event: 'eventgtm',
    eventCategory: 'floatButtons',
    eventAction: 'homePage_GoCotizamed',
    eventLabel: 'btn_homePage_goToCotizamed',
  }

  window.dataLayer.push(evgtm);
  console.log('[INFO] B.N: ', this.coockieServ.get('BROKER_ID'));
    
  if (this.coockieServ.get('BROKER_ID') && this.coockieServ.get('BROKER_ID') === 'PVI') {
    this.coockieServ.delete('BROKER_NAME');
    this.coockieServ.delete('BROKER_KEY');
    this.coockieServ.delete('BROKER_PROMOTER_ID');
    this.coockieServ.delete('BROKER_PROMOTER_NAME');
    this.coockieServ.delete('BROKER_AGENT_ID');
    this.coockieServ.delete('BROKER_AGENT_EMAIL');
  }
  window.location.replace('/cotizador-planmed/');
  window.location.replace('/cotizador-planmed/');
}
  sendTag(event: string) {
    let eventGtm: IEventGTM;
    if(event === 'call') {
      eventGtm = {
        event: 'eventgtm',
        eventCategory: 'floatButtons',
        eventAction: 'llamanos'
      }

      window.dataLayer.push(eventGtm);
    } else {
      eventGtm = {
        event: 'eventgtm',
        eventCategory: 'floatButtons',
        eventAction: 'escribenos'
      }

      window.dataLayer.push(eventGtm);
    }
  }
}
