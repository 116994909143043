/**
 * Constante de los estados de la Republica mexicana
 */
export const STATES = [
  { value: 'AS', name: 'AGUASCALIENTES' },
  { value: 'BC', name: 'BAJA CALIFORNIA' },
  { value: 'BS', name: 'BAJA CALIFORNIA SUR' },
  { value: 'CC', name: 'CAMPECHE' },
  { value: 'CL', name: 'COAHUILA' },
  { value: 'CM', name: 'COLIMA' },
  { value: 'CS', name: 'CHIAPAS' },
  { value: 'CH', name: 'CHIHUAHUA' },
  { value: 'DF', name: 'CIUDAD DE MEXICO' },
  { value: 'DG', name: 'DURANGO' },
  { value: 'GT', name: 'GUANAJUATO' },
  { value: 'GR', name: 'GUERRERO' },
  { value: 'HG', name: 'HIDALGO' },
  { value: 'JC', name: 'JALISCO' },
  { value: 'MC', name: 'MEXICO' },
  { value: 'MN', name: 'MICHOACAN' },
  { value: 'MS', name: 'MORELOS' },
  { value: 'NT', name: 'NAYARIT' },
  { value: 'NL', name: 'NUEVO LEON' },
  { value: 'OC', name: 'OAXACA' },
  { value: 'PL', name: 'PUEBLA' },
  { value: 'QT', name: 'QUERETARO' },
  { value: 'QR', name: 'QUINTANA ROO' },
  { value: 'SP', name: 'SAN LUIS POTOSI' },
  { value: 'SL', name: 'SINALOA' },
  { value: 'SR', name: 'SONORA' },
  { value: 'TC', name: 'TABASCO' },
  { value: 'TS', name: 'TAMAULIPAS' },
  { value: 'TL', name: 'TLAXCALA' },
  { value: 'VZ', name: 'VERACRUZ' },
  { value: 'YN', name: 'YUCATAN' },
  { value: 'ZS', name: 'ZACATECAS' },
  { value: 'NE', name: 'NACIDO EN EL EXTRANJERO' }
];
/**
 * Constante de estados para datos de contacto
 */
export const STATES_CONTACT = [
  { value: 'AS', name: 'AGUASCALIENTES' },
  { value: 'BC', name: 'BAJA CALIFORNIA' },
  { value: 'BS', name: 'BAJA CALIFORNIA SUR' },
  { value: 'CC', name: 'CAMPECHE' },
  { value: 'CL', name: 'COAHUILA' },
  { value: 'CM', name: 'COLIMA' },
  { value: 'CS', name: 'CHIAPAS' },
  { value: 'CH', name: 'CHIHUAHUA' },
  { value: 'DF', name: 'CIUDAD DE MEXICO' },
  { value: 'DG', name: 'DURANGO' },
  { value: 'GT', name: 'GUANAJUATO' },
  { value: 'GR', name: 'GUERRERO' },
  { value: 'HG', name: 'HIDALGO' },
  { value: 'JC', name: 'JALISCO' },
  { value: 'MC', name: 'MEXICO' },
  { value: 'MN', name: 'MICHOACAN' },
  { value: 'MS', name: 'MORELOS' },
  { value: 'NT', name: 'NAYARIT' },
  { value: 'NL', name: 'NUEVO LEON' },
  { value: 'OC', name: 'OAXACA' },
  { value: 'PL', name: 'PUEBLA' },
  { value: 'QT', name: 'QUERETARO' },
  { value: 'QR', name: 'QUINTANA ROO' },
  { value: 'SP', name: 'SAN LUIS POTOSI' },
  { value: 'SL', name: 'SINALOA' },
  { value: 'SR', name: 'SONORA' },
  { value: 'TC', name: 'TABASCO' },
  { value: 'TS', name: 'TAMAULIPAS' },
  { value: 'TL', name: 'TLAXCALA' },
  { value: 'VZ', name: 'VERACRUZ' },
  { value: 'YN', name: 'YUCATAN' },
  { value: 'ZS', name: 'ZACATECAS' },
];

/**
 * Estados de la republica Mexicana.
 */
export const STATES_BH = [
  {
      value: "AS",
      code: "01"
  },
  {
      value: "BC",
      code: "02"
  },
  {
      value: "BS",
      code: "03"
  },
  {
      value: "CC",
      code: "04"
  },
  {
      value: "CL",
      code: "05"
  },
  {
      value: "CM",
      code: "06"
  },
  {
      value: "CS",
      code: "07"
  },
  {
      value: "CH",
      code: "08"
  },
  {
      value: "DF",
      code: "09"
  },
  {
      value: "DG",
      code: "10"
  },
  {
      value: "GT",
      code: "11"
  },
  {
      value: "GR",
      code: "12"
  },
  {
      value: "HG",
      code: "13"
  },
  {
      value: "JC",
      code: "14"
  },
  {
      value: "MC",
      code: "15"
  },
  {
      value: "MN",
      code: "16"
  },
  {
      value: "MS",
      code: "17"
  },
  {
      value: "NT",
      code: "18"
  },
  {
      value: "NL",
      code: "19"
  },
  {
      value: "OC",
      code: "20"
  },
  {
      value: "PL",
      code: "21"
  },
  {
      value: "QT",
      code: "22"
  },
  {
      value: "QR",
      code: "23"
  },
  {
      value: "SP",
      code: "24"
  },
  {
      value: "SL",
      code: "25"
  },
  {
      value: "SR",
      code: "26"
  },
  {
      value: "TC",
      code: "27"
  },
  {
      value: "TS",
      code: "28"
  },
  {
      value: "TL",
      code: "29"
  },
  {
      value: "VZ",
      code: "30"
  },
  {
      value: "YN",
      code: "31"
  },
  {
      value: "ZS",
      code: "32"
  }
]