<!-- PLANMED -->
<div class="container">
  <div class="row justify-content-center mb-4">
    <div class="col-12 col-md-9 col-lg-8 pt-5">
      <p class="title-text text-center">
        ¡Accede a nuestros servicios con Planmed
        <sup class="registered-icon" style="margin-left: -10px; font-size: 20px;top: -1.0em;">&reg;</sup>!
      </p>
    </div>
  </div>    
</div>
<!-- PLANES -->
<div class="container">
  <ul class="nav col-12 pr-0">
    <li *ngFor="let item of PLANS; let planIndex = index" class="col-6 px-0">
      <a 
        class="nav-item"
        [class.nav-item-op]="planIndex == 0" 
        [class.nav-item-mix]="planIndex == 1" 
        [class.nav-item-active]="planIdSelected == item.id" 
        (click)="plamedSelect(planIndex == 0, item.id)">
        <div class="col-12">
          <span class="planmed Planmed px-2 c-pointer">
            <span class="planmed Registered"></span>
          </span>
          <span class="text-plan planmed-description d-block d-lg-inline-block">{{item.plan}}</span>
        </div>
      </a>
    </li>
  </ul>
  <!-- PLANES Y BENEFICIOS -->
  <div *ngFor="let item of PLANS; let planIndex = index">
    <div class="planOptimo p-0" *ngIf="planIndex == 0 ? isPlanOptimo : !isPlanOptimo">
      <div 
        [class.header-wrapper]="planIndex == 0" 
        [class.header-wrapper-mix]="planIndex == 1" 
        class="col-12 header-wrapper">
        <div class="row text-center">
          <!-- RANGO DE EDADES -->
          <div 
            *ngFor="let range of item.ranges; let rangeIndex = index" 
            [class.spanded-md]="rangeIndex > 0" 
            class="col-12 col-md-4 py-4">
            <span class="text-price old-years">{{range.age}}:</span>
            <br />
            <strong class="price price-tag"> ${{range.price | number}}</strong>
            <br class="break-price" />
            <span class="text-price"> {{range.desc}} </span>
          </div>
        </div>
      </div>

      <div 
        [class.main-wrapper]="planIndex == 0" [class.main-wrapper-mix]="planIndex == 1" 
        class="row justify-content-center justify-content-lg-start coverages m-0 py-4">
        <div *ngFor="let coverage of item.coverages; let coverageIndex = index" class="col px-2 py-2">
          <!-- COBERTURA -->
          <div class="text-center">
            <span [class.c-soft]="planIndex == 0" [class.c-soft-mix]="planIndex == 1" class="dot-1">
              <span [class.c-hard]="planIndex == 0" [class.c-hard-mix]="planIndex == 1" class="dot-2">
                <!-- ICONO PORCENTAJE -->
                <span *ngIf="planIndex == 1 && ![2, 3].includes(coverageIndex)" class="dot-disc position">
                  <span class="ak-icon icon-tag_discount"></span>
                </span>
                <span [class]="coverage.icon" class="span-services"></span>
              </span>
            </span>
            <p class="services-description my-2" [innerHtml]="coverage.coverage"></p>
          </div>
        </div>
        <!-- BENEFICIO COPAGO -->
        <div *ngIf="planIndex == 1" class="w-100 my-4 pl-5">
          <div class="float-left text-center pr-1">
            <span class="dot-disc">
                <span class="ak-icon icon-tag_discount"></span>
            </span>
          </div>
          <div class="pl-0 pr-0">
            <p class="text-discounts">Beneficio includo con copago del 30%</p>
          </div>
        </div>
        <!-- LINK DE COTIZACIÓN -->
        <div class="w-100 text-center my-3">
          <a (click)="redirectToCot(item.id)" class="btn btn-primary button-redirect" style="text-decoration: none;">
            <div class="custom-buttom d-flex align-items-center justify-content-center">
                ¡Lo quiero!
            </div>
          </a>
        </div>
      </div>
      <!-- DESCRIPCIÓN DE SERVICIOS -->
      <div class="row m-0 mt-1 subpoints">
        <div class="col-12 text-small pt-3 pl-4">
          <p *ngFor="let description of item.descriptions">{{description}}</p>
          <p *ngIf="planIndex == 1" class="font-weight-bold">El copago se realiza cada vez que se utilice un servicio.</p>
        </div>
      </div>

      <div class="row m-0 mt-5 text-center">
        <p class="text-center font-weight-bold centauro-details-title m-auto">
          Además, Clínicas Dentales Centauro te ofrece beneficios dentales al ser usuario Planmed®. 
          Consulta más detalles haciendo clic 
          <a href="https://www.centauro.com.mx/wp-content/uploads/Planmed_y_Centauro.pdf"
            target="_blank"
          >
            aquí</a>.
        </p> 
      </div>

      <div class="row m-0 mt-1 text-center">
        <p class="text-center details-title m-auto">
          El beneficio dental es ofrecido por Salud Masiva, S.C., a través de una membresía ofrecida como parte 
          de una campaña de activación para los asegurados de Planmed® de AXA Salud, S.A. de C.V. Los servicios 
          dentales son responsabilidad de Salud Masiva, S.C. al igual que los términos y condiciones para su 
          otorgamiento.
        </p> 
      </div>
  
      <div class="text-discount text-center pt-3 pb-5">
          <p class="mb-0">¡Contamos con 3 y 6 meses sin intereses con tarjetas participantes!</p>
      </div>
    </div>
  </div>

</div>