/** import angular/core' */
import { Injectable } from '@angular/core';
/** import rxjs/operator*/
import { map } from 'rxjs/operators';
/** import http.service*/
import { HttpService } from '../http-service/http.service';
/** import errors.service*/
import { ErrorsService } from '../errors.service';
/** Injectable */
@Injectable({
  /**providedIn */
  providedIn: 'root'
})
export class QnUsefulInformationService {

  /**
   * Constructor
   * @param http //HttpService
   * @param errorsService //ErrorsService
   */
  constructor(private http: HttpService, private errorsService: ErrorsService) { }

  /**
   * servicio para conocer si fue útil la información
   * @param usefulInformation 
   */
  qnUsefulInformationSchema(usefulInformation){
    return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/qnUsefulInformation/usefulInformation', {usefulInformation}, false).pipe(map(res=>{
      if(res){
        return res;
      }
    }, (error) => {
      this.errorsService.handleError(error);
      return false;
    }))
  }
}
