
/** import Component, OnInit  */
import { Component, OnInit } from '@angular/core';
/**
 * Component SignInComponent
 */
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  /** constructor */
  constructor() { }

  /** ngOnInit */
  ngOnInit() {
  }

}
