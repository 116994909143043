<section class="container py-3">
  <div class="row no-gutters pt-5 px-3 px-md-4 mx-auto" style="max-width: 600px;">
    <div class="col 12 col-sm-3 mb-3 mb-sm-0 modal__icon-border" *ngIf="modalIcon">
      <img class="close d-sm-none" src="assets/img/modal/close_modal.png" alt="" (click)="actionButton(false)">
      <figure class="modal__icon" [ngClass]="figureIcon">
        <span [ngClass]="[familyIcon, modalIcon]"></span>
      </figure>
    </div>
    <div class="col-12 col-sm-9 text-center text-sm-left">
      <img class="close d-none d-sm-flex" src="assets/img/modal/close_modal.png" alt="" (click)="actionButton(false)">
      <h6 class="modal__title col-12 fs-15 text-center text-sm-left" style="color: #FFFFFF;" *ngIf="modalTitle">
        <strong>
          {{ modalTitle }}
        </strong>
      </h6>
      <p class="modal__text text-center text-sm-left col-12 fs-15 pb-2" style="color: #FFFFFF;" *ngIf="modalMessage" [innerHTML]="modalMessage"></p>
      <img *ngIf="modalImage" [src]="[modalImage]" class="modal__curp ml-sm-3 ml-0" alt="CURP" />
      <div class="col-12">
        <button
        class="modal__button modal__button-cancel mr-2 mt-4 ml-1rem"
        *ngIf="showCancelBtn"
        (click)="actionButton(false)"
        id="btn_cotizaP_gralModalCancel"
      >
        {{ btnCancelText }}
      </button>
      <button
        class="modal__button modal__button-accept mt-4 ml-1rem"
        [ngClass]="{ cerulean: !showCancelBtn }"
        (click)="actionButton(true)"
        id="btn_cotizaP_gralModalAcept"
      >
        {{ btnText }}
      </button>
      </div>
    </div>
  </div>
  <div
    class="row mb-4 mt-4 d-flex px-5 align-items-center"
    [ngClass]="showCancelBtn ? 'justify-content-around' : 'justify-content-end'"
  ></div>
</section>
