import { Component, EventEmitter } from '@angular/core';

/**
 * Modal General para presentar información
 * @export
 * @class ModalGeneralV2Component
 * @implements {OnInit}
 */
@Component({
  templateUrl: './modal-general-v2.component.html',
  styleUrls: ['./modal-general-v2.component.scss']
})
export class ModalGeneralV2Component {
  /** Clases de figure */
  figureIcon: string;
  /** Familia de icono */
  familyIcon = 'axa-icon';
  /** Icono a presnetar arriba de título */
  modalIcon: string;
  /** Titulo de modal */
  modalTitle: string;
  /** Información a presentar en modal */
  modalMessage: string;
  /** Imagen a presentar en el modal */
  modalImage: string;
  /** Suscripción para cerrar modal */
  button = new EventEmitter<boolean>();
  /** Texto en botón de modal */
  btnText = 'Aceptar';
  /** Bandera para mostrar boton de regresar */
  showCancelBtn = false;
  /** Texto en botón de modal regresar */
  btnCancelText = 'Regresar';

  /**
   * Acción de botón
   * @memberof ModalGeneralV2Component
   */
  actionButton(value: boolean) {
    this.button.next(value);
  }
}
