import { IEventGTM } from 'src/app/interfaces/gtmEvent.interface';
/** import Component, OnInit*/
import { Component, OnInit } from '@angular/core';
/** import opinions contant*/
import { OPINIONS } from '../../../constants/opinions.constant';
/**
 * Component UsersOpinionComponent
 */
@Component({
  selector: 'app-users-opinion',
  templateUrl: './users-opinion.component.html',
  styleUrls: ['./users-opinion.component.scss']
})
export class UsersOpinionComponent implements OnInit {
  /** Variable opinions */
  opinions = OPINIONS.USERS;
  /** Variable title */
  title = 'ngSlick';

  /**
   * slideConfig
   * Configuración del slide
   */
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    arrows:false,
    dots: true,
    autoplay: true,
      autoplaySpeed: 6000,
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
  };

  /** constructor */
  constructor() { }

  /** ngOnInit */
  ngOnInit() {
  }

  getModal(slickModal: any) {
    const slide = slickModal.currentIndex;

    let eventGtm: IEventGTM;

    switch (slide) {
      case 0:
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'opinionsSection',
          eventAction: 'slide1',
          eventLabel: 'slick-slide1'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 1:
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'opinionsSection',
          eventAction: 'slide2',
          eventLabel: 'slick-slide2'
        }

        window.dataLayer.push(eventGtm)
        break;

      case 2:
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'opinionsSection',
          eventAction: 'slide3',
          eventLabel: 'slick-slide3'
        }

        window.dataLayer.push(eventGtm)
        break;

      case 3:
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'opinionsSection',
          eventAction: 'slide4',
          eventLabel: 'slick-slide4'
        }

        window.dataLayer.push(eventGtm)
        break;

      default:
        this.tagContinue(slide);
        break;
    }
  }

  tagContinue(pos: any){
    let eventGtm: IEventGTM;

    switch (pos) {
      case 4:
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'opinionsSection',
          eventAction: 'slide5',
          eventLabel: 'slick-slide5'
        }

        window.dataLayer.push(eventGtm)
        break;

      case 5:
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'opinionsSection',
          eventAction: 'slide6',
          eventLabel: 'slick-slide6'
        }

        window.dataLayer.push(eventGtm)
        break;

      case 6:
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'opinionsSection',
          eventAction: 'slide7',
          eventLabel: 'slick-slide7'
        }

        window.dataLayer.push(eventGtm)
        break;

      case 7:
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'opinionsSection',
          eventAction: 'slide8',
          eventLabel: 'slick-slide8'
        }

        window.dataLayer.push(eventGtm)
        break;

        case 8:
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'opinionsSection',
          eventAction: 'slide9',
          eventLabel: 'slick-slide9'
        }

        window.dataLayer.push(eventGtm)
        break;
    }
  }

}
