/**
 * Arreglo de objetos de preguntas
 */
export const FAQQUEST = [
  {
    id: 1,
    title: 'Acerca de AXA Keralty ',
    questions: [
      '¿A qué se refiere el sistema integral de salud que ofrece AXA Keralty?',
      '¿Qué es Planmed®?',
      '¿Planmed® es una tarjeta de descuentos?',
      '¿Qué pasa si ya tengo un seguro de gastos médicos mayores?',
      'Tengo Cuidado Integral+ o Flex Plus, ¿también puedo acceder a los servicios de AXA Keralty?'
    ],
    active: false,
    selectedQuestion: undefined
  },
  {
    id: 2,
    title: 'Acerca de la contratación de Planmed®',
    questions: [
      '¿Cuál es la edad mínima y máxima de contratación?',
      '¿Qué necesito para contratar Planmed®?',
      '¿Puedo contratar un plan, aunque tenga una enfermedad pre existente o crónica?',
      '¿Cuáles son los métodos de pago disponibles?',
      '¿Hay tarifas diversificadas (primas) según el rango de edad?'
    ],
    active: false,
    selectedQuestion: undefined
  },
  {
    id: 3,
    title: 'Clínicas AXA Keralty: servicios e información general',
    questions: [
      '¿Cuáles son nuestros horarios de atención?',
      '¿En dónde se encuentran las Clínicas AXA Keralty? ',
      '¿Puedo acceder a una consulta o servicio, aunque no cuente con un plan contratado?',
      '¿Cómo puedo programar una consulta?',
      '¿Cómo me identifico al llegar a mi Clínica AXA Keralty?'
    ],
    active: false,
    selectedQuestion: undefined
  },
  {
    id: 4,
    title: 'Atención médica',
    questions: [
      'Si tengo contratado Planmed®, ¿Cómo puedo acceder a un médico especialista?',
      '¿En qué consiste el Check up Anual?',
      '¿Únicamente puedo acceder a atención médica cuando estoy enfermo?',
      '¿Cómo funciona la Atención no Programada?'
      
    ],
    active: false,
    selectedQuestion: undefined
  }
];


/**
 * Arreglo de objetos de respuestas
 */
export const FAQANS = [
  {
    section: 1,
    answers: [
      `sistema-integral`,

      `Es un plan de salud que no se limita a una simple cobertura financiera, sino que cuida de tu salud 
      incluso cuando no estás enfermo. Planmed® te da acceso a una red de Clínicas y prestadores de salud
       capacitados donde podrás tener consultas con médicos generales, consultas con especialistas, 
       orientación médica telefónica y domiciliaria, atención no programada, terapias físicas, estudios de
        laboratorio, entre otros servicios disponibles en nuestras Clínicas, dependiendo el plan contratado.`,

      `Planmed® no es una tarjeta de descuentos. AXA Keralty se encarga directamente de tu atención incluyendo
       en cobertura servicios de salud dependiendo el producto Planmed® de tu elección, el cual te ofrece acceso
        a un sistema integral de salud. Nos encargamos de acompañarte en tu viaje de salud, cuidando los 
        protocolos y con profesionales de salud capacitados`,

      ` Planmed® es el complemento ideal que te proporcionará acceso a servicios médicos que un Seguro de Gastos 
      Médicos Mayores difícilmente ofrece, robusteciendo y complementando así tu protección y cuidado de la salud.`,

      `flex-plus`
    ]
  },
  {
    section: 2,
    answers: [
      `Siempre y cuando el contratante titular tenga entre 18 y 64 años, no existe límite de edad para el resto de los asegurados en la póliza.`,

      'click',

      `¡Claro! En AXA Keralty puedes contratar un plan de salud, independientemente de que tengas alguna enfermedad.`,

      `Tarjeta de crédito, tarjeta de débito o transferencia a una cuenta con referencia bancaria. Por política y protección, nuestros colaboradores no aceptan pago en efectivo.`,

      `Las tarifas de Planmed® se dividen en tres rangos de edad: 0-17 años, 18 a 49 años, 50 años y más. `
    ]
  },
  {
    section: 3,
    answers: [
      `Lunes a sábado de 8:00 a 20:00 hrs.`,
      'Ubicación',
      'ConsultaOServicios',//`¡Claro! Nuestros Centros de Atención Médica ofrecen servicios al público en general.`,
      `ProgramarConsulta`,
      `Únicamente presenta una identificación oficial con fotografía al llegar a la recepción.`
    ]
  },
  {
    section: 4,
    answers: [
      //'trabajo',

      //'ContractPlanmed Te invitamos a programar una consulta con nuestros médicos y ellos determinarán el mejor plan de seguimiento de acuerdo a tu condición de salud.'+
      //'*El costo de estos servicios pueden variar de acuerdo al plan contratado',
      `consulta-especialidad`,

      `checkup-inicial`,

      'No, nuestro sistema de salud tiene un enfoque preventivo, por lo que te recomendamos consultar frecuentemente a tu médico para asegurarte de que todo está en orden, recibir atención oportuna y conocer tu plan de seguimiento de acuerdo con tus necesidades. Recuerda validar las coberturas y beneficios que se incluyen con tu plan y, en caso de aplicar, consulta el costo preferente al que tienes acceso por ser usuario Planmed®.',
      `En caso de sufrir un imprevisto y requerir cuidados oportunos, con Atención no Programada, puedes acudir a las Clínicas AXA Keralty o con la red de prestadores de servicio y recibir atención para solucionar tu situación, sin necesidad de agendar una cita previamente.`,
    ]
  }
];
/**
 * Planmed 
 */
export const PLANMED = [
  'Es un plan con el que podrás tener acceso a un sistema integral de salud que no se limita a una simple\
  cobertura financiera, sino que cuida de tu salud incluso cuando no estás enfermo. Planmed® te da acceso a una red de\
  centros de atención médica de alta calidad desde atención médica de primer nivel, hasta atención médica con especialistas. ',
  'El plan con el que podrás acceder a diferentes beneficios para tener un mejor control y acompañamiento de tu salud.'
];

/**
 * Contrato Planmed
 */
export const CONTRACT_PLANMED = [
  'Te invitamos a programar una consulta con nuestros médicos y ellos determinarán el mejor plan de seguimiento de acuerdo a tu condición de salud.',
  '*El costo de estos servicios pueden variar de acuerdo al plan contratado'
];
