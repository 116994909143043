/** import @angular/core */
import { Component, OnInit } from '@angular/core';
/** import constants*/
import { urlsExt } from 'src/app/constants/urls.constants';
import { ChatService, Message } from '../../services/chat/chat.service';
import { Observable } from 'rxjs';

import { IEventGTM } from '../../interfaces/gtmEvent.interface';
import { Router } from '@angular/router';
import { ConsultService } from 'src/app/services/consult.service';
import { SERVICES } from 'src/app/constants/services.constants';
import { HeaderService } from './header.service';
/**
 * Component
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  /** inicialización de message Observable */
  messages: Observable<Message[]>;
  /** inicialización de formValue */
  formValue: string;
  /** inicialización showOptions */
  showOptions: string;
  /** inicialización de message */
  message: string;
  /** inicialización de titulo del componente */
  title = '2B Bot';
  /** inicialización de bandera  de tipo boolean */
  band_options: boolean;
  /** inicialización band */
  public band: boolean;
  /** arreglo history messages  */
  history: Message[] = [];
  /** variable de efecto */
  fadeOf = true;
  rutaActiva = '';
  GENERAL_SERVICES = SERVICES;
  showNewFooter = false;
  /** URL del servidor actual */
  urlServer: string = '';
  /** inicialización writingMessage para detecta al insertar texto*/
  writingMessage: boolean;
  eventGTM: IEventGTM;
  activeMenu = false;
  constructor(
    private chatService: ChatService,
    private userInfo: ConsultService,
    private headerService: HeaderService,
    private router: Router) {
    this.urlServer = 'https://preprod.axakeralty.mx';
  }
  toggleMenu() {
    this.activeMenu = !this.activeMenu;

  }
  /** inicio componente ngOnInit */
  ngOnInit() {
    this.router.events.subscribe((reponse: any) => {
      this.rutaActiva = reponse.url !== undefined ? reponse.url : this.rutaActiva;
      const activeRoute = this.rutaActiva.split('/');
      // if (this.rutaActiva === '/') {
      //   window.location.reload();
      // }
      if (this.rutaActiva === '/servicios' || this.rutaActiva === '/infografia' || this.rutaActiva === '/servicios-pago' || this.rutaActiva === '/servicios-checkout' || this.rutaActiva === '/servicios-exito' || this.rutaActiva === '/consulta-terminos-y-condiciones') {
        this.showNewFooter = true;
      } else {
        this.showNewFooter = false;
      }
      this.headerService.changedByRefresh.next(false);
    });
    this.chatService.toggle.asObservable().subscribe((res) => {
      this.band = res;
    });


    var currentRoute = this.router.url;
    //this.rutaActiva = currentRoute; 
    if (currentRoute === '/servicios' || currentRoute === '/infografia' ||  currentRoute === '/servicios-pago' ||  currentRoute === '/servicios-checkout' ||  currentRoute === '/servicios-exito' ||  currentRoute === '/consulta-terminos-y-condiciones' ) {
      this.showNewFooter = true;
    } else {
      this.showNewFooter = false;
    }/** */
    this.headerService.changedByRefresh.next(true);
  }


  /** Método abrir chat */
  openChat() {
    this.chatService.changetoggle(this.band = true);

    /* Función para envío de taggeo */
    const event: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'headerLinks',
      eventAction: 'cuestionario COVID19',
      eventLabel: 'link_header_questionnaireCOVID'
    }

    window.dataLayer.push(event);
  }

  goToPaymentAntigenos() {
    this.userInfo.typeService.next('prueba-antigenos');
    let consultRate = this.GENERAL_SERVICES.filter(consult => consult.type === 'prueba-antigenos');
    console.log("consultRate", consultRate);

    let egtm = {
      'event': 'eventgtm',
      'eventCategory': 'headerLinks',
      'eventAction': 'homePage_linkclick',
      'eventLabel': 'click-antígeno'
    };

    window.dataLayer.push(egtm);

    this.userInfo.rateConsult.next(consultRate[0]);
    this.router.navigate(['/servicios']);
  }

  goToPaymentPCR() {
    this.userInfo.typeService.next('prueba-covid');
    let consultRate = this.GENERAL_SERVICES.filter(consult => consult.type === 'prueba-covid');
    // console.log("consultRate", consultRate);

    let egtm = {
      'event': 'eventgtm',
      'eventCategory': 'headerLinks',
      'eventAction': 'homePage_linkclick',
      'eventLabel': 'click-PCR'
    }

    window.dataLayer.push(egtm);

    this.userInfo.rateConsult.next(consultRate[0]);
    this.router.navigate(['/servicios-pago']);
  }
  /** Redirecciona por ambiente */
  redirect(): void {
    location.href = this.urlServer + '/consultas/';
  }

  /** addToCartEvent */
  addToCartEvent() {
    window.location.replace('/portal/#/login');

    const eventGTM: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'headerLinks',
      eventAction: 'miPlanmed'
    }

    window.dataLayer.push(eventGTM);
  }

  /**
   * Redirecciona a la info de tokenizacion
   */
  goToTokenPage() {
    window.location.replace('/tokenizacion');
  }

  /** goToNet */
  goToNet() {
    window.location.replace('/sso/');

    const Egtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'headerLinks',
      eventAction: 'soyDistribuidor'
    }

    window.dataLayer.push(Egtm);
  }

  /**
   * Redireccion a home
   */
  navigateHome() {
    this.router.navigate(['/home']);
  }

  navigatePortal() {
    window.location.href = '/portal'
  }
  /** goToFaceBook*/
  goToFaceBook() {
    location.href = urlsExt.facebook;
  }
  /** goToIsntagram*/
  goToIsntagram() {
    location.href = urlsExt.instagram;
  }

  changeEnglish() {
    this.sendTags('english');
    window.location.assign('https://cloud.email-axakeralty.mx/Englishversion');
  }

  sendTags(section: string) {
    let obj: IEventGTM;
    switch (section) {
      case 'home':
        obj = {
          event: 'eventgtm',
          eventCategory: 'headerLinks',
          eventAction: 'goToHome',
          eventLabel: 'link_home_AK'
        }

        window.dataLayer.push(obj);
        break;

      case 'mapCovid':
        obj = {
          event: 'eventgtm',
          eventCategory: 'headerLinks',
          eventAction: 'mapCOVID19',
          eventLabel: 'link_realTimeMapCOVID'
        }

        window.dataLayer.push(obj);
        break;

      case 'call':
        obj = {
          event: 'eventgtm',
          eventCategory: 'headerLinks',
          eventAction: '(55) 5169 3080',
          eventLabel: 'link_header_call'
        }

        window.dataLayer.push(obj);
        break;

      case 'mail':
        obj = {
          event: 'eventgtm',
          eventCategory: 'headerLinks',
          eventAction: 'contacto formulario',
          eventLabel: 'link_header_contact'
        }

        window.dataLayer.push(obj);
        break;

      default:
        this.tagsContinue(section);
        break;
    }
  }

  tagsContinue(element: string) {
    let gtm: IEventGTM;

    switch (element) {
      case 'fb':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'headerLinks',
          eventAction: 'contacto facebook',
          eventLabel: 'link_header_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'insta':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'headerLinks',
          eventAction: 'contacto instagram',
          eventLabel: 'link_header_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'contract':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'homeMenu',
          eventAction: 'homePage_menuClick',
          eventLabel: 'click-Contratar'
        }

        window.dataLayer.push(gtm);
        break;

      case 'about-us':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'homeMenu',
          eventAction: 'homePage_menuClick',
          eventLabel: 'click-Nosotros'
        }

      case 'english':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'homeMenu',
          eventAction: 'homePage_menuClick',
          eventLabel: 'click-English version'
        }

        window.dataLayer.push(gtm);
        break;

      default:
        this.tagsFin(element);
        break;
    }
  }

  tagsFin(action: string) {
    let eGtm: IEventGTM;

    switch (action) {

      case 'CAMs':
        eGtm = {
          event: 'eventgtm',
          eventCategory: 'homeMenu',
          eventAction: 'homePage_menuClick',
          eventLabel: 'click-Ubicaciones'
        }

        window.dataLayer.push(eGtm);
        break;

      case 'contact-us':
        eGtm = {
          event: 'eventgtm',
          eventCategory: 'homeMenu',
          eventAction: 'homePage_menuClick',
          eventLabel: 'click-Contactanos'
        }

        window.dataLayer.push(eGtm);
        break;

      case 'faqs':
        eGtm = {
          event: 'eventgtm',
          eventCategory: 'homeMenu',
          eventAction: 'homePage_menuClick',
          eventLabel: 'click-Preguntas frecuentes'
        }

        window.dataLayer.push(eGtm);
        break;

      case 'videoconsultation':
        eGtm = {
          event: 'eventgtm',
          eventCategory: 'headerLinks',
          eventAction: 'videoconsulta gratis',
          eventLabel: 'link_header_videoconsulta'
        }

        window.dataLayer.push(eGtm);
        break;

      case 'servicesPay':
        eGtm = {
          event: 'eventgtm',
          eventCategory: 'homeMenu',
          eventAction: 'homePage_menuClick',
          eventLabel: 'click-Pago de servicios'
        }

        window.dataLayer.push(eGtm);
        break;
    }
  }
}