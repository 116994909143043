import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-no-balance',
  templateUrl: './no-balance.component.html',
  styleUrls: ['./no-balance.component.scss']
})
export class NoBalanceComponent implements OnInit {

  currentDate : Date;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.currentDate = new Date();
  }

  returnToHome(event){
    this.router.navigate(['/home']);
  }

}
