<div class="row text-center py-3 pt-6 mt-6 cl-keralty-blue mx-0">
  <div class="col-12 pt-6 mt-6">
    <p class=" pt-6 mt-6 third-section-text"><strong>¡En AXA Keralty</strong> nuestro compromiso es cuidarte!</p>
    <p class="third-section-text-second">Ponemos a tu disposición un mapa en tiempo real sobre el estado del COVID-19</p>
  </div>
</div>
<div class="col-6 mx-auto mb-4 text-center" >
  <div class="btn-group custom-align-self-center" role="group" aria-label="Basic example">
    <button
            type="button"
            class="btn btn-secondary"
            [ngClass]="defaultOpt ?'bg-keralty-blue': 'bg-keralty-gray'"
            (click)="defaultOpt=true; sendTag('mexico')"
    >Mapa México: CONABIO</button>
    <button
            type="button"
            class="btn btn-secondary"
            [ngClass]="defaultOpt ?'bg-keralty-gray': 'bg-keralty-blue'"
            (click)="defaultOpt=false; sendTag('mundial')"
    >Mapa Mundial: © Jonhs Hopkins University</button>
  </div>
</div>
<div class="d-none d-lg-block">
  <iframe *ngIf="defaultOpt"
          src="https://conabio.maps.arcgis.com/apps/opsdashboard/index.html#/06ab0b883d6b42e0bf4fef1ff0b56d25"
    class="iframe embed" title="map"
    #iframe
    referrerpolicy="no-referrer"
  ></iframe>
  <iframe *ngIf="!defaultOpt"
          src="https://www.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6"
          class="iframe embed" title="map"
          #iframe
          referrerpolicy="no-referrer"
  ></iframe>
</div>
<div class="d-lg-none">
  <iframe *ngIf="defaultOpt"
          src="https://conabio.maps.arcgis.com/apps/opsdashboard/index.html#/06ab0b883d6b42e0bf4fef1ff0b56d25"
          class="iframe embed" title="map"
          #iframe
          referrerpolicy="no-referrer"
  ></iframe>
  <iframe *ngIf="!defaultOpt"
          src="https://www.arcgis.com/apps/opsdashboard/index.html#/85320e2ea5424dfaaa75ae62e5c06e61"
          class="iframe embed" title="map"
          #iframe
          referrerpolicy="no-referrer"
  ></iframe>
</div>

<div class="row text-center justify-content-center py-3 background-full mx-0">
  <div class="col-12" *ngIf="!defaultOpt">
    © Johns Hopkins University
  </div>
  <div class="col-12" *ngIf="defaultOpt">
    © CONABIO
  </div>

</div>

<div class="row text-center justify-content-center py-3 background-full mx-0">
  <div class="col-12">
    <span class="title-text ">¿Te interesa conocer información oficial sobre el <span class="font-weight-bold">Coronavirus COVID-19</span></span>
    <p><span class="title-text">publicada por el <span class="font-weight-bold">Gobierno de México?</span></span></p>
  </div>
  <div class="btn">
    <a href="https://www.gob.mx/salud/documentos/nuevo-coronavirus" target="_blank" rel="noopener noreferrer" (click)="sendTag('infoMexico')"><button class="ak-btn-filledCerulean">Clic aquí</button></a>
  </div>
</div>
