import { Injectable } from '@angular/core';
import { AES } from 'crypto-js';
import { Crypt, RSA } from 'hybrid-crypto-js';
import * as encUTF8 from 'crypto-js/enc-utf8';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class CipherService {

    crypt = new Crypt();
    rsa = new RSA();

    publicKey = environment.publicKey;

    constructor() { }

    /**
     * Encripta llave SIM aleatoria con llave publica ASIM
     * @param keyToEnc 
     */
    setEnc(keyToEnc: any) {
        let dataEnc = this.crypt.encrypt(window.atob(this.publicKey), keyToEnc);
        return dataEnc;
    }

    /**
     *  Encripta el body con llave SIM aleatoria
     * @param data 
     * @param keyToParse 
     */
    async encrBody(data: any, keyToParse: any) {
        let key = encUTF8.parse(keyToParse);
        let iv = encUTF8.parse(environment.sim_iv);

        let encryptString = window.atob(AES.encrypt(JSON.stringify(data), key, { iv }).toString());
        return encryptString;
    }


    /**
     * Crea llave SIM aleatoria
     */
    cr_UUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxxAxxxxA4xxxByxxxCxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;

    }


}