import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentType'
})
export class DocumentTypePipe implements PipeTransform {

  /**
	 * Obtener el tipo de identificación del usuario
	 * @param documentType
	 * @param code
	 */
  transform(documentType: any, isNumber?: boolean): number | string {
    if(isNumber) {
      switch (Number(documentType)) {
        case 1: return 'CU';
        case 2: return 'IT';
        case 6: return 'ND';
        case 7: return 'OT';
        case 8: return 'PA';
        case 9: return 'IE';
        case 10: return 'RF';
        case 3: return 'ME';
        case 11: return 'TI';
        default: return 'CU';
      }
    } else {
      switch (String(documentType)) {
        case 'CU': return 1;
        case 'IT': return 2;
        case 'ND': return 6;
        case 'OT': return 7;
        case 'PA': return 8;
        case 'IE': return 9;
        case 'RF': return 10;
        case 'ME': return 3;
        case 'TI': return 11;
        default: return 1;
      } 
    }
	}

}
