import { IEventGTM, InterImpressions } from './../../../interfaces/gtmEvent.interface';

/** import Angular Component, OnInit */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
/** import url.constants*/
import { urlsLoc } from 'src/app/constants/urls.constants';
import { ChatService, Message } from '../../../services/chat/chat.service';
import { Observable} from 'rxjs';
import { DimentionService } from 'src/app/services/dimention/dimention.service';
import { ConsultService } from 'src/app/services/consult.service';
import { visitUsService } from '../visit-us/visit-us.service';
// INTERFACES
import { IBannerHeader } from 'src/app/interfaces';
// CONSTANTS
import { SERVICES, BANNERS } from 'src/app/constants';

/**
 * Component
 */
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BannerComponent implements OnInit {
  /** inicialización de messages Observable */
  messages: Observable<Message[]>;
  /** inicialización de formValue  */
  formValue: string;
  /** inicialización de showOptions*/
  showOptions: string;
  /** inicialización de message*/
  message: string;
  /** inicialización de title component*/
  title = "2B Bot";
  /** inicialización de band_options */
  band_options: boolean;
  /** inicialización de band */
  public band: boolean;
  /** inicialización de history Array */
  history: Message[] = [];
  /** inicialización fadeOf */
  fadeOf = true;
  /** URL Servidor */
  urlServer: string = '';
  widthMobile = false;
  /**inicialización writingMessage*/
  writingMessage: boolean;
  /** Arreglo de banners */
  banners: IBannerHeader[] = BANNERS;
  /** Arreglo de servicios */
  GENERAL_SERVICES = SERVICES;

  impressionsObj: InterImpressions;
  constructor(
    private chatService: ChatService,
    private dimention : DimentionService,
    private router: Router,
    private userInfo: ConsultService,
    private visitUsService: visitUsService,
  ) {
    this.urlServer = 'https://preprod.axakeralty.mx';
  }
  /** ngOnInit*/
  ngOnInit() {
    this.chatService.toggle.asObservable().subscribe((res) => {
      this.band = res;
    });
    this.impressions();
    this.dimention.widthSize.subscribe((resp) => {
      if (resp < 850) {
        this.widthMobile = true;
      } else {
        this.widthMobile = false;
      }
    });
  }

  showCamTijuana() {
    this.userInfo.showTijuana.next(true);
  }

  /**
   * Redireccion a CAM mtty
   */
  goToCamMTY() {
    this.userInfo.showMapMTY.next(true);
  }
  goGranSurLocation(event)
  {

    let eGtm: IEventGTM;

    eGtm = {
      event: 'eventgtm',
      eventCategory: 'headerLinks',
      eventAction: 'Centro de Atención Médica',
      eventLabel: 'link_header_goCAMs'
    }

    window.dataLayer.push(eGtm);

    this.visitUsService.changeLocations.next({location: 0});
    setTimeout(()=>{
      window.scroll({top: window.scrollY + 600})
      this.visitUsService.changeCamShow.next({cam: 4});
    },2000);
  }
  goToHermosillo(event)
  {
    let eGtm: IEventGTM;

    eGtm = {
      event: 'eventgtm',
      eventCategory: 'headerLinks',
      eventAction: 'Clínica Plaza Boulevard',
      eventLabel: 'link_header_goCAMs'
    }

    window.dataLayer.push(eGtm);

    this.visitUsService.changeLocations.next({locationKey: "SONORA"});
    setTimeout(()=>{
      window.scroll({top: window.scrollY + 600})
      //this.visitUsService.changeCamShow.next({cam: 0});
      this.visitUsService.changeCamShow.next({camSquare: "Clínica Plaza Boulevard",locationKey: "SONORA"});
    },2000);
  }

  bannerAction($event, funtionName){
    $event.preventDefault()
    this[funtionName]()
  }
  goToChihuahua(event)
  {
    this.visitUsService.changeLocations.next({locationKey: "CHIHUAHUA"});
    setTimeout(()=>{
      if (this.widthMobile == true){
        window.scroll({top: window.scrollY + 4800})
      } else {
        window.scroll({top: window.scrollY + 3650})
      }
      //this.visitUsService.changeCamShow.next({cam: 0});
      this.visitUsService.changeCamShow.next({camSquare: "Clínica Plaza San Ángel",locationKey: "CHIHUAHUA"});
    },2000);
  }

  bannerVideo($event, watchVideoUrl){ 
    window.open('https://youtu.be/7CVo3fS8h2Q');
  }

  bannerdocument($event, watchDocumentoUrl){ 
    window.open('https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/1fe23e48-c33b-455d-ac80-5717ee6e2f8b.pdf');
  }

  goToLocations(event)
  {
    let eGtm: IEventGTM;

    eGtm = {
      event: 'eventgtm',
      eventCategory: 'headerLinks',
      eventAction: 'Centro de Atención Médica',
      eventLabel: 'link_header_goCAMs'
    }

    window.dataLayer.push(eGtm);
    this.visitUsService.changeLocations.next(null);
  }
  /**
   * Redireccion a homepage
   */
  visitHome() {
    window.location.replace('/cotizador-planmed/');
  }

  goToPayment(){

    this.userInfo.typeService.next('prueba-covid');
    let consultRate = this.GENERAL_SERVICES.filter(consult => consult.type === 'prueba-covid');
    // console.log("consultRate", consultRate);
    
    this.userInfo.rateConsult.next(consultRate[0]);
    this.router.navigate(['/servicios-pago']);
  }
  goPaymentAntigen() {
    this.userInfo.typeService.next('prueba-antigenos');
    let consultRate = this.GENERAL_SERVICES.filter(consult => consult.type === 'prueba-antigenos');
    // console.log("consultRate", consultRate);
    
    this.userInfo.rateConsult.next(consultRate[0]);
    this.router.navigate(['/servicios-pago']);
  }

   /**
   * Redireccion a Portal de clientes
   */
  visitPortal() {
    window.location.replace('/portal/');
  }
  /**
   * Método openChat
   */
  openChat() {
    /** Evento enviado al dataLayer para taggeo */
    const eGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'bannerButtons',
      eventAction: 'cuestionario COVID19',
      eventLabel: 'btn_banner_carousel2'
    }

    this.chatService.changetoggle(this.band=true);
  }

  logChange(event) {
  }

  /** addToCartEvent*/
  addToCartEvent(section: string) {
    /** Evento enviado al dataLayer para taggeo */
    let gtm: IEventGTM;

    if (section !== 'discount') {
      gtm = {
        event: 'eventgtm',
        eventCategory: 'homeButtons',
        eventAction: 'homePage_GoCotizamed',
        eventLabel: 'btnTop_homePage_goToCotizamed'

      }

      window.dataLayer.push(gtm);

    } else {
      gtm = {
        event: 'eventgtm',
        eventCategory: 'homeButtons',
        eventAction: 'discountPlanmed'
      }

      window.dataLayer.push(gtm);
      this.clickImpressions(this.impressionsObj);
    }
    location.href = this.urlServer + urlsLoc.cotizador;
  }

  /** Redirecciona por ambiente */
  redirect(): void {
    location.href = this.urlServer + '/consultas/';
  }

  sendTag(element: string) {
    let eventGtm: IEventGTM;

    switch (element) {
      case 'conoce':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'homeButtons',
          eventAction: 'homePage_GoPlanmed',
          eventLabel: 'btn_banner_plans'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'liverpool':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'bannerButtons',
          eventAction: 'contrata_aqui',
          eventLabel: 'btn_banner_carousel_1'
        }
        break;

      case 'videoconsult':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'bannerButtons',
          eventAction: 'videoconsulta gratis',
          eventLabel: 'btn_banner_carousel2'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'moreInfo':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'bannerButtons',
          eventAction: 'mas informacion',
          eventLabel: 'btn_banner_carousel4'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'COVIDMexico':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'bannerButtons',
          eventAction: 'consulta aqui',
          eventLabel: 'btn_banner_carousel4'
        }

        window.dataLayer.push(eventGtm);
        break;
    }
  }

  redirectToPdf(event)
  {
    if(event)
    {
      event.preventDefault();
    }
    window.location.href = "https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/877719a6-221c-40f8-8e13-44cbfedd83d6.pdf";
  }

  impressions(){
    this.impressionsObj = {
      id: 'contrata tu planmed',
      name: 'contrata tu planmed',
      creative: 'venta en linea',
      position: 'bannerButton'
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'promoImpressions',
      ecommerce: {
        promoView: {
          promotions: [this.impressionsObj]
        }
      }
    });
   }

   clickImpressions(promoObj) {
    window.dataLayer.push({
     event: 'promotionClick',
     ecommerce: {
       promoClick: {
         promotions: [
          {
            id: this.impressionsObj.id,
            name: this.impressionsObj.name,
            creative: this.impressionsObj.creative,
            position: this.impressionsObj.position
          }]
       }
     }
   });
 }
}
