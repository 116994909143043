<div class=" background pb-5">
<div class="container-xl">
    <div class="row justify-content-center py-5">
        <span class="about-us__title">
            ¿Quiénes somos?
        </span>
    </div>
    <div class="row">
        <div class="col-12 mb-2">
            <p class="about-us__subtitle">AXA y Keralty, empresas expertas en seguros y prestación de servicios
                médicos respectivamente, se unen
                para crear un <strong>sistema integral de salud </strong>que te acompaña de forma personal, ofreciendo los cuidados para
                la salud y la de tu familia.</p>
        </div>
    </div>
    <div class="row justify-content-center pt-lg-3 pt-lg-5">
        <div class="col-md-10 col-12 px-4 pt-4">
            <div class="row">
                <div class="col-md-6 col-12 px-4 mb-3">
                    <p class="about-us__tab-title">
                        Misión
                    </p>
                    <p class="about-us__tab-txt mx-auto">
                       <strong> Brindar bienestar a las familias mexicanas</strong> a lo largo de toda su vida, a través de un modelo
                        integrado de cuidado en salud, social y comunitario.
                    </p>
                    <p class="about-us__tab-title mt-5">
                        Visión
                    </p>
                    <p class="about-us__tab-txt mx-auto mb-5 mb-lg-0">
                        Ser reconocidos por <strong>transformar</strong> la salud privada en México.
                    </p>
                </div>
                <div class="col-md-6 col-12">
                    <p class="about-us__tab-title">
                        Valores
                    </p>
                    <div class="d-flex">
                        <div class="col-2 pl-md-0 pl-lg-3">
                           <div class="circle">
                            <span class="icon-circle-star ak-icon"><span class="path1"></span><span class="path2"></span></span>
                           </div>
                        </div>

                        <div class="col-10">
                            <p class="mb-0 ml-3 about-us__tab-txt-subvalores">Integridad</p>
                            <p class="ml-3 about-us__tab-txt-valores">Teniendo tu cuidado como guía, trabajamos para
                                hacer lo correcto.</p>
                        </div>

                    </div>
                    <div class="d-flex">
                        <div class="col-2 pl-md-0 pl-lg-2">
                            <div class="circle">
                                <span class="icon-circle-heart ak-icon"><span class="path1"></span><span class="path2"></span></span>
                               </div>
                        </div>

                        <div class="col-10">
                            <p class="mb-0 ml-3 about-us__tab-txt-subvalores">Compasión</p>
                            <p class="ml-3 about-us__tab-txt-valores">Nos ponemos en tu lugar para acompañarte y
                                ayudarte.</p>
                        </div>

                    </div>
                    <div class="d-flex">
                        <div class="col-2 pl-md-0 pl-lg-2">
                            <div class="circle">
                                <span class="icon-circle-lock ak-icon"><span class="path1"></span><span class="path2"></span></span>
                               </div>
                        </div>

                        <div class="col-10">
                            <p class="mb-0 ml-3 about-us__tab-txt-subvalores">Protección</p>
                            <p class="ml-3 about-us__tab-txt-valores">Te ofrecemos cuidados con el dinamismo y
                                entrega de nuestro equipo.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row my-5">
        <div class="mb-2 col-12 col-md text-center">
            <button class="btn about-us__mb-button float-md-right" (click)="sendTag()"
                    pageScroll [routerLink]="['/home']" href="#hiw" pageScrollOffset="35"
            > CONOCE <img class="mt-n2"
                    src="assets/logos/Planmed logo_w.png" alt="Logotipo de Planmed®"> </button>
        </div>
        <div class="col-12 col-md text-center">
            <button class="btn about-us__mb-button float-md-left mt-3 mt-md-0" (click)="addToCartEvent()"> CONTRATA <img class="mt-n2"
                    src="assets/logos/Planmed logo_w.png" alt="Logotipo de Planmed®"> </button>
        </div>
    </div>

</div>
</div>
