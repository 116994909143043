import { IEventGTM } from 'src/app/interfaces/gtmEvent.interface';
/** import Angular Component, OnInit, PLATFORM_ID, Inject */
import { Component, OnInit, PLATFORM_ID, Inject, ViewChild } from '@angular/core';
/** import map.constant*/
import { MAP } from '../../../constants/map.constant';
/** import isPlatformBrowser*/
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { isPlatformBrowser } from '@angular/common';
import { DimentionService } from 'src/app/services/dimention/dimention.service';
import { ConsultService } from 'src/app/services/consult.service';
import { UntypedFormControl } from '@angular/forms';

import { visitUsService } from './visit-us.service';

/**
 * componente visitanos
 */
@Component({
  selector: 'app-visit-us',
  templateUrl: './visit-us.component.html',
  styleUrls: ['./visit-us.component.scss']
})

export class VisitUsComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  /** Variable title*/
  title = 'ngSlick';
  google: any;
  searchVideo = new UntypedFormControl();
  /** Variable isBrowser*/
  isBrowser: Boolean;
  zoomActiveCity: any;
  latActiveCity: any;
  showButtons = true;
  longActiveCity: any;
  usingFinder = false;
  notFind = false;
  /** Variable que verifica m+obile */
  isMobile = false;
  constCentroMty = {
    id_number: 5,
    latitude: 25.66778569644115,
    longitude: -100.32000844344641,
    title: 'Monterrey',
    square: 'Centro Cuauhtémoc',
    parking: 'Estacionamiento con convenio AXA Keralty, presentando boleto sellado en recepción.',
    description: `Miguel Hidalgo 330, Col. Centro, C.P. 64070, Monterrey, N.L.`,
    help_information: 'Planta baja, a cinco minutos de la Macroplaza.',
    location: 'https://goo.gl/maps/9vbGzixZnEFbzgRm7',
    location_apple: 'maps://goo.gl/maps/HX5VdW5piHPVgPFT7',
    location_waze: 'https://ul.waze.com/ul?place=ChIJ5-AAPCS-YoYREcDIZ3CMpVY&ll=25.66770480%2C-100.31998070&navigate=yes',
    tels: '',
    email: '',
    closedIcon: '/assets/map/candado.png',
    hour: 'Lunes a Sábado 8:00 a 20:00 hrs.',
    img: 'assets/map/_EK_3273.png',
    alt: 'Centro de Atención Médica de AXA Keralty en Cuauhtémoc',
    responsable_name: 'María Corazón García Pérez',
    responsable_education: "Universidad Autónoma de Nuevo León",
    responsable_number: "Cédula profesional no. 8979061",
    expanded: false
  }
  constParquesPlaza = {
    id_number: 0,
    latitude: 19.438993,
    longitude: -99.177687,
    title: 'Parques Plaza',
    parking: 'El centro comercial cuenta con estacionamiento independiente a AXA Keralty',
    square: 'Parques Plaza Nuevo Polanco',
    description: `Lago Alberto 442, Anáhuac I Secc, Alcaldía Miguel Hidalgo, CP 11320, CDMX.`,
    help_information: 'Dentro de Parques Plaza, planta baja, entrada principal sobre Bahía de San Hipólito',
    location: 'https://goo.gl/maps/fteTFneTCa528BKB7',
    location_waze: 'https://ul.waze.com/ul?place=ChIJoRLNwrH40YUR6DC4QX6cFow&ll=19.43879590%2C-99.17805180&navigate=yes',
    tels: '',
    email: '',
    closedIcon: '',
    hour: 'Lunes a Sábado de 08:00 a 20:00 hrs.',
    img: 'assets/map/CAM-Parques Plaza.jpg',
    alt: 'Centro de Atención Médica de AXA Keralty en Parques Plaza',
    responsable_name: 'Carlos Alberto López García',
    responsable_education: "Universidad Michoacana de San Nicolás de Hidalgo",
    responsable_number: "Cédula profesional no. 9696628",
    closed: '',
    expanded: false
  };
  /**Constatnte de estatcionamientos cerca CAM ROMA */
  ConstParking = 'https://www.google.com/maps/search/estacionamiento/@19.4056586,-99.1687132,17z/data=!3m1!4b1!4m8!2m7!3m6!1sestacionamiento!2sAv.+Baja+California+200,+Roma+Sur,+Cuauht%C3%A9moc,+06760+Ciudad+de+M%C3%A9xico,+CDMX!3s0x85d1ff152239e6e3:0xee128d6a96a43add!4m2!1d-99.1665245!2d19.4056586';
  ConstParkingLeon = 'https://www.google.com.mx/maps/search/estacionamiento/@21.1502078,-101.6967672,14.94z/data=!4m8!2m7!3m6!1sestacionamiento!2sM%C3%A9dica+Campestre,+Manantial+106,+Futurama+Monterrey,+37180+Le%C3%B3n,+Gto.!3s0x842bbf463e59920b:0x56bc73f7d012fa7d!4m2!1d-101.68736!2d21.150167'
  /**
   * Slides
   *
   * @memberof VisitUsComponent
   */
  slidesMap = MAP.CAROUSEL_OPTIONS;
  OriginalSlidesMap = MAP.CAROUSEL_OPTIONS;
  slides = [
    { img: "assets/visitus/_EK_0533-ok.jpg", alt: "Instalaciones limpias y seguras con atención personalizada en Centro de Atención Médica Plaza Sentura" },
    { img: "assets/visitus/_EK_1507-ok.jpg", alt: "Sala de espera con juegos para niños en área de pediatría de Planmed" },
    { img: "assets/visitus/_EK_1569-ok.jpg", alt: "Consultorios equipados en CAM Sentura" },
    { img: "assets/visitus/_EK_1707-ok.jpg", alt: "Consultorios de especialidades de alta calidad en AXA Keralty" },
    { img: "assets/visitus/_EK_2175-ok.jpg", alt: "Sala de espera cómodas en Centros de Atención Médica de AXA Keralty" },
    { img: "assets/visitus/_EK_2593-ok.jpg", alt: 'Vinil en la pared con la leyenda "Nuestro compromiso es cuidarte para que disfrutes la vida" en Planmed®' },
    { img: "assets/visitus/_EK_3183_1-ok.jpg", alt: "Vinil autoadherible decorativo en pasillo de Centro de Atención Médica de AXA Keralty" },
    { img: "assets/visitus/_EK_3273-ok.jpg", alt: "Consultorio pediádrico en AXA Keralty" },
    { img: "assets/visitus/_EK_3415_1-ok.jpg", alt: "Interior del Centro de Atención Médica Roma con acceso a consultorios pediátricos" },
    { img: "assets/visitus/_EK_3938_1-ok.jpg", alt: " Pasilllo CAM Roma de AXA Keralty" },
    { img: "assets/visitus/_EK_4042-ok.jpg", alt: "Instalaciones de vanguardia para atención médica especializada en la contratación de tu Planmed®" },
    { img: "assets/visitus/_EK_5159-ok.jpg", alt: "Vinil adherible con forma de mono en una selva para recibir a los pacientes más pequeños en clínica de AXA Keralty" },
  ];

  /**
   * Configuración de slide
   *
   * @memberof VisitUsComponent
   */
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    focusOnSelect: true,
    cssEase: 'linear',
    arrows: true,
    centerMode: true,
    centerPadding: 0,
    autoplay: true,
    autoplaySpeed: 4000
  };
  slideConfigMap = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    focusOnSelect: true,
    cssEase: 'linear',
    arrows: true,
    centerMode: true,
    centerPadding: '20px',
    autoplay: false,
    responsive: [
      {
        breakpoint: 1288,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1278,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1044,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 997,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
        }
      },
    ]
  };

  /**
   * Inicialización de marcadores
   *
   * @memberof VisitUsComponent
   */
  markers: any = MAP.MARKERS;

  markersMobile: any = MAP.MARKERS;
  markerCams = [];

  /**
   * Objeto para mostrar marcador seleccionado en tarjeta de información
   *
   * @memberof VisitUsComponent
   */
  markerSelected: any;

  /**
   * Variable para mostrar u ocultar tarjeta de información
   *
   * @memberof VisitUsComponent
   */
  showCard = false;
  /**
  * Definicion de latitud de maoa
  */
  lat = 20.452468;
  /**
   * Inicializacion de longitud de mapa
   */
  lng = -105.170437;
  /**
   * Inicializacion de valor para zoom de mapa
   */
  zoom = 6;
  optionsGeneralMap = {
    lat: 25.452468,
    lng: -105.170437,
    zoom: 5
  }
  hideGeneralMap = false;
  activeCity = '';
  activeCityCode = '';
  iconGeneral = {
    url: 'assets/map/pin_locator_.png',
    scaledSize: {
      width: 37,
      height: 53
    }
  }
  /**
   * Estilos de mapa
   *
   * @memberof VisitUsComponent
   */
  styles: google.maps.MapTypeStyle[] = [{
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#edefef"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#133967"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "weight": 1
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#EAEDF2"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#D8DFE8"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.medical",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#0075e2"
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#CBD5E0"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#D8DFE8"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#98B3D5"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }

  ]

  /**
   * Constructor
   * @param platformId 
   */
  constructor(@Inject(PLATFORM_ID) private platformId,
    private dimention: DimentionService,
    private userInfo: ConsultService,
    private visitUsService: visitUsService) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  /** ngOnInit */
  ngOnInit() {
    this.visitUsService.changeLocations.subscribe(data => {
      if (data && (data.location || data.location === 0)) //Ir a una ubicación por el índice en el carrusel
      {
        this.slickModal.slickGoTo(data.location);
        this.changeActive(data.location, this.slidesMap[data.location])
      }
      else if (data && (data.locationKey)) //Ir a una ubicación por la clave del estado
      {
        let carousel = MAP.CAROUSEL_OPTIONS;
        for (let i = 0; i < carousel.length; i++) {
          if (carousel[i].codeCity == data.locationKey) {
            this.slickModal.slickGoTo(i);
            this.changeActive(i, this.slidesMap[i])
            return;
          }
        }
      }
    }
    );
    this.visitUsService.changeCamShow.subscribe(
      data => {
        if (data && (data.cam || data.cam === 0)) //Se activa el CAM por el índice en el estado de red-markers
        {
          this.showCardFunction(this.markers[data.cam]);
        }
        else if (data && data.camSquare && data.locationKey) // Se activa el cam buscándolo por el square y la clave de estado
        {
          let location = MAP.MARKERS_RED[data.locationKey];
          let cams = location.cams;
          if (cams.length) {
            for (let i = 0; i < cams.length; i++) {
              if (cams[i].square == data.camSquare) {
                if (this.isMobile == true) {
                  this.expandItem(cams[i], i)
                } else {
                  this.showCardFunction(this.markers.cams[i]);
                }
                return;
              }
            }
          }
        }
      }
    )
    this.userInfo.showTijuana.subscribe(response => {
      if (response) {
        this.changeActive(2, this.slidesMap[2]);
        this.showCardFunction(this.markers[1]);
      }
    });
    this.searchVideo = undefined;
    if (typeof window.localStorage !== 'undefined') {
      let value = localStorage.getItem('showMap');
      if (value === 'true') {
        let cam = localStorage.getItem('selectedCAM');
        switch (cam) {
          case 'cdmx':
            this.changeActive(0, this.slidesMap[0]);
            localStorage.setItem('showMap', 'false');
            break;
          case 'parques-plaza':
            this.changeActive(0, this.slidesMap[0]);
            this.showCardFunction(this.markers[0]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'altavista':
            this.changeActive(0, this.slidesMap[0]);
            this.showCardFunction(this.markers[1]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'roma':
            this.changeActive(0, this.slidesMap[0]);
            this.showCardFunction(this.markers[2]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'via-515':
            this.changeActive(0, this.slidesMap[0]);
            this.showCardFunction(this.markers[3]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'gran-sur':
            this.changeActive(0, this.slidesMap[0]);
            this.showCardFunction(this.markers[4]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'sentura':
            this.changeActive(1, this.slidesMap[1]);
            this.showCardFunction(this.markers[0]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'mexicali':
            this.changeActive(2, this.slidesMap[2]);
            this.showCardFunction(this.markers[0]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'tijuana':
            this.changeActive(2, this.slidesMap[2]);
            this.showCardFunction(this.markers[1]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'ciudad-juarez':
            this.changeActive(3, this.slidesMap[3]);
            this.showCardFunction(this.markers[0]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'chihuahua':
            this.changeActive(3, this.slidesMap[3]);
            this.showCardFunction(this.markers[1]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'leon':
            this.changeActive(4, this.slidesMap[4]);
            this.showCardFunction(this.markers[0]);
            localStorage.setItem('showMap', 'false');
            break;
          case 'leon-puerta-interior':
            this.changeActive(4, this.slidesMap[4]);
            this.showCardFunction(this.markers[1]);
            localStorage.setItem('showMap', 'false');
            break;
          case 'guadalajara':
            this.changeActive(5, this.slidesMap[5]);
            this.showCardFunction(this.markers[0]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'monterrey':
            this.changeActive(6, this.slidesMap[6]);
            this.showCardFunction(this.markers[0]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'puebla':
            this.changeActive(7, this.slidesMap[7]);
            this.showCardFunction(this.markers[0]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'queretaro':
            this.changeActive(8, this.slidesMap[8]);
            this.showCardFunction(this.markers[0]);
            localStorage.setItem('showMap', 'false');
            break;

          case 'hermosillo-boulevard':
            this.changeActive(9, this.slidesMap[9]);
            this.showCardFunction(this.markers[0]);
            localStorage.setItem('showMap', 'false');
            break;
          case 'hermosillo-noroeste':
            this.changeActive(9, this.slidesMap[9]);
            this.showCardFunction(this.markers[1]);
            localStorage.setItem('showMap', 'false');
            break;


        }
      } else {

      }
    }
    this.dimention.widthSize.subscribe(response => {

      if (response < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      if (response < 750) {
        this.optionsGeneralMap.zoom = 4;
        this.iconGeneral.scaledSize.width = 17;
        this.iconGeneral.scaledSize.height = 23;
      }
      if (response > 750) {
        this.iconGeneral.scaledSize.width = 37;
        this.iconGeneral.scaledSize.height = 53;
      }
    });
    this.userInfo.showMapMTY.subscribe(resp => {
      if (resp === true) {
        this.showCardFunction(this.constCentroMty);
        this.userInfo.showMapMTY.next(false);
      } else {

      }
    });

  }

  getDifferentsCodeCity() {
    let existsCodeCity = [];
    let codeCity = [];
    for (let i = 0; i < this.slidesMap.length; i++) {
      if (!existsCodeCity.includes(this.slidesMap[i].codeCity)) {
        codeCity.push(this.slidesMap[i]);
        existsCodeCity.push(this.slidesMap[i].codeCity);
      }
    }
    return codeCity;
  }

  /**
   * Función para abrir tarjeta de información en mapa
   *
   * @param {*} object
   * @memberof VisitUsComponent
   */
  showCardFunction(object) {
    object.hour = object.hour.split("\n").join("<br/>");
    this.markerSelected = object;
    this.showCard = true;
    this.markers = [object];
    console.log(this.markers)
    this.zoom = 14;
    this.lat = object.latitude;
    this.lng = object.longitude;

    this.sendTagCAM(this.markerSelected.title);
  }

  sendToMap(link) {
    window.open(`${link}`);
  }


  onMapReady(map) {
    map.setOptions({
      zoomControl: 'true',
      zoomControlOptions: {
        position: 9
      }
    });
  }
  /**
   * 
   * @param object Funcion que muestra CAM seleccionado en mobile
   */
  expandItem(object, index) {

    object.hour = object.hour.split("\n").join("<br/>");
    this.markerSelected = object;
    this.sendTagCAM(this.markerSelected.title);
    console.log(this.markersMobile.length);

    for (let i = 0; i < this.markerCams.length; i++) {
      //if (index === this.markersMobile[i].id_number ) {
      if (object.square === this.markerCams[i].square && object.description === this.markerCams[i].description) {
        this.markerCams[i].expanded = !this.markerCams[i].expanded;
      } else {
        this.markerCams[i].expanded = false;
      }
    }
  }

  changeActiveClic(item, slide) {

    window.dataLayer.push({
      'event': 'eventgtm',
      'eventCategory': 'homeButtons',
      'eventAction': 'homePage_searchLocation',
      'eventLabel': 'btn-' + slide.nameDetail
    })

    this.changeActive(item, slide);
  }

  changeActive(item, slide) {
    this.activeCityCode = slide.codeCity;
    this.closeCardFunction();
    this.hideGeneralMap = true;
    for (let i = 0; i < this.slidesMap.length; i++) {
      if (item === i) {
        this.slidesMap[i].active = true;
      } else {
        this.slidesMap[i].active = false;
      }
    }
    this.activeCityCode = slide.codeCity;
    this.markers = MAP.MARKERS_RED[slide.codeCity].cams;
    console.log(this.markers);
    this.markersMobile = MAP.MARKERS_RED[slide.codeCity].cams;
    this.markerCams = MAP.MARKERS_RED[slide.codeCity].cams;
    switch (slide.nameDetail) {
      case 'CDMX':
        this.activeCity = 'Ciudad de México';
        break;
      case 'EDOMEX':
        this.activeCity = 'Estado de México';
        break;
      case 'Sentura':
        this.activeCity = 'Estado de México';
        break;
      case 'Toluca':
        this.activeCity = 'Estado de México';
        break;
      case 'Parques Plaza':
        this.activeCity = 'Ciudad de México';
        break;
      case 'Puerta Altavista':
        this.activeCity = 'Ciudad de México';
        break;
      case 'Roma':
        this.activeCity = 'Ciudad de México';
        break;
      case 'Vía 515':
        this.activeCity = 'Ciudad de México';
        break;
      case 'Gran Sur':
        this.activeCity = 'Ciudad de México';
        break;
      case 'Mexicali':
        this.activeCity = 'Baja California';
        break;
      case 'Tijuana':
        this.activeCity = 'Baja California';
        break;
      case 'Ciudad Juárez':
        this.activeCity = 'Chihuahua';
        break;
      case 'Chihuahua':
        this.activeCity = 'Chihuahua';
        break;
      case 'Aranda de la Parra':
        this.activeCity = 'Guanajuato';
        break;
      case 'León':
        this.activeCity = 'Guanajuato';
        break;
      case 'Aranda de la Parra – Puerto Interior':
        this.activeCity = 'Guanajuato';
        break;
      case 'Guadalajara':
        this.activeCity = 'Jalisco';
        break;
      case 'Monterrey':
        this.activeCity = 'Nuevo León';
        break;
      case 'Hermosillo':
        this.activeCity = 'Sonora';
        break;
      case 'Coahuila':
        this.activeCity = 'Coahuila';
      default:
        this.activeCity = slide.nameDetail;
        break;
    };
    this.latActiveCity = slide.lat;
    this.longActiveCity = slide.long;
    this.zoomActiveCity = slide.zoom;
    this.zoom = slide.zoom;
    this.lat = slide.lat;
    this.lng = slide.long;
  }
  zoomIn(typeMap) {
    if (typeMap === 'general') {
      this.optionsGeneralMap.zoom = this.optionsGeneralMap.zoom + .7;
    } else {
      this.zoom = this.zoom + .7;
    }

  }
  zoomOut(typeMap) {
    if (typeMap === 'general') {
      this.optionsGeneralMap.zoom = this.optionsGeneralMap.zoom - .7;
    } else {
      this.zoom = this.zoom - .7;
    }
  }
  /**
   * Función para cerrar tarjeta de información en mapa
   *
   * @memberof VisitUsComponent
   */
  closeCardFunction() {
    this.showCard = false;
    this.markers = MAP.MARKERS_RED[this.activeCityCode].cams;
    this.markerSelected = {
      latitude: 0,
      longitude: 0,
      title: '',
      description: '',
      location: '',
      tels: '',
      email: '',
      hour: '',
      img: '',
      alt: '',
      closed: '',
      closedIcon: ''
    }
    this.zoom = this.zoomActiveCity;
    this.lat = this.latActiveCity;
    this.lng = this.longActiveCity;
  }

  getDescriptions() {
    return MAP.MARKERS_RED[this.activeCityCode].descriptions;
  }

  /**
   * Función para redireccionar a google maps con ubicacion de lugar
   */
  information() {
    window.open('https://cloud.email-axakeralty.mx/ofertaservicios', '_blank');
  }



  redirectMaps() {
    window.open('https://maps.googleapis.com/maps/api/staticmap?key=YOUR_API_KEY&center=19.405814755594136,-99.16652700000002&zoom=18&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0xedefef&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0xa4a4a4&style=element:labels.text.stroke%7Ccolor:0xffffff%7Cweight:1&style=feature:administrative%7Celement:geometry%7Cvisibility:off&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Cvisibility:off&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.medical%7Celement:geometry.fill%7Ccolor:0x0075e2%7Cvisibility:off&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit%7Cvisibility:off&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=480x360', '_blank');
  }

  sendTagCAM(camSelected: string) {
    let eventGtm: IEventGTM;

    switch (camSelected) {
      case 'Parques Plaza':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'parquesPlaza'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Puerta Altavista':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'puertaAltavista'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Roma':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'roma'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Sentura':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'sentura'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Vía 515':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'via515'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Gran Sur':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'granSur'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Toluca':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'toluca'
        }

        window.dataLayer.push(eventGtm);
        break;
    }
  }

  sendTagMail(selected: string) {
    let eventGtm: IEventGTM;

    switch (selected) {
      case 'Parques Plaza':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'parquesPlaza',
          eventLabel: 'GA_map_email'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Puerta Altavista':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'puertaAltavista',
          eventLabel: 'GA_map_email'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Roma':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'roma',
          eventLabel: 'GA_map_email'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Sentura':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'sentura',
          eventLabel: 'GA_map_email'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Vía 515':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'via515',
          eventLabel: 'GA_map_email'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Gran Sur':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'granSur',
          eventLabel: 'GA_map_email'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Toluca':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'toluca',
          eventLabel: 'GA_map_email'
        }

        window.dataLayer.push(eventGtm);
        break;
    }
  }

  sendTagCall(select: string) {
    let eventGtm: IEventGTM;
    console.log(select)

    switch (select) {
      case 'Parques Plaza':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'parquesPlaza',
          eventLabel: 'GA_map_linkContactTel'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Puerta Altavista':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'puertaAltavista',
          eventLabel: 'GA_map_linkContactTel'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Roma':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'roma',
          eventLabel: 'GA_map_linkContactTel'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Sentura':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'sentura',
          eventLabel: 'GA_map_linkContactTel'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Vía 515':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'via515',
          eventLabel: 'GA_map_linkContactTel'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Gran Sur':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'granSur',
          eventLabel: 'GA_map_linkContactTel'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Toluca':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'tocula',
          eventLabel: 'GA_map_linkContactTel'
        }

        window.dataLayer.push(eventGtm);
        break;
    }
  }


  logEvent(event: any) {
    this.usingFinder = true;
    if (event === '') {
      this.slidesMap = this.OriginalSlidesMap;
      this.showCarrusel();
    }

    // let copy_GENERAL_SERVICES = this.GENERAL_SERVICES;

    let newEvent = event.normalize('NFD')
      .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
      .normalize();
    const delimeterRegex = /[ _-]+/;
    this.slidesMap = this.OriginalSlidesMap.filter(function (tag) {

      let newTagName = (tag.nameDetail).normalize('NFD')
        .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
        .normalize();
      var names = newTagName.split(delimeterRegex);
      return newTagName.toLowerCase().indexOf(newEvent.toLowerCase()) === 0;

    });
    const hash = {};
    this.slidesMap = this.slidesMap.filter(function (current) {
      var exists = !hash[current.codeCity];
      hash[current.codeCity] = true;
      return exists;
    });
    console.log(this.slidesMap);
    if (this.slidesMap.length === 0) {
      this.notFind = true;
    } else if (this.slidesMap.length === 12) {
      this.showCarrusel();
    } else {
      this.notFind = false;
    }
  }
  showCarrusel() {
    this.usingFinder = false;
    this.notFind = false;
    this.searchVideo = undefined;
    this.slidesMap = this.OriginalSlidesMap;
    for (let i = 0; i < this.slidesMap.length; i++) {
      this.slidesMap[i].active = false;
    }
    this.hideGeneralMap = false;
    this.markers = MAP.MARKERS;
  }
  sendTagMaps(selectCam: string) {
    let eventGtm: IEventGTM;

    switch (selectCam) {
      case 'Parques Plaza':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'parquesPlaza',
          eventLabel: 'GA_map_goToMaps'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Puerta Altavista':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'puertaAltavista',
          eventLabel: 'GA_map_goToMaps'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Roma':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'roma',
          eventLabel: 'GA_map_goToMaps'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Sentura':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'sentura',
          eventLabel: 'GA_map_goToMaps'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Vía 515':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'via515',
          eventLabel: 'GA_map_goToMaps'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Gran Sur':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'granSur',
          eventLabel: 'GA_map_goToMaps'
        }

        window.dataLayer.push(eventGtm);
        break;

      case 'Toluca':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'mapVisitUs',
          eventAction: 'toluca',
          eventLabel: 'GA_map_goToMaps'
        }

        window.dataLayer.push(eventGtm);
        break;
    }
  }



}
