
  <footer class="container-fluid" *ngIf="!showNewFooter">
    <div class="row footer_top footer py-3" >
        <div class="footer_top-wrapper col-12">
            <!-- ROW WEB-->
            <div class="container">
                <div class="row justify-content-between d-none d-lg-flex">
                    <div class="col-2">
                        <div class="card-body pt-0">
                            <img class="img-keralty img-keralty-header mt-2 mt-sm-0" src="assets/AK_blanco_.png" alt="">
                           
                            <div class="mt-3">
                              <br> <button  class="btn btn-outline-light " (click)="goToIntranet()" style="width: 140px; height: 32px;">Intranet</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-2 center-footer m-0 p-1">
                        <div class="card-body pt-0">
                            <hr class="line-2 mx-3 mt-3 mb-0 footer__hr">
                            <label class="card-title footer__title-columns">Ayuda</label><br>

                            <hr class="horizontal-line m-0"/>

                            <a href="https://cloud.email-axakeralty.mx/ofertaservicios" target="_blank" rel="noopener noreferrer">
                              <span class="footer__title-content link-footer">Servicios disponibles</span>
                            </a><br>

                            <a [routerLink]="['/faqs']" (click)="sendTags('faqs')" rel="noopener noreferrer">
                                <span class="footer__title-content link-footer">Preguntas frecuentes</span>
                            </a><br>

                            <a [routerLink]="['/contact-us']" (click)="sendTags('contacto')" rel="noopener noreferrer">
                                <span class="footer__title-content link-footer">Contáctanos</span>
                            </a><br>

                            <a rel="noopener noreferrer" href="https://axa.mx/es/servicios/ayuda-al-cliente" target="_blank" rel="noopener noreferrer" (click)="sendTags('une')">
                                <span class="footer__title-content link-footer">UNE / CONDUSEF</span>
                            </a><br />

                            <a [routerLink]="['/bolsa-trabajo']" (click)="sendTags('trabajo')" rel="noopener noreferrer">
                              <span class="footer__title-content link-footer">Bolsa de trabajo</span>
                            </a>
                        </div>
                    </div>

                    <div class="col-2 m-0 p-1">
                        <div class="card-body pt-0">
                            <hr class="line-2 mx-3 mt-3 mb-0 footer__hr">
                            <label class="card-title footer__title-columns">Sitios de interés</label><br>
                            <hr class="horizontal-line m-0"/>
                            <a rel="noopener noreferrer" href="https://axa.mx/personas/inicio" (click)="sendTags('axa')"  target="_blank" rel="noopener noreferrer">
                                <span class="footer__title-content link-footer">AXA</span>
                            </a><br>
                            <a rel="noopener noreferrer" href="https://www.keralty.com/es/inicio"  (click)="sendTags('keralty')" target="_blank" rel="noopener noreferrer">
                                <span class="footer__title-content link-footer">Keralty</span>
                            </a>
                        </div>
                    </div>

                    

                  <div class="col-3 m-0 p-1">
                      <div class="card-body pt-0">
                          <hr class="line-2 mx-3 mt-3 mb-0 footer__hr">
                          <label class="card-title footer__title-columns">Legales</label><br>
                          <hr class="horizontal-line m-0"/>
                          <hr class="line-2 mx-3 mt-0 mb-0 footer__hr">
                    
                          <a
                            class="footer__link link-footer"
                            href="https://axa.mx/condiciones-generales"
                            rel="noopener noreferrer"
                            target="_blank"
                            id="link_footer_conditionsGral"
                            >Condiciones generales</a>
                          <br />
                          <a rel="noopener noreferrer" href="https://axa.mx/aviso-de-privacidad" (click)="sendTags('privacidadAxa')"   target="_blank" rel="noopener noreferrer">
                              <span class="footer__title-content link-footer">Aviso de privacidad AXA Salud</span>
                          </a><br>
                          <a rel="noopener noreferrer" href="#" [routerLink]="['/aviso-keralty']" (click)="sendTags('privacidadKeralty')" rel="noopener noreferrer">
                              <span class="footer__title-content link-footer">Aviso de privacidad Keralty México</span>
                          </a><br>
                          <a rel="noopener noreferrer" href="#" [routerLink]="['/politicas-de-uso']"  (click)="sendTags('politicaUso')" rel="noopener noreferrer">
                              <span class="footer__title-content link-footer">Políticas de uso</span>
                          </a><br>
                          <a rel="noopener noreferrer" href="#" [routerLink]="['/cancellation-policy']" (click)="sendTags('politicaCancela')"  routerLinkActive="router-link-active" rel="noopener noreferrer">
                              <span class="footer__title-content link-footer">Política de cancelación</span>
                          </a>
                          <hr class="line-2 mx-3 mt-0 mb-0 footer__hr">
                      </div>
                  </div>


                  <div class="col-2 m-0 p-1">
                    <div class="card-body pt-0 follow-us">
                        <hr class="line-2 mx-3 mt-3 mb-0 footer__hr">
                        <label class="card-title footer__title-columns">Síguenos</label><br>
                        <hr class="horizontal-line m-0"/>
                        <a class="" rel="noopener noreferrer" href="https://www.facebook.com/axakeralty/" rel="noopener noreferrer"
                        target="_blank" style="">
                          <span class="mr-2 mt-3 pl-1" style="    position: relative;
                          background-color: #042145;
                          width: 30px !important;
                          height: 30px !important;
                          display: inline-block;
                          top: -5px;
                          border-radius: 5PX;">
                            <span class="ak-icon icon-facebook" style="font-size: 21px;
                            line-height: 0.5;
                            position: relative;
                            top: 7px;"></span>
                          </span>
                            
                        </a> 
                        <a class="social-icon" rel="noopener noreferrer" href="https://www.instagram.com/axa.keralty/" (click)="sendTags('insta')" target="_blank" rel="noopener noreferrer" style="line-height: 0;margin-top: 6px;">
                            <!--<img src="assets/icons/footer/instagram.png" class="footer__icon-social-instagram-02 instagram" alt="AXA icon social instagram">-->
                            <span class="ak-icon icon-instagram" style="font-size: 21px;line-height: 0;"></span>
                        </a> 
                        <a class="social-icon" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC9mLJLdrpG1jQ0ng0X_LMjQ" (click)="sendTags('youtube')" target="_blank" rel="noopener noreferrer" style="line-height: 0;margin-top: 5px;">
                          <span class="ak-icon icon-youtube" style="font-size: 16px;color: white;line-height: 0;"></span>
                        </a> 

                        <a class="social-icon" rel="noopener noreferrer" href="https://www.linkedin.com/company/axa-keralty" (click)="sendTags('linkedin')" target="_blank" rel="noopener noreferrer" style="line-height: 0;margin-top: 5px;">
                          <span class="ak-icon icon-LinkedIn" style="font-size: 20px;color: white;line-height: 0;"></span>
                        </a>

                        <hr class="horizontal-line m-0"/>
                        <a href="https://play.google.com/store/apps/details?id=com.axakeralty.cuidat.dev" target="_blank" class="d-block">
                          <img src="assets/img/google-play-badge.png" style="
                              width: 101.52px;
                              margin-bottom: 0;
                            " />
                        </a>
                        <a href="https://apps.apple.com/mx/app/axa-keralty/id1607860346" target="_blank" class="d-block">
                          <img src="assets/img/Download-app-store.png" style="
                              width: 89.52px;
                              margin-left: 7px;
                            " />
                        </a>


                    </div>
                  </div>


                </div>
            </div>
            <!-- ROW Mobil-->




    <section class="footer__mobile d-lg-none">
      <ul class="footer__mobile-list">

        <div class="col-12 text-center mb-4">
          <img class="img-keralty img-keralty-header mt-2 mt-sm-0" src="assets/AK_blanco_.png" alt="" 
            style="width: 159.82px !important">
            <hr class="horizontal-line-division mt-5"/>
        </div>

        <li class="footer__mobile-btn mb-3 pb-3" (click)="toggleSection(2)">
          Ayuda <span class="keralty-icon keralty-validar" [ngClass]="{ rotated: secondSectionActive }" style="
            float: right;
            position: relative;
            top: 7px;"></span>
        </li>
        <div class="footer__mobile-collapse" [ngClass]="{ open: secondSectionActive }">
          <ul class="list-unstyled">
            
            <li class="footer__link mt-3" id="link_footer_faqs">
              <a 
                class="footer__link"
                href="https://cloud.email-axakeralty.mx/ofertaservicios" 
                target="_blank" 
                rel="noopener noreferrer">
                Servicios disponibles
              </a>
            </li>
            <li class="footer__link mt-3" [routerLink]="['/faq']" id="link_footer_faqs">Preguntas frecuentes</li>
            <li class="footer__link mt-3" [routerLink]="['/contact-us']" (click)="sendTags('contacto')" id="link_footer_contact">Contáctanos</li>
            <li class="footer__link mt-3">
              <a
                class="footer__link"
                rel="noopener noreferrer"
                href="https://axa.mx/es/servicios/ayuda-al-cliente"
                target="_blank"
                (click)="sendTags('une')" 
                >UNE / CONDUSEF</a
              >
            </li>

            <li class="footer__link mt-3" id="link_footer_faqs">
              <a 
                class="footer__link"
                [routerLink]="['/bolsa-trabajo']"
                (click)="sendTags('trabajo')"
                rel="noopener noreferrer">
                Bolsa de trabajo
              </a>
            </li>

          </ul>
        </div>
        <li class="footer__mobile-btn mb-3 pb-3" (click)="toggleSection(3)">
           Sitios de interés <span class="keralty-icon keralty-validar" [ngClass]="{ rotated: thirdSectionActive }"
           style="
            float: right;
            position: relative;
            top: 7px;" ></span>
        </li>
        <div class="footer__mobile-collapse" [ngClass]="{ open: thirdSectionActive }">
          <ul class="list-unstyled">
            <li class="footer__link mt-3">
              <a
                class="footer__link"
                rel="noopener noreferrer"
                href="https://axa.mx/"
                target="_blank"
                (click)="sendTags('axa')" >
                AXA</a
              >
            </li>
            <li class="footer__link mt-3 ">
              <a
                class="footer__link"
                rel="noopener noreferrer"
                href="https://www.keralty.com/es/inicio"
                target="_blank"
                (click)="sendTags('keralty')"
                >Keralty</a
              >
            </li>
          </ul>
        </div>
        <li class="footer__mobile-btn pb-3" (click)="toggleSection(5)">
           Legales
           <span class="keralty-icon keralty-validar" [ngClass]="{ rotated: fifthSectionActive }"
           style="
            float: right;
            position: relative;
            top: 7px;" ></span>
        </li>
        <div class="footer__mobile-collapse" [ngClass]="{ open: fifthSectionActive }">
          <ul class="list-unstyled">

            <li class="footer__link mt-3">
              <a
                class="footer__link"
                href="https://axa.mx/condiciones-generales"
                rel="noopener noreferrer"
                target="_blank"
                id="link_footer_privacy"
                >Condiciones generales</a
              >
            </li>

            <li class="footer__link mt-3">
              <a
                class="footer__link"
                href="https://axa.mx/aviso-de-privacidad"
                rel="noopener noreferrer"
                target="_blank"
                id="link_footer_privacy"
                >Aviso de privacidad AXA Salud</a
              >
            </li>
            <li class="footer__link mt-3" (click)="sendTags('privacidadAxa')" >
             <a  href="#" [routerLink]="['/aviso-keralty']" (click)="sendTags('privacidadKeralty')" target="_blank" style="text-decoration: none; color: rgba(255, 255, 255, 0.7);" > Aviso de privacidad Keralty México</a>
            </li>
            <li class="footer__link mt-3" [routerLink]="['/politicas-de-uso']" (click)="sendTags('politicaUso')">
              Políticas de uso
            </li>
            <li class="footer__link mt-3"  (click)="sendTags('politicaCancela')">
              <a href="https://axakeralty.mx/cancellation-policy" target="_blank" style="text-decoration: none;color: rgba(255, 255, 255, 0.7);">Política de cancelación</a>
            </li>
          </ul>
        </div>
      </ul>
    </section>
    
        </div>
         <div class="col-12 text-center d-lg-none my-2 mt-0">

          <div class="mt-0" style="padding: 0px 32px;">
            <button  class="btn btn-outline-light " (click)="goToIntranet()"
            style="
            height: 60px;
            width: 100%;
            max-width: 400px;">Intranet</button>
         </div>
         <hr class="horizontal-line-division mt-4 mb-4"/>

          <a class="" rel="noopener noreferrer" href="https://www.facebook.com/axakeralty/" rel="noopener noreferrer"
          target="_blank" style="">
            <span class="mr-4" style="    position: relative;
            background-color: #042145;
            width: 30px !important;
            height: 30px !important;
            display: inline-block;
            top: -5px;
            border-radius: 5PX;">
              <span class="ak-icon icon-facebook" style="font-size: 21px;
              line-height: 0.5;
              position: relative;
              top: 7px;"></span>
            </span>
              
          </a> 


          <a rel="noopener noreferrer" href="https://www.instagram.com/axa.keralty/"
            target="_blank" rel="noopener noreferrer">
            <span class="ak-icon icon-instagram" style="font-size: 17px; line-height: 0;"></span>
          </a>


          <a class="ml-4" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC9mLJLdrpG1jQ0ng0X_LMjQ"
            target="_blank" rel="noopener noreferrer">
              <span class="ak-icon icon-youtube" style="font-size: 16px;color: white;line-height: 0;"></span>
          </a>


          <a class="ml-4" rel="noopener noreferrer" href="https://www.linkedin.com/company/axa-keralty"
            target="_blank" rel="noopener noreferrer">
              <span class="ak-icon icon-LinkedIn" style="font-size: 20px;color: white;line-height: 0;"></span>
          </a>

          <hr class="horizontal-line-division mt-4 mb-4"/>



          <a href="https://play.google.com/store/apps/details?id=com.axakeralty.cuidat.dev" target="_blank" class="d-inline-block">
            <img src="assets/img/google-play-badge.png" style="
                width: 150px;
                margin-bottom: 0;
              " />
          </a>
          <a href="https://apps.apple.com/mx/app/axa-keralty/id1607860346" target="_blank" class="d-inline-block">
            <img src="assets/img/Download-app-store.png" style="
                width: 121.52px;
                margin-left: 7px;
              " />
          </a>

      </div>
    </div>
    <div class="footer_desktop_font footer_bottom row"  >
  <div class="container">
    <div class="row m-auto pb-1 px-0 d-none d-sm-flex">
      <div class="col-4">
      </div>
      <div class="col-12 row text-center text-footer d-flex text-light justify-content-center align-items-center">
        
        <div class="d-flex justify-content-center">
          <p><span class="ak-icon icon-ambulance-footer ambulance-desktop"></span></p>
        </div>

        <div class="d-flex justify-content-center w-100">
          <p><span class="footer__copy text-secundary">AXA Keralty {{year}} © </span></p>
        </div>

        <div class="d-flex justify-content-center">
          <p class="footer__copy text-axaker mt-1">AXA Keralty se refiere a la alianza de AXA Salud, S.A. de C.V., como encargada del aseguramiento y Keralty México, S.A. de C.V., 
        como responsable de los servicios médicos en conjunto con la red de proveedores.</p>
        </div>
      </div>
      <div class="col-4 text-right mt-xs-4 mt-sm-4 mt-md-0">
        
      </div>
  </div>
  <div class="row m-auto pb-1 px-0  d-sm-none">
    <div class="col-12 text-center mt-xs-4 mt-sm-4 mt-md-0 align-items-center">
      <span class="footer__copy text-secundary mr-2" style="font-size: 10px !important;">AXA Keralty {{year}} © </span>
      <span class="ak-icon icon-ambulance-footer"></span>
    </div>
    <div class="d-flex justify-content-center">
      <p class="footer__copy text-center text-axaker m-0">AXA Keralty se refiere a la alianza de AXA Salud, S.A. de C.V., como encargada del aseguramiento y Keralty México, S.A. de C.V., 
      como responsable de los servicios médicos en conjunto con la red de proveedores.</p>
      </div>      
</div>
  </div>
</div>
</footer>
<!--
<div class="div" *ngIf="showNewFooter">
  <div class="footer_desktop_font footer_bottom">
    <div class="container">
      <div class="row m-auto pb-1 px-0">
        <div class="col-4">
          <span class="ff-planmed Planmed cl-white"></span>
          <span class="vertical-line mx-2"></span>
          <img class="img-keralty mt-2 mt-sm-0" src="assets/AK_blanco_.png" alt="">
        </div>
        <div class="col-4 text-center text-footer d-flex text-light justify-content-center align-items-center">
          <a rel="noopener noreferrer" href="https://axakeralty.mx/#/politica-de-cancelacion" target="_blank">  <span class="text-light">Política de cancelación</span></a>
        </div>
        <div class="col-4 text-right">
          <div>


            <a rel="noopener noreferrer" href="https://www.facebook.com/axakeralty/"
            target="_blank"><img class="img-social" src="assets/facebook_.png" alt=""></a> 

            <a rel="noopener noreferrer" href="https://www.instagram.com/axa.keralty/"
          target="_blank">
          <img class="img-social ml-2" src="assets/instagram_.png" alt="">
        </a>

          </div>
        </div>
    </div>
    </div>
  </div>
  <div class="footer_desktop_font footer-bottom back-new">
    <div class="container">
      <div class="row m-auto back-new px-0">
        <div class="col-4 text-footer-end text-light d-flex align-items-center">
          <a rel="noopener noreferrer" href="https://axakeralty.mx/#/politicas-de-uso" target="_blank"><span class="text-secondary">Condiciones generales</span></a>
        </div>
        <div class="col-4 d-flex justify-content-center align-items-center">
          <span class="ak-icon icon-ambulance-footer"></span>
        </div>
        <div class="col-4 text-footer-end text-right d-flex align-items-center">
          <span class="footer__copy ml-auto text-secondary">© AXA Keralty {{year}} </span>
        </div>
      </div>
    </div>
  </div>
</div>
-->
<div id="map-redirection">

</div>