/**
 * Arreglo de objetos con datos de banner
 */
export const BANNERS = [
  {
    title: '',
    description: ``,
    image: {
      desktop: 'assets/banner/desktop/whatsapp.png',
      mobile: 'assets/banner/mobile/whatsapp.png'
    }
  },
  {
    title: '',
    description: ``,
    image: {
      desktop: 'assets/banner/desktop/Banner-HP_Desktop_Video.png',
      mobile: 'assets/banner/mobile/Banner-HP_Mobile_Video.png'
    },
    watchVideo: {
      url: 'https://youtu.be/7CVo3fS8h2Q'
    }
  },
  {
    title: '',
    description: ``,
    image: {
      desktop: 'assets/banner/desktop/Banner-HP_Desktop_Conoce-mas.png',
      mobile: 'assets/banner/mobile/Banner-HP_Mobile_Conoce-mas.png'
    },
    watchDocumento: {
      url: 'https://image.email-axakeralty.mx/lib/fe3a117171640478711472/m/2/1fe23e48-c33b-455d-ac80-5717ee6e2f8b.pdf'
    }
  },
 
];
