import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { MatExpansionModule } from '@angular/material/expansion';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgModule } from '@angular/core';

import { NgxMaskModule } from 'ngx-mask';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PlanmedPolicyComponent } from './components/planmed-policy/planmed-policy.component';
import { PaymentConfirmationComponent } from './components/planmed-policy/payment-confirmation/payment-confirmation.component';
import { ApprovedPaymentComponent } from './components/planmed-policy/approved-payment/approved-payment.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { FrequentQuestionsComponent } from './components/frequent-questions/frequent-questions.component';
import { GeneralPoliciesComponent } from './components/general-policies/general-policies.component';
import { PrivacyNoticeComponent } from './components/privacy-notice/privacy-notice.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { LandingComponent } from './components/landing/landing.component';
import { BasicInfoComponent } from './components/planmed-policy/basic-info/basic-info.component';
import { BannerComponent } from './components/landing/banner/banner.component';
import { BenefitsComponent } from './components/landing/benefits/benefits.component';
import { HowItWorksComponent } from './components/landing/how-it-works/how-it-works.component';
import { IntroductionComponent } from './components/landing/introduction/introduction.component';
import { VisitUsComponent } from './components/landing/visit-us/visit-us.component';
import { UsersOpinionComponent } from './components/landing/users-opinion/users-opinion.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FloatButtonComponent } from './shared/float-button/float-button.component'
import { CancellationPolicyComponent } from './components/cancellation-policy/cancellation-policy.component';
import { AxaCOVIDComponent } from './components/axa-covid/axa-covid.component';
import { CovidMapComponent } from './components/covid-map/covid-map.component';

import { LoaderComponent } from './shared/loader/loader.component';
import { AnimationComponent } from './components/landing/animation/animation.component';
import { FloatButtonActionsComponent } from './shared/float-button-actions/float-button-actions.component';
import { FloatCovidComponent } from './shared/float-covid/float-covid.component';
import { FloatUpComponent } from './shared/float-up/float-up.component';
import { ModalDiscountComponent } from "./shared/modal-discount/modal-discount.component";
import { JobExchangeComponent } from './components/job-exchange/job-exchange.component';
import { ChatDialogComponent } from './shared/chat-dialog/chat-dialog.component';
import { BannerAlertComponent } from './shared/banner-alert/banner-alert.component';
import { CheckoutPaymentComponent } from './components/services/checkout-payment-services/checkout-payment.component';
import { DataPaymentComponent } from './components/services/data-payment-services/data-payment.component';
import { SuccessPaymentComponent } from './components/services/success-payment-services/success-payment.component';
import { LandingPaymentComponent } from './components/services/landing-payment-services/landing-payment.component';
import { ConsultConditionsComponent } from './components/services/consult-conditions/consult-conditions.component';
import { ConsultInfographicComponent } from './components/services/consult-infographic/consult-infographic.component';
import { ModalGeneralV2Component } from './shared/modal-general-v2/modal-general-v2.component';
import { TokenComponent } from './components/token/token.component';
import { PayPolicyComponent } from './components/pay-policy/pay-policy.component';
import { SearchPolicyComponent } from './components/pay-policy/search-policy/search-policy.component';
import { NoBalanceComponent } from './components/pay-policy/no-balance/no-balance.component';
import { CheckoutComponent } from './components/pay-policy/checkout/checkout.component';
import { PaymentSuccessComponent } from './components/pay-policy/payment-success/payment-success.component';

import { LayoutComponent } from './core/layout/layout.component';
import { MainLayoutComponent } from './corev2/layout/main-layout/main-layout.component';
import { HeaderComponent as HeaderComponentv2 } from './corev2/layout/header/header.component';
import { FooterComponent as FooterComponetv2 } from './corev2/layout/footer/footer.component';
import { LoaderComponent as LoaderComponenv2 } from './sharedv2/loader/loader.component';
import { NavigationComponent } from './corev2/layout/navigation/navigation.component';
import { InfographicPlanmedComponent } from './components/infographic-planmed/infographic-planmed.component';
import { DocumentTypePipe } from './pipes/document-type/document-type.pipe';
import { httpEnpointProvider } from './interceptors/http-endpoint/http-endpoint.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    PlanmedPolicyComponent,
    PaymentConfirmationComponent,
    ApprovedPaymentComponent,
    AboutUsComponent,
    FrequentQuestionsComponent,
    DataPaymentComponent,
    GeneralPoliciesComponent,
    PrivacyNoticeComponent,
    ContactUsComponent,
    SignInComponent,
    LandingComponent,
    BasicInfoComponent,
    BannerComponent,
    SuccessPaymentComponent,
    BannerAlertComponent,
    BenefitsComponent,
    HowItWorksComponent,
    IntroductionComponent,
    VisitUsComponent,
    UsersOpinionComponent,
    LandingPaymentComponent,
    HeaderComponent,
    FooterComponent,
    ConsultConditionsComponent,
    ConsultInfographicComponent,
    LoaderComponent,
    CheckoutPaymentComponent,
    FloatButtonComponent,
    LoaderComponent,
    CancellationPolicyComponent,
    AxaCOVIDComponent,
    CovidMapComponent,
    AnimationComponent,
    FloatButtonActionsComponent,
    FloatCovidComponent,
    FloatUpComponent,
    ModalDiscountComponent,
    JobExchangeComponent,
    ChatDialogComponent,
    ModalGeneralV2Component,
    TokenComponent,
    PayPolicyComponent,
    SearchPolicyComponent,
    NoBalanceComponent,
    CheckoutComponent,
    PaymentSuccessComponent,
    LayoutComponent,
    MainLayoutComponent,
    HeaderComponentv2,
    FooterComponetv2,
    LoaderComponenv2,
    NavigationComponent,
    InfographicPlanmedComponent,
    DocumentTypePipe,
  ],
  exports: [VisitUsComponent, BannerAlertComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'axa-keralty2' }),
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    NgbModalModule,
    NgxPageScrollCoreModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgbTooltipModule,
    CollapseModule.forRoot(),
    MatExpansionModule,
    NgxPageScrollModule,
    BrowserAnimationsModule,
    NgxCaptchaModule,
    GoogleMapsModule,
    HttpClientModule,
    // InterceptorsModule, //Para desarrollo en local deshabilitar este modulo
    AngularSvgIconModule.forRoot(),
    ReactiveFormsModule,
    SlickCarouselModule,
  ],
  providers: [CookieService, httpEnpointProvider],
  bootstrap: [AppComponent]
})
export class AppModule { }
