import { IEventGTM } from './../../interfaces/gtmEvent.interface';
/** import angular/core */
import {Component, HostListener, OnInit} from '@angular/core';
/**
 * Component FloatUpComponent
 */
@Component({
  selector: 'app-float-up',
  templateUrl: './float-up.component.html',
  styleUrls: ['./float-up.component.scss']
})
export class FloatUpComponent implements OnInit {
  /** Variable que almacena el valor de si el botón es visible o no */
  onTop: boolean;

  /**
   * Constructor
   * Condicionar el moemento 
   * en que se logra activar el 
   * scroll
   */
  constructor() { }
  /** Evento de lectura de pantalla */
  @HostListener('window:scroll', ['$event.target'])
  triggeredScroll() {
    /** lee la posicion de la pantalla y permite cambiar la variable de visibilidad  */
    const scrollY = window.scrollY;
    if (scrollY >= 200) {
      this.onTop = true
    } else {
      this.onTop = false
    }
  }
  /** ngOnInit */
  ngOnInit(): void {
  }
  /**
   * scrollToTop
   * getScroll, hacer que el scroll de la pantalla sea igual a 0
   */
  scrollToTop() {
    /** Envío de evento a dataLayer para taggeo */
    const eventGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'homeButtons',
      eventAction: 'scrollTop',
      eventLabel: 'btn_scrollTop'
    }

    window.dataLayer.push(eventGtm);

    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }
}
