import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
/***SERVICES */
import { ErrorService } from '../../services/error-service/error.service';
/***CONSTANT */
import {Response} from '../../interfaces';
/** Injectable*/
@Injectable({
    /**providedIn */
  providedIn: 'root'
})
/**
 * Servicio para peticiones HTTP
 */
export class HttpService {
  /**
   * Creates an instance of HttpService
   * @param {HttpClient} httpClient
   * @param {ErrorService} errorService
   * @param {TokenService} tokenService
   */
  constructor(private httpClient: HttpClient,
              private errorService: ErrorService,
              ) { }


   /**
	 * Realizar llamada `GET`
	 * @param {string} url
	 * @returns {any}
	 */
get(url: string, options = {}, requireToken = false): Observable<any> {
  if (url) {
    if (requireToken) {

   }
   return this.httpClient.get(url, options);
  }
  }

  /**
 *Realiza petición post
 *
 * @param {string} service
 * @param {*} [obj]
 * @param {boolean} [requireToken=false]
 * @returns {Observable<any>}
 * @memberof HttpService
 */
post(
  service: string,
  obj?: any, requireToken = false, interceptor = true): Observable<any> {
    if (service) {
      if (requireToken) {
     }
     if (!interceptor) {
     }
     return   this.httpClient.post<Response>(service, obj).pipe(map((responseOK) => {
       return  this.success(responseOK);
     }));
    }
}
/**
 *Validación pra requst de petición de servicio
 *
 * @private
 * @param {Response} responseOK
 * @returns
 * @memberof HttpService
 */
private success(
  responseOK: Response
) {
  /** Si respuesta OK, regresa sólo data */
  if (responseOK ) {
    return responseOK;
  } else {
    return this.errorService.errorMessage(responseOK.data.message);
  }
}
}
