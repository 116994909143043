import { Router } from '@angular/router';
/** import Angular Component, OnInit */
import { Component, OnInit } from '@angular/core';
/** import urls.constants*/
import { urlsLoc } from 'src/app/constants/urls.constants';
import { IEventGTM } from 'src/app/interfaces/gtmEvent.interface';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Componente acerca de nosotros
 */
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  /** Url del servidor actual */
  urlServer: string = '';

  clientID: string;
  /** constructor*/
  constructor(private deviceService: DeviceDetectorService, private router: Router) {
    this.urlServer = 'https://preprod.axakeralty.mx';
  }

  /** ngOnInit*/
  ngOnInit() {
    this.replaceScript();
  }
  /**
   * addToCartEvent
   * Evento para agregar al carrito
   */
  addToCartEvent() {
    /** Evento enviado al dataLayer para taggeo */
    const gtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'homeButtons',
      eventAction: 'homePage_GoCotizamed',
      eventLabel: 'btn_homePage_goToCotizamed'
    }
    window.dataLayer.push(gtm);

    location.href = this.urlServer + urlsLoc.cotizador;
  }

  sendTag() {
    const eventGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'homeButtons',
      eventAction: 'homePage_GoPlanmed',
      eventLabel: 'btn_banner_plans'
    }

    window.dataLayer.push(eventGtm);
  }

  replaceScript() {
    try{
      if(document.cookie.indexOf('_ga=')>-1){
          const gaCookie = document.cookie.split('_ga=')[1].split(';')[0].split('.');
          this.clientID = gaCookie[2] + '.' + gaCookie[3];
      }
    } catch(e){
      return 'Error al obtener el id de Google Analytics';
    }

    if (this.router.url === '/about-us'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        login: 'no',
        clientId: `${this.clientID}`,
        pageType: 'home',
        pageCategory: `${this.router.url}`,
        pageURLCategory: `${window.location.href}`,
        device: `${this.getDevice()}`,
        numeroCotizacion: 'NA',
        folioPago: 'NA',
        numeroSolicitud: 'NA',
        numeroPoliza: 'NA'
      });
    }
  }

  getDevice() {
    if(this.deviceService.isDesktop()) {
       return 'desktop';
    } else if(this.deviceService.isMobile()) {
      return 'mobile';
    } else if(this.deviceService.isTablet()) {
      return 'tablet';
    } else {
      return 'other';
    }
  }
}
