/** Arreglo con estados de la República Méxicana */
export const STATES = [
  {id: 'AS', name: 'AGUASCALIENTES'},
  {id: 'BC', name: 'BAJA CALIFORNIA'},
  {id: 'BS', name: 'BAJA CALIFORNIA SUR'},
  {id: 'CC', name: 'CAMPECHE'},
  {id: 'CL', name: 'COAHUILA DE ZARAGOZA'},
  {id: 'CM', name: 'COLIMA'},
  {id: 'CS', name: 'CHIAPAS'},
  {id: 'CH', name: 'CHIHUAHUA'},
  {id: 'DF', name: 'CIUDAD DE MÉXICO'},
  {id: 'DG', name: 'DURANGO'},
  {id: 'GT', name: 'GUANAJUATO'},
  {id: 'GR', name: 'GUERRERO'},
  {id: 'HG', name: 'HIDALGO'},
  {id: 'JC', name: 'JALISCO'},
  {id: 'MC', name: 'ESTADO DE MÉXICO'},
  {id: 'MN', name: 'MICHOACÁN DE OCAMPO'},
  {id: 'MS', name: 'MORELOS'},
  {id: 'NT', name: 'NAYARIT'},
  {id: 'NL', name: 'NUEVO LEÓN'},
  {id: 'OC', name: 'OAXACA'},
  {id: 'PL', name: 'PUEBLA'},
  {id: 'QT', name: 'QUERÉTARO'},
  {id: 'QR', name: 'QUINTANA ROO'},
  {id: 'SP', name: 'SAN LUIS POTOSÍ'},
  {id: 'SL', name: 'SINALOA'},
  {id: 'SR', name: 'SONORA'},
  {id: 'TC', name: 'TABASCO'},
  {id: 'TS', name: 'TAMAULIPAS'},
  {id: 'TL', name: 'TLAXCALA'},
  {id: 'VZ', name: 'VERACRUZ'},
  {id: 'YN', name: 'YUCATAN'},
  {id: 'ZS', name: 'ZACATECAS'},
  {id: 'NE', name: 'NACIDO EN EL EXTRANJERO'}
];
