/** import angular core*/
import { Component, OnInit, OnDestroy } from '@angular/core';
/** import ngx-bootstrap/modal*/
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
/** import constants*/
// import { urlsLoc } from 'src/app/constants/urls.constants';
/** import angular/forms*/
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
/** import modal-discount*/
import { ModalDiscountComponent } from '../modal-discount/modal-discount.component';
/** import experience.service*/
import { ExperienceService } from '../../services/experience-service/experience.service';
/** declara interface global */
declare global {
  /** interface de ventana viewport*/
  interface Window {
    /** capa de datos*/
    dataLayer: any;
  }
}
import { IEventGTM, InterImpressions } from 'src/app/interfaces/gtmEvent.interface';
import { CookieService } from 'ngx-cookie-service';
/**
 * Component FloatButtonComponent
 */
@Component({
  selector: 'app-float-button',
  templateUrl: './float-button.component.html',
  styleUrls: ['./float-button.component.scss']
})
export class FloatButtonComponent implements OnInit, OnDestroy {

  /** Variable que almacena el valor de si el botón es visible o no */
  isVisible : boolean;
  /**
   * Variable condicionar el cuadro de dialogo de sugerencias
   */
  opennedDialog: boolean;
  /**
   * Variable condicionar el cuadro de dialogo de sugerencias
   */
  opennedSuccessDialog: boolean;
  /** Declaración de form de Suggestions  */
  formSuggestions: UntypedFormGroup;
  /** Variable bsModalRef  */
  bsModalRef: BsModalRef;

  selectedR: string;

  impressionsObj: InterImpressions;

  /**
   * Constructor
   * @param modalService //
   * @param fb //FormBuilder
   * @param experienceService //ExperienceService
   */
  constructor(
    private modalService: BsModalService,
    private fb: UntypedFormBuilder,
    public experienceService: ExperienceService,
    private coockieServ: CookieService
  ) { }

  /**
   * Código que se ejecuta al crearse el componente
   */
  ngOnInit() {
    this.initForm();
    this.impressions();
  }
  /**
   * initForm
   */
  initForm() {
    this.formSuggestions = this.fb.group({
      qualification: ['', Validators.required],
      experience: ['', Validators.required]
    });
  }
  /**
   * Código que se ejecuta al destruirse el componente
   */
  ngOnDestroy(){
  }
  /**
   * openDialog
   */
  openDialog(){
    /** Envío de evento al dataLayer para taggeo */
    const eventgtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'floatButtons',
      eventAction: 'Sugerencias'
    }

    window.dataLayer.push(eventgtm);

    this.experienceService.opennedDialog.next(true);
  }
  /**
   * closeDialog
   */
  closeDialog(){
    /** Envío de evento al dataLayer para taggeo */
    const Egtm: IEventGTM = {
      event: 'gtmevent',
      eventCategory: 'floatButtons',
      eventAction: 'close',
      eventLabel: 'suggestion_close'
    }

    window.dataLayer.push(Egtm);
    this.experienceService.opennedDialog.next(false);
  }
  /**
   * sendSuggest
   * @param expData //formSuggestions.value
   */
  sendSuggest(expData) {
    /** Envío de evento al dataLayer para taggeo */
    const gtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'floatButtons',
      eventAction: 'sendExperience',
      eventLabel: 'suggestion_experience'
    }

    window.dataLayer.push(gtm);

    this.experienceService.qualifyExperience(expData).subscribe(res=>{
      if (res) {
        this.experienceService.opennedDialog.next(false);
        this.opennedSuccessDialog= true;
        this.formSuggestions.reset();
      }
    });
  }
  /** closeSuccessDialog*/
  closeSuccessDialog() {
    this.opennedSuccessDialog= false;
    this.experienceService.opennedDialog.next(false);
  }
  /** openSuccessDialog*/
 openSuccessDialog() {
    this.opennedSuccessDialog= false;
   this.experienceService.opennedDialog.next(false);
  }
  /** openModalDiscount*/
  openModalDiscount() {
    /** Envío de evento al dataLayer para taggeo */
    const event: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'floatButtons',
      eventAction: '10descuento',
      eventLabel: 'btn_homePage_goToCotizamed',
    }

    window.dataLayer.push(event);

    this.clickImpressions(this.impressionsObj);

    this.bsModalRef = this.modalService.show(ModalDiscountComponent, {class: 'modal-lg discount-modal modal-dialog-centered'});
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  /** addToCartEvent*/
  addToCartEvent() {
    /** Envío de evento al dataLayer para taggeo */
    const evgtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'floatButtons',
      eventAction: 'homePage_GoCotizamed',
      eventLabel: 'btnTop_homePage_goToCotizamed',
    }

    window.dataLayer.push(evgtm);
      this.coockieServ.delete('BROKER_NAME');
      this.coockieServ.delete('BROKER_KEY');
      this.coockieServ.delete('BROKER_PROMOTER_ID');
      this.coockieServ.delete('BROKER_PROMOTER_NAME');
      this.coockieServ.delete('BROKER_AGENT_ID');
      this.coockieServ.delete('BROKER_AGENT_EMAIL');
    window.location.replace('/cotizador-planmed/');
  }

  /**
   * Método para enviar evento al dataLayer
   * de acuerdo con rate de esperiencia.
   *
   * @param {*} event
   * @memberof FloatButtonComponent
   */
  sendGTM(event: any) {
    this.selectedR = event.target.value;
    let eventGtm: IEventGTM;
    switch (this.selectedR) {
      case '1':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'floatButtons',
          eventAction: 'really good',
          eventLabel: 'suggestion_face'
        }

        window.dataLayer.push(eventGtm);
        break;
      case '2':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'floatButtons',
          eventAction: 'good',
          eventLabel: 'suggestion_face'
        }

        window.dataLayer.push(eventGtm);
        break;
      case '3':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'floatButtons',
          eventAction: 'average',
          eventLabel: 'suggestion_face'
        }

        window.dataLayer.push(eventGtm);
        break;
      case '4':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'floatButtons',
          eventAction: 'bad',
          eventLabel: 'suggestion_face'
        }

        window.dataLayer.push(eventGtm);
        break;
      case '5':
        eventGtm = {
          event: 'eventgtm',
          eventCategory: 'floatButtons',
          eventAction: 'really bad',
          eventLabel: 'suggestion_face'
        }

        window.dataLayer.push(eventGtm);
        break;
    }
   }

   /**
    * Método para enviar evento al dataLayer
    * en descripción de experiencia.
    *
    * @memberof FloatButtonComponent
    */
   expTag() {
    const tagObj: IEventGTM  = {
      event: 'eventgtm',
      eventCategory: 'floatButtons',
      eventAction: 'completeExperience',
      eventLabel: 'suggestion_experience'
    }

    window.dataLayer.push(tagObj);
   }

   impressions(){
     this.impressionsObj = {
        id: '10% de descuento',
        name: '10% de descuento',
        creative: 'venta en linea',
        position: 'floatButton'
     }

     window.dataLayer.push({
       event: 'promoImpressions',
       ecommerce: {
         promoView: {
           promotions: [this.impressionsObj]
          }
        }
      });
    }

    clickImpressions(promoObj) {
      window.dataLayer.push({
       event: 'promotionClick',
       ecommerce: {
         promoClick: {
           promotions: [
            {
              id: this.impressionsObj.id,
              name: this.impressionsObj.name,
              creative: this.impressionsObj.creative,
              position: this.impressionsObj.position
            }]
         }
       }
     });
   }
}

