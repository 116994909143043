import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from '../http-service/http.service';
import { ErrorsService } from '../errors.service';
/**Injectable */
@Injectable({
  /** providedIn */
  providedIn: 'root'
})
export class ContactService {

  /**
   *Creates an instance of ContactService.
   * @param {HttpService} http
   * @param {ErrorsService} errorsService
   * @memberof ContactService
   */
  constructor(private http: HttpService, private errorsService: ErrorsService) { }

  /**
   * Envía informacion de cuestionario de contacot para su guradado en la base de datos
   * @param data
   */
  saveProspectInfo(data: any) {
    return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/auth/register', data).pipe(map( response => {
        
      return response;
      },(error) => {

        this.errorsService.handleError(error);
        return false;
      }));
  }

}
