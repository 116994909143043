/** import Angular Component, OnInit*/
import { Component, OnInit } from '@angular/core';
/** import LoaderService*/
import { LoaderService } from './services/loader/loader.service';
/** import Angular Meta*/
import { Meta } from '@angular/platform-browser';
/** import Angular Router*/
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
/**
 * AppComponent
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /** Variable title*/
  title = 'axa-keralty2';
  rutaActiva= '';
  showNewFooter = false;
    /** bandera para mostrar */
  showBannerCookie= true;

  /**
   * Constructor
   * @param loaderService //LoaderService
   * @param meta //Meta
   * @param router //Router
   */
  constructor(
    private loaderService: LoaderService,
    private meta: Meta,
    public router:Router,
    public cookieService: CookieService
    ) {
      this.meta.addTags([
        { name: 'description', content: 'El nuevo sistema integral para el cuidado de tu salud donde por un costo accesible tendrás acceso a atención médica de calidad y programas de salud hechos para ti' },
        { name: 'author', content: 'AXA Keralty' }
      ]);
  }

  /**
   * ngOnInit
   * Inicializacion loader
   */
  ngOnInit() {
    if (this.cookieService.get('acceptCookie') === 'true') {
      console.log('ya se acepto la cokie');
      this.loaderService.acceptCookies.next(true);

    } else {
      console.log('no se ha acepttado cookie');
      
      this.loaderService.acceptCookies.next(false);
    }
       this.router.events.subscribe((reponse: any) => {
      this.rutaActiva = reponse.url !== undefined ? reponse.url : this.rutaActiva;
      const activeRoute = this.rutaActiva.split('/');
      // if (this.rutaActiva === '/') {
      //   window.location.reload();
      // }
      if (this.rutaActiva === '/servicios' || this.rutaActiva === '/infografia' ||  this.rutaActiva === '/servicios-pago' ||  this.rutaActiva === '/servicios-checkout' ||  this.rutaActiva === '/servicios-exito' ||  this.rutaActiva === '/consulta-terminos-y-condiciones' ) {
        this.showNewFooter = true;
      } else {
        this.showNewFooter = false;
      }
    });
    this.loaderService.star()
    setTimeout(() => {
      this.loaderService.stop();
    }, 1500);
  }

}
