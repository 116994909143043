/**
 * Constantes para catalogo de servicios
 */
export const CATALOG = {
  /**
   * Personalizar estilos del mapa
   */
  SERVICES: [
    {
      title: 'Consultas de atención primaria',
      icon: 'keralty-icon keralty-doc',
      plan: {
          desc: `Consultas de Medicina General, Pediatría, Ginecología, Nutrición
          con médicos de nuestros Centros de Atención Médica.`,
          price: {
            public: {
              title: '',
              cost: '8,000',
              quote: ''
            },
            essential: {
              title: '',
              cost: '2,550',
              quote: ''
            },
            middle: {
              title: '',
              cost: '4,200',
              quote: ''
            }
          }
      }
    },
    {
      title: 'Atención inmediata',
      icon: 'keralty-icon keralty-lugage',
      plan: {
          desc: `Nuestros Centros de Atención Médica cuentan con áreas de observación
          diseñados para tu cuidado y la solución de situaciones médicas imprevistas.`,
          price: {
            public: {
              title: '',
              cost: '8,000',
              quote: '$1,500'
            },
            essential: {
              title: '',
              cost: '2,550',
              quote: ''
            },
            middle: {
              title: '',
              cost: '4,200',
              quote: ''
            }
          }
      }
    },
    {
      title: 'Atención médica telefónica',
      icon: 'keralty-icon keralty-telephone',
      plan: {
          desc: `Ponemos a tu disposición una línea telefónica en donde médicos capacitados
          podrán resolver tus dudas más frecuentes y hacer recomendaciones para que te sientas mejor,
          sin costo alguno.`,
          price: {
            public: {
              title: '',
              cost: '8,000',
              quote: ''
            },
            essential: {
              title: '',
              cost: '2,550',
              quote: ''
            },
            middle: {
              title: '',
              cost: '4,200',
              quote: ''
            }
          }
      }
    },
    {
      title: 'Atención médica domiciliaria',
      icon: 'keralty-icon keralty-stetoscope',
      plan: {
          desc: `Si tu malestar lo requiere, nuestros médicos podrán coordinar una atención médica domiciliaria.`,
          price: {
            public: {
              title: '',
              cost: '8,000',
              quote: ''
            },
            essential: {
              title: '',
              cost: '2,550',
              quote: '$250'
            },
            middle: {
              title: '',
              cost: '4,200',
              quote: '$250'
            }
          }
      }
    },
    {
      title: 'Ambulancia',
      icon: 'keralty-icon keralty-car_1',
      plan: {
          desc: `Traslado hacia el Centro de Atención Médica, hospital más cercano o de hospital a hospital.
          Incluye dos servicios al año en Área Metropolitana de la CDMX.`,
          price: {
            public: {
              title: '',
              cost: '8,000',
              quote: ''
            },
            essential: {
              title: '',
              cost: '2,550',
              quote: '2 anuales sin costo'
            },
            middle: {
              title: '',
              cost: '4,200',
              quote: '2 anuales sin costo'
            }
          }
      }
    },
    {
      title: 'Terapias físicas y respiratorias',
      icon: 'keralty-icon keralty-therapy',
      plan: {
          desc: `Compresas, Electroestimulación, Ultrasonido, Bandas, Pelotas, Rollos, Tens,
          Puntos de estimulación con aguja, Barra sueca, Balancín. Se prescriben en bloques de 10 sesiones.`,
          price: {
            public: {
              title: 'Por sesión',
              cost: '8,000',
              quote: '$500'
            },
            essential: {
              title: '',
              cost: '2,550',
              quote: 'Precio preferencial'
            },
            middle: {
              title: '',
              cost: '4,200',
              quote: `Sin costo con <br> referencia médica`
            }
          }
      }
    },
    {
      title: 'Consultas de especialidad',
      icon: 'keralty-icon keralty-nurse',
      plan: {
          desc: `Consultas de Medicina Interna, Cirugía General, Ortopedia, Otorrinolaringología, Urología,
          Dermatología y Oftalmología con médicos de nuestros Centros de Atención Médica.`,
          price: {
            public: {
              title: '',
              cost: '8,000',
              quote: '$800'
            },
            essential: {
              title: '',
              cost: '2,550',
              quote: '$600'
            },
            middle: {
              title: '',
              cost: '4,200',
              quote: `Sin costo con <br> referencia médica`
            }
          }
      }
    },
    {
      title: 'Estudios de laboratorio',
      icon: 'keralty-icon keralty-microscope_3',
      plan: {
          desc: `Apoyando las acciones de prevención y diagnóstico oportuno, tu plan brinda cobertura en los
          estudios de laboratorio que están disponibles dentro del Centro de Atención Médica.`,
          price: {
            public: {
              title: '',
              cost: '8,000',
              quote: ''
            },
            essential: {
              title: '',
              cost: '2,550',
              quote: 'Precio preferencial'
            },
            middle: {
              title: '',
              cost: '4,200',
              quote: `Sin costo con <br> referencia médica`
            }
          }
      }
    }
  ],

  PLANS: [
    {
      title: 'Precio Público',
      alias: 'public',
      icon: false
    },
    {
      title: 'Esencial',
      alias: 'essential',
      icon: true
    },
    {
      title: 'Intermedio',
      alias: 'middle',
      icon: true
    }
  ]
};
