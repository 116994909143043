import {IContact} from './contact-form.interface';
import {EGenders} from './metabolic-age.interface';

/** Interfaz para envío de datos en FORMULARIO DE CONTACTO */
export interface IJobExchangeFormInterface {
  /** Nombre(s) */
  name: string;
  /** Apellido paterno */
  fatherSurname: string;
  /** Apellido materno */
  motherSurname: string;
  /** Sexo */
  gender: EGenders;
  /** Bandera entera indicando rol */
  role: number;
  /** Arreglo con medios de contacto registrados */
  contact: IContact[];
  /** Categorias de la solicitud */
  category: EJobApplicationCategories;
  /** Cédula profesional */
  professionalId: string;
  /** Años de ecperiencia profesional */
  professionalExperience: number;
  /** Origen del usuario para guardado */
  origin: string;
  /** Token de captcha */
  token?: string;
}

/** Categorías disponibles para los comentarios */
export enum EJobApplicationCategories {
  PROFESSIONAL = 'PROFESSIONAL',
  ADMIN = 'ADMIN'
}
