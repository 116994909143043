import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { response } from 'express';
import { map } from 'rxjs/operators';
/**
 * Injectable
 */
@Injectable({
  providedIn: 'root'
})
export class TokenService {
  /**
   * Constructor
   * @param http  httpClient
   */
  constructor(private http: HttpClient) { }

   /**
   * Genera el token con autenticacion google
   */
  generateToken() {
     return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/chatbot', {});
  }
    /**
     * obtiene el ambiente actual
     */
  getEnviroment(){
    return this.http.get('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/envi',{})
  }

  /**
   * Obtener token de autorización
   * @returns
   */

  getAuthorize(){
    const headers:HttpHeaders = new HttpHeaders({'x-channel':'home-page'});
    return this.http
    .get('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/auth/token', {headers})
    .pipe(map((response:any) => response))
    
  }
}
