<div class="header" *ngIf="!showNewFooter">
    <nav class="navbar navbar-expand-md navbar-light p-0">
        <!--navBar-->
        <div class="container-xl principal-header mt-md-0">
            <!-- logoAxa -->
            <a class="d-lg-none navbar-brand ml-2 mr-0 pl-2" [routerLink]="['#']" >
                <img src="assets/headerIcons/logo_mariposa_RGB-01.png" alt="Logotipo de AXA Keralty" (click)="sendTags('home')" height="44px"
                    width="144px">
            </a>
            <a class="d-none d-lg-block navbar-brand ml-4 pl-3" [routerLink]="['#']" (click)="sendTags('home')">
                <img src="assets/headerIcons/logo_mariposa_RGB-01.png" alt="Logotipo de AXA Keralty" style="width: 150px;">
            </a>
            <!-- Responsive Mobile -->
            <div class="row mr-1 d-flex flex-column justify-content-center responsive-mobil1" style="margin-bottom: 20px;">
                <p class="text-planmed" style="margin-bottom: 2px; font-size: 10px;">
                    Ingresa a tu cuenta:
                </p>
                <div class=" mobile-button d-flex flex-column justify-content-center m-auto py-1" (click)="addToCartEvent()">
                    <p class="mt-1 mb-0 text-center">Mi Portal</p>
                </div>
            </div>
            <!-- Responsive Mobile end -->
            <div class="row  upgrade-content  custom-ipad responsive-mobil">
                <div class="d-flex align-items-center">
                    <div class=" ripple ml-md- bg-cerulean">
                        <span class="ak-icon icon-covid_test"></span>
                        <!-- <img src="assets/headerIcons/virus.png" class="img-upgrade"
                            alt="Icono de coronavirus color naranja, COVID-19" width="30px" height="29px" /> -->
                    </div>
                    <div>
                      <div class="header__link-test align-items-center ml-2">
                        Prueba de 
                        <span (click)="goToPaymentAntigenos()" class="header__link-test-text">
                          Antígeno o PCR COVID-19
                        </span>  
                      </div>
                    </div>
                </div>
                
             
            </div>
            <!-- Social, burger-button, burger-button showIn <768 -->
            <div class="d-lg-none mb-3">
                <div class="mt-1 pr-5 d-flex justify-content-end">
                    <div class="btn-group">
                        <div class="d-flex ml-1 mr-3">
                            <a href="tel:+525551693080" class="d-flex" (click)="sendTags('call')"> <img
                                    src="assets/headerIcons/Phone.png" class="mt-1"
                                    height="20px" width="20px" alt="icon"></a>
                        </div>
                        <!-- Menú Hamburguesa mobile -->
                        <div class="principal-header__burger-button"  (click)="toggleMenu()" [ngClass]="activeMenu ? 'principal-header__burger-button-expanded' : ''"
                           >
                            <span class="principal-header__burger-button__icon-bar top-bar"></span>
                            <span class="principal-header__burger-button__icon-bar middle-bar"></span>
                            <span class="principal-header__burger-button__icon-bar bottom-bar"></span>
                        </div>
                        <div class="bg_menu" [ngClass]="activeMenu ? 'active-bg-menu' : ''">
                            <div class=" custom-menu " [ngClass]="activeMenu ? 'custom-menu-active' : ''">
                                <div class="d-flex justify-content-end close-expanded-button">
                                   <div class="principal-header__burger-button" (click)="toggleMenu()" [ngClass]="activeMenu ? 'principal-header__burger-button-expanded' : ''">
                                   <span class="principal-header__burger-button__icon-bar top-bar"></span>
                                   <span class="principal-header__burger-button__icon-bar middle-bar"></span>
                                   <span class="principal-header__burger-button__icon-bar bottom-bar"></span>
                                   </div>
                                </div>
                                <div class="container mt-3">
                                 <div class="row px-3">
                                    <div class=" col-11">
                                        <div (click)="sendTags('contract'); toggleMenu()"
                                            class="list-element"
                                            routerLinkActive="active-route" [routerLink]="['/get-plan']"><span
                                                class="header__link-label__pointer">Contratar </span>
                                        </div>
                                        <div (click)="sendTags('servicesPay'); toggleMenu()"
                                        class="list-element"
                                        routerLinkActive="active-route" [routerLink]="['/servicios']"><span
                                            class="header__link-label__pointer">Pago de servicios </span>
                                    </div>
                                        <div (click)="sendTags('about-us'); toggleMenu()"
                                            class="list-element"
                                            routerLinkActive="active-route" [routerLink]="['/about-us']"><span
                                                class="header__link-label__pointer">Nosotros</span>
                                        </div>
                                        <div (click)="sendTags('CAMs'); toggleMenu()"
                                            class="list-element"
                                            pageScroll [routerLink]="['/home']" href="#head-visit-us"
                                            pageScrollOffset="150"><span class="header__link-label__pointer">Ubicaciones</span>
                                        </div>
                                        <div (click)="sendTags('contact-us'); toggleMenu()"
                                            class="list-element"  [routerLink]="['/contact-us']"
                                            routerLinkActive="active-route"><span class="header__link-label__pointer"
                                               >Contáctanos </span>
                                        </div>
                                        <div (click)="sendTags('faqs'); toggleMenu()"
                                            class="list-element"  [routerLink]="['/faqs']"
                                            routerLinkActive="active-route"><span class="header__link-label__pointer"
                                               >Preguntas frecuentes</span>
                                        </div>
                                        <div class="list-element"(click)="goToNet(); toggleMenu()" >
                                            <span class="header__link-label__pointer" >Soy
                                                distribuidor</span>
                                        </div>
                                        <div (click)="sendTags('faqs'); goToTokenPage(); toggleMenu()"
                                        class="list-element" 
                                        routerLinkActive="active-route"><span class="header__link-label__pointer"
                                       >Tokenizador </span>
                                        </div>
                                        <div (click)="changeEnglish(); toggleMenu()"
                                            class="list-element" 
                                            style="border-bottom: none;" 
                                            routerLinkActive="active-route"><span class="italic-font header__link-label__pointer"
                                           >English version</span>
                                        </div>
                                    </div>
                                   <div class="col-11 mt-4">
                                    <div class="content-phone d-flex justify-content-center align-items-center mb-3">
                                      <span class=" ak-icon icon-phone1" style="color: #FFFFFF; font-size: 12px;"></span>
                                    </div>
                                   </div>
                                   <div class="col-11">
                                    <p class="text-number mb-2"> 55 5169 3080</p>
                                    <p class="text-description-number">
                                     Lunes a viernes: De 9:00 a 21:00 hrs. <br>
                                     Sábados: De 9:00 a 15:00 hrs.
                                    </p>
                                  </div>
                                 </div>
                                </div>
                             </div>
                        </div>
                        <div class="dropdown-menu dropdown-menu-right burger-button__custom-menu">
                            <div class="px-4">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Options >767 -->
            <div class="d-none d-lg-block mb-4">
                <p class="text-planmed">
                    Ingresa a tu cuenta:
                </p>
                <div class="mt-1 text-center mr-4 d-flex">
                    <div class="principal-header__button-act-logo_first mr-2 " (click)="addToCartEvent()">
                        <span class="mt-1">Mi Portal</span>
                    </div>
                    <div class="principal-header__button-act-logo_second d-flex justify-content-center align-items-center " (click)="goToTokenPage()"> Tokenizador
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <!-- blueNavBar showIn > 768px-->
    <div class="container-fluid bar-header d-none d-lg-block px-0">
        <div class="container-fluid px-0">
            <ul class="nav nav-pills nav-fill">
                <li class="nav-item">
                    <a (click)="sendTags('contract')" class="nav-link header__link-label__default"
                        routerLinkActive="active-route" [routerLink]="['/get-plan']"><span
                            class="header__link-label__pointer">Contratar</span></a>
                </li>
                <li class="nav-item">
                    <a (click)="sendTags('servicesPay')" class="nav-link header__link-label__default"
                        routerLinkActive="active-route"  [routerLink]="['/servicios']"><span
                            class="header__link-label__pointer">Pago de servicios</span></a>
                </li>
                <li class="nav-item">
                    <a (click)="sendTags('about-us')" class="nav-link header__link-label__default"
                        routerLinkActive="active-route" [routerLink]="['/about-us']"><span
                            class="header__link-label__pointer">Nosotros</span></a>
                </li>
                <li class="nav-item">
                    <a (click)="sendTags('CAMs')" class="nav-link header__link-label__default" pageScroll
                        [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175"><span
                            class="header__link-label__pointer">Ubicaciones</span></a>
                </li>
                <li class="nav-item">
                    <a (click)="sendTags('contact-us')" class="nav-link header__link-label__default"
                        routerLinkActive="active-route" [routerLink]="['/contact-us']"><span
                            class="header__link-label__pointer">Contáctanos</span></a>
                </li>
                <li class="nav-item">
                    <a (click)="sendTags('faqs')" class="nav-link header__link-label__default" routerLinkActive="active-route"
                        [routerLink]="['/faqs']"><span class="header__link-label__pointer">Preguntas
                            frecuentes</span></a>
                </li>
                <li class="nav-item">
                    <a (click)="sendTags('english')" href=" https://cloud.email-axakeralty.mx/Englishversion" rel="noreferrer" class="nav-link header__link-label__default" routerLinkActive="active-route"
                       ><span class="header__link-label__pointer italic-font">English version</span></a>
                </li>
                <li class="nav-item" style="background-color: rgba(22, 64, 116, .24);">
                    <a class="nav-link header__link-label__default d-flex align-items-center justify-content-center" routerLinkActive="active-route" placement="bottom" tooltipClass="tooltip-class" data-html="true" 
                    ngbTooltip="Lunes a viernes: De 09:00 a 21:00 hrs.  &#13;Sábados: De 09:00 a 15:00 hrs."><span class="">
                        <span class="axa-icon axa-phone mr-2" style="color:#FFFFFF"></span>
                    (55)
                    5169 3080</span></a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="sticky-header" *ngIf="showNewFooter">
    <div class="navbar">
       <div class="container">
        <div>
            <img class="header-img c-pointer" style="cursor: pointer;" src="assets/ak-logo.png" (click)="navigateHome()" alt="">
        </div>
        <div class="d-flex">
            
            <div class="d-flex align-items-center" style="color:#00008F;">
                <span class="home-icon icon-beneficiary c-pointer"  (click)="navigatePortal()" style="
                    cursor: pointer;
                    color: #00008F;
                    font-size: 25px;
                    position: relative;
                    margin-right: 10px;
                    margin-top: 5px;"></span>
                <span class="axa-icon axa-Home c-pointer" style="cursor: pointer;"  (click)="navigateHome()" style="color: #00008F"></span>
            </div>
        </div>
       </div>
    </div>
</div>
