// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/**
 * Const environment develop
 */
export const environment = {
  production: false,
  baseUrl: 'http://localhost:3000',
  templateURL: 'https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/pdf/download/template1',
  dialogflow: {
    angularBot: '8778f6acbadf48efb54517b1cb0c50b5',
  },
  sim_iv: 'abcdef9876543210abcdef9876543210',
  publicKey: 'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0NCk1JR2ZNQTBHQ1NxR1NJYjNEUUVCQVFVQUE0R05BRENCaVFLQmdRQ3p5bm95R1NzZGozS2o5MkljdmQxMUM4cXUNCnhORTIwblo1S1IrUXRTSU1OTWxUcHFJcERiWjc1TGFRU0YyY0FYa05oTXNCZzB0YWJRZWd1MFMyQmt1NW5uQ2QNCjNLZCtBRVlSdTdSekV1czhJSDVtT0lReFBqM1JoZUVja24yQ1QrVTdoaXMwWjZ6S2RtdXk3dCtDY0pibjBMbTgNCllKVmRKNFl0YXBsY1lUMW9id0lEQVFBQg0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0tDQo='

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
