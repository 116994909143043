import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IUserData } from 'src/app/interfaces/data-user/data-user.interface';
import { Router } from '@angular/router';
import { ConsultService } from 'src/app/services/consult.service';
import { STATES } from 'src/app/constants/states/states.const';
import { CITIES } from 'src/app/constants/cities/cities.const';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { MxValidatorService } from 'src/app/services/mx-validator/mx-validator.service';
import { filter, finalize, map } from 'rxjs/operators';
import { IAsentamientosData, IEntityData } from 'src/app/interfaces/postal-code.interface';
import { HeaderService } from '../../../shared/header/header.service';

/**
 * Componente para Datos de usuario
 */
@Component({
  selector: 'data-payment',
  templateUrl: './data-payment.component.html',
  styleUrls: ['./data-payment.component.scss']
})
export class DataPaymentComponent implements OnInit {
  /**Formulario de datos de usuario */
  dataForm: UntypedFormGroup;
  /** Máscara para inputs de nombre */
  streetPatterns = { B: { pattern: new RegExp('[a-zñÑA-Z0-9# ]') } };
  /** Máscara para inputs de dirección */
  addressPatternsBilling = { B: { pattern: new RegExp('[a-zñÑA-Z0-9#,. \/\-]') } };
  /** Seleccionar tipo de plan */
  optionsSelect = [
    { value: 'Externo', name: 'No cuento con seguro AXA ni Planmed®' },
    { value: 'Planmed', name: 'Tengo Planmed® y/o GMM AXA' },
    //{ value: 'GMM', name: 'Tengo Gastos Médicos Mayores AXA' },
  ];
  /** Errores del formulario */
  /** Contiene info de usuario en caso de ya existir */
  userData: IUserData;
  /** Modelo para primer nombre */
  firstName: string = '';
  /** Modelo para segundo nombre */
  secondName: string = '';
  /** Variable para tipo de usuario */
  typeUser: any = '';
  /**Variable ara validar si requiere factura */
  requireBill: boolean;
  /**Check no requiere factura */
  declareNoBill = true;
  /** Modelo para fatherSurname */
  fatherSurname: string = '';
  /** Modelo para motherSurname */
  motherSurname: string = '';
  /** Modelo para phone */
  phone: string = '';
  /**modelo para rfc */
  RFC: string = '';
  /**bandera que verifica si RFC es válido */
  validRFC: boolean = false;
  /**bandera que verifica si validacion de RFC ha fallado */
  failedRFC: boolean = false;
  /** Modelo para email */
  email: string = '';
  /**constantes de estados */
  mexicoStates = STATES;
  /**constante de municipios */
  mexicoCities = CITIES;
  firstCheckBox: boolean;
  /** Validacion de nombre correcto */
  validTypeUser: boolean;
  /** Validacion de nombre correcto */
  validFirstName: boolean;
  /** Validacion de segundo nombre correcto */
  validSecondName: boolean;
  /** Validacion de apellido paterno correcto */
  validFatherSurname: boolean;
  /** Validacion de apellido paterno correcto */
  validMotherSurname: boolean;
  /** Validacion de telefono correcto */
  validPhone: boolean;
  invalidStrPhone: boolean = false;
  /** Validacion de email correcto */
  validEmail: boolean;
  /** Validacion de cosigo postal correcto */
  validCP: boolean;
  /** Validacion de estado correcto */
  validState: boolean;
  /** Validacion de municipio correcto */
  validMunicipality: boolean;
  /** Validacion de ciudad correcto */
  validCity: boolean;
  validRequireBill: any;
  /** Validacion de colonia correcto */
  validSuburb: boolean;
  /** Validacion de calle correcto */
  validStreet: boolean;
  /** Validacion de nuemero exterior correcto */
  validNumExt: boolean;
  /** Validacion de numero interior correcto */
  validIntNum: boolean;
  validForm = false;
  secondNameFlag = false;

  /** CFDI 4.0 */
  taxRegime = '';
  useRegime = '';
  streetInvoice = '';
  numExtInvoice = '';
  numIntInvoice = '';
  zipCodeInvoice = '';
  colonyInvoice = '';
  cityInvoice = '';
  municipalityInvoice = '';
  municipalityCodeInvoice = '';
  stateInvoice = '';
  stateCodeInvoice = '';

  dataCFDI: UntypedFormGroup | null;

  cfdiTaxRegime: Array<any> = [];
  cfdiUseArray: Array<any> = [];
  entity: IEntityData | undefined;
  checkedRFC: boolean = false;

  /** Máscara para inputs de nombre */
  namePatterns = { B: { pattern: new RegExp('[a-zñÑA-Z ]') } };
  /** Máscara para inputs de dirección */
  addressPatterns = { B: { pattern: new RegExp('[a-zñÑáÁéÉíÍóÓúÚA-Z ]') } };
  /** Máscara para correo */
  emailMask = { B: { pattern: new RegExp('[a-zA-Z0-9@.-_]') } };
  /**M ascara para numeros */
  numberMask = { B: { pattern: new RegExp('[0-9]') } };
  stateSelected: any = null;
  serviceSelected: string | null = null;
  changeByRefresh: boolean = false;


  constructor(
    private userInfo: ConsultService,
    private router: Router,
    private loader: LoaderService,
    private modalService: ModalService,
    private validateServ: MxValidatorService,
    private fb: UntypedFormBuilder,
    private headerService: HeaderService,
  ) { }
  /** Hook de inicialización de componente */
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.userInfo.userInfo.subscribe((response: IUserData) => {
      // console.log("response userInfo", response);
      if (response !== null) {
        this.typeUser = response.typeUser;
        this.firstName = response.firstName;
        this.secondName = response.secondName;
        this.fatherSurname = response.fatherSurname;
        this.motherSurname = response.motherSurname;
        this.phone = response.phone;
        this.email = response.email;
        this.RFC = response.rfc === 'XAXX010101000' ? '' : response.rfc;
        this.requireBill = response.requireBill;
        this.declareNoBill = response.declareNoBill;
        this.validRFC = response.validRFC;
        this.zipCodeInvoice = response.zipCodeInvoice;
        this.cityInvoice = response.cityInvoice;
        this.colonyInvoice = response.colonyInvoice;
        this.stateInvoice = response.stateInvoice;
        this.numExtInvoice = response.numExtInvoice;
        this.numIntInvoice = response.numIntInvoice;
        this.streetInvoice = response.streetInvoice;
        this.taxRegime = response.taxRegime;
        this.useRegime = response.useRegime;
        this.validateTypeUser();
        this.validateFirstName();
        this.validateFatherSurname();
        this.validateSecondName();
        this.validatePhone();
        this.validateMail();
        // this.validateData();
        if (response.requireBill) {
          this.validatePostalCode();
          this.validateState();
          this.validateMunicipality()
        }
      }
      this.userData = response;
      this.validateData();
    });
    this.userInfo.stateService.subscribe(resp => {
      this.stateSelected = resp;
    })
    this.userInfo.typeService.subscribe(resp => {
      //console.log(resp);
      this.serviceSelected = resp;
      switch (resp) {
        case 'prueba-covid':
          this.optionsSelect = [
            { value: 'Externo', name: 'No cuento con seguro AXA ni Planmed®' },
            { value: 'Planmed', name: 'Tengo Planmed® y/o GMM AXA' },
            //{ value: 'Planmed', name: 'Tengo Planmed®' },
            //{ value: 'Flex', name: 'Tengo Flex Plus CDMX' },
          ];
          break;
        case 'medicina-general':
          this.optionsSelect = [
            { value: 'Externo', name: 'No cuento con seguro AXA ni Planmed®' },
            { value: 'Planmed', name: 'Tengo Planmed® y/o GMM AXA' },
          ];
          break;
        case 'pediatria':
          this.optionsSelect = [
            { value: 'Externo', name: 'No cuento con seguro AXA ni Planmed®' },
            { value: 'Planmed', name: 'Tengo Planmed® y/o GMM AXA' },
          ];
          break;
        case 'prueba-antigenos':
          this.optionsSelect = [
            { value: 'Externo', name: 'No cuento con seguro AXA ni Planmed®' },
            { value: 'Planmed', name: 'Tengo Planmed® y/o GMM AXA' },
          ];
          break;
        default:
          this.optionsSelect = [
            { value: 'Externo', name: 'No cuento con seguro AXA ni Planmed®' },
            { value: 'Planmed', name: 'Tengo Planmed® y/o GMM AXA' },
          ];
      }

    });
    this.validateServ.getTaxRegimeList().pipe(
      map((res: any) => {
        if (res.axaHeaderRes.resultado.codigo === "0" && (res.data as Array<any>).length) {
          (res.data as Array<any>).forEach(elem => {
            if (elem.aplica_persona_fisica) {
              this.cfdiTaxRegime.push({
                c_RegimenFiscal: elem.c_RegimenFiscal,
                descripcion: elem.descripcion
              });
            }
          });
        } else {
          console.error("Hubo un error al cargar lista de régimen fiscal")
        }
      }))
      .subscribe();

    this.validateData();
    setTimeout(
      () => {
        if (this.zipCodeInvoice) {
          this.zipCodeInvoice = this.zipCodeInvoice;
        }
        this.validateData();
      },
      500
    )

    this.headerService.changedByRefresh.subscribe(
      (response: boolean) => {
        this.changeByRefresh = response;
      }
    )
  }

  /** Función pra envío de formulario */
  submitForm() {
    this.RFC = this.RFC.toUpperCase();
    const form: IUserData = {
      typeUser: this.typeUser,
      firstName: this.firstName,
      secondName: this.secondName,
      fatherSurname: this.fatherSurname,
      motherSurname: this.motherSurname,
      phone: this.phone,
      email: this.email,
      rfc: this.RFC === '' ? 'XAXX010101000' : this.RFC,
      validRFC: this.validRFC,
      requireBill: this.requireBill,
      declareNoBill: this.declareNoBill,
      zipCodeInvoice: this.zipCodeInvoice,
      cityInvoice: this.cityInvoice,
      colonyInvoice: this.colonyInvoice,
      stateInvoice: this.stateInvoice,
      municipalityInvoice: this.municipalityInvoice,
      streetInvoice: this.streetInvoice,
      numExtInvoice: this.numExtInvoice,
      numIntInvoice: this.numIntInvoice,
      taxRegime: this.taxRegime,
      useRegime: this.useRegime
    }
    this.userInfo.userInfo.next(form);
    this.router.navigate(['/servicios-checkout']);
  }

  capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }
  changeBill(value) {

    this.requireBill = value;
    this.validateData();
  }
  validateTypeUser() {
    console.log(this.typeUser);
    setTimeout(
      () => {
        if (this.typeUser === '' || this.typeUser === undefined) {
          this.validTypeUser = false;
        } else {
          this.validTypeUser = true;
        }
        this.validateData();
      }, 200
    )

    this.validateData();
  }
  validateFirstName() {
    if (this.firstName === '' || this.firstName === undefined || this.firstName.length < 3) {
      this.validFirstName = false;
    } else {
      this.validFirstName = true;
    }
    this.validateData();
  }
  validateSecondName() {
    if (this.secondName === '' || this.secondName === undefined || this.secondName.length < 3) {
      this.validSecondName = false;
    } else {
      this.validSecondName = true;
    }
    this.validateData();
  }

  validateFatherSurname() {
    if (this.fatherSurname === '' || this.fatherSurname === undefined || this.fatherSurname.length < 3) {
      this.validFatherSurname = false;
    } else {
      this.validFatherSurname = true;
    }
    this.validateData();
  }
  validatePhone() {
    this.invalidStrPhone = false;
    if (this.phone === '' || this.phone === undefined || this.phone.length < 10) {
      this.validPhone = false;
    } else {
      this.validPhone = true;
      for (let i = 0; i <= 9; i++) {
        if (this.cuenta(this.phone, i)) {
          this.invalidStrPhone = true;
        }
      }
    }
    this.validateData();
  }
  /**
   * Validar teléfono
   * @param str 
   * @param ind 
   */
  cuenta(str, ind) {
    let regex = new RegExp("[^" + ind + "]", "g");
    str = str.replace(regex, "").length
    if (str == 10) {
      return true;
    } else {
      return false;
    }
  }
  validateMail() {
    if (this.email === '' || this.email === undefined || !this.email.includes('@') || !this.email.includes('.')) {
      this.validEmail = false;
    } else {
      this.validEmail = true;
    }
    this.validateData();
  }
  /** Validacion de codigo postal */
  validatePostalCode() {
    if (this.requireBill && (this.zipCodeInvoice === '' || this.zipCodeInvoice === undefined || this.zipCodeInvoice.length < 5)) {
      this.validCP = false;
    } else {
      this.validCP = true;
    }
    this.validateData();
  }

  /** Validacion de estado */
  validateState() {
    if (this.stateInvoice === '' && this.requireBill) {
      this.validState = false;
    } else {
      this.validState = true;
    }
    this.validateData();
  }

  /** Validacion de estado */
  validateMunicipality() {
    if (this.municipalityInvoice === '' && this.requireBill) {
      this.validMunicipality = false;
    } else {
      this.validMunicipality = true;
    }
    this.validateData();
  }



  /** Validacion de colonia */
  validateSuburb() {
    if (this.colonyInvoice === '' && this.requireBill) {
      this.validSuburb = false;
    } else {
      this.validSuburb = true;
    }
    this.validateData();
  }



  /** Validacion de colonia */
  validateStreet() {
    if (this.streetInvoice === '' && this.requireBill) {
      this.validStreet = false;
    } else {
      this.validStreet = true;
    }
    this.validateData();
  }

  /** Validacion de colonia */
  validateNumExt() {
    if (this.numExtInvoice === '' && this.requireBill) {
      this.validNumExt = false;
    } else {
      this.validNumExt = true;
    }
    this.validateData();
  }

  /** Validacion de colonia */
  // validateNumInt() {
  //   if (this.numIntInvoice === '' || this.numIntInvoice === undefined) {
  //     this.validIntNum = false;
  //   } else {
  //     this.validIntNum = true;
  //   }
  //   this.validateData(); 
  // }
  checkData() {
    this.userInfo.obDeclareNoBill.next(this.declareNoBill);
    this.validateData();
  }
  /**Funcion para validar formulario */
  validateData() {


    if (this.validFirstName && this.validFatherSurname && this.validTypeUser && this.validPhone && this.validEmail) {
      if (this.requireBill === false) {
        this.userInfo.obDeclareNoBill.subscribe(resp => {
          this.validRequireBill = resp;
        });
        if (this.validRequireBill === false) {
          this.validForm = true;
        } else {
          this.validForm = false;
        }

      } else if (this.requireBill === true) {
        if (this.validCP && this.validState && this.validMunicipality && this.validRFC) {
          this.validForm = true;
        } else {
          this.validForm = false;
        }
      } else {
        this.validForm = false;
      }
    } else {
      this.validForm = false;
    }
  }

  /**Funcion para resetear valores de factura */
  resetValidate() {
    this.validForm = false;
    this.declareNoBill = false;
  }

  disabledButton() {
    this.checkedRFC = false;
    if (this.RFC.length < 13) {
      this.validRFC = false;
      this.taxRegime = '';
      this.useRegime = '';
      this.streetInvoice = '';
      this.numExtInvoice = '';
      this.numIntInvoice = '';
      this.zipCodeInvoice = '';
      this.colonyInvoice = '';
      this.cityInvoice = '';
      this.municipalityInvoice = '';
      this.municipalityCodeInvoice = '';
      this.stateInvoice = '';
      this.stateCodeInvoice = '';
      this.validateData();
    }
  }

  /**
   * Función para validación de RFC
   * @param {*} member
   * @memberof ProspectSaleComponent
   */
  validateRFC() {
    this.checkedRFC = true;
    // this.loader.message = 'Validando RFC';

    this.loader.star();
    let channelRFC = 'payment-gateway';

    this.validateServ.newValidateRFC(channelRFC, this.RFC).subscribe(
      async (res: any) => {

        // SI LA CONSULTA FUE EXITOSA PERO NO VALIDA
        if (res && res.data.data.isValid === false) {

          this.failedRFC = true;
          this.validRFC = false;
          // this.quoteServ.validRFC = true;
          this.loader.stop();
          // this.loader.message = '';
          this.validateData();
          switch (res.data.data.code) {
            case -1:
              this.validRFC = true;
              // this.quoteServ.validRFC = true;
              this.failedRFC = false;
              this.loader.stop();
              // this.loader.message = '';
              this.validateData();
              this.modalService.errorModal('Este ambiente no tiene habilitado el servicio de Nubarium.', 'Mensaje de prueba');
              break;
            case 1:
              this.modalService.errorModal('RFC no válido, por favor verifique los datos e intente de nuevo.', '¡Oh no!');
              break;
            case 2:
              this.modalService.errorModal('El formato del RFC es inválido, por favor verifique los datos e intente de nuevo.', '¡Oh no!');
              break;
            case 3:
              this.modalService.errorModal('El RFC no cuenta con un formato válido, por favor verifique e intente de nuevo.', '¡Oh no!');
              break;
            case 4:
              this.modalService.errorModal('Error en el servicio, por favor intente más tarde.', '¡Oh no!');
              break;
            case 5:
              this.modalService.errorModal('Faltan datos para poder validar el RFC, por favor verifique e intente de nuevo.', '¡Oh no!');
              break;
            case 6:
              this.modalService.errorModal('Error en el servicio, por favor intente más tarde.', '¡Oh no!');
              break;

            default:
              break;
          }


          // SI LA CONSULTA FUE EXITOSA Y VALIDA
        } else {
          if (
            res &&
            res.data.data.isValid === true
          ) {
            this.validRFC = true;
            // this.quoteServ.validRFC = true;
            this.failedRFC = false;
            this.loader.stop();
            this.validateData();
            // this.loader.message = '';
          } else {
            this.failedRFC = true;
            this.validRFC = false;
            // this.quoteServ.validRFC = true;
            this.loader.stop();
            // this.loader.message = '';
            this.validateData();
          }
        }
      },
      error => {
        // this.loader.message = '';
        this.loader.stop();
        this.validForm = false;
      }
    );
    this.validateData();
  }

  getCFDIUse(taxRegimeCode: any) {
    this.cfdiUseArray = [];
    // this.reviewInformationForm.get('cfdiUse')?.setValue(null)
    // this.reviewInformationForm.get('cfdiUse')?.disable();

    this.validateServ.getCFDIUse(taxRegimeCode).pipe(
      map((res: any) => {

        if (res.axaHeaderRes.resultado.codigo === "0" && (res.data as Array<any>).length) {
          (res.data as Array<any>).forEach(elem => {
            this.cfdiUseArray.push({
              c_UsoCFDI: elem.c_UsoCFDI,
              uso_CFDI: elem.uso_CFDI
            });
          });

          // if (this.cfdiUseArray.length === 0) this.reviewInformationForm.get('cfdiUse')?.disable()
          // else this.reviewInformationForm.get('cfdiUse')?.enable()

        } else {
          console.error("Hubo un error al cargar lista de uso del cfdi")
        }
      })
    ).subscribe();
  }


  /**
   * Obtener datos de ubicación y validar CP
   * @param postalCode
   */
  postalCodeValid(postalCode: string) {
    if (postalCode.length === 5) {
      this.loader.star();
      let cp$ = this.validateServ
        .validateCP(postalCode)
        .pipe(
          filter((response: IAsentamientosData) => {
            //  console.log('QUOTE PLAN: GET RESPONSE CODE POSTAL ===> ', response);
            const finded: boolean = !!response.estados;
            // Validar datos encontrados
            if (!finded) {
              // this.openModalError();
              return finded;
            } else {
              this.entity = response.estados?.shift();
              // // this.cp.entityData = entity;
              this.stateInvoice = this.entity.descripcionEstado;
              this.stateCodeInvoice = this.entity.codigoEstado;
              this.municipalityInvoice = this.entity?.municipio[0].descripcionMunicipio;
              this.municipalityCodeInvoice = this.entity?.municipio[0].codigoMunicipio;
              // this.reviewInformationForm.get('addressCFDI')?.get('state')?.patchValue(this.entity?.descripcionEstado);
              // this.reviewInformationForm.get('addressCFDI')?.get('stateCode')?.patchValue(this.entity?.codigoEstado);
              // this.reviewInformationForm.get('addressCFDI')?.get('municipality')?.patchValue(this.entity?.municipio[0].descripcionMunicipio);
              // this.reviewInformationForm.get('addressCFDI')?.get('municipalityCode')?.patchValue(this.entity?.municipio[0].codigoMunicipio);

              if (this.entity?.municipio[0].asentamientos.length === 1) {
                this.colonyInvoice = this.entity?.municipio[0].asentamientos[0].descripcionAsentamiento;

              }

              this.validateState();
              this.validateMunicipality();

            }
            return finded;
          }),
          finalize(() => this.loader.stop())
        ).subscribe(() => {
          cp$.unsubscribe();
        });
    } else {
      this.loader.stop()
    }
  }

  addCFDIAddressGroup() {
    this.dataCFDI = this.fb.group({
      taxRegime: new UntypedFormControl('', Validators.required),
      useRegime: new UntypedFormControl('', Validators.required),
      streetInvoice: new UntypedFormControl('', Validators.required),
      numExtInvoice: new UntypedFormControl('', Validators.required),
      numIntInvoice: new UntypedFormControl(''),
      zipCodeInvoice: new UntypedFormControl('', Validators.required),
      colonyInvoice: new UntypedFormControl('', Validators.required),
      cityInvoice: new UntypedFormControl(''),
      municipalityInvoice: new UntypedFormControl('', Validators.required),
      municipalityCodeInvoice: new UntypedFormControl('', Validators.required),
      stateInvoice: new UntypedFormControl('', Validators.required),
      stateCodeInvoice: new UntypedFormControl('', Validators.required),
    })
  }

  testChange(cp: any) {
    console.log(cp)
  }

  get isZipCodeDisabled() {
    return this.useRegime === '';
  }

  get isColoniesDisabled() {
    return this.entity === undefined;
  }

  get fullName() {
    return `${this.firstName} ${this.secondName} ${this.fatherSurname} ${this.motherSurname}`
  }

  showHideSecondName(event) {
    if (event) {
      event.preventDefault();
    }
    this.secondNameFlag = !this.secondNameFlag;
    if (!this.secondNameFlag) {
      this.secondName = "";
    }
  }

  goServices() {
    this.router.navigate(['/servicios']);
  }

  /** Funcion para retorno de errores */
  // getError(input: string, touched: any) {
  //    this.formErrors
  //     ? (this.formErrors.find(item => item.input === input && touched) || { message: '' }).message
  //     : '';

  // }
}
