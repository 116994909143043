/**
 * Politicas generales 
 */
export const GENERALPOLICY = [
    {
      section: 1,
      title: 'CONDICIONES GENERALES',
      description: [
        `AXA Salud, S.A. de C.V., con domicilio en Avenida Félix Cuevas No. 366, Piso 3, Colonia Tlacoquemécatl, Alcaldía Benito Juárez, C.P. 03200, Ciudad de México (“AXA”), y Keralty México, S.A. de C.V., con domicilio en Avenida Insurgentes Sur 1871, Interior 401, Guadalupe Inn, Álvaro Obregón, 01020 Ciudad de México (“Keralty” y en conjunto con AXA, “AXA Keralty”).`,
        `La presente Política de Uso es aplicable a cualquier persona (en adelante “El Usuario”) que (i) navegue para consultar información respecto de la prestación de servicios de salud que ofrece Keralty; y/o (ii) realice operaciones tales como consultar información de productos de seguro, contratación de productos, modificación de condiciones contratadas, pago de primas, consultar y solicitar servicios, y en general cualquiera de las operaciones necesarias para el uso de los productos y prestación de servicios adicionales vinculados a las coberturas, asistencias o beneficios de productos contratados con AXA (Operaciones con Medios Electrónicos) en el portal de axakeralty.mx (en adelante el “Sitio Web”) y en la aplicación móvil AXA Keralty (en adelante la “Aplicación”) propiedad de AXA, por lo que el Usuario deberá observar lo siguiente:`,
        `El Usuario reconoce que cualquier derecho de propiedad intelectual o industrial de este Sitio Web y la Aplicación, así como los gráficos, textos, planos, botones y, en general, toda la información contenida en ella pertenece únicamente a AXA y/o Keralty, respectivamente. Queda prohibida la reproducción, carga, presentación, ejecución, transmisión, almacenamiento, traducción, adaptación, modificación, arreglo o distribución, ya sea total o parcial, realizadas sin el consentimiento y autorización expresa de AXA y/o Keralty. Cualquier contravención a lo anterior constituye una infracción de los derechos de propiedad intelectual e industrial. AXA Keralty no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad industrial e intelectual o sobre cualquier otra propiedad o derecho relacionado con el Sitio Web ni la Aplicación, ni sobre su contenido.`,
        `El uso del Sitio Web y la Aplicación estará destinado exclusivamente a consultar información de productos y servicios, Operaciones con Medios Electrónicos, prospecto de clientes, titulares de pólizas, asegurados y/o pacientes de AXA y/o Keralty, respectivamente. El acceso al Sitio Web y la Aplicación es libre, voluntario y su simple utilización le da el carácter de Usuario. Para el uso del Sitio Web y registro en la Aplicación, el Usuario debe aceptar esta Política de Uso, por lo que se recomienda leerla atentamente. Si no está de acuerdo con la misma, le informamos que no debe continuar navegando ni accediendo a los contenidos del Sitio Web ni la Aplicación.`,
        `El uso de la Aplicación estará destinado exclusivamente a asegurados de AXA, o empresas subsidiarias y/o filiales a las cuales les preste servicios de administración de sus usuarios y/o asegurados bajo cualquiera de las modalidades autorizadas por la regulación vigente. El acceso a la Aplicación es libre, voluntario y su utilización le da el carácter de Usuario. Para el uso de la Aplicación el Usuario debe aceptar estos Términos y Condiciones de Uso, por lo que se recomienda leerlos atentamente. Si no está de acuerdo con éstos, no debe continuar navegando ni accediendo a los contenidos de la misma pues el hecho de acceder a ellas confirma la aceptación de los presentes Términos y Condiciones de Uso.`,
        `Al registrarse en la Aplicación, usted podrá acceder a la información de su póliza contratada con AXA, agendar servicios cubiertos por la misma y/o brindados por Keralty, y actualizar información de contacto.`,
        `Para contactar a AXA Keralty, hágalo en axakeralty.mx mediante la sección destinada para ello, o a través del correo electrónico hola@axakeralty.mx o bien, llamando al teléfono 5169 3080.`,
        
      ]
    },
    {
      section: 2,
      title: 'OBJETO',
      description: [
        `A través de la presente Política de Uso que AXA pone a su disposición, se rigen las Operaciones con Medios Electrónicos, publicación de información de sus productos, beneficios y servicios contratados con AXA mediante el Sitio Web. Asimismo, el catálogo de servicios de salud que ofrece Keralty en los centros de atención médica y sus costos, y en general las operaciones necesarias para la gestión de la prestación de los servicios de salud ofrecidos por Keralty.`,
        `Los productos de seguros comercializados por AXA se rigen por las Condiciones Generales aplicables a cada producto o servicio, las cuales se encuentran registradas ante la Comisión Nacional de Seguros y de Fianzas, mismas que se podrán consultar en el Sitio Web, sección Condiciones Generales.`
      ]
    },
    {
      section: 3,
      title: 'EL USUARIO',
      description: [
        `El uso seguro del Sitio Web requiere identificar al Usuario a través de datos únicos que lo vinculan con AXA. Adicionalmente, se requiere, en función del tipo de operación o servicio implicado, medidas de autentificación de la identidad del Usuario, entre ellas cuestionarios, contraseñas de acceso (password), y/o mecanismos de generación de contraseñas dinámicas (token). Estos medios de identificación y autentificación serán utilizados en sustitución de la firma autógrafa y producirán los mismos efectos que las leyes otorgan a los documentos correspondientes; por lo que las operaciones y servicios realizados a través del Sitio Web con uso de las medidas de identificación y procesos de autentificación de identidad tendrán el mismo valor que se concede a los documentos con firma autógrafa.`,
        `El Usuario deberá identificarse con AXA usando información que lo individualice, a través de un nombre de usuario y contraseña.`,
        `Para efectos de registro el Usuario deberá indicar su correo electrónico que será su nombre de Usuario y generar su contraseña, con los cuales accederá en las ocasiones subsecuentes. No obstante, AXA también podrá asignar contraseña de acceso al Usuario, en caso de que éste lo requiera.`,
        `El uso del nombre de usuario y de la contraseña será exclusiva responsabilidad del Usuario, quien reconoce y acepta que son de carácter personal, intransferible y confidencial y acepta desde ahora como suyas todas las operaciones que se celebren con AXA utilizando las mismas, y para todos los efectos legales a que haya lugar. `,
        `Durante su registro en el uso de los Medios Electrónicos el Usuario deberá proporcionar una dirección de correo electrónico y sólo en su defecto un número telefónico celular o móvil que será el medio de contacto a través del cual AXA le haga llegar las notificaciones o comprobantes, en su caso, de las Operaciones y Servicios realizados.`,
        `De acuerdo con las características del dispositivo móvil del Usuario, éste podrá utilizar la funcionalidad de huella digital y/o reconocimiento facial con que cuente el sistema biométrico de su Dispositivo para acceder a la Aplicación y sus servicios. La Aplicación no guarda datos biométricos y tampoco lee aquellos que resguarda su dispositivo móvil, sólo se conecta con dicho dispositivo para validar la existencia de un perfil de autenticación.`,
        `Los servicios de localización, en su caso, de la Aplicación permiten que éstas recopilen y usen información basada en su ubicación actual, para poder ofrecer los servicios que presta AXA y/o Keralty. Para tener acceso a estas funciones, debe activar los servicios de localización en su dispositivo electrónico. `,
        `Si los servicios de localización están activados, su dispositivo electrónico enviará periódicamente su ubicación geográfica de manera anónima y encriptada para alimentar la base de datos.`,
        `Al activar los servicios de localización en su dispositivo electrónico, acepta y autoriza a AXA y/o Keralty, subsidiarias, filiales, empresas relacionadas y a aquellos a los que AXA haya dado permiso para transmitir, recopilar, mantener, procesar y usar sus datos de ubicación y sus búsquedas de ubicación para las finalidades antes mencionadas.`,
        `En caso de extravío o robo de las credenciales (usuarios y contraseñas) para el acceso a las cuentas, el Usuario deberá notificar dicha situación a AXA, a través de correo electrónico señalado en este párrafo de los presentes Términos y Condiciones, en un plazo máximo de 24 (Veinticuatro) horas  a fin de que se tomen las medidas necesarias tendientes a evitar el acceso a terceros no autorizados, de lo contrario, AXA quedará libre de cualquier responsabilidad frente al Usuario que haya sido omiso en la notificación. En caso de recuperación de contraseña o Usuario, el Usuario podrá solicitarla a través del sitio web y recibirá un corro electrónico de la dirección soportenotificaciones@axakeralty.mx con la información correspondiente para dichos efectos al correo electrónico que tenga registrado.`,
        `Los datos de la cuenta creada serán guardados dentro del directorio activo de clientes de AXA para permitirle acceder a la cuenta desde diferentes dispositivos en diferentes momentos y tener siempre acceso a su información. Asimismo, en caso de que el Usuario deje de ser cliente de AXA, los datos de la cuenta permanecerán en el servidor con estatus de inactivo durante 6 meses y posterior a ese plazo, serán borrados del sistema. No obstante, en caso de que el Usuario desee que los datos de su cuenta sean eliminados de axakeralty.mx, podrá presentar una solicitud contactando a AXA, a través de los medios descritos en los presentes Términos y Condiciones; y dicha solicitud será atendida en el plazo de 5 (cinco) días hábiles.`,
        `No obstante, lo anterior, toda la información relacionada con el expediente clínico y demás registros médicos serán conservados en completa confidencialidad por AXA Keralty y posterior a los 5 (cinco) años que exige la Norma Oficial Mexicana respectiva podrán ser borrados de sus archivos.`,
        `AXA no se hace cargo de los gastos que generen el servicio de telecomunicaciones tal como el costo por el consumo de datos móviles, programas, software y hardware ni recomienda o promueve marca alguna de insumos tecnológicos.`
      ]
    },
    {
      section: 4,
      title: 'COMPROMISO DEL USUARIO',
      description: [
        `El Usuario se compromete a utilizar el Sitio Web y la Aplicación de buena fe, de forma diligente, lícita y adecuada, sin incurrir en actividades que puedan ser ilegales y/o que infrinjan los derechos de AXA Keralty o de terceros, no haciendo uso indebido o ilícito de la información ni de los contenidos existentes en el Sitio Web y/o la Aplicación.`,
        `Asimismo, el Usuario se compromete a no llevar a cabo de manera enunciativa, más no limitativa, cualquiera de las siguientes conductas:`,
        `%% a) Reproducir, copiar, distribuir cualquier contenido del Sitio Web y/o la Aplicación para cualquier fin de lucro sin autorización de AXA Keralty.`,
        `%% b) Transformar o modificar los contenidos del Sitio Web y/o la Aplicación.`,
        `%% c)	Suprimir, eludir o manipular el copyright y demás datos identificativos de los derechos de AXA Keralty o de sus titulares incorporados a los contenidos del Sitio Web y/o la Aplicación.`,
        `%% d)	Destruir, alterar, inutilizar o, de cualquier otra forma, dañar los datos, programas o documentos electrónicos del prestador de servicios o de terceros. Obstaculizar el acceso de otros Usuarios a Internet, así como realizar acciones que dañen, interrumpan o generen errores en el sistema de acceso.`,
        `%% e)	Introducir programas, virus o cualquier otro dispositivo que cause o sea susceptible de causar cualquier tipo de alteración o perjuicio en los sistemas informáticos de AXA Keralty o de terceros.`,
        `%% f)	Cualquier ataque o intento de alteración o manipulación al sistema informático o del Sitio Web y/o Aplicación. Es obligación del Usuario disponer de herramientas adecuadas para la detección y, en su caso supresión, de virus y cualquier otro elemento informático dañino de su dispositivo electrónico.`,
        `Es responsabilidad del Usuario la veracidad de los datos que proporcione, a través del Sitio Web y Aplicaciones. Los documentos e informaciones que el Usuario proporcione a o hacia AXA Keralty, directamente, de terceros que haya autorizado o a través de prestadores de servicios con motivo de las Operaciones y Servicios utilizando los Medios Electrónicos tendrán el mismo efecto y valor que los documentos e informaciones presentados o proporcionados de manera física e impresa; por lo que el Usuario acepta su responsabilidad por la autenticidad de los documentos y veracidad de la información contenida en ellos y el resto de las informaciones proporcionadas por estos Medios para, entre otros, la contratación de productos, reporte y gestión de siniestros, así como, en general, la realización de las Operaciones y Servicios por el Sitio Web y/o Aplicación. El Usuario acepta toda la responsabilidad de sus acciones y las consecuencias de las mismas.`,
        ``
      ]
    },
    {
      section: 5,
      title: 'CONDICIONES Y RESTRICCIONES DE USO DEL SITIO WEB Y APLICACIÓN',
      description: [
        `En el uso de los Medios Electrónicos, AXA proporcionará al Usuario, como evidencia de realización de la Operación y seguimiento de los Servicios evidencias como número de póliza contratada y número de endoso. En adición AXA enviará a la dirección de correo electrónico o número telefónico proporcionado por el Usuario al registrarse como usuario de los Medios Electrónicos, el comprobante de la Operación o Servicio realizado, tales como carátula de póliza, recibo de primas o endosos.`,
        `En razón de las medidas de seguridad electrónica y operativa que AXA ha determinado en beneficio y protección del Usuario, algunas de las operaciones y servicios podrán limitarse en el Uso de Medios Electrónicos, en cuyo caso el interesado deberá presentar su solicitud de trámite o servicio a través del agente de seguros o personal autorizado por AXA en sus diferentes oficinas de servicio.`,
        `AXA ha adoptado los niveles de seguridad y medios técnicos a su alcance para garantizar la máxima confidencialidad de la información y seguridad en las operaciones y prestación de Servicios y datos personales facilitados a través del uso de estas Sitio Web y Aplicación con objeto de evitar su pérdida, mal uso, alteración y acceso no autorizado; sin embargo, el Usuario reconoce que por los avances tecnológicos las medidas de seguridad pueden ser vulneradas, por lo cual AXA no garantiza que terceros no autorizados o actuando ilegítimamente puedan tener acceso a la información gestionada a través de los Medios Electrónicos.`,
        `AXA Keralty se reserva el derecho a modificar cualquier contenido y demás elementos o funcionalidades incluidas en el Sitio Web y Aplicación en cualquier momento y sin previo aviso.`,
        `AXA Keralty notificará al Usuario que existen actualizaciones del Sitio Web y/o Aplicación mediante un aviso, colocado en el Sitio Web y/o Aplicación o enviado al punto de contacto designado por el Usuario.`,
        `Si el Usuario no lleva a cabo las actualizaciones recomendadas trabajará bajo su propio riesgo con una versión no actualizada del Sitio Web y/o Aplicación puede llegar el momento en que ésta deje de funcionar por completo debido a que la versión instalada en el dispositivo del Usuario no sea la actualizada.`,
        `AXA Keralty no cubrirá el gasto que implique al Usuario obtener dichas actualizaciones (paquete de datos o el uso de internet) ya que esos cargos corren a cargo del Usuario con su proveedor de datos y/o de telefonía móvil. AXA Keralty no controla ni garantiza la ausencia de virus ni de otros elementos contenidos en los dispositivos electrónicos del Usuario, que puedan producir alteraciones en su equipo o en sus archivos y documentos electrónicos almacenados.`,
        `AXA Keralty no es responsable de las consecuencias eventuales que puedan afectar la cuenta del Usuario en el Sitio Web y Aplicación como resultado de un caso fortuito o de fuerza mayor.`,
        `AXA Keralty realiza sus mejores esfuerzos para mantener la continuidad y disponibilidad de los contenidos y servicios en el Sitio Web y Aplicación; sin embargo, no garantiza la disponibilidad y continuidad del funcionamiento de éstas, toda vez que ocasionalmente éstas podrán no estar disponibles temporalmente por motivos de mantenimiento o por otras causas. Cuando ello sea razonablemente posible, AXA Keralty advertirá de las interrupciones en el funcionamiento del Sitio Web y Aplicación.`,
        `AXA Keralty no asume ningún tipo de responsabilidad por errores, interrupciones, retrasos de funcionamiento o de transmisión, fallos de la línea de comunicaciones o alteración de las comunicaciones, derivado del funcionamiento de los dispositivos propiedad o en posesión del Usuario. Tampoco es responsable por causas que dependen de prestadores de servicios externos tales como proveedores de servicios de energía eléctrica, de internet o de telecomunicaciones`,
        `AXA no se responsabiliza de daños surgidos por la disfunción o una configuración incorrecta del entorno telefónico, informático o de software del acceso a internet, haciendo imposible el acceso al Sitio Web y Aplicación.`
      ]
    },
    {
      section: 6,
      title: 'RESPONSABILIDAD POR LOS ENLACES CONTENIDOS EN LA RED',
      description: [
        `AXA Keralty puede poner a disposición de los Usuarios enlaces tales como botones y herramientas de búsqueda que les permitan acceder a sitios web pertenecientes a y/o gestionados por terceros (en adelante, Sitios Enlazados), cuyo objeto es facilitar a los Usuarios la búsqueda y acceso a la información.`,
        `AXA Keralty no puede garantizar que los enlaces a otros lugares sean exactos en el momento de su acceso no pudiendo exigirse obligación alguna de actualización de los mismos a AXA Keralty.`,
        `AXA Keralty no garantiza ni asume ningún tipo de responsabilidad por los daños y perjuicios de toda clase que puedan deberse a: (a) el funcionamiento, disponibilidad, accesibilidad o continuidad de los Sitios Enlazados; (b) el mantenimiento de los servicios, contenidos, información, datos, archivos, productos y cualquier clase de material existente en los Sitios Enlazados; (c) la prestación o transmisión de los servicios, contenidos, información, datos, archivos, productos y cualquier clase de material existente en los Sitios Enlazados; (d) la calidad, licitud, fiabilidad y utilidad de los servicios, contenidos, información, datos, archivos, productos y cualquier clase de material existente en los Sitios Enlazados.`,
        `AXA Keralty no puede garantizar que los enlaces a otros lugares sean exactos en el momento de su acceso no pudiendo exigirse obligación alguna de actualización de los mismos a AXA Keralty. AXA Keralty no garantiza ni asume ningún tipo de responsabilidad por los daños y perjuicios de toda clase que puedan deberse a:`,
        `El Usuario, por tanto, debe extremar la prudencia en la valoración y utilización de los servicios, información, datos, archivos, productos y cualquier clase de material existente en los Sitios Enlazados. AXA Keralty notificará al Usuario, por avisos emergentes en las pantallas de navegación o algún otro medio, el redireccionamiento a esos Sitios Enlazados que sean estrictamente necesarios para proveer los Servicios u Operaciones correspondientes.`
      ]
    },
    {
      section: 7,
      title: 'RESPONSABILIDAD POR DAÑOS Y PERJUICIOS',
      description: [
        `Cuando el Usuario realice cualquier intento o ejecución de aplicación de programas o sistemas que con intención, accidente o error pretendan atacar o vulnerar o bien ataquen o vulneren el Sitio Web, podrá ser perseguido con las acciones legales pertinentes que a criterio de AXA Keralty sean suficientes, incluyendo penales, civiles y de cualquier otra naturaleza.`,
        `El uso del Sitio Web de modo distinto al específicamente autorizado en la presente Política de Uso, sin el previo consentimiento por escrito de AXA Keralty, queda estrictamente prohibido y podrá constituir una infracción de la legislación aplicable, incluyendo las leyes de marcas y derechos de autor. El Usuario responderá por los daños y perjuicios que pudiera ocasionar a AXA Keralty por el incumplimiento e inobservancia de la presente Política de Uso.`,
        `AXA Keralty se reserva el derecho de denegar o retirar el acceso al Sitio Web en cualquier momento a aquellos Usuarios que incumplan con la presente Política de Uso.`
      ]
    },
    {
      section: 8,
      title: 'UTILIZACIÓN DE COOKIES',
      description: [
        `Las cookies son archivos de texto que contienen pequeñas cantidades de información y que se descargan en los dispositivos electrónicos del Usuario cuando visite el Sitio Web. Después, las cookies, se envían de vuelta al Sitio Web de origen en cada una de las visitas subsiguientes (cookies de origen) o a otra página web que reconozca esa cookie (cookies de terceros). Las cookies son útiles porque hacen posible que una página web reconozca el dispositivo de un Usuario. Las cookies tienen muchas finalidades, como permitirte navegar entre páginas con eficiencia, recordar tus preferencias y, en general, mejorar tu experiencia como usuario.`,
        `Las cookies no pueden ejecutar ningún programa o virus. Las cookies de AXA Keralty no pueden leer datos de su disco duro.`,
        `El Sitio Web usa cookies con la intención de conocer el sistema operativo que utiliza, el navegador (Internet Explorer, Netscape, Opera, etc.), los sitios y los servicios a los que se accede y el tiempo que permanece en ellos, el proveedor de acceso a Internet y la ubicación geográfica en que se encuentra. Es imprescindible que consulte las opciones de internet de su navegador para configurar el funcionamiento y control de las cookies.`,
        `AXA Keralty reúne información acerca de la navegación de los Usuarios cuando estos navegan por el Sitio Web. En ningún caso la dirección IP de su ordenador será almacenada ni guardada (la dirección IP es un número que identifica a un dispositivo).`
      ]
    },
    {
      section: 9,
      title: 'AVISO DE PRIVACIDAD',
      description: [
        `AXA Salud, S.A. de C.V., con domicilio en Félix Cuevas No. 366, Piso 3, Colonia Tlacoquemécatl, C.P. 03200,
        Alcaldía Benito Juárez, Ciudad de México, México, llevará a cabo el 
        tratamiento de sus datos personales para la promoción y 
        comercialización de seguros a través del Sitio Web, así 
        como para las demás finalidades previstas en el aviso de 
        privacidad integral que puede consultar en axa.mx en la 
        sección Aviso de Privacidad. Keralty México, S.A. de C.V., 
        con domicilio en Baja California No. 200, Colonia Roma Sur, 
        Alcaldía Cuauhtémoc, C.P. 06760, Ciudad de México, llevará 
        a cabo el tratamiento de sus datos personales para la 
        promoción y prestación de los servicios de salud ofrecidos 
        a través del Sitio Web, así como para las demás finalidades 
        previstas en el aviso de privacidad integral que puede consultar 
        en el Sitio Web, en la sección Aviso de Privacidad.`
      ]
    },
    {
      section: 10,
      title: 'LEY APLICABLE Y JURISDICCIÓN COMPETENTE',
      description: [
        `Para cualquier controversia o cuestión que pudiera 
        derivarse por el uso del Sitio Web, o por la interpretación 
        de la presente Política de Uso, el Usuario y AXA Keralty 
        aplicarán los principios de la buena fe y se regirán por 
        las leyes vigentes en la República Mexicana. En caso de 
        conflicto, el Usuario y AXA Keralty se someten expresamente 
        a la jurisdicción y competencia de los tribunales del fuero 
        común en la Ciudad de México, renunciando a cualquier otro 
        fuero que en razón de sus domicilios presentes o futuros o 
        por cualquier otra causa, pudiera corresponderles. El presente 
        texto se pone a disposición de los Usuarios para ser consultado 
        en cualquier momento.`
      ]
    },
    
];