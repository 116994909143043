<div class="container modal-general">
  <div class="row no-gutters">
    <!-- ICONO -->
    <!-- MENSAJE  -->
    <div class="col-12  py-3 mt-5">
      <p class="txt_style text-center fs-12 pb-0">
        AXA Keralty se refiere a la alianza entre AXA Salud, S.A. de C.V., como
encargada del aseguramiento y Keralty México, S.A. de C.V. como responsable de los servicios
médicos.
      </p>
    </div>
    <!-- BOTÓN -->
    <div class="col-12 text-center mb-5 mt-4">
      <button class="button" (click)="actionButton()" id="btn_modal_acept">
        Aceptar
      </button>
    </div>
  </div>
</div>
