<div class="container">
  <div class="row">
    <div class="img__wrapper">
    </div>
  </div>

  <div class="d-flex flex-row justify-content-center my-4">
    <div class="col-lg-6 col-md-9 col-sm-12 px-md-5 px-sm-3">
      <h1 class="text-center heading-2">Bolsa de trabajo</h1>
      <div class="row">
        <div class="col-12">
          <form [formGroup]="jobExchangeForm" (ngSubmit)="jobFormSubmit()" class="custom-axa-form job-exchange-form">
            <div class="form-group">
              <input
                class=" placeholder-person"
                type="text"
                formControlName="name"
                aria-placeholder="Nombre"
                placeholder="Nombre"
                maxlength="85"
                               (blur)="jobExchangeForm.get('name').value ? nameBlur = true : nameBlur = false"
                               [ngClass]="jobExchangeForm.get('name').hasError('pattern') && nameBlur ? 'input-error' : ''"
              />
            </div>
            <div class="form-group responsive-input-wrapper">
              <input
                class=" mr-lg-2 placeholder-person"
                type="text"
                formControlName="fatherLastname"
                aria-placeholder="Apellido paterno"
                placeholder="Apellido paterno"
                (blur)="jobExchangeForm.get('fatherLastname').value ? lastNameBlur = true : lastNameBlur = false"
                        [ngClass]="jobExchangeForm.get('fatherLastname').hasError('pattern') && lastNameBlur ? 'input-error' : ''"
              />
              <input
                class=" ml-lg-2 mt-3 mt-lg-0 placeholder-person"
                type="text"
                formControlName="motherLastname"
                aria-placeholder="Apellido materno"
                placeholder="Apellido materno"
                (blur)="jobExchangeForm.get('motherLastname').value ? lastNameBlur = true : lastNameBlur = false"
                [ngClass]="jobExchangeForm.get('motherLastname').hasError('pattern') && lastNameBlur ? 'input-error' : ''"
              />
            </div>
            <div class="form-group d-flex w-100"></div>

            <div class="form-group radio-wrapper">
              <label class="radio_label">Sexo</label>
              <label class="radio_label font-weight-normal check-container">
                <input class="radio" type="radio" formControlName="gender" [value]="GENDERS.MALE" /> Hombre
                <span class="checkmark-radio"></span>
              </label>
              <label class="radio_label font-weight-normal check-container">
                <input class="radio" type="radio" formControlName="gender" [value]="GENDERS.FEMALE" /> Mujer
                <span class="checkmark-radio"></span>
              </label>
            </div>
            <div class="form-group">
              <input
                class="placeholder-email"
                type="text"
                formControlName="email"
                aria-placeholder="Correo electrónico"
                placeholder="Correo electrónico"
                [ngClass]="jobExchangeForm.get('email').hasError('pattern') && emailBlur ? 'input-error' : ''"
                (blur)=" jobExchangeForm.get('email').value ? emailBlur = true : emailBlur = false"
              />
            </div>
            <div class="form-group">
              <input
                class="placeholder-phone"
                type="text"
                formControlName="phone"
                aria-placeholder="Teléfono"
                placeholder="Teléfono"
                [ngClass]="jobExchangeForm.get('phone').invalid && phoneBlur ? 'input-error' : ''"
                (blur)=" jobExchangeForm.get('phone').value ? phoneBlur = true : phoneBlur = false"
              />
            </div>
            <div class="form-group select-wrapper" placement="top">
                <select (change)="changeCategory()" formControlName="category" placeholder="Categoría" class="select-text form-control placeholder-options fixedSelected placeholder-category-input">
                  <option class="select-text d-none" value="" disabled selected>Categoría</option>
                  <option *ngFor="let s of JOB_EXCHANGE_CATEGORIES" class="select-text" [value]="s.id">{{s.name}}</option>
                  </select>     
            </div>
            <div class="form-group" *ngIf="jobExchangeForm.get('category').value === 'PROFESSIONAL'">
              <input
                class="text-uppercase placeholder-file"
                type="text"
                formControlName="professionalId"
                aria-placeholder="Cédula profesional"
                placeholder="Cédula profesional"
                [maxLength]="PROFESSIONAL_ID_LENGTH"
                mask="00000000"
              />
            </div>
            <div class="form-group">
              <input
                class="placeholder-calendar"
                type="text"
                formControlName="professionalExperience"
                aria-placeholder="Años de experiencia laboral"
                placeholder="Años de experiencia laboral"
                [ngClass]="jobExchangeForm.get('professionalExperience').invalid && phoneBlur ? 'input-error' : ''"
                (blur)=" jobExchangeForm.get('professionalExperience').value ? phoneBlur = true : phoneBlur = false"
                maxlength="2"
              />
            </div>
            <div class="form-group d-flex justify-content-between">
              <input
                class="mr-2 w-50 placeholder-file cursor-pointer"
                type="text"
                formControlName="dummyFile"
                aria-placeholder="Carga tu CV"
                placeholder="Carga tu CV"
                readonly
              />
              <input
                #fileInput
                hidden
                type="file"
                accept="application/pdf"
                (change)="selectedFile($event)"
              />
              <button class="button ml-2 cv__button" type="button" (click)="openFilesExplorer()">Examinar</button>
            </div>
            <p class="warning-text" *ngIf="showWarning" (click)="scrollTop('.ng-invalid')">
              * Por favor complete todos los campos correctamente
            </p>
            <!-- Aviso de privacidad simplificado -->
            <p class="privacy-disclaimer mt-2" [innerHTML]="PRIVACY_NOTICE"></p>
            <!-- Checkboxes para aviso de privacidad y terminos/condiciones -->
            <div class="form-group mb-0 reduced_height check-container cursor-pointer">
              <label>
                <input class="check" type="checkbox" formControlName="privacyNotice" />
                <span class="checkmark"></span>
                <a [href]="PRIVACY_NOTICE_URL" target="_blank" rel="noopener noreferrer">
                  Acepto el aviso de privacidad
                </a>
              </label>
            </div>
            <div class="form-group mb-2 check-container cursor-pointer">
              <label>
                <input class="check" type="checkbox" formControlName="termsAndConditions" />
                <span class="checkmark"></span>
                <a [href]="TERMS_CONDITIONS_URL" target="_blank" rel="noopener noreferrer"
                  >Acepto los términos y condiciones del servicio</a
                >
              </label>
            </div>
            <!-- Botones para formulario -->
            <div class="form-group text-center mt-4">
              <div class="row mt-2 mb-3">
                <div class="col-6">
                  <button class="button reset-button w-100" type="button" (click)="resetForm()">
                    Limpiar formulario
                  </button>
                </div>
                <div class="col-6">
                  <p *ngIf="sendingForm">Enviando ...</p>
                  <button *ngIf="!sendingForm" class="button w-100" type="submit" [disabled]="!jobExchangeForm.valid" (click)="sendTag()">
                    Enviar
                  </button>

                </div>
                <div class="col-12 mt-4 text-center">
                  <button class="button w-100" type="button" (click)="goToVacants()">
                    Conoce nuestras Vacantes
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
