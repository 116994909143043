import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/** Librería para guardar archivos */
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { ModalService } from './modal/modal.service';
import * as saveFile from 'file-saver';

/**
 * Generador de plantillas
 *
 * @export
 * @class PdfService
 */
@Injectable({
  providedIn: 'root'
})
export class PdfService {
  /**
   * Crea una instancia del servicio
   * @param {HttpClient} http
   * @param quoteService
   * @param loader
   * @param modal
   * @param router
   * @memberof PdfService
   */
  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private modal: ModalService,
    private router: Router
  ) {}

  async generateTemplateWithBody(
    template: any,
    data: any = {},
    loader = true,
    autoPrint?: boolean
  ) {
    try {
      if (loader) {
        this.loader.star();
      }

      const templatesEndPoint = environment.templateURL;

      const headers: HttpHeaders = new HttpHeaders({
        'Contet-Type': 'application/json',
        'Template-Type': String(template)
      });


      return this.http
        .post(templatesEndPoint, data, {
          responseType: 'arraybuffer',
          headers
        })
        .subscribe(
          res => {
            let filename;
            if (template === 'CERTIFICADOS'){
              filename = `${template}-${(data.certificateNumber)}.pdf`;
            } else if (template === 'COTIZACION') {
              filename = `${template}-${(data.idQuote)}.pdf`;
            } else {
              filename = `${template}.pdf`;
            }

            // Se abre el PDF en otra ventana
            const pdf = new Blob([res], { type: 'application/pdf' });
            const pdfURL = URL.createObjectURL(pdf);

            if (autoPrint) {
              try {
                const winPrint = window.open(pdfURL);
                winPrint.focus();
                setTimeout(() => winPrint.print(), 500);
              } catch (e) {
                console.error(e);
              }
            } else {
              // SE GUARDA EL ARCHIVO A PDF
              saveFile.saveAs(new Blob([res]), filename);
            }
            this.loader.stop();
          },
          () => {
            this.loader.stop();
            this.modal.errorModal('Ha faltado algún dato del formulario.');
          }
        );
    } catch (error) {
      this.loader.stop();
      this.modal.errorModal('Ha faltado algún dato del formulario.');
    }
  }


  /**
   * Transforma blob(ArrayBuffer) en base64 para cañon de correos
   * @param buffer
   */
  _arrayBufferToBase64(buffer: any) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }




	/**
	 * Get Certificate Data
	 *
	 * @param {string} policyNumber
	 * @returns
	 * @memberof AzureService
	 */
	getCertificateData(policyNumber: string) {
		return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/file/certificates_data', { id: policyNumber }).pipe(
			map((response: any) => {
				console.log('Certificate Data (Response) => ', response);
				const data: any = response;
				return data;
			})
		);
	}

  /**
   * Transforma string base64 y retorna Blob para mostrar archivos guardados en MONGO
   * @param base64Data
   * @param contentType
   * @param name
   */
  base64toBlob(base64Data: string, contentType: string, name?: string) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);
    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
