import {Component, EventEmitter} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Modal General para presentar información
 * @export
 * @class ModalGeneralComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent {
  /** Icono a presentar arriba de título */
  modalIcon: string;
  /** Titulo de modal */
  modalTitle: string;
  /** Información a presentar en modal */
  modalMessage: string;
  /** Suscripción para cerrar modal */
  button = new EventEmitter<boolean>();
  /** Texto en botón de modal */
  btnText = 'Aceptar';
  constructor(
    private modalService: NgbModal,
  ) { }
  /**
   * Acción de botón
   * @memberof ModalGeneralComponent
   */
  actionButton() {
    this.button.next(true);
  }
}
