/**
 * Const SERVICES
 * CONSTANTES DE SERVICIOS A OFRECER
 */
export const SERVICES = [
    {
        "rates": {
            "noPrice": "Consulta y paga tu saldo pendiente"
        },
        "image": "assets/img/1-saldo-pendiente.webp",
        "_id": "5f31657dbc5af6f33e122e6f",
        "segment": "Servicio",
        "type": "/pay-policy",
        "name": "Saldo pendiente",
        "description": "Pólizas pendiente de pago",
        "createdAt": "2020-08-10T15:19:25.535Z",
        "updatedAt": "2020-08-10T15:19:25.536Z",
        "buttonDescription": "Pagar",
        "redirect": true,
        states: null,
    },
    {
        "rates": {
            "externo": 413.79,
            "planmed": 301.72,
            //"gmm": 302.00
        },
        "image": "assets/img/2-medicina-general.webp",
        "_id": "5f31657dbc5af6f33e122e6f",
        "segment": "Servicio",
        "type": "medicina-general",
        "name": "Medicina general",
        "description": "Presencial/Videoconsulta",
        "createdAt": "2020-08-10T15:19:25.535Z",
        "updatedAt": "2020-08-10T15:19:25.536Z",
        states: null,
    },
    {
        "rates": {
            "externo": 517.24,
            "planmed": 362.065,
            //"gmm": 362.00
        },
        "image": "assets/img/6-nutricion.webp",
        "_id": "5f3178e19be159f36f313a37",
        "segment": "Servicio",
        "type": "nutricion",
        "name": "Nutrición",
        "description": "Presencial/Videoconsulta",
        "createdAt": "2020-08-10T16:42:09.338Z",
        "updatedAt": "2020-08-10T16:42:09.338Z",
        states: null,
    },
    {
        "rates": {
            "externo": 818.96,
            "planmed": 543.1,
            //"gmm": 543.00
        },
        "image": "assets/img/3-pediatria.webp",
        "_id": "5f3178cf9be159f36f313a36",
        "segment": "Servicio",
        "type": "pediatria",
        "name": "Pediatría",
        "description": "Presencial/Videoconsulta",
        "createdAt": "2020-08-10T16:41:51.754Z",
        "updatedAt": "2020-08-10T16:41:51.754Z",
        states: null,
    },
    {
        "rates": {
            "externo": 732.76,
            "planmed": 543.1,
            //"gmm": 466.00
        },
        "image": "assets/img/9-ginecologia.webp",
        "_id": "5f3178f39be159f36f313a38",
        "segment": "Servicio",
        "type": "ginecologia",
        "name": "Ginecología",
        "description": "Presencial/Videoconsulta",
        "createdAt": "2020-08-10T16:42:27.185Z",
        "updatedAt": "2020-08-10T16:42:27.185Z",
        states: null,
    },
    {
        "rates": {
            "externo": 1551.72,
            "planmed": 1396.55,
            //"gmm": 466.00
        },
        "image": "assets/img/10-colposcopia.webp",
        "_id": "5f3178f39be159f36f313a38",
        "segment": "Servicio",
        "type": "colposcopia",
        "name": "Colposcopia",
        "description": "Procedimiento en clínica",
        "createdAt": "2020-08-10T16:42:27.185Z",
        "updatedAt": "2020-08-10T16:42:27.185Z",
        states: null,
    },
    {
        "rates": {
            "externo": 758.62,
            "planmed": 560.34,
            //"gmm": 466.00
        },
        "image": "assets/img/8-medicina-interna.webp",
        "_id": "5f3178f39be159f36f313a38",
        "segment": "Servicio",
        "type": "medicina-interna",
        "name": "Medicina interna",
        "description": "Presencial/Videoconsulta",
        "createdAt": "2020-08-10T16:42:27.185Z",
        "updatedAt": "2020-08-10T16:42:27.185Z",
        states: ['DF','MC','SR'],
    },
    {
        "rates": {
            "externo": 629.31,
            "planmed": 465.51,
            //"gmm": 466.00
        },
        "image": "assets/img/7-psicologia.webp",
        "_id": "5f3178f39be159f36f313a38",
        "segment": "Servicio",
        "type": "psicologia",
        "name": "Psicología",
        "description": "Presencial/Videoconsulta",
        "createdAt": "2020-08-10T16:42:27.185Z",
        "updatedAt": "2020-08-10T16:42:27.185Z",
        states: null,
    },
    {
        "rates": {
            "externo": 629.31,
            "planmed": 465.51,
            //"gmm": 466.00
        },
        "image": "assets/img/12-terapia-fisica.webp",
        "_id": "5f3178f39be159f36f313a38",
        "segment": "Servicio",
        "type": "terapia-fisica",
        "name": "Terapia física",
        "description": "Presencial en clínica",
        "createdAt": "2020-08-10T16:42:27.185Z",
        "updatedAt": "2020-08-10T16:42:27.185Z",
        states: null,
    },
    {
        "rates": {
            "externo": 677.58,
            "planmed": 521.55,
            //"gmm": 466.00
        },
        "image": "assets/img/11-radiografia.webp",
        "_id": "5f3178f39be159f36f313a38",
        "segment": "Servicio",
        "type": "radiografia",
        "name": "Radiografía tórax PA y lateral",
        "description": "Presencial en clínica",
        "createdAt": "2020-08-10T16:42:27.185Z",
        "updatedAt": "2020-08-10T16:42:27.185Z",
        states: ['DF','MC','NL','QT'],
    },
    {
        "rates": {
            "externo": 2154.31,
            "planmed": 2154.31,
            //"flex": 2154.32,
        },
        "image": "assets/img/4-prueba-PCR.webp",
        "_id": "5f3178e19be159f36f313a37",
        "segment": "Servicio",
        "type": "prueba-covid",
        "name": "Prueba COVID-19 PCR",
        "description": "Estudio de laboratorio PCR-RT-SARS-COV2",
        "createdAt": "2020-08-10T16:42:09.338Z",
        "updatedAt": "2020-08-10T16:42:09.338Z",
        states: null,
    },
    {
        "rates": {
            "externo": 343.96,
            "planmed": 318.1,
            //"flex": 343.97
        },
        "image": "assets/img/5-prueba-antígeno.webp",
        "_id": "5f3178e19be159f36f313a37",
        "segment": "Servicio",
        "type": "prueba-antigenos",
        "name": "Prueba antígeno COVID-19",
        "description": "Estudio de laboratorio",
        "createdAt": "2020-08-10T16:42:09.338Z",
        "updatedAt": "2020-08-10T16:42:09.338Z",
        states: null,
    },

];
// [
//     {   
//         image: '',
//         name: 'Medicina general',
//         type: 'Servicio',
//         price: '$400.00'
//     },
//     {   
//         image: '',
//         name: 'Pediatría',
//         type: 'Servicio',
//         price: '$750.00'
//     },
//     {   
//         image: '',
//         name: 'Nutrición',
//         type: 'Videoconsulta',
//         price: '$600.00'
//     },
//     {   
//         image: '',
//         name: 'Psicología',
//         type: 'Videoconsulta',
//         price: '$600.00'
//     },
// ];
  
