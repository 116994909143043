import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DocumentTypeService {

    documents = [
        {
            idPortal: 1,
            idBH: 20,
            shortName: "CU",
            name: "CURP",
        },
        {
            idPortal: 2,
            idBH: 21,
            shortName: "IT",
            name: "CURP"
        },
        {
            idPortal: 6,
            shortName: "ND",
            name: "No identificado",
        },
        {
            idPortal: 7,
            shortName: "OT",
            name: "OTRO",
        },
        {
            idPortal: 8,
            idBH: 25,
            shortName: "PA",
            name: "Pasaporte",
        },
        {
            idPortal: 9,
            idBH: 26,
            shortName: "IE",
            name: "Tarjeta de identificación extranjero",
        },
        {
            idPortal: 10,
            idBH: 22,
            shortName: "RF",
            name: "RFC",
        },
        {
            idPortal: 3,
            idBH: 24,
            shortName: "ME",
            name: "Menor sin identificacion",
        },
        {
            idPortal: 11,
            idBH: 23,
            shortName: "TI",
            name: "TIN",
        }
    ]

    convertPortalToBhId(portalId)
    {
        for(let i=0;i<this.documents.length;i++)
        {
            if(this.documents[i].idPortal == portalId)
            {
                if(this.documents[i].idBH)
                {
                    return this.documents[i].idBH;
                }
                else
                {
                    return portalId;
                }
            }
        }
        return portalId;
    }

}