import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { RenovationPolicyService } from 'src/app/services/renovation-policy/renovationPolicy.service';
/** import LoaderService*/
import { LoaderService } from 'src/app/sharedv2/loader/loader.service';
/** import ModalService*/
import { ModalService } from 'src/app/services/modal/modal.service-v2';
import { RecaptchaService } from 'src/app/services/recaptcha/recaptcha.service';
import { concatMap, filter, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
// INTERFACES
import { IReCaptcha } from '../../../interfaces/recaptcha.interface';
import { IPolicyStatusRes } from 'src/app/interfaces';
// CONSTANTS
import { DOMICILED } from 'src/app/constants';

@Component({
  selector: 'app-search-policy',
  templateUrl: './search-policy.component.html',
  styleUrls: ['./search-policy.component.scss']
})
export class SearchPolicyComponent implements OnInit, OnDestroy {

  searchForm: UntypedFormGroup;
  policyNotfound : boolean = false;
  errorToken : boolean = false;
  subscribedToken : boolean = false;
  subscriptionTOken : any = null;

  @Output() sendFindPolicy = new EventEmitter<any>()

  constructor(
    private fb: UntypedFormBuilder,
    private renovationPolicyService : RenovationPolicyService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    private reCaptcha: RecaptchaService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.reCaptcha.tokenGenerate(); 
  }

  /**
   * Inicializar formulario y validaciones
   */
  initForm(){
    this.searchForm = this.fb.group({
      policy: new UntypedFormControl('', { validators: [Validators.required]})
    });
  }
  
  /**
   * Búsqueda de póliza para obtener estado de pago
   */
  findPolicy() {
    console.log('SEARCH POLICY: GET SUBS TOKEN ===> ', this.subscribedToken);
    this.loaderService.start();
    // Validación de Token
    if(!this.subscribedToken) {
      this.subscriptionTOken = this.reCaptcha.token
      .pipe(
        // Validar que el token no venga vacío
        filter(validToken => validToken !== undefined),
      )
      .subscribe((token: any) => this.http
        .post(`https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/captcha`, {
          token: token,
          V3: true
        })
        .pipe(
          filter((response: IReCaptcha) => {
            const success: boolean = response.success;
            // Validar token captcha
            if(!success) {
              this.modalService.errorModal("Ha ocurrido un error; favor de intentarlo más tarde.");
              this.loaderService.stop();
            }
            return success;
          }),
          concatMap(() => this.findPolicyCheckedCaptcha())
        )
        .subscribe(() => this.loaderService.stop())
      );
      this.subscribedToken = true;
    }

    this.reCaptcha.tokenGenerate();

  }

  /**
   * Consultar póliza para estatus de pago
   */
   findPolicyCheckedCaptcha() {
    console.log('SEARCH POLICY: POLICY STATUS');
    // Búsqueda de póliza
    return this.renovationPolicyService.getPolicyStatus(this.searchForm.value.policy)
    .pipe(
      filter((response : IPolicyStatusRes) => {
        const existsError: boolean = response.data.hasOwnProperty('status');
        // Validar existencia de algún error en la consulta
        if(existsError) {
          this.policyNotfound = existsError;
          this.loaderService.stop();
        }
        return !existsError;
      }),
      filter((response : IPolicyStatusRes) => {
        let allowPayment: boolean;
        // Identificar pólizas domiciliadas
        const domiciled: boolean = response.data.CartaAutorizacion === 'SI';
        // Validar domiciliación
        if(domiciled) {
          console.log('SEARCH POLICY: DOMICILIATED ===> ');
          // Identificar existencia de reintentos
          if(response.data.hasOwnProperty('reintentoDebito')) {
            console.log('SEARCH POLICY: RETRY ===> ');
            const retries: number = response.data.reintentoDebito;
            const status: string = response.data.estadoDebito;
            // Validar estados de domiciliación
            switch(true) {
              case retries <= 2 && [DOMICILED.GENERATED, DOMICILED.APPROVED, DOMICILED.REJECTED].includes(status) :
              case retries == 3 && [DOMICILED.GENERATED, DOMICILED.APPROVED].includes(status) :
                this.showModalDomiciled();
                allowPayment = false;
                break;
              case retries == 3 && status == DOMICILED.REJECTED :
              case retries >= 4 && [DOMICILED.GENERATED, DOMICILED.APPROVED, DOMICILED.REJECTED].includes(status) :
                allowPayment = true;
                break;
            }
          } else {
            this.showModalDomiciled();
            allowPayment = false;
          }
        } else {
          allowPayment = true;
        }
        this.loaderService.stop();
        console.log('SEARCH POLICY: ALLOY PAYMENT ===> ', allowPayment);
        return allowPayment;
      }),
      map((response : IPolicyStatusRes) => this.sendFindPolicy.emit(response.data))
    );
  }

  /**
   * Mostrar modal de póliza domiciliada
   */
  showModalDomiciled() {
    this.modalService.errorModal(
      'Tu póliza se encuentra domiciliada, por favor, espera el cobro por parte de tu banco.',
      'Tu póliza está domiciliada',
      {icon: 'axa-TDC', family: 'axa-icon'}
    );    
  }

  ngOnDestroy(): void{
    if(this.subscriptionTOken) {
      this.subscriptionTOken.unsubscribe();
    }
  }

}
