import {Component, OnInit} from '@angular/core';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { IUserData } from 'src/app/interfaces/data-user/data-user.interface';
import { Router } from '@angular/router';
import { ConsultService } from 'src/app/services/consult.service';
import { SERVICES } from 'src/app/constants/services.constants';
import { STATES_SERVICES } from 'src/app/constants/states/states-services.const';
import { state } from '@angular/animations';
import { HeaderService } from '../../../shared/header/header.service';
/**
 * Componente para Datos de usuario
 */
@Component({
  selector: 'landing-payment',
  templateUrl: './landing-payment.component.html',
  styleUrls: ['./landing-payment.component.scss']
})
export class LandingPaymentComponent implements OnInit {
  
GENERAL_SERVICES = SERVICES;
ORIGINAL_SERVICES = SERVICES;
searchVideo = new UntypedFormControl('');
  http: any;
  stateSelected: any;

states_services = STATES_SERVICES;
services_filters = SERVICES;
serviceSelected = null;
showInitialModal = true;
changeByRefresh: boolean = false;

iva = 0.16;

constructor(
    private userInfo: ConsultService,
    private headerService: HeaderService,
    private router: Router
  ) {}
  /** Hook de inicialización de componente */
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.stateSelected = STATES_SERVICES[0].value;
    this.filter();
    this.headerService.changedByRefresh.subscribe(
      (response : boolean) => {
        this.changeByRefresh = response;
      }
    )
  }

  goToMap(cam) {
    localStorage.setItem('showMap', 'true');
    localStorage.setItem('selectedCAM', cam);
  }
  /**
   * Funcion que guarda el tipo de servicio
   * @param service 
   */
  selectService(service:any, redirect?) {

    if(redirect){
      this.router.navigate([service]);
      return;
    }
    
    this.userInfo.typeService.next(service);
    this.userInfo.stateService.next(this.stateSelected);
    let consultRate = this.GENERAL_SERVICES.filter(consult => consult.type === service);
    // console.log("consultRate", consultRate);
    
    this.userInfo.rateConsult.next(consultRate[0]);
    this.router.navigate(['/servicios-pago']);
  }

  redirectHome() {
    this.router.navigate(['/home']);
  }


  logEvent(event: any) {
    if(event === '') {
      this.GENERAL_SERVICES = this.ORIGINAL_SERVICES;
    }
    
    // let copy_GENERAL_SERVICES = this.GENERAL_SERVICES;

    let newEvent = event.normalize('NFD')
    .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2")
    .normalize();

    this.GENERAL_SERVICES = this.ORIGINAL_SERVICES.filter(function(tag) {

      let newTagName = (tag.name).normalize('NFD')
      .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2")
      .normalize();
      
      return newTagName.toLowerCase().indexOf(newEvent.toLowerCase()) >= 0;
  });
  }

  filterByState()
  {
    this.serviceSelected = null;
    this.filter();
  }

  filter()
  {
    if(this.serviceSelected === null)
    {
      /*let stateSelected = STATES_SERVICES.filter(state => {
        return state.value = this.stateSelected;
      })/** */
      //this.stateSelected = stateSelected ? stateSelected : null;
      this.getIva();
      this.GENERAL_SERVICES = this.ORIGINAL_SERVICES.filter( service => {
        return (service.states && service.states.includes(this.stateSelected) || service.states == null);
      });
      this.services_filters = this.GENERAL_SERVICES;
    }
    else
    {
      this.GENERAL_SERVICES = this.ORIGINAL_SERVICES.filter( service => {
        return (service.type == this.serviceSelected);
      });
    }
    
  }

  getIva()
  {
    for(let i=0;i<this.states_services.length;i++)
    {
      if(this.stateSelected == this.states_services[i].value)
      {
        this.iva = this.states_services[i].iva;
      }
    }
  }

  filterModal()
  {
    this.filter()
  }

  removeServiceSelected()
  {
    this.serviceSelected = null;
    this.showInitialModal = false;
    this.filter();
  }

  closeInitialModal(){
    this.stateSelected = STATES_SERVICES[0].value;
    this.showInitialModal = false;
  }

  acceptFirstState()
  {
    this.showInitialModal = false;

  }

  goHome()
  {
    this.router.navigate(['/']);
  }

  roundCeil(num : number)
  {
    //return Math.ceil( num * (1+this.iva));
    var factor = Math.pow(10,2);
    return (Math.ceil(num* (1+this.iva)*factor) / factor).toFixed(2)
  }

}
