/**
 * CONSTANTES
 * @description Indexar constantes para componentes.
 */

/** MAPA DE GOOGLE */
export * from './map.constant';
/** CÓMO FUNCIONA */
export * from './works.constant';
/** ESTADOS DE LA REPÚBLICA */
export * from './states.constant';
/** CONSTANTES PARA CUESTIONARIO DE EDAD METABÓLICA */
export * from './metabolic-age.constant';
/** CONSTANTES COMUNES PARA FORMULARIOS */
export * from './forms.constant';
/** PATRONES PARA MÁSCARAS DE INPUT PESONALIZADAS */
export * from './masks.constant';
/** CATALOGO DE SERVICIOS */
export * from './catalog.constant';
/** ESTATUS DE PAGOS Y DOMICILIACIONES */
export * from './payment.const';
/** ARREGLO DE SERVICIOS */
export * from './services.constants';
/** ARREGLO DE BANNERS */
export * from './banner.constant';
/** PLANES DE SERVICIOS Y COBERTURAS */
export * from './coverages.constants';
