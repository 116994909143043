import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
/** import ReCaptcha2Component*/
/** import DOCUMENT*/
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
/** import Component, ElementRef, Inject, OnInit, ViewChild*/
import { Component, ElementRef, Inject, OnInit, ViewChild, PLATFORM_ID } from '@angular/core';
/** import FormControl, FormGroup, Validator*/
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
/** import PageScrollService*/
import { PageScrollService } from 'ngx-page-scroll-core';
/** import DatePipe*/
import { DatePipe } from '@angular/common';
/** CONSTANTS */
import {
  JOB_EXCHANGE_CATEGORIES,
  MASK_PATTERNS,
  PHONE_NUMBER_LENGTH,
  PRIVACY_NOTICE_URL,
  PROFESSIONAL_ID_LENGTH,
  SIMPLIFIED_PRIVACY_NOTICE,
  SUCCESS_MESSAGE,
  TERMS_CONDITIONS_URL
} from '../../constants';
/** import JobApplicationCategories, IJobExchangeFormInterface*/
import { EJobApplicationCategories, IJobExchangeFormInterface } from '../../interfaces/job-exchange.interface';
/** import EGenders*/
import { EGenders } from '../../interfaces/metabolic-age.interface';
/** import SERVICES JobExchangeService */
import { JobExchangeService } from '../../services/job-exchange/job-exchange.service';
/** import SERVICES EmailService*/
import { EmailService } from "../../services/email/email.service";
/** import ModalService*/
import { ModalService } from 'src/app/services/modal/modal.service';
/** import LoaderService*/
import { LoaderService } from 'src/app/services/loader/loader.service';
/** Import captcha service */
import { RecaptchaService } from 'src/app/services/recaptcha/recaptcha.service';
import { IEventGTM } from 'src/app/interfaces/gtmEvent.interface';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Componente para formulario de BOLSA DE TRABAJO
 */
@Component({
  selector: 'app-job-exchange',
  templateUrl: './job-exchange.component.html',
  styleUrls: ['./job-exchange.component.scss']
})
export class JobExchangeComponent implements OnInit {
  /** Input de entidad de nacimiento */
  @ViewChild('category') category: ElementRef;
  /** Input de optionCategory*/
  @ViewChild('optionCategory') optionCategory: ElementRef;
  /** Input de archivo */
  @ViewChild('fileInput') fileInput: ElementRef;
  /** Input de archivo */
  // @ViewChild('captchaElement') captchaElement: ReCaptcha2Component;
  /** Constante número telefónico */
  PHONE_NUMBER_LENGTH = PHONE_NUMBER_LENGTH;
  /** Constante de longitud para cédula profesional */
  PROFESSIONAL_ID_LENGTH = PROFESSIONAL_ID_LENGTH;
  /** URL para AVISO DE PRIVACIDAD */
  PRIVACY_NOTICE_URL = PRIVACY_NOTICE_URL;
  /** Aviso de privacidad simplificado */
  PRIVACY_NOTICE = SIMPLIFIED_PRIVACY_NOTICE.JOB_EXCHANGE;
  /** URL para TERMINOS Y CONDICIONES */
  TERMS_CONDITIONS_URL = TERMS_CONDITIONS_URL;
  /** Constante con categorias para el formulario */
  JOB_EXCHANGE_CATEGORIES = JOB_EXCHANGE_CATEGORIES;
  /** Mensaje de éxito a mostrar en modal */
  SUCCESS_MESSAGE = SUCCESS_MESSAGE.JOB_EXCHANGE;
  /** Géneros */
  GENDERS = EGenders;
  /**Contador modal error */
  countModal= 0;
  /** Máscara de texto para nombres */
  NAME_PATTERN = MASK_PATTERNS.NAME_PATTERN;
  /** Formulario de BOLSA DE TRABAJO */
  jobExchangeForm: UntypedFormGroup;
  /** Identificador de la categoría seleccionada */
  categoryId: EJobApplicationCategories = undefined;
  /** Valores disponibles para categorias de la solicitud */
  OPTIONS = EJobApplicationCategories;
  /** Bandera utilizada para mostrar error de formulario incompleto */
  showWarning: boolean;
  /** Variable para URL de Vacantes completa */
  fullURL: string;
  /** Variable nameBlur para validación input*/
  nameBlur: boolean;
  /** Variable emailBlur para validación input*/
  emailBlur: boolean;
  /** Variable lastNameBlur para validación input*/
  lastNameBlur: boolean;
  /** Variable lastNameSecondBlur para validación input*/
  lastNameSecondBlur: boolean;
  /** Variable phoneBlur para validación input*/
  phoneBlur: boolean;
  /*** Bnadera de envio de formulario */
  sendingForm = false;
  /** APP Environment */
  env: string = 'dev';
  
  clientID: string;

  /**
   * constructor
   * @param pageScrollService 
   * @param document 
   * @param formService 
   * @param emailService 
   */
  constructor(
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private formService: JobExchangeService,
    private emailService: EmailService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    public captchaService: RecaptchaService,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {
    if (isPlatformBrowser(this.platformId)) {
    this.captchaService.tokenGenerate();
    }
  }

  /**
   * Hook de inicialización de componente 
   */
  ngOnInit() {
    window.scroll(0, 0);
    this.initForm();
    this.emailService.getEnvironment().subscribe(res => {
			this.env = res.environment ? res.environment : 'dev'
    });
    this.replaceScript();
  }

  /** Inicializa formulario reactivo de CONTACTO y textos */
  initForm() {
    this.jobExchangeForm = new UntypedFormGroup({
      name: new UntypedFormControl(undefined,  { validators: [Validators.required, Validators.pattern('^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙñÑ.-]+')] }),
      motherLastname: new UntypedFormControl(undefined, { validators: [Validators.required, Validators.pattern('^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙñÑ.-]+')] }),
      fatherLastname: new UntypedFormControl(undefined, { validators: [Validators.required, Validators.pattern('^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙñÑ.-]+')] }),
      gender: new UntypedFormControl(undefined, [Validators.required]),
      email: new UntypedFormControl(undefined, {
        validators: [Validators.required,  Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      }),
      phone: new UntypedFormControl(undefined,  {
        validators: [Validators.required,  Validators.pattern('^[0-9]*$'), Validators.maxLength(10),
          Validators.minLength(10)],
      }),
      category: new UntypedFormControl('', [Validators.required]),
      professionalId: new UntypedFormControl(undefined),
      professionalExperience: new UntypedFormControl(undefined,  {
        validators: [Validators.required,  Validators.pattern('^[0-9]*$'), Validators.maxLength(2),
          Validators.minLength(1)],
      }),
      dummyFile: new UntypedFormControl(undefined),
      file: new UntypedFormControl(undefined),
      privacyNotice: new UntypedFormControl(undefined, [Validators.requiredTrue]),
      termsAndConditions: new UntypedFormControl(undefined, [Validators.requiredTrue]),
    });
    this.jobExchangeForm.valueChanges.subscribe(() => {
      this.showWarning = this.jobExchangeForm.dirty && this.jobExchangeForm.invalid;
    });

  }

  
  /** Envío de formulario */
  jobFormSubmit() {
    this.loaderService.star();
    this.sendingForm = true;
    /**
     * const subs
     */    
    if (isPlatformBrowser(this.platformId)) {
    this.captchaService.tokenGenerate();
    this.captchaService.token.pipe(
      filter(validToken => validToken !== undefined),
      map((token: string) => token)
    ).subscribe(tokenResponse => {

    setTimeout(() => {
      if (this.sendingForm) {        
      const {
        name,
        motherLastname,
        fatherLastname,
        gender,
        email,
        phone,
        professionalId,
        professionalExperience,
      } = this.jobExchangeForm.value;
      const obj: IJobExchangeFormInterface = {
        name: name.toUpperCase(),
        fatherSurname: fatherLastname.toUpperCase(),
        motherSurname: motherLastname.toUpperCase(),
        gender: gender.toUpperCase(),
        role: 2,
        contact: [{ type: 'phone', value: phone }, { type: 'email', value: email }],
        category: this.jobExchangeForm.get('category').value,
        professionalId: this.jobExchangeForm.get('category').value === this.OPTIONS.PROFESSIONAL ? professionalId.toUpperCase() : '',
        professionalExperience: Number(professionalExperience),
        origin: 'JOB',
        token: tokenResponse
      };
      this.countModal = this.countModal + 1;
      if (this.countModal === 1) {
        const subs = this.formService
      .saveJobApplication(obj)
      .pipe()
      .subscribe(
        response => {
          if (response) {
            
            let pipe = new DatePipe('es-MX');
            let bodyEmail:any = {
              "idUser": this.env === 'PRO' ? 6 : 2,
              "tokenUser": this.env === 'PRO' ? '5cb96a4ac79da30ab4c5c023fe44464' : '1A6c9d7310f8cb96aXvsfake0pLa2c4',
              "idMessage" : " ",
              "idService" : "1",
              "idTemplate" : "132",
              "subject" : "AXA Keralty | Bolsa de Trabajo",
              "sender" : "karenlizette.romero@axa.com.mx",
              "targets" : "almagallanes@axakeralty.mx,reclutamiento@axakeralty.mx",
              "attachments": {
                  file: this.jobExchangeForm.controls['file'].value,
                  name: this.jobExchangeForm.controls['dummyFile'].value
              }
            }   
            this.emailService.sendEmail(bodyEmail).subscribe(res => {
              if (res){
                    if (res['soap:Envelope']['soap:Body'][0]['ns2:sendEmailUseTemplateIDResponse'][0]['return'][0]['statusCode'][0] == "0"){
                      this.loaderService.stop(); 
                      this.modalService.successModal(this.SUCCESS_MESSAGE);
                       
                       this.resetForm();
                     } else {
                      this.loaderService.stop();
                      this.sendingForm = false;
                      this.modalService.errorModal('Informacion guardada en la base de datos, pero hubo un error al enviar correo. Intente mas tarde');
                      
                      this.resetForm();
                     }              
              } else {
                this.sendingForm = false;
                this.loaderService.stop();
                this.modalService.errorModal('Informacion guardada en la base de datos, pero hubo un error al enviar correo. Intente mas tarde');
                
                this.resetForm();
               }     
            }, err => {
              this.sendingForm = false;
              this.loaderService.stop()
              this.modalService.errorModal('Informacion guardada en la base de datos, servicio de correo intermitente');
              
            });               
          }
        },
        e => {
          this.sendingForm = false;
          this.loaderService.stop()
          this.modalService.successModal(this.SUCCESS_MESSAGE);        
        }
      );
      }
    }
    }, 500);
    });
          // this.sendingForm = false;
          // this.loaderService.stop()
          // this.modalService.errorModal('Error al realizar la consulta de la base de datos');    
  }
  }

  /** Simula click en input */
  simulateClick() {
    const element: HTMLElement = this.category.nativeElement as HTMLInputElement;
    element.click();
  }

  /**
   * Actualiza variable para identificiador del estado de la república y llama generación de CURP
   * @param categoryId
   * @param categoryName
   */
  setCategoryId(categoryId: EJobApplicationCategories, categoryName: string) {

    this.categoryId = categoryId;
    const control = 'category';
    this.jobExchangeForm.controls[control].setValue(categoryId);
  }

  /** Reinicio de formulario */
  resetForm() {
    /** Envío de evento a dataLayer para taggeo */
    const Egtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'jobsection',
      eventAction: 'limpiar formulario'
    }

    window.dataLayer.push(Egtm);

    this.jobExchangeForm.reset();
    this.scrollTop();
    this.showWarning = false;
  }

  /** Realiza scroll de pantalla hacia contenedor superior */
  scrollTop(scrollTarget = '.container') {
    this.pageScrollService.scroll({ document: this.document, scrollTarget });
  }

  /** Abre explorador de archivos para seleccionar CV a subir */
  openFilesExplorer() {
    this.fileInput.nativeElement.click();
  }

  /** Trigger de cambio en la selección de archivo para CV */
  selectedFile(event) {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      const control = 'dummyFile';
      this.jobExchangeForm.controls[control].setValue(event.target.files[0].name);
      this.jobExchangeForm.controls['file'].setValue((<string>reader.result).split(',')[1]);
    }
  }

  /**
   * Método para abrir página de Vacantes
   *
   * @returns
   * @memberof JobExchangeComponent
   */
  goToVacants() {
    /** Envío de evento a dataLayer para taggeo */
    const gtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'jobsection',
      eventAction: 'vacantes',
    }

    window.dataLayer.push(gtm);

    const url1 = 'https://jobs.axa/careersection/1/moresearch.ftl?';
    const url2 = 'lang=es&location=547205020509&portal=8105020509&radius=1&radiusType=KsearchExpanded=true';
    this.fullURL = url1 + url2;
    return window.open(this.fullURL, '_blank');
  }

      /**
   * Transforma blob(ArrayBuffer) en base64 para cañon de correos
   * @param buffer
   */
  _arrayBufferToBase64( buffer: any ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }


  /**
   * changeCategory
   */
  changeCategory(){
    this.jobExchangeForm.get('category').valueChanges
    .subscribe(value => {
      if(value==="PROFESSIONAL") {
        this.jobExchangeForm.get('professionalId').setValidators(Validators.required);
        this.jobExchangeForm.get('professionalId').updateValueAndValidity();
      } else{
        this.jobExchangeForm.get('professionalId').clearValidators();
        this.jobExchangeForm.get('professionalId').updateValueAndValidity();
      }
    }
    );
  }

  /**
   * demo
   */
  demo(){
    this.optionCategory.nativeElement.remove();
  }

  sendTag() {
    const eventGTM: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'jobsection',
      eventAction: 'envio formulario'
    }

    window.dataLayer.push(eventGTM);
  }

  replaceScript() {
    try{
      if(document.cookie.indexOf('_ga=')>-1){
          const gaCookie = document.cookie.split('_ga=')[1].split(';')[0].split('.');
          this.clientID = gaCookie[2] + '.' + gaCookie[3];
      }
    } catch(e){
      return 'Error al obtener el id de Google Analytics';
    }

    if (this.router.url === '/bolsa-trabajo'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        login: 'no',
        clientId: `${this.clientID}`,
        pageType: 'home',
        pageCategory: `${this.router.url}`,
        pageURLCategory: `${window.location.href}`,
        device: `${this.getDevice()}`,
        numeroCotizacion: 'NA',
        folioPago: 'NA',
        numeroSolicitud: 'NA',
        numeroPoliza: 'NA'
      });
    }
  }

  getDevice() {
    if(this.deviceService.isDesktop()) {
       return 'desktop';
    } else if(this.deviceService.isMobile()) {
      return 'mobile';
    } else if(this.deviceService.isTablet()) {
      return 'tablet';
    } else {
      return 'other';
    }
  }
}
