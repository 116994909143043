<div class="background pb-5 pt-3">
    <div class="container pay-policy-wrapper">
        <div class="row">
            <div class="col-12 d-block d-md-none mobile-title-wrapper" *ngIf="isSearch()"><h1>Consulta de saldo pendiente</h1></div>
            <div class="col-12 d-block d-md-none mobile-title-wrapper" *ngIf="isNoBalance()"><h1>Gracias por cuidar tu salud</h1></div>
            <div class="col-12 d-block d-md-none mobile-title-wrapper" *ngIf="isCheckout()"><h1>Consulta de saldo pendiente</h1></div>
            <div class="col-12 col-md-5 image-wrapper">
                <img [src]="isNoBalance()?'assets/searchpolicy/healthy_patient_real_size.jpg':'assets/searchpolicy/pay_man_real size.jpg'" alt="">
            </div>
            <div class="col-12 col-md-7 component-wrapper pt-4">
                <app-search-policy *ngIf="isSearch()" (sendFindPolicy)="getFindPolicy($event)"></app-search-policy>
                <app-no-balance *ngIf="isNoBalance()"></app-no-balance>
                <app-checkout [clean]="cleanData" [policy]="policyNumber" [balance]="balance" *ngIf="isCheckout()"></app-checkout>
                <p class="return-wrapper mt-5">
                    <span class="ak-icon icon-return" style="font-size: 40px; cursor: pointer;" (click)="returnToHome($event)" ><span class="path1"></span><span class="path2"></span></span>
                </p>
            </div>
        </div>
    </div>
</div>