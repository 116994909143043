import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

/**
 * Componente para Datos de usuario
 */
@Component({
  selector: 'consult-infographic',
  templateUrl: './consult-infographic.component.html',
  styleUrls: ['./consult-infographic.component.scss']
})
export class ConsultInfographicComponent {
  landingPageUrl = `${window.location.protocol}//${window.location.host}/`;
    constructor(
        private router: Router
      ) {
        window.scrollTo(0,0);
      }
         /**
   * Redireccion a cotizador publico 
   */
  redirectToCot() {
    window.open(`${window.location.protocol}//${window.location.host}/cotizador-planmed/`);
  }
  goToMap() {
    localStorage.setItem('showMap', 'true');
    location.assign(this.landingPageUrl);
  }
}