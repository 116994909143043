<div class="banner d-flex align-items-center justify-content-center">
  <div class="container">
    <div class="row m-auto px-0 pt-sm-0 text-center">
      <p class="banner__title font-weight-bolder m-auto pl-sm-0 pt-2 mb-0 ">
        Conoce una forma diferente y completa para cuidar tu salud
      </p>
    </div>
  </div>
</div>
<!-- BANNERS -->
<div class="banner__background">
  <div 
    id="carouselExampleBanner" 
    class="carousel slide mx-auto d-block" 
    data-ride="carousel" 
    data-interval="22000"
    (slide.bs.carousel)="logChange($event)">
    <ol class="carousel-indicators m-0">
      <li 
        *ngFor="let banner of banners; let bannerIndex = index"
        data-target="#carouselExampleBanner" 
        [attr.data-slide-to]="bannerIndex"
        [class.active]="bannerIndex == 0">
      </li>
    </ol>
    <div class="carousel-inner">
      <div 
        *ngFor="let banner of banners; let bannerIndex = index"
        class="carousel-item" 
        [class.active]="bannerIndex == 0"
        data-interval="7000">
        <ng-container *ngIf="!banner.watchVideo && !banner.watchDocumento">
          <img alt="banner liverpool" [src]="banner.image.desktop" *ngIf="!widthMobile" class="banner__img mx-auto d-block">
          <img alt="banner liverpool" [src]="banner.image.mobile" *ngIf="widthMobile" class="img-mobile mx-auto d-block">
          <div class="banner__carousel-text text-center ml-2 ml-lg-5">
            <div class="vertical-centered">
              <p class="banner__carousel-title title-mty mx-auto mb-4" [class]="banner.classtitle" [ngClass]="  'text-white' " >{{banner.title}}</p>
              <div [innerHTML]="banner.description"></div>
              <a *ngIf="!!banner.link" class="mx-auto px-2 btn pt-2 pb-3" [ngClass]=" 'button-services' " [target]="banner.link.target" [href]="banner.link.url">
                {{banner.link.title}}
              </a>
              <a *ngIf="!!banner.function" class="mx-auto px-2 btn pt-2 pb-3" [ngClass]=" 'button-services' " (click)= "bannerAction($event,banner.function.name)" >
                {{banner.function.title}}
              </a>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="banner.watchVideo">
          <img (click)="bannerVideo($event,banner.watchVideo.url)" alt="banner liverpool" [src]="banner.image.desktop" *ngIf="!widthMobile" class="banner__img mx-auto d-block">
          <img (click)="bannerVideo($event,banner.watchVideo.url)" alt="banner liverpool" [src]="banner.image.mobile" *ngIf="widthMobile" class="img-mobile mx-auto d-block">
        </ng-container>

        <ng-container *ngIf="banner.watchDocumento">
          <img (click)="bannerdocument($event,banner.watchDocumento.url)" alt="banner liverpool" [src]="banner.image.desktop" *ngIf="!widthMobile" class="banner__img mx-auto d-block">
          <img (click)="bannerdocument($event,banner.watchDocumento.url)" alt="banner liverpool" [src]="banner.image.mobile" *ngIf="widthMobile" class="img-mobile mx-auto d-block">
        </ng-container>

      </div>
    </div>
  </div>
</div>
<!-- AXA KERALTY PLANMED -->
<div class="row justify-content-center pt-lg-5 mt-lg-5 mt-5 m-0">
  <div class="col-10 intro text-center">
    <b>AXA KERALTY</b> te ofrece un nuevo modelo de cuidado de salud que brinda acceso a diferentes servicios de 
    atención médica de calidad en instalaciones de alto nivel y con profesionales de la salud.
  </div>
</div>
<div class="container-fluid mt-4">
  <div class="row mb-5 mt-5 mt-md-2 px-0">
    <div class="mb-2 col-12 col-md text-center px-0">
      <button class="btn new-button py-0 ml-5 ml-md-0 mt-4 mt-md-0 ml-md-3" (click)="addToCartEvent('button')">
        CONTRATA <span class="ml-2 " style="font-weight: 300;"><span class="ff-planmed Planmed"></span>®</span>
      </button>
      <button class="banner__discount" style="padding-top: 9px;padding-left: 10px;" (click)="addToCartEvent('discount')">
        <span class="ak-icon icon-tag_discount" style="font-size: 28px;color: #FFFFFF;"></span>
      </button>
    </div>
  </div>
</div>