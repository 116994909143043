/** import Component, OnInit */
import { Component, OnInit } from '@angular/core';
/**
 * Component PaymentConfirmationComponent
 */
@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit {

  /** constructor*/
  constructor() { }

  /** ngOnInit */
  ngOnInit() {
  }

}
