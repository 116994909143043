<div class="general-content" [style.margin-top]="changeByRefresh? '-153px':'0'">
    <div class="container">

        <div class="row d-flex">
          <div class="col-12">
    
            <div class="row m-0 mb-3">
              <div class="col-12">
                <p class="mt-4" 
                  style="font-family: 'Source Sans Pro SemiBold';font-size: 17px;color: #1094B9; font-weight: 600;">
    
                  <span style="cursor: pointer;" (click)="goServices()">
                    <span class="circle-arrow">
                      <span class="arrow-span">&#10140;</span> 
                    </span>
                    <span class="back-label"> Regresar a servicios</span>
                  </span>
                  
                </p>
              </div>
            </div>
    
            
          </div>
        </div>
    
      </div>

    <div class="container">


            <div class="row">
                <div class="col-12">

                    <div style="min-height: 105px;" class="col-12 col-lg-6 m-auto">


                        <ol class="ProgressBar">
                            <li class="ProgressBar-step is-complete">
                              
                            </li>
                            <li class="ProgressBar-step is-complete">
                              <svg class="ProgressBar-icon"><use xlink:href="#checkmark-bold"/></svg>
                              <span class="ProgressBar-stepLabel">Datos</span>
                            </li>
                            <li class="ProgressBar-step is-complete">
                              <svg class="ProgressBar-icon"><use xlink:href="#checkmark-bold"/></svg>
                              <span class="ProgressBar-stepLabel">Checkout</span>
                            </li>
                            <li class="ProgressBar-step">
                              <svg class="ProgressBar-icon"><use xlink:href="#checkmark-bold"/></svg>
                              <span class="ProgressBar-stepLabel">Éxito</span>
                            </li>
                            <li class="ProgressBar-step">
                              
                            </li>
                          </ol>
    
    
                    </div>

                </div>
            </div>

            <div class="row m-auto" 
            style="background-color: #D7E9FA;
                border-radius: 20px;
                max-width: 720px;">
                <div class="row d-flex justify-content-center col-12 py-4" style="margin-bottom: -30px;">
                    <div class="col-lg-5">
                        <!--
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-center justify-content-lg-between">
                            <img class="img-inactive" src="assets/img/breadcrumb/datos_done_.png" alt="">
                            <img class="dotted-line" src="assets/img/breadcrumb/punteadas_.png" alt="">
                            <img class="active-img" src="assets/img/breadcrumb/checkout_activo_.png" alt="">
                            <img class="dotted-line" src="assets/img/breadcrumb/punteadas_.png" alt="">
                            <img class="img-inactive" src="assets/img/breadcrumb/pago_inactivo_.png" alt="">
                            <img class="dotted-line" src="assets/img/breadcrumb/punteadas_.png" alt="">
                            <img class="img-inactive" src="assets/img/breadcrumb/completar_inactivo_.png" alt="">
                        </div>
                    </div>
                        -->
                        <div class="row">
                            <div class="col-12 text-center">
                                    <div class="text-main m-auto">
                                        Checkout
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="horizontal-line d-block w-75"></div>
                    
                    <span class="text-revisa-info mt-5">Revisa que toda la información sea correcta.</span>
                </div>
                <div class="row d-flex justify-content-center col-12 py-4">

                    <div class="col-12">
                        <div class="title-scheduled pl-3">
                          Datos del paciente
                        </div>
                        <div class="body-scheduled  row">
                          <div class="col-6">
                            <p>
                                <b class="titular-label">Paciente:</b>
                                <br />
                                {{this.userData?.firstName}} {{this.userData?.secondName}} {{this.userData?.fatherSurname}} {{this.userData?.motherSurname}}
                            </p>
                          </div>
                          <div class="col-6">
                            
                          </div>
                          <div class="col-6 align-text-bottom">
                            <p>
                                
                            </p>
                          </div>
                          <div class="col-6 text-left">
                            
                          </div>
                          <div class="col-6 align-text-bottom">
                            <p>
                              <b class="titular-label">Teléfono de contacto:</b>
                              <br />
                              {{this.userData?.phone}}
                            </p>
                          </div>
                          <div class="col-6 text-left">
                            <p>
                                <b class="titular-label">Correo de contacto:</b>
                                <br />
                                {{this.userData?.email}}
                            </p>
                          </div>
          
                        </div>
                    </div>


                    <div class="col-12 col-md-8 mt-4">
                        <div class="title-scheduled pl-3">
                          Producto:
                        </div>
                        <div class="body-scheduled  row">
                            <div class="col-12">
                                <p>
                                    <b class="titular-label">Producto:</b>
                                    <br />
                                    <span class="text-green" style="font-size: 12px;" *ngIf="typeService === 'Prueba antígeno COVID-19'">{{this.typeService}}</span>
                                    <span class="text-green" style="font-size: 12px;" *ngIf="typeService === 'Prueba PCR-RT-SARS-COV2'">{{this.typeService}}</span>
                                    <span class="text-green" style="font-size: 12px;" *ngIf="typeService !== 'Prueba PCR-RT-SARS-COV2' && typeService !== 'Prueba antígeno COVID-19'">Videoconsulta / {{this.typeService}}</span>
                                </p>
                            </div>
                            <div class="col-12 align-text-bottom">
                                <p>
                                    <b class="titular-label">Tipo de usuario:</b>
                                    <br />
                                    <span [ngClass]="this.userData?.typeUser === 'GMM' ? 'text-ggm-axa' : ''">{{typeUser}} </span>
                                </p>
                            </div>
          
                        </div>
                    </div>

                    <div class="col-12 col-md-4 mt-4">
                        <div class="title-scheduled pl-3">
                            Detalle de pago:
                        </div>
                        <div class="body-scheduled  row">
                            <div class="col-12">
                                <p>
                                    <b class="titular-label">Precio:</b>
                                    <br />
                                    ${{priceService | number : '1.2-2'}} MXN
                                </p>
                            </div>
                            <div class="col-12 align-text-bottom">
                                <p>
                                    <b class="titular-label">I.V.A.:</b>
                                    <br />
                                    ${{ivaService | number : '1.2-2'}} MXN
                                </p>
                            </div>
                            <div class="col-12 align-text-bottom">
                                <p>
                                    <b class="titular-label">Total:</b>
                                    <br />
                                    <strong>${{totalService | number : '1.2-2'}} MXN</strong>
                                </p>
                            </div>
          
                        </div>
                    </div>




                    <!--
                    <div class="col-lg-8 col-md-10 col-12">
                        <div class="border-table">
                            <div class="row m-auto pb-4">
                                <div class="col-md-6 col-12 text-center px-0">
                                    <div class="menu-fondo-right py-2">
                                        Producto y paciente:
                                    </div>
                                    <div class="row m-0 pt-3 text-product">
                                        <div class="col-5">
                                            <p>Producto:</p>
                                        </div>
                                        <div class="col-7">
                                            <p class="text-green" style="font-size: 12px;" *ngIf="typeService === 'Prueba antígeno COVID-19'">{{this.typeService}}</p>
                                            <p class="text-green" style="font-size: 12px;" *ngIf="typeService === 'Prueba PCR-RT-SARS-COV2'">{{this.typeService}}</p>
                                            <p class="text-green" style="font-size: 12px;" *ngIf="typeService !== 'Prueba PCR-RT-SARS-COV2' && typeService !== 'Prueba antígeno COVID-19'">Videoconsulta / {{this.typeService}}</p>
                                        </div>
                                    </div>
                                    <div class="row m-0 text-product">
                                        <div class="col-5">
                                            <p>Tipo de usuario:</p>
                                        </div>
                                        <div class="col-7">
                                            <p [ngClass]="this.userData?.typeUser === 'GMM' ? 'text-ggm-axa' : ''">{{typeUser}} </p>
                                        </div>
                                    </div>
                                    <div class="row m-0 text-product">
                                        <div class="col-5">
                                            <p>Paciente:</p>
                                        </div>
                                        <div class="col-7 text-name">
                                            <p>{{this.userData?.firstName}} {{this.userData?.secondName}} {{this.userData?.fatherSurname}} {{this.userData?.motherSurname}}</p>
                                        </div>
                                    </div>
                                    <div class="row m-0 text-product">
                                        <div class="col-5">
                                            <p>Contacto:</p>
                                        </div>
                                        <div class="col-7">
                                            <p><span class="axa-icon axa-phone mr-2"></span>{{this.userData?.phone}}</p>
                                            <p><span class="axa-icon axa-email mr-2"></span>{{this.userData?.email}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 text-center px-0">
                                <div class=" menu-fondo-left  py-2">
                                    Detalle de pago:
                                </div>
                                <div class="row m-0 py-3">
                                    <div class="col-6 text-product text-right">
                                        <p>Precio:</p>
                                        <p>I.V.A.:</p>
                                        <p [ngClass]="this.userData?.typeUser === 'GMM' ? 'text-ggm-axa' : ''"><strong>Total:</strong></p >
                                    </div>
                                    <div class="col-6 text-product">
                                        <p>${{priceService | number : '1.2-2'}} MXN</p>
                                        <p>${{ivaService | number : '1.2-2'}} MXN</p>
                                        <p [ngClass]="this.userData?.typeUser === 'GMM' ? 'text-ggm-axa' : ''"><strong>${{totalService | number : '1.2-2'}} MXN</strong></p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    -->
                </div>
                <div class="row  d-flex justify-content-center col-12">
                    <div class="col-12">
                        <div class="check-container cursor-pointer mb-2 d-flex">
                            <label class=" fs-12 cl-blue-keralty text-justify text-check" id="chk_cotizaP_tdcContractorData">
                                <input
                                    class="check"
                                    type="checkbox"
                                    [(ngModel)]="firstCheckBox"
                                    [ngModelOptions]="{ standalone: true }"
                                    id="chk_cotizaP_tdcContractorData"
                                />
                                <span class="checkmark" id="chk_cotizaP_tdcContractorData"></span>
                                Te recomendamos tener a la mano los datos de tu tarjeta para completar el pago. El cargo se visualizará como <strong>"Mercado Pago"</strong> en el historial de cargos de tu tarjeta.
                            </label>
                        </div>
                        <div class="check-container cursor-pointer mb-2 d-flex">
                            <label class=" fs-12 cl-blue-keralty text-justify text-check" id="chk_cotizaP_tdcContractorData">
                                <input
                                    class="check"
                                    type="checkbox"
                                    [(ngModel)]="secondCheckBox"
                                    [ngModelOptions]="{ standalone: true }"
                                    id="chk_cotizaP_tdcContractorData"
                                />
                                <span class="checkmark" id="chk_cotizaP_tdcContractorData"></span>
                                Asegúrate, antes de realizar tu pago, que el nombre del usuario principal/comprador sea el mismo de
                                la tarjeta de
                                crédito o débito con la que vas a pagar.
                            </label>
                        </div>
                        <div class="check-container cursor-pointer mb-2 d-flex">
                            <label class=" fs-12 cl-blue-keralty text-justify text-check" id="chk_cotizaP_tdcContractorData">
                                <input
                                    class="check"
                                    type="checkbox"
                                    [(ngModel)]="thirdCheckbox"
                                    [ngModelOptions]="{ standalone: true }"
                                    id="chk_cotizaP_tdcContractorData"
                                />
                                <span class="checkmark" id="chk_cotizaP_tdcContractorData"></span>
                                Keralty México, S.A. de C.V. (AXA Keralty) con domicilio en Av. Félix Cuevas 366, 
                                Piso 3, Colonia Tlacoquemécatl, Alcaldía Benito Juárez, C.P. 03200, Ciudad de 
                                México, llevará a cabo el tratamiento de sus datos personales para proporcionarle información 
                                relativa a las atenciones médicas solicitadas, así como para las demás finalidades 
                                contempladas en el Aviso de Privacidad Integral, el cual puede consultar en axakeralty.mx 
                                en la sección Aviso de Privacidad Keralty México.
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row text-center pt-4 col-12 mb-4">
                    <div class="col-12 col-md-6 wrapper-btn-left">
                        <button class="button-custom new-button" type="submit" (click)="goToPayment()" [disabled]="!firstCheckBox || !secondCheckBox || !thirdCheckbox" [ngClass]="(!firstCheckBox || !secondCheckBox || !thirdCheckbox) ? 'inactive-button' : ''"> 
                            Pagar
                        </button>
                    </div>
                    <div class="col-12 col-md-6 wrapper-btn-right">
                        <a class="back-buttom-custom" [routerLink]="['/servicios-pago']" > 
                           <span
                                style="display: block;
                                position: relative;
                                top: 1px;"
                            >Regresar</span> 
                        </a>
                    </div>
                </div>
            </div>

            <div class="row py-4" *ngIf="this.userData?.typeUser === 'Externo'">
                <div class="col-12 text-center d-flex flex-column justify-content-center py-4">
                    <div class="ripple d-flex justify-content-center align-items-center m-auto" (click)="redirectToCot()">
                        <span class="axa-icon axa-tag_discount"></span>
                    </div>
                   <div  class="py-3">
                    <p class="text-green">Adquiere tu <a [routerLink]="['/infografia']" class="planmed-img" target="_blank" ><span class="planmed Planmed ml-1" ><span class="planmed Registered"></span></span></a></p>
                   </div>
                </div>
            </div> 
            <div class="row d-flex justify-content-center" *ngIf="this.userData?.typeUser === 'Externo'">
            <!--<div class="row d-flex justify-content-center">-->
                <div class="col-lg-10">
                    <p class="care-family-text c-blue text-center">Cuida tu salud y la de toda tu familia</p>
                    <p class="care-family-descrip c-blue">Planmed<sup>®</sup> Óptimo es el plan de salud que brinda 
                        acceso a todos los servicios de nuestro modelo de salud, tales como: consultas de medicina general, pediatría y ginecología, orientación médica telefónica y 
                        domiciliaria 24/7, servicio de ambulancia por emergencia, consultas de especialidad, estudios 
                        de laboratorio, terapias físicas, check up anual, entre otros. 
                    </p>
                    <div class="row">
                        <div class="col-12 text-center">
                            <p class="title-example text-center">Ejemplos de lo que podrías llegar a ahorrar al contratar Planmed<sup>®</sup></p>
                        </div>

                        <div class="col-12 col-sm-4 top-30-md">
                            <div class="costs-example-card-header text-center">
                                <p class="text-center member">Mujer</p>
                                <p class="text-center years-old">40 años</p>
                            </div>
                            <div class="costs-example-card">
                                <p class="p-service">1 Check up</p>
                                <p class="p-price">$879</p>

                                <p class="p-service">1 Consulta de ginecología</p>
                                <p class="p-price">$1000</p>

                                <p class="p-service">1 Papanicolaou</p>
                                <p class="p-price">$684</p>

                                <p class="p-service">1 Mastografía</p>
                                <p class="p-price">$998</p>

                                <p class="p-service">6 Consultas de nutrición</p>
                                <p class="p-price">$700 c/u</p>

                                <p class="p-total-price">Total: $7,761</p>

                                <div class="bottom-example-card">
                                    <p class="p-planmed">Con
                                        <span class="planmed Planmed px-2 c-pointer" style="padding-left: 1px !important;">
                                            <span class="planmed Registered"></span>
                                        </span> Óptimo
                                    </p>

                                    <p class="p-current-price">$5,560</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 top-30-md">
                            <div class="costs-example-card-header text-center">
                                <p class="text-center member">Hombre</p>
                                <p class="text-center years-old">60 años</p>
                            </div>
                            <div class="costs-example-card">
                                <p class="p-service">1 Check up</p>
                                <p class="p-price">$879</p>

                                <p class="p-service">1 Estudio de próstata</p>
                                <p class="p-price">$249</p>

                                <p class="p-service">1 Consulta de medicina general</p>
                                <p class="p-price">$800</p>

                                <p class="p-service">1 Sangre oculta en heces</p>
                                <p class="p-price">$428</p>

                                <p class="p-service">6 Consultas de nutrición</p>
                                <p class="p-price">$700 c/u</p>

                                <p class="p-total-price">Total: $6,556</p>

                                <div class="bottom-example-card">
                                    <p class="p-planmed">Con
                                        <span class="planmed Planmed px-2 c-pointer" style="padding-left: 1px !important;">
                                            <span class="planmed Registered"></span>
                                        </span> Óptimo
                                    </p>

                                    <p class="p-current-price">$5,868</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 top-30-md">
                            <div class="costs-example-card-header text-center">
                                <p class="text-center member">Bebé</p>
                                <p class="text-center years-old">En su primer año de vida</p>
                            </div>
                            <div class="costs-example-card">
                                <p class="p-service">12 Consultas de pediatría</p>
                                <p class="p-price">$1,000</p>

                                <p class="p-service">1 Radiografía de cadera</p>
                                <p class="p-price">$2,700</p>

                                <p class="p-service">1 Ultrasonido de cadera</p>
                                <p class="p-price">$3,326</p>

                                <p class="p-total-price">Total: $18,026</p>

                                <div class="bottom-example-card">
                                    <p class="p-planmed last">Con
                                        <span class="planmed Planmed px-2 c-pointer" style="padding-left: 1px !important;">
                                            <span class="planmed Registered"></span>
                                        </span> Óptimo
                                    </p>

                                    <p class="p-current-price last">$4,229</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row txt-small mt-3">
                        <div class="col-12">
                            <p class="mb-0 footer-prices">Precios de referencia derivados de un análisis del área de 
                                mercadotecnia de AXA Keralty. Precios ilustrativos de servicios de laboratorios e 
                                imagenología vs tarifa anual de Planmed<sup>®</sup> Óptimo.</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-12 text-center text-special-discount">
                        <button class="button-custom redirect-button" (click)="redirectToCot()"> 
                            ¡Me interesa!
                       </button>
                    </div>
                </div>
                
            </div>

            <div class="row text-center pt-4">
                <!--
                <div class="col-12 py-2 text-center" style="cursor: pointer;" (click)="return()">
                    <img [routerLink]="['/servicios-pago']" src="assets/img/regresar_.png" alt="">
                </div>
                -->
                <div class="col-12 pt-2 text-recaptcha">
                    Protegido por reCAPTCHA - <span>Privacidad</span> - <span>Condiciones</span>
                </div>
           </div>
    </div>
</div>