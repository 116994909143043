<div class="general-content" [style.margin-top]="changeByRefresh? '-153px':'0'" >
  <!--
  <div class="container-full banner d-flex align-items-center">
    <div class="container d-flex">
      <div>
        <span class="text-banner"><strong>Compra</strong> de servicios</span>
      </div>
    </div>
  </div>
  -->
  <div class="container">

    <div class="row d-flex">
      <div class="col-12">

        <div class="row m-0 mb-3">
          <div class="col-12">
            <p class="mt-4" 
              style="font-family: 'Source Sans Pro SemiBold';font-size: 17px;color: #1094B9; font-weight: 600;">

              <span style="cursor: pointer;" (click)="goHome()">
                <span class="circle-arrow">
                  <span class="arrow-span">&#10140;</span> 
                </span>
                <span class="back-label">Regresar a Home</span>
              </span>
              
            </p>
          </div>
        </div>

        
      </div>
    </div>

  </div>
  <div class="container">
    <form>
      <div class="row d-flex justify-content-center py-4">
        <div class="col-lg-12">
          <!--
          <div class="row">
            <div class="col-12 d-flex align-items-center justify-content-center">
              <img class="active-img-landing" src="assets/img/breadcrumb/intro_servicios_real.png" alt="">
            </div>
          </div>
          <div class="row pt-5 pb-3">
            <div class="col-12 text-center">
              <div class="text-main m-auto">
                Servicios
              </div>
            </div>
            <div class="col py-3">
            </div>
          </div>
          -->
          <div class="row m-0 mb-3">
            <div class="col-12">
              <p class="text-justify lh-1">
                <span class="c-blue">
                  Recuerda, no necesitas ser asegurado AXA para acceder a los servicios de las Clínicas AXA 
                  Keralty, pero si cuentas con un Planmed®, un producto de vida y/o gastos médicos mayores AXA, 
                  por ser asegurado, podrás acceder a los servicios que no tengas en cobertura con precio 
                  preferencial. Si requieres un servicio, puedes pagarlo anticipadamente online o acudir a una 
                  Clínica AXA Keralty y solicitarlo.
                </span>
              </p>
              
            </div>
            <div class="col-12 align-items-left">
              <p class="text-new c-blue text-left">
                Contamos con precios de promoción en diversos servicios.
                <br />
                <span class="know">¡Conócelos!</span>
              </p>
            </div>
            
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="container-fluid background-cards py-4">
    <div class="container">
      <div class="row m-0">
        <div class="col-lg-4 col-md-6 col-12">
          <select class="custom-select plan-select select-bold" [(ngModel)]="stateSelected" name="stateSelected" style="border-radius: 6px;"
            (change)="filterByState()">
            <option *ngFor="let stateService of states_services" [value]="stateService.value"> {{stateService.name}}</option>
          </select>

        </div>

        <div class="col-lg-4 col-md-6 col-12 mt-4 mt-md-0">

          <select class="custom-select plan-select select-bold" [(ngModel)]="serviceSelected" name="serviceSelected" style="border-radius: 6px;"
            (change)="filter()">
            <option [ngValue]="null" >Selecciona un servicio</option>
            <option *ngFor="let service of services_filters" [value]="service.type"> {{service.name}}</option>
          </select>
          <p>
            <span *ngIf="serviceSelected!==null" class="close-icon" (click)="removeServiceSelected()">

            <span class="close-line" style="left:4px">

            </span>
            <span class="close-line" style="left:-4px">

            </span>

            </span>
          </p>

          

        </div>

      </div>
      <div class="row m-0">
        <div class="col-12">
          <p style="color: #4F9AAF;font-size: 14px;" *ngIf="GENERAL_SERVICES.length === 0">
            No se encontraron resultados.
          </p>
        </div>
      </div>
      <div class="row m-0 py-3">
        <div class="col-lg-4 col-md-6 col-12 d-flex flex-column mb-5"
          *ngFor="let service of GENERAL_SERVICES; index as i">
          <div class="card-service">
            <img [src]='service.tag.image' alt="" class="img-new" *ngIf="service.tag?.image">
            <img class="img-card" [src]="service.image" alt="">
            <p class="title-card">{{service.name}}</p>
            <strong>
              <p class="type-card">{{service.description}}</p>
            </strong>
            <P class="price-card mb-1" *ngIf="!service.rates.noPrice">${{  roundCeil(service.rates.externo  ) | number : '1.2-2'}} MXN</P>
            <P class="price-card no-price" *ngIf="service.rates.noPrice">{{service.rates.noPrice}}</P>
            <p class="iva-price" *ngIf="!service.rates.noPrice">IVA incluido</p>
            <div class="button-buy" (click)="selectService(service.type,service.redirect)">
              {{service.buttonDescription ? service.buttonDescription : "Comprar" }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center responsable">
        <p><b>Responsable sanitario</b>: Carlos Alberto López García, Universidad Michoacana de San Nicolás de 
          Hidalgo, cédula profesional no. 9696628.
        </p>
        <p>
          AXA Keralty se refiere a la alianza entre AXA Salud, S.A. de C.V., AXA Seguros, S.A. de C.V., como 
          encargadas del aseguramiento y Keralty México, S.A. de C.V., como responsable de los servicios 
          médicos en conjunto con la red de proveedores. Consulta detalles de coberturas, exclusiones y 
          requisitos de contratación en axa.mx
        </p>
      </div>
    </div>

  </div>
  <div class="container">
    
  </div>


  <div class="modal-overlay-custom" *ngIf="showInitialModal">
    <div class="modal-content-custom">

      <p>
        <span class="close-icon-modal" (click)="closeInitialModal()">

        <span class="close-line" style="left:6px">

        </span>
        <span class="close-line" style="left:-6px">

        </span>

        </span>
      </p>

      <p class="text-center mb-0 title-modal-state" >Selecciona el estado</p>
      <p class="text-center mb-4 subtitle-modal-state">donde realizarás tu servicio</p>



      <div class="container">
        <div class="row m-0">
          <div class="col-12">

            <select class="custom-select plan-select select-bold" [(ngModel)]="stateSelected" name="stateSelected" style="border-radius: 6px;"
              (change)="filterModal()">
              <option *ngFor="let stateService of states_services" [value]="stateService.value"> {{stateService.name}}</option>
            </select>



          </div>

          <div class="col-12 text-center mt-5">
            <button class="button-custom accepts-modal-button" (click)="acceptFirstState()">
              Aceptar
            </button>
          </div>

        </div>
      </div>



    </div>
  </div>


</div>