/**
 * Decorator to auto unsubscribe before destroy component
 *
 * @export
 * @returns
 */
export function AutoUnsub() {
  return ( constructor: IHTMLConstructor ) => {
    const origin = constructor.prototype.ngOnDestroy;
    constructor.prototype.ngOnDestroy = function() {
      for ( const prop in this ) {
        const property = this[prop];
        if ( typeof property.subscribe === "function" ) {
          property.unsubscribe();
        }
      }
      origin.apply();
    }
  }
}

/** Constructor to prototype */
interface IHTMLConstructor {
  prototype: any;
}
