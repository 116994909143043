<a class="float-button">
  <div class="float-button__rectangle widget d-flex mt-1" (click)="addToCartEvent()">
    <div class="float-button__text m-auto d-flex align-items-center">
      <span class="icon-shopping-kart1 ak-icon ml-2" style="font-size: 20px;"></span>
      <p class="mb-0 text-center">Contrata tu
        <span class=" ml-2 fs-16 ak-icon icon-logo-planmed1" style="font-size: 14px;"></span>
      </p>
    </div>
  </div>
  <div class="float-button__rectangle widget float-button__green d-flex mt-1">
    <div (click)="openDialog()" class="d-flex w-100 align-items-center">
      <div class="float-button__text m-auto my-lg-auto mx-lg-0 d-flex align-items-center">
        <span class="icon-stars1 ak-icon ml-2"  style="font-size: 20px;"></span>
        <p class="mb-0 text-center ml-1 mb-0" >Sugerencias
        </p>
      </div>
    </div>
    <div class="float-button__suggestions" *ngIf="(experienceService.opennedDialog | async ) && !opennedSuccessDialog">
      <form [formGroup]="formSuggestions">
        <div class="row mx-0 px-3 pt-2">
          <div class="col-12 text-center float-button__close py-2">
            ¿Cómo fue tu experiencia?
            <div class="float-button__close--icon" (click)="closeDialog()">
              <span class="icon-Cerrar"></span>
            </div>
          </div>
        </div>
        <div class="row justify-content-center pt-2 user-select mx-0 px-3">
          <div class="col-2 px-0 mr-2">
            <input class="user-select--icons" type="radio" value="1" id="check1"
                   maxlength="600"
                   formControlName="qualification" (click)="sendGTM($event)">
            <label for="check1" class="icon-FB-Group-10 user-select--icons"></label>
          </div>
          <div class="col-2 px-0 mr-2">
            <input class="user-select--icons" type="radio" value="2" id="check2"
                   formControlName="qualification" (click)="sendGTM($event)"
            >
            <label for="check2" class="icon-FB-Group-11 user-select--icons"></label>
          </div>
          <div class="col-2 px-0 mr-2">
            <input class="user-select--icons" type="radio" value="3" id="check3"
                   formControlName="qualification" (click)="sendGTM($event)">
            <label for="check3" class="icon-FB-Group-12 user-select--icons"></label>
          </div>
          <div class="col-2 px-0 mr-2">
            <input class="icon-FB-Group-10 user-select--icons" type="radio" id="check4" value="4"
                   formControlName="qualification" (click)="sendGTM($event)">
            <label for="check4" class="icon-FB-Group-13 user-select--icons"></label>
          </div>
          <div class="col-2 px-0">
            <input class="icon-FB-Group-10 user-select--icons" type="radio" id="check5" value="5" (click)="sendGTM($event)"
                   formControlName="qualification">
            <label for="check5" class="icon-FB-Group-14 user-select--icons"></label>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center mt-2">
             <textarea
               maxlength="500"
               placeholder="Cuéntanos tu experiencia…"
               class=""
               formControlName="experience"
               (click)="expTag()"
             ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-1 text-center mb-2" (click)="sendSuggest(formSuggestions.value)">
            <button class="btn green-btn" [ngClass]="formSuggestions.invalid ? 'invalid-button' : ''" [disabled]="formSuggestions.invalid">
              Enviar
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="float-button__suggestions float-button__success" *ngIf="opennedSuccessDialog">
      <div class="row mx-0 px-3 pt-2">
        <div class="col-12 text-center float-button__close py-2">
          <img alt="sombreros de evaluación exitosa" src="assets/img/widgets/suggestions/party_hat.png" width="68px"/>
          <div class="float-button__close--icon" (click)="closeSuccessDialog()">
            <span class="icon-Cerrar"></span>
          </div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-12 text-center">
          ¡Gracias por compartir tu experiencia!
        </div>
      </div>
    </div>
  </div>
</a>
