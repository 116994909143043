import { INavigation } from './navigation.interface';
export const NAVIGATION_OPTIONS: INavigation[] = [
  { priority: 0,
    title: 'Tu información',
    description: 'Revisa y completa tus datos',
    icon: 'ak-document',
    active: true,
    checked: false,
    route: 'tu-informacion',
    class: 'icon-ak-document'
  },
  { priority: 1,
    title: 'Contacto',
    description: 'Completa tus datos de contacto',
    icon: 'ak-email',
    active: false,
    checked: false,
    route: 'tu-direccion',
    class: 'icon-ak-email'
  },
  { priority: 2,
    title: 'Integrantes del plan',
    description: 'Completa los datos de tus integrantes',
    icon: 'ak-users',
    active: false,
    checked: false,
    route: 'integrantes-del-plan',
    class: 'icon-ak-users'
  },
  { priority: 3,
    title: 'Revisa tu información',
    description: 'y realiza el pago',
    icon: 'ak-money',
    active: false,
    checked: false,
    route: 'revisa-tu-informacion',
    class: 'icon-ak-money'
  },
]
