<div class="background">
  <div class="container">
    <nav class="navigation row d-flex align-items-center">
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <h3 class="title fs-28 mb-0">{{ currentStep?.title }}</h3>
        <h4 class="description fs-20 mb-0">{{ currentStep?.description }}</h4>
      </div>
      <div class="col-12 col-md-4 row mx-0 px-0">

        <div class="step col px-0 d-flex align-items-center justify-content-center"
          *ngFor="let step of (steps | async)">

          <div class="step-circle all-center transition" [ngClass]="{'active': step.active, 'checked': step.checked}" >
            <div class="step-circle-inner all-center">
              <span class="ak-icon-40" [ngClass]="[step.icon, step.class]"></span>
            </div>
          </div>



        </div>

        <div class="d-flex justify-content-center align-items-center px-0 mx-0 w-100">
          <div class="line" [ngClass]="{
            's1': currentStepIndex === 0,
            's2': currentStepIndex === 1,
            's3': currentStepIndex === 2,
            's4': currentStepIndex === 3}"></div>
        </div>
      </div>
    </nav>
  </div>
</div>
<!-- <button (click)="prev()" >Anterior</button>
<button (click)="next()">Siguiente</button> -->
