import { Injectable, Inject, PLATFORM_ID } from "@angular/core";

import { BehaviorSubject, timer } from "rxjs";
import {
  DIALOGFLOWCONFIGDEV,
  DIALOGFLOWCONFIGPRE,
  DIALOGFLOWCONFIGPROD,
} from "../../constants/chatbot.constant";
import { TokenService } from "../token/token.service";
import { HttpClient } from "@angular/common/http";
import { isPlatformBrowser } from '@angular/common';

export class Message {
  constructor(
    public content: string,
    public sentBy: string,
    public time: string
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class ChatService {
  generalOptions = new BehaviorSubject<string>("");
  conversation = new BehaviorSubject<Message[]>([]);
  time = new Date();
  enableConv = true;
  toggle = new BehaviorSubject<boolean>(false);
  writing = new BehaviorSubject<boolean>(false);
  endConv = new BehaviorSubject<boolean>(false);
  cancell = new BehaviorSubject<boolean>(false);
  reload = new BehaviorSubject<boolean>(false);
  currentAction: string;
  timer;
  currentInterval;
  cancellBand;
  infoband;
  // Session alias
  private DF_SESSION_ALIAS;

  // URL Project
  private URL;
  private token;
  private DialogConfig;
  i = 1;

  constructor(
    private tokenService: TokenService,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.enableConv = true;
      this.tokenService.generateToken().subscribe(
        (res: any) => {
          this.token = res.token;
          this.tokenService.getEnviroment().subscribe((res: any) => {
            let enviroment = res.environment;
            if (enviroment == "PRE") {
              this.DF_SESSION_ALIAS = `/projects/${
                DIALOGFLOWCONFIGPRE.DF_ACCOUNT_KEYS.project_id
              }/agent/sessions/${new Date().getTime()}`;
              this.URL = `${DIALOGFLOWCONFIGPRE.DF_API_BASE_URL}${this.DF_SESSION_ALIAS}:detectIntent`;
            } else if (enviroment == "PRO") {
              this.DF_SESSION_ALIAS = `/projects/${
                DIALOGFLOWCONFIGPROD.DF_ACCOUNT_KEYS.project_id
              }/agent/sessions/${new Date().getTime()}`;
              this.URL = `${DIALOGFLOWCONFIGPROD.DF_API_BASE_URL}${this.DF_SESSION_ALIAS}:detectIntent`;
            } else {
              this.DF_SESSION_ALIAS = `/projects/${
                DIALOGFLOWCONFIGDEV.DF_ACCOUNT_KEYS.project_id
              }/agent/sessions/${new Date().getTime()}`;
              this.URL = `${DIALOGFLOWCONFIGDEV.DF_API_BASE_URL}${this.DF_SESSION_ALIAS}:detectIntent`;
            }
          });
        },
        (err: any) => {
        }
      );
      this.cancell.subscribe((res) => {
        this.cancellBand = res;
        if (res == true) {
          clearTimeout(this.timer);
          clearInterval(this.currentInterval);
        }
      });
    }
  }

  /**
   *
   * Metodo de conversacion con el bot
   */
  converse(msg: string) {
    const msgValidacionEnd =
      "Tengo que dejarte, si cambias de opinión estaré aquí para apoyarte. ";
    const config = {
      headers: {
        Authorization: "Bearer " + this.token,
        "Content-Type": "application/json",
      },
    };
    const userMessage = new Message(msg, "user", this.convTime());
    if (
      msg != "hola:intro" &&
      msg != "si:continue" &&
      msg != "retry" &&
      msg != "info:intro"
    ) {
      this.update(userMessage);
    }
    const queryInput = {
      text: {
        text: msg,
        languageCode: DIALOGFLOWCONFIGDEV.LANGUAGE,
      },
    };
    if (this.enableConv) {
      this.changeWriting(!this.writing.value);
      this.http.post(`${this.URL}`, { queryInput }, config).subscribe(
        (res: any) => {
          const speech = res.queryResult.fulfillmentText;
          this.currentAction = res.queryResult.action;
          if (speech != undefined) {
            if (res.queryResult.action == "Coronavirus.infografia") {
              let responses = speech.split("<br>");
              responses[responses.length - 1] =
                '<a class="infografia" href="http://d32mzo7i5ry9c.cloudfront.net/infografias/Infograf%C3%ADa_Planmed.pdf" target="_blank">' +
                '	<img height="100" width="80" src="/assets/doc/Infografia_Planmed.PNG"/>' +
                "</a>" +
                '<div class="buttonsPDF"><div class="buttonoptionTop">' +
                '	<a href="http://d32mzo7i5ry9c.cloudfront.net/infografias/Infograf%C3%ADa_Planmed.pdf" target="_blank">' +
                "		Ver" +
                "	</a>" +
                "</div>" +
                '<div class="buttonoptionBottom">' +
                '	<a href="/assets/doc/Infografia_Planmed.pdf" target="_blank" download="Infografía_Planmed">' +
                "		Descargar" +
                "	</a>" +
                "</div>";
              responses.push("¿Te interesaría conocer más de Planmed®?");
              this.currentInterval = setInterval(() => {
                this.escribirMensajes(responses);
                clearInterval(this.currentInterval);
              }, 2000);
            } else if (res.queryResult.action == "intro-action") {
              let responses = speech.split("<br>");
              this.escribirMensajes(responses);
            } else if (res.queryResult.action == "errordeconexion") {
              let responses = speech.split("<br>");
              let i = 0;
              responses.forEach((element) => {
                let botMessage = new Message(element, "bot", this.convTime());
                this.update(botMessage);
              });
              this.changeWriting(false);
            } else if (res.queryResult.action == "Challenge.Challenge-custom") {
              this.enableConv = false;
              const botMessage = new Message(speech, "bot", this.convTime());
              this.currentInterval = setInterval(() => {
                this.update(botMessage);
                this.enableConv = true;
                this.changeWriting(!this.writing.value);
                clearInterval(this.currentInterval);
              }, 2000);
            } else if (
              res.queryResult.action == "finish" ||
              speech == msgValidacionEnd ||
              speech.includes("¡Hasta pronto!")
            ) {
              let responses = speech.split("<br>");
              responses.push(
                "Esta conversación se cerrará en un minuto. ¡Muchas gracias por tú tiempo!"
              );
              this.escribirMensajes(responses);
              this.endConvEvent();
              this.enableConv = false;
            } else if (res.queryResult.action == "Coronavirus.continueTerms") {
              const botMessage = new Message(speech, "bot", this.convTime());
              this.update(botMessage);
              this.currentInterval = setInterval(() => {
                this.converse("si:continue");
                this.changeWriting(!this.writing.value);
                clearInterval(this.currentInterval);
              }, 2000);
            } else if (
              (res.queryResult.action == "Coronavirus" ||
                res.queryResult.action == "DiasDeContacto") &&
              speech == "Correcto"
            ) {
              this.currentInterval = setInterval(() => {
                this.converse("si:continue");
                this.changeWriting(!this.writing.value);
                clearInterval(this.currentInterval);
              }, 1500);
            } else if (res.queryResult.action == "info") {
              let responses = speech.split("<br>");
              if (responses.length <= 2) {
                let text = responses[responses.length - 1];
                responses.push(text);
                responses[responses.length - 2] =
                  "<a class='infografia' href='https://2bchatbot.s3.us-east-2.amazonaws.com/Recomendaciones_de_cuidados_en_casa_AXA_Keralty.pdf' target='_blank'>" +
                  "	<img height='100' width='80' src='/assets/doc/infografia.PNG'/>" +
                  "</a>" +
                  "<div class='buttonsPDF'><div class='buttonoptionTop'>" +
                  "	<a href='https://2bchatbot.s3.us-east-2.amazonaws.com/Recomendaciones_de_cuidados_en_casa_AXA_Keralty.pdf' target='_blank'>" +
                  "		Ver" +
                  "	</a>" +
                  "</div>" +
                  "<div class='buttonoptionBottom'>" +
                  "	<a href='/assets/doc/Recomendaciones de cuidados_en_casa_AXA_Keralty.pdf' target='_blank' download='InfografiaRecomendaciones'>" +
                  "		Descargar" +
                  "	</a>" +
                  "</div></div>";
              } else {
                let text = responses[responses.length - 1];
                responses[responses.length - 1] =
                  responses[responses.length - 2];
                responses.push(text);
                responses[responses.length - 3] =
                  "<a class='infografia' href='https://2bchatbot.s3.us-east-2.amazonaws.com/Recomendaciones_de_cuidados_en_casa_AXA_Keralty.pdf' target='_blank'>" +
                  "	<img height='100' width='80' src='/assets/doc/infografia.PNG'/>" +
                  "</a>" +
                  "<div class='buttonsPDF'><div class='buttonoptionTop'>" +
                  "	<a href='https://2bchatbot.s3.us-east-2.amazonaws.com/Recomendaciones_de_cuidados_en_casa_AXA_Keralty.pdf' target='_blank'>" +
                  "		Ver" +
                  "	</a>" +
                  "</div>" +
                  "<div class='buttonoptionBottom'>" +
                  "	<a href='/assets/doc/Recomendaciones de cuidados_en_casa_AXA_Keralty.pdf' target='_blank' download='InfografiaRecomendaciones'>" +
                  "		Descargar" +
                  "	</a>" +
                  "</div></div>";
              }
              this.currentInterval = setInterval(() => {
                this.escribirMensajes(responses);
                clearInterval(this.currentInterval);
              }, 2000);
            } else if (this.currentAction == "input.unknown") {
              let context = res.queryResult.outputContexts[0].name.split("/");
              if (
                res.queryResult.outputContexts.length == 1 &&
                context[context.length - 1] == "__system_counters__"
              ) {
                this.changeReload(true);
              } else {
                let responses = speech.split("<br>");
                this.currentInterval = setInterval(() => {
                  this.escribirMensajes(responses);
                  clearInterval(this.currentInterval);
                }, 1000);
              }
            } else {
              let responses = speech.split("<br>");
              this.currentInterval = setInterval(() => {
                this.escribirMensajes(responses);
                clearInterval(this.currentInterval);
              }, 1000);
            }
          } else {
            this.converse("retry");
          }
        },
        (err: any) => {
          if (err.status == 401) {
            this.newToken()
            this.changeReload(true);
          }
        }
      );
    }
  }

  newToken() {
    this.tokenService.generateToken().subscribe((res: any) => {
      this.token = res.token;
      this.tokenService.getEnviroment().subscribe((res: any) => {
        let enviroment = res.environment;
        if (enviroment == "PRE") {
          this.DF_SESSION_ALIAS = `/projects/${
            DIALOGFLOWCONFIGPRE.DF_ACCOUNT_KEYS.project_id
          }/agent/sessions/${new Date().getTime()}`;
          this.URL = `${DIALOGFLOWCONFIGPRE.DF_API_BASE_URL}${this.DF_SESSION_ALIAS}:detectIntent`;
        } else if (enviroment == "PRO") {
          this.DF_SESSION_ALIAS = `/projects/${
            DIALOGFLOWCONFIGPROD.DF_ACCOUNT_KEYS.project_id
          }/agent/sessions/${new Date().getTime()}`;
          this.URL = `${DIALOGFLOWCONFIGPROD.DF_API_BASE_URL}${this.DF_SESSION_ALIAS}:detectIntent`;
        } else {
          this.DF_SESSION_ALIAS = `/projects/${
            DIALOGFLOWCONFIGDEV.DF_ACCOUNT_KEYS.project_id
          }/agent/sessions/${new Date().getTime()}`;
          this.URL = `${DIALOGFLOWCONFIGDEV.DF_API_BASE_URL}${this.DF_SESSION_ALIAS}:detectIntent`;
        }
      });
    });
  }

  escribirMensajes(responses) {
    let i = 0;
    let wait = 0;
    if (responses.length > 0) {
      if (responses[i].length < 100) {
        wait = responses[i].length * 60;
      } else {
        wait = responses[i].length * 40;
        if (wait > 15000) {
          wait -= 15000;
        }
      }
      let botMessage = new Message(responses[i++], "bot", this.convTime());
      this.update(botMessage);
      responses.shift();
      if (responses.length == 0) {
        wait = 100;
      }
      this.timer = setTimeout(() => {
        this.escribirMensajes(responses);
      }, wait);
    } else {
      this.changeWriting(false);
    }
  }

  /**
   * Obtiene el mensaje
   * @param msg
   */
  update(msg: Message) {
    this.conversation.next([msg]);
  }

  changetoggle(change: boolean) {
    this.toggle.next(change);
  }

  changeWriting(change: boolean) {
    this.writing.next(change);
  }

  changeCancell(change: boolean) {
    this.cancell.next(change);
  }

  changeReload(change: boolean) {
    this.reload.next(change);
  }

  welcome() {
    this.enableConv = true;
    this.changeWriting(false);
    this.endConv.next(false);
    this.changeCancell(false);
    this.converse("hola:intro");
  }

  info() {
    this.enableConv = true;
    this.changeWriting(false);
    this.endConv.next(false);
    this.changeCancell(false);
    this.converse("info:intro");
  }

  endConvEvent() {
    this.endConv.next(true);
  }

  /**
   *
   * Fin metodos bot
   */
  convTime() {
    let hrs = this.time.getHours();
    let min = this.time.getMinutes();
    let sec = this.time.getSeconds();
    let day = this.time.getDate();
    let month = this.time.getMonth();
    let year = this.time.getFullYear();
    return `${hrs}:${min}:${sec} ${day}-${month}-${year}`;
  }
}
