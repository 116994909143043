import {Component} from '@angular/core';
import { IUserData } from 'src/app/interfaces/data-user/data-user.interface';
import { Router } from '@angular/router';
import { ConsultService } from 'src/app/services/consult.service';
import { RecaptchaService } from 'src/app/services/recaptcha/recaptcha.service';
import { PaymentService } from 'src/app/services/payment/payment.service';

// ENVIRONMENTS
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/services/loader/loader.service';

// OPERATORS
import { concatMap, filter, map } from 'rxjs/operators';
import { SERVICES } from 'src/app/constants/services.constants';
import { STATES_SERVICES } from 'src/app/constants/states/states-services.const';
import { HeaderService } from '../../../shared/header/header.service';

/**
 * Componente para Vista de pago
 */
@Component({
  selector: 'checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss']
})
export class CheckoutPaymentComponent {

  /** Variable para primer check */
  firstCheckBox = false;
  /** Variable para segundo check */
  secondCheckBox = false;
    /** Variable para tercer check */
    thirdCheckbox = false;
  serviceFile: any;
  typeService:any;
  /** Guarda informacion que viene de vista anterior */
  userData: IUserData;
  esencialDay = 213;
  consultRate: any;
  GENERAL_SERVICES = SERVICES;
  iva = 0.16;
 
  priceService: any;
  ivaService: any;
  totalService: any;
  typeUser: any;
  stateSelected: any = null; 
  changeByRefresh: boolean = false;


  constructor(
    private router: Router,
    private userInfo: ConsultService,
    private reCaptcha: RecaptchaService,
    private payment: PaymentService,
    private loader: LoaderService,
    private headerService: HeaderService,
  ) {}

  roundCeil(num : number)
  {
    //return Math.ceil( num * (1+this.iva));
    var factor = Math.pow(10,2);
    return (Math.ceil(num* (1+this.iva)*factor) / factor).toFixed(2)
  }

   /** Hook de inicialización de componente */
  ngOnInit() {
    this.userInfo.userInfo.subscribe((resp: IUserData) => {
      this.userData = resp;
      switch (this.userData.typeUser) {
        case 'Externo': 
        this.typeUser = 'Externo';
        
        break;
        case 'Planmed': 
        this.typeUser = 'Planmed®';
        break;
        case 'GMM': 
        this.typeUser = 'Vida y/o Gastos Médicos Mayores AXA';
        break;
        case 'Flex': 
        this.typeUser = 'Flex Plus CDMX';
        break;
      }
    });
    this.userInfo.stateService.subscribe(resp => {
      this.stateSelected = resp;

      let stateIva = 0.16;
      for(let i=0;i<STATES_SERVICES.length;i++)
      {
        let state = STATES_SERVICES[i];
        if(state.value == this.stateSelected)
        {
          stateIva = state.iva;
        }
      }
      this.iva = stateIva;
      this.priceService = (this.userInfo.rateConsult.getValue()).rates[this.userData.typeUser.toLowerCase()]
      this.ivaService = this.priceService * stateIva;
     this.totalService = this.roundCeil(this.priceService);//this.ivaService + this.priceService;    

    })
    this.userInfo.typeService.subscribe((response: any) =>  {
      this.consultRate = this.GENERAL_SERVICES.filter(consult => consult.type === response);
      switch (response) {
        case 'prueba-covid':
          this.serviceFile = 'prueba-covid';
          this.typeService = 'Prueba PCR-RT-SARS-COV2'
          break;
          case 'prueba-antigenos':
            this.serviceFile = 'prueba-antigenos';
            this.typeService = 'Prueba antígeno COVID-19'
            localStorage.setItem('Antigenos', 'true');
            break;
        case 'medicina-general':
          this.typeService = 'Medicina general'
          break;
          case 'pediatria':
          this.typeService = 'Pediatría'
          break;
          case 'nutricion':
          this.typeService = 'Nutrición'
          break;
          case 'psicologia':
          this.typeService = 'Psicología'
          break;
          case 'geriatria':
          this.typeService = 'Geriatría'
          break;
      }
    });

    let stateIva = 0.16;
    for(let i=0;i<STATES_SERVICES.length;i++)
    {
      let state = STATES_SERVICES[i];
      if(state.value == this.stateSelected)
      {
        stateIva = state.iva;
      }
    }
    this.iva = stateIva;
     this.priceService = (this.userInfo.rateConsult.getValue()).rates[this.userData.typeUser.toLowerCase()]
      this.ivaService = this.priceService * stateIva;
     this.totalService = this.roundCeil(this.priceService);//this.ivaService + this.priceService;    

     this.headerService.changedByRefresh.subscribe(
      (response : boolean) => {
        this.changeByRefresh = response;
      }
    )
  }

  return(event) {
    if(event)
    {
      event.preventDefault();
    }
    this.router.navigate(['/datos-consulta']);
  }

  /** Redreccion a agendar  */
  goToAgendar() {
    this.router.navigate(['/servicios-exito']);
  }
    /**
   * Redireccion a cotizador publico 
   */
  redirectToCot() {
    window.open(`${window.location.protocol}//${window.location.host}/cotizador-planmed/`);
  }
  goToPayment(){
    this.loader.star()
    this.reCaptcha.tokenGenerate();

    let stateIva = 0.16;
    for(let i=0;i<STATES_SERVICES.length;i++)
    {
      let state = STATES_SERVICES[i];
      if(state.value == this.stateSelected)
      {
        stateIva = state.iva;
      }
    }
    this.iva = stateIva;
    let price = (this.userInfo.rateConsult.getValue()).rates[this.userData.typeUser.toLowerCase()]
    //var factor = Math.pow(10,2);
    let iva = price *stateIva;
    //(Math.ceil(num* (1+this.iva)*factor) / factor).toFixed(2)
    //let total = iva + price;
    let total : number = Number( this.roundCeil(price));

    //price = total / (this.iva + 1);
    //iva = total - price;
    //iva = total - price;
    //total = parseFloat(total.toFixed(2));
    
    let bodyToPayment = {
      ...this.userData,
      service: {
        segment: this.serviceFile === 'prueba-covid' ? 'Servicio' : this.serviceFile === 'prueba-antigenos' ? 'Servicio' : 'Videoconsulta',
        type: this.serviceFile === 'prueba-covid' ? 'Prueba PCR-RT-SARS-COV2' : this.serviceFile === 'prueba-antigenos' ? 'Prueba antígeno COVID-19' : this.userInfo.typeService.getValue()
      },
      disclaimers: {
        "terms": true,
        "secondTerm": true,
        "namePaymentMP": true
      },
      rate: {
        price,
        iva,
        total
      },
      createdAt: new Date()
    };
    
    this.reCaptcha.tokenGenerate();
    // Obtener token generado
    this.reCaptcha.token
      .pipe(
        // Validar que el token no venga vacío
        filter(validToken => validToken !== undefined),
        // Agregar al objeto el token obtenido del captcha
        map((getToken: string) => {
          bodyToPayment['token'] = getToken;
          return bodyToPayment;
        }),
        // Emitir cotización
        concatMap(async (bodyToPayment: any) => 
        {
          return await this.payment.generatePayment(bodyToPayment)
        }
        )
      )
      .subscribe((response: any) => {
        let urlToRedirect = response.init_point;
        setTimeout(function() {
          window.location.href = urlToRedirect;
        }, 150);
      });



  }

  goServices()
  {
    this.router.navigate(['/servicios']);
  }

}
