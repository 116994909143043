import { Injectable } from '@angular/core';

/** SERVCIOS */
import { ModalService } from './modal/modal.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
/** Injectable*/
@Injectable({
  /** providedIn*/
  providedIn: 'root'
})
/**
 * Servicio para manejo de errores
 */
export class ErrorsService {
  /**
   * Creates an instance of ErrorService
   */
  constructor(
    private modalService: ModalService,
    private loaderService: LoaderService) {}

  /**
   * Servicio para manejo de errores
   * @param error
   */
  handleError(error?: any) {
    let message = "";

    if(error.status === 0){
      message = "Se ha perdido la conexión de esta página. Verifique su conexión a internet o intente ingresar más tarde.";
    }else if(error.status === 401){
      message = "Ha caducado tu sesión!!";
    }else{
      message = error.message ? error.message : 'Intente mas tarde.';
    }
    this.loaderService.stop();
    this.modalService.errorModal(message);
  }
}
