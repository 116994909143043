import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';
// OPERATORS
import { map } from 'rxjs/operators';

/**
 * Servicio encargado de la estado del usuario activo
 * @export
 * @class ConsultService
 */
@Injectable({
    providedIn: 'root'
  })
  export class HeaderService {

    /** Información del usuario */
    public changedByRefresh = new BehaviorSubject<Boolean>(null);

}