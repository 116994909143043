import { Router } from '@angular/router';
import { IEventGTM } from 'src/app/interfaces/gtmEvent.interface';
/** import Component, OnInit*/
import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser'
import { DeviceDetectorService } from 'ngx-device-detector';
/**
 * CovidMapComponent
 */
@Component({
  selector: 'app-covid-map',
  templateUrl: './covid-map.component.html',
  styleUrls: ['./covid-map.component.scss']
})
export class CovidMapComponent implements OnInit {
  /** Opcion seleccionada  */
  defaultOpt = true;

  clientID: string;
  /** constructor*/
  constructor(private sanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService,
    private router: Router) { }

  /** ngOnInit*/
  ngOnInit() {
    this.replaceScript();
  }

  sendTag(elemento: string) {
    /** Envío de evento a dataLayer para taggeo */
    let Egtm:IEventGTM;

    switch (elemento) {
      case 'mexico':
        Egtm = {
          event: 'eventgtm',
          eventCategory: 'COVID19section',
          eventAction: 'mapa Mexico'
        }

        window.dataLayer.push(Egtm);
        break;

      case 'mundial':
        Egtm = {
          event: 'eventgtm',
          eventCategory: 'COVID19section',
          eventAction: 'mapa Mundial'
        }

        window.dataLayer.push(Egtm);
        break;

      case 'infoMexico':
        Egtm = {
          event: 'eventgtm',
          eventCategory: 'COVID19section',
          eventAction: 'informacion oficial'
        }

        window.dataLayer.push(Egtm);
        break;
    }
  }

  replaceScript() {
    try{
      if(document.cookie.indexOf('_ga=')>-1){
          const gaCookie = document.cookie.split('_ga=')[1].split(';')[0].split('.');
          this.clientID = gaCookie[2] + '.' + gaCookie[3];
      }
    } catch(e){
      return 'Error al obtener el id de Google Analytics';
    }

    if (this.router.url === '/covid-map'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        login: 'no',
        clientId: `${this.clientID}`,
        pageType: 'home',
        pageCategory: `${this.router.url}`,
        pageURLCategory: `${window.location.href}`,
        device: `${this.getDevice()}`,
        numeroCotizacion: 'NA',
        folioPago: 'NA',
        numeroSolicitud: 'NA',
        numeroPoliza: 'NA'
      });
    }
  }

  getDevice() {
    if(this.deviceService.isDesktop()) {
       return 'desktop';
    } else if(this.deviceService.isMobile()) {
      return 'mobile';
    } else if(this.deviceService.isTablet()) {
      return 'tablet';
    } else {
      return 'other';
    }
  }
}
