import {Component, OnInit} from '@angular/core';
import { ConsultService } from 'src/app/services/consult.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IUserData } from 'src/app/interfaces/data-user/data-user.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

// OPERATORS
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

// CONSTANTS
import { PAYMENT } from 'src/app/constants/payment.const';
import { PdfService } from 'src/app/services/pdf.service';

/**
 * Componente para Vista de pago
 */
@Component({
  selector: 'success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss']
})
export class SuccessPaymentComponent {
  /** Contiene informacion general de usuario */
  userData: any;
  /** Mensaje de pago */
  paymentMessage: any = {};
  /** Método de pago */
  paymentMethod = {
    credit_card: 'tdc',
    debit_card: 'tdd'
  };
  /** Email de aviso a MP */
  email: string;
  /** Informacion de consulta */
  infoService;
  typeServiceCovid: any;
  showAntigenos: any;

  constructor(
    private userInfo: ConsultService,
    private route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private loader: LoaderService,
    private consult: ConsultService,
    private pdfService: PdfService,
    private coockieServ: CookieService
  ) {}

    /** Hook de inicialización de componente */
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.loader.star();
    this.showAntigenos = localStorage.getItem('Antigenos');
    localStorage.setItem('Antigenos', 'true');
    
    let info = this.coockieServ.get('emmit_payment_service');

    this.userData = JSON.parse(this.b64DecodeUnicode(info));
    this.infoService = JSON.parse(this.b64DecodeUnicode(info));
    if (this.infoService.service.type === 'Prueba PCR-RT-SARS-COV2') {
      this.typeServiceCovid = 'prueba-covid';
    }
    this.email = this.infoService.email;
    this.loader.stop();
  }

  

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }

  goToBegin() {
    this.router.navigate(['/datos-free']);
  }



  /**
   * Identificar estado del pago generado
   * @param status
   */
  setStatusPayment(status: string) {
    // Estado del pago
    switch (status) {
      case PAYMENT.STATUS.APPROVED.code:
        this.paymentMessage = PAYMENT.STATUS.APPROVED;
        // this.sendEvent();
        break;
      case PAYMENT.STATUS.IN_PROCCESS.code:
        this.paymentMessage = PAYMENT.STATUS.IN_PROCCESS;
        break;
      case PAYMENT.STATUS.REJECTED.code:
        this.paymentMessage = PAYMENT.STATUS.REJECTED;
        break;
    }
  }

  /**
   * Agregar parámetros y valores de pago
   * @param payment
   * @param details
   */
  setDataPayment(payment: any, details: any) {
    let issuance: any = {};
    // Estado del pago
    issuance.idQuotation = payment.external_reference;
    issuance.paymentStatus = payment.collection_status;
    // Detalles de pago
    issuance.idTransaction = `${details.id}`;
    issuance.paymentMethod = this.paymentMethod[details.payment_type_id];
    issuance.paymentType = details.payment_method_id;
    issuance.promotion = `${details.installments}`;
    return issuance;
  }


  /**
   * Validar que coincida el nombre del contratante con el titular
   * de la tarjeta de pago
   * @param contractor
   * @param holder
   */
  holderValid(contractor: any, holder: any) {
    const contractorName: string = `${contractor.name} ${contractor.fatherSurname}`;
    const holderName: string = holder.name;

    return contractorName.toLowerCase() === holderName.toLowerCase();
  }

  /** Descargar pdf */
  downloadPremium(){
  this.pdfService.generateTemplateWithBody('PREMIUM-PAYMENT-SERVICE', this.infoService, true, true);
  }

  goServices()
  {
    this.router.navigate(['/servicios']);
  }

}