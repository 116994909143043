import { Observable } from 'rxjs';
import { INavigation } from './navigation.interface';
import { NavigationService } from './navigation.service';
import { Component } from '@angular/core';
import { AutoUnsub } from 'src/app/utilsv2/ng-on-destroy';

@AutoUnsub()
@Component({
  selector: 'ak-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  steps: Observable<INavigation[]>;

  constructor(private navigationService: NavigationService) {
    this.steps = this.navigationService.steps;
  }

  prev(): void {
    this.navigationService.nextStep('prev');
  }

  next(): void {
    this.navigationService.nextStep('next');
  }

  get currentStepIndex(): number {
    return this.navigationService.getCurrentIndex;
  }

  get currentStep(): INavigation {
    return this.navigationService.getCurrentStep;
  }

}
