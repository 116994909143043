/**
 * Estatus de transacciones
 */
export const PAYMENT = {
  STATUS: {
    APPROVED: {
      code: 'approved',
      title: '¡Felicidades!',
      message: 'Acabas de dar el primer paso en el cuidado de tu salud'
    },
    IN_PROCCESS: {
      code: 'in_process',
      title: '¡Felicidades!',
      message: 'Estamos procesando el pago. En menos de 2 días hábiles te enviaremos por e-mail el resultado.'
    },
    REJECTED: {
      code: 'rejected',
      title: '¡Uups, Algo falló!',
      message: 'Hubo un problema al pagar con tu tarjeta.'
    }
  }
};
/**
 * Estatus de domiciliaciones
 */
 export const DOMICILED = {
   GENERATED: 'GENERADO',
   APPROVED: 'APROBADO',
   REJECTED: 'RECHAZADO'
 }