/** import Component, OnInit*/
import { Component, OnInit } from '@angular/core';
/**
 * ApprovedPaymentComponent
 */
@Component({
  selector: 'app-approved-payment',
  templateUrl: './approved-payment.component.html',
  styleUrls: ['./approved-payment.component.scss']
})
export class ApprovedPaymentComponent implements OnInit {

  /** constructor*/
  constructor() { }

  /** ngOnInit*/
  ngOnInit() {
  }

}
