
<app-banner></app-banner>
<div class="background">
<app-introduction></app-introduction>
<!--<app-how-it-works></app-how-it-works>-->
<app-users-opinion></app-users-opinion>
<app-benefits></app-benefits>
</div>
<div class="background pb-5">
<app-visit-us></app-visit-us>
<!--<app-users-opinion></app-users-opinion>-->
</div>