/**
 * Planes
 */
 export const SERVICE_PLANS = [
  {
    id: 'optimo',
    plan: 'Óptimo',
    ranges: [
      {age: 'De 0 a 17 años', price: 4229, desc: 'MXN anuales por persona'},
      {age: 'De 18 a 50 años', price: 5560, desc: 'MXN anuales por persona'},
      {age: '51 años en adelante', price: 5868, desc: 'MXN anuales por persona'}
    ],
    coverages: [
      {icon: 'ak-icon icon-check-up', coverage: `Check up anual`},
      {icon: 'ak-icon icon-atention', coverage: `Consultas de medicina general, pediatría y ginecología`},
      {icon: 'ak-icon icon-phone1', coverage: `Orientación médica telefónica y domiciliaria 24/7 <sup>1</sup>`},
      {icon: 'ak-icon icon-ambulance1', coverage: `Servicio de ambulancia por emergencia <sup>2</sup>`},
      //{icon: 'ak-icon icon-portfolio', coverage: `Atención no programada`},
      {icon: 'ak-icon icon-heart1', coverage: `Consultas de especialidad <sup>3,5</sup>`},
      {icon: 'ak-icon icon-faq-icon', coverage: `6 consultas de nutrición y 6 consultas de psicología <sup>4</sup>`},
      {icon: 'ak-icon icon-dna1', coverage: `Estudios de laboratorio <sup>5</sup>`},
      {icon: 'ak-icon icon-hand1', coverage: `Terapias físicas <sup>5</sup>`}
    ],
    descriptions: [
      '1.- El costo de atención domiciliaria es de hasta $300 MXN por evento.',
      '2.- Dos servicios incluidos sin costo al año. Nuestros médicos coordinarán el evento en caso de ser necesario.',
      '3.- Cirugía general, medicina interna, psiquiatría, otorrinolaringología, ortopedia y urología.',
      '4.- Después de las 6 consultas incluidas, podrás acceder a consultas subsecuentes a un precio preferencial.',
      '5.- Estos servicios se otorgan con referencia de un médico de AXA Keralty.',
    ]
  },
  {
    id: 'optimoMix',
    plan: 'Óptimo Mix',
    ranges: [
      {age: 'De 0 a 17 años', price: 2960, desc: 'MXN anuales por persona'},
      {age: 'De 18 a 50 años', price: 3892, desc: 'MXN anuales por persona'},
      {age: '51 años en adelante', price: 4108, desc: 'MXN anuales por persona'}
    ],
    coverages: [
      {icon: 'ak-icon icon-check-up', coverage: `Check up anual`},
      {icon: 'ak-icon icon-atention', coverage: `Consultas de medicina general, pediatría y ginecología <sup></sup>`},
      {icon: 'ak-icon icon-phone1', coverage: `Orientación médica telefónica y domiciliaria 24/7 <sup>1</sup>`},
      {icon: 'ak-icon icon-ambulance1', coverage: `Servicio de ambulancia por emergencia <sup>2</sup>`},
      //{icon: 'ak-icon icon-portfolio', coverage: `Atención no programada`},
      {icon: 'ak-icon icon-heart1', coverage: `Consultas de especialidad <sup>3,5</sup>`},
      {icon: 'ak-icon icon-faq-icon', coverage: `6 consultas de nutrición y 6 consultas de psicología <sup>4</sup>`},
      {icon: 'ak-icon icon-dna1', coverage: `Estudios de laboratorio <sup>5</sup> `},
      {icon: 'ak-icon icon-hand1', coverage: `Terapias físicas <sup>5</sup> `}
    ],
    descriptions: [
      '1.- El costo de atención domiciliaria es de hasta $300 MXN por evento.',
      '2.- Dos servicios incluidos sin costo al año. Nuestros médicos coordinarán el evento en caso de ser necesario.',
      '3.- Cirugía general, medicina interna, psiquiatría, otorrinolaringología, ortopedia y urología.',
      '4.- Después de las 6 consultas incluidas, podrás acceder a consultas subsecuentes a un precio preferencial.',
      '5.- Estos servicios se otorgan con referencia de un médico de AXA Keralty.',
    ]
  }
];
