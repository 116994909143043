import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { IJobExchangeFormInterface } from '../../interfaces/job-exchange.interface';
import { ErrorsService } from '../errors.service';

/**
 * Servicios para manejo de formulario de BOLSA DE TRABAJO
 */
@Injectable({ providedIn: 'root' })
export class JobExchangeService {
  
  /**
   *Creates an instance of JobExchangeService.
   * @param {HttpClient} http
   * @param {ErrorsService} errorsService
   * @memberof JobExchangeService
   */
  constructor(private http: HttpClient, private errorsService: ErrorsService) {}

  /**
   * Envía informacion del formulario de BOLSA DE TRABAJO para su guradado en la base de datos
   * @param data
   */
  saveJobApplication(data: IJobExchangeFormInterface) {

    return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/auth/register-job', data).pipe(
      map(
        response => {

          return response;
        },
        error => {
          this.errorsService.handleError(error);
          return false;
        }
      )
    );
  }
}
