

export const STATES_SERVICES = [
    { value: 'DF', name: 'CDMX', iva: 0.16 },
    { value: 'MC', name: 'EDOMEX', iva: 0.16 },
    { value: 'BC', name: 'Baja California', iva: 0.08 },
    { value: 'CH', name: 'Chihuahua', iva: 0.16 },
    { value: 'JC', name: 'Jalisco', iva: 0.16 },
    { value: 'NL', name: 'Nuevo León', iva: 0.16 },
    { value: 'PL', name: 'Puebla', iva: 0.16 },
    { value: 'QT', name: 'Querétaro', iva: 0.16 },
    { value: 'SR', name: 'Sonora', iva: 0.16 },
]