import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

/** SERVCIOS */
import { ModalService } from '../modal/modal.service';
import { LoaderService } from 'src/app/services/loader/loader.service';


/**
 *Servicio para validar mensajes de error
 *
 * @export
 * @class ErrorService
 */
@Injectable({
  providedIn: 'root'
})
/**
 * Aervicio para manejo de errores
 */
export class ErrorService {


    /***variable global para registro de usuario*/
    isRegister = new BehaviorSubject<boolean>(false);
  /**
   * creates an instance of errorService
   */
  constructor(private router: Router, 
              private modalService: ModalService,
              private loaderService: LoaderService) { }

  /**
 *LLamada para mostrar mensajes de error en modal
 *
 * @param {string} error
 * @memberof ErrorMessageService
 */
errorMessage(error: string) {
  if (error === 'Error: ERR-005') {
      this.isRegister.next(true);
      this.router.navigate(['/information-required']);
  } else {
    this.errors('', error, 'Aceptar');
  }
 }
 successMessage(message) {
   this.errors(
     '',
     message,
     'Aceptar',
     '',
     true
     );
  }
   /**
  *Instancia de componente compartido de mensajes de errores
  *
  * @param {string} header
  * @param {string} body
  * @param {string} [accept]
  * @param {string} [cancel]
  * @returns {*}
  * @memberof ErrorMessageService
  */
 errors(
  header: string,
  body: string,
  accept?: string,
  cancel?: string,
  isSuccess = false
): any {
  this.loaderService.stop();
  this.modalService.errorModal(body);
  //this.modalService.errorModal("No se ha podido conectar con el servicio");
  //swal({  title: header,text: body, type: 'error', confirmButtonText: accept, cancelButtonText: cancel});
}

}
