<a class="float-actions">
  <div class="float-actions__rectangle d-flex mt-1 py-2" (click)="addToCartEvent()">
    <div class="float-button__text m-auto d-flex align-items-center">
      <span class="icon-shopping-kart1 ak-icon ml-2" style="font-size: 20px;"></span>
      <p class="mb-0 text-center">Contrata tu
        <span class=" ml-2 fs-16 ak-icon icon-logo-planmed1" style="font-size: 14px;"></span>
      </p>
    </div>
  </div>
  <div class="float-actions__rectangle float-actions__green d-flex mt-1">
    <a class="float-actions__text my-auto d-flex" (click)="sendTag('sendMail')" href="mailto:hola@axakeralty.mx" target="_top">
      <img alt="Icono de sobre blanco con fondo turquesa para enviar correo" src="assets/icons/visitus/contactanos.png" class="ml-md-2 mx-2 " width="20px" height="17px" />
      <p class="mb-0 text-center ml-2">Escríbenos</p>
    </a>
  </div>
</a>
