import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
/** import LoaderService*/
import { LoaderService } from 'src/app/sharedv2/loader/loader.service';

import { RenovationPolicyService } from 'src/app/services/renovation-policy/renovationPolicy.service';
/** import ModalService*/
import { ModalService } from 'src/app/services/modal/modal.service-v2';
import {Router} from "@angular/router";
import { RecaptchaService } from 'src/app/services/recaptcha/recaptcha.service';
import { concatMap, filter, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IReCaptcha } from '../../../interfaces/recaptcha.interface';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

  @Input() policy : string;
  @Input() balance : number;
  @Input() clean : (args: any) => void;

  checkForm: UntypedFormGroup;
  formSubmited : boolean = false;
  dataPolicy : any;
  dateStart : Date;
  dateEnd : Date;
  currentDate : Date = new Date();
  errorToken : boolean = false;
  subscribedToken: boolean = false;
  subscriptionToken: any = null;
  payer: {
    name: string,
    surname: string,
    email: string,
    phone: {
      area_code: string,
      number: string
    },
    address: {
      street_name: string,
      street_number: string,
      zip_code: string
    },
    date_created: Date,
  }

  constructor(
    private fb: UntypedFormBuilder,
    private renovationPolicyService : RenovationPolicyService,
    private loaderService : LoaderService,
    private modalService : ModalService,
    private router: Router,
    private reCaptcha: RecaptchaService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.initForm()
    this.reCaptcha.tokenGenerate();
  }

  ngOnDestroy(): void{
    if(this.subscribedToken)
    {
      this.subscriptionToken.unsubscribe();
    }
  }

  initForm(){
    this.checkForm = this.fb.group({
      checkCardData: new UntypedFormControl(null, { validators: [Validators.required]}),
      checkPersonalInfo: new UntypedFormControl(null, { validators: [Validators.required]}),
      checkAuthorization: new UntypedFormControl(null, { validators: [Validators.required]}),
    });


    this.loaderService.message = "Buscando póliza";
    this.loaderService.start();
    this.renovationPolicyService.getFullInfoByContract(this.policy)
    .subscribe(
      response => {
        this.loaderService.stop();
        if(response.axaHeaderRes.resultado.codigo == "1" && response.axaHeaderRes.resultado.subcodigo == 402)
        {
          this.modalService.errorModal(
            "Tu póliza se encuentra cancelada, no te preocupes, por favor comunícate al 55 5169 3080 de Lunes a Viernes: 09:00-21:00 hrs, Sábado 09:00-15:00 hrs donde te apoyaremos",
            "Póliza cancelada"
          );
          this.clean(null);
          return;
        }
        if(response.data.length)
        {
          //let policyOnlyNumber = this.policy.replace( /^\D+/g, '');
          let policyOnlyNumberMatch = this.policy.match(/\d/g);
          let policyOnlyNumber = policyOnlyNumberMatch.join("");

          let data = null;
          for(let i=0;i<response.data.length;i++)
          {
            if(String(response.data[i].datosContratoDTO.contUsr).endsWith(String(policyOnlyNumber)))
            {
              data = response.data[i];
              break;
            }
          }
          if(!data)
          {
            this.modalService.closeBehavior.subscribe(
              response => {
                if(response)
                {
                  //this.returnToInit(null);
                  this.clean(null);
                }
              }
            );
            this.modalService.errorModal("La consulta con los parámetros enviados no contiene información.");

            return;
          }

          //let data = response.data[0];

          let dateStringStart = data.datosContratoDTO.fecIniUsr;// "31/01/2022"
          let dateStringEnd = data.datosContratoDTO.datosUsuarioDTO[0].fecLimiteServicio;
          let datePartsStart = dateStringStart.split("/");
          let datePartsEnd = dateStringEnd.split("/");

          this.dateStart = new Date(+Number(datePartsEnd[2])-1, Number(datePartsStart[1]) - 1, +Number(datePartsStart[0])); //new Date(+Number(datePartsStart[2]), Number(datePartsStart[1]) - 1, +Number(datePartsStart[0])); 
          this.dateEnd = new Date(+Number(datePartsEnd[2]), Number(datePartsEnd[1]) - 1, +Number(datePartsEnd[0])); 

          this.payer = {
            name: data.datosContratoDTO.datosContratante.nombre,
            surname: data.datosContratoDTO.datosContratante.apellidoPaterno+" "+data.datosContratoDTO.datosContratante.apellidoMaterno,
            email: data.datosContratoDTO.datosContratante.mail,
            phone: {
              area_code: "",
              number: data.datosContratoDTO.datosUsuarioDTO[0].datosAdicionalesDTO.telefono
            },
            address: {
              street_name: data.datosContratoDTO.datosUsuarioDTO[0].datosAdicionalesDTO.direccion,
              street_number: "",
              zip_code: data.datosContratoDTO.datosUsuarioDTO[0].datosAdicionalesDTO.codMunicipio
            },
            date_created: new Date(),
          }
        }
        else
        {
          this.modalService.closeBehavior.subscribe(
            response => {
              if(response)
              {
                //this.returnToInit(null);
                this.clean(null);
              }
            }
          )
          if(response.axaHeaderRes.resultado.subcodigo == "400" || 
          response.axaHeaderRes.resultado.subcodigo == "401")
          {
            this.modalService.errorModal(response.axaHeaderRes.resultado.mensaje);
          }
          else
          {
            this.modalService.errorModal("Hubo un error al recuperar los datos de su póliza");
          }

        }
      },
      error => {
        this.loaderService.stop();
        this.modalService.closeBehavior.subscribe(
          response => {
            if(response)
            {
              this.clean(null);
            }
          }
        )
        if(error.error.axaHeaderRes.resultado.subcodigo == "400" || 
          error.error.axaHeaderRes.resultado.subcodigo == "401")
        {
          this.modalService.errorModal(error.error.axaHeaderRes.resultado.mensaje);
        }
        else
        {
          this.modalService.errorModal("Hubo un error al recuperar los datos de su póliza");
        }
      }
    )
  }

  async sendCheckout(event){
    event.preventDefault();
    event.stopPropagation();

    this.formSubmited = true;

    if(this.checkForm.invalid)
    {
      return;
    }

    if(!this.subscribedToken)
    {
      this.subscriptionToken = this.reCaptcha.token
      .pipe(
        // Validar que el token no venga vacío
        filter(validToken => validToken !== undefined),
      )
      .subscribe((token: any) => {

        this.http
        .post(`https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/captcha`, {
          token: token,
          V3: true
        })
        .pipe(map((response: IReCaptcha) => response))
        .subscribe(
          response => {
            if(response.success)
            {
              this.sendCheckoutCheckedCaptcha();
            }
            else
            {
              this.modalService.errorModal("Ha ocurrido un error; favor de intentarlo más tarde.");
            }
            
          }
        )

        
      });/** */
      this.subscribedToken = true;
    }

    this.reCaptcha.tokenGenerate();

    


  }

  async sendCheckoutCheckedCaptcha()
  {
    this.loaderService.message = "Aquí vamos";
    this.loaderService.description = "No olvides tener a la mano los datos de tu tarjeta";
    this.loaderService.start();
    try{
      
      let response = await this.renovationPolicyService.getMercadoPagoReference({
        "amount": this.balance,
        "policy": this.policy, 
        payer: this.payer
      });
      this.loaderService.stop();
      //console.log(response);

      window.location.href = response.init_point;
    }catch(error)
    {
      this.loaderService.stop();
      
      this.modalService.errorModal("Hubo un error al generar la referencia de pago.");
    }
  }

  returnToHome(event){
    this.router.navigate(['/home']);
  }

  returnToInit(event){
    this.router.navigate(['/pay-policy']);
  }

}
