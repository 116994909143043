/** import Angular Component, OnInit*/
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
/**
 * CancellationPolicyComponent
 */
@Component({
  selector: 'app-cancellation-policy',
  templateUrl: './cancellation-policy.component.html',
  styleUrls: ['./cancellation-policy.component.scss']
})
export class CancellationPolicyComponent implements OnInit {

  clientID: string;

  /** constructor*/
  constructor(private router: Router, private deviceService: DeviceDetectorService) { }

  /** ngOnInit */
  ngOnInit(){
    this.replaceScript();
  }

  replaceScript() {
    try{
      if(document.cookie.indexOf('_ga=')>-1){
          const gaCookie = document.cookie.split('_ga=')[1].split(';')[0].split('.');
          this.clientID = gaCookie[2] + '.' + gaCookie[3];
      }
    } catch(e){
      return 'Error al obtener el id de Google Analytics';
    }

    if (this.router.url === '/cancellation-policy'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        login: 'no',
        clientId: `${this.clientID}`,
        pageType: 'home',
        pageCategory: `${this.router.url}`,
        pageURLCategory: `${window.location.href}`,
        device: `${this.getDevice()}`,
        numeroCotizacion: 'NA',
        folioPago: 'NA',
        numeroSolicitud: 'NA',
        numeroPoliza: 'NA'
      });
    }
  }

  getDevice() {
    if(this.deviceService.isDesktop()) {
       return 'desktop';
    } else if(this.deviceService.isMobile()) {
      return 'mobile';
    } else if(this.deviceService.isTablet()) {
      return 'tablet';
    } else {
      return 'other';
    }
  }

}
