/** import Component, OnInit */
import { Component, OnInit } from '@angular/core';
/**
 * BasicInfoComponent
 */
@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit {

  /** constructor */
  constructor() { }

  /** ngOnInit*/
  ngOnInit() {
  }

}
