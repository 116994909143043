/** import  Component, OnInit  */
import { Component, OnInit } from '@angular/core';
/**
 * PlanmedPolicyComponent
 */
@Component({
  selector: 'app-planmed-policy',
  templateUrl: './planmed-policy.component.html',
  styleUrls: ['./planmed-policy.component.scss']
})
export class PlanmedPolicyComponent implements OnInit {

  /**
   * constructor
   */
  constructor() { }

  /**
   * Código que se ejecuta al crearse el componente
   */
  ngOnInit() {
  }

}
