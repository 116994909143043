/**
 * Constantes del footer
 */
 /** Url locales */
export const urlsLoc = {
    bolsaTrabajo: '/#/bolsa-trabajo',
    intranet: '/sso/',
    miPlan: '/portal/',
    cotizador: '/cotizador-planmed/',
    referidos: '/cotizador-planmed/#/formulario/referidos',
    opinion: '/#/sugerencias',
    keralty: 'https://www.keralty.com/es/inicio'
};

 /** Url externas */
export const urlsExt = {
    avisoPrivacidad: 'https://axa.mx/aviso-de-privacidad',
    condicionesGenerales: 'https://axa.mx/condiciones-generales',
    soyAgente: 'https://portal.axa.com.mx/siteminderagent/forms/axa/SAML/login.fcc?TYPE=33554433&REALMOID=06-000d68d4-8c97-19ea-abe0-4cb40a4da0fe&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=-SM-%2bDlQVjGLUr1rM4Xso0yNwM9othZ6X5eZDLFnyigvyyKaIsvCeGFwmk7tKpVc1xOu&TARGET=-SM-HTTP%3a%2f%2fportal%2eaxa%2ecom%2emx%2faffwebservices%2fredirectjsp%2fredirect%2ejsp%3fSPID%3dsp--axakeralty--portalclientes%26ProtocolBinding%3durn-%3Aoasis-%3Anames-%3Atc-%3ASAML-%3A2%2e0-%3Abindings-%3AHTTP--POST%26SAMLRequest%3dnZLLTsMwEEV-%2FJfKCXWI3LdCaPlSoEJV4RLSwYIOcdNJaOLbxOND-%2BPW5DxWNRCbb2nTtn7kx-%2FtK5U9AYOpdED0koYGQ37KCpl-%2Bbj2K30PrzWgj4JMI999DEjtNDcCJXItKkDuCz4b31zzNGHcOuNNYRSJppMBeYai7OS9ojwBOO6mJ6dsIToketw3DBVBiFjDVKMX2ocnlrKYteO0PWcdnvY46yXddveJRNmn9bnUC6mXhznyRoT8aj7P4uxuNifRJEwitfC71ivvLXJKrXFeqESsRVKYKqnWVJTlO-%2BQI7k0WgNTWuZIF3c6eIprRLAuToY1DxQs4ofwmbjwKJUF7wCNR2bPfsH9lHWMA2JJeGI11BW7W8DzcXzfsAV3lwtrkiyPA8xZjrEUDJ1VmKTUlzTr5LmT3bY-%2BH4xP79mR4aNQ-%2B-%2FWa-%2BP5zb4DadZCaktvnP4VwaVwl-%2FWL19kYu43El5YJF-%2BE0JTyrxfOBAeBsS7GggdNog-%2Fz3n4AQ-%3D-%3D%26SMPORTALURL%3dhttps-%3A-%2F-%2Fportal%2eaxa%2ecom%2emx-%2Faffwebservices-%2Fpublic-%2Fsaml2sso%26SAMLTRANSACTIONID%3d3b79a5b8--5d67af73--d636798f--def2b0a1--df3ef750--8f',
    uneCondusef: 'https://inconformidades.axa.com.mx/ComplaintClient.aspx',
    axa: 'https://axa.mx/personas/inicio',
    facebook: 'https://www.facebook.com/axakeralty/',
    instagram: 'https://www.instagram.com/axa.keralty/'
};
