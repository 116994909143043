<div class="general-content">
    <div class="container">

        <div class="row d-flex">
          <div class="col-12">
    
            <div class="row m-0 mb-3">
              <div class="col-12">
                <p class="mt-4" 
                  style="font-family: 'Source Sans Pro SemiBold';font-size: 17px;color: #1094B9; font-weight: 600;">
    
                  <span style="cursor: pointer;" (click)="goServices()">
                    <span class="circle-arrow">
                      <span class="arrow-span">&#10140;</span> 
                    </span>
                    <span class="back-label"> Regresar a servicios</span>
                  </span>
                  
                </p>
              </div>
            </div>
    
            
          </div>
        </div>
    
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <p class="ml-3 titular-type-service">{{typeServiceCovid === 'prueba-covid' ? 'Prueba COVID-19 PCR' : showAntigenos ?  'Prueba de antígenos COVID-19' : 'Medicina general'}}</p>
            
                <div class="horizontal-line"></div>
            </div>
        </div>
    </div>

    <div class="container">

        <div class="row">
            <div class="col-12">

                <div style="min-height: 105px;" class="col-12 col-lg-6 m-auto">


                    <ol class="ProgressBar">
                        <li class="ProgressBar-step is-complete">
                          
                        </li>
                        <li class="ProgressBar-step is-complete">
                          <svg class="ProgressBar-icon"><use xlink:href="#checkmark-bold"/></svg>
                          <span class="ProgressBar-stepLabel label-alpha">Datos</span>
                        </li>
                        <li class="ProgressBar-step is-complete">
                          <svg class="ProgressBar-icon"><use xlink:href="#checkmark-bold"/></svg>
                          <span class="ProgressBar-stepLabel label-alpha">Checkout</span>
                        </li>
                        <li class="ProgressBar-step is-complete">
                          <svg class="ProgressBar-icon"><use xlink:href="#checkmark-bold"/></svg>
                          <span class="ProgressBar-stepLabel">Éxito</span>
                        </li>
                        <li class="ProgressBar-step is-complete">
                          
                        </li>
                      </ol>


                </div>

            </div>
        </div>


        <div class="row d-flex justify-content-center py-4">
            <div class="" style="
                    border: 2px solid #D7E9FA;
                    border-radius: 20px;">
                <div class="row m-0">
                    <div class="col-sm-5 d-flex justify-content-center mb-4 mb-sm-0 image-container"
                        style="background-color: #D7E9FA;">
                        <img class="img-card" src="assets/img/felicidades.png" alt=""
                        
                        style="height: 208px;
                        width: 217px;
                        margin-bottom: 20px;
                        margin-top: 30px;"
                        >
                    </div>
                    <div class="col-12 col-sm-7 text-center text-sm-left">
                        <p class="txt-success-pay">¡Felicidades!</p>
                        <p class="text-payment">
                            
                            Estamos listos para agendar tu cita: <b>{{typeServiceCovid === 'prueba-covid' ? 'Prueba COVID-19 PCR' : showAntigenos ?  'Prueba de antígenos COVID-19' : 'Medicina general'}}</b>, llamanos al <b>(55) 5169 3080</b>

                        <p class="text-payment my-4">¡Te esperamos!</p>
                        <p class="text-payment">Mercado Pago enviará el comprobante a: <b>{{infoService?.email }}</b></p>
                    
                        <div class="col py-5">
                            <button class="button-download mr-2" (click)="downloadPremium()">
                                Descargar recibo
                            </button>
                           <button class="button-custom" (click)="goToBegin()">
                            Finalizar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
