import { Router } from '@angular/router';
/** import Component, OnInit */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
/** import FAQQUEST, FAQANS, PLANMED, CONTRACT_PLANMED*/
import { FAQQUEST, FAQANS, PLANMED, CONTRACT_PLANMED } from 'src/app/constants/faq.constant';
/** import QnUsefulInformationService*/
import { QnUsefulInformationService } from '../../services/qnUsefulInformation-service/qnUsefulInformation.service';
/** import ModalService*/
import { ModalService } from 'src/app/services/modal/modal.service';
/** import LoaderService*/
import { LoaderService } from 'src/app/services/loader/loader.service';
import { DeviceDetectorService } from 'ngx-device-detector';
/**
 * Component
 */
@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.scss']
})
export class FrequentQuestionsComponent implements OnInit {
  /** Arreglo vacio de preguntas */
  FAQQUEST = [];
  /** Arreglo vacio de respuestas */
  FAQANS = [];
  /** Index de la pregunta */
  idSection = 0;
  /** Index de la respuesta */
  indexQues = 0;
  /** Index de las posibles preguntas */
  indexItemMobil = 0;
  /** Titulo cabecera */
  titleAuxiliar ="";
  /** Respuesta */
  answer = "";
  /** Pasar la pregunta seleccionada */
  nameQuestion = "";
  /** Mostrar o ocultar icono */
  ocultarIcon = false;
  /** Estado del collapse */
  panelOpenState = false;
  /**Paso para ir abriendo y cerrando el collapse */
  step = 0;
    /** Array de Planmed */
  planmed0 = PLANMED[0];
    /** Array de Planmed */
  planmed1 = PLANMED[1];
    /**Array de contractPlan0 */
  contractPlan0 =  CONTRACT_PLANMED[0];
    /**Array de contractPlan0 */
  contractPlan1 =  CONTRACT_PLANMED[1];

  clientID: string
  tableFamiliares: any = [
    {
      left:"No. Integrantes póliza  (familiares directos)",
      right:"Descuento Familiar"
    },
    {
      left:"3",
      right:"5%"
    },
    {
      left:"4",
      right:"7%"
    },
    {
      left:"5",
      right:"9%"
    },
    {
      left:"6 - 10",
      right:"12%"
    }
  ];

  /**
   * Constructor de Preguntas y respuestas
   * @param qnUsefulInformationService
   * @param modalService 
   * @param loaderService
   */
  constructor(
    private qnUsefulInformationService:QnUsefulInformationService,
    private modalService: ModalService,
    private loaderService: LoaderService,
    private deviceService: DeviceDetectorService,
    private router: Router) { }

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.FAQQUEST = FAQQUEST;
    this.FAQANS = FAQANS;
    this.initComponent()
    this.replaceScript();
  }

  /**
   * Inicializar componentes y recorridos
   */
  initComponent(){
    if (this.idSection == 0 && this.indexQues == 0){
      this.idSection = 1;
      this.indexQues = 0;
      this.foreachQuestion();
      this.foreachAns();
    } else{
      this.ocultarIcon = true;
    }
  }

 /**
  * Cambio de Seccion
  * @param id //Id section
  * @param j  //indexQuestion
  */
  changeQuestion(id: number, j:number){
    this.idSection = id;
    this.indexQues = j;
    this.foreachQuestion();
    this.foreachAns();
    this.ocultarIcon = true;
  }

  /**
   * Recorrido de preguntas 1er vez
   */
  foreachQuestion(){
    this.FAQQUEST.forEach((item, index)=>{
      if (item.id === this.idSection) {
        this.titleAuxiliar = item.title;
        this.nameQuestion = item.questions[this.indexQues];
      }
    })
  }

  /**
   * Recorrer las respuestas
   */
  foreachAns(){
    this.FAQANS.forEach((item, index)=>{
      if (item.section === this.idSection) {
        this.answer = item.answers[this.indexQues];
      }
    })
  }

  /**
   * 
   * @param usefulInformation 
   */
  qnUsefulInformation(usefulInformation:string){
    this.loaderService.star();
    this.qnUsefulInformationService.qnUsefulInformationSchema(usefulInformation).subscribe(res=>{
      if (res) {
        this.loaderService.stop();
        this.modalService.successModal('¡Gracias!');
      }
      this.loaderService.stop();
    });
    setTimeout( () => {
      this.loaderService.stop();
      this.modalService.successModal('¡Gracias!');
    },2000);
  }

  /**
   * Obtener la respuesta seleccionada
   * @param j index de la pregunta
   */
  getRespuesta(id: number, j:number){
    this.idSection = id;
    this.indexQues = j;
    this.foreachQuestion();
    this.foreachAns();
    this.ocultarIcon = true;
    this.indexItemMobil = j;
  }

  /**
   * Cambio de seccion
   * El valor {indexItemMobil} se inicializa a la primera pregunta
   * El valor {idSection} se iguala al id de la seccion
   * El valor {indexQues} se iguala a la primera pregunta de la seccion
   * 
   * @param id ID de la seccion a la que voy a 
   * comenzar
   */
  showPanel(id: number){
    this.indexItemMobil = 0;
    this.idSection = id; 
    this.indexQues = 0;
    this.foreachQuestion();
    this.foreachAns();
  }

  /**Obtener el index del collapse */
  setStep(index: number) {
    this.step = index;
  }

  /**
   * Obtengo el indice anterior al item
   * se realiza la busqueda de la pregunta
   * y de la respuesta en los arreglos
   * @param indexItem position pregunta
   */
  goToItem(indexItem, dispositivo: string){
    this.indexQues = indexItem;
    this.foreachQuestion();
    this.getRespuesta(this.idSection, indexItem);
  }

  replaceScript() {
    try{
      if(document.cookie.indexOf('_ga=')>-1){
          const gaCookie = document.cookie.split('_ga=')[1].split(';')[0].split('.');
          this.clientID = gaCookie[2] + '.' + gaCookie[3];
      }
    } catch(e){
      return 'Error al obtener el id de Google Analytics';
    }

    if (this.router.url === '/faqs'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        login: 'no',
        clientId: `${this.clientID}`,
        pageType: 'home',
        pageCategory: `${this.router.url}`,
        pageURLCategory: `${window.location.href}`,
        device: `${this.getDevice()}`,
        numeroCotizacion: 'NA',
        folioPago: 'NA',
        numeroSolicitud: 'NA',
        numeroPoliza: 'NA'
      });
    }
  }

  getDevice() {
    if(this.deviceService.isDesktop()) {
       return 'desktop';
    } else if(this.deviceService.isMobile()) {
      return 'mobile';
    } else if(this.deviceService.isTablet()) {
      return 'tablet';
    } else {
      return 'other';
    }
  }
}
