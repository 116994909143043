import { Component, OnDestroy, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-pay-policy',
  templateUrl: './pay-policy.component.html',
  styleUrls: ['./pay-policy.component.scss']
})
export class PayPolicyComponent implements OnInit, OnDestroy {

  state : any = {
    search: 1,
    noBalance: 2,
    checkout: 3,
  };
  currentState : number;

  policyNumber : string;
  balance : number;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentState = this.state.search;
    document.body.className = 'pay-policy'
  }

  ngOnDestroy():void{
    document.body.className = ''
  }

  isSearch(){
    //return this.currentState == this.state.search;
    return !this.policyNumber;
  }

  isNoBalance(){
    //return this.currentState == this.state.noBalance;
    return this.policyNumber && (this.balance==0 || this.balance<0);
  }

  isCheckout(){
    //return this.currentState == this.state.checkout;
    return this.policyNumber && this.balance && this.balance>0;
  }

  returnToHome(event){
    this.router.navigate(['/home']);
  }

  /**
   * 
   * @param data : {numContrato: "ASIEA579", saldoAlaFecha: "12886"}
   */
  getFindPolicy(data)
  {
    this.policyNumber = data.numContrato;
    this.balance = Number(data.saldoAlaFecha);

    console.log(this.policyNumber);
    console.log(this.balance);
  }

  cleanData= (args: any): void => { 
    this.policyNumber = null;
    this.balance = null;
  }

}
