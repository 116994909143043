<div class="container pb-5 pt-5">
    <div class="col-12">
        <div class="row justify-content-center text-center pb-2">
            <h1 class="title-policy">POLÍTICA DE USO Y TÉRMINOS Y CONDICIONES PARA USO DE MEDIOS ELECTRÓNICOS</h1>
        </div>
        <div class="row pb-3" *ngFor="let policy of GENERALPOLICY; index as a ">
            <div class="col-12">
                <div class="row">
                    <h5 class="title-item pt-2">{{a + 1}}.- {{policy.title}}</h5>
                </div>
                <div class="row text-justify" *ngFor="let gg of policy.description">
                    <div *ngIf="gg.includes('%%')" class="pl-3">
                        <div>
                            <p class="mt-0">{{gg.replace("%%", "")}}</p>
                        </div>
                    </div>
                    <div *ngIf="!gg.includes('%%')" >
                        <p class="mt-0">{{gg}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>