<app-loader></app-loader>
<app-header></app-header>

<div class="" [ngClass]="showNewFooter ? '' : 'app-content'">
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>
<app-float-button  *ngIf="router.url === '/home'"></app-float-button>
<app-float-covid ></app-float-covid>
<app-float-button-actions *ngIf="router.url != '/home'" ></app-float-button-actions>
<app-float-up></app-float-up>