<div class="container modal-general">
  <div class="row no-gutters">
    <!-- ICONO -->
    <div class="col-12 text-center mt-5 mb-4" id="btn_modal_close">
      <img [src]='modalIcon' class="img" alt="icono de modal">
    </div>
    <!-- TÍTULO -->
    <div class="col-12 mb-3">
      <p class="txt_style text-center fs-15">

          {{ modalTitle }}

      </p>
    </div>
    <!-- MENSAJE  -->
    <div class="col-12">
      <p class="txt_style text-center fs-12 pb-0">
        {{ modalMessage }}
      </p>
    </div>
    <!-- BOTÓN -->
    <div class="col-12 text-center mb-5 mt-4">
      <button class="button" (click)="actionButton()">
        {{ btnText }}
      </button>
    </div>
  </div>
</div>
