import {Component, OnInit} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { IEventGTM } from 'src/app/interfaces/gtmEvent.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';

/**
 * Componente para Banner de alertas
 *
 * @export
 * @class BannerAlertComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-banner-alert',
  templateUrl: './banner-alert.component.html',
  styleUrls: ['./banner-alert.component.scss']
})
export class BannerAlertComponent implements OnInit {
  /**
   * Variable para realizar el cierre del banner
   *
   * @type {boolean}
   * @memberof BannerAlertComponent
   */
  showBanner: boolean = false;



  constructor(
    public cookieService: CookieService,
    private loaderService:LoaderService
    ) {
  }
  /**
   * Método principal del componente para comprobar el estado de las cookies
   * @memberof BannerAlertComponent
   */
  ngOnInit() {
    this.loaderService.acceptCookies.subscribe(response => {
      console.log(response);
      
      if (response === true) {
        this.showBanner = false;
      } else {
        this.showBanner = true;
      }
    });
    // if (localStorage) {
    //   this.openAlert = localStorage.getItem('cookiesAccepted') === null;
    // }
  }

  goToHome(): void {
    location.assign(`${window.location.protocol}//${window.location.host}/politicas-de-uso`);

    // Envío de tag a dataLayer para evento click

    let gtmTag: IEventGTM;
    gtmTag = {
      event: 'eventgtm',
      eventCategory: 'homeButtons',
      eventAction: 'cookie_banner',
      eventLabel: 'btn-politicasUso'
    }

    window.dataLayer.push(gtmTag);
  }

  /**
   * Método para cerrar el banner y guardar opción de cookies
   * @memberof BannerAlertComponent
   */
  close() {
    this.cookieService.set( 'acceptCookie', 'true' ); // To Set Cookie
    this.loaderService.acceptCookies.next(true);
    this.showBanner = false;

    // Envío de tag a dataLayer para evento click

    let gtm: IEventGTM;
    gtm = {
      event: 'eventgtm',
      eventCategory: 'homeButtons',
      eventAction: 'cookie_banner',
      eventLabel: 'btn_cerrar'
    }

    window.dataLayer.push(gtm);
  }
}
