<div class="general-content" [style.margin-top]="changeByRefresh? '-153px':'0'">
    <div class="container-full banner d-flex align-items-center">
        <div class="container d-flex">
         <!--   <img class="img-banner" src="assets/images/Planmed_blanco_.png" alt="">
            <div class="divisory-line-banner mx-2"></div> -->
            <div>
                <span class="text-banner"><strong>Servicio de </strong>videoconsultas</span>
            </div>
        </div>

    </div>
    <div class="container mt-5 mb-">
        <img class="active-img" src="assets/img/breadcrumb/no_presenciales_.png" alt="">
        <p class="title-main my-5">
           <strong> Términos y condiciones </strong><br>
            Chat y videoconsultas

        </p>
    </div>
    <div class="container text-info py-3">
        <p class="title-text">Chat</p>
        <p>Esta es una herramienta automatizada diseñada para proporcionarte información del riesgo que puedas tener de haber contraído SARS CoV-2 (comúnmente conocido como “Covid-19” o “Coronavirus”).

        </p>
        <p>Para la implementación del cuestionario del Covid-19, el usuario no será identificado de manera alguna y AXA Salud, S.A. de C.V. (AXA Salud) no guardará las respuestas que proporcione. 
            El cuestionario deberá ser respondido puntualmente y las respuestas que obtendrás son de carácter informativo, por lo que esto no sustituye ni se considera una consulta médica. 
            Para recibir un diagnóstico al respecto de tu estado de salud por favor consulta a tu médico. 
            
            </p>
        <p class="title-text">
            Videoconsulta
        </p>
        <p>
            El servicio de videoconsulta es proporcionado por médicos y profesionales de la salud contratados por Keralty México, S.A de C.V., o 
            a través de cualquiera de sus filiales y/o subsidiarias (“Keralty México”).

        </p>
        <p>Keralty México reconoce para todos los efectos legales a que haya lugar que si decide tomar la videoconsulta acepta los términos y condiciones de acuerdo a lo expuesto a continuación.

        </p>
        <p>Keralty México le informa que el servicio de vídeoconsulta no está diseñado para atender ninguna clase de urgencias médicas. En caso de considerar que presenta alguna urgencia médica,
         le pedimos no seguir con la vídeoconsulta y acercarse de inmediato a su centro de salud u hospital más cercano.
        </p>
        <p>Keralty México solicita que previo al inicio de la videconsulta revise el consentimiento informado respecto a la prestación de este servicio
             y le indique al médico responsable de su atención la razón de la videoconsulta. Esto con la finalidad de que el médico pueda determinar si de
              acuerdo con sus necesidades y estado de salud es apropiado llevar a cabo la atención médica por medio de videoconsulta.

        </p>
        <p>Al terminar la videoconsulta el médico podrá concluir: i) que cuenta con los elementos suficientes para otorgar un diagnóstico y un tratamiento, 
            lo cual le será así informado; o ii) que no cuenta con los elementos necesarios por lo que será requerido que se lleve a cabo una consulta médica 
            presencial para determinar un diagnóstico y recomendar un tratamiento.
        </p>
        <p class="title-text">Datos personales</p>
        <p class="mb-0">Para los procesos posteriores a la implementación del cuestionario de Covid-19, será necesario recabar sus datos personales, mismos que serán tratados
            de conformidad con lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y los avisos
             de privacidad integrales de AXA Salud y de Keralty Mexico, según corresponda, ambos disponibles para consulta en axakeralty.mx. </p>
        <p class="mb-0">En un segundo proceso del chat usted podrá optar por recibir información de los productos PlanMed o bien, agendar una videoconsulta. Si usted
            desea recibir mayor información respecto a los productos, deberá proporcionar su nombre, teléfono y/o correo electrónico para que pueda ser
            contactado para dichos efectos. AXA Salud llevará a cabo el tratamiento de sus datos personales para contactarlo para proporcionarle
            información de los productos de AXA Salud y en su caso realizar su cotización, así como para las demás finalidades contempladas en su
            aviso de privacidad integral. </p>
            <p class="mb-0">En caso de que desee una videoconsulta, también tendrá que proporcionar su nombre, teléfono y/o correo electrónico.  Keralty México llevará
                a cabo el tratamiento de sus datos personales para contactarlo para agendar su videoconsulta, así como para las demás finalidades
                 contempladas en su aviso de privacidad integral. </p>
        <p>En el proceso de atención médica, a través de videoconsulta,  sus datos personales serán registrados en un expediente clínico electrónico.
            Keralty México será el Responsable del tratamiento de dichos datos y dicho expediente médico deberá conservarlo por un periodo mínimo de 5 años,
            contados a partir de la fecha del último acto médico. Lo anterior, de conformidad con lo establecido en las normas oficiales
            mexicanas NOM-024-SSA3-2010 y NOM-004-SSA3-2012.</p>
    </div>
</div>
