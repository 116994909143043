/** import Injectable */
import { Injectable } from '@angular/core';
/** import map */
import { map } from 'rxjs/operators';
/** import ErrorsService*/
import { ErrorsService } from '../errors.service';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../http-service/http.service';
/** Injectable */
@Injectable({
  /** providedIn */
  providedIn: 'root'
})
export class ExperienceService {

  /** Bandera para mostrar el dialogo de sugerencias */
  opennedDialog = new BehaviorSubject(false);

  /**
   * Constructor
   * @param http //HttpService
   * @param errorsService //ErrorsService
   */
  constructor(private http: HttpService, private errorsService: ErrorsService) { }

  /**
   * Calificar la experiencia
   * @param objData
   */
  qualifyExperience(objData){
    return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/experience/qualifyExperience', objData, false).pipe(map(res=>{
      if(res){
        return res;
      }
    }, (error) => {
      this.errorsService.handleError(error);
      return false;
    }))
  }

}
