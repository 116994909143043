/** Modelo de respuesta obtenido tras capturar respuestas del usuario */
export interface IMetabolicAgeAnswers {
  /** Género / sexo */
  gender?: EGenders;
  /** Altura en centímetros [100-200] */
  heigth?: number;
  /** Peso en kilogramos [40-200] */
  weight?: number;
  /** Número de cirgarros al día */
  cigarettes?: number;
  /** Horas promedio de ejercicio al día [0-5] */
  exercise?: number;
  /** Consume 5 o más vegetales al día */
  vegetables?: boolean;
  /** Nivel de estrés diario [1-10] */
  stress?: number;
  /** Antecedentes familiares */
  familyAfflictions?: string[];
}

/** Opciones válidas para referente a antecedentes médicos */
export enum EFamilyMembers {
  /** Madre */
  MOTHER = 'MOTHER',
  /** Padre */
  FATHER = 'FATHER',
  /** Hijo */
  SON = 'SON',
  /** Hija */
  DAUGHTER = 'DAUGHTER',
  /** Ninguno */
  NONE = 'NONE'
}

/** Géneros seleccionables */
export enum EGenders {
  MALE = 'Male',
  FEMALE = 'Female'
}
/** Calculo medico para el estado de salud de una persona */
export interface IHealthKeeperObject {
  /** Género de la persona */
  gender: EGenders;
  /**
   * Edad biológica
   * Rango: 18 - 80 (`int o con centésimas`)
   */
  age: number;
  /**
   * Altura en centímeros
   * Rango: 100 - 200 (`int o con centésimas`)
   */
  height: number;
  /**
   * Peso en kg
   *  Rango: 40 -200 (`int o con centésimas`)
   */
  weight: number;
  /**
   * Cantidad promedio de horas que el usuario
   * realiza ejercicios físicos moderados a vigorosos por día
   * Rango: 0 - 5
   */
  metabolicEquivalentDailyHours: number;
  /**
   * Número con el nivel de estrés que la persona
   * Rango: 1 - 10
   */
  stressLevel: number;
  /**
   * Número de familiares inmediatos que tengasn problemas cardiovasculares
   * Rango: 0 - 4
   */
  familyCvdCount: number;
  /**
   * Número de cigarros por día
   */
  cigarettesPerDay: number;
  /**
   * Indica si la persona consume 5 vegetales o frutas todos los días
   */
  haveFivePlusFruitVegdaily: boolean;
}
