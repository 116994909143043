import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {TokenService}  from '../token/token.service';
import { map, concatMap } from 'rxjs/operators';

/** Injectable*/
@Injectable({
  /**providedIn */
  providedIn: 'root'
})
export class EmailService {
  
  /**
   * Creates an instance of EmailService.
   * @param {HttpClient} http
   * @memberof EmailService
   */

	currentUserValue = {
    name: 'home-keralty',
    user: 'home-keralty',
    mx: '111111',
    email: 'hola@axakeralty.mx',
    rol: 'home-keralty',
    sid: '0000000000000111111'
};


  constructor(private http: HttpClient, private token: TokenService) { }

  /**
   * Servicio para enviar email desde bolsa de trabajo
   *
   * @param {string} bodyEmail
   * @returns
   * @memberof EmailService
   */
   sendEmail(bodyEmail:string) {
      let body = {
        "axaHeaderReq": {
          "usuario": "MXS00000000A",
          "uuid": "11111111-1111-1111-1111-111111111111",
          "fechaHora": Date()
        },
        "data": bodyEmail
    }
    return this.token.getAuthorize()
    .pipe(
      map(token => 
        new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Access-Token': `Bearer ${token.access_token}`,
          'x-channel': 'home-page'
          
        })),
      concatMap(headers => this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/email/sendEmail', body, { headers }))
    )
    //  return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/email/sendEmail', body, {headers});
  }

  /**
   * Returns environment from APP Server
   * @example DEVELOPMENT, DEV, QA, PRE, PRO
   *
   * @returns
   * @memberof EmailService
   */
  getEnvironment(): Observable<{environment: string, date: Date | string}>{
    return this.http.get('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/envi') as any;
  }


}

