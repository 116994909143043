<div class="general-content">
    <div class="container-full first-setcion">
        <div class="text-center pt-5">
            <span class="planmed Planmed .Planmed-first px-2 mr-2 planmed-title"><span class="planmed Registered"></span></span>
        </div>
            <div class="row m-0 pt-4 first-setion-img">
                <div class="col-lg-5 d-flex flex-column d-lg-block justify-content-center text-center">
                    <img src="assets/img/infografia/familia_completa.png" alt="">
                    <p class="text-left d-none d-lg-block">Un nuevo sistema privado de salud integral, para el cuidado de ti y los que quieres.</p>
                    <p class="text-right-responsive d-block d-lg-none">Un nuevo sistema privado de salud integral, para el cuidado de ti y los que quieres.</p>
                </div>
                <div class="col-lg-7 pt-5 d-flex flex-column d-lg-block justify-content-center text-center">
                    <img src="assets/img/infografia/doctor_completo_.png" alt="">
                    <p class="text-right d-none d-lg-block">Por un costo accesible tendrás atención médica
                        de calidad, excelentes instalaciones y <strong>muchos beneficios.</strong></p>
                    <p class="text-right-responsive d-block d-lg-none">Por un costo accesible tendrás atención médica
                        de calidad, excelentes instalaciones y <strong>muchos beneficios.</strong></p>
                </div>
            </div>
        
        <app-infographic-planmed></app-infographic-planmed>
        
    </div>
    <div class="container-full second-section">
        <div class="container py-5">
            <div class="row m-0">
                <div class="col-12 col-md-7">
                    <div class=" text-center">
                        <p class="title-map mb-1">Nuestras ubicaciones</p>
                        <p style="color: #FFFFFF;font-size: 29px;">¡Muy pronto estaremos cerca de ti!</p>
                    </div>
                    <img src="assets/mapa_big.png" style="width: 100%;" alt="">
                </div>
              
                <div class="col-12 col-md-5 text-center py-md-0 py-5 d-flex align-items-center justify-content-center flex-column">
                    <img src="assets/pin_big.png" style="width: 48px; height: 68px;" alt="">
                    <div class="map-button d-flex justify-content-center align-items-center mt-3" (click)="goToMap()">
                        Ver ubicaciones
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-full third-section py-5">
        <div class="row m-0">
            <div class="col-12  text-center d-flex justify-content-center py-3">
                <img src="assets/img/infografia/circulos_verdes_.png" alt="">
            </div>
            <div class="col-12 text-center">
                <p class="text-number">(55) 5169 3080</p>
                <p class="text-data-bottom">Consulta tarjetas participantes, coberturas y requisitos de contratación</p>
            </div>
        </div>
    </div>
</div>
