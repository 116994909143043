<div class="container-xl">
    <div class="row justify-content-center my-5 pt-4 px-5">
        <span class="covid-body__title">Protocolo de atención a usuarios <img
                src="assets/logos/Planmed logo.png" alt="Logotipo de Planmed turquesa" class="mt-n3 covid-body__img"><span
                class="covid-body__title--bold"> ante COVID-19</span></span>
    </div>
    <div class="row">
        <div class="col-12 px-5">
            <p class="covid-body__txt">Para protegerte a ti y a tu familia, y siguiendo las recomendaciones oficiales,
                ponemos a tu disposición diferentes medios para acceder a la atención médica que necesitas.</p>
            <p class="covid-body__txt">Te solicitamos tomar en cuenta las siguientes medidas de prevención:</p>
        </div>
    </div>
    <div class="row justify-content-center covid-body__txt--center mt-3 px-5">
        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-1">
            <img class="img-fluid" src="assets/covid/1_manos.png" alt="" heidht="150px" width="150px">
            <br>
            <p class="covid-body__txt mt-3"><span class="covid-body__txt--bold">Lava las manos con regularidad,</span> usando agua y jabón o gel desinfectante.</p>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-1">
            <img class="img-fluid" src="assets/covid/2_limpia_.png" alt="" heidht="150px" width="150px">
            <br>
            <p class="covid-body__txt mt-3"><span class="covid-body__txt--bold">Limpia y desinfecta</span> objetos de uso común en especial en lugares públicos.</p>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-1">
            <img class="img-fluid" src="assets/covid/3_cubre_.png" alt="" heidht="150px" width="150px">
            <br>
            <p class="covid-body__txt mt-3"><span class="covid-body__txt--bold">Cubre boca y nariz</span> al toser o estornudar.</p>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 mt-1">
            <img class="img-fluid" src="assets/covid/4_no_.png" alt="" heidht="150px" width="150px">
            <br>
            <p class="covid-body__txt mt-3"><span class="covid-body__txt--bold">No saludes de beso y de mano</span> a otras personas.</p>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 mt-1">
            <img class="img-fluid" src="assets/covid/5_virus_.png" alt="" heidht="150px" width="150px">
            <br>
            <p class="covid-body__txt mt-3"><span class="covid-body__txt--bold">Si estás en contacto con personas con gripe</span> que no usan tapabocas, úsalo tú.</p>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 mt-1">
            <img class="img-fluid" src="assets/covid/6_enfermedad_.png" alt="" heidht="150px" width="150px">
            <br>
            <p class="covid-body__txt mt-3"><span class="covid-body__txt--bold">Si tienes alguna enfermedad respiratoria</span> usa cubrebocas.</p>
        </div>
        <div class="col-lg-3 col-sm-6 col-12 mt-1">
            <img class="img-fluid" src="assets/covid/7_grupos_.png" alt="" heidht="150px" width="150px">
            <br>
            <p class="covid-body__txt mt-3"><span class="covid-body__txt--bold">Evita asistir a sitios concurridos</span> si tienes tos, fiebre y secrección nasal.</p>
        </div>
    </div>
    <div class="row justify-content-center my-5">
        <span class="covid-body__title"><span class="covid-body__title--bold">¡En AXA Keralty <br> </span>
            nuestro compromiso es cuidarte!</span>
    </div>
    <div class="row">
        <div class="col-12 px-5">
            <p class="covid-body__txt"><span class="covid-body__txt--bold">Ante la presencia de cualquier síntoma de
                    enfermedad respiratoria, te pedimos
                    por favor seguir los pasos que a continuación se enlistan:</span></p>
            <p class="covid-body__txt">1. <span class="covid-body__txt--bold">Quédate en casa.</span> “Te cuidas, me
                cuidas”</p>
            <p class="covid-body__txt">2. Llama a la línea exclusiva <span class="covid-body__txt--bold">AXA Keralty 55
                    5169 3080</span> para recibir
                orientación médica telefónica, seleccionando la opción 2.</p>
            <p class="covid-body__txt">3. Nuestro personal médico <span class="covid-body__txt--bold">te realizará una
                    serie de preguntas</span> para
                determinar si te encuentras en riesgo de haber contraído COVID-19. Si eres menor de 60 años, no
                cuentas con enfermedades cardiovasculares, enfermedades pulmonares crónicas, diabetes o alguna
                inmunodeficiencia y no tienes sospecha por viaje o contacto con una persona con diagnóstico confirmado
                de COVID-19, la recomendación será<span class="covid-body__txt--bold"> reposo en casa y la toma de
                    líquidos.</span></p>
            <p class="covid-body__txt">4. Si uno de nuestros médicos sospecha que te encuentras contagiado por
                COVID-19, <span class="covid-body__txt--bold">te dará las indicaciones correspondientes y te orientará
                    sobre los pasos a seguir.</span></p>
            <p class="covid-body__txt">Recuerda que si presentas una urgencia,<span class="covid-body__txt--bold">
                    Planmed® te ofrece servicio de
                    ambulancia para traslados en la CDMX y Área Metropolitana.</span></p>
            <p class="covid-body__txt">
                <span class="covid-body__txt--bold"> La prevención es la clave para nuestro cuidado.</span> Sigue las
                recomendaciones oficiales para cuidar de ti y
                tu familia; con Planmed® te ayudamos a lograrlo. Si tienes cualquier duda sobre síntomas o necesitas
                orientación médica puedes llamar al<span class="covid-body__txt--bold"> 5169 3080 las 24 horas del
                    día.</span></p>
        </div>
    </div>
    <div class="container">
        <div class="row my-5 pb-5">
            <div class="col-12 col-lg-4 ml-auto text-center">
                <p class="covid-body__links mx-auto mb-0 mb-lg-3">Mapa en tiempo real del COVID-19.</p>
                <button class="btn covid-body__button" [routerLink]="['/covid-map']">CONSÚLTALO AQUÍ</button>
            </div>
            <div class="col-12 col-lg-4 mr-auto text-center my-4 my-lg-0">
                <p class="covid-body__links mx-auto">Información oficial publicada por el Gobierno de México.</p>
                <a class="btn covid-body__button pt-2" rel="noopener noreferrer" href="https://www.gob.mx/salud/documentos/nuevo-coronavirus" target="_blank">CONSÚLTALO AQUÍ</a>
            </div>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-12 px-0">
            <img class="img-fluid max-width: 100% heidht:auto" src="assets/covid/escritorio_.png"
                alt="medicPlanmed">
        </div>
    </div>
</div>
