<div class="container">
    <div class="row">
        <div class="col-12">
            <p class="title d-none d-md-block">¡Gracias por cuidar tu salud!</p>
            <p class="announcement"><span>Tu póliza no presenta ningún saldo vencido.</span></p>
            <p class="description mb-0 mt-3"><b>Fecha de consulta:</b></p>
            <p class="description"><span>{{currentDate | date:'dd/MM/yyyy'}}</span></p>
            <p class="image-weapper mt-5">
                <img src="assets/searchpolicy/validar_2.svg" alt="">
            </p>
            <p class="mt-5 button-wrapper">
                <button class="btn" (click)="returnToHome($event)" >Finalizar</button>
            </p>
        </div>
    </div>
</div>