import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

import { INavigation } from './navigation.interface';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private _steps = new BehaviorSubject<INavigation[]>([]);

  initSteps(steps: INavigation[]): void {
    let newSteps: INavigation[] = [];
    let orderSteps = _.orderBy(steps, 'priority', 'asc');
    let url = location.href.split('://')[1].split('/')[1];
    newSteps = orderSteps.map(step => ({...step, active: url.includes(step.route), checked: false}));
    let currentIndex = newSteps.findIndex(step => step.active);
    this._steps.next( this.getNewSteps(newSteps, 'actual', currentIndex) );
  }

  get getCurrentIndex(): number {
    return this._steps.value.findIndex(step => step.active);
  }

  get getCurrentStep(): INavigation {
    return this._steps.value[this.getCurrentIndex];
  }

  get length(): number {
    return this._steps.value.length;
  }

  get steps(): Observable<INavigation[]> {
    return this._steps.asObservable();
  }

  nextStep(type: 'next' | 'prev'): void {
    this._steps.next(_.cloneDeep( this.getNewSteps(this._steps.value, type) ));
  }

  getNewSteps(steps: INavigation[], type: 'prev' | 'next' | 'actual', customIndex?: number): INavigation[] {
    let newSteps: INavigation[] = [];
    let actualStep = customIndex || 0;
    let nextStep = this.getCurrentIndex < this.length - 1 ? this.getCurrentIndex + 1: this.length - 1;
    let prevStep = this.getCurrentIndex > 0 ? this.getCurrentIndex - 1 : 0;
    let stepToCompare = type === 'next' ? nextStep : (type === 'prev' ? prevStep : actualStep);

    for (let index = -1; ++index < steps.length;) {
      switch (true) {
        case index < stepToCompare:
          newSteps = [...newSteps, { ...steps[index], checked: true, active: false }];
          break;
        case index === stepToCompare:
          newSteps = [...newSteps, { ...steps[index], checked: false, active: true }];
          break;
        default:
          newSteps = newSteps = [...newSteps, { ...steps[index], checked: false, active: false }];
          break;
      }
    }

    return newSteps;
  }
}
