import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from '../../../shared/header/header.service';

/**
 * Componente para Datos de usuario
 */
@Component({
  selector: 'consult-conditions',
  templateUrl: './consult-conditions.component.html',
  styleUrls: ['./consult-conditions.component.scss']
})
export class ConsultConditionsComponent {

  changeByRefresh: boolean = false;

    constructor(
        private router: Router,
        private headerService: HeaderService,
      ) {}


    ngOnInit(){
      this.headerService.changedByRefresh.subscribe(
        (response : boolean) => {
          this.changeByRefresh = response;
        }
      )
    }
    
}