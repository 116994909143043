/**
 * Archivo para constantes comunes para cuestionarios y formularios
 */
import {EJobApplicationCategories} from '../interfaces/job-exchange.interface';

/** Longitud del número telefónico de contacto */
export const PHONE_NUMBER_LENGTH = 10;
/** Longitud del número telefónico de contacto */
export const PROFESSIONAL_ID_LENGTH = 8;

/** Objeto de configuración para fechas min/max a mostrar en datepickers [18-80 años] */
export const DATE_RANGE = {
  MIN: {year: new Date().getFullYear() - 80, month: new Date().getMonth() + 1, day: new Date().getDate()},
  MAX: {year: new Date().getFullYear() - 18, month: new Date().getMonth() + 1, day: new Date().getDate()}
};

/** URL para AVISO DE PRIVACIDAD */
export const PRIVACY_NOTICE_URL = 'https://axa.mx/aviso-de-privacidad';
/** URL para TERMINOS Y CONDICIONES */
export const TERMS_CONDITIONS_URL = '/politicas-de-uso';
/** URL para información extra en SUGERENCIAS Y QUEJAS */
export const UNE_URL = 'hhttps://inconformidades.axa.com.mx/ComplaintClient.aspx';

/** Categorias disponibles para formulario de QUEJAS / SUGERENCIAS
export const SUGGESTION_CATEGORIES = [
  {id: ESuggestionCategories.SUGGESTION, name: 'Sugerencia'}, {id: ESuggestionCategories.COMPLAINT, name: 'Queja'},
  {id: ESuggestionCategories.COMMENT, name: 'Solicitud'}, {id: ESuggestionCategories.CONGRAT, name: 'Felicitación'}
];*/

/** Categorias disponibles para formulario de BOLSA DE TRABAJO */
export const JOB_EXCHANGE_CATEGORIES = [
  {id: EJobApplicationCategories.PROFESSIONAL, name: 'Profesional de la salud'},
  {id: EJobApplicationCategories.ADMIN, name: 'Administrativo'}
];

/** Avisos de privacidad simplificados para formularios */
export const SIMPLIFIED_PRIVACY_NOTICE = {
  CONTACT: `AXA Salud, S.A. de C.V., con domicilio en Félix Cuevas No. 366, Piso 3, Colonia Tlacoquemécatl, C.P. 03200,
  Alcaldía Benito Juárez, Ciudad de México, México, llevará a cabo el tratamiento de sus datos personales
          para contactarlo con objeto de ofrecerle sus productos y para realizar su cotización, así como para las demás
          finalidades contempladas en el aviso de privacidad integral, el cual puede consultar en
          <a class="privacy-disclaimer ml-0" href="https://axa.mx/" target="_blank" rel="noopener noreferrer">
            axa.mx
          </a>
          en la sección
          <a
            class="privacy-disclaimer ml-0"
            href="https://axa.mx/aviso-de-privacidad"
            target="_blank"
            rel="noopener noreferrer"
          >
            Aviso de Privacidad.
          </a>`,
  JOB_EXCHANGE: `AXA Salud, S.A. de C.V., con domicilio en Félix Cuevas No. 366, Piso 3, Colonia Tlacoquemécatl, C.P. 03200,
  Alcaldía Benito Juárez, Ciudad de México, México, llevará a cabo el tratamiento de sus datos
              personales para selección y en su caso reclutamiento, así como para las demás finalidades contempladas en
              el aviso de privacidad integral, el cual puede consultar en
              <a class="privacy-disclaimer ml-0" href="https://axa.mx" target="_blank" rel="noopener noreferrer">
                axa.mx
              </a>
              en la sección
              <a
                class="privacy-disclaimer ml-0"
                href="https://axa.mx/anexos/aviso-de-privacidad"
                target="_blank"
                rel="noopener noreferrer"
              >
                Aviso de Privacidad.
              </a>`,
  SUGGESTIONS: `AXA Salud, S.A. de C.V., con domicilio en Félix Cuevas No. 366, Piso 3, Colonia Tlacoquemécatl, C.P. 03200,
  Alcaldía Benito Juárez, Ciudad de México, México, llevará a cabo el tratamiento de
              sus datos personales para dar seguimiento a su queja, solicitud o sugerencia, así como para contactarlo en
              caso de requerirlo para las finalidades antes descritas. El aviso de privacidad integral lo puede
              consultar en
              <a class="privacy-disclaimer ml-0" href="https://axa.mx" target="_blank" rel="noopener noreferrer">
                axa.mx
              </a>
              en la sección
              <a
                class="privacy-disclaimer ml-0"
                href="https://axa.mx/anexos/aviso-de-privacidad"
                target="_blank"
                rel="noopener noreferrer"
              >
                Aviso de Privacidad.
              </a>`
};

/** Texto a mostrar en modales de éxito tras envío */
export const SUCCESS_MESSAGE = {
  SUGGESTIONS: 'Sugerencia enviada correctamente, gracias por tus comentarios.',
  JOB_EXCHANGE: 'Solicitud enviada correctamente, nos contáctaremos contigo.'
};
