import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// OPERATORS
import { map } from 'rxjs/operators';
import { CipherService } from '../cipher/cipher.service';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  cupones: Observable<any>;
  /** Agregar url para la build */
  url: string = `${window.location.protocol}//${window.location.host}/`;

  constructor(private http: HttpClient, private cipher: CipherService) {}

  /**
   * Generar pago y obtener URL de redirección a Mercado Pago
   * @param data
   */
  async generatePayment(data: any) {

    // console.log("DATA A ENVIAR PARA PAGO DE SERVICIOS ====>", data);
    
    let sim_key = this.cipher.cr_UUID();
    
    let encr_body = await this.cipher.encrBody(data, sim_key);

    let encr_key = await this.cipher.setEnc(sim_key);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Key-Sim': `${btoa(encr_key)}`
    });
    
    return await this.http.post(`https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/pago/pago-servicios-video`, { data: encr_body } , { headers }).pipe(
      map((response: any) => {
        let JSONResponse = JSON.parse(atob(response.data));
        return JSONResponse;
      })
    ).toPromise();
  }

  /**
   * Obtener detalles de la transacción realizada con Mercado Pago
   * @param data
   */
  getPaymentDetails(data: any) {
    return this.http.post('https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/pago/details-servicios-video', data).pipe(
      map(response => {
        return response;
      })
    );
  }


  /**
  * Obtiene los datos del Cupon de la coleccion  akPlanmedCoupons
  *
  * @param {*} data  coupon: NNNNNN
  * @returns 
  * @memberof PaymentService
  */
   getCouponDB(data:any){
  return this.http.post(`https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/content-manager/get-coupon`,data).pipe(
    map(response => {
      return response;
    })
  );
  } 

  /**
   * Genera la url de referencias bancarias para pago en efectivo
   * @param data 
   */
  generatePaymentReferences(data:any){
    console.log("datePaymentReference", data);
    let today = new Date();
		today.setDate(today.getDate() + (30));
    
    let dataReference = {
      axaHeaderReq: {
          ramo: "salud",
          medio: "",
          segmento: "",
          rol: "",
          usuario: "MXI00000123A",
          sistemaId: "",
          version: "1.0",
          uuid: "f1a9f044-0a60-0030-0750-0a95f5580115"
      } ,
      data: {
          transaction_amount:
          data.selectedPlan.premiumNetTotalDecimal && data.selectedPlan.premiumNetTotalDecimal !== 0
            ? this.trunc(data.selectedPlan.premiumNetTotalDecimal, 2) : data.selectedPlan.premiumNetTotal,
          payer_email: data.contractor.email,
          policy_number: data.idQuote,
          contractor_name: data.contractor.name,
          type_contract:"FAMILIAR",
          plan: data.selectedPlan.plan === 'inter' ? 'intermedio' : data.selectedPlan.plan,
          version: 0,
          executive_id: data.executiveId,
          executive_name: data.executiveName,
          validity_start_period: new Date(),
          validity_end_period: today, //10 dias 
          validity_end_policy: data.validityEnd,
          portal_bh: true, 
          paymentPeriod: data.payment.paymentPeriod, 
          origin: 'PORTAL'
      }
    }

  

   return this.http.post(`https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com/v1/payment/create_payment_references`,dataReference).pipe(
      map(response => {
        return response;
      })
    );
  }

  trunc (x, posiciones = 0) {
    var s = x.toString()
    var l = s.length
    var decimalLength = s.indexOf('.') + 1
  
    if (l - decimalLength <= posiciones){
      return x
    }

    var isNeg  = x < 0
    var decimal =  x % 1
    var entera  = isNeg ? Math.ceil(x) : Math.floor(x)

    var decimalFormated = Math.floor(
      Math.abs(decimal) * Math.pow(10, posiciones)
    )

    var finalNum = entera + 
      ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
    
    return finalNum
  }
}
