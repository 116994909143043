<div class="container mt-5">
  
  <section class="list">
    <div class="row mb-5 pb-3">
      <div class="col-2 col-md-1">
        <div class="list__oval ml-4 ml-lg-5 d-flex list__tittle number-list">
          <div class="span m-auto">
            1
          </div>
        </div>
      </div>
      <div class="col-10 col-md-5">
        <div class="col-12 list__tittle">
          Un buen principio <br> para tu salud.
        </div>
        <div class="col-12 list__detail pt-1 pt-md-4 pr-5 pr-sm-2">
          De la mano de nuestros médicos, conoce el programa de seguimiento hecho para ti. ¡Comienza la atención de tu salud con un <i>check up</i> anual!
        </div>
      </div>
      <div class="col-md-6 col-12 text-center">
        <img src="assets/img/pm/IMG2.jpg" alt="Atención pediática de calidad en Centro de Atención Médica Roma" class="list__img">
      </div>
    </div>
    <div class="row mb-5 pb-3">
      <div class="col-2 col-md-1 order-lg-1">
        <div class="list__oval ml-4 ml-lg-5 d-flex list__tittle number-list">
          <div class="span m-auto">
            2
          </div>
        </div>
      </div>
      <div class="col-10 col-md-5 order-lg-2">
        <div class="col-12 list__tittle">
          De la mano contigo para el cuidado de tu salud
        </div>
        <div class="col-12 list__detail pt-1 pt-md-4 pr-5 pr-sm-2">
          Contamos con diferentes servicios médicos brindados a través de nuestras Clínicas y nuestra red de proveedores externos.
        </div>
      </div>
      <div class="col-md-6 col-12 order-lg-0 text-center">
        <img src="assets/img/pm/IMG3.jpg" alt="Servicio de Radiología proporcionado por Planmeden CDMX" class="list__img">
      </div>
    </div>
    <div class="row my-5 pt-5 pb-3">
      <div class="col-2 col-md-1">
        <div class="list__oval ml-4 ml-lg-5 d-flex list__tittle number-list">
          <div class="span m-auto">
            3
          </div>
        </div>
      </div>
      <div class="col-10 col-md-5">
        <div class="col-12 list__tittle">
          Salud y bienestar en todas<br>
          las etapas de tu vida.
        </div>
        <div class="col-12 list__detail pt-1 pt-md-4 pr-5 pr-sm-2">
         ¡En AXA Keralty te queremos acompañar siempre, es por ello que tú eliges el modelo de cuidado que más se acomode a tus necesidades!
        </div>
      </div>
      <div class="col-md-6 col-12 text-center">
        <img src="assets/img/pm/IMG1.jpg" alt="Consulta de geriatria o consultas para personas mayores en centro de atención medica altavista" class="list__img">
      </div>
    </div>
  </section>
</div>
