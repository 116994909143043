import { IEventGTM, InterImpressions } from 'src/app/interfaces/gtmEvent.interface';
/** import @angular/core  */
import { Component, OnInit } from '@angular/core';
/** import contants  */
import { urlsLoc } from 'src/app/constants/urls.constants';
/**
 * Component ModalDiscountComponent
 */
@Component({
  selector: 'app-modal-discount',
  templateUrl: './modal-discount.component.html',
  styleUrls: ['./modal-discount.component.scss']
})
export class ModalDiscountComponent implements OnInit {
  /** Url del servidor actual */
  urlServer: string = '';

  impressionsObj: InterImpressions;
  /** constructor */
  constructor() {
    this.urlServer = 'https://preprod.axakeralty.mx';
  }

  /** ngOnInit  */
  ngOnInit(): void {
    this.impressions();
  }

  /**
   * funtion addToCartEvent
   * href this.server.url + urlsLoc.cotizador
   */
  addToCartEvent() {
    const eventGtm: IEventGTM = {
      event: 'eventgtm',
      eventCategory: 'floatButtons',
      eventAction: 'adquiere tu Planmed',
      eventLabel: 'btn_banner_plans'
    }

    window.dataLayer.push(eventGtm);

    this.clickImpressions(this.impressionsObj);

    location.href = this.urlServer + urlsLoc.cotizador;
  }

  impressions(){
    this. impressionsObj = {
      id: 'obten 10% de descuento',
      name: 'obten 10% de descuento',
      creative: 'venta en linea',
      position: 'modal_discount'
    }

    window.dataLayer.push({
      event: 'promoImpressions',
      ecommerce: {
        promoView: {
          promotions: [this.impressionsObj]
        }
      }
    });
   }

  clickImpressions(promoObj) {
     window.dataLayer.push({
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [
           {
             id: this.impressionsObj.id,
             name: this.impressionsObj.name,
             creative: this.impressionsObj.creative,
             position: this.impressionsObj.position
           }]
        }
      }
    });
  }
}
