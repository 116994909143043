<div *ngIf="loader" class="loader__wrapper">
  <div class="loader__container">
    <h2 class="loader__message text-center">{{ message }}
      <p class="title-description" style="font-size: 20px;font-weight: 100;">{{description}}</p>
    </h2>
  </div>
  <div class="rightToLeft">
    <span class="ak-icon ak-cloud icon-cloud"></span>
  </div>
  <div class="rightToLeftCloud">
    <span class="ak-icon ak-cloud icon-cloud"></span>
  </div>
 
  <div class="leftToRight up-dowm">
    <span class="ak-icon ak-ambulance icon-ambulance "></span>
  </div>
 
  <svg
    width="180px"
    height="180px"
    viewBox="0 0 54 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
  >
    <defs></defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
      <path
        class="loader__beat"
        d="M0.5,38.5 L16,38.5 L19,25.5 L24.5,57.5 L31.5,7.5 L37.5,46.5 L43,38.5 L53.5,38.5"
        id="Path-2"
        stroke-width="2"
        sketch:type="MSShapeGroup"
      ></path>
    </g>
  </svg>
</div>
