<div class="background py-4">
  <div class="card-faqs">
    <div class="card-faqs__header pt-3">
      <div class="col">
        <div class="row justify-content-center mx-0">
          <span class="card-faqs__frequent-questions d-flex">Preguntas frecuentes</span>
        </div>
        <div class="row justify-content-center d-flex mx-0">
          <span class="card-faqs__need-help mt-n2">¿Necesitas ayuda?</span>
        </div>
      </div>
    </div>
    <div class="card-body d-flex" >
      <div class="container card-body-faqs" >
        <div class="row">
          <div class="col-5 card-body-faqs__content-collapse px-0">
            <mat-accordion class="pt-2">
              <mat-expansion-panel
                *ngFor="let faqs of FAQQUEST; index as i"
                (opened)="panelOpenState === true"
                (closed)="panelOpenState === false"
                [expanded]="faqs.id  === 1" (opened)="setStep(0)" >
                <mat-expansion-panel-header class="px-3">
                <mat-panel-title class="mr-0">
                    <div class="d-lg-none">
                      <span class="icon-faq-icon ak-icon mr-2" style="font-size: 40px;color: #FFFFFF;"></span>
                    </div>
                    <span class="card-body-faqs__title-collapse mt-2" (click)="showPanel(faqs.id)">{{faqs.title}}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <ul class="list-group list-group-flush pt-0 pb-0">
                    <li class="collapse-body__list-group-item collapse-body__list-group-item-action pb-0 pt-0 pt-md-2 px-0"
                        *ngFor="let nameQuestion of faqs.questions; index as j"
                        [class.active]="idSection === i && indexQues === j"
                        data-toggle="collapse">
                      <div class="row">
                        <div class="col ml-1 mb-1 mt-3 mt-md-0">
                          <span *ngIf="answer.includes('sistema-integral') && j === 0" class="card-text card-body-faqs__description pt-2 px-0 col-12 mb-3 d-md-none" style="height: auto; text-align: justify;">
                            AXA Keralty es la alianza entre AXA Salud, S.A. de C.V., como encargada del aseguramiento con Planmed®, y Keralty México, S.A. de C.V., 
                            como responsable de prestar los servicios médicos en conjunto con la red de proveedores contratados. Algunos productos de seguro de gastos
                             médicos mayores de AXA Seguros como Cuidado Integral+ y Flex Plus incluyen como beneficio servicios como consultas médicas y estudios
                              diagnósticos dentro de las clínicas de Keralty México y la red de proveedores contratada.
                          </span> <br> <br>
                        
                          <span class="card-body-faqs__body-collapse text-decoration__{{j + 'st' + indexItemMobil}}  color-subtitle" (click)="getRespuesta(faqs.id, j)">{{j+1}}. {{ nameQuestion }}</span>
                          <div *ngIf="j === indexItemMobil" class="pb-4 pt-2 d-md-none">           
                          <div *ngIf="answer.includes('consulta-especialidad')" class="pt-2 col">
                            <span class="card-text card-body-faqs__description">
                              Las consultas de especialidad requieren referencia médica. Te invitamos a programar una cita con nuestros médicos de atención primaria 
                              (médico general, ginecólogo o  pediatra) y ellos determinarán el plan de seguimiento de acuerdo con tu condición de salud, y si fuera el 
                              caso, te referirán a las especialidades o servicios de salud que requieras. <br> <br>
                              Recuerda validar las coberturas y beneficios que se incluyen con tu plan y, en caso de no contar con la cobertura, tendrás derecho al costo
                              preferente al que tienes acceso por ser usuario Planmed®.
                            </span>
                          
                          </div>
                          <div *ngIf="answer.includes('sistema-integral')" class="pt-2 col-12 px-0">
                            <span class="card-text card-body-faqs__description">
                              
                            Se trata de un modelo de cuidado que te da acceso a diferentes servicios médicos, que te 
                            ayudan a prevenir y resolver los padecimientos más comunes por los que una persona acude al médico. <br>
                            Dentro de este sistema integral, podrás encontrar desde consultas de nutrición, psicología y 
                            medicina general hasta consultas pediátricas para tus hijos, y otras especialidades como ginecólogos
                             y estudios para poder llevar un embarazo, incluyendo un <span style="font-style: italic;">check up</span> médico para revisar tu estado de 
                             salud, y otras coberturas necesarias como orientación médica telefónica y ambulancia 24/7 para poder
                              responder rápido ante necesidades no previstas. AXA Keralty te ofrece un sistema integral de salud 
                              que busca cuidarte en cada etapa de tu vida. </span>
                          </div>
                          <div *ngIf="answer.includes('flex-plus')" class="pt-2 col">
                            <span class="card-text card-body-faqs__description">
                              ¡Por supuesto! Tanto Cuidado Integral+ como Flex Plus de AXA Seguros ofrecen el acceso a los servicios del sistema de salud AXA Keralty. <br>
                              Recuerda validar las coberturas y beneficios que se incluyen en el producto de seguro que hayas contratado y, en caso de no contar con los beneficios o coberturas del 
                              servicio que requieres, consulta el costo preferente al que tienes acceso por ser asegurado de los productos de seguro de gastos médicos mayores de AXA Seguros.
                              
                            </span>
                          </div>
                            <div *ngIf="answer.includes('trabajo')" class="pt-2 col text-center">
                              <button class="card-footer-faqs__trabajo btn btn-outline-primary mr-2" [routerLink]="['/bolsa-trabajo']"  >Ir a bolsa de trabajo</button>
                            </div>
                            
                            <div *ngIf="answer.includes('click')">
                              <span class="card-text card-body-faqs__description">¡Es muy sencillo contratar! Solo necesitas llenar un formulario con tus datos. Puedes contratar directamente en línea haciendo  <a class="contacto" href="https://axakeralty.mx/cotizador-planmed/#/formulario">clic aquí</a>.</span>
                              <br />
                              <span class="card-text card-body-faqs__description">O bien, puedes llamar al 55 5169 3080 o acudir a una de las Clínicas AXA Keralty y nuestro personal con gusto te atenderá. <a class="contacto ml-2" pageScroll [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175">  Haz clic aquí</a> para ver las ubicaciones en las diferentes ciudades del país.</span>
                            </div>
                            <div *ngIf="answer.includes('checkUpInicial')">
                              <span class="card-text card-body-faqs__description">No, al momento de contratar con un Plan AXA Keralty, podrás
                                asistir a tu <a class="contacto" pageScroll  href="#hiw" (click)="goToItem(indexQues-1, 'm')"
                                pageScrollOffset="35"><span style="font-style: italic;">check up</span> incial</a> , en la que recibirás un plan de seguimiento personalizado para mantenerte sano.</span>
                            </div>
                            <div *ngIf="answer.includes('Ubicación')">
                              <p><span class="card-text card-body-faqs__description">
                                Nuestras Clínicas se encuentran en diversas ciudades de la República Mexicana. Para ver el mapa y ubicar las Clínicas haz  <a class="contacto ml-2" pageScroll [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175">clic aquí</a>. <br>
                                También contamos con una red de prestadores de servicio en convenio que brindan el servicio en diferentes ciudades del país. Para conocerlos haz <a class="contacto ml-2" pageScroll [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175">clic aquí</a>. 
                              </span></p> <br>
                                <span class="card-text card-body-faqs__description">
                                  <img src="assets/map/hospital.png" alt="locacion icono" width="35px" class="pointer" pageScroll
                                       [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175">
                                  <a class="contacto ml-2" pageScroll [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175">Ir al mapa</a>
                                </span>
                            </div>
                            <div *ngIf="answer.includes('checkup-inicial')" class="pt-2">
                              <span class="card-text card-body-faqs__description">
                                El <span style="font-style: italic;">check up</span> anual consiste en una serie de actividades médicas estructuradas de acuerdo con la edad y sexo biológico del usuario con el objetivo de conocer antecedentes familiares, hábitos y estado de salud actual.
                              </span>
                            </div>
                            <div *ngIf="answer.includes('ConsultaOServicios')" class="pt-2">
                              <span class="card-text card-body-faqs__description">
                                ¡Claro que sí! Nuestras Clínicas ofrecen servicios al público en general. Haz <a href="https://axakeralty.mx/servicios"><b>clic aquí</b></a> para agendar una consulta.
                                Si lo prefieres, puedes también marcar a la línea telefónica AXA Keralty (55) 5169 3080 donde con gusto te atenderemos.  
                              </span>
                            </div>
                            <div *ngIf="answer.includes('ProgramarConsulta')" class="pt-2">
                              <p><span class="card-text card-body-faqs__description">
                                Lo puedes hacer a través de tres sencillas formas: directo en la app AXA Keralty, accediendo al portal de clientes o llamando por teléfono.<br>

                                <br> Si ya eres uno de nuestros usuarios, ingresa a la app o al portal de clientes de AXA Keralty en el apartado “Mi Portal” dando clic <a href="https://axakeralty.mx/portal/#/login"><strong>aquí</strong></a> y sigue las instrucciones para agendar una consulta, o bien puedes llamar a la línea telefónica AXA Keralty (55) 5169 3080, opción 4.
                              </span></p>
                              <p><span class="card-text card-body-faqs__description">
                                Si todavía no cuentas con Planmed®, puedes agendar una cita en la línea telefónica AXA Keralty al (55) 5169 3080 y pagar el servicio.
                              </span></p>
                            </div>
                        <!-- <div *ngIf="answer.includes('planes-familirares')" class="pt-2">
                              <p><span class="card-text card-body-faqs__description">
                                ¡Por supuesto! Queremos ajustarnos a tu bolsillo para cuidar a todos los integrantes de tu familia, por lo que Planmed® Óptimo
                                 Individual te da acceso a descuentos en la tarifa del plan, cuando incluye a tres o más integrantes. El descuento es aplicable al titular, cónyuge o concubino, e hijos.  <br> <br>
                                 A continuación, encontrarás una tabla con los porcentajes del descuento para que tengas noción de cómo funcionan. También recuerda que puedes acercarte con nosotros llamando al 55 5169 3080 para saber más al respecto. 
                              </span></p>
                              <div class="row"  *ngFor="let familiar of tableFamiliares; index as i;let last = last;let first = first" style="border: 1px solid #00467c;" [ngClass]="[!last ? 'border-div': '', first ? 'back-blue' : '', i === 1 ? 'back-sky-blue' : '', i === 3 ? 'back-sky-blue' : '']">
                                <div class="col-6 text-center" style="border-right: 1px solid #00467c;">
                                {{familiar.left}}
                                </div>
                                <div class="col-6 text-center" >
                                  {{familiar.right}}
                                </div>
                              </div> <br>
                              <p><span class="card-text card-body-faqs__description">
                                Por ejemplo, si eres el titular y contratas Planmed® para ti, tu esposo(a) 
                                y dos hijos; al ser 4 integrantes en la póliza, tendrías un descuento del 7% sobre la tarifa que les corresponde de acuerdo con su rango de edad. <br> <br>
                                Importante: Si en tu póliza incluyes familiares no directos, sí podrán ser asegurados en la póliza, pero no computarán para el cálculo de tu descuento familiar.
                              </span></p>
                            </div>-->  
                            <div *ngIf="answer.includes('Planmed??')" class="pt-2">
                              <span class="card-text card-body-faqs__description">
                                {{planmed0}}<br>{{planmed1}}
                              </span>
                            </div>

                            <div *ngIf="answer.includes('ContractPlanmed')" class="pt-2">
                              <span class="card-text card-body-faqs__description">
                                {{contractPlan0}}<br> <span class="card-body-faqs__description--notes">{{contractPlan1}}</span>
                              </span>
                            </div>
                            <div *ngIf="!answer.includes('trabajo') &&  !answer.includes('click') &&
                                 !answer.includes('checkUpInicial') &&  !answer.includes('Ubicación')
                                 && !answer.includes('Planmed??') && !answer.includes('ContractPlanmed')
                                 && !answer.includes('ConsultaOServicios') && !answer.includes('ProgramarConsulta') 
                                 && !answer.includes('planes-familirares') && !answer.includes('sistema-integral') 
                                 && !answer.includes('checkup-inicial') && !answer.includes('consulta-especialidad')  &&  !answer.includes('flex-plus')" >
                                <span class="card-text card-body-faqs__description pt-2" [innerHTML]="answer"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="col-7">
            <div class="row justify-content-center pt-4 pl-4 pr-4 d-flex align-items-center">
              <p class="card-title card-body-faqs__title d-flex align-items-center">  <span class="icon-faq-icon ak-icon mr-2" style="font-size: 40px;"></span>{{titleAuxiliar}}</p>
           
            </div>
           
            <div class="row justify-content-start pt-2 px-md-5 text-justify">
              <span *ngIf="answer.includes('sistema-integral')" class="card-text card-body-faqs__description pt-2 px-0 col-12 mb-3" style="height: auto; text-align: justify;">
                AXA Keralty es la alianza entre AXA Salud, S.A. de C.V., como encargada del aseguramiento con Planmed®, y Keralty México, S.A. de C.V., 
                como responsable de prestar los servicios médicos en conjunto con la red de proveedores contratados. Algunos productos de seguro de gastos
                 médicos mayores de AXA Seguros como Cuidado Integral+ y Flex Plus incluyen como beneficio servicios como consultas médicas y estudios
                  diagnósticos dentro de las clínicas de Keralty México y la red de proveedores contratada.
              </span>
              <span class="card-body-faqs__title-sup mb-2" *ngIf="!answer.includes('checkup-inicial')">{{nameQuestion}}</span>
              <span class="card-body-faqs__title-sup mb-2" *ngIf="answer.includes('checkup-inicial')">¿En qué consiste el <span style="font-style: italic;">Check up</span> Anual?</span>
              <div *ngIf="answer.includes('sistema-integral')" class="pt-2 col-12 px-0">
                <span class="card-text card-body-faqs__description">
                  
                Se trata de un modelo de cuidado que te da acceso a diferentes servicios médicos, que te 
                ayudan a prevenir y resolver los padecimientos más comunes por los que una persona acude al médico. <br>
                Dentro de este sistema integral, podrás encontrar desde consultas de nutrición, psicología y 
                medicina general hasta consultas pediátricas para tus hijos, y otras especialidades como ginecólogos
                 y estudios para poder llevar un embarazo, incluyendo un <span style="font-style: italic;">check up</span> médico para revisar tu estado de 
                 salud, y otras coberturas necesarias como orientación médica telefónica y ambulancia 24/7 para poder
                  responder rápido ante necesidades no previstas. AXA Keralty te ofrece un sistema integral de salud 
                  que busca cuidarte en cada etapa de tu vida. </span>
              </div>
              <div *ngIf="answer.includes('trabajo')" class="pt-2 col-12">
                <span class="card-text card-body-faqs__description">Nuestro objetivo es garantizar la calidad de los médicos. Te proponemos invitarlo a que forme parte de nuestro sistema de salud ingresando a nuestra Bolsa de trabajo. </span>
              </div>
              <div *ngIf="answer.includes('consulta-especialidad')" class="pt-2 col-12 px-0">
                <span class="card-text card-body-faqs__description">
                  Las consultas de especialidad requieren referencia médica. Te invitamos a programar una cita con nuestros médicos de atención primaria 
                  (médico general, ginecólogo o  pediatra) y ellos determinarán el plan de seguimiento de acuerdo con tu condición de salud, y si fuera el 
                  caso, te referirán a las especialidades o servicios de salud que requieras. <br> <br>
                  Recuerda validar las coberturas y beneficios que se incluyen con tu plan y, en caso de no contar con la cobertura, tendrás derecho al costo
                  preferente al que tienes acceso por ser usuario Planmed®.
                </span>
              </div>
              <div *ngIf="answer.includes('flex-plus')" class="pt-2 col-12">
                <span class="card-text card-body-faqs__description">
                  ¡Por supuesto! Tanto Cuidado Integral+ como Flex Plus de AXA Seguros ofrecen el acceso a los servicios del sistema de salud AXA Keralty. <br>
                  Recuerda validar las coberturas y beneficios que se incluyen en el producto de seguro que hayas contratado y, en caso de no contar con los beneficios o coberturas del 
                  servicio que requieres, consulta el costo preferente al que tienes acceso por ser asegurado de los productos de seguro de gastos médicos mayores de AXA Seguros.
                  
                </span>
              </div>
            
              <div *ngIf="answer.includes('trabajo')" class="pt-2 col-12 text-center">
                <button class="card-footer-faqs__trabajo btn btn-outline-primary mr-2 mt-3 text-center" [routerLink]="['/bolsa-trabajo']">Ir a bolsa de trabajo</button>
              </div>
             
              <div *ngIf="answer.includes('click')">
                <span class="card-text card-body-faqs__description">¡Es muy sencillo contratar! Solo necesitas llenar un formulario con tus datos. Puedes contratar directamente en línea haciendo  <a class="contacto" href="https://axakeralty.mx/cotizador-planmed/#/formulario">clic aquí</a>.</span>
                <br />
                <span class="card-text card-body-faqs__description">O bien, puedes llamar al 55 5169 3080 o acudir a una de las Clínicas AXA Keralty y nuestro personal con gusto te atenderá. <a class="contacto ml-2" pageScroll [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175">  Haz clic aquí</a> para ver las ubicaciones en las diferentes ciudades del país.</span>
              </div>
              <div *ngIf="answer.includes('checkUpInicial')" class="pt-2">
                <span class="card-text card-body-faqs__description">No, al momento de contratar con un Plan AXA Keralty, podrás
                  asistir a tu <a class="contacto" pageScroll  href="#hiw" (click)="goToItem(indexQues-1, 'w')" pageScrollOffset="35"><span style="font-style: italic;">check up</span>  inicial</a> , en el que recibirás un plan de seguimiento personalizado para mantenerte sano.</span>
              </div>
              <div *ngIf="answer.includes('Ubicación')" class="pt-2">
                Nuestras Clínicas se encuentran en diversas ciudades de la República Mexicana. Para ver el mapa y ubicar las Clínicas haz  <a class="contacto ml-2" pageScroll [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175">clic aquí</a>. <br>
                También contamos con una red de prestadores de servicio en convenio que brindan el servicio en diferentes ciudades del país. Para conocerlos haz <a class="contacto ml-2" pageScroll [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175">clic aquí</a>. 
               <br>
                  <span class="card-text card-body-faqs__description">
                    <img src="assets/map/hospital.png" alt="locacion icono" width="35px" class="pointer" pageScroll
                         [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175">
                    <a class="contacto ml-2" pageScroll [routerLink]="['/home']" href="#head-visit-us" pageScrollOffset="175">Ir al mapa</a>
                  </span>
              </div>
              <div *ngIf="answer.includes('checkup-inicial')" class="pt-2">
                <span class="card-text card-body-faqs__description">
                  El <span style="font-style: italic;">check up</span> anual consiste en una serie de actividades médicas estructuradas de acuerdo con la edad y sexo biológico del usuario con el objetivo de conocer antecedentes familiares, hábitos y estado de salud actual.
                </span>
              </div>
              <div *ngIf="answer.includes('ConsultaOServicios')" class="pt-2">
                <span class="card-text card-body-faqs__description">
                  ¡Claro que sí! Nuestras Clínicas AXA Keralty ofrecen servicios al público en general. Haz <a href="https://axakeralty.mx/servicios"><b>clic aquí</b></a> para agendar una consulta.
                  Si lo prefieres, puedes también marcar a la línea telefónica AXA Keralty (55) 5169 3080 donde con gusto te atenderemos.  
                </span>
              </div>
              <div *ngIf="answer.includes('ProgramarConsulta')" class="pt-2">
                <p><span class="card-text card-body-faqs__description">
                  Lo puedes hacer a través de tres sencillas formas: directo en la app AXA Keralty, accediendo al portal de clientes o llamando por teléfono. <br>

                  <br> Si ya eres uno de nuestros usuarios, ingresa a la app o al portal de clientes de AXA Keralty en el apartado “Mi Portal” dando clic <a href="https://axakeralty.mx/portal/#/login"><strong>aquí</strong></a> y sigue las instrucciones para agendar una consulta, o bien puedes llamar a la línea telefónica AXA Keralty (55) 5169 3080, opción 4.
                </span></p>
                <p><span class="card-text card-body-faqs__description">
                  Si todavía no cuentas con Planmed®, puedes agendar una cita en la línea telefónica AXA Keralty al (55) 5169 3080 y pagar el servicio.
                </span></p>
              </div>
            <!--  <div *ngIf="answer.includes('planes-familirares')" class="pt-2">
                <p><span class="card-text card-body-faqs__description">
                  ¡Por supuesto! Queremos ajustarnos a tu bolsillo para cuidar a todos los integrantes de tu familia, por lo que Planmed® Óptimo
                   Individual te da acceso a descuentos en la tarifa del plan, cuando incluye a tres o más integrantes. El descuento es aplicable al titular, cónyuge o concubino, e hijos.  <br> <br>
                   A continuación, encontrarás una tabla con los porcentajes del descuento para que tengas noción de cómo funcionan. También recuerda que puedes acercarte con nosotros llamando al 55 5169 3080 para saber más al respecto. 
                </span></p>
                <div class="row"  *ngFor="let familiar of tableFamiliares; index as i;let last = last;let first = first" style="border: 1px solid #00467c;" [ngClass]="[!last ? 'border-div': '', first ? 'back-blue' : '', i === 1 ? 'back-sky-blue' : '', i === 3 ? 'back-sky-blue' : '']">
                  <div class="col-6 text-center" style="border-right: 1px solid #00467c;">
                  {{familiar.left}}
                  </div>
                  <div class="col-6 text-center" >
                    {{familiar.right}}
                  </div>
                </div> <br>
                <p><span class="card-text card-body-faqs__description">
                  Por ejemplo, si eres el titular y contratas Planmed® para ti, tu esposo(a) 
                  y dos hijos; al ser 4 integrantes en la póliza, tendrías un descuento del 7% sobre la tarifa que les corresponde de acuerdo con su rango de edad. <br> <br>
                  Importante: Si en tu póliza incluyes familiares no directos, sí podrán ser asegurados en la póliza, pero no computarán para el cálculo de tu descuento familiar.
                </span></p>
              </div>-->
              <div *ngIf="answer.includes('Planmed??')" class="pt-2">
                <span class="card-text card-body-faqs__description">
                  {{planmed0}}
                    <br>
                  {{planmed1}}
                </span>
              </div>
              <div *ngIf="answer.includes('ContractPlanmed')" class="pt-2">
                <span class="card-text card-body-faqs__description">
                  {{contractPlan0}}
                  <br>
                <span class="card-body-faqs__description--notes">{{contractPlan1}}</span>
                </span>
              </div>
              <div *ngIf="!answer.includes('trabajo') &&  !answer.includes('click') &&
                   !answer.includes('checkUpInicial') &&  !answer.includes('Ubicación')
                   && !answer.includes('Planmed??') && !answer.includes('ContractPlanmed')
                   && !answer.includes('ConsultaOServicios') && !answer.includes('ProgramarConsulta')
                    && !answer.includes('planes-familirares')  && !answer.includes('sistema-integral')
                     && !answer.includes('checkup-inicial') && !answer.includes('consulta-especialidad')  &&  !answer.includes('flex-plus')">
                <span class="card-text card-body-faqs__description pt-3">{{ answer }}</span>
              </div>
            </div>
            <hr class="card-body-faqs__line-p mt-4" *ngIf="ocultarIcon" >
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer-faqs text-muted pb-5 pt-5 ">
      <div class="row justify-content-center pb-2">
        <span class="card-footer-faqs__footer">¿Esta información te resultó útil?</span>
      </div>
      <div class="row justify-content-center d-flex">
        <button class="card-footer-faqs__group-2 btn btn-outline-primary mr-2" (click)='qnUsefulInformation("Sí")' >Sí</button>
        <button class="card-footer-faqs__group-2 btn btn-outline-primary" (click)='qnUsefulInformation("No")' >No</button>
      </div>
    </div>
  </div>
</div>
