/**
 * Estados de la República mexicana
 */
export const CITIES = {
    AS: [
        "AGUASCALIENTES",
        "ASIENTOS",
        "CALVILLO",
        "COSIO",
        "JESUS MARIA",
        "PABELLON DE ARTEAGA",
        "RINCON DE ROMOS",
        "SAN JOSE DE GRACIA",
        "TEPEZALA",
        "EL LLANO",
        "SAN FRANCISCO DE LOS ROMO"
    ],
    BC: [
        "ENSENADA",
        "MEXICALI",
        "TECATE",
        "TIJUANA",
        "PLAYAS DE ROSARITO"
    ],
    BS: [
        "COMONDU",
        "MULEGE",
        "LA PAZ",
        "LOS CABOS",
        "LORETO"
    ],
    CC: [
        "CALKINI",
        "CAMPECHE",
        "CARMEN",
        "CHAMPOTON",
        "HECELCHAKAN",
        "HOPELCHEN",
        "PALIZADA",
        "TENABO",
        "ESCARCEGA",
        "CALAKMUL",
        "CANDELARIA"
    ],
    CL: [
        "ABASOLO",
        "ACUÑA",
        "ALLENDE",
        "ARTEAGA",
        "CANDELA",
        "CASTAÑOS",
        "CUATRO CIENEGAS",
        "ESCOBEDO",
        "FRANCISCO I. MADERO",
        "FRONTERA",
        "GENERAL CEPEDA",
        "GUERRERO",
        "HIDALGO",
        "JIMENEZ",
        "JUAREZ",
        "LAMADRID",
        "MATAMOROS",
        "MONCLOVA",
        "MORELOS",
        "MUZQUIZ",
        "NADADORES",
        "NAVA",
        "OCAMPO",
        "PARRAS",
        "PIEDRAS NEGRAS",
        "PROGRESO",
        "RAMOS ARIZPE",
        "SABINAS",
        "SACRAMENTO",
        "SALTILLO",
        "SAN BUENAVENTURA",
        "SAN JUAN DE SABINAS",
        "SAN PEDRO",
        "SIERRA MOJADA",
        "TORREON",
        "VIESCA",
        "VILLA UNION",
        "ZARAGOZA"
    ],
    CM: [
        "ARMERIA",
        "COLIMA",
        "COMALA",
        "COQUIMATLAN",
        "CUAUHTEMOC",
        "IXTLAHUACAN",
        "MANZANILLO",
        "MINATITLAN",
        "TECOMAN",
        "VILLA DE ALVAREZ"
    ],
    CS: [
        "ACACOYAGUA",
        "ACALA",
        "ACAPETAHUA",
        "ALTAMIRANO",
        "AMATAN",
        "AMATENANGO DE LA FRONTERA",
        "AMATENANGO DEL VALLE",
        "ANGEL ALBINO CORZO",
        "ARRIAGA",
        "BEJUCAL DE OCAMPO",
        "BELLA VISTA",
        "BERRIOZABAL",
        "BOCHIL",
        "EL BOSQUE",
        "CACAHOATAN",
        "CATAZAJA",
        "CINTALAPA",
        "COAPILLA",
        "COMITAN DE DOMINGUEZ",
        "LA CONCORDIA",
        "COPAINALA",
        "CHALCHIHUITAN",
        "CHAMULA",
        "CHANAL",
        "CHAPULTENANGO",
        "CHENALHO",
        "CHIAPA DE CORZO",
        "CHIAPILLA",
        "CHICOASEN",
        "CHICOMUSELO",
        "CHILON",
        "ESCUINTLA",
        "FRANCISCO LEON",
        "FRONTERA COMALAPA",
        "FRONTERA HIDALGO",
        "LA GRANDEZA",
        "HUEHUETAN",
        "HUIXTAN",
        "HUITIUPAN",
        "HUIXTLA",
        "LA INDEPENDENCIA",
        "IXHUATAN",
        "IXTACOMITAN",
        "IXTAPA",
        "IXTAPANGAJOYA",
        "JIQUIPILAS",
        "JITOTOL",
        "JUAREZ",
        "LARRAINZAR",
        "LA LIBERTAD",
        "MAPASTEPEC",
        "LAS MARGARITAS",
        "MAZAPA DE MADERO",
        "MAZATAN",
        "METAPA",
        "MITONTIC",
        "MOTOZINTLA",
        "NICOLAS RUIZ",
        "OCOSINGO",
        "OCOTEPEC",
        "OCOZOCOAUTLA DE ESPINOSA",
        "OSTUACAN",
        "OSUMACINTA",
        "OXCHUC",
        "PALENQUE",
        "PANTELHO",
        "PANTEPEC",
        "PICHUCALCO",
        "PIJIJIAPAN",
        "EL PORVENIR",
        "VILLA COMALTITLAN",
        "PUEBLO NUEVO SOLISTAHUACAN",
        "RAYON",
        "REFORMA",
        "LAS ROSAS",
        "SABANILLA",
        "SALTO DE AGUA",
        "SAN CRISTOBAL DE LAS CASAS",
        "SAN FERNANDO",
        "SILTEPEC",
        "SIMOJOVEL",
        "SITALA",
        "SOCOLTENANGO",
        "SOLOSUCHIAPA",
        "SOYALO",
        "SUCHIAPA",
        "SUCHIATE",
        "SUNUAPA",
        "TAPACHULA",
        "TAPALAPA",
        "TAPILULA",
        "TECPATAN",
        "TENEJAPA",
        "TEOPISCA",
        "TILA",
        "TONALA",
        "TOTOLAPA",
        "LA TRINITARIA",
        "TUMBALA",
        "TUXTLA GUTIERREZ",
        "TUXTLA CHICO",
        "TUZANTAN",
        "TZIMOL",
        "UNION JUAREZ",
        "VENUSTIANO CARRANZA",
        "VILLA CORZO",
        "VILLAFLORES",
        "YAJALON",
        "SAN LUCAS",
        "ZINACANTAN",
        "SAN JUAN CANCUC",
        "ALDAMA",
        "BENEMERITO DE LAS AMERICAS",
        "MARAVILLA TENEJAPA",
        "MARQUES DE COMILLAS",
        "MONTECRISTO DE GUERRERO",
        "SAN ANDRES DURAZNAL",
        "SANTIAGO EL PINAR",
        "CAPITAN LUIS ANGEL VIDAL",
        "RINCON CHAMULA SAN PEDRO",
        "EL PARRAL",
        "EMILIANO ZAPATA",
        "MEZCALAPA"
    ],
    CH: [
        "AHUMADA",
        "ALDAMA",
        "ALLENDE",
        "AQUILES SERDAN",
        "ASCENSION",
        "BACHINIVA",
        "BALLEZA",
        "BATOPILAS DE MANUEL GOMEZ MORIN",
        "BOCOYNA",
        "BUENAVENTURA",
        "CAMARGO",
        "CARICHI",
        "CASAS GRANDES",
        "CORONADO",
        "COYAME DEL SOTOL",
        "LA CRUZ",
        "CUAUHTEMOC",
        "CUSIHUIRIACHI",
        "CHIHUAHUA",
        "CHINIPAS",
        "DELICIAS",
        "DR. BELISARIO DOMINGUEZ",
        "GALEANA",
        "SANTA ISABEL",
        "GOMEZ FARIAS",
        "GRAN MORELOS",
        "GUACHOCHI",
        "GUADALUPE",
        "GUADALUPE Y CALVO",
        "GUAZAPARES",
        "GUERRERO",
        "HIDALGO DEL PARRAL",
        "HUEJOTITAN",
        "IGNACIO ZARAGOZA",
        "JANOS",
        "JIMENEZ",
        "JUAREZ",
        "JULIMES",
        "LOPEZ",
        "MADERA",
        "MAGUARICHI",
        "MANUEL BENAVIDES",
        "MATACHI",
        "MATAMOROS",
        "MEOQUI",
        "MORELOS",
        "MORIS",
        "NAMIQUIPA",
        "NONOAVA",
        "NUEVO CASAS GRANDES",
        "OCAMPO",
        "OJINAGA",
        "PRAXEDIS G. GUERRERO",
        "RIVA PALACIO",
        "ROSALES",
        "ROSARIO",
        "SAN FRANCISCO DE BORJA",
        "SAN FRANCISCO DE CONCHOS",
        "SAN FRANCISCO DEL ORO",
        "SANTA BARBARA",
        "SATEVO",
        "SAUCILLO",
        "TEMOSACHIC",
        "EL TULE",
        "URIQUE",
        "URUACHI",
        "VALLE DE ZARAGOZA"
    ],
    DF: [
        "ALVARO OBREGON",
        "AZCAPOTZALCO",
        "BENITO JUAREZ",
        "COYOACAN",
        "CUAJIMALPA DE MORELOS",
        "CUAUHTEMOC",
        "GUSTAVO A. MADERO",
        "IZTACALCO",
        "IZTAPALAPA",
        "LA MAGDALENA CONTRERAS",
        "MIGUEL HIDALGO",
        "MILPA ALTA",
        "TLAHUAC",
        "TLALPAN",
        "VENUSTIANO CARRANZA",
        "XOCHIMILCO",
    ],
    DG: [
        "CANATLAN",
        "CANELAS",
        "CONETO DE COMONFORT",
        "CUENCAME",
        "DURANGO",
        "GENERAL SIMON BOLIVAR",
        "GOMEZ PALACIO",
        "GUADALUPE VICTORIA",
        "GUANACEVI",
        "HIDALGO",
        "INDE",
        "LERDO",
        "MAPIMI",
        "MEZQUITAL",
        "NAZAS",
        "NOMBRE DE DIOS",
        "OCAMPO",
        "EL ORO",
        "OTAEZ",
        "PANUCO DE CORONADO",
        "PEÑON BLANCO",
        "POANAS",
        "PUEBLO NUEVO",
        "RODEO",
        "SAN BERNARDO",
        "SAN DIMAS",
        "SAN JUAN DE GUADALUPE",
        "SAN JUAN DEL RIO",
        "SAN LUIS DEL CORDERO",
        "SAN PEDRO DEL GALLO",
        "SANTA CLARA",
        "SANTIAGO PAPASQUIARO",
        "SUCHIL",
        "TAMAZULA",
        "TEPEHUANES",
        "TLAHUALILO",
        "TOPIA",
        "VICENTE GUERRERO",
        "NUEVO IDEAL"
    ],
    GT: [
        "ABASOLO",
        "ACAMBARO",
        "SAN MIGUEL DE ALLENDE",
        "APASEO EL ALTO",
        "APASEO EL GRANDE",
        "ATARJEA",
        "CELAYA",
        "MANUEL DOBLADO",
        "COMONFORT",
        "CORONEO",
        "CORTAZAR",
        "CUERAMARO",
        "DOCTOR MORA",
        "DOLORES HIDALGO CUNA DE LA INDEPENDENCIA NACIONAL",
        "GUANAJUATO",
        "HUANIMARO",
        "IRAPUATO",
        "JARAL DEL PROGRESO",
        "JERECUARO",
        "LEON",
        "MOROLEON",
        "OCAMPO",
        "PENJAMO",
        "PUEBLO NUEVO",
        "PURISIMA DEL RINCON",
        "ROMITA",
        "SALAMANCA",
        "SALVATIERRA",
        "SAN DIEGO DE LA UNION",
        "SAN FELIPE",
        "SAN FRANCISCO DEL RINCON",
        "SAN JOSE ITURBIDE",
        "SAN LUIS DE LA PAZ",
        "SANTA CATARINA",
        "SANTA CRUZ DE JUVENTINO ROSAS",
        "SANTIAGO MARAVATIO",
        "SILAO DE LA VICTORIA",
        "TARANDACUAO",
        "TARIMORO",
        "TIERRA BLANCA",
        "URIANGATO",
        "VALLE DE SANTIAGO",
        "VICTORIA",
        "VILLAGRAN",
        "XICHU",
        "YURIRIA"
    ],
    GR: [
        "ACAPULCO DE JUAREZ",
        "AHUACUOTZINGO",
        "AJUCHITLAN DEL PROGRESO",
        "ALCOZAUCA DE GUERRERO",
        "ALPOYECA",
        "APAXTLA",
        "ARCELIA",
        "ATENANGO DEL RIO",
        "ATLAMAJALCINGO DEL MONTE",
        "ATLIXTAC",
        "ATOYAC DE ALVAREZ",
        "AYUTLA DE LOS LIBRES",
        "AZOYU",
        "BENITO JUAREZ",
        "BUENAVISTA DE CUELLAR",
        "COAHUAYUTLA DE JOSE MARIA IZAZAGA",
        "COCULA",
        "COPALA",
        "COPALILLO",
        "COPANATOYAC",
        "COYUCA DE BENITEZ",
        "COYUCA DE CATALAN",
        "CUAJINICUILAPA",
        "CUALAC",
        "CUAUTEPEC",
        "CUETZALA DEL PROGRESO",
        "CUTZAMALA DE PINZON",
        "CHILAPA DE ALVAREZ",
        "CHILPANCINGO DE LOS BRAVO",
        "FLORENCIO VILLARREAL",
        "GENERAL CANUTO A. NERI",
        "GENERAL HELIODORO CASTILLO",
        "HUAMUXTITLAN",
        "HUITZUCO DE LOS FIGUEROA",
        "IGUALA DE LA INDEPENDENCIA",
        "IGUALAPA",
        "IXCATEOPAN DE CUAUHTEMOC",
        "ZIHUATANEJO DE AZUETA",
        "JUAN R. ESCUDERO",
        "LEONARDO BRAVO",
        "MALINALTEPEC",
        "MARTIR DE CUILAPAN",
        "METLATONOC",
        "MOCHITLAN",
        "OLINALA",
        "OMETEPEC",
        "PEDRO ASCENCIO ALQUISIRAS",
        "PETATLAN",
        "PILCAYA",
        "PUNGARABATO",
        "QUECHULTENANGO",
        "SAN LUIS ACATLAN",
        "SAN MARCOS",
        "SAN MIGUEL TOTOLAPAN",
        "TAXCO DE ALARCON",
        "TECOANAPA",
        "TECPAN DE GALEANA",
        "TELOLOAPAN",
        "TEPECOACUILCO DE TRUJANO",
        "TETIPAC",
        "TIXTLA DE GUERRERO",
        "TLACOACHISTLAHUACA",
        "TLACOAPA",
        "TLALCHAPA",
        "TLALIXTAQUILLA DE MALDONADO",
        "TLAPA DE COMONFORT",
        "TLAPEHUALA",
        "LA UNION DE ISIDORO MONTES DE OCA",
        "XALPATLAHUAC",
        "XOCHIHUEHUETLAN",
        "XOCHISTLAHUACA",
        "ZAPOTITLAN TABLAS",
        "ZIRANDARO",
        "ZITLALA",
        "EDUARDO NERI",
        "ACATEPEC",
        "MARQUELIA",
        "COCHOAPA EL GRANDE",
        "JOSE JOAQUIN DE HERRERA",
        "JUCHITAN",
        "ILIATENCO"
    ],
    HG: [
        "ACATLAN",
        "ACAXOCHITLAN",
        "ACTOPAN",
        "AGUA BLANCA DE ITURBIDE",
        "AJACUBA",
        "ALFAJAYUCAN",
        "ALMOLOYA",
        "APAN",
        "EL ARENAL",
        "ATITALAQUIA",
        "ATLAPEXCO",
        "ATOTONILCO EL GRANDE",
        "ATOTONILCO DE TULA",
        "CALNALI",
        "CARDONAL",
        "CUAUTEPEC DE HINOJOSA",
        "CHAPANTONGO",
        "CHAPULHUACAN",
        "CHILCUAUTLA",
        "ELOXOCHITLAN",
        "EMILIANO ZAPATA",
        "EPAZOYUCAN",
        "FRANCISCO I. MADERO",
        "HUASCA DE OCAMPO",
        "HUAUTLA",
        "HUAZALINGO",
        "HUEHUETLA",
        "HUEJUTLA DE REYES",
        "HUICHAPAN",
        "IXMIQUILPAN",
        "JACALA DE LEDEZMA",
        "JALTOCAN",
        "JUAREZ HIDALGO",
        "LOLOTLA",
        "METEPEC",
        "SAN AGUSTIN METZQUITITLAN",
        "METZTITLAN",
        "MINERAL DEL CHICO",
        "MINERAL DEL MONTE",
        "LA MISION",
        "MIXQUIAHUALA DE JUAREZ",
        "MOLANGO DE ESCAMILLA",
        "NICOLAS FLORES",
        "NOPALA DE VILLAGRAN",
        "OMITLAN DE JUAREZ",
        "SAN FELIPE ORIZATLAN",
        "PACULA",
        "PACHUCA DE SOTO",
        "PISAFLORES",
        "PROGRESO DE OBREGON",
        "MINERAL DE LA REFORMA",
        "SAN AGUSTIN TLAXIACA",
        "SAN BARTOLO TUTOTEPEC",
        "SAN SALVADOR",
        "SANTIAGO DE ANAYA",
        "SANTIAGO TULANTEPEC DE LUGO GUERRERO",
        "SINGUILUCAN",
        "TASQUILLO",
        "TECOZAUTLA",
        "TENANGO DE DORIA",
        "TEPEAPULCO",
        "TEPEHUACAN DE GUERRERO",
        "TEPEJI DEL RIO DE OCAMPO",
        "TEPETITLAN",
        "TETEPANGO",
        "VILLA DE TEZONTEPEC",
        "TEZONTEPEC DE ALDAMA",
        "TIANGUISTENGO",
        "TIZAYUCA",
        "TLAHUELILPAN",
        "TLAHUILTEPA",
        "TLANALAPA",
        "TLANCHINOL",
        "TLAXCOAPAN",
        "TOLCAYUCA",
        "TULA DE ALLENDE",
        "TULANCINGO DE BRAVO",
        "XOCHIATIPAN",
        "XOCHICOATLAN",
        "YAHUALICA",
        "ZACUALTIPAN DE ANGELES",
        "ZAPOTLAN DE JUAREZ",
        "ZEMPOALA",
        "ZIMAPAN"
    ],
    JC: [
        "ACATIC",
        "ACATLAN DE JUAREZ",
        "AHUALULCO DE MERCADO",
        "AMACUECA",
        "AMATITAN",
        "AMECA",
        "SAN JUANITO DE ESCOBEDO",
        "ARANDAS",
        "EL ARENAL",
        "ATEMAJAC DE BRIZUELA",
        "ATENGO",
        "ATENGUILLO",
        "ATOTONILCO EL ALTO",
        "ATOYAC",
        "AUTLAN DE NAVARRO",
        "AYOTLAN",
        "AYUTLA",
        "LA BARCA",
        "BOLAÑOS",
        "CABO CORRIENTES",
        "CASIMIRO CASTILLO",
        "CIHUATLAN",
        "ZAPOTLAN EL GRANDE",
        "COCULA",
        "COLOTLAN",
        "CONCEPCION DE BUENOS AIRES",
        "CUAUTITLAN DE GARCIA BARRAGAN",
        "CUAUTLA",
        "CUQUIO",
        "CHAPALA",
        "CHIMALTITAN",
        "CHIQUILISTLAN",
        "DEGOLLADO",
        "EJUTLA",
        "ENCARNACION DE DIAZ",
        "ETZATLAN",
        "EL GRULLO",
        "GUACHINANGO",
        "GUADALAJARA",
        "HOSTOTIPAQUILLO",
        "HUEJUCAR",
        "HUEJUQUILLA EL ALTO",
        "LA HUERTA",
        "IXTLAHUACAN DE LOS MEMBRILLOS",
        "IXTLAHUACAN DEL RIO",
        "JALOSTOTITLAN",
        "JAMAY",
        "JESUS MARIA",
        "JILOTLAN DE LOS DOLORES",
        "JOCOTEPEC",
        "JUANACATLAN",
        "JUCHITLAN",
        "LAGOS DE MORENO",
        "EL LIMON",
        "MAGDALENA",
        "SANTA MARIA DEL ORO",
        "LA MANZANILLA DE LA PAZ",
        "MASCOTA",
        "MAZAMITLA",
        "MEXTICACAN",
        "MEZQUITIC",
        "MIXTLAN",
        "OCOTLAN",
        "OJUELOS DE JALISCO",
        "PIHUAMO",
        "PONCITLAN",
        "PUERTO VALLARTA",
        "VILLA PURIFICACION",
        "QUITUPAN",
        "EL SALTO",
        "SAN CRISTOBAL DE LA BARRANCA",
        "SAN DIEGO DE ALEJANDRIA",
        "SAN JUAN DE LOS LAGOS",
        "SAN JULIAN",
        "SAN MARCOS",
        "SAN MARTIN DE BOLAÑOS",
        "SAN MARTIN HIDALGO",
        "SAN MIGUEL EL ALTO",
        "GOMEZ FARIAS",
        "SAN SEBASTIAN DEL OESTE",
        "SANTA MARIA DE LOS ANGELES",
        "SAYULA",
        "TALA",
        "TALPA DE ALLENDE",
        "TAMAZULA DE GORDIANO",
        "TAPALPA",
        "TECALITLAN",
        "TECOLOTLAN",
        "TECHALUTA DE MONTENEGRO",
        "TENAMAXTLAN",
        "TEOCALTICHE",
        "TEOCUITATLAN DE CORONA",
        "TEPATITLAN DE MORELOS",
        "TEQUILA",
        "TEUCHITLAN",
        "TIZAPAN EL ALTO",
        "TLAJOMULCO DE ZUÑIGA",
        "SAN PEDRO TLAQUEPAQUE",
        "TOLIMAN",
        "TOMATLAN",
        "TONALA",
        "TONAYA",
        "TONILA",
        "TOTATICHE",
        "TOTOTLAN",
        "TUXCACUESCO",
        "TUXCUECA",
        "TUXPAN",
        "UNION DE SAN ANTONIO",
        "UNION DE TULA",
        "VALLE DE GUADALUPE",
        "VALLE DE JUAREZ",
        "SAN GABRIEL",
        "VILLA CORONA",
        "VILLA GUERRERO",
        "VILLA HIDALGO",
        "CAÑADAS DE OBREGON",
        "YAHUALICA DE GONZALEZ GALLO",
        "ZACOALCO DE TORRES",
        "ZAPOPAN",
        "ZAPOTILTIC",
        "ZAPOTITLAN DE VADILLO",
        "ZAPOTLAN DEL REY",
        "ZAPOTLANEJO",
        "SAN IGNACIO CERRO GORDO"
    ],
    MC: [
        "ACAMBAY DE RUIZ CASTAÑEDA",
        "ACOLMAN",
        "ACULCO",
        "ALMOLOYA DE ALQUISIRAS",
        "ALMOLOYA DE JUAREZ",
        "ALMOLOYA DEL RIO",
        "AMANALCO",
        "AMATEPEC",
        "AMECAMECA",
        "APAXCO",
        "ATENCO",
        "ATIZAPAN",
        "ATIZAPAN DE ZARAGOZA",
        "ATLACOMULCO",
        "ATLAUTLA",
        "AXAPUSCO",
        "AYAPANGO",
        "CALIMAYA",
        "CAPULHUAC",
        "COACALCO DE BERRIOZABAL",
        "COATEPEC HARINAS",
        "COCOTITLAN",
        "COYOTEPEC",
        "CUAUTITLAN",
        "CHALCO",
        "CHAPA DE MOTA",
        "CHAPULTEPEC",
        "CHIAUTLA",
        "CHICOLOAPAN",
        "CHICONCUAC",
        "CHIMALHUACAN",
        "DONATO GUERRA",
        "ECATEPEC DE MORELOS",
        "ECATZINGO",
        "HUEHUETOCA",
        "HUEYPOXTLA",
        "HUIXQUILUCAN",
        "ISIDRO FABELA",
        "IXTAPALUCA",
        "IXTAPAN DE LA SAL",
        "IXTAPAN DEL ORO",
        "IXTLAHUACA",
        "JALTENCO",
        "JILOTEPEC",
        "JILOTZINGO",
        "JIQUIPILCO",
        "JOCOTITLAN",
        "JOQUICINGO",
        "JUCHITEPEC",
        "LERMA",
        "MALINALCO",
        "MELCHOR OCAMPO",
        "METEPEC",
        "MEXICALTZINGO",
        "MORELOS",
        "NAUCALPAN DE JUAREZ",
        "NEZAHUALCOYOTL",
        "NEXTLALPAN",
        "NICOLAS ROMERO",
        "NOPALTEPEC",
        "OCOYOACAC",
        "OCUILAN",
        "EL ORO",
        "OTUMBA",
        "OTZOLOAPAN",
        "OTZOLOTEPEC",
        "OZUMBA",
        "PAPALOTLA",
        "LA PAZ",
        "POLOTITLAN",
        "RAYON",
        "SAN ANTONIO LA ISLA",
        "SAN FELIPE DEL PROGRESO",
        "SAN MARTIN DE LAS PIRAMIDES",
        "SAN MATEO ATENCO",
        "SAN SIMON DE GUERRERO",
        "SANTO TOMAS",
        "SOYANIQUILPAN DE JUAREZ",
        "SULTEPEC",
        "TECAMAC",
        "TEJUPILCO",
        "TEMAMATLA",
        "TEMASCALAPA",
        "TEMASCALCINGO",
        "TEMASCALTEPEC",
        "TEMOAYA",
        "TENANCINGO",
        "TENANGO DEL AIRE",
        "TENANGO DEL VALLE",
        "TEOLOYUCAN",
        "TEOTIHUACAN",
        "TEPETLAOXTOC",
        "TEPETLIXPA",
        "TEPOTZOTLAN",
        "TEQUIXQUIAC",
        "TEXCALTITLAN",
        "TEXCALYACAC",
        "TEXCOCO",
        "TEZOYUCA",
        "TIANGUISTENCO",
        "TIMILPAN",
        "TLALMANALCO",
        "TLALNEPANTLA DE BAZ",
        "TLATLAYA",
        "TOLUCA",
        "TONATICO",
        "TULTEPEC",
        "TULTITLAN",
        "VALLE DE BRAVO",
        "VILLA DE ALLENDE",
        "VILLA DEL CARBON",
        "VILLA GUERRERO",
        "VILLA VICTORIA",
        "XALATLACO",
        "XONACATLAN",
        "ZACAZONAPAN",
        "ZACUALPAN",
        "ZINACANTEPEC",
        "ZUMPAHUACAN",
        "ZUMPANGO",
        "CUAUTITLAN IZCALLI",
        "VALLE DE CHALCO SOLIDARIDAD",
        "LUVIANOS",
        "SAN JOSE DEL RINCON",
        "TONANITLA"
    ],
    MN: [
        "ACUITZIO",
        "AGUILILLA",
        "ALVARO OBREGON",
        "ANGAMACUTIRO",
        "ANGANGUEO",
        "APATZINGAN",
        "APORO",
        "AQUILA",
        "ARIO",
        "ARTEAGA",
        "BRISEÑAS",
        "BUENAVISTA",
        "CARACUARO",
        "COAHUAYANA",
        "COALCOMAN DE VAZQUEZ PALLARES",
        "COENEO",
        "CONTEPEC",
        "COPANDARO",
        "COTIJA",
        "CUITZEO",
        "CHARAPAN",
        "CHARO",
        "CHAVINDA",
        "CHERAN",
        "CHILCHOTA",
        "CHINICUILA",
        "CHUCANDIRO",
        "CHURINTZIO",
        "CHURUMUCO",
        "ECUANDUREO",
        "EPITACIO HUERTA",
        "ERONGARICUARO",
        "GABRIEL ZAMORA",
        "HIDALGO",
        "LA HUACANA",
        "HUANDACAREO",
        "HUANIQUEO",
        "HUETAMO",
        "HUIRAMBA",
        "INDAPARAPEO",
        "IRIMBO",
        "IXTLAN",
        "JACONA",
        "JIMENEZ",
        "JIQUILPAN",
        "JUAREZ",
        "JUNGAPEO",
        "LAGUNILLAS",
        "MADERO",
        "MARAVATIO",
        "MARCOS CASTELLANOS",
        "LAZARO CARDENAS",
        "MORELIA",
        "MORELOS",
        "MUGICA",
        "NAHUATZEN",
        "NOCUPETARO",
        "NUEVO PARANGARICUTIRO",
        "NUEVO URECHO",
        "NUMARAN",
        "OCAMPO",
        "PAJACUARAN",
        "PANINDICUARO",
        "PARACUARO",
        "PARACHO",
        "PATZCUARO",
        "PENJAMILLO",
        "PERIBAN",
        "LA PIEDAD",
        "PUREPERO",
        "PURUANDIRO",
        "QUERENDARO",
        "QUIROGA",
        "COJUMATLAN DE REGULES",
        "LOS REYES",
        "SAHUAYO",
        "SAN LUCAS",
        "SANTA ANA MAYA",
        "SALVADOR ESCALANTE",
        "SENGUIO",
        "SUSUPUATO",
        "TACAMBARO",
        "TANCITARO",
        "TANGAMANDAPIO",
        "TANGANCICUARO",
        "TANHUATO",
        "TARETAN",
        "TARIMBARO",
        "TEPALCATEPEC",
        "TINGAMBATO",
        "TINGÜINDIN",
        "TIQUICHEO DE NICOLAS ROMERO",
        "TLALPUJAHUA",
        "TLAZAZALCA",
        "TOCUMBO",
        "TUMBISCATIO",
        "TURICATO",
        "TUXPAN",
        "TUZANTLA",
        "TZINTZUNTZAN",
        "TZITZIO",
        "URUAPAN",
        "VENUSTIANO CARRANZA",
        "VILLAMAR",
        "VISTA HERMOSA",
        "YURECUARO",
        "ZACAPU",
        "ZAMORA",
        "ZINAPARO",
        "ZINAPECUARO",
        "ZIRACUARETIRO",
        "ZITACUARO",
        "JOSE SIXTO VERDUZCO"
    ],
    MS: [
        "AMACUZAC",
        "ATLATLAHUCAN",
        "AXOCHIAPAN",
        "AYALA",
        "COATLAN DEL RIO",
        "CUAUTLA",
        "CUERNAVACA",
        "EMILIANO ZAPATA",
        "HUITZILAC",
        "JANTETELCO",
        "JIUTEPEC",
        "JOJUTLA",
        "JONACATEPEC DE LEANDRO VALLE",
        "MAZATEPEC",
        "MIACATLAN",
        "OCUITUCO",
        "PUENTE DE IXTLA",
        "TEMIXCO",
        "TEPALCINGO",
        "TEPOZTLAN",
        "TETECALA",
        "TETELA DEL VOLCAN",
        "TLALNEPANTLA",
        "TLALTIZAPAN DE ZAPATA",
        "TLAQUILTENANGO",
        "TLAYACAPAN",
        "TOTOLAPAN",
        "XOCHITEPEC",
        "YAUTEPEC",
        "YECAPIXTLA",
        "ZACATEPEC",
        "ZACUALPAN DE AMILPAS",
        "TEMOAC"
    ],
    NT: [
        "ACAPONETA",
        "AHUACATLAN",
        "AMATLAN DE CAÑAS",
        "COMPOSTELA",
        "HUAJICORI",
        "IXTLAN DEL RIO",
        "JALA",
        "XALISCO",
        "DEL NAYAR",
        "ROSAMORADA",
        "RUIZ",
        "SAN BLAS",
        "SAN PEDRO LAGUNILLAS",
        "SANTA MARIA DEL ORO",
        "SANTIAGO IXCUINTLA",
        "TECUALA",
        "TEPIC",
        "TUXPAN",
        "LA YESCA",
        "BAHIA DE BANDERAS"
    ],
    NL: [
        "ABASOLO",
        "AGUALEGUAS",
        "LOS ALDAMAS",
        "ALLENDE",
        "ANAHUAC",
        "APODACA",
        "ARAMBERRI",
        "BUSTAMANTE",
        "CADEREYTA JIMENEZ",
        "EL CARMEN",
        "CERRALVO",
        "CIENEGA DE FLORES",
        "CHINA",
        "DOCTOR ARROYO",
        "DOCTOR COSS",
        "DOCTOR GONZALEZ",
        "GALEANA",
        "GARCIA",
        "SAN PEDRO GARZA GARCIA",
        "GENERAL BRAVO",
        "GENERAL ESCOBEDO",
        "GENERAL TERAN",
        "GENERAL TREVIÑO",
        "GENERAL ZARAGOZA",
        "GENERAL ZUAZUA",
        "GUADALUPE",
        "LOS HERRERAS",
        "HIGUERAS",
        "HUALAHUISES",
        "ITURBIDE",
        "JUAREZ",
        "LAMPAZOS DE NARANJO",
        "LINARES",
        "MARIN",
        "MELCHOR OCAMPO",
        "MIER Y NORIEGA",
        "MINA",
        "MONTEMORELOS",
        "MONTERREY",
        "PARAS",
        "PESQUERIA",
        "LOS RAMONES",
        "RAYONES",
        "SABINAS HIDALGO",
        "SALINAS VICTORIA",
        "SAN NICOLAS DE LOS GARZA",
        "HIDALGO",
        "SANTA CATARINA",
        "SANTIAGO",
        "VALLECILLO",
        "VILLALDAMA"
    ],
    OC: [
        "ABEJONES",
        "ACATLAN DE PEREZ FIGUEROA",
        "ASUNCION CACALOTEPEC",
        "ASUNCION CUYOTEPEJI",
        "ASUNCION IXTALTEPEC",
        "ASUNCION NOCHIXTLAN",
        "ASUNCION OCOTLAN",
        "ASUNCION TLACOLULITA",
        "AYOTZINTEPEC",
        "EL BARRIO DE LA SOLEDAD",
        "CALIHUALA",
        "CANDELARIA LOXICHA",
        "CIENEGA DE ZIMATLAN",
        "CIUDAD IXTEPEC",
        "COATECAS ALTAS",
        "COICOYAN DE LAS FLORES",
        "LA COMPAÑIA",
        "CONCEPCION BUENAVISTA",
        "CONCEPCION PAPALO",
        "CONSTANCIA DEL ROSARIO",
        "COSOLAPA",
        "COSOLTEPEC",
        "CUILAPAM DE GUERRERO",
        "CUYAMECALCO VILLA DE ZARAGOZA",
        "CHAHUITES",
        "CHALCATONGO DE HIDALGO",
        "CHIQUIHUITLAN DE BENITO JUAREZ",
        "HEROICA CIUDAD DE EJUTLA DE CRESPO",
        "ELOXOCHITLAN DE FLORES MAGON",
        "EL ESPINAL",
        "TAMAZULAPAM DEL ESPIRITU SANTO",
        "FRESNILLO DE TRUJANO",
        "GUADALUPE ETLA",
        "GUADALUPE DE RAMIREZ",
        "GUELATAO DE JUAREZ",
        "GUEVEA DE HUMBOLDT",
        "MESONES HIDALGO",
        "VILLA HIDALGO",
        "HEROICA CIUDAD DE HUAJUAPAN DE LEON",
        "HUAUTEPEC",
        "HUAUTLA DE JIMENEZ",
        "IXTLAN DE JUAREZ",
        "HEROICA CIUDAD DE JUCHITAN DE ZARAGOZA",
        "LOMA BONITA",
        "MAGDALENA APASCO",
        "MAGDALENA JALTEPEC",
        "SANTA MAGDALENA JICOTLAN",
        "MAGDALENA MIXTEPEC",
        "MAGDALENA OCOTLAN",
        "MAGDALENA PEÑASCO",
        "MAGDALENA TEITIPAC",
        "MAGDALENA TEQUISISTLAN",
        "MAGDALENA TLACOTEPEC",
        "MAGDALENA ZAHUATLAN",
        "MARISCALA DE JUAREZ",
        "MARTIRES DE TACUBAYA",
        "MATIAS ROMERO AVENDAÑO",
        "MAZATLAN VILLA DE FLORES",
        "MIAHUATLAN DE PORFIRIO DIAZ",
        "MIXISTLAN DE LA REFORMA",
        "MONJAS",
        "NATIVIDAD",
        "NAZARENO ETLA",
        "NEJAPA DE MADERO",
        "IXPANTEPEC NIEVES",
        "SANTIAGO NILTEPEC",
        "OAXACA DE JUAREZ",
        "OCOTLAN DE MORELOS",
        "LA PE",
        "PINOTEPA DE DON LUIS",
        "PLUMA HIDALGO",
        "SAN JOSE DEL PROGRESO",
        "PUTLA VILLA DE GUERRERO",
        "SANTA CATARINA QUIOQUITANI",
        "REFORMA DE PINEDA",
        "LA REFORMA",
        "REYES ETLA",
        "ROJAS DE CUAUHTEMOC",
        "SALINA CRUZ",
        "SAN AGUSTIN AMATENGO",
        "SAN AGUSTIN ATENANGO",
        "SAN AGUSTIN CHAYUCO",
        "SAN AGUSTIN DE LAS JUNTAS",
        "SAN AGUSTIN ETLA",
        "SAN AGUSTIN LOXICHA",
        "SAN AGUSTIN TLACOTEPEC",
        "SAN AGUSTIN YATARENI",
        "SAN ANDRES CABECERA NUEVA",
        "SAN ANDRES DINICUITI",
        "SAN ANDRES HUAXPALTEPEC",
        "SAN ANDRES HUAYAPAM",
        "SAN ANDRES IXTLAHUACA",
        "SAN ANDRES LAGUNAS",
        "SAN ANDRES NUXIÑO",
        "SAN ANDRES PAXTLAN",
        "SAN ANDRES SINAXTLA",
        "SAN ANDRES SOLAGA",
        "SAN ANDRES TEOTILALPAM",
        "SAN ANDRES TEPETLAPA",
        "SAN ANDRES YAA",
        "SAN ANDRES ZABACHE",
        "SAN ANDRES ZAUTLA",
        "SAN ANTONINO CASTILLO VELASCO",
        "SAN ANTONINO EL ALTO",
        "SAN ANTONINO MONTE VERDE",
        "SAN ANTONIO ACUTLA",
        "SAN ANTONIO DE LA CAL",
        "SAN ANTONIO HUITEPEC",
        "SAN ANTONIO NANAHUATIPAM",
        "SAN ANTONIO SINICAHUA",
        "SAN ANTONIO TEPETLAPA",
        "SAN BALTAZAR CHICHICAPAM",
        "SAN BALTAZAR LOXICHA",
        "SAN BALTAZAR YATZACHI EL BAJO",
        "SAN BARTOLO COYOTEPEC",
        "SAN BARTOLOME AYAUTLA",
        "SAN BARTOLOME LOXICHA",
        "SAN BARTOLOME QUIALANA",
        "SAN BARTOLOME YUCUAÑE",
        "SAN BARTOLOME ZOOGOCHO",
        "SAN BARTOLO SOYALTEPEC",
        "SAN BARTOLO YAUTEPEC",
        "SAN BERNARDO MIXTEPEC",
        "SAN BLAS ATEMPA",
        "SAN CARLOS YAUTEPEC",
        "SAN CRISTOBAL AMATLAN",
        "SAN CRISTOBAL AMOLTEPEC",
        "SAN CRISTOBAL LACHIRIOAG",
        "SAN CRISTOBAL SUCHIXTLAHUACA",
        "SAN DIONISIO DEL MAR",
        "SAN DIONISIO OCOTEPEC",
        "SAN DIONISIO OCOTLAN",
        "SAN ESTEBAN ATATLAHUCA",
        "SAN FELIPE JALAPA DE DIAZ",
        "SAN FELIPE TEJALAPAM",
        "SAN FELIPE USILA",
        "SAN FRANCISCO CAHUACUA",
        "SAN FRANCISCO CAJONOS",
        "SAN FRANCISCO CHAPULAPA",
        "SAN FRANCISCO CHINDUA",
        "SAN FRANCISCO DEL MAR",
        "SAN FRANCISCO HUEHUETLAN",
        "SAN FRANCISCO IXHUATAN",
        "SAN FRANCISCO JALTEPETONGO",
        "SAN FRANCISCO LACHIGOLO",
        "SAN FRANCISCO LOGUECHE",
        "SAN FRANCISCO NUXAÑO",
        "SAN FRANCISCO OZOLOTEPEC",
        "SAN FRANCISCO SOLA",
        "SAN FRANCISCO TELIXTLAHUACA",
        "SAN FRANCISCO TEOPAN",
        "SAN FRANCISCO TLAPANCINGO",
        "SAN GABRIEL MIXTEPEC",
        "SAN ILDEFONSO AMATLAN",
        "SAN ILDEFONSO SOLA",
        "SAN ILDEFONSO VILLA ALTA",
        "SAN JACINTO AMILPAS",
        "SAN JACINTO TLACOTEPEC",
        "SAN JERONIMO COATLAN",
        "SAN JERONIMO SILACAYOAPILLA",
        "SAN JERONIMO SOSOLA",
        "SAN JERONIMO TAVICHE",
        "SAN JERONIMO TECOATL",
        "SAN JORGE NUCHITA",
        "SAN JOSE AYUQUILA",
        "SAN JOSE CHILTEPEC",
        "SAN JOSE DEL PEÑASCO",
        "SAN JOSE ESTANCIA GRANDE",
        "SAN JOSE INDEPENDENCIA",
        "SAN JOSE LACHIGUIRI",
        "SAN JOSE TENANGO",
        "SAN JUAN ACHIUTLA",
        "SAN JUAN ATEPEC",
        "ANIMAS TRUJANO",
        "SAN JUAN BAUTISTA ATATLAHUCA",
        "SAN JUAN BAUTISTA COIXTLAHUACA",
        "SAN JUAN BAUTISTA CUICATLAN",
        "SAN JUAN BAUTISTA GUELACHE",
        "SAN JUAN BAUTISTA JAYACATLAN",
        "SAN JUAN BAUTISTA LO DE SOTO",
        "SAN JUAN BAUTISTA SUCHITEPEC",
        "SAN JUAN BAUTISTA TLACOATZINTEPEC",
        "SAN JUAN BAUTISTA TLACHICHILCO",
        "SAN JUAN BAUTISTA TUXTEPEC",
        "SAN JUAN CACAHUATEPEC",
        "SAN JUAN CIENEGUILLA",
        "SAN JUAN COATZOSPAM",
        "SAN JUAN COLORADO",
        "SAN JUAN COMALTEPEC",
        "SAN JUAN COTZOCON",
        "SAN JUAN CHICOMEZUCHIL",
        "SAN JUAN CHILATECA",
        "SAN JUAN DEL ESTADO",
        "SAN JUAN DEL RIO",
        "SAN JUAN DIUXI",
        "SAN JUAN EVANGELISTA ANALCO",
        "SAN JUAN GUELAVIA",
        "SAN JUAN GUICHICOVI",
        "SAN JUAN IHUALTEPEC",
        "SAN JUAN JUQUILA MIXES",
        "SAN JUAN JUQUILA VIJANOS",
        "SAN JUAN LACHAO",
        "SAN JUAN LACHIGALLA",
        "SAN JUAN LAJARCIA",
        "SAN JUAN LALANA",
        "SAN JUAN DE LOS CUES",
        "SAN JUAN MAZATLAN",
        "SAN JUAN MIXTEPEC -DTO. 08 -",
        "SAN JUAN MIXTEPEC -DTO. 26 -",
        "SAN JUAN ÑUMI",
        "SAN JUAN OZOLOTEPEC",
        "SAN JUAN PETLAPA",
        "SAN JUAN QUIAHIJE",
        "SAN JUAN QUIOTEPEC",
        "SAN JUAN SAYULTEPEC",
        "SAN JUAN TABAA",
        "SAN JUAN TAMAZOLA",
        "SAN JUAN TEITA",
        "SAN JUAN TEITIPAC",
        "SAN JUAN TEPEUXILA",
        "SAN JUAN TEPOSCOLULA",
        "SAN JUAN YAEE",
        "SAN JUAN YATZONA",
        "SAN JUAN YUCUITA",
        "SAN LORENZO",
        "SAN LORENZO ALBARRADAS",
        "SAN LORENZO CACAOTEPEC",
        "SAN LORENZO CUAUNECUILTITLA",
        "SAN LORENZO TEXMELUCAN",
        "SAN LORENZO VICTORIA",
        "SAN LUCAS CAMOTLAN",
        "SAN LUCAS OJITLAN",
        "SAN LUCAS QUIAVINI",
        "SAN LUCAS ZOQUIAPAM",
        "SAN LUIS AMATLAN",
        "SAN MARCIAL OZOLOTEPEC",
        "SAN MARCOS ARTEAGA",
        "SAN MARTIN DE LOS CANSECOS",
        "SAN MARTIN HUAMELULPAM",
        "SAN MARTIN ITUNYOSO",
        "SAN MARTIN LACHILA",
        "SAN MARTIN PERAS",
        "SAN MARTIN TILCAJETE",
        "SAN MARTIN TOXPALAN",
        "SAN MARTIN ZACATEPEC",
        "SAN MATEO CAJONOS",
        "CAPULALPAM DE MENDEZ",
        "SAN MATEO DEL MAR",
        "SAN MATEO YOLOXOCHITLAN",
        "SAN MATEO ETLATONGO",
        "SAN MATEO NEJAPAM",
        "SAN MATEO PEÑASCO",
        "SAN MATEO PIÑAS",
        "SAN MATEO RIO HONDO",
        "SAN MATEO SINDIHUI",
        "SAN MATEO TLAPILTEPEC",
        "SAN MELCHOR BETAZA",
        "SAN MIGUEL ACHIUTLA",
        "SAN MIGUEL AHUEHUETITLAN",
        "SAN MIGUEL ALOAPAM",
        "SAN MIGUEL AMATITLAN",
        "SAN MIGUEL AMATLAN",
        "SAN MIGUEL COATLAN",
        "SAN MIGUEL CHICAHUA",
        "SAN MIGUEL CHIMALAPA",
        "SAN MIGUEL DEL PUERTO",
        "SAN MIGUEL DEL RIO",
        "SAN MIGUEL EJUTLA",
        "SAN MIGUEL EL GRANDE",
        "SAN MIGUEL HUAUTLA",
        "SAN MIGUEL MIXTEPEC",
        "SAN MIGUEL PANIXTLAHUACA",
        "SAN MIGUEL PERAS",
        "SAN MIGUEL PIEDRAS",
        "SAN MIGUEL QUETZALTEPEC",
        "SAN MIGUEL SANTA FLOR",
        "VILLA SOLA DE VEGA",
        "SAN MIGUEL SOYALTEPEC",
        "SAN MIGUEL SUCHIXTEPEC",
        "VILLA TALEA DE CASTRO",
        "SAN MIGUEL TECOMATLAN",
        "SAN MIGUEL TENANGO",
        "SAN MIGUEL TEQUIXTEPEC",
        "SAN MIGUEL TILQUIAPAM",
        "SAN MIGUEL TLACAMAMA",
        "SAN MIGUEL TLACOTEPEC",
        "SAN MIGUEL TULANCINGO",
        "SAN MIGUEL YOTAO",
        "SAN NICOLAS",
        "SAN NICOLAS HIDALGO",
        "SAN PABLO COATLAN",
        "SAN PABLO CUATRO VENADOS",
        "SAN PABLO ETLA",
        "SAN PABLO HUITZO",
        "SAN PABLO HUIXTEPEC",
        "SAN PABLO MACUILTIANGUIS",
        "SAN PABLO TIJALTEPEC",
        "SAN PABLO VILLA DE MITLA",
        "SAN PABLO YAGANIZA",
        "SAN PEDRO AMUZGOS",
        "SAN PEDRO APOSTOL",
        "SAN PEDRO ATOYAC",
        "SAN PEDRO CAJONOS",
        "SAN PEDRO COXCALTEPEC CANTAROS",
        "SAN PEDRO COMITANCILLO",
        "SAN PEDRO EL ALTO",
        "SAN PEDRO HUAMELULA",
        "SAN PEDRO HUILOTEPEC",
        "SAN PEDRO IXCATLAN",
        "SAN PEDRO IXTLAHUACA",
        "SAN PEDRO JALTEPETONGO",
        "SAN PEDRO JICAYAN",
        "SAN PEDRO JOCOTIPAC",
        "SAN PEDRO JUCHATENGO",
        "SAN PEDRO MARTIR",
        "SAN PEDRO MARTIR QUIECHAPA",
        "SAN PEDRO MARTIR YUCUXACO",
        "SAN PEDRO MIXTEPEC -DTO. 22 -",
        "SAN PEDRO MIXTEPEC -DTO. 26 -",
        "SAN PEDRO MOLINOS",
        "SAN PEDRO NOPALA",
        "SAN PEDRO OCOPETATILLO",
        "SAN PEDRO OCOTEPEC",
        "SAN PEDRO POCHUTLA",
        "SAN PEDRO QUIATONI",
        "SAN PEDRO SOCHIAPAM",
        "SAN PEDRO TAPANATEPEC",
        "SAN PEDRO TAVICHE",
        "SAN PEDRO TEOZACOALCO",
        "SAN PEDRO TEUTILA",
        "SAN PEDRO TIDAA",
        "SAN PEDRO TOPILTEPEC",
        "SAN PEDRO TOTOLAPAM",
        "VILLA DE TUTUTEPEC",
        "SAN PEDRO YANERI",
        "SAN PEDRO YOLOX",
        "SAN PEDRO Y SAN PABLO AYUTLA",
        "VILLA DE ETLA",
        "SAN PEDRO Y SAN PABLO TEPOSCOLULA",
        "SAN PEDRO Y SAN PABLO TEQUIXTEPEC",
        "SAN PEDRO YUCUNAMA",
        "SAN RAYMUNDO JALPAN",
        "SAN SEBASTIAN ABASOLO",
        "SAN SEBASTIAN COATLAN",
        "SAN SEBASTIAN IXCAPA",
        "SAN SEBASTIAN NICANANDUTA",
        "SAN SEBASTIAN RIO HONDO",
        "SAN SEBASTIAN TECOMAXTLAHUACA",
        "SAN SEBASTIAN TEITIPAC",
        "SAN SEBASTIAN TUTLA",
        "SAN SIMON ALMOLONGAS",
        "SAN SIMON ZAHUATLAN",
        "SANTA ANA",
        "SANTA ANA ATEIXTLAHUACA",
        "SANTA ANA CUAUHTEMOC",
        "SANTA ANA DEL VALLE",
        "SANTA ANA TAVELA",
        "SANTA ANA TLAPACOYAN",
        "SANTA ANA YARENI",
        "SANTA ANA ZEGACHE",
        "SANTA CATALINA QUIERI",
        "SANTA CATARINA CUIXTLA",
        "SANTA CATARINA IXTEPEJI",
        "SANTA CATARINA JUQUILA",
        "SANTA CATARINA LACHATAO",
        "SANTA CATARINA LOXICHA",
        "SANTA CATARINA MECHOACAN",
        "SANTA CATARINA MINAS",
        "SANTA CATARINA QUIANE",
        "SANTA CATARINA TAYATA",
        "SANTA CATARINA TICUA",
        "SANTA CATARINA YOSONOTU",
        "SANTA CATARINA ZAPOQUILA",
        "SANTA CRUZ ACATEPEC",
        "SANTA CRUZ AMILPAS",
        "SANTA CRUZ DE BRAVO",
        "SANTA CRUZ ITUNDUJIA",
        "SANTA CRUZ MIXTEPEC",
        "SANTA CRUZ NUNDACO",
        "SANTA CRUZ PAPALUTLA",
        "SANTA CRUZ TACACHE DE MINA",
        "SANTA CRUZ TACAHUA",
        "SANTA CRUZ TAYATA",
        "SANTA CRUZ XITLA",
        "SANTA CRUZ XOXOCOTLAN",
        "SANTA CRUZ ZENZONTEPEC",
        "SANTA GERTRUDIS",
        "SANTA INES DEL MONTE",
        "SANTA INES YATZECHE",
        "SANTA LUCIA DEL CAMINO",
        "SANTA LUCIA MIAHUATLAN",
        "SANTA LUCIA MONTEVERDE",
        "SANTA LUCIA OCOTLAN",
        "SANTA MARIA ALOTEPEC",
        "SANTA MARIA APAZCO",
        "SANTA MARIA LA ASUNCION",
        "HEROICA CIUDAD DE TLAXIACO",
        "AYOQUEZCO DE ALDAMA",
        "SANTA MARIA ATZOMPA",
        "SANTA MARIA CAMOTLAN",
        "SANTA MARIA COLOTEPEC",
        "SANTA MARIA CORTIJO",
        "SANTA MARIA COYOTEPEC",
        "SANTA MARIA CHACHOAPAM",
        "VILLA DE CHILAPA DE DIAZ",
        "SANTA MARIA CHILCHOTLA",
        "SANTA MARIA CHIMALAPA",
        "SANTA MARIA DEL ROSARIO",
        "SANTA MARIA DEL TULE",
        "SANTA MARIA ECATEPEC",
        "SANTA MARIA GUELACE",
        "SANTA MARIA GUIENAGATI",
        "SANTA MARIA HUATULCO",
        "SANTA MARIA HUAZOLOTITLAN",
        "SANTA MARIA IPALAPA",
        "SANTA MARIA IXCATLAN",
        "SANTA MARIA JACATEPEC",
        "SANTA MARIA JALAPA DEL MARQUES",
        "SANTA MARIA JALTIANGUIS",
        "SANTA MARIA LACHIXIO",
        "SANTA MARIA MIXTEQUILLA",
        "SANTA MARIA NATIVITAS",
        "SANTA MARIA NDUAYACO",
        "SANTA MARIA OZOLOTEPEC",
        "SANTA MARIA PAPALO",
        "SANTA MARIA PEÑOLES",
        "SANTA MARIA PETAPA",
        "SANTA MARIA QUIEGOLANI",
        "SANTA MARIA SOLA",
        "SANTA MARIA TATALTEPEC",
        "SANTA MARIA TECOMAVACA",
        "SANTA MARIA TEMAXCALAPA",
        "SANTA MARIA TEMAXCALTEPEC",
        "SANTA MARIA TEOPOXCO",
        "SANTA MARIA TEPANTLALI",
        "SANTA MARIA TEXCATITLAN",
        "SANTA MARIA TLAHUITOLTEPEC",
        "SANTA MARIA TLALIXTAC",
        "SANTA MARIA TONAMECA",
        "SANTA MARIA TOTOLAPILLA",
        "SANTA MARIA XADANI",
        "SANTA MARIA YALINA",
        "SANTA MARIA YAVESIA",
        "SANTA MARIA YOLOTEPEC",
        "SANTA MARIA YOSOYUA",
        "SANTA MARIA YUCUHITI",
        "SANTA MARIA ZACATEPEC",
        "SANTA MARIA ZANIZA",
        "SANTA MARIA ZOQUITLAN",
        "SANTIAGO AMOLTEPEC",
        "SANTIAGO APOALA",
        "SANTIAGO APOSTOL",
        "SANTIAGO ASTATA",
        "SANTIAGO ATITLAN",
        "SANTIAGO AYUQUILILLA",
        "SANTIAGO CACALOXTEPEC",
        "SANTIAGO CAMOTLAN",
        "SANTIAGO COMALTEPEC",
        "SANTIAGO CHAZUMBA",
        "SANTIAGO CHOAPAM",
        "SANTIAGO DEL RIO",
        "SANTIAGO HUAJOLOTITLAN",
        "SANTIAGO HUAUCLILLA",
        "SANTIAGO IHUITLAN PLUMAS",
        "SANTIAGO IXCUINTEPEC",
        "SANTIAGO IXTAYUTLA",
        "SANTIAGO JAMILTEPEC",
        "SANTIAGO JOCOTEPEC",
        "SANTIAGO JUXTLAHUACA",
        "SANTIAGO LACHIGUIRI",
        "SANTIAGO LALOPA",
        "SANTIAGO LAOLLAGA",
        "SANTIAGO LAXOPA",
        "SANTIAGO LLANO GRANDE",
        "SANTIAGO MATATLAN",
        "SANTIAGO MILTEPEC",
        "SANTIAGO MINAS",
        "SANTIAGO NACALTEPEC",
        "SANTIAGO NEJAPILLA",
        "SANTIAGO NUNDICHE",
        "SANTIAGO NUYOO",
        "SANTIAGO PINOTEPA NACIONAL",
        "SANTIAGO SUCHILQUITONGO",
        "SANTIAGO TAMAZOLA",
        "SANTIAGO TAPEXTLA",
        "VILLA TEJUPAM DE LA UNION",
        "SANTIAGO TENANGO",
        "SANTIAGO TEPETLAPA",
        "SANTIAGO TETEPEC",
        "SANTIAGO TEXCALCINGO",
        "SANTIAGO TEXTITLAN",
        "SANTIAGO TILANTONGO",
        "SANTIAGO TILLO",
        "SANTIAGO TLAZOYALTEPEC",
        "SANTIAGO XANICA",
        "SANTIAGO XIACUI",
        "SANTIAGO YAITEPEC",
        "SANTIAGO YAVEO",
        "SANTIAGO YOLOMECATL",
        "SANTIAGO YOSONDUA",
        "SANTIAGO YUCUYACHI",
        "SANTIAGO ZACATEPEC",
        "SANTIAGO ZOOCHILA",
        "NUEVO ZOQUIAPAM",
        "SANTO DOMINGO INGENIO",
        "SANTO DOMINGO ALBARRADAS",
        "SANTO DOMINGO ARMENTA",
        "SANTO DOMINGO CHIHUITAN",
        "SANTO DOMINGO DE MORELOS",
        "SANTO DOMINGO IXCATLAN",
        "SANTO DOMINGO NUXAA",
        "SANTO DOMINGO OZOLOTEPEC",
        "SANTO DOMINGO PETAPA",
        "SANTO DOMINGO ROAYAGA",
        "SANTO DOMINGO TEHUANTEPEC",
        "SANTO DOMINGO TEOJOMULCO",
        "SANTO DOMINGO TEPUXTEPEC",
        "SANTO DOMINGO TLATAYAPAM",
        "SANTO DOMINGO TOMALTEPEC",
        "SANTO DOMINGO TONALA",
        "SANTO DOMINGO TONALTEPEC",
        "SANTO DOMINGO XAGACIA",
        "SANTO DOMINGO YANHUITLAN",
        "SANTO DOMINGO YODOHINO",
        "SANTO DOMINGO ZANATEPEC",
        "SANTOS REYES NOPALA",
        "SANTOS REYES PAPALO",
        "SANTOS REYES TEPEJILLO",
        "SANTOS REYES YUCUNA",
        "SANTO TOMAS JALIEZA",
        "SANTO TOMAS MAZALTEPEC",
        "SANTO TOMAS OCOTEPEC",
        "SANTO TOMAS TAMAZULAPAN",
        "SAN VICENTE COATLAN",
        "SAN VICENTE LACHIXIO",
        "SAN VICENTE NUÑU",
        "SILACAYOAPAM",
        "SITIO DE XITLAPEHUA",
        "SOLEDAD ETLA",
        "VILLA DE TAMAZULAPAM DEL PROGRESO",
        "TANETZE DE ZARAGOZA",
        "TANICHE",
        "TATALTEPEC DE VALDES",
        "TEOCOCUILCO DE MARCOS PEREZ",
        "TEOTITLAN DE FLORES MAGON",
        "TEOTITLAN DEL VALLE",
        "TEOTONGO",
        "TEPELMEME VILLA DE MORELOS",
        "TEZOATLAN DE SEGURA Y LUNA",
        "SAN JERONIMO TLACOCHAHUAYA",
        "TLACOLULA DE MATAMOROS",
        "TLACOTEPEC PLUMAS",
        "TLALIXTAC DE CABRERA",
        "TOTONTEPEC VILLA DE MORELOS",
        "TRINIDAD ZAACHILA",
        "LA TRINIDAD VISTA HERMOSA",
        "UNION HIDALGO",
        "VALERIO TRUJANO",
        "SAN JUAN BAUTISTA VALLE NACIONAL",
        "VILLA DIAZ ORDAZ",
        "YAXE",
        "MAGDALENA YODOCONO DE PORFIRIO DIAZ",
        "YOGANA",
        "YUTANDUCHI DE GUERRERO",
        "VILLA DE ZAACHILA",
        "SAN MATEO YUCUTINDOO",
        "ZAPOTITLAN LAGUNAS",
        "ZAPOTITLAN PALMAS",
        "SANTA INES DE ZARAGOZA",
        "ZIMATLAN DE ALVAREZ"
    ],
    PL: [
        "ACAJETE",
        "ACATENO",
        "ACATLAN",
        "ACATZINGO",
        "ACTEOPAN",
        "AHUACATLAN",
        "AHUATLAN",
        "AHUAZOTEPEC",
        "AHUEHUETITLA",
        "AJALPAN",
        "ALBINO ZERTUCHE",
        "ALJOJUCA",
        "ALTEPEXI",
        "AMIXTLAN",
        "AMOZOC",
        "AQUIXTLA",
        "ATEMPAN",
        "ATEXCAL",
        "ATLIXCO",
        "ATOYATEMPAN",
        "ATZALA",
        "ATZITZIHUACAN",
        "ATZITZINTLA",
        "AXUTLA",
        "AYOTOXCO DE GUERRERO",
        "CALPAN",
        "CALTEPEC",
        "CAMOCUAUTLA",
        "CAXHUACAN",
        "COATEPEC",
        "COATZINGO",
        "COHETZALA",
        "COHUECAN",
        "CORONANGO",
        "COXCATLAN",
        "COYOMEAPAN",
        "COYOTEPEC",
        "CUAPIAXTLA DE MADERO",
        "CUAUTEMPAN",
        "CUAUTINCHAN",
        "CUAUTLANCINGO",
        "CUAYUCA DE ANDRADE",
        "CUETZALAN DEL PROGRESO",
        "CUYOACO",
        "CHALCHICOMULA DE SESMA",
        "CHAPULCO",
        "CHIAUTLA",
        "CHIAUTZINGO",
        "CHICONCUAUTLA",
        "CHICHIQUILA",
        "CHIETLA",
        "CHIGMECATITLAN",
        "CHIGNAHUAPAN",
        "CHIGNAUTLA",
        "CHILA",
        "CHILA DE LA SAL",
        "HONEY",
        "CHILCHOTLA",
        "CHINANTLA",
        "DOMINGO ARENAS",
        "ELOXOCHITLAN",
        "EPATLAN",
        "ESPERANZA",
        "FRANCISCO Z. MENA",
        "GENERAL FELIPE ANGELES",
        "GUADALUPE",
        "GUADALUPE VICTORIA",
        "HERMENEGILDO GALEANA",
        "HUAQUECHULA",
        "HUATLATLAUCA",
        "HUAUCHINANGO",
        "HUEHUETLA",
        "HUEHUETLAN EL CHICO",
        "HUEJOTZINGO",
        "HUEYAPAN",
        "HUEYTAMALCO",
        "HUEYTLALPAN",
        "HUITZILAN DE SERDAN",
        "HUITZILTEPEC",
        "ATLEQUIZAYAN",
        "IXCAMILPA DE GUERRERO",
        "IXCAQUIXTLA",
        "IXTACAMAXTITLAN",
        "IXTEPEC",
        "IZUCAR DE MATAMOROS",
        "JALPAN",
        "JOLALPAN",
        "JONOTLA",
        "JOPALA",
        "JUAN C. BONILLA",
        "JUAN GALINDO",
        "JUAN N. MENDEZ",
        "LAFRAGUA",
        "LIBRES",
        "LA MAGDALENA TLATLAUQUITEPEC",
        "MAZAPILTEPEC DE JUAREZ",
        "MIXTLA",
        "MOLCAXAC",
        "CAÑADA MORELOS",
        "NAUPAN",
        "NAUZONTLA",
        "NEALTICAN",
        "NICOLAS BRAVO",
        "NOPALUCAN",
        "OCOTEPEC",
        "OCOYUCAN",
        "OLINTLA",
        "ORIENTAL",
        "PAHUATLAN",
        "PALMAR DE BRAVO",
        "PANTEPEC",
        "PETLALCINGO",
        "PIAXTLA",
        "PUEBLA",
        "QUECHOLAC",
        "QUIMIXTLAN",
        "RAFAEL LARA GRAJALES",
        "LOS REYES DE JUAREZ",
        "SAN ANDRES CHOLULA",
        "SAN ANTONIO CAÑADA",
        "SAN DIEGO LA MESA TOCHIMILTZINGO",
        "SAN FELIPE TEOTLALCINGO",
        "SAN FELIPE TEPATLAN",
        "SAN GABRIEL CHILAC",
        "SAN GREGORIO ATZOMPA",
        "SAN JERONIMO TECUANIPAN",
        "SAN JERONIMO XAYACATLAN",
        "SAN JOSE CHIAPA",
        "SAN JOSE MIAHUATLAN",
        "SAN JUAN ATENCO",
        "SAN JUAN ATZOMPA",
        "SAN MARTIN TEXMELUCAN",
        "SAN MARTIN TOTOLTEPEC",
        "SAN MATIAS TLALANCALECA",
        "SAN MIGUEL IXITLAN",
        "SAN MIGUEL XOXTLA",
        "SAN NICOLAS BUENOS AIRES",
        "SAN NICOLAS DE LOS RANCHOS",
        "SAN PABLO ANICANO",
        "SAN PEDRO CHOLULA",
        "SAN PEDRO YELOIXTLAHUACA",
        "SAN SALVADOR EL SECO",
        "SAN SALVADOR EL VERDE",
        "SAN SALVADOR HUIXCOLOTLA",
        "SAN SEBASTIAN TLACOTEPEC",
        "SANTA CATARINA TLALTEMPAN",
        "SANTA INES AHUATEMPAN",
        "SANTA ISABEL CHOLULA",
        "SANTIAGO MIAHUATLAN",
        "HUEHUETLAN EL GRANDE",
        "SANTO TOMAS HUEYOTLIPAN",
        "SOLTEPEC",
        "TECALI DE HERRERA",
        "TECAMACHALCO",
        "TECOMATLAN",
        "TEHUACAN",
        "TEHUITZINGO",
        "TENAMPULCO",
        "TEOPANTLAN",
        "TEOTLALCO",
        "TEPANCO DE LOPEZ",
        "TEPANGO DE RODRIGUEZ",
        "TEPATLAXCO DE HIDALGO",
        "TEPEACA",
        "TEPEMAXALCO",
        "TEPEOJUMA",
        "TEPETZINTLA",
        "TEPEXCO",
        "TEPEXI DE RODRIGUEZ",
        "TEPEYAHUALCO",
        "TEPEYAHUALCO DE CUAUHTEMOC",
        "TETELA DE OCAMPO",
        "TETELES DE AVILA CASTILLO",
        "TEZIUTLAN",
        "TIANGUISMANALCO",
        "TILAPA",
        "TLACOTEPEC DE BENITO JUAREZ",
        "TLACUILOTEPEC",
        "TLACHICHUCA",
        "TLAHUAPAN",
        "TLALTENANGO",
        "TLANEPANTLA",
        "TLAOLA",
        "TLAPACOYA",
        "TLAPANALA",
        "TLATLAUQUITEPEC",
        "TLAXCO",
        "TOCHIMILCO",
        "TOCHTEPEC",
        "TOTOLTEPEC DE GUERRERO",
        "TULCINGO",
        "TUZAMAPAN DE GALEANA",
        "TZICATLACOYAN",
        "VENUSTIANO CARRANZA",
        "VICENTE GUERRERO",
        "XAYACATLAN DE BRAVO",
        "XICOTEPEC",
        "XICOTLAN",
        "XIUTETELCO",
        "XOCHIAPULCO",
        "XOCHILTEPEC",
        "XOCHITLAN DE VICENTE SUAREZ",
        "XOCHITLAN TODOS SANTOS",
        "YAONAHUAC",
        "YEHUALTEPEC",
        "ZACAPALA",
        "ZACAPOAXTLA",
        "ZACATLAN",
        "ZAPOTITLAN",
        "ZAPOTITLAN DE MENDEZ",
        "ZARAGOZA",
        "ZAUTLA",
        "ZIHUATEUTLA",
        "ZINACATEPEC",
        "ZONGOZOTLA",
        "ZOQUIAPAN",
        "ZOQUITLAN"
    ],
    QT: [
        "AMEALCO DE BONFIL",
        "PINAL DE AMOLES",
        "ARROYO SECO",
        "CADEREYTA DE MONTES",
        "COLON",
        "CORREGIDORA",
        "EZEQUIEL MONTES",
        "HUIMILPAN",
        "JALPAN DE SERRA",
        "LANDA DE MATAMOROS",
        "EL MARQUES",
        "PEDRO ESCOBEDO",
        "PEÑAMILLER",
        "QUERETARO",
        "SAN JOAQUIN",
        "SAN JUAN DEL RIO",
        "TEQUISQUIAPAN",
        "TOLIMAN"
    ],
    QR: [
        "COZUMEL",
        "FELIPE CARRILLO PUERTO",
        "ISLA MUJERES",
        "OTHON P. BLANCO",
        "BENITO JUAREZ",
        "JOSE MARIA MORELOS",
        "LAZARO CARDENAS",
        "SOLIDARIDAD",
        "TULUM",
        "BACALAR",
        "PUERTO MORELOS"
    ],
    SP: [
        "AHUALULCO",
        "ALAQUINES",
        "AQUISMON",
        "ARMADILLO DE LOS INFANTE",
        "CARDENAS",
        "CATORCE",
        "CEDRAL",
        "CERRITOS",
        "CERRO DE SAN PEDRO",
        "CIUDAD DEL MAIZ",
        "CIUDAD FERNANDEZ",
        "TANCANHUITZ",
        "CIUDAD VALLES",
        "COXCATLAN",
        "CHARCAS",
        "EBANO",
        "GUADALCAZAR",
        "HUEHUETLAN",
        "LAGUNILLAS",
        "MATEHUALA",
        "MEXQUITIC DE CARMONA",
        "MOCTEZUMA",
        "RAYON",
        "RIOVERDE",
        "SALINAS",
        "SAN ANTONIO",
        "SAN CIRO DE ACOSTA",
        "SAN LUIS POTOSI",
        "SAN MARTIN CHALCHICUAUTLA",
        "SAN NICOLAS TOLENTINO",
        "SANTA CATARINA",
        "SANTA MARIA DEL RIO",
        "SANTO DOMINGO",
        "SAN VICENTE TANCUAYALAB",
        "SOLEDAD DE GRACIANO SANCHEZ",
        "TAMASOPO",
        "TAMAZUNCHALE",
        "TAMPACAN",
        "TAMPAMOLON CORONA",
        "TAMUIN",
        "TANLAJAS",
        "TANQUIAN DE ESCOBEDO",
        "TIERRA NUEVA",
        "VANEGAS",
        "VENADO",
        "VILLA DE ARRIAGA",
        "VILLA DE GUADALUPE",
        "VILLA DE LA PAZ",
        "VILLA DE RAMOS",
        "VILLA DE REYES",
        "VILLA HIDALGO",
        "VILLA JUAREZ",
        "AXTLA DE TERRAZAS",
        "XILITLA",
        "ZARAGOZA",
        "VILLA DE ARISTA",
        "MATLAPA",
        "EL NARANJO"
    ],
    SL: [
        "AHOME",
        "ANGOSTURA",
        "BADIRAGUATO",
        "CONCORDIA",
        "COSALA",
        "CULIACAN",
        "CHOIX",
        "ELOTA",
        "ESCUINAPA",
        "EL FUERTE",
        "GUASAVE",
        "MAZATLAN",
        "MOCORITO",
        "ROSARIO",
        "SALVADOR ALVARADO",
        "SAN IGNACIO",
        "SINALOA",
        "NAVOLATO"
    ],
    SR: [
        "ACONCHI",
        "AGUA PRIETA",
        "ALAMOS",
        "ALTAR",
        "ARIVECHI",
        "ARIZPE",
        "ATIL",
        "BACADEHUACHI",
        "BACANORA",
        "BACERAC",
        "BACOACHI",
        "BACUM",
        "BANAMICHI",
        "BAVIACORA",
        "BAVISPE",
        "BENJAMIN HILL",
        "CABORCA",
        "CAJEME",
        "CANANEA",
        "CARBO",
        "LA COLORADA",
        "CUCURPE",
        "CUMPAS",
        "DIVISADEROS",
        "EMPALME",
        "ETCHOJOA",
        "FRONTERAS",
        "GRANADOS",
        "GUAYMAS",
        "HERMOSILLO",
        "HUACHINERA",
        "HUASABAS",
        "HUATABAMPO",
        "HUEPAC",
        "IMURIS",
        "MAGDALENA",
        "MAZATAN",
        "MOCTEZUMA",
        "NACO",
        "NACORI CHICO",
        "NACOZARI DE GARCIA",
        "NAVOJOA",
        "NOGALES",
        "ONAVAS",
        "OPODEPE",
        "OQUITOA",
        "PITIQUITO",
        "PUERTO PEÑASCO",
        "QUIRIEGO",
        "RAYON",
        "ROSARIO",
        "SAHUARIPA",
        "SAN FELIPE DE JESUS",
        "SAN JAVIER",
        "SAN LUIS RIO COLORADO",
        "SAN MIGUEL DE HORCASITAS",
        "SAN PEDRO DE LA CUEVA",
        "SANTA ANA",
        "SANTA CRUZ",
        "SARIC",
        "SOYOPA",
        "SUAQUI GRANDE",
        "TEPACHE",
        "TRINCHERAS",
        "TUBUTAMA",
        "URES",
        "VILLA HIDALGO",
        "VILLA PESQUEIRA",
        "YECORA",
        "GENERAL PLUTARCO ELIAS CALLES",
        "BENITO JUAREZ",
        "SAN IGNACIO RIO MUERTO"
    ],
    TC: [
        "BALANCAN",
        "CARDENAS",
        "CENTLA",
        "CENTRO",
        "COMALCALCO",
        "CUNDUACAN",
        "EMILIANO ZAPATA",
        "HUIMANGUILLO",
        "JALAPA",
        "JALPA DE MENDEZ",
        "JONUTA",
        "MACUSPANA",
        "NACAJUCA",
        "PARAISO",
        "TACOTALPA",
        "TEAPA",
        "TENOSIQUE"
    ],
    TS: [
        "ABASOLO",
        "ALDAMA",
        "ALTAMIRA",
        "ANTIGUO MORELOS",
        "BURGOS",
        "BUSTAMANTE",
        "CAMARGO",
        "CASAS",
        "CIUDAD MADERO",
        "CRUILLAS",
        "GOMEZ FARIAS",
        "GONZALEZ",
        "GÜEMEZ",
        "GUERRERO",
        "GUSTAVO DIAZ ORDAZ",
        "HIDALGO",
        "JAUMAVE",
        "JIMENEZ",
        "LLERA",
        "MAINERO",
        "EL MANTE",
        "MATAMOROS",
        "MENDEZ",
        "MIER",
        "MIGUEL ALEMAN",
        "MIQUIHUANA",
        "NUEVO LAREDO",
        "NUEVO MORELOS",
        "OCAMPO",
        "PADILLA",
        "PALMILLAS",
        "REYNOSA",
        "RIO BRAVO",
        "SAN CARLOS",
        "SAN FERNANDO",
        "SAN NICOLAS",
        "SOTO LA MARINA",
        "TAMPICO",
        "TULA",
        "VALLE HERMOSO",
        "VICTORIA",
        "VILLAGRAN",
        "XICOTENCATL"
    ],
    TL: [
        "AMAXAC DE GUERRERO",
        "APETATITLAN DE ANTONIO CARVAJAL",
        "ATLANGATEPEC",
        "ATLTZAYANCA",
        "APIZACO",
        "CALPULALPAN",
        "EL CARMEN TEQUEXQUITLA",
        "CUAPIAXTLA",
        "CUAXOMULCO",
        "CHIAUTEMPAN",
        "MUÑOZ DE DOMINGO ARENAS",
        "ESPAÑITA",
        "HUAMANTLA",
        "HUEYOTLIPAN",
        "IXTACUIXTLA DE MARIANO MATAMOROS",
        "IXTENCO",
        "MAZATECOCHCO DE JOSE MARIA MORELOS",
        "CONTLA DE JUAN CUAMATZI",
        "TEPETITLA DE LARDIZABAL",
        "SANCTORUM DE LAZARO CARDENAS",
        "NANACAMILPA DE MARIANO ARISTA",
        "ACUAMANALA DE MIGUEL HIDALGO",
        "NATIVITAS",
        "PANOTLA",
        "SAN PABLO DEL MONTE",
        "SANTA CRUZ TLAXCALA",
        "TENANCINGO",
        "TEOLOCHOLCO",
        "TEPEYANCO",
        "TERRENATE",
        "TETLA DE LA SOLIDARIDAD",
        "TETLATLAHUCA",
        "TLAXCALA",
        "TLAXCO",
        "TOCATLAN",
        "TOTOLAC",
        "ZILTLALTEPEC DE TRINIDAD SANCHEZ SANTOS",
        "TZOMPANTEPEC",
        "XALOZTOC",
        "XALTOCAN",
        "PAPALOTLA DE XICOHTENCATL",
        "XICOHTZINCO",
        "YAUHQUEMEHCAN",
        "ZACATELCO",
        "BENITO JUAREZ",
        "EMILIANO ZAPATA",
        "LAZARO CARDENAS",
        "LA MAGDALENA TLALTELULCO",
        "SAN DAMIAN TEXOLOC",
        "SAN FRANCISCO TETLANOHCAN",
        "SAN JERONIMO ZACUALPAN",
        "SAN JOSE TEACALCO",
        "SAN JUAN HUACTZINCO",
        "SAN LORENZO AXOCOMANITLA",
        "SAN LUCAS TECOPILCO",
        "SANTA ANA NOPALUCAN",
        "SANTA APOLONIA TEACALCO",
        "SANTA CATARINA AYOMETLA",
        "SANTA CRUZ QUILEHTLA",
        "SANTA ISABEL XILOXOXTLA"
    ],
    VZ: [
        "ACAJETE",
        "ACATLAN",
        "ACAYUCAN",
        "ACTOPAN",
        "ACULA",
        "ACULTZINGO",
        "CAMARON DE TEJEDA",
        "ALPATLAHUAC",
        "ALTO LUCERO DE GUTIERREZ BARRIOS",
        "ALTOTONGA",
        "ALVARADO",
        "AMATITLAN",
        "NARANJOS AMATLAN",
        "AMATLAN DE LOS REYES",
        "ANGEL R. CABADA",
        "LA ANTIGUA",
        "APAZAPAN",
        "AQUILA",
        "ASTACINGA",
        "ATLAHUILCO",
        "ATOYAC",
        "ATZACAN",
        "ATZALAN",
        "TLALTETELA",
        "AYAHUALULCO",
        "BANDERILLA",
        "BENITO JUAREZ",
        "BOCA DEL RIO",
        "CALCAHUALCO",
        "CAMERINO Z. MENDOZA",
        "CARRILLO PUERTO",
        "CATEMACO",
        "CAZONES DE HERRERA",
        "CERRO AZUL",
        "CITLALTEPETL",
        "COACOATZINTLA",
        "COAHUITLAN",
        "COATEPEC",
        "COATZACOALCOS",
        "COATZINTLA",
        "COETZALA",
        "COLIPA",
        "COMAPA",
        "CORDOBA",
        "COSAMALOAPAN DE CARPIO",
        "COSAUTLAN DE CARVAJAL",
        "COSCOMATEPEC",
        "COSOLEACAQUE",
        "COTAXTLA",
        "COXQUIHUI",
        "COYUTLA",
        "CUICHAPA",
        "CUITLAHUAC",
        "CHACALTIANGUIS",
        "CHALMA",
        "CHICONAMEL",
        "CHICONQUIACO",
        "CHICONTEPEC",
        "CHINAMECA",
        "CHINAMPA DE GOROSTIZA",
        "LAS CHOAPAS",
        "CHOCAMAN",
        "CHONTLA",
        "CHUMATLAN",
        "EMILIANO ZAPATA",
        "ESPINAL",
        "FILOMENO MATA",
        "FORTIN",
        "GUTIERREZ ZAMORA",
        "HIDALGOTITLAN",
        "HUATUSCO",
        "HUAYACOCOTLA",
        "HUEYAPAN DE OCAMPO",
        "HUILOAPAN DE CUAUHTEMOC",
        "IGNACIO DE LA LLAVE",
        "ILAMATLAN",
        "ISLA",
        "IXCATEPEC",
        "IXHUACAN DE LOS REYES",
        "IXHUATLAN DEL CAFE",
        "IXHUATLANCILLO",
        "IXHUATLAN DEL SURESTE",
        "IXHUATLAN DE MADERO",
        "IXMATLAHUACAN",
        "IXTACZOQUITLAN",
        "JALACINGO",
        "XALAPA",
        "JALCOMULCO",
        "JALTIPAN",
        "JAMAPA",
        "JESUS CARRANZA",
        "XICO",
        "JILOTEPEC",
        "JUAN RODRIGUEZ CLARA",
        "JUCHIQUE DE FERRER",
        "LANDERO Y COSS",
        "LERDO DE TEJADA",
        "MAGDALENA",
        "MALTRATA",
        "MANLIO FABIO ALTAMIRANO",
        "MARIANO ESCOBEDO",
        "MARTINEZ DE LA TORRE",
        "MECATLAN",
        "MECAYAPAN",
        "MEDELLIN DE BRAVO",
        "MIAHUATLAN",
        "LAS MINAS",
        "MINATITLAN",
        "MISANTLA",
        "MIXTLA DE ALTAMIRANO",
        "MOLOACAN",
        "NAOLINCO",
        "NARANJAL",
        "NAUTLA",
        "NOGALES",
        "OLUTA",
        "OMEALCA",
        "ORIZABA",
        "OTATITLAN",
        "OTEAPAN",
        "OZULUAMA DE MASCAREÑAS",
        "PAJAPAN",
        "PANUCO",
        "PAPANTLA",
        "PASO DEL MACHO",
        "PASO DE OVEJAS",
        "LA PERLA",
        "PEROTE",
        "PLATON SANCHEZ",
        "PLAYA VICENTE",
        "POZA RICA DE HIDALGO",
        "LAS VIGAS DE RAMIREZ",
        "PUEBLO VIEJO",
        "PUENTE NACIONAL",
        "RAFAEL DELGADO",
        "RAFAEL LUCIO",
        "LOS REYES",
        "RIO BLANCO",
        "SALTABARRANCA",
        "SAN ANDRES TENEJAPAN",
        "SAN ANDRES TUXTLA",
        "SAN JUAN EVANGELISTA",
        "SANTIAGO TUXTLA",
        "SAYULA DE ALEMAN",
        "SOCONUSCO",
        "SOCHIAPA",
        "SOLEDAD ATZOMPA",
        "SOLEDAD DE DOBLADO",
        "SOTEAPAN",
        "TAMALIN",
        "TAMIAHUA",
        "TAMPICO ALTO",
        "TANCOCO",
        "TANTIMA",
        "TANTOYUCA",
        "TATATILA",
        "CASTILLO DE TEAYO",
        "TECOLUTLA",
        "TEHUIPANGO",
        "ALAMO TEMAPACHE",
        "TEMPOAL",
        "TENAMPA",
        "TENOCHTITLAN",
        "TEOCELO",
        "TEPATLAXCO",
        "TEPETLAN",
        "TEPETZINTLA",
        "TEQUILA",
        "JOSE AZUETA",
        "TEXCATEPEC",
        "TEXHUACAN",
        "TEXISTEPEC",
        "TEZONAPA",
        "TIERRA BLANCA",
        "TIHUATLAN",
        "TLACOJALPAN",
        "TLACOLULAN",
        "TLACOTALPAN",
        "TLACOTEPEC DE MEJIA",
        "TLACHICHILCO",
        "TLALIXCOYAN",
        "TLALNELHUAYOCAN",
        "TLAPACOYAN",
        "TLAQUILPA",
        "TLILAPAN",
        "TOMATLAN",
        "TONAYAN",
        "TOTUTLA",
        "TUXPAN",
        "TUXTILLA",
        "URSULO GALVAN",
        "VEGA DE ALATORRE",
        "VERACRUZ",
        "VILLA ALDAMA",
        "XOXOCOTLA",
        "YANGA",
        "YECUATLA",
        "ZACUALPAN",
        "ZARAGOZA",
        "ZENTLA",
        "ZONGOLICA",
        "ZONTECOMATLAN DE LOPEZ Y FUENTES",
        "ZOZOCOLCO DE HIDALGO",
        "AGUA DULCE",
        "EL HIGO",
        "NANCHITAL DE LAZARO CARDENAS DEL RIO",
        "TRES VALLES",
        "CARLOS A. CARRILLO",
        "TATAHUICAPAN DE JUAREZ",
        "UXPANAPA",
        "SAN RAFAEL",
        "SANTIAGO SOCHIAPAN"
    ],
    YN: [
        "ABALA",
        "ACANCEH",
        "AKIL",
        "BACA",
        "BOKOBA",
        "BUCTZOTZ",
        "CACALCHEN",
        "CALOTMUL",
        "CANSAHCAB",
        "CANTAMAYEC",
        "CELESTUN",
        "CENOTILLO",
        "CONKAL",
        "CUNCUNUL",
        "CUZAMA",
        "CHACSINKIN",
        "CHANKOM",
        "CHAPAB",
        "CHEMAX",
        "CHICXULUB PUEBLO",
        "CHICHIMILA",
        "CHIKINDZONOT",
        "CHOCHOLA",
        "CHUMAYEL",
        "DZAN",
        "DZEMUL",
        "DZIDZANTUN",
        "DZILAM DE BRAVO",
        "DZILAM GONZALEZ",
        "DZITAS",
        "DZONCAUICH",
        "ESPITA",
        "HALACHO",
        "HOCABA",
        "HOCTUN",
        "HOMUN",
        "HUHI",
        "HUNUCMA",
        "IXIL",
        "IZAMAL",
        "KANASIN",
        "KANTUNIL",
        "KAUA",
        "KINCHIL",
        "KOPOMA",
        "MAMA",
        "MANI",
        "MAXCANU",
        "MAYAPAN",
        "MERIDA",
        "MOCOCHA",
        "MOTUL",
        "MUNA",
        "MUXUPIP",
        "OPICHEN",
        "OXKUTZCAB",
        "PANABA",
        "PETO",
        "PROGRESO",
        "QUINTANA ROO",
        "RIO LAGARTOS",
        "SACALUM",
        "SAMAHIL",
        "SANAHCAT",
        "SAN FELIPE",
        "SANTA ELENA",
        "SEYE",
        "SINANCHE",
        "SOTUTA",
        "SUCILA",
        "SUDZAL",
        "SUMA",
        "TAHDZIU",
        "TAHMEK",
        "TEABO",
        "TECOH",
        "TEKAL DE VENEGAS",
        "TEKANTO",
        "TEKAX",
        "TEKIT",
        "TEKOM",
        "TELCHAC PUEBLO",
        "TELCHAC PUERTO",
        "TEMAX",
        "TEMOZON",
        "TEPAKAN",
        "TETIZ",
        "TEYA",
        "TICUL",
        "TIMUCUY",
        "TINUM",
        "TIXCACALCUPUL",
        "TIXKOKOB",
        "TIXMEHUAC",
        "TIXPEHUAL",
        "TIZIMIN",
        "TUNKAS",
        "TZUCACAB",
        "UAYMA",
        "UCU",
        "UMAN",
        "VALLADOLID",
        "XOCCHEL",
        "YAXCABA",
        "YAXKUKUL",
        "YOBAIN"
    ],
    ZS: [
        "APOZOL",
        "APULCO",
        "ATOLINGA",
        "BENITO JUAREZ",
        "CALERA",
        "CAÑITAS DE FELIPE PESCADOR",
        "CONCEPCION DEL ORO",
        "CUAUHTEMOC",
        "CHALCHIHUITES",
        "FRESNILLO",
        "TRINIDAD GARCIA DE LA CADENA",
        "GENARO CODINA",
        "GENERAL ENRIQUE ESTRADA",
        "GENERAL FRANCISCO R. MURGUIA",
        "EL PLATEADO DE JOAQUIN AMARO",
        "GENERAL PANFILO NATERA",
        "GUADALUPE",
        "HUANUSCO",
        "JALPA",
        "JEREZ",
        "JIMENEZ DEL TEUL",
        "JUAN ALDAMA",
        "JUCHIPILA",
        "LORETO",
        "LUIS MOYA",
        "MAZAPIL",
        "MELCHOR OCAMPO",
        "MEZQUITAL DEL ORO",
        "MIGUEL AUZA",
        "MOMAX",
        "MONTE ESCOBEDO",
        "MORELOS",
        "MOYAHUA DE ESTRADA",
        "NOCHISTLAN DE MEJIA",
        "NORIA DE ANGELES",
        "OJOCALIENTE",
        "PANUCO",
        "PINOS",
        "RIO GRANDE",
        "SAIN ALTO",
        "EL SALVADOR",
        "SOMBRERETE",
        "SUSTICACAN",
        "TABASCO",
        "TEPECHITLAN",
        "TEPETONGO",
        "TEUL DE GONZALEZ ORTEGA",
        "TLALTENANGO DE SANCHEZ ROMAN",
        "VALPARAISO",
        "VETAGRANDE",
        "VILLA DE COS",
        "VILLA GARCIA",
        "VILLA GONZALEZ ORTEGA",
        "VILLA HIDALGO",
        "VILLANUEVA",
        "ZACATECAS",
        "TRANCOSO",
        "SANTA MARIA DE LA PAZ"
    ]
}

/**
 * Ciudades de la republica Mexicana.
 */
export const CITIES_BH = [
  {
      value: "AGUASCALIENTES",
      name: "01001"
  },
  {
      value: "ASIENTOS",
      name: "01002"
  },
  {
      value: "CALVILLO",
      name: "01003"
  },
  {
      value: "COSIO",
      name: "01004"
  },
  {
      value: "JESUS MARIA",
      name: "01005"
  },
  {
      value: "PABELLON DE ARTEAGA",
      name: "01006"
  },
  {
      value: "RINCON DE ROMOS",
      name: "01007"
  },
  {
      value: "SAN JOSE DE GRACIA",
      name: "01008"
  },
  {
      value: "TEPEZALA",
      name: "01009"
  },
  {
      value: "EL LLANO",
      name: "01010"
  },
  {
      value: "SAN FRANCISCO DE LOS ROMO",
      name: "01011"
  },
  {
      value: "ENSENADA",
      name: "02001"
  },
  {
      value: "MEXICALI",
      name: "02002"
  },
  {
      value: "TECATE",
      name: "02003"
  },
  {
      value: "TIJUANA",
      name: "02004"
  },
  {
      value: "PLAYAS DE ROSARITO",
      name: "02005"
  },
  {
      value: "COMONDU",
      name: "03001"
  },
  {
      value: "MULEGE",
      name: "03002"
  },
  {
      value: "LA PAZ",
      name: "03003"
  },
  {
      value: "LOS CABOS",
      name: "03008"
  },
  {
      value: "LORETO",
      name: "03009"
  },
  {
      value: "CALKINI",
      name: "04001"
  },
  {
      value: "CAMPECHE",
      name: "04002"
  },
  {
      value: "CARMEN",
      name: "04003"
  },
  {
      value: "CHAMPOTON",
      name: "04004"
  },
  {
      value: "HECELCHAKAN",
      name: "04005"
  },
  {
      value: "HOPELCHEN",
      name: "04006"
  },
  {
      value: "PALIZADA",
      name: "04007"
  },
  {
      value: "TENABO",
      name: "04008"
  },
  {
      value: "ESCARCEGA",
      name: "04009"
  },
  {
      value: "CALAKMUL",
      name: "04010"
  },
  {
      value: "CANDELARIA",
      name: "04011"
  },
  {
      value: "ABASOLO",
      name: "05001"
  },
  {
      value: "ACUÑA",
      name: "05002"
  },
  {
      value: "ALLENDE",
      name: "05003"
  },
  {
      value: "ARTEAGA",
      name: "05004"
  },
  {
      value: "CANDELA",
      name: "05005"
  },
  {
      value: "CASTAÑOS",
      name: "05006"
  },
  {
      value: "CUATRO CIENEGAS",
      name: "05007"
  },
  {
      value: "ESCOBEDO",
      name: "05008"
  },
  {
      value: "FRANCISCO I. MADERO",
      name: "05009"
  },
  {
      value: "FRONTERA",
      name: "05010"
  },
  {
      value: "GENERAL CEPEDA",
      name: "05011"
  },
  {
      value: "GUERRERO",
      name: "05012"
  },
  {
      value: "HIDALGO",
      name: "05013"
  },
  {
      value: "JIMENEZ",
      name: "05014"
  },
  {
      value: "JUAREZ",
      name: "05015"
  },
  {
      value: "LAMADRID",
      name: "05016"
  },
  {
      value: "MATAMOROS",
      name: "05017"
  },
  {
      value: "MONCLOVA",
      name: "05018"
  },
  {
      value: "MORELOS",
      name: "05019"
  },
  {
      value: "MUZQUIZ",
      name: "05020"
  },
  {
      value: "NADADORES",
      name: "05021"
  },
  {
      value: "NAVA",
      name: "05022"
  },
  {
      value: "OCAMPO",
      name: "05023"
  },
  {
      value: "PARRAS",
      name: "05024"
  },
  {
      value: "PIEDRAS NEGRAS",
      name: "05025"
  },
  {
      value: "PROGRESO",
      name: "05026"
  },
  {
      value: "RAMOS ARIZPE",
      name: "05027"
  },
  {
      value: "SABINAS",
      name: "05028"
  },
  {
      value: "SACRAMENTO",
      name: "05029"
  },
  {
      value: "SALTILLO",
      name: "05030"
  },
  {
      value: "SAN BUENAVENTURA",
      name: "05031"
  },
  {
      value: "SAN JUAN DE SABINAS",
      name: "05032"
  },
  {
      value: "SAN PEDRO",
      name: "05033"
  },
  {
      value: "SIERRA MOJADA",
      name: "05034"
  },
  {
      value: "TORREON",
      name: "05035"
  },
  {
      value: "VIESCA",
      name: "05036"
  },
  {
      value: "VILLA UNION",
      name: "05037"
  },
  {
      value: "ZARAGOZA",
      name: "05038"
  },
  {
      value: "ARMERIA",
      name: "06001"
  },
  {
      value: "COLIMA",
      name: "06002"
  },
  {
      value: "COMALA",
      name: "06003"
  },
  {
      value: "COQUIMATLAN",
      name: "06004"
  },
  {
      value: "CUAUHTEMOC",
      name: "06005"
  },
  {
      value: "IXTLAHUACAN",
      name: "06006"
  },
  {
      value: "MANZANILLO",
      name: "06007"
  },
  {
      value: "MINATITLAN",
      name: "06008"
  },
  {
      value: "TECOMAN",
      name: "06009"
  },
  {
      value: "VILLA DE ALVAREZ",
      name: "06010"
  },
  {
      value: "ACACOYAGUA",
      name: "07001"
  },
  {
      value: "ACALA",
      name: "07002"
  },
  {
      value: "ACAPETAHUA",
      name: "07003"
  },
  {
      value: "ALTAMIRANO",
      name: "07004"
  },
  {
      value: "AMATAN",
      name: "07005"
  },
  {
      value: "AMATENANGO DE LA FRONTERA",
      name: "07006"
  },
  {
      value: "AMATENANGO DEL VALLE",
      name: "07007"
  },
  {
      value: "ANGEL ALBINO CORZO",
      name: "07008"
  },
  {
      value: "ARRIAGA",
      name: "07009"
  },
  {
      value: "BEJUCAL DE OCAMPO",
      name: "07010"
  },
  {
      value: "BELLA VISTA",
      name: "07011"
  },
  {
      value: "BERRIOZABAL",
      name: "07012"
  },
  {
      value: "BOCHIL",
      name: "07013"
  },
  {
      value: "EL BOSQUE",
      name: "07014"
  },
  {
      value: "CACAHOATAN",
      name: "07015"
  },
  {
      value: "CATAZAJA",
      name: "07016"
  },
  {
      value: "CINTALAPA",
      name: "07017"
  },
  {
      value: "COAPILLA",
      name: "07018"
  },
  {
      value: "COMITAN DE DOMINGUEZ",
      name: "07019"
  },
  {
      value: "LA CONCORDIA",
      name: "07020"
  },
  {
      value: "COPAINALA",
      name: "07021"
  },
  {
      value: "CHALCHIHUITAN",
      name: "07022"
  },
  {
      value: "CHAMULA",
      name: "07023"
  },
  {
      value: "CHANAL",
      name: "07024"
  },
  {
      value: "CHAPULTENANGO",
      name: "07025"
  },
  {
      value: "CHENALHO",
      name: "07026"
  },
  {
      value: "CHIAPA DE CORZO",
      name: "07027"
  },
  {
      value: "CHIAPILLA",
      name: "07028"
  },
  {
      value: "CHICOASEN",
      name: "07029"
  },
  {
      value: "CHICOMUSELO",
      name: "07030"
  },
  {
      value: "CHILON",
      name: "07031"
  },
  {
      value: "ESCUINTLA",
      name: "07032"
  },
  {
      value: "FRANCISCO LEON",
      name: "07033"
  },
  {
      value: "FRONTERA COMALAPA",
      name: "07034"
  },
  {
      value: "FRONTERA HIDALGO",
      name: "07035"
  },
  {
      value: "LA GRANDEZA",
      name: "07036"
  },
  {
      value: "HUEHUETAN",
      name: "07037"
  },
  {
      value: "HUIXTAN",
      name: "07038"
  },
  {
      value: "HUITIUPAN",
      name: "07039"
  },
  {
      value: "HUIXTLA",
      name: "07040"
  },
  {
      value: "LA INDEPENDENCIA",
      name: "07041"
  },
  {
      value: "IXHUATAN",
      name: "07042"
  },
  {
      value: "IXTACOMITAN",
      name: "07043"
  },
  {
      value: "IXTAPA",
      name: "07044"
  },
  {
      value: "IXTAPANGAJOYA",
      name: "07045"
  },
  {
      value: "JIQUIPILAS",
      name: "07046"
  },
  {
      value: "JITOTOL",
      name: "07047"
  },
  {
      value: "JUAREZ",
      name: "07048"
  },
  {
      value: "LARRAINZAR",
      name: "07049"
  },
  {
      value: "LA LIBERTAD",
      name: "07050"
  },
  {
      value: "MAPASTEPEC",
      name: "07051"
  },
  {
      value: "LAS MARGARITAS",
      name: "07052"
  },
  {
      value: "MAZAPA DE MADERO",
      name: "07053"
  },
  {
      value: "MAZATAN",
      name: "07054"
  },
  {
      value: "METAPA",
      name: "07055"
  },
  {
      value: "MITONTIC",
      name: "07056"
  },
  {
      value: "MOTOZINTLA",
      name: "07057"
  },
  {
      value: "NICOLAS RUIZ",
      name: "07058"
  },
  {
      value: "OCOSINGO",
      name: "07059"
  },
  {
      value: "OCOTEPEC",
      name: "07060"
  },
  {
      value: "OCOZOCOAUTLA DE ESPINOSA",
      name: "07061"
  },
  {
      value: "OSTUACAN",
      name: "07062"
  },
  {
      value: "OSUMACINTA",
      name: "07063"
  },
  {
      value: "OXCHUC",
      name: "07064"
  },
  {
      value: "PALENQUE",
      name: "07065"
  },
  {
      value: "PANTELHO",
      name: "07066"
  },
  {
      value: "PANTEPEC",
      name: "07067"
  },
  {
      value: "PICHUCALCO",
      name: "07068"
  },
  {
      value: "PIJIJIAPAN",
      name: "07069"
  },
  {
      value: "EL PORVENIR",
      name: "07070"
  },
  {
      value: "VILLA COMALTITLAN",
      name: "07071"
  },
  {
      value: "PUEBLO NUEVO SOLISTAHUACAN",
      name: "07072"
  },
  {
      value: "RAYON",
      name: "07073"
  },
  {
      value: "REFORMA",
      name: "07074"
  },
  {
      value: "LAS ROSAS",
      name: "07075"
  },
  {
      value: "SABANILLA",
      name: "07076"
  },
  {
      value: "SALTO DE AGUA",
      name: "07077"
  },
  {
      value: "SAN CRISTOBAL DE LAS CASAS",
      name: "07078"
  },
  {
      value: "SAN FERNANDO",
      name: "07079"
  },
  {
      value: "SILTEPEC",
      name: "07080"
  },
  {
      value: "SIMOJOVEL",
      name: "07081"
  },
  {
      value: "SITALA",
      name: "07082"
  },
  {
      value: "SOCOLTENANGO",
      name: "07083"
  },
  {
      value: "SOLOSUCHIAPA",
      name: "07084"
  },
  {
      value: "SOYALO",
      name: "07085"
  },
  {
      value: "SUCHIAPA",
      name: "07086"
  },
  {
      value: "SUCHIATE",
      name: "07087"
  },
  {
      value: "SUNUAPA",
      name: "07088"
  },
  {
      value: "TAPACHULA",
      name: "07089"
  },
  {
      value: "TAPALAPA",
      name: "07090"
  },
  {
      value: "TAPILULA",
      name: "07091"
  },
  {
      value: "TECPATAN",
      name: "07092"
  },
  {
      value: "TENEJAPA",
      name: "07093"
  },
  {
      value: "TEOPISCA",
      name: "07094"
  },
  {
      value: "TILA",
      name: "07096"
  },
  {
      value: "TONALA",
      name: "07097"
  },
  {
      value: "TOTOLAPA",
      name: "07098"
  },
  {
      value: "LA TRINITARIA",
      name: "07099"
  },
  {
      value: "TUMBALA",
      name: "07100"
  },
  {
      value: "TUXTLA GUTIERREZ",
      name: "07101"
  },
  {
      value: "TUXTLA CHICO",
      name: "07102"
  },
  {
      value: "TUZANTAN",
      name: "07103"
  },
  {
      value: "TZIMOL",
      name: "07104"
  },
  {
      value: "UNION JUAREZ",
      name: "07105"
  },
  {
      value: "VENUSTIANO CARRANZA",
      name: "07106"
  },
  {
      value: "VILLA CORZO",
      name: "07107"
  },
  {
      value: "VILLAFLORES",
      name: "07108"
  },
  {
      value: "YAJALON",
      name: "07109"
  },
  {
      value: "SAN LUCAS",
      name: "07110"
  },
  {
      value: "ZINACANTAN",
      name: "07111"
  },
  {
      value: "SAN JUAN CANCUC",
      name: "07112"
  },
  {
      value: "ALDAMA",
      name: "07113"
  },
  {
      value: "BENEMERITO DE LAS AMERICAS",
      name: "07114"
  },
  {
      value: "MARAVILLA TENEJAPA",
      name: "07115"
  },
  {
      value: "MARQUES DE COMILLAS",
      name: "07116"
  },
  {
      value: "MONTECRISTO DE GUERRERO",
      name: "07117"
  },
  {
      value: "SAN ANDRES DURAZNAL",
      name: "07118"
  },
  {
      value: "SANTIAGO EL PINAR",
      name: "07119"
  },
  {
      value: "CAPITAN LUIS ANGEL VIDAL",
      name: "07120"
  },
  {
      value: "RINCON CHAMULA SAN PEDRO",
      name: "07121"
  },
  {
      value: "EL PARRAL",
      name: "07122"
  },
  {
      value: "EMILIANO ZAPATA",
      name: "07123"
  },
  {
      value: "MEZCALAPA",
      name: "07124"
  },
  {
      value: "AHUMADA",
      name: "08001"
  },
  {
      value: "ALDAMA",
      name: "08002"
  },
  {
      value: "ALLENDE",
      name: "08003"
  },
  {
      value: "AQUILES SERDAN",
      name: "08004"
  },
  {
      value: "ASCENSION",
      name: "08005"
  },
  {
      value: "BACHINIVA",
      name: "08006"
  },
  {
      value: "BALLEZA",
      name: "08007"
  },
  {
      value: "BATOPILAS DE MANUEL GOMEZ MORIN",
      name: "08008"
  },
  {
      value: "BOCOYNA",
      name: "08009"
  },
  {
      value: "BUENAVENTURA",
      name: "08010"
  },
  {
      value: "CAMARGO",
      name: "08011"
  },
  {
      value: "CARICHI",
      name: "08012"
  },
  {
      value: "CASAS GRANDES",
      name: "08013"
  },
  {
      value: "CORONADO",
      name: "08014"
  },
  {
      value: "COYAME DEL SOTOL",
      name: "08015"
  },
  {
      value: "LA CRUZ",
      name: "08016"
  },
  {
      value: "CUAUHTEMOC",
      name: "08017"
  },
  {
      value: "CUSIHUIRIACHI",
      name: "08018"
  },
  {
      value: "CHIHUAHUA",
      name: "08019"
  },
  {
      value: "CHINIPAS",
      name: "08020"
  },
  {
      value: "DELICIAS",
      name: "08021"
  },
  {
      value: "DR. BELISARIO DOMINGUEZ",
      name: "08022"
  },
  {
      value: "GALEANA",
      name: "08023"
  },
  {
      value: "SANTA ISABEL",
      name: "08024"
  },
  {
      value: "GOMEZ FARIAS",
      name: "08025"
  },
  {
      value: "GRAN MORELOS",
      name: "08026"
  },
  {
      value: "GUACHOCHI",
      name: "08027"
  },
  {
      value: "GUADALUPE",
      name: "08028"
  },
  {
      value: "GUADALUPE Y CALVO",
      name: "08029"
  },
  {
      value: "GUAZAPARES",
      name: "08030"
  },
  {
      value: "GUERRERO",
      name: "08031"
  },
  {
      value: "HIDALGO DEL PARRAL",
      name: "08032"
  },
  {
      value: "HUEJOTITAN",
      name: "08033"
  },
  {
      value: "IGNACIO ZARAGOZA",
      name: "08034"
  },
  {
      value: "JANOS",
      name: "08035"
  },
  {
      value: "JIMENEZ",
      name: "08036"
  },
  {
      value: "JUAREZ",
      name: "08037"
  },
  {
      value: "JULIMES",
      name: "08038"
  },
  {
      value: "LOPEZ",
      name: "08039"
  },
  {
      value: "MADERA",
      name: "08040"
  },
  {
      value: "MAGUARICHI",
      name: "08041"
  },
  {
      value: "MANUEL BENAVIDES",
      name: "08042"
  },
  {
      value: "MATACHI",
      name: "08043"
  },
  {
      value: "MATAMOROS",
      name: "08044"
  },
  {
      value: "MEOQUI",
      name: "08045"
  },
  {
      value: "MORELOS",
      name: "08046"
  },
  {
      value: "MORIS",
      name: "08047"
  },
  {
      value: "NAMIQUIPA",
      name: "08048"
  },
  {
      value: "NONOAVA",
      name: "08049"
  },
  {
      value: "NUEVO CASAS GRANDES",
      name: "08050"
  },
  {
      value: "OCAMPO",
      name: "08051"
  },
  {
      value: "OJINAGA",
      name: "08052"
  },
  {
      value: "PRAXEDIS G. GUERRERO",
      name: "08053"
  },
  {
      value: "RIVA PALACIO",
      name: "08054"
  },
  {
      value: "ROSALES",
      name: "08055"
  },
  {
      value: "ROSARIO",
      name: "08056"
  },
  {
      value: "SAN FRANCISCO DE BORJA",
      name: "08057"
  },
  {
      value: "SAN FRANCISCO DE CONCHOS",
      name: "08058"
  },
  {
      value: "SAN FRANCISCO DEL ORO",
      name: "08059"
  },
  {
      value: "SANTA BARBARA",
      name: "08060"
  },
  {
      value: "SATEVO",
      name: "08061"
  },
  {
      value: "SAUCILLO",
      name: "08062"
  },
  {
      value: "TEMOSACHIC",
      name: "08063"
  },
  {
      value: "EL TULE",
      name: "08064"
  },
  {
      value: "URIQUE",
      name: "08065"
  },
  {
      value: "URUACHI",
      name: "08066"
  },
  {
      value: "VALLE DE ZARAGOZA",
      name: "08067"
  },
  {
      value: "AZCAPOTZALCO",
      name: "09002"
  },
  {
      value: "COYOACAN",
      name: "09003"
  },
  {
      value: "CUAJIMALPA DE MORELOS",
      name: "09004"
  },
  {
      value: "GUSTAVO A. MADERO",
      name: "09005"
  },
  {
      value: "IZTACALCO",
      name: "09006"
  },
  {
      value: "IZTAPALAPA",
      name: "09007"
  },
  {
      value: "LA MAGDALENA CONTRERAS",
      name: "09008"
  },
  {
      value: "MILPA ALTA",
      name: "09009"
  },
  {
      value: "ALVARO OBREGON",
      name: "09010"
  },
  {
      value: "TLAHUAC",
      name: "09011"
  },
  {
      value: "TLALPAN",
      name: "09012"
  },
  {
      value: "XOCHIMILCO",
      name: "09013"
  },
  {
      value: "BENITO JUAREZ",
      name: "09014"
  },
  {
      value: "CUAUHTEMOC",
      name: "09015"
  },
  {
      value: "MIGUEL HIDALGO",
      name: "09016"
  },
  {
      value: "VENUSTIANO CARRANZA",
      name: "09017"
  },
  {
      value: "CANATLAN",
      name: "10001"
  },
  {
      value: "CANELAS",
      name: "10002"
  },
  {
      value: "CONETO DE COMONFORT",
      name: "10003"
  },
  {
      value: "CUENCAME",
      name: "10004"
  },
  {
      value: "DURANGO",
      name: "10005"
  },
  {
      value: "GENERAL SIMON BOLIVAR",
      name: "10006"
  },
  {
      value: "GOMEZ PALACIO",
      name: "10007"
  },
  {
      value: "GUADALUPE VICTORIA",
      name: "10008"
  },
  {
      value: "GUANACEVI",
      name: "10009"
  },
  {
      value: "HIDALGO",
      name: "10010"
  },
  {
      value: "INDE",
      name: "10011"
  },
  {
      value: "LERDO",
      name: "10012"
  },
  {
      value: "MAPIMI",
      name: "10013"
  },
  {
      value: "MEZQUITAL",
      name: "10014"
  },
  {
      value: "NAZAS",
      name: "10015"
  },
  {
      value: "NOMBRE DE DIOS",
      name: "10016"
  },
  {
      value: "OCAMPO",
      name: "10017"
  },
  {
      value: "EL ORO",
      name: "10018"
  },
  {
      value: "OTAEZ",
      name: "10019"
  },
  {
      value: "PANUCO DE CORONADO",
      name: "10020"
  },
  {
      value: "PEÑON BLANCO",
      name: "10021"
  },
  {
      value: "POANAS",
      name: "10022"
  },
  {
      value: "PUEBLO NUEVO",
      name: "10023"
  },
  {
      value: "RODEO",
      name: "10024"
  },
  {
      value: "SAN BERNARDO",
      name: "10025"
  },
  {
      value: "SAN DIMAS",
      name: "10026"
  },
  {
      value: "SAN JUAN DE GUADALUPE",
      name: "10027"
  },
  {
      value: "SAN JUAN DEL RIO",
      name: "10028"
  },
  {
      value: "SAN LUIS DEL CORDERO",
      name: "10029"
  },
  {
      value: "SAN PEDRO DEL GALLO",
      name: "10030"
  },
  {
      value: "SANTA CLARA",
      name: "10031"
  },
  {
      value: "SANTIAGO PAPASQUIARO",
      name: "10032"
  },
  {
      value: "SUCHIL",
      name: "10033"
  },
  {
      value: "TAMAZULA",
      name: "10034"
  },
  {
      value: "TEPEHUANES",
      name: "10035"
  },
  {
      value: "TLAHUALILO",
      name: "10036"
  },
  {
      value: "TOPIA",
      name: "10037"
  },
  {
      value: "VICENTE GUERRERO",
      name: "10038"
  },
  {
      value: "NUEVO IDEAL",
      name: "10039"
  },
  {
      value: "ABASOLO",
      name: "11001"
  },
  {
      value: "ACAMBARO",
      name: "11002"
  },
  {
      value: "SAN MIGUEL DE ALLENDE",
      name: "11003"
  },
  {
      value: "APASEO EL ALTO",
      name: "11004"
  },
  {
      value: "APASEO EL GRANDE",
      name: "11005"
  },
  {
      value: "ATARJEA",
      name: "11006"
  },
  {
      value: "CELAYA",
      name: "11007"
  },
  {
      value: "MANUEL DOBLADO",
      name: "11008"
  },
  {
      value: "COMONFORT",
      name: "11009"
  },
  {
      value: "CORONEO",
      name: "11010"
  },
  {
      value: "CORTAZAR",
      name: "11011"
  },
  {
      value: "CUERAMARO",
      name: "11012"
  },
  {
      value: "DOCTOR MORA",
      name: "11013"
  },
  {
      value: "DOLORES HIDALGO CUNA DE LA INDEPENDENCIA NACIONAL",
      name: "11014"
  },
  {
      value: "GUANAJUATO",
      name: "11015"
  },
  {
      value: "HUANIMARO",
      name: "11016"
  },
  {
      value: "IRAPUATO",
      name: "11017"
  },
  {
      value: "JARAL DEL PROGRESO",
      name: "11018"
  },
  {
      value: "JERECUARO",
      name: "11019"
  },
  {
      value: "LEON",
      name: "11020"
  },
  {
      value: "MOROLEON",
      name: "11021"
  },
  {
      value: "OCAMPO",
      name: "11022"
  },
  {
      value: "PENJAMO",
      name: "11023"
  },
  {
      value: "PUEBLO NUEVO",
      name: "11024"
  },
  {
      value: "PURISIMA DEL RINCON",
      name: "11025"
  },
  {
      value: "ROMITA",
      name: "11026"
  },
  {
      value: "SALAMANCA",
      name: "11027"
  },
  {
      value: "SALVATIERRA",
      name: "11028"
  },
  {
      value: "SAN DIEGO DE LA UNION",
      name: "11029"
  },
  {
      value: "SAN FELIPE",
      name: "11030"
  },
  {
      value: "SAN FRANCISCO DEL RINCON",
      name: "11031"
  },
  {
      value: "SAN JOSE ITURBIDE",
      name: "11032"
  },
  {
      value: "SAN LUIS DE LA PAZ",
      name: "11033"
  },
  {
      value: "SANTA CATARINA",
      name: "11034"
  },
  {
      value: "SANTA CRUZ DE JUVENTINO ROSAS",
      name: "11035"
  },
  {
      value: "SANTIAGO MARAVATIO",
      name: "11036"
  },
  {
      value: "SILAO DE LA VICTORIA",
      name: "11037"
  },
  {
      value: "TARANDACUAO",
      name: "11038"
  },
  {
      value: "TARIMORO",
      name: "11039"
  },
  {
      value: "TIERRA BLANCA",
      name: "11040"
  },
  {
      value: "URIANGATO",
      name: "11041"
  },
  {
      value: "VALLE DE SANTIAGO",
      name: "11042"
  },
  {
      value: "VICTORIA",
      name: "11043"
  },
  {
      value: "VILLAGRAN",
      name: "11044"
  },
  {
      value: "XICHU",
      name: "11045"
  },
  {
      value: "YURIRIA",
      name: "11046"
  },
  {
      value: "ACAPULCO DE JUAREZ",
      name: "12001"
  },
  {
      value: "AHUACUOTZINGO",
      name: "12002"
  },
  {
      value: "AJUCHITLAN DEL PROGRESO",
      name: "12003"
  },
  {
      value: "ALCOZAUCA DE GUERRERO",
      name: "12004"
  },
  {
      value: "ALPOYECA",
      name: "12005"
  },
  {
      value: "APAXTLA",
      name: "12006"
  },
  {
      value: "ARCELIA",
      name: "12007"
  },
  {
      value: "ATENANGO DEL RIO",
      name: "12008"
  },
  {
      value: "ATLAMAJALCINGO DEL MONTE",
      name: "12009"
  },
  {
      value: "ATLIXTAC",
      name: "12010"
  },
  {
      value: "ATOYAC DE ALVAREZ",
      name: "12011"
  },
  {
      value: "AYUTLA DE LOS LIBRES",
      name: "12012"
  },
  {
      value: "AZOYU",
      name: "12013"
  },
  {
      value: "BENITO JUAREZ",
      name: "12014"
  },
  {
      value: "BUENAVISTA DE CUELLAR",
      name: "12015"
  },
  {
      value: "COAHUAYUTLA DE JOSE MARIA IZAZAGA",
      name: "12016"
  },
  {
      value: "COCULA",
      name: "12017"
  },
  {
      value: "COPALA",
      name: "12018"
  },
  {
      value: "COPALILLO",
      name: "12019"
  },
  {
      value: "COPANATOYAC",
      name: "12020"
  },
  {
      value: "COYUCA DE BENITEZ",
      name: "12021"
  },
  {
      value: "COYUCA DE CATALAN",
      name: "12022"
  },
  {
      value: "CUAJINICUILAPA",
      name: "12023"
  },
  {
      value: "CUALAC",
      name: "12024"
  },
  {
      value: "CUAUTEPEC",
      name: "12025"
  },
  {
      value: "CUETZALA DEL PROGRESO",
      name: "12026"
  },
  {
      value: "CUTZAMALA DE PINZON",
      name: "12027"
  },
  {
      value: "CHILAPA DE ALVAREZ",
      name: "12028"
  },
  {
      value: "CHILPANCINGO DE LOS BRAVO",
      name: "12029"
  },
  {
      value: "FLORENCIO VILLARREAL",
      name: "12030"
  },
  {
      value: "GENERAL CANUTO A. NERI",
      name: "12031"
  },
  {
      value: "GENERAL HELIODORO CASTILLO",
      name: "12032"
  },
  {
      value: "HUAMUXTITLAN",
      name: "12033"
  },
  {
      value: "HUITZUCO DE LOS FIGUEROA",
      name: "12034"
  },
  {
      value: "IGUALA DE LA INDEPENDENCIA",
      name: "12035"
  },
  {
      value: "IGUALAPA",
      name: "12036"
  },
  {
      value: "IXCATEOPAN DE CUAUHTEMOC",
      name: "12037"
  },
  {
      value: "ZIHUATANEJO DE AZUETA",
      name: "12038"
  },
  {
      value: "JUAN R. ESCUDERO",
      name: "12039"
  },
  {
      value: "LEONARDO BRAVO",
      name: "12040"
  },
  {
      value: "MALINALTEPEC",
      name: "12041"
  },
  {
      value: "MARTIR DE CUILAPAN",
      name: "12042"
  },
  {
      value: "METLATONOC",
      name: "12043"
  },
  {
      value: "MOCHITLAN",
      name: "12044"
  },
  {
      value: "OLINALA",
      name: "12045"
  },
  {
      value: "OMETEPEC",
      name: "12046"
  },
  {
      value: "PEDRO ASCENCIO ALQUISIRAS",
      name: "12047"
  },
  {
      value: "PETATLAN",
      name: "12048"
  },
  {
      value: "PILCAYA",
      name: "12049"
  },
  {
      value: "PUNGARABATO",
      name: "12050"
  },
  {
      value: "QUECHULTENANGO",
      name: "12051"
  },
  {
      value: "SAN LUIS ACATLAN",
      name: "12052"
  },
  {
      value: "SAN MARCOS",
      name: "12053"
  },
  {
      value: "SAN MIGUEL TOTOLAPAN",
      name: "12054"
  },
  {
      value: "TAXCO DE ALARCON",
      name: "12055"
  },
  {
      value: "TECOANAPA",
      name: "12056"
  },
  {
      value: "TECPAN DE GALEANA",
      name: "12057"
  },
  {
      value: "TELOLOAPAN",
      name: "12058"
  },
  {
      value: "TEPECOACUILCO DE TRUJANO",
      name: "12059"
  },
  {
      value: "TETIPAC",
      name: "12060"
  },
  {
      value: "TIXTLA DE GUERRERO",
      name: "12061"
  },
  {
      value: "TLACOACHISTLAHUACA",
      name: "12062"
  },
  {
      value: "TLACOAPA",
      name: "12063"
  },
  {
      value: "TLALCHAPA",
      name: "12064"
  },
  {
      value: "TLALIXTAQUILLA DE MALDONADO",
      name: "12065"
  },
  {
      value: "TLAPA DE COMONFORT",
      name: "12066"
  },
  {
      value: "TLAPEHUALA",
      name: "12067"
  },
  {
      value: "LA UNION DE ISIDORO MONTES DE OCA",
      name: "12068"
  },
  {
      value: "XALPATLAHUAC",
      name: "12069"
  },
  {
      value: "XOCHIHUEHUETLAN",
      name: "12070"
  },
  {
      value: "XOCHISTLAHUACA",
      name: "12071"
  },
  {
      value: "ZAPOTITLAN TABLAS",
      name: "12072"
  },
  {
      value: "ZIRANDARO",
      name: "12073"
  },
  {
      value: "ZITLALA",
      name: "12074"
  },
  {
      value: "EDUARDO NERI",
      name: "12075"
  },
  {
      value: "ACATEPEC",
      name: "12076"
  },
  {
      value: "MARQUELIA",
      name: "12077"
  },
  {
      value: "COCHOAPA EL GRANDE",
      name: "12078"
  },
  {
      value: "JOSE JOAQUIN DE HERRERA",
      name: "12079"
  },
  {
      value: "JUCHITAN",
      name: "12080"
  },
  {
      value: "ILIATENCO",
      name: "12081"
  },
  {
      value: "ACATLAN",
      name: "13001"
  },
  {
      value: "ACAXOCHITLAN",
      name: "13002"
  },
  {
      value: "ACTOPAN",
      name: "13003"
  },
  {
      value: "AGUA BLANCA DE ITURBIDE",
      name: "13004"
  },
  {
      value: "AJACUBA",
      name: "13005"
  },
  {
      value: "ALFAJAYUCAN",
      name: "13006"
  },
  {
      value: "ALMOLOYA",
      name: "13007"
  },
  {
      value: "APAN",
      name: "13008"
  },
  {
      value: "EL ARENAL",
      name: "13009"
  },
  {
      value: "ATITALAQUIA",
      name: "13010"
  },
  {
      value: "ATLAPEXCO",
      name: "13011"
  },
  {
      value: "ATOTONILCO EL GRANDE",
      name: "13012"
  },
  {
      value: "ATOTONILCO DE TULA",
      name: "13013"
  },
  {
      value: "CALNALI",
      name: "13014"
  },
  {
      value: "CARDONAL",
      name: "13015"
  },
  {
      value: "CUAUTEPEC DE HINOJOSA",
      name: "13016"
  },
  {
      value: "CHAPANTONGO",
      name: "13017"
  },
  {
      value: "CHAPULHUACAN",
      name: "13018"
  },
  {
      value: "CHILCUAUTLA",
      name: "13019"
  },
  {
      value: "ELOXOCHITLAN",
      name: "13020"
  },
  {
      value: "EMILIANO ZAPATA",
      name: "13021"
  },
  {
      value: "EPAZOYUCAN",
      name: "13022"
  },
  {
      value: "FRANCISCO I. MADERO",
      name: "13023"
  },
  {
      value: "HUASCA DE OCAMPO",
      name: "13024"
  },
  {
      value: "HUAUTLA",
      name: "13025"
  },
  {
      value: "HUAZALINGO",
      name: "13026"
  },
  {
      value: "HUEHUETLA",
      name: "13027"
  },
  {
      value: "HUEJUTLA DE REYES",
      name: "13028"
  },
  {
      value: "HUICHAPAN",
      name: "13029"
  },
  {
      value: "IXMIQUILPAN",
      name: "13030"
  },
  {
      value: "JACALA DE LEDEZMA",
      name: "13031"
  },
  {
      value: "JALTOCAN",
      name: "13032"
  },
  {
      value: "JUAREZ HIDALGO",
      name: "13033"
  },
  {
      value: "LOLOTLA",
      name: "13034"
  },
  {
      value: "METEPEC",
      name: "13035"
  },
  {
      value: "SAN AGUSTIN METZQUITITLAN",
      name: "13036"
  },
  {
      value: "METZTITLAN",
      name: "13037"
  },
  {
      value: "MINERAL DEL CHICO",
      name: "13038"
  },
  {
      value: "MINERAL DEL MONTE",
      name: "13039"
  },
  {
      value: "LA MISION",
      name: "13040"
  },
  {
      value: "MIXQUIAHUALA DE JUAREZ",
      name: "13041"
  },
  {
      value: "MOLANGO DE ESCAMILLA",
      name: "13042"
  },
  {
      value: "NICOLAS FLORES",
      name: "13043"
  },
  {
      value: "NOPALA DE VILLAGRAN",
      name: "13044"
  },
  {
      value: "OMITLAN DE JUAREZ",
      name: "13045"
  },
  {
      value: "SAN FELIPE ORIZATLAN",
      name: "13046"
  },
  {
      value: "PACULA",
      name: "13047"
  },
  {
      value: "PACHUCA DE SOTO",
      name: "13048"
  },
  {
      value: "PISAFLORES",
      name: "13049"
  },
  {
      value: "PROGRESO DE OBREGON",
      name: "13050"
  },
  {
      value: "MINERAL DE LA REFORMA",
      name: "13051"
  },
  {
      value: "SAN AGUSTIN TLAXIACA",
      name: "13052"
  },
  {
      value: "SAN BARTOLO TUTOTEPEC",
      name: "13053"
  },
  {
      value: "SAN SALVADOR",
      name: "13054"
  },
  {
      value: "SANTIAGO DE ANAYA",
      name: "13055"
  },
  {
      value: "SANTIAGO TULANTEPEC DE LUGO GUERRERO",
      name: "13056"
  },
  {
      value: "SINGUILUCAN",
      name: "13057"
  },
  {
      value: "TASQUILLO",
      name: "13058"
  },
  {
      value: "TECOZAUTLA",
      name: "13059"
  },
  {
      value: "TENANGO DE DORIA",
      name: "13060"
  },
  {
      value: "TEPEAPULCO",
      name: "13061"
  },
  {
      value: "TEPEHUACAN DE GUERRERO",
      name: "13062"
  },
  {
      value: "TEPEJI DEL RIO DE OCAMPO",
      name: "13063"
  },
  {
      value: "TEPETITLAN",
      name: "13064"
  },
  {
      value: "TETEPANGO",
      name: "13065"
  },
  {
      value: "VILLA DE TEZONTEPEC",
      name: "13066"
  },
  {
      value: "TEZONTEPEC DE ALDAMA",
      name: "13067"
  },
  {
      value: "TIANGUISTENGO",
      name: "13068"
  },
  {
      value: "TIZAYUCA",
      name: "13069"
  },
  {
      value: "TLAHUELILPAN",
      name: "13070"
  },
  {
      value: "TLAHUILTEPA",
      name: "13071"
  },
  {
      value: "TLANALAPA",
      name: "13072"
  },
  {
      value: "TLANCHINOL",
      name: "13073"
  },
  {
      value: "TLAXCOAPAN",
      name: "13074"
  },
  {
      value: "TOLCAYUCA",
      name: "13075"
  },
  {
      value: "TULA DE ALLENDE",
      name: "13076"
  },
  {
      value: "TULANCINGO DE BRAVO",
      name: "13077"
  },
  {
      value: "XOCHIATIPAN",
      name: "13078"
  },
  {
      value: "XOCHICOATLAN",
      name: "13079"
  },
  {
      value: "YAHUALICA",
      name: "13080"
  },
  {
      value: "ZACUALTIPAN DE ANGELES",
      name: "13081"
  },
  {
      value: "ZAPOTLAN DE JUAREZ",
      name: "13082"
  },
  {
      value: "ZEMPOALA",
      name: "13083"
  },
  {
      value: "ZIMAPAN",
      name: "13084"
  },
  {
      value: "ACATIC",
      name: "14001"
  },
  {
      value: "ACATLAN DE JUAREZ",
      name: "14002"
  },
  {
      value: "AHUALULCO DE MERCADO",
      name: "14003"
  },
  {
      value: "AMACUECA",
      name: "14004"
  },
  {
      value: "AMATITAN",
      name: "14005"
  },
  {
      value: "AMECA",
      name: "14006"
  },
  {
      value: "SAN JUANITO DE ESCOBEDO",
      name: "14007"
  },
  {
      value: "ARANDAS",
      name: "14008"
  },
  {
      value: "EL ARENAL",
      name: "14009"
  },
  {
      value: "ATEMAJAC DE BRIZUELA",
      name: "14010"
  },
  {
      value: "ATENGO",
      name: "14011"
  },
  {
      value: "ATENGUILLO",
      name: "14012"
  },
  {
      value: "ATOTONILCO EL ALTO",
      name: "14013"
  },
  {
      value: "ATOYAC",
      name: "14014"
  },
  {
      value: "AUTLAN DE NAVARRO",
      name: "14015"
  },
  {
      value: "AYOTLAN",
      name: "14016"
  },
  {
      value: "AYUTLA",
      name: "14017"
  },
  {
      value: "LA BARCA",
      name: "14018"
  },
  {
      value: "BOLAÑOS",
      name: "14019"
  },
  {
      value: "CABO CORRIENTES",
      name: "14020"
  },
  {
      value: "CASIMIRO CASTILLO",
      name: "14021"
  },
  {
      value: "CIHUATLAN",
      name: "14022"
  },
  {
      value: "ZAPOTLAN EL GRANDE",
      name: "14023"
  },
  {
      value: "COCULA",
      name: "14024"
  },
  {
      value: "COLOTLAN",
      name: "14025"
  },
  {
      value: "CONCEPCION DE BUENOS AIRES",
      name: "14026"
  },
  {
      value: "CUAUTITLAN DE GARCIA BARRAGAN",
      name: "14027"
  },
  {
      value: "CUAUTLA",
      name: "14028"
  },
  {
      value: "CUQUIO",
      name: "14029"
  },
  {
      value: "CHAPALA",
      name: "14030"
  },
  {
      value: "CHIMALTITAN",
      name: "14031"
  },
  {
      value: "CHIQUILISTLAN",
      name: "14032"
  },
  {
      value: "DEGOLLADO",
      name: "14033"
  },
  {
      value: "EJUTLA",
      name: "14034"
  },
  {
      value: "ENCARNACION DE DIAZ",
      name: "14035"
  },
  {
      value: "ETZATLAN",
      name: "14036"
  },
  {
      value: "EL GRULLO",
      name: "14037"
  },
  {
      value: "GUACHINANGO",
      name: "14038"
  },
  {
      value: "GUADALAJARA",
      name: "14039"
  },
  {
      value: "HOSTOTIPAQUILLO",
      name: "14040"
  },
  {
      value: "HUEJUCAR",
      name: "14041"
  },
  {
      value: "HUEJUQUILLA EL ALTO",
      name: "14042"
  },
  {
      value: "LA HUERTA",
      name: "14043"
  },
  {
      value: "IXTLAHUACAN DE LOS MEMBRILLOS",
      name: "14044"
  },
  {
      value: "IXTLAHUACAN DEL RIO",
      name: "14045"
  },
  {
      value: "JALOSTOTITLAN",
      name: "14046"
  },
  {
      value: "JAMAY",
      name: "14047"
  },
  {
      value: "JESUS MARIA",
      name: "14048"
  },
  {
      value: "JILOTLAN DE LOS DOLORES",
      name: "14049"
  },
  {
      value: "JOCOTEPEC",
      name: "14050"
  },
  {
      value: "JUANACATLAN",
      name: "14051"
  },
  {
      value: "JUCHITLAN",
      name: "14052"
  },
  {
      value: "LAGOS DE MORENO",
      name: "14053"
  },
  {
      value: "EL LIMON",
      name: "14054"
  },
  {
      value: "MAGDALENA",
      name: "14055"
  },
  {
      value: "SANTA MARIA DEL ORO",
      name: "14056"
  },
  {
      value: "LA MANZANILLA DE LA PAZ",
      name: "14057"
  },
  {
      value: "MASCOTA",
      name: "14058"
  },
  {
      value: "MAZAMITLA",
      name: "14059"
  },
  {
      value: "MEXTICACAN",
      name: "14060"
  },
  {
      value: "MEZQUITIC",
      name: "14061"
  },
  {
      value: "MIXTLAN",
      name: "14062"
  },
  {
      value: "OCOTLAN",
      name: "14063"
  },
  {
      value: "OJUELOS DE JALISCO",
      name: "14064"
  },
  {
      value: "PIHUAMO",
      name: "14065"
  },
  {
      value: "PONCITLAN",
      name: "14066"
  },
  {
      value: "PUERTO VALLARTA",
      name: "14067"
  },
  {
      value: "VILLA PURIFICACION",
      name: "14068"
  },
  {
      value: "QUITUPAN",
      name: "14069"
  },
  {
      value: "EL SALTO",
      name: "14070"
  },
  {
      value: "SAN CRISTOBAL DE LA BARRANCA",
      name: "14071"
  },
  {
      value: "SAN DIEGO DE ALEJANDRIA",
      name: "14072"
  },
  {
      value: "SAN JUAN DE LOS LAGOS",
      name: "14073"
  },
  {
      value: "SAN JULIAN",
      name: "14074"
  },
  {
      value: "SAN MARCOS",
      name: "14075"
  },
  {
      value: "SAN MARTIN DE BOLAÑOS",
      name: "14076"
  },
  {
      value: "SAN MARTIN HIDALGO",
      name: "14077"
  },
  {
      value: "SAN MIGUEL EL ALTO",
      name: "14078"
  },
  {
      value: "GOMEZ FARIAS",
      name: "14079"
  },
  {
      value: "SAN SEBASTIAN DEL OESTE",
      name: "14080"
  },
  {
      value: "SANTA MARIA DE LOS ANGELES",
      name: "14081"
  },
  {
      value: "SAYULA",
      name: "14082"
  },
  {
      value: "TALA",
      name: "14083"
  },
  {
      value: "TALPA DE ALLENDE",
      name: "14084"
  },
  {
      value: "TAMAZULA DE GORDIANO",
      name: "14085"
  },
  {
      value: "TAPALPA",
      name: "14086"
  },
  {
      value: "TECALITLAN",
      name: "14087"
  },
  {
      value: "TECOLOTLAN",
      name: "14088"
  },
  {
      value: "TECHALUTA DE MONTENEGRO",
      name: "14089"
  },
  {
      value: "TENAMAXTLAN",
      name: "14090"
  },
  {
      value: "TEOCALTICHE",
      name: "14091"
  },
  {
      value: "TEOCUITATLAN DE CORONA",
      name: "14092"
  },
  {
      value: "TEPATITLAN DE MORELOS",
      name: "14093"
  },
  {
      value: "TEQUILA",
      name: "14094"
  },
  {
      value: "TEUCHITLAN",
      name: "14095"
  },
  {
      value: "TIZAPAN EL ALTO",
      name: "14096"
  },
  {
      value: "TLAJOMULCO DE ZUÑIGA",
      name: "14097"
  },
  {
      value: "SAN PEDRO TLAQUEPAQUE",
      name: "14098"
  },
  {
      value: "TOLIMAN",
      name: "14099"
  },
  {
      value: "TOMATLAN",
      name: "14100"
  },
  {
      value: "TONALA",
      name: "14101"
  },
  {
      value: "TONAYA",
      name: "14102"
  },
  {
      value: "TONILA",
      name: "14103"
  },
  {
      value: "TOTATICHE",
      name: "14104"
  },
  {
      value: "TOTOTLAN",
      name: "14105"
  },
  {
      value: "TUXCACUESCO",
      name: "14106"
  },
  {
      value: "TUXCUECA",
      name: "14107"
  },
  {
      value: "TUXPAN",
      name: "14108"
  },
  {
      value: "UNION DE SAN ANTONIO",
      name: "14109"
  },
  {
      value: "UNION DE TULA",
      name: "14110"
  },
  {
      value: "VALLE DE GUADALUPE",
      name: "14111"
  },
  {
      value: "VALLE DE JUAREZ",
      name: "14112"
  },
  {
      value: "SAN GABRIEL",
      name: "14113"
  },
  {
      value: "VILLA CORONA",
      name: "14114"
  },
  {
      value: "VILLA GUERRERO",
      name: "14115"
  },
  {
      value: "VILLA HIDALGO",
      name: "14116"
  },
  {
      value: "CAÑADAS DE OBREGON",
      name: "14117"
  },
  {
      value: "YAHUALICA DE GONZALEZ GALLO",
      name: "14118"
  },
  {
      value: "ZACOALCO DE TORRES",
      name: "14119"
  },
  {
      value: "ZAPOPAN",
      name: "14120"
  },
  {
      value: "ZAPOTILTIC",
      name: "14121"
  },
  {
      value: "ZAPOTITLAN DE VADILLO",
      name: "14122"
  },
  {
      value: "ZAPOTLAN DEL REY",
      name: "14123"
  },
  {
      value: "ZAPOTLANEJO",
      name: "14124"
  },
  {
      value: "SAN IGNACIO CERRO GORDO",
      name: "14125"
  },
  {
      value: "ACAMBAY DE RUIZ CASTAÑEDA",
      name: "15001"
  },
  {
      value: "ACOLMAN",
      name: "15002"
  },
  {
      value: "ACULCO",
      name: "15003"
  },
  {
      value: "ALMOLOYA DE ALQUISIRAS",
      name: "15004"
  },
  {
      value: "ALMOLOYA DE JUAREZ",
      name: "15005"
  },
  {
      value: "ALMOLOYA DEL RIO",
      name: "15006"
  },
  {
      value: "AMANALCO",
      name: "15007"
  },
  {
      value: "AMATEPEC",
      name: "15008"
  },
  {
      value: "AMECAMECA",
      name: "15009"
  },
  {
      value: "APAXCO",
      name: "15010"
  },
  {
      value: "ATENCO",
      name: "15011"
  },
  {
      value: "ATIZAPAN",
      name: "15012"
  },
  {
      value: "ATIZAPAN DE ZARAGOZA",
      name: "15013"
  },
  {
      value: "ATLACOMULCO",
      name: "15014"
  },
  {
      value: "ATLAUTLA",
      name: "15015"
  },
  {
      value: "AXAPUSCO",
      name: "15016"
  },
  {
      value: "AYAPANGO",
      name: "15017"
  },
  {
      value: "CALIMAYA",
      name: "15018"
  },
  {
      value: "CAPULHUAC",
      name: "15019"
  },
  {
      value: "COACALCO DE BERRIOZABAL",
      name: "15020"
  },
  {
      value: "COATEPEC HARINAS",
      name: "15021"
  },
  {
      value: "COCOTITLAN",
      name: "15022"
  },
  {
      value: "COYOTEPEC",
      name: "15023"
  },
  {
      value: "CUAUTITLAN",
      name: "15024"
  },
  {
      value: "CHALCO",
      name: "15025"
  },
  {
      value: "CHAPA DE MOTA",
      name: "15026"
  },
  {
      value: "CHAPULTEPEC",
      name: "15027"
  },
  {
      value: "CHIAUTLA",
      name: "15028"
  },
  {
      value: "CHICOLOAPAN",
      name: "15029"
  },
  {
      value: "CHICONCUAC",
      name: "15030"
  },
  {
      value: "CHIMALHUACAN",
      name: "15031"
  },
  {
      value: "DONATO GUERRA",
      name: "15032"
  },
  {
      value: "ECATEPEC DE MORELOS",
      name: "15033"
  },
  {
      value: "ECATZINGO",
      name: "15034"
  },
  {
      value: "HUEHUETOCA",
      name: "15035"
  },
  {
      value: "HUEYPOXTLA",
      name: "15036"
  },
  {
      value: "HUIXQUILUCAN",
      name: "15037"
  },
  {
      value: "ISIDRO FABELA",
      name: "15038"
  },
  {
      value: "IXTAPALUCA",
      name: "15039"
  },
  {
      value: "IXTAPAN DE LA SAL",
      name: "15040"
  },
  {
      value: "IXTAPAN DEL ORO",
      name: "15041"
  },
  {
      value: "IXTLAHUACA",
      name: "15042"
  },
  {
      value: "XALATLACO",
      name: "15043"
  },
  {
      value: "JALTENCO",
      name: "15044"
  },
  {
      value: "JILOTEPEC",
      name: "15045"
  },
  {
      value: "JILOTZINGO",
      name: "15046"
  },
  {
      value: "JIQUIPILCO",
      name: "15047"
  },
  {
      value: "JOCOTITLAN",
      name: "15048"
  },
  {
      value: "JOQUICINGO",
      name: "15049"
  },
  {
      value: "JUCHITEPEC",
      name: "15050"
  },
  {
      value: "LERMA",
      name: "15051"
  },
  {
      value: "MALINALCO",
      name: "15052"
  },
  {
      value: "MELCHOR OCAMPO",
      name: "15053"
  },
  {
      value: "METEPEC",
      name: "15054"
  },
  {
      value: "MEXICALTZINGO",
      name: "15055"
  },
  {
      value: "MORELOS",
      name: "15056"
  },
  {
      value: "NAUCALPAN DE JUAREZ",
      name: "15057"
  },
  {
      value: "NEZAHUALCOYOTL",
      name: "15058"
  },
  {
      value: "NEXTLALPAN",
      name: "15059"
  },
  {
      value: "NICOLAS ROMERO",
      name: "15060"
  },
  {
      value: "NOPALTEPEC",
      name: "15061"
  },
  {
      value: "OCOYOACAC",
      name: "15062"
  },
  {
      value: "OCUILAN",
      name: "15063"
  },
  {
      value: "EL ORO",
      name: "15064"
  },
  {
      value: "OTUMBA",
      name: "15065"
  },
  {
      value: "OTZOLOAPAN",
      name: "15066"
  },
  {
      value: "OTZOLOTEPEC",
      name: "15067"
  },
  {
      value: "OZUMBA",
      name: "15068"
  },
  {
      value: "PAPALOTLA",
      name: "15069"
  },
  {
      value: "LA PAZ",
      name: "15070"
  },
  {
      value: "POLOTITLAN",
      name: "15071"
  },
  {
      value: "RAYON",
      name: "15072"
  },
  {
      value: "SAN ANTONIO LA ISLA",
      name: "15073"
  },
  {
      value: "SAN FELIPE DEL PROGRESO",
      name: "15074"
  },
  {
      value: "SAN MARTIN DE LAS PIRAMIDES",
      name: "15075"
  },
  {
      value: "SAN MATEO ATENCO",
      name: "15076"
  },
  {
      value: "SAN SIMON DE GUERRERO",
      name: "15077"
  },
  {
      value: "SANTO TOMAS",
      name: "15078"
  },
  {
      value: "SOYANIQUILPAN DE JUAREZ",
      name: "15079"
  },
  {
      value: "SULTEPEC",
      name: "15080"
  },
  {
      value: "TECAMAC",
      name: "15081"
  },
  {
      value: "TEJUPILCO",
      name: "15082"
  },
  {
      value: "TEMAMATLA",
      name: "15083"
  },
  {
      value: "TEMASCALAPA",
      name: "15084"
  },
  {
      value: "TEMASCALCINGO",
      name: "15085"
  },
  {
      value: "TEMASCALTEPEC",
      name: "15086"
  },
  {
      value: "TEMOAYA",
      name: "15087"
  },
  {
      value: "TENANCINGO",
      name: "15088"
  },
  {
      value: "TENANGO DEL AIRE",
      name: "15089"
  },
  {
      value: "TENANGO DEL VALLE",
      name: "15090"
  },
  {
      value: "TEOLOYUCAN",
      name: "15091"
  },
  {
      value: "TEOTIHUACAN",
      name: "15092"
  },
  {
      value: "TEPETLAOXTOC",
      name: "15093"
  },
  {
      value: "TEPETLIXPA",
      name: "15094"
  },
  {
      value: "TEPOTZOTLAN",
      name: "15095"
  },
  {
      value: "TEQUIXQUIAC",
      name: "15096"
  },
  {
      value: "TEXCALTITLAN",
      name: "15097"
  },
  {
      value: "TEXCALYACAC",
      name: "15098"
  },
  {
      value: "TEXCOCO",
      name: "15099"
  },
  {
      value: "TEZOYUCA",
      name: "15100"
  },
  {
      value: "TIANGUISTENCO",
      name: "15101"
  },
  {
      value: "TIMILPAN",
      name: "15102"
  },
  {
      value: "TLALMANALCO",
      name: "15103"
  },
  {
      value: "TLALNEPANTLA DE BAZ",
      name: "15104"
  },
  {
      value: "TLATLAYA",
      name: "15105"
  },
  {
      value: "TOLUCA",
      name: "15106"
  },
  {
      value: "TONATICO",
      name: "15107"
  },
  {
      value: "TULTEPEC",
      name: "15108"
  },
  {
      value: "TULTITLAN",
      name: "15109"
  },
  {
      value: "VALLE DE BRAVO",
      name: "15110"
  },
  {
      value: "VILLA DE ALLENDE",
      name: "15111"
  },
  {
      value: "VILLA DEL CARBON",
      name: "15112"
  },
  {
      value: "VILLA GUERRERO",
      name: "15113"
  },
  {
      value: "VILLA VICTORIA",
      name: "15114"
  },
  {
      value: "XONACATLAN",
      name: "15115"
  },
  {
      value: "ZACAZONAPAN",
      name: "15116"
  },
  {
      value: "ZACUALPAN",
      name: "15117"
  },
  {
      value: "ZINACANTEPEC",
      name: "15118"
  },
  {
      value: "ZUMPAHUACAN",
      name: "15119"
  },
  {
      value: "ZUMPANGO",
      name: "15120"
  },
  {
      value: "CUAUTITLAN IZCALLI",
      name: "15121"
  },
  {
      value: "VALLE DE CHALCO SOLIDARIDAD",
      name: "15122"
  },
  {
      value: "LUVIANOS",
      name: "15123"
  },
  {
      value: "SAN JOSE DEL RINCON",
      name: "15124"
  },
  {
      value: "TONANITLA",
      name: "15125"
  },
  {
      value: "ACUITZIO",
      name: "16001"
  },
  {
      value: "AGUILILLA",
      name: "16002"
  },
  {
      value: "ALVARO OBREGON",
      name: "16003"
  },
  {
      value: "ANGAMACUTIRO",
      name: "16004"
  },
  {
      value: "ANGANGUEO",
      name: "16005"
  },
  {
      value: "APATZINGAN",
      name: "16006"
  },
  {
      value: "APORO",
      name: "16007"
  },
  {
      value: "AQUILA",
      name: "16008"
  },
  {
      value: "ARIO",
      name: "16009"
  },
  {
      value: "ARTEAGA",
      name: "16010"
  },
  {
      value: "BRISEÑAS",
      name: "16011"
  },
  {
      value: "BUENAVISTA",
      name: "16012"
  },
  {
      value: "CARACUARO",
      name: "16013"
  },
  {
      value: "COAHUAYANA",
      name: "16014"
  },
  {
      value: "COALCOMAN DE VAZQUEZ PALLARES",
      name: "16015"
  },
  {
      value: "COENEO",
      name: "16016"
  },
  {
      value: "CONTEPEC",
      name: "16017"
  },
  {
      value: "COPANDARO",
      name: "16018"
  },
  {
      value: "COTIJA",
      name: "16019"
  },
  {
      value: "CUITZEO",
      name: "16020"
  },
  {
      value: "CHARAPAN",
      name: "16021"
  },
  {
      value: "CHARO",
      name: "16022"
  },
  {
      value: "CHAVINDA",
      name: "16023"
  },
  {
      value: "CHERAN",
      name: "16024"
  },
  {
      value: "CHILCHOTA",
      name: "16025"
  },
  {
      value: "CHINICUILA",
      name: "16026"
  },
  {
      value: "CHUCANDIRO",
      name: "16027"
  },
  {
      value: "CHURINTZIO",
      name: "16028"
  },
  {
      value: "CHURUMUCO",
      name: "16029"
  },
  {
      value: "ECUANDUREO",
      name: "16030"
  },
  {
      value: "EPITACIO HUERTA",
      name: "16031"
  },
  {
      value: "ERONGARICUARO",
      name: "16032"
  },
  {
      value: "GABRIEL ZAMORA",
      name: "16033"
  },
  {
      value: "HIDALGO",
      name: "16034"
  },
  {
      value: "LA HUACANA",
      name: "16035"
  },
  {
      value: "HUANDACAREO",
      name: "16036"
  },
  {
      value: "HUANIQUEO",
      name: "16037"
  },
  {
      value: "HUETAMO",
      name: "16038"
  },
  {
      value: "HUIRAMBA",
      name: "16039"
  },
  {
      value: "INDAPARAPEO",
      name: "16040"
  },
  {
      value: "IRIMBO",
      name: "16041"
  },
  {
      value: "IXTLAN",
      name: "16042"
  },
  {
      value: "JACONA",
      name: "16043"
  },
  {
      value: "JIMENEZ",
      name: "16044"
  },
  {
      value: "JIQUILPAN",
      name: "16045"
  },
  {
      value: "JUAREZ",
      name: "16046"
  },
  {
      value: "JUNGAPEO",
      name: "16047"
  },
  {
      value: "LAGUNILLAS",
      name: "16048"
  },
  {
      value: "MADERO",
      name: "16049"
  },
  {
      value: "MARAVATIO",
      name: "16050"
  },
  {
      value: "MARCOS CASTELLANOS",
      name: "16051"
  },
  {
      value: "LAZARO CARDENAS",
      name: "16052"
  },
  {
      value: "MORELIA",
      name: "16053"
  },
  {
      value: "MORELOS",
      name: "16054"
  },
  {
      value: "MUGICA",
      name: "16055"
  },
  {
      value: "NAHUATZEN",
      name: "16056"
  },
  {
      value: "NOCUPETARO",
      name: "16057"
  },
  {
      value: "NUEVO PARANGARICUTIRO",
      name: "16058"
  },
  {
      value: "NUEVO URECHO",
      name: "16059"
  },
  {
      value: "NUMARAN",
      name: "16060"
  },
  {
      value: "OCAMPO",
      name: "16061"
  },
  {
      value: "PAJACUARAN",
      name: "16062"
  },
  {
      value: "PANINDICUARO",
      name: "16063"
  },
  {
      value: "PARACUARO",
      name: "16064"
  },
  {
      value: "PARACHO",
      name: "16065"
  },
  {
      value: "PATZCUARO",
      name: "16066"
  },
  {
      value: "PENJAMILLO",
      name: "16067"
  },
  {
      value: "PERIBAN",
      name: "16068"
  },
  {
      value: "LA PIEDAD",
      name: "16069"
  },
  {
      value: "PUREPERO",
      name: "16070"
  },
  {
      value: "PURUANDIRO",
      name: "16071"
  },
  {
      value: "QUERENDARO",
      name: "16072"
  },
  {
      value: "QUIROGA",
      name: "16073"
  },
  {
      value: "COJUMATLAN DE REGULES",
      name: "16074"
  },
  {
      value: "LOS REYES",
      name: "16075"
  },
  {
      value: "SAHUAYO",
      name: "16076"
  },
  {
      value: "SAN LUCAS",
      name: "16077"
  },
  {
      value: "SANTA ANA MAYA",
      name: "16078"
  },
  {
      value: "SALVADOR ESCALANTE",
      name: "16079"
  },
  {
      value: "SENGUIO",
      name: "16080"
  },
  {
      value: "SUSUPUATO",
      name: "16081"
  },
  {
      value: "TACAMBARO",
      name: "16082"
  },
  {
      value: "TANCITARO",
      name: "16083"
  },
  {
      value: "TANGAMANDAPIO",
      name: "16084"
  },
  {
      value: "TANGANCICUARO",
      name: "16085"
  },
  {
      value: "TANHUATO",
      name: "16086"
  },
  {
      value: "TARETAN",
      name: "16087"
  },
  {
      value: "TARIMBARO",
      name: "16088"
  },
  {
      value: "TEPALCATEPEC",
      name: "16089"
  },
  {
      value: "TINGAMBATO",
      name: "16090"
  },
  {
      value: "TINGÜINDIN",
      name: "16091"
  },
  {
      value: "TIQUICHEO DE NICOLAS ROMERO",
      name: "16092"
  },
  {
      value: "TLALPUJAHUA",
      name: "16093"
  },
  {
      value: "TLAZAZALCA",
      name: "16094"
  },
  {
      value: "TOCUMBO",
      name: "16095"
  },
  {
      value: "TUMBISCATIO",
      name: "16096"
  },
  {
      value: "TURICATO",
      name: "16097"
  },
  {
      value: "TUXPAN",
      name: "16098"
  },
  {
      value: "TUZANTLA",
      name: "16099"
  },
  {
      value: "TZINTZUNTZAN",
      name: "16100"
  },
  {
      value: "TZITZIO",
      name: "16101"
  },
  {
      value: "URUAPAN",
      name: "16102"
  },
  {
      value: "VENUSTIANO CARRANZA",
      name: "16103"
  },
  {
      value: "VILLAMAR",
      name: "16104"
  },
  {
      value: "VISTA HERMOSA",
      name: "16105"
  },
  {
      value: "YURECUARO",
      name: "16106"
  },
  {
      value: "ZACAPU",
      name: "16107"
  },
  {
      value: "ZAMORA",
      name: "16108"
  },
  {
      value: "ZINAPARO",
      name: "16109"
  },
  {
      value: "ZINAPECUARO",
      name: "16110"
  },
  {
      value: "ZIRACUARETIRO",
      name: "16111"
  },
  {
      value: "ZITACUARO",
      name: "16112"
  },
  {
      value: "JOSE SIXTO VERDUZCO",
      name: "16113"
  },
  {
      value: "AMACUZAC",
      name: "17001"
  },
  {
      value: "ATLATLAHUCAN",
      name: "17002"
  },
  {
      value: "AXOCHIAPAN",
      name: "17003"
  },
  {
      value: "AYALA",
      name: "17004"
  },
  {
      value: "COATLAN DEL RIO",
      name: "17005"
  },
  {
      value: "CUAUTLA",
      name: "17006"
  },
  {
      value: "CUERNAVACA",
      name: "17007"
  },
  {
      value: "EMILIANO ZAPATA",
      name: "17008"
  },
  {
      value: "HUITZILAC",
      name: "17009"
  },
  {
      value: "JANTETELCO",
      name: "17010"
  },
  {
      value: "JIUTEPEC",
      name: "17011"
  },
  {
      value: "JOJUTLA",
      name: "17012"
  },
  {
      value: "JONACATEPEC DE LEANDRO VALLE",
      name: "17013"
  },
  {
      value: "MAZATEPEC",
      name: "17014"
  },
  {
      value: "MIACATLAN",
      name: "17015"
  },
  {
      value: "OCUITUCO",
      name: "17016"
  },
  {
      value: "PUENTE DE IXTLA",
      name: "17017"
  },
  {
      value: "TEMIXCO",
      name: "17018"
  },
  {
      value: "TEPALCINGO",
      name: "17019"
  },
  {
      value: "TEPOZTLAN",
      name: "17020"
  },
  {
      value: "TETECALA",
      name: "17021"
  },
  {
      value: "TETELA DEL VOLCAN",
      name: "17022"
  },
  {
      value: "TLALNEPANTLA",
      name: "17023"
  },
  {
      value: "TLALTIZAPAN DE ZAPATA",
      name: "17024"
  },
  {
      value: "TLAQUILTENANGO",
      name: "17025"
  },
  {
      value: "TLAYACAPAN",
      name: "17026"
  },
  {
      value: "TOTOLAPAN",
      name: "17027"
  },
  {
      value: "XOCHITEPEC",
      name: "17028"
  },
  {
      value: "YAUTEPEC",
      name: "17029"
  },
  {
      value: "YECAPIXTLA",
      name: "17030"
  },
  {
      value: "ZACATEPEC",
      name: "17031"
  },
  {
      value: "ZACUALPAN DE AMILPAS",
      name: "17032"
  },
  {
      value: "TEMOAC",
      name: "17033"
  },
  {
      value: "ACAPONETA",
      name: "18001"
  },
  {
      value: "AHUACATLAN",
      name: "18002"
  },
  {
      value: "AMATLAN DE CAÑAS",
      name: "18003"
  },
  {
      value: "COMPOSTELA",
      name: "18004"
  },
  {
      value: "HUAJICORI",
      name: "18005"
  },
  {
      value: "IXTLAN DEL RIO",
      name: "18006"
  },
  {
      value: "JALA",
      name: "18007"
  },
  {
      value: "XALISCO",
      name: "18008"
  },
  {
      value: "DEL NAYAR",
      name: "18009"
  },
  {
      value: "ROSAMORADA",
      name: "18010"
  },
  {
      value: "RUIZ",
      name: "18011"
  },
  {
      value: "SAN BLAS",
      name: "18012"
  },
  {
      value: "SAN PEDRO LAGUNILLAS",
      name: "18013"
  },
  {
      value: "SANTA MARIA DEL ORO",
      name: "18014"
  },
  {
      value: "SANTIAGO IXCUINTLA",
      name: "18015"
  },
  {
      value: "TECUALA",
      name: "18016"
  },
  {
      value: "TEPIC",
      name: "18017"
  },
  {
      value: "TUXPAN",
      name: "18018"
  },
  {
      value: "LA YESCA",
      name: "18019"
  },
  {
      value: "BAHIA DE BANDERAS",
      name: "18020"
  },
  {
      value: "ABASOLO",
      name: "19001"
  },
  {
      value: "AGUALEGUAS",
      name: "19002"
  },
  {
      value: "LOS ALDAMAS",
      name: "19003"
  },
  {
      value: "ALLENDE",
      name: "19004"
  },
  {
      value: "ANAHUAC",
      name: "19005"
  },
  {
      value: "APODACA",
      name: "19006"
  },
  {
      value: "ARAMBERRI",
      name: "19007"
  },
  {
      value: "BUSTAMANTE",
      name: "19008"
  },
  {
      value: "CADEREYTA JIMENEZ",
      name: "19009"
  },
  {
      value: "EL CARMEN",
      name: "19010"
  },
  {
      value: "CERRALVO",
      name: "19011"
  },
  {
      value: "CIENEGA DE FLORES",
      name: "19012"
  },
  {
      value: "CHINA",
      name: "19013"
  },
  {
      value: "DOCTOR ARROYO",
      name: "19014"
  },
  {
      value: "DOCTOR COSS",
      name: "19015"
  },
  {
      value: "DOCTOR GONZALEZ",
      name: "19016"
  },
  {
      value: "GALEANA",
      name: "19017"
  },
  {
      value: "GARCIA",
      name: "19018"
  },
  {
      value: "SAN PEDRO GARZA GARCIA",
      name: "19019"
  },
  {
      value: "GENERAL BRAVO",
      name: "19020"
  },
  {
      value: "GENERAL ESCOBEDO",
      name: "19021"
  },
  {
      value: "GENERAL TERAN",
      name: "19022"
  },
  {
      value: "GENERAL TREVIÑO",
      name: "19023"
  },
  {
      value: "GENERAL ZARAGOZA",
      name: "19024"
  },
  {
      value: "GENERAL ZUAZUA",
      name: "19025"
  },
  {
      value: "GUADALUPE",
      name: "19026"
  },
  {
      value: "LOS HERRERAS",
      name: "19027"
  },
  {
      value: "HIGUERAS",
      name: "19028"
  },
  {
      value: "HUALAHUISES",
      name: "19029"
  },
  {
      value: "ITURBIDE",
      name: "19030"
  },
  {
      value: "JUAREZ",
      name: "19031"
  },
  {
      value: "LAMPAZOS DE NARANJO",
      name: "19032"
  },
  {
      value: "LINARES",
      name: "19033"
  },
  {
      value: "MARIN",
      name: "19034"
  },
  {
      value: "MELCHOR OCAMPO",
      name: "19035"
  },
  {
      value: "MIER Y NORIEGA",
      name: "19036"
  },
  {
      value: "MINA",
      name: "19037"
  },
  {
      value: "MONTEMORELOS",
      name: "19038"
  },
  {
      value: "MONTERREY",
      name: "19039"
  },
  {
      value: "PARAS",
      name: "19040"
  },
  {
      value: "PESQUERIA",
      name: "19041"
  },
  {
      value: "LOS RAMONES",
      name: "19042"
  },
  {
      value: "RAYONES",
      name: "19043"
  },
  {
      value: "SABINAS HIDALGO",
      name: "19044"
  },
  {
      value: "SALINAS VICTORIA",
      name: "19045"
  },
  {
      value: "SAN NICOLAS DE LOS GARZA",
      name: "19046"
  },
  {
      value: "HIDALGO",
      name: "19047"
  },
  {
      value: "SANTA CATARINA",
      name: "19048"
  },
  {
      value: "SANTIAGO",
      name: "19049"
  },
  {
      value: "VALLECILLO",
      name: "19050"
  },
  {
      value: "VILLALDAMA",
      name: "19051"
  },
  {
      value: "ABEJONES",
      name: "20001"
  },
  {
      value: "ACATLAN DE PEREZ FIGUEROA",
      name: "20002"
  },
  {
      value: "ASUNCION CACALOTEPEC",
      name: "20003"
  },
  {
      value: "ASUNCION CUYOTEPEJI",
      name: "20004"
  },
  {
      value: "ASUNCION IXTALTEPEC",
      name: "20005"
  },
  {
      value: "ASUNCION NOCHIXTLAN",
      name: "20006"
  },
  {
      value: "ASUNCION OCOTLAN",
      name: "20007"
  },
  {
      value: "ASUNCION TLACOLULITA",
      name: "20008"
  },
  {
      value: "AYOTZINTEPEC",
      name: "20009"
  },
  {
      value: "EL BARRIO DE LA SOLEDAD",
      name: "20010"
  },
  {
      value: "CALIHUALA",
      name: "20011"
  },
  {
      value: "CANDELARIA LOXICHA",
      name: "20012"
  },
  {
      value: "CIENEGA DE ZIMATLAN",
      name: "20013"
  },
  {
      value: "CIUDAD IXTEPEC",
      name: "20014"
  },
  {
      value: "COATECAS ALTAS",
      name: "20015"
  },
  {
      value: "COICOYAN DE LAS FLORES",
      name: "20016"
  },
  {
      value: "LA COMPAÑIA",
      name: "20017"
  },
  {
      value: "CONCEPCION BUENAVISTA",
      name: "20018"
  },
  {
      value: "CONCEPCION PAPALO",
      name: "20019"
  },
  {
      value: "CONSTANCIA DEL ROSARIO",
      name: "20020"
  },
  {
      value: "COSOLAPA",
      name: "20021"
  },
  {
      value: "COSOLTEPEC",
      name: "20022"
  },
  {
      value: "CUILAPAM DE GUERRERO",
      name: "20023"
  },
  {
      value: "CUYAMECALCO VILLA DE ZARAGOZA",
      name: "20024"
  },
  {
      value: "CHAHUITES",
      name: "20025"
  },
  {
      value: "CHALCATONGO DE HIDALGO",
      name: "20026"
  },
  {
      value: "CHIQUIHUITLAN DE BENITO JUAREZ",
      name: "20027"
  },
  {
      value: "HEROICA CIUDAD DE EJUTLA DE CRESPO",
      name: "20028"
  },
  {
      value: "ELOXOCHITLAN DE FLORES MAGON",
      name: "20029"
  },
  {
      value: "EL ESPINAL",
      name: "20030"
  },
  {
      value: "TAMAZULAPAM DEL ESPIRITU SANTO",
      name: "20031"
  },
  {
      value: "FRESNILLO DE TRUJANO",
      name: "20032"
  },
  {
      value: "GUADALUPE ETLA",
      name: "20033"
  },
  {
      value: "GUADALUPE DE RAMIREZ",
      name: "20034"
  },
  {
      value: "GUELATAO DE JUAREZ",
      name: "20035"
  },
  {
      value: "GUEVEA DE HUMBOLDT",
      name: "20036"
  },
  {
      value: "MESONES HIDALGO",
      name: "20037"
  },
  {
      value: "VILLA HIDALGO",
      name: "20038"
  },
  {
      value: "HEROICA CIUDAD DE HUAJUAPAN DE LEON",
      name: "20039"
  },
  {
      value: "HUAUTEPEC",
      name: "20040"
  },
  {
      value: "HUAUTLA DE JIMENEZ",
      name: "20041"
  },
  {
      value: "IXTLAN DE JUAREZ",
      name: "20042"
  },
  {
      value: "HEROICA CIUDAD DE JUCHITAN DE ZARAGOZA",
      name: "20043"
  },
  {
      value: "LOMA BONITA",
      name: "20044"
  },
  {
      value: "MAGDALENA APASCO",
      name: "20045"
  },
  {
      value: "MAGDALENA JALTEPEC",
      name: "20046"
  },
  {
      value: "SANTA MAGDALENA JICOTLAN",
      name: "20047"
  },
  {
      value: "MAGDALENA MIXTEPEC",
      name: "20048"
  },
  {
      value: "MAGDALENA OCOTLAN",
      name: "20049"
  },
  {
      value: "MAGDALENA PEÑASCO",
      name: "20050"
  },
  {
      value: "MAGDALENA TEITIPAC",
      name: "20051"
  },
  {
      value: "MAGDALENA TEQUISISTLAN",
      name: "20052"
  },
  {
      value: "MAGDALENA TLACOTEPEC",
      name: "20053"
  },
  {
      value: "MAGDALENA ZAHUATLAN",
      name: "20054"
  },
  {
      value: "MARISCALA DE JUAREZ",
      name: "20055"
  },
  {
      value: "MARTIRES DE TACUBAYA",
      name: "20056"
  },
  {
      value: "MATIAS ROMERO AVENDAÑO",
      name: "20057"
  },
  {
      value: "MAZATLAN VILLA DE FLORES",
      name: "20058"
  },
  {
      value: "MIAHUATLAN DE PORFIRIO DIAZ",
      name: "20059"
  },
  {
      value: "MIXISTLAN DE LA REFORMA",
      name: "20060"
  },
  {
      value: "MONJAS",
      name: "20061"
  },
  {
      value: "NATIVIDAD",
      name: "20062"
  },
  {
      value: "NAZARENO ETLA",
      name: "20063"
  },
  {
      value: "NEJAPA DE MADERO",
      name: "20064"
  },
  {
      value: "IXPANTEPEC NIEVES",
      name: "20065"
  },
  {
      value: "SANTIAGO NILTEPEC",
      name: "20066"
  },
  {
      value: "OAXACA DE JUAREZ",
      name: "20067"
  },
  {
      value: "OCOTLAN DE MORELOS",
      name: "20068"
  },
  {
      value: "LA PE",
      name: "20069"
  },
  {
      value: "PINOTEPA DE DON LUIS",
      name: "20070"
  },
  {
      value: "PLUMA HIDALGO",
      name: "20071"
  },
  {
      value: "SAN JOSE DEL PROGRESO",
      name: "20072"
  },
  {
      value: "PUTLA VILLA DE GUERRERO",
      name: "20073"
  },
  {
      value: "SANTA CATARINA QUIOQUITANI",
      name: "20074"
  },
  {
      value: "REFORMA DE PINEDA",
      name: "20075"
  },
  {
      value: "LA REFORMA",
      name: "20076"
  },
  {
      value: "REYES ETLA",
      name: "20077"
  },
  {
      value: "ROJAS DE CUAUHTEMOC",
      name: "20078"
  },
  {
      value: "SALINA CRUZ",
      name: "20079"
  },
  {
      value: "SAN AGUSTIN AMATENGO",
      name: "20080"
  },
  {
      value: "SAN AGUSTIN ATENANGO",
      name: "20081"
  },
  {
      value: "SAN AGUSTIN CHAYUCO",
      name: "20082"
  },
  {
      value: "SAN AGUSTIN DE LAS JUNTAS",
      name: "20083"
  },
  {
      value: "SAN AGUSTIN ETLA",
      name: "20084"
  },
  {
      value: "SAN AGUSTIN LOXICHA",
      name: "20085"
  },
  {
      value: "SAN AGUSTIN TLACOTEPEC",
      name: "20086"
  },
  {
      value: "SAN AGUSTIN YATARENI",
      name: "20087"
  },
  {
      value: "SAN ANDRES CABECERA NUEVA",
      name: "20088"
  },
  {
      value: "SAN ANDRES DINICUITI",
      name: "20089"
  },
  {
      value: "SAN ANDRES HUAXPALTEPEC",
      name: "20090"
  },
  {
      value: "SAN ANDRES HUAYAPAM",
      name: "20091"
  },
  {
      value: "SAN ANDRES IXTLAHUACA",
      name: "20092"
  },
  {
      value: "SAN ANDRES LAGUNAS",
      name: "20093"
  },
  {
      value: "SAN ANDRES NUXIÑO",
      name: "20094"
  },
  {
      value: "SAN ANDRES PAXTLAN",
      name: "20095"
  },
  {
      value: "SAN ANDRES SINAXTLA",
      name: "20096"
  },
  {
      value: "SAN ANDRES SOLAGA",
      name: "20097"
  },
  {
      value: "SAN ANDRES TEOTILALPAM",
      name: "20098"
  },
  {
      value: "SAN ANDRES TEPETLAPA",
      name: "20099"
  },
  {
      value: "SAN ANDRES YAA",
      name: "20100"
  },
  {
      value: "SAN ANDRES ZABACHE",
      name: "20101"
  },
  {
      value: "SAN ANDRES ZAUTLA",
      name: "20102"
  },
  {
      value: "SAN ANTONINO CASTILLO VELASCO",
      name: "20103"
  },
  {
      value: "SAN ANTONINO EL ALTO",
      name: "20104"
  },
  {
      value: "SAN ANTONINO MONTE VERDE",
      name: "20105"
  },
  {
      value: "SAN ANTONIO ACUTLA",
      name: "20106"
  },
  {
      value: "SAN ANTONIO DE LA CAL",
      name: "20107"
  },
  {
      value: "SAN ANTONIO HUITEPEC",
      name: "20108"
  },
  {
      value: "SAN ANTONIO NANAHUATIPAM",
      name: "20109"
  },
  {
      value: "SAN ANTONIO SINICAHUA",
      name: "20110"
  },
  {
      value: "SAN ANTONIO TEPETLAPA",
      name: "20111"
  },
  {
      value: "SAN BALTAZAR CHICHICAPAM",
      name: "20112"
  },
  {
      value: "SAN BALTAZAR LOXICHA",
      name: "20113"
  },
  {
      value: "SAN BALTAZAR YATZACHI EL BAJO",
      name: "20114"
  },
  {
      value: "SAN BARTOLO COYOTEPEC",
      name: "20115"
  },
  {
      value: "SAN BARTOLOME AYAUTLA",
      name: "20116"
  },
  {
      value: "SAN BARTOLOME LOXICHA",
      name: "20117"
  },
  {
      value: "SAN BARTOLOME QUIALANA",
      name: "20118"
  },
  {
      value: "SAN BARTOLOME YUCUAÑE",
      name: "20119"
  },
  {
      value: "SAN BARTOLOME ZOOGOCHO",
      name: "20120"
  },
  {
      value: "SAN BARTOLO SOYALTEPEC",
      name: "20121"
  },
  {
      value: "SAN BARTOLO YAUTEPEC",
      name: "20122"
  },
  {
      value: "SAN BERNARDO MIXTEPEC",
      name: "20123"
  },
  {
      value: "SAN BLAS ATEMPA",
      name: "20124"
  },
  {
      value: "SAN CARLOS YAUTEPEC",
      name: "20125"
  },
  {
      value: "SAN CRISTOBAL AMATLAN",
      name: "20126"
  },
  {
      value: "SAN CRISTOBAL AMOLTEPEC",
      name: "20127"
  },
  {
      value: "SAN CRISTOBAL LACHIRIOAG",
      name: "20128"
  },
  {
      value: "SAN CRISTOBAL SUCHIXTLAHUACA",
      name: "20129"
  },
  {
      value: "SAN DIONISIO DEL MAR",
      name: "20130"
  },
  {
      value: "SAN DIONISIO OCOTEPEC",
      name: "20131"
  },
  {
      value: "SAN DIONISIO OCOTLAN",
      name: "20132"
  },
  {
      value: "SAN ESTEBAN ATATLAHUCA",
      name: "20133"
  },
  {
      value: "SAN FELIPE JALAPA DE DIAZ",
      name: "20134"
  },
  {
      value: "SAN FELIPE TEJALAPAM",
      name: "20135"
  },
  {
      value: "SAN FELIPE USILA",
      name: "20136"
  },
  {
      value: "SAN FRANCISCO CAHUACUA",
      name: "20137"
  },
  {
      value: "SAN FRANCISCO CAJONOS",
      name: "20138"
  },
  {
      value: "SAN FRANCISCO CHAPULAPA",
      name: "20139"
  },
  {
      value: "SAN FRANCISCO CHINDUA",
      name: "20140"
  },
  {
      value: "SAN FRANCISCO DEL MAR",
      name: "20141"
  },
  {
      value: "SAN FRANCISCO HUEHUETLAN",
      name: "20142"
  },
  {
      value: "SAN FRANCISCO IXHUATAN",
      name: "20143"
  },
  {
      value: "SAN FRANCISCO JALTEPETONGO",
      name: "20144"
  },
  {
      value: "SAN FRANCISCO LACHIGOLO",
      name: "20145"
  },
  {
      value: "SAN FRANCISCO LOGUECHE",
      name: "20146"
  },
  {
      value: "SAN FRANCISCO NUXAÑO",
      name: "20147"
  },
  {
      value: "SAN FRANCISCO OZOLOTEPEC",
      name: "20148"
  },
  {
      value: "SAN FRANCISCO SOLA",
      name: "20149"
  },
  {
      value: "SAN FRANCISCO TELIXTLAHUACA",
      name: "20150"
  },
  {
      value: "SAN FRANCISCO TEOPAN",
      name: "20151"
  },
  {
      value: "SAN FRANCISCO TLAPANCINGO",
      name: "20152"
  },
  {
      value: "SAN GABRIEL MIXTEPEC",
      name: "20153"
  },
  {
      value: "SAN ILDEFONSO AMATLAN",
      name: "20154"
  },
  {
      value: "SAN ILDEFONSO SOLA",
      name: "20155"
  },
  {
      value: "SAN ILDEFONSO VILLA ALTA",
      name: "20156"
  },
  {
      value: "SAN JACINTO AMILPAS",
      name: "20157"
  },
  {
      value: "SAN JACINTO TLACOTEPEC",
      name: "20158"
  },
  {
      value: "SAN JERONIMO COATLAN",
      name: "20159"
  },
  {
      value: "SAN JERONIMO SILACAYOAPILLA",
      name: "20160"
  },
  {
      value: "SAN JERONIMO SOSOLA",
      name: "20161"
  },
  {
      value: "SAN JERONIMO TAVICHE",
      name: "20162"
  },
  {
      value: "SAN JERONIMO TECOATL",
      name: "20163"
  },
  {
      value: "SAN JORGE NUCHITA",
      name: "20164"
  },
  {
      value: "SAN JOSE AYUQUILA",
      name: "20165"
  },
  {
      value: "SAN JOSE CHILTEPEC",
      name: "20166"
  },
  {
      value: "SAN JOSE DEL PEÑASCO",
      name: "20167"
  },
  {
      value: "SAN JOSE ESTANCIA GRANDE",
      name: "20168"
  },
  {
      value: "SAN JOSE INDEPENDENCIA",
      name: "20169"
  },
  {
      value: "SAN JOSE LACHIGUIRI",
      name: "20170"
  },
  {
      value: "SAN JOSE TENANGO",
      name: "20171"
  },
  {
      value: "SAN JUAN ACHIUTLA",
      name: "20172"
  },
  {
      value: "SAN JUAN ATEPEC",
      name: "20173"
  },
  {
      value: "ANIMAS TRUJANO",
      name: "20174"
  },
  {
      value: "SAN JUAN BAUTISTA ATATLAHUCA",
      name: "20175"
  },
  {
      value: "SAN JUAN BAUTISTA COIXTLAHUACA",
      name: "20176"
  },
  {
      value: "SAN JUAN BAUTISTA CUICATLAN",
      name: "20177"
  },
  {
      value: "SAN JUAN BAUTISTA GUELACHE",
      name: "20178"
  },
  {
      value: "SAN JUAN BAUTISTA JAYACATLAN",
      name: "20179"
  },
  {
      value: "SAN JUAN BAUTISTA LO DE SOTO",
      name: "20180"
  },
  {
      value: "SAN JUAN BAUTISTA SUCHITEPEC",
      name: "20181"
  },
  {
      value: "SAN JUAN BAUTISTA TLACOATZINTEPEC",
      name: "20182"
  },
  {
      value: "SAN JUAN BAUTISTA TLACHICHILCO",
      name: "20183"
  },
  {
      value: "SAN JUAN BAUTISTA TUXTEPEC",
      name: "20184"
  },
  {
      value: "SAN JUAN CACAHUATEPEC",
      name: "20185"
  },
  {
      value: "SAN JUAN CIENEGUILLA",
      name: "20186"
  },
  {
      value: "SAN JUAN COATZOSPAM",
      name: "20187"
  },
  {
      value: "SAN JUAN COLORADO",
      name: "20188"
  },
  {
      value: "SAN JUAN COMALTEPEC",
      name: "20189"
  },
  {
      value: "SAN JUAN COTZOCON",
      name: "20190"
  },
  {
      value: "SAN JUAN CHICOMEZUCHIL",
      name: "20191"
  },
  {
      value: "SAN JUAN CHILATECA",
      name: "20192"
  },
  {
      value: "SAN JUAN DEL ESTADO",
      name: "20193"
  },
  {
      value: "SAN JUAN DEL RIO",
      name: "20194"
  },
  {
      value: "SAN JUAN DIUXI",
      name: "20195"
  },
  {
      value: "SAN JUAN EVANGELISTA ANALCO",
      name: "20196"
  },
  {
      value: "SAN JUAN GUELAVIA",
      name: "20197"
  },
  {
      value: "SAN JUAN GUICHICOVI",
      name: "20198"
  },
  {
      value: "SAN JUAN IHUALTEPEC",
      name: "20199"
  },
  {
      value: "SAN JUAN JUQUILA MIXES",
      name: "20200"
  },
  {
      value: "SAN JUAN JUQUILA VIJANOS",
      name: "20201"
  },
  {
      value: "SAN JUAN LACHAO",
      name: "20202"
  },
  {
      value: "SAN JUAN LACHIGALLA",
      name: "20203"
  },
  {
      value: "SAN JUAN LAJARCIA",
      name: "20204"
  },
  {
      value: "SAN JUAN LALANA",
      name: "20205"
  },
  {
      value: "SAN JUAN DE LOS CUES",
      name: "20206"
  },
  {
      value: "SAN JUAN MAZATLAN",
      name: "20207"
  },
  {
      value: "SAN JUAN MIXTEPEC -DTO. 08 -",
      name: "20208"
  },
  {
      value: "SAN JUAN MIXTEPEC -DTO. 26 -",
      name: "20209"
  },
  {
      value: "SAN JUAN ÑUMI",
      name: "20210"
  },
  {
      value: "SAN JUAN OZOLOTEPEC",
      name: "20211"
  },
  {
      value: "SAN JUAN PETLAPA",
      name: "20212"
  },
  {
      value: "SAN JUAN QUIAHIJE",
      name: "20213"
  },
  {
      value: "SAN JUAN QUIOTEPEC",
      name: "20214"
  },
  {
      value: "SAN JUAN SAYULTEPEC",
      name: "20215"
  },
  {
      value: "SAN JUAN TABAA",
      name: "20216"
  },
  {
      value: "SAN JUAN TAMAZOLA",
      name: "20217"
  },
  {
      value: "SAN JUAN TEITA",
      name: "20218"
  },
  {
      value: "SAN JUAN TEITIPAC",
      name: "20219"
  },
  {
      value: "SAN JUAN TEPEUXILA",
      name: "20220"
  },
  {
      value: "SAN JUAN TEPOSCOLULA",
      name: "20221"
  },
  {
      value: "SAN JUAN YAEE",
      name: "20222"
  },
  {
      value: "SAN JUAN YATZONA",
      name: "20223"
  },
  {
      value: "SAN JUAN YUCUITA",
      name: "20224"
  },
  {
      value: "SAN LORENZO",
      name: "20225"
  },
  {
      value: "SAN LORENZO ALBARRADAS",
      name: "20226"
  },
  {
      value: "SAN LORENZO CACAOTEPEC",
      name: "20227"
  },
  {
      value: "SAN LORENZO CUAUNECUILTITLA",
      name: "20228"
  },
  {
      value: "SAN LORENZO TEXMELUCAN",
      name: "20229"
  },
  {
      value: "SAN LORENZO VICTORIA",
      name: "20230"
  },
  {
      value: "SAN LUCAS CAMOTLAN",
      name: "20231"
  },
  {
      value: "SAN LUCAS OJITLAN",
      name: "20232"
  },
  {
      value: "SAN LUCAS QUIAVINI",
      name: "20233"
  },
  {
      value: "SAN LUCAS ZOQUIAPAM",
      name: "20234"
  },
  {
      value: "SAN LUIS AMATLAN",
      name: "20235"
  },
  {
      value: "SAN MARCIAL OZOLOTEPEC",
      name: "20236"
  },
  {
      value: "SAN MARCOS ARTEAGA",
      name: "20237"
  },
  {
      value: "SAN MARTIN DE LOS CANSECOS",
      name: "20238"
  },
  {
      value: "SAN MARTIN HUAMELULPAM",
      name: "20239"
  },
  {
      value: "SAN MARTIN ITUNYOSO",
      name: "20240"
  },
  {
      value: "SAN MARTIN LACHILA",
      name: "20241"
  },
  {
      value: "SAN MARTIN PERAS",
      name: "20242"
  },
  {
      value: "SAN MARTIN TILCAJETE",
      name: "20243"
  },
  {
      value: "SAN MARTIN TOXPALAN",
      name: "20244"
  },
  {
      value: "SAN MARTIN ZACATEPEC",
      name: "20245"
  },
  {
      value: "SAN MATEO CAJONOS",
      name: "20246"
  },
  {
      value: "CAPULALPAM DE MENDEZ",
      name: "20247"
  },
  {
      value: "SAN MATEO DEL MAR",
      name: "20248"
  },
  {
      value: "SAN MATEO YOLOXOCHITLAN",
      name: "20249"
  },
  {
      value: "SAN MATEO ETLATONGO",
      name: "20250"
  },
  {
      value: "SAN MATEO NEJAPAM",
      name: "20251"
  },
  {
      value: "SAN MATEO PEÑASCO",
      name: "20252"
  },
  {
      value: "SAN MATEO PIÑAS",
      name: "20253"
  },
  {
      value: "SAN MATEO RIO HONDO",
      name: "20254"
  },
  {
      value: "SAN MATEO SINDIHUI",
      name: "20255"
  },
  {
      value: "SAN MATEO TLAPILTEPEC",
      name: "20256"
  },
  {
      value: "SAN MELCHOR BETAZA",
      name: "20257"
  },
  {
      value: "SAN MIGUEL ACHIUTLA",
      name: "20258"
  },
  {
      value: "SAN MIGUEL AHUEHUETITLAN",
      name: "20259"
  },
  {
      value: "SAN MIGUEL ALOAPAM",
      name: "20260"
  },
  {
      value: "SAN MIGUEL AMATITLAN",
      name: "20261"
  },
  {
      value: "SAN MIGUEL AMATLAN",
      name: "20262"
  },
  {
      value: "SAN MIGUEL COATLAN",
      name: "20263"
  },
  {
      value: "SAN MIGUEL CHICAHUA",
      name: "20264"
  },
  {
      value: "SAN MIGUEL CHIMALAPA",
      name: "20265"
  },
  {
      value: "SAN MIGUEL DEL PUERTO",
      name: "20266"
  },
  {
      value: "SAN MIGUEL DEL RIO",
      name: "20267"
  },
  {
      value: "SAN MIGUEL EJUTLA",
      name: "20268"
  },
  {
      value: "SAN MIGUEL EL GRANDE",
      name: "20269"
  },
  {
      value: "SAN MIGUEL HUAUTLA",
      name: "20270"
  },
  {
      value: "SAN MIGUEL MIXTEPEC",
      name: "20271"
  },
  {
      value: "SAN MIGUEL PANIXTLAHUACA",
      name: "20272"
  },
  {
      value: "SAN MIGUEL PERAS",
      name: "20273"
  },
  {
      value: "SAN MIGUEL PIEDRAS",
      name: "20274"
  },
  {
      value: "SAN MIGUEL QUETZALTEPEC",
      name: "20275"
  },
  {
      value: "SAN MIGUEL SANTA FLOR",
      name: "20276"
  },
  {
      value: "VILLA SOLA DE VEGA",
      name: "20277"
  },
  {
      value: "SAN MIGUEL SOYALTEPEC",
      name: "20278"
  },
  {
      value: "SAN MIGUEL SUCHIXTEPEC",
      name: "20279"
  },
  {
      value: "VILLA TALEA DE CASTRO",
      name: "20280"
  },
  {
      value: "SAN MIGUEL TECOMATLAN",
      name: "20281"
  },
  {
      value: "SAN MIGUEL TENANGO",
      name: "20282"
  },
  {
      value: "SAN MIGUEL TEQUIXTEPEC",
      name: "20283"
  },
  {
      value: "SAN MIGUEL TILQUIAPAM",
      name: "20284"
  },
  {
      value: "SAN MIGUEL TLACAMAMA",
      name: "20285"
  },
  {
      value: "SAN MIGUEL TLACOTEPEC",
      name: "20286"
  },
  {
      value: "SAN MIGUEL TULANCINGO",
      name: "20287"
  },
  {
      value: "SAN MIGUEL YOTAO",
      name: "20288"
  },
  {
      value: "SAN NICOLAS",
      name: "20289"
  },
  {
      value: "SAN NICOLAS HIDALGO",
      name: "20290"
  },
  {
      value: "SAN PABLO COATLAN",
      name: "20291"
  },
  {
      value: "SAN PABLO CUATRO VENADOS",
      name: "20292"
  },
  {
      value: "SAN PABLO ETLA",
      name: "20293"
  },
  {
      value: "SAN PABLO HUITZO",
      name: "20294"
  },
  {
      value: "SAN PABLO HUIXTEPEC",
      name: "20295"
  },
  {
      value: "SAN PABLO MACUILTIANGUIS",
      name: "20296"
  },
  {
      value: "SAN PABLO TIJALTEPEC",
      name: "20297"
  },
  {
      value: "SAN PABLO VILLA DE MITLA",
      name: "20298"
  },
  {
      value: "SAN PABLO YAGANIZA",
      name: "20299"
  },
  {
      value: "SAN PEDRO AMUZGOS",
      name: "20300"
  },
  {
      value: "SAN PEDRO APOSTOL",
      name: "20301"
  },
  {
      value: "SAN PEDRO ATOYAC",
      name: "20302"
  },
  {
      value: "SAN PEDRO CAJONOS",
      name: "20303"
  },
  {
      value: "SAN PEDRO COXCALTEPEC CANTAROS",
      name: "20304"
  },
  {
      value: "SAN PEDRO COMITANCILLO",
      name: "20305"
  },
  {
      value: "SAN PEDRO EL ALTO",
      name: "20306"
  },
  {
      value: "SAN PEDRO HUAMELULA",
      name: "20307"
  },
  {
      value: "SAN PEDRO HUILOTEPEC",
      name: "20308"
  },
  {
      value: "SAN PEDRO IXCATLAN",
      name: "20309"
  },
  {
      value: "SAN PEDRO IXTLAHUACA",
      name: "20310"
  },
  {
      value: "SAN PEDRO JALTEPETONGO",
      name: "20311"
  },
  {
      value: "SAN PEDRO JICAYAN",
      name: "20312"
  },
  {
      value: "SAN PEDRO JOCOTIPAC",
      name: "20313"
  },
  {
      value: "SAN PEDRO JUCHATENGO",
      name: "20314"
  },
  {
      value: "SAN PEDRO MARTIR",
      name: "20315"
  },
  {
      value: "SAN PEDRO MARTIR QUIECHAPA",
      name: "20316"
  },
  {
      value: "SAN PEDRO MARTIR YUCUXACO",
      name: "20317"
  },
  {
      value: "SAN PEDRO MIXTEPEC -DTO. 22 -",
      name: "20318"
  },
  {
      value: "SAN PEDRO MIXTEPEC -DTO. 26 -",
      name: "20319"
  },
  {
      value: "SAN PEDRO MOLINOS",
      name: "20320"
  },
  {
      value: "SAN PEDRO NOPALA",
      name: "20321"
  },
  {
      value: "SAN PEDRO OCOPETATILLO",
      name: "20322"
  },
  {
      value: "SAN PEDRO OCOTEPEC",
      name: "20323"
  },
  {
      value: "SAN PEDRO POCHUTLA",
      name: "20324"
  },
  {
      value: "SAN PEDRO QUIATONI",
      name: "20325"
  },
  {
      value: "SAN PEDRO SOCHIAPAM",
      name: "20326"
  },
  {
      value: "SAN PEDRO TAPANATEPEC",
      name: "20327"
  },
  {
      value: "SAN PEDRO TAVICHE",
      name: "20328"
  },
  {
      value: "SAN PEDRO TEOZACOALCO",
      name: "20329"
  },
  {
      value: "SAN PEDRO TEUTILA",
      name: "20330"
  },
  {
      value: "SAN PEDRO TIDAA",
      name: "20331"
  },
  {
      value: "SAN PEDRO TOPILTEPEC",
      name: "20332"
  },
  {
      value: "SAN PEDRO TOTOLAPAM",
      name: "20333"
  },
  {
      value: "VILLA DE TUTUTEPEC",
      name: "20334"
  },
  {
      value: "SAN PEDRO YANERI",
      name: "20335"
  },
  {
      value: "SAN PEDRO YOLOX",
      name: "20336"
  },
  {
      value: "SAN PEDRO Y SAN PABLO AYUTLA",
      name: "20337"
  },
  {
      value: "VILLA DE ETLA",
      name: "20338"
  },
  {
      value: "SAN PEDRO Y SAN PABLO TEPOSCOLULA",
      name: "20339"
  },
  {
      value: "SAN PEDRO Y SAN PABLO TEQUIXTEPEC",
      name: "20340"
  },
  {
      value: "SAN PEDRO YUCUNAMA",
      name: "20341"
  },
  {
      value: "SAN RAYMUNDO JALPAN",
      name: "20342"
  },
  {
      value: "SAN SEBASTIAN ABASOLO",
      name: "20343"
  },
  {
      value: "SAN SEBASTIAN COATLAN",
      name: "20344"
  },
  {
      value: "SAN SEBASTIAN IXCAPA",
      name: "20345"
  },
  {
      value: "SAN SEBASTIAN NICANANDUTA",
      name: "20346"
  },
  {
      value: "SAN SEBASTIAN RIO HONDO",
      name: "20347"
  },
  {
      value: "SAN SEBASTIAN TECOMAXTLAHUACA",
      name: "20348"
  },
  {
      value: "SAN SEBASTIAN TEITIPAC",
      name: "20349"
  },
  {
      value: "SAN SEBASTIAN TUTLA",
      name: "20350"
  },
  {
      value: "SAN SIMON ALMOLONGAS",
      name: "20351"
  },
  {
      value: "SAN SIMON ZAHUATLAN",
      name: "20352"
  },
  {
      value: "SANTA ANA",
      name: "20353"
  },
  {
      value: "SANTA ANA ATEIXTLAHUACA",
      name: "20354"
  },
  {
      value: "SANTA ANA CUAUHTEMOC",
      name: "20355"
  },
  {
      value: "SANTA ANA DEL VALLE",
      name: "20356"
  },
  {
      value: "SANTA ANA TAVELA",
      name: "20357"
  },
  {
      value: "SANTA ANA TLAPACOYAN",
      name: "20358"
  },
  {
      value: "SANTA ANA YARENI",
      name: "20359"
  },
  {
      value: "SANTA ANA ZEGACHE",
      name: "20360"
  },
  {
      value: "SANTA CATALINA QUIERI",
      name: "20361"
  },
  {
      value: "SANTA CATARINA CUIXTLA",
      name: "20362"
  },
  {
      value: "SANTA CATARINA IXTEPEJI",
      name: "20363"
  },
  {
      value: "SANTA CATARINA JUQUILA",
      name: "20364"
  },
  {
      value: "SANTA CATARINA LACHATAO",
      name: "20365"
  },
  {
      value: "SANTA CATARINA LOXICHA",
      name: "20366"
  },
  {
      value: "SANTA CATARINA MECHOACAN",
      name: "20367"
  },
  {
      value: "SANTA CATARINA MINAS",
      name: "20368"
  },
  {
      value: "SANTA CATARINA QUIANE",
      name: "20369"
  },
  {
      value: "SANTA CATARINA TAYATA",
      name: "20370"
  },
  {
      value: "SANTA CATARINA TICUA",
      name: "20371"
  },
  {
      value: "SANTA CATARINA YOSONOTU",
      name: "20372"
  },
  {
      value: "SANTA CATARINA ZAPOQUILA",
      name: "20373"
  },
  {
      value: "SANTA CRUZ ACATEPEC",
      name: "20374"
  },
  {
      value: "SANTA CRUZ AMILPAS",
      name: "20375"
  },
  {
      value: "SANTA CRUZ DE BRAVO",
      name: "20376"
  },
  {
      value: "SANTA CRUZ ITUNDUJIA",
      name: "20377"
  },
  {
      value: "SANTA CRUZ MIXTEPEC",
      name: "20378"
  },
  {
      value: "SANTA CRUZ NUNDACO",
      name: "20379"
  },
  {
      value: "SANTA CRUZ PAPALUTLA",
      name: "20380"
  },
  {
      value: "SANTA CRUZ TACACHE DE MINA",
      name: "20381"
  },
  {
      value: "SANTA CRUZ TACAHUA",
      name: "20382"
  },
  {
      value: "SANTA CRUZ TAYATA",
      name: "20383"
  },
  {
      value: "SANTA CRUZ XITLA",
      name: "20384"
  },
  {
      value: "SANTA CRUZ XOXOCOTLAN",
      name: "20385"
  },
  {
      value: "SANTA CRUZ ZENZONTEPEC",
      name: "20386"
  },
  {
      value: "SANTA GERTRUDIS",
      name: "20387"
  },
  {
      value: "SANTA INES DEL MONTE",
      name: "20388"
  },
  {
      value: "SANTA INES YATZECHE",
      name: "20389"
  },
  {
      value: "SANTA LUCIA DEL CAMINO",
      name: "20390"
  },
  {
      value: "SANTA LUCIA MIAHUATLAN",
      name: "20391"
  },
  {
      value: "SANTA LUCIA MONTEVERDE",
      name: "20392"
  },
  {
      value: "SANTA LUCIA OCOTLAN",
      name: "20393"
  },
  {
      value: "SANTA MARIA ALOTEPEC",
      name: "20394"
  },
  {
      value: "SANTA MARIA APAZCO",
      name: "20395"
  },
  {
      value: "SANTA MARIA LA ASUNCION",
      name: "20396"
  },
  {
      value: "HEROICA CIUDAD DE TLAXIACO",
      name: "20397"
  },
  {
      value: "AYOQUEZCO DE ALDAMA",
      name: "20398"
  },
  {
      value: "SANTA MARIA ATZOMPA",
      name: "20399"
  },
  {
      value: "SANTA MARIA CAMOTLAN",
      name: "20400"
  },
  {
      value: "SANTA MARIA COLOTEPEC",
      name: "20401"
  },
  {
      value: "SANTA MARIA CORTIJO",
      name: "20402"
  },
  {
      value: "SANTA MARIA COYOTEPEC",
      name: "20403"
  },
  {
      value: "SANTA MARIA CHACHOAPAM",
      name: "20404"
  },
  {
      value: "VILLA DE CHILAPA DE DIAZ",
      name: "20405"
  },
  {
      value: "SANTA MARIA CHILCHOTLA",
      name: "20406"
  },
  {
      value: "SANTA MARIA CHIMALAPA",
      name: "20407"
  },
  {
      value: "SANTA MARIA DEL ROSARIO",
      name: "20408"
  },
  {
      value: "SANTA MARIA DEL TULE",
      name: "20409"
  },
  {
      value: "SANTA MARIA ECATEPEC",
      name: "20410"
  },
  {
      value: "SANTA MARIA GUELACE",
      name: "20411"
  },
  {
      value: "SANTA MARIA GUIENAGATI",
      name: "20412"
  },
  {
      value: "SANTA MARIA HUATULCO",
      name: "20413"
  },
  {
      value: "SANTA MARIA HUAZOLOTITLAN",
      name: "20414"
  },
  {
      value: "SANTA MARIA IPALAPA",
      name: "20415"
  },
  {
      value: "SANTA MARIA IXCATLAN",
      name: "20416"
  },
  {
      value: "SANTA MARIA JACATEPEC",
      name: "20417"
  },
  {
      value: "SANTA MARIA JALAPA DEL MARQUES",
      name: "20418"
  },
  {
      value: "SANTA MARIA JALTIANGUIS",
      name: "20419"
  },
  {
      value: "SANTA MARIA LACHIXIO",
      name: "20420"
  },
  {
      value: "SANTA MARIA MIXTEQUILLA",
      name: "20421"
  },
  {
      value: "SANTA MARIA NATIVITAS",
      name: "20422"
  },
  {
      value: "SANTA MARIA NDUAYACO",
      name: "20423"
  },
  {
      value: "SANTA MARIA OZOLOTEPEC",
      name: "20424"
  },
  {
      value: "SANTA MARIA PAPALO",
      name: "20425"
  },
  {
      value: "SANTA MARIA PEÑOLES",
      name: "20426"
  },
  {
      value: "SANTA MARIA PETAPA",
      name: "20427"
  },
  {
      value: "SANTA MARIA QUIEGOLANI",
      name: "20428"
  },
  {
      value: "SANTA MARIA SOLA",
      name: "20429"
  },
  {
      value: "SANTA MARIA TATALTEPEC",
      name: "20430"
  },
  {
      value: "SANTA MARIA TECOMAVACA",
      name: "20431"
  },
  {
      value: "SANTA MARIA TEMAXCALAPA",
      name: "20432"
  },
  {
      value: "SANTA MARIA TEMAXCALTEPEC",
      name: "20433"
  },
  {
      value: "SANTA MARIA TEOPOXCO",
      name: "20434"
  },
  {
      value: "SANTA MARIA TEPANTLALI",
      name: "20435"
  },
  {
      value: "SANTA MARIA TEXCATITLAN",
      name: "20436"
  },
  {
      value: "SANTA MARIA TLAHUITOLTEPEC",
      name: "20437"
  },
  {
      value: "SANTA MARIA TLALIXTAC",
      name: "20438"
  },
  {
      value: "SANTA MARIA TONAMECA",
      name: "20439"
  },
  {
      value: "SANTA MARIA TOTOLAPILLA",
      name: "20440"
  },
  {
      value: "SANTA MARIA XADANI",
      name: "20441"
  },
  {
      value: "SANTA MARIA YALINA",
      name: "20442"
  },
  {
      value: "SANTA MARIA YAVESIA",
      name: "20443"
  },
  {
      value: "SANTA MARIA YOLOTEPEC",
      name: "20444"
  },
  {
      value: "SANTA MARIA YOSOYUA",
      name: "20445"
  },
  {
      value: "SANTA MARIA YUCUHITI",
      name: "20446"
  },
  {
      value: "SANTA MARIA ZACATEPEC",
      name: "20447"
  },
  {
      value: "SANTA MARIA ZANIZA",
      name: "20448"
  },
  {
      value: "SANTA MARIA ZOQUITLAN",
      name: "20449"
  },
  {
      value: "SANTIAGO AMOLTEPEC",
      name: "20450"
  },
  {
      value: "SANTIAGO APOALA",
      name: "20451"
  },
  {
      value: "SANTIAGO APOSTOL",
      name: "20452"
  },
  {
      value: "SANTIAGO ASTATA",
      name: "20453"
  },
  {
      value: "SANTIAGO ATITLAN",
      name: "20454"
  },
  {
      value: "SANTIAGO AYUQUILILLA",
      name: "20455"
  },
  {
      value: "SANTIAGO CACALOXTEPEC",
      name: "20456"
  },
  {
      value: "SANTIAGO CAMOTLAN",
      name: "20457"
  },
  {
      value: "SANTIAGO COMALTEPEC",
      name: "20458"
  },
  {
      value: "SANTIAGO CHAZUMBA",
      name: "20459"
  },
  {
      value: "SANTIAGO CHOAPAM",
      name: "20460"
  },
  {
      value: "SANTIAGO DEL RIO",
      name: "20461"
  },
  {
      value: "SANTIAGO HUAJOLOTITLAN",
      name: "20462"
  },
  {
      value: "SANTIAGO HUAUCLILLA",
      name: "20463"
  },
  {
      value: "SANTIAGO IHUITLAN PLUMAS",
      name: "20464"
  },
  {
      value: "SANTIAGO IXCUINTEPEC",
      name: "20465"
  },
  {
      value: "SANTIAGO IXTAYUTLA",
      name: "20466"
  },
  {
      value: "SANTIAGO JAMILTEPEC",
      name: "20467"
  },
  {
      value: "SANTIAGO JOCOTEPEC",
      name: "20468"
  },
  {
      value: "SANTIAGO JUXTLAHUACA",
      name: "20469"
  },
  {
      value: "SANTIAGO LACHIGUIRI",
      name: "20470"
  },
  {
      value: "SANTIAGO LALOPA",
      name: "20471"
  },
  {
      value: "SANTIAGO LAOLLAGA",
      name: "20472"
  },
  {
      value: "SANTIAGO LAXOPA",
      name: "20473"
  },
  {
      value: "SANTIAGO LLANO GRANDE",
      name: "20474"
  },
  {
      value: "SANTIAGO MATATLAN",
      name: "20475"
  },
  {
      value: "SANTIAGO MILTEPEC",
      name: "20476"
  },
  {
      value: "SANTIAGO MINAS",
      name: "20477"
  },
  {
      value: "SANTIAGO NACALTEPEC",
      name: "20478"
  },
  {
      value: "SANTIAGO NEJAPILLA",
      name: "20479"
  },
  {
      value: "SANTIAGO NUNDICHE",
      name: "20480"
  },
  {
      value: "SANTIAGO NUYOO",
      name: "20481"
  },
  {
      value: "SANTIAGO PINOTEPA NACIONAL",
      name: "20482"
  },
  {
      value: "SANTIAGO SUCHILQUITONGO",
      name: "20483"
  },
  {
      value: "SANTIAGO TAMAZOLA",
      name: "20484"
  },
  {
      value: "SANTIAGO TAPEXTLA",
      name: "20485"
  },
  {
      value: "VILLA TEJUPAM DE LA UNION",
      name: "20486"
  },
  {
      value: "SANTIAGO TENANGO",
      name: "20487"
  },
  {
      value: "SANTIAGO TEPETLAPA",
      name: "20488"
  },
  {
      value: "SANTIAGO TETEPEC",
      name: "20489"
  },
  {
      value: "SANTIAGO TEXCALCINGO",
      name: "20490"
  },
  {
      value: "SANTIAGO TEXTITLAN",
      name: "20491"
  },
  {
      value: "SANTIAGO TILANTONGO",
      name: "20492"
  },
  {
      value: "SANTIAGO TILLO",
      name: "20493"
  },
  {
      value: "SANTIAGO TLAZOYALTEPEC",
      name: "20494"
  },
  {
      value: "SANTIAGO XANICA",
      name: "20495"
  },
  {
      value: "SANTIAGO XIACUI",
      name: "20496"
  },
  {
      value: "SANTIAGO YAITEPEC",
      name: "20497"
  },
  {
      value: "SANTIAGO YAVEO",
      name: "20498"
  },
  {
      value: "SANTIAGO YOLOMECATL",
      name: "20499"
  },
  {
      value: "SANTIAGO YOSONDUA",
      name: "20500"
  },
  {
      value: "SANTIAGO YUCUYACHI",
      name: "20501"
  },
  {
      value: "SANTIAGO ZACATEPEC",
      name: "20502"
  },
  {
      value: "SANTIAGO ZOOCHILA",
      name: "20503"
  },
  {
      value: "NUEVO ZOQUIAPAM",
      name: "20504"
  },
  {
      value: "SANTO DOMINGO INGENIO",
      name: "20505"
  },
  {
      value: "SANTO DOMINGO ALBARRADAS",
      name: "20506"
  },
  {
      value: "SANTO DOMINGO ARMENTA",
      name: "20507"
  },
  {
      value: "SANTO DOMINGO CHIHUITAN",
      name: "20508"
  },
  {
      value: "SANTO DOMINGO DE MORELOS",
      name: "20509"
  },
  {
      value: "SANTO DOMINGO IXCATLAN",
      name: "20510"
  },
  {
      value: "SANTO DOMINGO NUXAA",
      name: "20511"
  },
  {
      value: "SANTO DOMINGO OZOLOTEPEC",
      name: "20512"
  },
  {
      value: "SANTO DOMINGO PETAPA",
      name: "20513"
  },
  {
      value: "SANTO DOMINGO ROAYAGA",
      name: "20514"
  },
  {
      value: "SANTO DOMINGO TEHUANTEPEC",
      name: "20515"
  },
  {
      value: "SANTO DOMINGO TEOJOMULCO",
      name: "20516"
  },
  {
      value: "SANTO DOMINGO TEPUXTEPEC",
      name: "20517"
  },
  {
      value: "SANTO DOMINGO TLATAYAPAM",
      name: "20518"
  },
  {
      value: "SANTO DOMINGO TOMALTEPEC",
      name: "20519"
  },
  {
      value: "SANTO DOMINGO TONALA",
      name: "20520"
  },
  {
      value: "SANTO DOMINGO TONALTEPEC",
      name: "20521"
  },
  {
      value: "SANTO DOMINGO XAGACIA",
      name: "20522"
  },
  {
      value: "SANTO DOMINGO YANHUITLAN",
      name: "20523"
  },
  {
      value: "SANTO DOMINGO YODOHINO",
      name: "20524"
  },
  {
      value: "SANTO DOMINGO ZANATEPEC",
      name: "20525"
  },
  {
      value: "SANTOS REYES NOPALA",
      name: "20526"
  },
  {
      value: "SANTOS REYES PAPALO",
      name: "20527"
  },
  {
      value: "SANTOS REYES TEPEJILLO",
      name: "20528"
  },
  {
      value: "SANTOS REYES YUCUNA",
      name: "20529"
  },
  {
      value: "SANTO TOMAS JALIEZA",
      name: "20530"
  },
  {
      value: "SANTO TOMAS MAZALTEPEC",
      name: "20531"
  },
  {
      value: "SANTO TOMAS OCOTEPEC",
      name: "20532"
  },
  {
      value: "SANTO TOMAS TAMAZULAPAN",
      name: "20533"
  },
  {
      value: "SAN VICENTE COATLAN",
      name: "20534"
  },
  {
      value: "SAN VICENTE LACHIXIO",
      name: "20535"
  },
  {
      value: "SAN VICENTE NUÑU",
      name: "20536"
  },
  {
      value: "SILACAYOAPAM",
      name: "20537"
  },
  {
      value: "SITIO DE XITLAPEHUA",
      name: "20538"
  },
  {
      value: "SOLEDAD ETLA",
      name: "20539"
  },
  {
      value: "VILLA DE TAMAZULAPAM DEL PROGRESO",
      name: "20540"
  },
  {
      value: "TANETZE DE ZARAGOZA",
      name: "20541"
  },
  {
      value: "TANICHE",
      name: "20542"
  },
  {
      value: "TATALTEPEC DE VALDES",
      name: "20543"
  },
  {
      value: "TEOCOCUILCO DE MARCOS PEREZ",
      name: "20544"
  },
  {
      value: "TEOTITLAN DE FLORES MAGON",
      name: "20545"
  },
  {
      value: "TEOTITLAN DEL VALLE",
      name: "20546"
  },
  {
      value: "TEOTONGO",
      name: "20547"
  },
  {
      value: "TEPELMEME VILLA DE MORELOS",
      name: "20548"
  },
  {
      value: "TEZOATLAN DE SEGURA Y LUNA",
      name: "20549"
  },
  {
      value: "SAN JERONIMO TLACOCHAHUAYA",
      name: "20550"
  },
  {
      value: "TLACOLULA DE MATAMOROS",
      name: "20551"
  },
  {
      value: "TLACOTEPEC PLUMAS",
      name: "20552"
  },
  {
      value: "TLALIXTAC DE CABRERA",
      name: "20553"
  },
  {
      value: "TOTONTEPEC VILLA DE MORELOS",
      name: "20554"
  },
  {
      value: "TRINIDAD ZAACHILA",
      name: "20555"
  },
  {
      value: "LA TRINIDAD VISTA HERMOSA",
      name: "20556"
  },
  {
      value: "UNION HIDALGO",
      name: "20557"
  },
  {
      value: "VALERIO TRUJANO",
      name: "20558"
  },
  {
      value: "SAN JUAN BAUTISTA VALLE NACIONAL",
      name: "20559"
  },
  {
      value: "VILLA DIAZ ORDAZ",
      name: "20560"
  },
  {
      value: "YAXE",
      name: "20561"
  },
  {
      value: "MAGDALENA YODOCONO DE PORFIRIO DIAZ",
      name: "20562"
  },
  {
      value: "YOGANA",
      name: "20563"
  },
  {
      value: "YUTANDUCHI DE GUERRERO",
      name: "20564"
  },
  {
      value: "VILLA DE ZAACHILA",
      name: "20565"
  },
  {
      value: "SAN MATEO YUCUTINDOO",
      name: "20566"
  },
  {
      value: "ZAPOTITLAN LAGUNAS",
      name: "20567"
  },
  {
      value: "ZAPOTITLAN PALMAS",
      name: "20568"
  },
  {
      value: "SANTA INES DE ZARAGOZA",
      name: "20569"
  },
  {
      value: "ZIMATLAN DE ALVAREZ",
      name: "20570"
  },
  {
      value: "ACAJETE",
      name: "21001"
  },
  {
      value: "ACATENO",
      name: "21002"
  },
  {
      value: "ACATLAN",
      name: "21003"
  },
  {
      value: "ACATZINGO",
      name: "21004"
  },
  {
      value: "ACTEOPAN",
      name: "21005"
  },
  {
      value: "AHUACATLAN",
      name: "21006"
  },
  {
      value: "AHUATLAN",
      name: "21007"
  },
  {
      value: "AHUAZOTEPEC",
      name: "21008"
  },
  {
      value: "AHUEHUETITLA",
      name: "21009"
  },
  {
      value: "AJALPAN",
      name: "21010"
  },
  {
      value: "ALBINO ZERTUCHE",
      name: "21011"
  },
  {
      value: "ALJOJUCA",
      name: "21012"
  },
  {
      value: "ALTEPEXI",
      name: "21013"
  },
  {
      value: "AMIXTLAN",
      name: "21014"
  },
  {
      value: "AMOZOC",
      name: "21015"
  },
  {
      value: "AQUIXTLA",
      name: "21016"
  },
  {
      value: "ATEMPAN",
      name: "21017"
  },
  {
      value: "ATEXCAL",
      name: "21018"
  },
  {
      value: "ATLIXCO",
      name: "21019"
  },
  {
      value: "ATOYATEMPAN",
      name: "21020"
  },
  {
      value: "ATZALA",
      name: "21021"
  },
  {
      value: "ATZITZIHUACAN",
      name: "21022"
  },
  {
      value: "ATZITZINTLA",
      name: "21023"
  },
  {
      value: "AXUTLA",
      name: "21024"
  },
  {
      value: "AYOTOXCO DE GUERRERO",
      name: "21025"
  },
  {
      value: "CALPAN",
      name: "21026"
  },
  {
      value: "CALTEPEC",
      name: "21027"
  },
  {
      value: "CAMOCUAUTLA",
      name: "21028"
  },
  {
      value: "CAXHUACAN",
      name: "21029"
  },
  {
      value: "COATEPEC",
      name: "21030"
  },
  {
      value: "COATZINGO",
      name: "21031"
  },
  {
      value: "COHETZALA",
      name: "21032"
  },
  {
      value: "COHUECAN",
      name: "21033"
  },
  {
      value: "CORONANGO",
      name: "21034"
  },
  {
      value: "COXCATLAN",
      name: "21035"
  },
  {
      value: "COYOMEAPAN",
      name: "21036"
  },
  {
      value: "COYOTEPEC",
      name: "21037"
  },
  {
      value: "CUAPIAXTLA DE MADERO",
      name: "21038"
  },
  {
      value: "CUAUTEMPAN",
      name: "21039"
  },
  {
      value: "CUAUTINCHAN",
      name: "21040"
  },
  {
      value: "CUAUTLANCINGO",
      name: "21041"
  },
  {
      value: "CUAYUCA DE ANDRADE",
      name: "21042"
  },
  {
      value: "CUETZALAN DEL PROGRESO",
      name: "21043"
  },
  {
      value: "CUYOACO",
      name: "21044"
  },
  {
      value: "CHALCHICOMULA DE SESMA",
      name: "21045"
  },
  {
      value: "CHAPULCO",
      name: "21046"
  },
  {
      value: "CHIAUTLA",
      name: "21047"
  },
  {
      value: "CHIAUTZINGO",
      name: "21048"
  },
  {
      value: "CHICONCUAUTLA",
      name: "21049"
  },
  {
      value: "CHICHIQUILA",
      name: "21050"
  },
  {
      value: "CHIETLA",
      name: "21051"
  },
  {
      value: "CHIGMECATITLAN",
      name: "21052"
  },
  {
      value: "CHIGNAHUAPAN",
      name: "21053"
  },
  {
      value: "CHIGNAUTLA",
      name: "21054"
  },
  {
      value: "CHILA",
      name: "21055"
  },
  {
      value: "CHILA DE LA SAL",
      name: "21056"
  },
  {
      value: "HONEY",
      name: "21057"
  },
  {
      value: "CHILCHOTLA",
      name: "21058"
  },
  {
      value: "CHINANTLA",
      name: "21059"
  },
  {
      value: "DOMINGO ARENAS",
      name: "21060"
  },
  {
      value: "ELOXOCHITLAN",
      name: "21061"
  },
  {
      value: "EPATLAN",
      name: "21062"
  },
  {
      value: "ESPERANZA",
      name: "21063"
  },
  {
      value: "FRANCISCO Z. MENA",
      name: "21064"
  },
  {
      value: "GENERAL FELIPE ANGELES",
      name: "21065"
  },
  {
      value: "GUADALUPE",
      name: "21066"
  },
  {
      value: "GUADALUPE VICTORIA",
      name: "21067"
  },
  {
      value: "HERMENEGILDO GALEANA",
      name: "21068"
  },
  {
      value: "HUAQUECHULA",
      name: "21069"
  },
  {
      value: "HUATLATLAUCA",
      name: "21070"
  },
  {
      value: "HUAUCHINANGO",
      name: "21071"
  },
  {
      value: "HUEHUETLA",
      name: "21072"
  },
  {
      value: "HUEHUETLAN EL CHICO",
      name: "21073"
  },
  {
      value: "HUEJOTZINGO",
      name: "21074"
  },
  {
      value: "HUEYAPAN",
      name: "21075"
  },
  {
      value: "HUEYTAMALCO",
      name: "21076"
  },
  {
      value: "HUEYTLALPAN",
      name: "21077"
  },
  {
      value: "HUITZILAN DE SERDAN",
      name: "21078"
  },
  {
      value: "HUITZILTEPEC",
      name: "21079"
  },
  {
      value: "ATLEQUIZAYAN",
      name: "21080"
  },
  {
      value: "IXCAMILPA DE GUERRERO",
      name: "21081"
  },
  {
      value: "IXCAQUIXTLA",
      name: "21082"
  },
  {
      value: "IXTACAMAXTITLAN",
      name: "21083"
  },
  {
      value: "IXTEPEC",
      name: "21084"
  },
  {
      value: "IZUCAR DE MATAMOROS",
      name: "21085"
  },
  {
      value: "JALPAN",
      name: "21086"
  },
  {
      value: "JOLALPAN",
      name: "21087"
  },
  {
      value: "JONOTLA",
      name: "21088"
  },
  {
      value: "JOPALA",
      name: "21089"
  },
  {
      value: "JUAN C. BONILLA",
      name: "21090"
  },
  {
      value: "JUAN GALINDO",
      name: "21091"
  },
  {
      value: "JUAN N. MENDEZ",
      name: "21092"
  },
  {
      value: "LAFRAGUA",
      name: "21093"
  },
  {
      value: "LIBRES",
      name: "21094"
  },
  {
      value: "LA MAGDALENA TLATLAUQUITEPEC",
      name: "21095"
  },
  {
      value: "MAZAPILTEPEC DE JUAREZ",
      name: "21096"
  },
  {
      value: "MIXTLA",
      name: "21097"
  },
  {
      value: "MOLCAXAC",
      name: "21098"
  },
  {
      value: "CAÑADA MORELOS",
      name: "21099"
  },
  {
      value: "NAUPAN",
      name: "21100"
  },
  {
      value: "NAUZONTLA",
      name: "21101"
  },
  {
      value: "NEALTICAN",
      name: "21102"
  },
  {
      value: "NICOLAS BRAVO",
      name: "21103"
  },
  {
      value: "NOPALUCAN",
      name: "21104"
  },
  {
      value: "OCOTEPEC",
      name: "21105"
  },
  {
      value: "OCOYUCAN",
      name: "21106"
  },
  {
      value: "OLINTLA",
      name: "21107"
  },
  {
      value: "ORIENTAL",
      name: "21108"
  },
  {
      value: "PAHUATLAN",
      name: "21109"
  },
  {
      value: "PALMAR DE BRAVO",
      name: "21110"
  },
  {
      value: "PANTEPEC",
      name: "21111"
  },
  {
      value: "PETLALCINGO",
      name: "21112"
  },
  {
      value: "PIAXTLA",
      name: "21113"
  },
  {
      value: "PUEBLA",
      name: "21114"
  },
  {
      value: "QUECHOLAC",
      name: "21115"
  },
  {
      value: "QUIMIXTLAN",
      name: "21116"
  },
  {
      value: "RAFAEL LARA GRAJALES",
      name: "21117"
  },
  {
      value: "LOS REYES DE JUAREZ",
      name: "21118"
  },
  {
      value: "SAN ANDRES CHOLULA",
      name: "21119"
  },
  {
      value: "SAN ANTONIO CAÑADA",
      name: "21120"
  },
  {
      value: "SAN DIEGO LA MESA TOCHIMILTZINGO",
      name: "21121"
  },
  {
      value: "SAN FELIPE TEOTLALCINGO",
      name: "21122"
  },
  {
      value: "SAN FELIPE TEPATLAN",
      name: "21123"
  },
  {
      value: "SAN GABRIEL CHILAC",
      name: "21124"
  },
  {
      value: "SAN GREGORIO ATZOMPA",
      name: "21125"
  },
  {
      value: "SAN JERONIMO TECUANIPAN",
      name: "21126"
  },
  {
      value: "SAN JERONIMO XAYACATLAN",
      name: "21127"
  },
  {
      value: "SAN JOSE CHIAPA",
      name: "21128"
  },
  {
      value: "SAN JOSE MIAHUATLAN",
      name: "21129"
  },
  {
      value: "SAN JUAN ATENCO",
      name: "21130"
  },
  {
      value: "SAN JUAN ATZOMPA",
      name: "21131"
  },
  {
      value: "SAN MARTIN TEXMELUCAN",
      name: "21132"
  },
  {
      value: "SAN MARTIN TOTOLTEPEC",
      name: "21133"
  },
  {
      value: "SAN MATIAS TLALANCALECA",
      name: "21134"
  },
  {
      value: "SAN MIGUEL IXITLAN",
      name: "21135"
  },
  {
      value: "SAN MIGUEL XOXTLA",
      name: "21136"
  },
  {
      value: "SAN NICOLAS BUENOS AIRES",
      name: "21137"
  },
  {
      value: "SAN NICOLAS DE LOS RANCHOS",
      name: "21138"
  },
  {
      value: "SAN PABLO ANICANO",
      name: "21139"
  },
  {
      value: "SAN PEDRO CHOLULA",
      name: "21140"
  },
  {
      value: "SAN PEDRO YELOIXTLAHUACA",
      name: "21141"
  },
  {
      value: "SAN SALVADOR EL SECO",
      name: "21142"
  },
  {
      value: "SAN SALVADOR EL VERDE",
      name: "21143"
  },
  {
      value: "SAN SALVADOR HUIXCOLOTLA",
      name: "21144"
  },
  {
      value: "SAN SEBASTIAN TLACOTEPEC",
      name: "21145"
  },
  {
      value: "SANTA CATARINA TLALTEMPAN",
      name: "21146"
  },
  {
      value: "SANTA INES AHUATEMPAN",
      name: "21147"
  },
  {
      value: "SANTA ISABEL CHOLULA",
      name: "21148"
  },
  {
      value: "SANTIAGO MIAHUATLAN",
      name: "21149"
  },
  {
      value: "HUEHUETLAN EL GRANDE",
      name: "21150"
  },
  {
      value: "SANTO TOMAS HUEYOTLIPAN",
      name: "21151"
  },
  {
      value: "SOLTEPEC",
      name: "21152"
  },
  {
      value: "TECALI DE HERRERA",
      name: "21153"
  },
  {
      value: "TECAMACHALCO",
      name: "21154"
  },
  {
      value: "TECOMATLAN",
      name: "21155"
  },
  {
      value: "TEHUACAN",
      name: "21156"
  },
  {
      value: "TEHUITZINGO",
      name: "21157"
  },
  {
      value: "TENAMPULCO",
      name: "21158"
  },
  {
      value: "TEOPANTLAN",
      name: "21159"
  },
  {
      value: "TEOTLALCO",
      name: "21160"
  },
  {
      value: "TEPANCO DE LOPEZ",
      name: "21161"
  },
  {
      value: "TEPANGO DE RODRIGUEZ",
      name: "21162"
  },
  {
      value: "TEPATLAXCO DE HIDALGO",
      name: "21163"
  },
  {
      value: "TEPEACA",
      name: "21164"
  },
  {
      value: "TEPEMAXALCO",
      name: "21165"
  },
  {
      value: "TEPEOJUMA",
      name: "21166"
  },
  {
      value: "TEPETZINTLA",
      name: "21167"
  },
  {
      value: "TEPEXCO",
      name: "21168"
  },
  {
      value: "TEPEXI DE RODRIGUEZ",
      name: "21169"
  },
  {
      value: "TEPEYAHUALCO",
      name: "21170"
  },
  {
      value: "TEPEYAHUALCO DE CUAUHTEMOC",
      name: "21171"
  },
  {
      value: "TETELA DE OCAMPO",
      name: "21172"
  },
  {
      value: "TETELES DE AVILA CASTILLO",
      name: "21173"
  },
  {
      value: "TEZIUTLAN",
      name: "21174"
  },
  {
      value: "TIANGUISMANALCO",
      name: "21175"
  },
  {
      value: "TILAPA",
      name: "21176"
  },
  {
      value: "TLACOTEPEC DE BENITO JUAREZ",
      name: "21177"
  },
  {
      value: "TLACUILOTEPEC",
      name: "21178"
  },
  {
      value: "TLACHICHUCA",
      name: "21179"
  },
  {
      value: "TLAHUAPAN",
      name: "21180"
  },
  {
      value: "TLALTENANGO",
      name: "21181"
  },
  {
      value: "TLANEPANTLA",
      name: "21182"
  },
  {
      value: "TLAOLA",
      name: "21183"
  },
  {
      value: "TLAPACOYA",
      name: "21184"
  },
  {
      value: "TLAPANALA",
      name: "21185"
  },
  {
      value: "TLATLAUQUITEPEC",
      name: "21186"
  },
  {
      value: "TLAXCO",
      name: "21187"
  },
  {
      value: "TOCHIMILCO",
      name: "21188"
  },
  {
      value: "TOCHTEPEC",
      name: "21189"
  },
  {
      value: "TOTOLTEPEC DE GUERRERO",
      name: "21190"
  },
  {
      value: "TULCINGO",
      name: "21191"
  },
  {
      value: "TUZAMAPAN DE GALEANA",
      name: "21192"
  },
  {
      value: "TZICATLACOYAN",
      name: "21193"
  },
  {
      value: "VENUSTIANO CARRANZA",
      name: "21194"
  },
  {
      value: "VICENTE GUERRERO",
      name: "21195"
  },
  {
      value: "XAYACATLAN DE BRAVO",
      name: "21196"
  },
  {
      value: "XICOTEPEC",
      name: "21197"
  },
  {
      value: "XICOTLAN",
      name: "21198"
  },
  {
      value: "XIUTETELCO",
      name: "21199"
  },
  {
      value: "XOCHIAPULCO",
      name: "21200"
  },
  {
      value: "XOCHILTEPEC",
      name: "21201"
  },
  {
      value: "XOCHITLAN DE VICENTE SUAREZ",
      name: "21202"
  },
  {
      value: "XOCHITLAN TODOS SANTOS",
      name: "21203"
  },
  {
      value: "YAONAHUAC",
      name: "21204"
  },
  {
      value: "YEHUALTEPEC",
      name: "21205"
  },
  {
      value: "ZACAPALA",
      name: "21206"
  },
  {
      value: "ZACAPOAXTLA",
      name: "21207"
  },
  {
      value: "ZACATLAN",
      name: "21208"
  },
  {
      value: "ZAPOTITLAN",
      name: "21209"
  },
  {
      value: "ZAPOTITLAN DE MENDEZ",
      name: "21210"
  },
  {
      value: "ZARAGOZA",
      name: "21211"
  },
  {
      value: "ZAUTLA",
      name: "21212"
  },
  {
      value: "ZIHUATEUTLA",
      name: "21213"
  },
  {
      value: "ZINACATEPEC",
      name: "21214"
  },
  {
      value: "ZONGOZOTLA",
      name: "21215"
  },
  {
      value: "ZOQUIAPAN",
      name: "21216"
  },
  {
      value: "ZOQUITLAN",
      name: "21217"
  },
  {
      value: "AMEALCO DE BONFIL",
      name: "22001"
  },
  {
      value: "PINAL DE AMOLES",
      name: "22002"
  },
  {
      value: "ARROYO SECO",
      name: "22003"
  },
  {
      value: "CADEREYTA DE MONTES",
      name: "22004"
  },
  {
      value: "COLON",
      name: "22005"
  },
  {
      value: "CORREGIDORA",
      name: "22006"
  },
  {
      value: "EZEQUIEL MONTES",
      name: "22007"
  },
  {
      value: "HUIMILPAN",
      name: "22008"
  },
  {
      value: "JALPAN DE SERRA",
      name: "22009"
  },
  {
      value: "LANDA DE MATAMOROS",
      name: "22010"
  },
  {
      value: "EL MARQUES",
      name: "22011"
  },
  {
      value: "PEDRO ESCOBEDO",
      name: "22012"
  },
  {
      value: "PEÑAMILLER",
      name: "22013"
  },
  {
      value: "QUERETARO",
      name: "22014"
  },
  {
      value: "SAN JOAQUIN",
      name: "22015"
  },
  {
      value: "SAN JUAN DEL RIO",
      name: "22016"
  },
  {
      value: "TEQUISQUIAPAN",
      name: "22017"
  },
  {
      value: "TOLIMAN",
      name: "22018"
  },
  {
      value: "COZUMEL",
      name: "23001"
  },
  {
      value: "FELIPE CARRILLO PUERTO",
      name: "23002"
  },
  {
      value: "ISLA MUJERES",
      name: "23003"
  },
  {
      value: "OTHON P. BLANCO",
      name: "23004"
  },
  {
      value: "BENITO JUAREZ",
      name: "23005"
  },
  {
      value: "JOSE MARIA MORELOS",
      name: "23006"
  },
  {
      value: "LAZARO CARDENAS",
      name: "23007"
  },
  {
      value: "SOLIDARIDAD",
      name: "23008"
  },
  {
      value: "TULUM",
      name: "23009"
  },
  {
      value: "BACALAR",
      name: "23010"
  },
  {
      value: "PUERTO MORELOS",
      name: "23011"
  },
  {
      value: "AHUALULCO",
      name: "24001"
  },
  {
      value: "ALAQUINES",
      name: "24002"
  },
  {
      value: "AQUISMON",
      name: "24003"
  },
  {
      value: "ARMADILLO DE LOS INFANTE",
      name: "24004"
  },
  {
      value: "CARDENAS",
      name: "24005"
  },
  {
      value: "CATORCE",
      name: "24006"
  },
  {
      value: "CEDRAL",
      name: "24007"
  },
  {
      value: "CERRITOS",
      name: "24008"
  },
  {
      value: "CERRO DE SAN PEDRO",
      name: "24009"
  },
  {
      value: "CIUDAD DEL MAIZ",
      name: "24010"
  },
  {
      value: "CIUDAD FERNANDEZ",
      name: "24011"
  },
  {
      value: "TANCANHUITZ",
      name: "24012"
  },
  {
      value: "CIUDAD VALLES",
      name: "24013"
  },
  {
      value: "COXCATLAN",
      name: "24014"
  },
  {
      value: "CHARCAS",
      name: "24015"
  },
  {
      value: "EBANO",
      name: "24016"
  },
  {
      value: "GUADALCAZAR",
      name: "24017"
  },
  {
      value: "HUEHUETLAN",
      name: "24018"
  },
  {
      value: "LAGUNILLAS",
      name: "24019"
  },
  {
      value: "MATEHUALA",
      name: "24020"
  },
  {
      value: "MEXQUITIC DE CARMONA",
      name: "24021"
  },
  {
      value: "MOCTEZUMA",
      name: "24022"
  },
  {
      value: "RAYON",
      name: "24023"
  },
  {
      value: "RIOVERDE",
      name: "24024"
  },
  {
      value: "SALINAS",
      name: "24025"
  },
  {
      value: "SAN ANTONIO",
      name: "24026"
  },
  {
      value: "SAN CIRO DE ACOSTA",
      name: "24027"
  },
  {
      value: "SAN LUIS POTOSI",
      name: "24028"
  },
  {
      value: "SAN MARTIN CHALCHICUAUTLA",
      name: "24029"
  },
  {
      value: "SAN NICOLAS TOLENTINO",
      name: "24030"
  },
  {
      value: "SANTA CATARINA",
      name: "24031"
  },
  {
      value: "SANTA MARIA DEL RIO",
      name: "24032"
  },
  {
      value: "SANTO DOMINGO",
      name: "24033"
  },
  {
      value: "SAN VICENTE TANCUAYALAB",
      name: "24034"
  },
  {
      value: "SOLEDAD DE GRACIANO SANCHEZ",
      name: "24035"
  },
  {
      value: "TAMASOPO",
      name: "24036"
  },
  {
      value: "TAMAZUNCHALE",
      name: "24037"
  },
  {
      value: "TAMPACAN",
      name: "24038"
  },
  {
      value: "TAMPAMOLON CORONA",
      name: "24039"
  },
  {
      value: "TAMUIN",
      name: "24040"
  },
  {
      value: "TANLAJAS",
      name: "24041"
  },
  {
      value: "TANQUIAN DE ESCOBEDO",
      name: "24042"
  },
  {
      value: "TIERRA NUEVA",
      name: "24043"
  },
  {
      value: "VANEGAS",
      name: "24044"
  },
  {
      value: "VENADO",
      name: "24045"
  },
  {
      value: "VILLA DE ARRIAGA",
      name: "24046"
  },
  {
      value: "VILLA DE GUADALUPE",
      name: "24047"
  },
  {
      value: "VILLA DE LA PAZ",
      name: "24048"
  },
  {
      value: "VILLA DE RAMOS",
      name: "24049"
  },
  {
      value: "VILLA DE REYES",
      name: "24050"
  },
  {
      value: "VILLA HIDALGO",
      name: "24051"
  },
  {
      value: "VILLA JUAREZ",
      name: "24052"
  },
  {
      value: "AXTLA DE TERRAZAS",
      name: "24053"
  },
  {
      value: "XILITLA",
      name: "24054"
  },
  {
      value: "ZARAGOZA",
      name: "24055"
  },
  {
      value: "VILLA DE ARISTA",
      name: "24056"
  },
  {
      value: "MATLAPA",
      name: "24057"
  },
  {
      value: "EL NARANJO",
      name: "24058"
  },
  {
      value: "AHOME",
      name: "25001"
  },
  {
      value: "ANGOSTURA",
      name: "25002"
  },
  {
      value: "BADIRAGUATO",
      name: "25003"
  },
  {
      value: "CONCORDIA",
      name: "25004"
  },
  {
      value: "COSALA",
      name: "25005"
  },
  {
      value: "CULIACAN",
      name: "25006"
  },
  {
      value: "CHOIX",
      name: "25007"
  },
  {
      value: "ELOTA",
      name: "25008"
  },
  {
      value: "ESCUINAPA",
      name: "25009"
  },
  {
      value: "EL FUERTE",
      name: "25010"
  },
  {
      value: "GUASAVE",
      name: "25011"
  },
  {
      value: "MAZATLAN",
      name: "25012"
  },
  {
      value: "MOCORITO",
      name: "25013"
  },
  {
      value: "ROSARIO",
      name: "25014"
  },
  {
      value: "SALVADOR ALVARADO",
      name: "25015"
  },
  {
      value: "SAN IGNACIO",
      name: "25016"
  },
  {
      value: "SINALOA",
      name: "25017"
  },
  {
      value: "NAVOLATO",
      name: "25018"
  },
  {
      value: "ACONCHI",
      name: "26001"
  },
  {
      value: "AGUA PRIETA",
      name: "26002"
  },
  {
      value: "ALAMOS",
      name: "26003"
  },
  {
      value: "ALTAR",
      name: "26004"
  },
  {
      value: "ARIVECHI",
      name: "26005"
  },
  {
      value: "ARIZPE",
      name: "26006"
  },
  {
      value: "ATIL",
      name: "26007"
  },
  {
      value: "BACADEHUACHI",
      name: "26008"
  },
  {
      value: "BACANORA",
      name: "26009"
  },
  {
      value: "BACERAC",
      name: "26010"
  },
  {
      value: "BACOACHI",
      name: "26011"
  },
  {
      value: "BACUM",
      name: "26012"
  },
  {
      value: "BANAMICHI",
      name: "26013"
  },
  {
      value: "BAVIACORA",
      name: "26014"
  },
  {
      value: "BAVISPE",
      name: "26015"
  },
  {
      value: "BENJAMIN HILL",
      name: "26016"
  },
  {
      value: "CABORCA",
      name: "26017"
  },
  {
      value: "CAJEME",
      name: "26018"
  },
  {
      value: "CANANEA",
      name: "26019"
  },
  {
      value: "CARBO",
      name: "26020"
  },
  {
      value: "LA COLORADA",
      name: "26021"
  },
  {
      value: "CUCURPE",
      name: "26022"
  },
  {
      value: "CUMPAS",
      name: "26023"
  },
  {
      value: "DIVISADEROS",
      name: "26024"
  },
  {
      value: "EMPALME",
      name: "26025"
  },
  {
      value: "ETCHOJOA",
      name: "26026"
  },
  {
      value: "FRONTERAS",
      name: "26027"
  },
  {
      value: "GRANADOS",
      name: "26028"
  },
  {
      value: "GUAYMAS",
      name: "26029"
  },
  {
      value: "HERMOSILLO",
      name: "26030"
  },
  {
      value: "HUACHINERA",
      name: "26031"
  },
  {
      value: "HUASABAS",
      name: "26032"
  },
  {
      value: "HUATABAMPO",
      name: "26033"
  },
  {
      value: "HUEPAC",
      name: "26034"
  },
  {
      value: "IMURIS",
      name: "26035"
  },
  {
      value: "MAGDALENA",
      name: "26036"
  },
  {
      value: "MAZATAN",
      name: "26037"
  },
  {
      value: "MOCTEZUMA",
      name: "26038"
  },
  {
      value: "NACO",
      name: "26039"
  },
  {
      value: "NACORI CHICO",
      name: "26040"
  },
  {
      value: "NACOZARI DE GARCIA",
      name: "26041"
  },
  {
      value: "NAVOJOA",
      name: "26042"
  },
  {
      value: "NOGALES",
      name: "26043"
  },
  {
      value: "ONAVAS",
      name: "26044"
  },
  {
      value: "OPODEPE",
      name: "26045"
  },
  {
      value: "OQUITOA",
      name: "26046"
  },
  {
      value: "PITIQUITO",
      name: "26047"
  },
  {
      value: "PUERTO PEÑASCO",
      name: "26048"
  },
  {
      value: "QUIRIEGO",
      name: "26049"
  },
  {
      value: "RAYON",
      name: "26050"
  },
  {
      value: "ROSARIO",
      name: "26051"
  },
  {
      value: "SAHUARIPA",
      name: "26052"
  },
  {
      value: "SAN FELIPE DE JESUS",
      name: "26053"
  },
  {
      value: "SAN JAVIER",
      name: "26054"
  },
  {
      value: "SAN LUIS RIO COLORADO",
      name: "26055"
  },
  {
      value: "SAN MIGUEL DE HORCASITAS",
      name: "26056"
  },
  {
      value: "SAN PEDRO DE LA CUEVA",
      name: "26057"
  },
  {
      value: "SANTA ANA",
      name: "26058"
  },
  {
      value: "SANTA CRUZ",
      name: "26059"
  },
  {
      value: "SARIC",
      name: "26060"
  },
  {
      value: "SOYOPA",
      name: "26061"
  },
  {
      value: "SUAQUI GRANDE",
      name: "26062"
  },
  {
      value: "TEPACHE",
      name: "26063"
  },
  {
      value: "TRINCHERAS",
      name: "26064"
  },
  {
      value: "TUBUTAMA",
      name: "26065"
  },
  {
      value: "URES",
      name: "26066"
  },
  {
      value: "VILLA HIDALGO",
      name: "26067"
  },
  {
      value: "VILLA PESQUEIRA",
      name: "26068"
  },
  {
      value: "YECORA",
      name: "26069"
  },
  {
      value: "GENERAL PLUTARCO ELIAS CALLES",
      name: "26070"
  },
  {
      value: "BENITO JUAREZ",
      name: "26071"
  },
  {
      value: "SAN IGNACIO RIO MUERTO",
      name: "26072"
  },
  {
      value: "BALANCAN",
      name: "27001"
  },
  {
      value: "CARDENAS",
      name: "27002"
  },
  {
      value: "CENTLA",
      name: "27003"
  },
  {
      value: "CENTRO",
      name: "27004"
  },
  {
      value: "COMALCALCO",
      name: "27005"
  },
  {
      value: "CUNDUACAN",
      name: "27006"
  },
  {
      value: "EMILIANO ZAPATA",
      name: "27007"
  },
  {
      value: "HUIMANGUILLO",
      name: "27008"
  },
  {
      value: "JALAPA",
      name: "27009"
  },
  {
      value: "JALPA DE MENDEZ",
      name: "27010"
  },
  {
      value: "JONUTA",
      name: "27011"
  },
  {
      value: "MACUSPANA",
      name: "27012"
  },
  {
      value: "NACAJUCA",
      name: "27013"
  },
  {
      value: "PARAISO",
      name: "27014"
  },
  {
      value: "TACOTALPA",
      name: "27015"
  },
  {
      value: "TEAPA",
      name: "27016"
  },
  {
      value: "TENOSIQUE",
      name: "27017"
  },
  {
      value: "ABASOLO",
      name: "28001"
  },
  {
      value: "ALDAMA",
      name: "28002"
  },
  {
      value: "ALTAMIRA",
      name: "28003"
  },
  {
      value: "ANTIGUO MORELOS",
      name: "28004"
  },
  {
      value: "BURGOS",
      name: "28005"
  },
  {
      value: "BUSTAMANTE",
      name: "28006"
  },
  {
      value: "CAMARGO",
      name: "28007"
  },
  {
      value: "CASAS",
      name: "28008"
  },
  {
      value: "CIUDAD MADERO",
      name: "28009"
  },
  {
      value: "CRUILLAS",
      name: "28010"
  },
  {
      value: "GOMEZ FARIAS",
      name: "28011"
  },
  {
      value: "GONZALEZ",
      name: "28012"
  },
  {
      value: "GÜEMEZ",
      name: "28013"
  },
  {
      value: "GUERRERO",
      name: "28014"
  },
  {
      value: "GUSTAVO DIAZ ORDAZ",
      name: "28015"
  },
  {
      value: "HIDALGO",
      name: "28016"
  },
  {
      value: "JAUMAVE",
      name: "28017"
  },
  {
      value: "JIMENEZ",
      name: "28018"
  },
  {
      value: "LLERA",
      name: "28019"
  },
  {
      value: "MAINERO",
      name: "28020"
  },
  {
      value: "EL MANTE",
      name: "28021"
  },
  {
      value: "MATAMOROS",
      name: "28022"
  },
  {
      value: "MENDEZ",
      name: "28023"
  },
  {
      value: "MIER",
      name: "28024"
  },
  {
      value: "MIGUEL ALEMAN",
      name: "28025"
  },
  {
      value: "MIQUIHUANA",
      name: "28026"
  },
  {
      value: "NUEVO LAREDO",
      name: "28027"
  },
  {
      value: "NUEVO MORELOS",
      name: "28028"
  },
  {
      value: "OCAMPO",
      name: "28029"
  },
  {
      value: "PADILLA",
      name: "28030"
  },
  {
      value: "PALMILLAS",
      name: "28031"
  },
  {
      value: "REYNOSA",
      name: "28032"
  },
  {
      value: "RIO BRAVO",
      name: "28033"
  },
  {
      value: "SAN CARLOS",
      name: "28034"
  },
  {
      value: "SAN FERNANDO",
      name: "28035"
  },
  {
      value: "SAN NICOLAS",
      name: "28036"
  },
  {
      value: "SOTO LA MARINA",
      name: "28037"
  },
  {
      value: "TAMPICO",
      name: "28038"
  },
  {
      value: "TULA",
      name: "28039"
  },
  {
      value: "VALLE HERMOSO",
      name: "28040"
  },
  {
      value: "VICTORIA",
      name: "28041"
  },
  {
      value: "VILLAGRAN",
      name: "28042"
  },
  {
      value: "XICOTENCATL",
      name: "28043"
  },
  {
      value: "AMAXAC DE GUERRERO",
      name: "29001"
  },
  {
      value: "APETATITLAN DE ANTONIO CARVAJAL",
      name: "29002"
  },
  {
      value: "ATLANGATEPEC",
      name: "29003"
  },
  {
      value: "ATLTZAYANCA",
      name: "29004"
  },
  {
      value: "APIZACO",
      name: "29005"
  },
  {
      value: "CALPULALPAN",
      name: "29006"
  },
  {
      value: "EL CARMEN TEQUEXQUITLA",
      name: "29007"
  },
  {
      value: "CUAPIAXTLA",
      name: "29008"
  },
  {
      value: "CUAXOMULCO",
      name: "29009"
  },
  {
      value: "CHIAUTEMPAN",
      name: "29010"
  },
  {
      value: "MUÑOZ DE DOMINGO ARENAS",
      name: "29011"
  },
  {
      value: "ESPAÑITA",
      name: "29012"
  },
  {
      value: "HUAMANTLA",
      name: "29013"
  },
  {
      value: "HUEYOTLIPAN",
      name: "29014"
  },
  {
      value: "IXTACUIXTLA DE MARIANO MATAMOROS",
      name: "29015"
  },
  {
      value: "IXTENCO",
      name: "29016"
  },
  {
      value: "MAZATECOCHCO DE JOSE MARIA MORELOS",
      name: "29017"
  },
  {
      value: "CONTLA DE JUAN CUAMATZI",
      name: "29018"
  },
  {
      value: "TEPETITLA DE LARDIZABAL",
      name: "29019"
  },
  {
      value: "SANCTORUM DE LAZARO CARDENAS",
      name: "29020"
  },
  {
      value: "NANACAMILPA DE MARIANO ARISTA",
      name: "29021"
  },
  {
      value: "ACUAMANALA DE MIGUEL HIDALGO",
      name: "29022"
  },
  {
      value: "NATIVITAS",
      name: "29023"
  },
  {
      value: "PANOTLA",
      name: "29024"
  },
  {
      value: "SAN PABLO DEL MONTE",
      name: "29025"
  },
  {
      value: "SANTA CRUZ TLAXCALA",
      name: "29026"
  },
  {
      value: "TENANCINGO",
      name: "29027"
  },
  {
      value: "TEOLOCHOLCO",
      name: "29028"
  },
  {
      value: "TEPEYANCO",
      name: "29029"
  },
  {
      value: "TERRENATE",
      name: "29030"
  },
  {
      value: "TETLA DE LA SOLIDARIDAD",
      name: "29031"
  },
  {
      value: "TETLATLAHUCA",
      name: "29032"
  },
  {
      value: "TLAXCALA",
      name: "29033"
  },
  {
      value: "TLAXCO",
      name: "29034"
  },
  {
      value: "TOCATLAN",
      name: "29035"
  },
  {
      value: "TOTOLAC",
      name: "29036"
  },
  {
      value: "ZILTLALTEPEC DE TRINIDAD SANCHEZ SANTOS",
      name: "29037"
  },
  {
      value: "TZOMPANTEPEC",
      name: "29038"
  },
  {
      value: "XALOZTOC",
      name: "29039"
  },
  {
      value: "XALTOCAN",
      name: "29040"
  },
  {
      value: "PAPALOTLA DE XICOHTENCATL",
      name: "29041"
  },
  {
      value: "XICOHTZINCO",
      name: "29042"
  },
  {
      value: "YAUHQUEMEHCAN",
      name: "29043"
  },
  {
      value: "ZACATELCO",
      name: "29044"
  },
  {
      value: "BENITO JUAREZ",
      name: "29045"
  },
  {
      value: "EMILIANO ZAPATA",
      name: "29046"
  },
  {
      value: "LAZARO CARDENAS",
      name: "29047"
  },
  {
      value: "LA MAGDALENA TLALTELULCO",
      name: "29048"
  },
  {
      value: "SAN DAMIAN TEXOLOC",
      name: "29049"
  },
  {
      value: "SAN FRANCISCO TETLANOHCAN",
      name: "29050"
  },
  {
      value: "SAN JERONIMO ZACUALPAN",
      name: "29051"
  },
  {
      value: "SAN JOSE TEACALCO",
      name: "29052"
  },
  {
      value: "SAN JUAN HUACTZINCO",
      name: "29053"
  },
  {
      value: "SAN LORENZO AXOCOMANITLA",
      name: "29054"
  },
  {
      value: "SAN LUCAS TECOPILCO",
      name: "29055"
  },
  {
      value: "SANTA ANA NOPALUCAN",
      name: "29056"
  },
  {
      value: "SANTA APOLONIA TEACALCO",
      name: "29057"
  },
  {
      value: "SANTA CATARINA AYOMETLA",
      name: "29058"
  },
  {
      value: "SANTA CRUZ QUILEHTLA",
      name: "29059"
  },
  {
      value: "SANTA ISABEL XILOXOXTLA",
      name: "29060"
  },
  {
      value: "ACAJETE",
      name: "30001"
  },
  {
      value: "ACATLAN",
      name: "30002"
  },
  {
      value: "ACAYUCAN",
      name: "30003"
  },
  {
      value: "ACTOPAN",
      name: "30004"
  },
  {
      value: "ACULA",
      name: "30005"
  },
  {
      value: "ACULTZINGO",
      name: "30006"
  },
  {
      value: "CAMARON DE TEJEDA",
      name: "30007"
  },
  {
      value: "ALPATLAHUAC",
      name: "30008"
  },
  {
      value: "ALTO LUCERO DE GUTIERREZ BARRIOS",
      name: "30009"
  },
  {
      value: "ALTOTONGA",
      name: "30010"
  },
  {
      value: "ALVARADO",
      name: "30011"
  },
  {
      value: "AMATITLAN",
      name: "30012"
  },
  {
      value: "NARANJOS AMATLAN",
      name: "30013"
  },
  {
      value: "AMATLAN DE LOS REYES",
      name: "30014"
  },
  {
      value: "ANGEL R. CABADA",
      name: "30015"
  },
  {
      value: "LA ANTIGUA",
      name: "30016"
  },
  {
      value: "APAZAPAN",
      name: "30017"
  },
  {
      value: "AQUILA",
      name: "30018"
  },
  {
      value: "ASTACINGA",
      name: "30019"
  },
  {
      value: "ATLAHUILCO",
      name: "30020"
  },
  {
      value: "ATOYAC",
      name: "30021"
  },
  {
      value: "ATZACAN",
      name: "30022"
  },
  {
      value: "ATZALAN",
      name: "30023"
  },
  {
      value: "TLALTETELA",
      name: "30024"
  },
  {
      value: "AYAHUALULCO",
      name: "30025"
  },
  {
      value: "BANDERILLA",
      name: "30026"
  },
  {
      value: "BENITO JUAREZ",
      name: "30027"
  },
  {
      value: "BOCA DEL RIO",
      name: "30028"
  },
  {
      value: "CALCAHUALCO",
      name: "30029"
  },
  {
      value: "CAMERINO Z. MENDOZA",
      name: "30030"
  },
  {
      value: "CARRILLO PUERTO",
      name: "30031"
  },
  {
      value: "CATEMACO",
      name: "30032"
  },
  {
      value: "CAZONES DE HERRERA",
      name: "30033"
  },
  {
      value: "CERRO AZUL",
      name: "30034"
  },
  {
      value: "CITLALTEPETL",
      name: "30035"
  },
  {
      value: "COACOATZINTLA",
      name: "30036"
  },
  {
      value: "COAHUITLAN",
      name: "30037"
  },
  {
      value: "COATEPEC",
      name: "30038"
  },
  {
      value: "COATZACOALCOS",
      name: "30039"
  },
  {
      value: "COATZINTLA",
      name: "30040"
  },
  {
      value: "COETZALA",
      name: "30041"
  },
  {
      value: "COLIPA",
      name: "30042"
  },
  {
      value: "COMAPA",
      name: "30043"
  },
  {
      value: "CORDOBA",
      name: "30044"
  },
  {
      value: "COSAMALOAPAN DE CARPIO",
      name: "30045"
  },
  {
      value: "COSAUTLAN DE CARVAJAL",
      name: "30046"
  },
  {
      value: "COSCOMATEPEC",
      name: "30047"
  },
  {
      value: "COSOLEACAQUE",
      name: "30048"
  },
  {
      value: "COTAXTLA",
      name: "30049"
  },
  {
      value: "COXQUIHUI",
      name: "30050"
  },
  {
      value: "COYUTLA",
      name: "30051"
  },
  {
      value: "CUICHAPA",
      name: "30052"
  },
  {
      value: "CUITLAHUAC",
      name: "30053"
  },
  {
      value: "CHACALTIANGUIS",
      name: "30054"
  },
  {
      value: "CHALMA",
      name: "30055"
  },
  {
      value: "CHICONAMEL",
      name: "30056"
  },
  {
      value: "CHICONQUIACO",
      name: "30057"
  },
  {
      value: "CHICONTEPEC",
      name: "30058"
  },
  {
      value: "CHINAMECA",
      name: "30059"
  },
  {
      value: "CHINAMPA DE GOROSTIZA",
      name: "30060"
  },
  {
      value: "LAS CHOAPAS",
      name: "30061"
  },
  {
      value: "CHOCAMAN",
      name: "30062"
  },
  {
      value: "CHONTLA",
      name: "30063"
  },
  {
      value: "CHUMATLAN",
      name: "30064"
  },
  {
      value: "EMILIANO ZAPATA",
      name: "30065"
  },
  {
      value: "ESPINAL",
      name: "30066"
  },
  {
      value: "FILOMENO MATA",
      name: "30067"
  },
  {
      value: "FORTIN",
      name: "30068"
  },
  {
      value: "GUTIERREZ ZAMORA",
      name: "30069"
  },
  {
      value: "HIDALGOTITLAN",
      name: "30070"
  },
  {
      value: "HUATUSCO",
      name: "30071"
  },
  {
      value: "HUAYACOCOTLA",
      name: "30072"
  },
  {
      value: "HUEYAPAN DE OCAMPO",
      name: "30073"
  },
  {
      value: "HUILOAPAN DE CUAUHTEMOC",
      name: "30074"
  },
  {
      value: "IGNACIO DE LA LLAVE",
      name: "30075"
  },
  {
      value: "ILAMATLAN",
      name: "30076"
  },
  {
      value: "ISLA",
      name: "30077"
  },
  {
      value: "IXCATEPEC",
      name: "30078"
  },
  {
      value: "IXHUACAN DE LOS REYES",
      name: "30079"
  },
  {
      value: "IXHUATLAN DEL CAFE",
      name: "30080"
  },
  {
      value: "IXHUATLANCILLO",
      name: "30081"
  },
  {
      value: "IXHUATLAN DEL SURESTE",
      name: "30082"
  },
  {
      value: "IXHUATLAN DE MADERO",
      name: "30083"
  },
  {
      value: "IXMATLAHUACAN",
      name: "30084"
  },
  {
      value: "IXTACZOQUITLAN",
      name: "30085"
  },
  {
      value: "JALACINGO",
      name: "30086"
  },
  {
      value: "XALAPA",
      name: "30087"
  },
  {
      value: "JALCOMULCO",
      name: "30088"
  },
  {
      value: "JALTIPAN",
      name: "30089"
  },
  {
      value: "JAMAPA",
      name: "30090"
  },
  {
      value: "JESUS CARRANZA",
      name: "30091"
  },
  {
      value: "XICO",
      name: "30092"
  },
  {
      value: "JILOTEPEC",
      name: "30093"
  },
  {
      value: "JUAN RODRIGUEZ CLARA",
      name: "30094"
  },
  {
      value: "JUCHIQUE DE FERRER",
      name: "30095"
  },
  {
      value: "LANDERO Y COSS",
      name: "30096"
  },
  {
      value: "LERDO DE TEJADA",
      name: "30097"
  },
  {
      value: "MAGDALENA",
      name: "30098"
  },
  {
      value: "MALTRATA",
      name: "30099"
  },
  {
      value: "MANLIO FABIO ALTAMIRANO",
      name: "30100"
  },
  {
      value: "MARIANO ESCOBEDO",
      name: "30101"
  },
  {
      value: "MARTINEZ DE LA TORRE",
      name: "30102"
  },
  {
      value: "MECATLAN",
      name: "30103"
  },
  {
      value: "MECAYAPAN",
      name: "30104"
  },
  {
      value: "MEDELLIN DE BRAVO",
      name: "30105"
  },
  {
      value: "MIAHUATLAN",
      name: "30106"
  },
  {
      value: "LAS MINAS",
      name: "30107"
  },
  {
      value: "MINATITLAN",
      name: "30108"
  },
  {
      value: "MISANTLA",
      name: "30109"
  },
  {
      value: "MIXTLA DE ALTAMIRANO",
      name: "30110"
  },
  {
      value: "MOLOACAN",
      name: "30111"
  },
  {
      value: "NAOLINCO",
      name: "30112"
  },
  {
      value: "NARANJAL",
      name: "30113"
  },
  {
      value: "NAUTLA",
      name: "30114"
  },
  {
      value: "NOGALES",
      name: "30115"
  },
  {
      value: "OLUTA",
      name: "30116"
  },
  {
      value: "OMEALCA",
      name: "30117"
  },
  {
      value: "ORIZABA",
      name: "30118"
  },
  {
      value: "OTATITLAN",
      name: "30119"
  },
  {
      value: "OTEAPAN",
      name: "30120"
  },
  {
      value: "OZULUAMA DE MASCAREÑAS",
      name: "30121"
  },
  {
      value: "PAJAPAN",
      name: "30122"
  },
  {
      value: "PANUCO",
      name: "30123"
  },
  {
      value: "PAPANTLA",
      name: "30124"
  },
  {
      value: "PASO DEL MACHO",
      name: "30125"
  },
  {
      value: "PASO DE OVEJAS",
      name: "30126"
  },
  {
      value: "LA PERLA",
      name: "30127"
  },
  {
      value: "PEROTE",
      name: "30128"
  },
  {
      value: "PLATON SANCHEZ",
      name: "30129"
  },
  {
      value: "PLAYA VICENTE",
      name: "30130"
  },
  {
      value: "POZA RICA DE HIDALGO",
      name: "30131"
  },
  {
      value: "LAS VIGAS DE RAMIREZ",
      name: "30132"
  },
  {
      value: "PUEBLO VIEJO",
      name: "30133"
  },
  {
      value: "PUENTE NACIONAL",
      name: "30134"
  },
  {
      value: "RAFAEL DELGADO",
      name: "30135"
  },
  {
      value: "RAFAEL LUCIO",
      name: "30136"
  },
  {
      value: "LOS REYES",
      name: "30137"
  },
  {
      value: "RIO BLANCO",
      name: "30138"
  },
  {
      value: "SALTABARRANCA",
      name: "30139"
  },
  {
      value: "SAN ANDRES TENEJAPAN",
      name: "30140"
  },
  {
      value: "SAN ANDRES TUXTLA",
      name: "30141"
  },
  {
      value: "SAN JUAN EVANGELISTA",
      name: "30142"
  },
  {
      value: "SANTIAGO TUXTLA",
      name: "30143"
  },
  {
      value: "SAYULA DE ALEMAN",
      name: "30144"
  },
  {
      value: "SOCONUSCO",
      name: "30145"
  },
  {
      value: "SOCHIAPA",
      name: "30146"
  },
  {
      value: "SOLEDAD ATZOMPA",
      name: "30147"
  },
  {
      value: "SOLEDAD DE DOBLADO",
      name: "30148"
  },
  {
      value: "SOTEAPAN",
      name: "30149"
  },
  {
      value: "TAMALIN",
      name: "30150"
  },
  {
      value: "TAMIAHUA",
      name: "30151"
  },
  {
      value: "TAMPICO ALTO",
      name: "30152"
  },
  {
      value: "TANCOCO",
      name: "30153"
  },
  {
      value: "TANTIMA",
      name: "30154"
  },
  {
      value: "TANTOYUCA",
      name: "30155"
  },
  {
      value: "TATATILA",
      name: "30156"
  },
  {
      value: "CASTILLO DE TEAYO",
      name: "30157"
  },
  {
      value: "TECOLUTLA",
      name: "30158"
  },
  {
      value: "TEHUIPANGO",
      name: "30159"
  },
  {
      value: "ALAMO TEMAPACHE",
      name: "30160"
  },
  {
      value: "TEMPOAL",
      name: "30161"
  },
  {
      value: "TENAMPA",
      name: "30162"
  },
  {
      value: "TENOCHTITLAN",
      name: "30163"
  },
  {
      value: "TEOCELO",
      name: "30164"
  },
  {
      value: "TEPATLAXCO",
      name: "30165"
  },
  {
      value: "TEPETLAN",
      name: "30166"
  },
  {
      value: "TEPETZINTLA",
      name: "30167"
  },
  {
      value: "TEQUILA",
      name: "30168"
  },
  {
      value: "JOSE AZUETA",
      name: "30169"
  },
  {
      value: "TEXCATEPEC",
      name: "30170"
  },
  {
      value: "TEXHUACAN",
      name: "30171"
  },
  {
      value: "TEXISTEPEC",
      name: "30172"
  },
  {
      value: "TEZONAPA",
      name: "30173"
  },
  {
      value: "TIERRA BLANCA",
      name: "30174"
  },
  {
      value: "TIHUATLAN",
      name: "30175"
  },
  {
      value: "TLACOJALPAN",
      name: "30176"
  },
  {
      value: "TLACOLULAN",
      name: "30177"
  },
  {
      value: "TLACOTALPAN",
      name: "30178"
  },
  {
      value: "TLACOTEPEC DE MEJIA",
      name: "30179"
  },
  {
      value: "TLACHICHILCO",
      name: "30180"
  },
  {
      value: "TLALIXCOYAN",
      name: "30181"
  },
  {
      value: "TLALNELHUAYOCAN",
      name: "30182"
  },
  {
      value: "TLAPACOYAN",
      name: "30183"
  },
  {
      value: "TLAQUILPA",
      name: "30184"
  },
  {
      value: "TLILAPAN",
      name: "30185"
  },
  {
      value: "TOMATLAN",
      name: "30186"
  },
  {
      value: "TONAYAN",
      name: "30187"
  },
  {
      value: "TOTUTLA",
      name: "30188"
  },
  {
      value: "TUXPAN",
      name: "30189"
  },
  {
      value: "TUXTILLA",
      name: "30190"
  },
  {
      value: "URSULO GALVAN",
      name: "30191"
  },
  {
      value: "VEGA DE ALATORRE",
      name: "30192"
  },
  {
      value: "VERACRUZ",
      name: "30193"
  },
  {
      value: "VILLA ALDAMA",
      name: "30194"
  },
  {
      value: "XOXOCOTLA",
      name: "30195"
  },
  {
      value: "YANGA",
      name: "30196"
  },
  {
      value: "YECUATLA",
      name: "30197"
  },
  {
      value: "ZACUALPAN",
      name: "30198"
  },
  {
      value: "ZARAGOZA",
      name: "30199"
  },
  {
      value: "ZENTLA",
      name: "30200"
  },
  {
      value: "ZONGOLICA",
      name: "30201"
  },
  {
      value: "ZONTECOMATLAN DE LOPEZ Y FUENTES",
      name: "30202"
  },
  {
      value: "ZOZOCOLCO DE HIDALGO",
      name: "30203"
  },
  {
      value: "AGUA DULCE",
      name: "30204"
  },
  {
      value: "EL HIGO",
      name: "30205"
  },
  {
      value: "NANCHITAL DE LAZARO CARDENAS DEL RIO",
      name: "30206"
  },
  {
      value: "TRES VALLES",
      name: "30207"
  },
  {
      value: "CARLOS A. CARRILLO",
      name: "30208"
  },
  {
      value: "TATAHUICAPAN DE JUAREZ",
      name: "30209"
  },
  {
      value: "UXPANAPA",
      name: "30210"
  },
  {
      value: "SAN RAFAEL",
      name: "30211"
  },
  {
      value: "SANTIAGO SOCHIAPAN",
      name: "30212"
  },
  {
      value: "ABALA",
      name: "31001"
  },
  {
      value: "ACANCEH",
      name: "31002"
  },
  {
      value: "AKIL",
      name: "31003"
  },
  {
      value: "BACA",
      name: "31004"
  },
  {
      value: "BOKOBA",
      name: "31005"
  },
  {
      value: "BUCTZOTZ",
      name: "31006"
  },
  {
      value: "CACALCHEN",
      name: "31007"
  },
  {
      value: "CALOTMUL",
      name: "31008"
  },
  {
      value: "CANSAHCAB",
      name: "31009"
  },
  {
      value: "CANTAMAYEC",
      name: "31010"
  },
  {
      value: "CELESTUN",
      name: "31011"
  },
  {
      value: "CENOTILLO",
      name: "31012"
  },
  {
      value: "CONKAL",
      name: "31013"
  },
  {
      value: "CUNCUNUL",
      name: "31014"
  },
  {
      value: "CUZAMA",
      name: "31015"
  },
  {
      value: "CHACSINKIN",
      name: "31016"
  },
  {
      value: "CHANKOM",
      name: "31017"
  },
  {
      value: "CHAPAB",
      name: "31018"
  },
  {
      value: "CHEMAX",
      name: "31019"
  },
  {
      value: "CHICXULUB PUEBLO",
      name: "31020"
  },
  {
      value: "CHICHIMILA",
      name: "31021"
  },
  {
      value: "CHIKINDZONOT",
      name: "31022"
  },
  {
      value: "CHOCHOLA",
      name: "31023"
  },
  {
      value: "CHUMAYEL",
      name: "31024"
  },
  {
      value: "DZAN",
      name: "31025"
  },
  {
      value: "DZEMUL",
      name: "31026"
  },
  {
      value: "DZIDZANTUN",
      name: "31027"
  },
  {
      value: "DZILAM DE BRAVO",
      name: "31028"
  },
  {
      value: "DZILAM GONZALEZ",
      name: "31029"
  },
  {
      value: "DZITAS",
      name: "31030"
  },
  {
      value: "DZONCAUICH",
      name: "31031"
  },
  {
      value: "ESPITA",
      name: "31032"
  },
  {
      value: "HALACHO",
      name: "31033"
  },
  {
      value: "HOCABA",
      name: "31034"
  },
  {
      value: "HOCTUN",
      name: "31035"
  },
  {
      value: "HOMUN",
      name: "31036"
  },
  {
      value: "HUHI",
      name: "31037"
  },
  {
      value: "HUNUCMA",
      name: "31038"
  },
  {
      value: "IXIL",
      name: "31039"
  },
  {
      value: "IZAMAL",
      name: "31040"
  },
  {
      value: "KANASIN",
      name: "31041"
  },
  {
      value: "KANTUNIL",
      name: "31042"
  },
  {
      value: "KAUA",
      name: "31043"
  },
  {
      value: "KINCHIL",
      name: "31044"
  },
  {
      value: "KOPOMA",
      name: "31045"
  },
  {
      value: "MAMA",
      name: "31046"
  },
  {
      value: "MANI",
      name: "31047"
  },
  {
      value: "MAXCANU",
      name: "31048"
  },
  {
      value: "MAYAPAN",
      name: "31049"
  },
  {
      value: "MERIDA",
      name: "31050"
  },
  {
      value: "MOCOCHA",
      name: "31051"
  },
  {
      value: "MOTUL",
      name: "31052"
  },
  {
      value: "MUNA",
      name: "31053"
  },
  {
      value: "MUXUPIP",
      name: "31054"
  },
  {
      value: "OPICHEN",
      name: "31055"
  },
  {
      value: "OXKUTZCAB",
      name: "31056"
  },
  {
      value: "PANABA",
      name: "31057"
  },
  {
      value: "PETO",
      name: "31058"
  },
  {
      value: "PROGRESO",
      name: "31059"
  },
  {
      value: "QUINTANA ROO",
      name: "31060"
  },
  {
      value: "RIO LAGARTOS",
      name: "31061"
  },
  {
      value: "SACALUM",
      name: "31062"
  },
  {
      value: "SAMAHIL",
      name: "31063"
  },
  {
      value: "SANAHCAT",
      name: "31064"
  },
  {
      value: "SAN FELIPE",
      name: "31065"
  },
  {
      value: "SANTA ELENA",
      name: "31066"
  },
  {
      value: "SEYE",
      name: "31067"
  },
  {
      value: "SINANCHE",
      name: "31068"
  },
  {
      value: "SOTUTA",
      name: "31069"
  },
  {
      value: "SUCILA",
      name: "31070"
  },
  {
      value: "SUDZAL",
      name: "31071"
  },
  {
      value: "SUMA",
      name: "31072"
  },
  {
      value: "TAHDZIU",
      name: "31073"
  },
  {
      value: "TAHMEK",
      name: "31074"
  },
  {
      value: "TEABO",
      name: "31075"
  },
  {
      value: "TECOH",
      name: "31076"
  },
  {
      value: "TEKAL DE VENEGAS",
      name: "31077"
  },
  {
      value: "TEKANTO",
      name: "31078"
  },
  {
      value: "TEKAX",
      name: "31079"
  },
  {
      value: "TEKIT",
      name: "31080"
  },
  {
      value: "TEKOM",
      name: "31081"
  },
  {
      value: "TELCHAC PUEBLO",
      name: "31082"
  },
  {
      value: "TELCHAC PUERTO",
      name: "31083"
  },
  {
      value: "TEMAX",
      name: "31084"
  },
  {
      value: "TEMOZON",
      name: "31085"
  },
  {
      value: "TEPAKAN",
      name: "31086"
  },
  {
      value: "TETIZ",
      name: "31087"
  },
  {
      value: "TEYA",
      name: "31088"
  },
  {
      value: "TICUL",
      name: "31089"
  },
  {
      value: "TIMUCUY",
      name: "31090"
  },
  {
      value: "TINUM",
      name: "31091"
  },
  {
      value: "TIXCACALCUPUL",
      name: "31092"
  },
  {
      value: "TIXKOKOB",
      name: "31093"
  },
  {
      value: "TIXMEHUAC",
      name: "31094"
  },
  {
      value: "TIXPEHUAL",
      name: "31095"
  },
  {
      value: "TIZIMIN",
      name: "31096"
  },
  {
      value: "TUNKAS",
      name: "31097"
  },
  {
      value: "TZUCACAB",
      name: "31098"
  },
  {
      value: "UAYMA",
      name: "31099"
  },
  {
      value: "UCU",
      name: "31100"
  },
  {
      value: "UMAN",
      name: "31101"
  },
  {
      value: "VALLADOLID",
      name: "31102"
  },
  {
      value: "XOCCHEL",
      name: "31103"
  },
  {
      value: "YAXCABA",
      name: "31104"
  },
  {
      value: "YAXKUKUL",
      name: "31105"
  },
  {
      value: "YOBAIN",
      name: "31106"
  },
  {
      value: "APOZOL",
      name: "32001"
  },
  {
      value: "APULCO",
      name: "32002"
  },
  {
      value: "ATOLINGA",
      name: "32003"
  },
  {
      value: "BENITO JUAREZ",
      name: "32004"
  },
  {
      value: "CALERA",
      name: "32005"
  },
  {
      value: "CAÑITAS DE FELIPE PESCADOR",
      name: "32006"
  },
  {
      value: "CONCEPCION DEL ORO",
      name: "32007"
  },
  {
      value: "CUAUHTEMOC",
      name: "32008"
  },
  {
      value: "CHALCHIHUITES",
      name: "32009"
  },
  {
      value: "FRESNILLO",
      name: "32010"
  },
  {
      value: "TRINIDAD GARCIA DE LA CADENA",
      name: "32011"
  },
  {
      value: "GENARO CODINA",
      name: "32012"
  },
  {
      value: "GENERAL ENRIQUE ESTRADA",
      name: "32013"
  },
  {
      value: "GENERAL FRANCISCO R. MURGUIA",
      name: "32014"
  },
  {
      value: "EL PLATEADO DE JOAQUIN AMARO",
      name: "32015"
  },
  {
      value: "GENERAL PANFILO NATERA",
      name: "32016"
  },
  {
      value: "GUADALUPE",
      name: "32017"
  },
  {
      value: "HUANUSCO",
      name: "32018"
  },
  {
      value: "JALPA",
      name: "32019"
  },
  {
      value: "JEREZ",
      name: "32020"
  },
  {
      value: "JIMENEZ DEL TEUL",
      name: "32021"
  },
  {
      value: "JUAN ALDAMA",
      name: "32022"
  },
  {
      value: "JUCHIPILA",
      name: "32023"
  },
  {
      value: "LORETO",
      name: "32024"
  },
  {
      value: "LUIS MOYA",
      name: "32025"
  },
  {
      value: "MAZAPIL",
      name: "32026"
  },
  {
      value: "MELCHOR OCAMPO",
      name: "32027"
  },
  {
      value: "MEZQUITAL DEL ORO",
      name: "32028"
  },
  {
      value: "MIGUEL AUZA",
      name: "32029"
  },
  {
      value: "MOMAX",
      name: "32030"
  },
  {
      value: "MONTE ESCOBEDO",
      name: "32031"
  },
  {
      value: "MORELOS",
      name: "32032"
  },
  {
      value: "MOYAHUA DE ESTRADA",
      name: "32033"
  },
  {
      value: "NOCHISTLAN DE MEJIA",
      name: "32034"
  },
  {
      value: "NORIA DE ANGELES",
      name: "32035"
  },
  {
      value: "OJOCALIENTE",
      name: "32036"
  },
  {
      value: "PANUCO",
      name: "32037"
  },
  {
      value: "PINOS",
      name: "32038"
  },
  {
      value: "RIO GRANDE",
      name: "32039"
  },
  {
      value: "SAIN ALTO",
      name: "32040"
  },
  {
      value: "EL SALVADOR",
      name: "32041"
  },
  {
      value: "SOMBRERETE",
      name: "32042"
  },
  {
      value: "SUSTICACAN",
      name: "32043"
  },
  {
      value: "TABASCO",
      name: "32044"
  },
  {
      value: "TEPECHITLAN",
      name: "32045"
  },
  {
      value: "TEPETONGO",
      name: "32046"
  },
  {
      value: "TEUL DE GONZALEZ ORTEGA",
      name: "32047"
  },
  {
      value: "TLALTENANGO DE SANCHEZ ROMAN",
      name: "32048"
  },
  {
      value: "VALPARAISO",
      name: "32049"
  },
  {
      value: "VETAGRANDE",
      name: "32050"
  },
  {
      value: "VILLA DE COS",
      name: "32051"
  },
  {
      value: "VILLA GARCIA",
      name: "32052"
  },
  {
      value: "VILLA GONZALEZ ORTEGA",
      name: "32053"
  },
  {
      value: "VILLA HIDALGO",
      name: "32054"
  },
  {
      value: "VILLANUEVA",
      name: "32055"
  },
  {
      value: "ZACATECAS",
      name: "32056"
  },
  {
      value: "TRANCOSO",
      name: "32057"
  },
  {
      value: "SANTA MARIA DE LA PAZ",
      name: "32058"
  }
];