
/** import Component, OnInit  */
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
/** import FormControl, FormGroup, Validator*/
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email/email.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ModalService } from 'src/app/services/modal/modal.service-v2';
import { ModalGeneralV2Component } from 'src/app/shared/modal-general-v2/modal-general-v2.component';

/**
 * Component TokenComponent
 */
@Component({
    selector: 'app-token',
    templateUrl: './token.component.html',
    styleUrls: ['./token.component.scss']
})
export class TokenComponent implements OnInit {


    /** Input de archivo */
    @ViewChild('fileInputDocReq') fileInputDocReq: ElementRef;
    @ViewChild('fileInputDocId') fileInputDocId: ElementRef;


    /** Formulario de tokenizacion*/
    tokenForm: UntypedFormGroup;
    fileDocReqForm: UntypedFormGroup;
    fileDocIdForm: UntypedFormGroup;

    /** Banderas de archivos subidos */
    fileDocReqFormBoolean = false;
    fileDocIdFormBoolean = false;
    errorReq: any;
    errorId: any;

    /** APP Environment */
    env: string = 'dev';

    /** constructor */
    constructor(
        private modalService: ModalService,
        private loader: LoaderService,
        private email: EmailService
    ) { }

    /** ngOnInit */
    ngOnInit() {
        this.email.getEnvironment().subscribe(res => {
			this.env = res.environment ? res.environment : 'dev'
        });
        this.fileDocReqForm = new UntypedFormGroup({
            fileInputDocReq: new UntypedFormControl(undefined),
            file_fileInputDocReq: new UntypedFormControl(undefined)
        });
        this.fileDocIdForm = new UntypedFormGroup({
            fileInputDocId: new UntypedFormControl(undefined),
            file_fileInputDocId: new UntypedFormControl(undefined)
        });
    }

    goTokenizer() {
        const modalReference = this.modalService.open(ModalGeneralV2Component);
        modalReference.componentInstance.modalTitle = 'Recuerda tener a la mano los datos de tu tarjeta';
        modalReference.componentInstance.modalMessage = 'Al dar clic en el botón "TOKENIZAR" entrará a un sitio seguro operado por MITEC que cuenta con los más altos estándares de seguridad y protección de la información.';
        modalReference.componentInstance.showCancelBtn = true;
        modalReference.componentInstance.btnText = 'Tokenizar';
        modalReference.componentInstance.modalIcon = 'axa-icon axa-TDC';
        modalReference.componentInstance.button.subscribe((res) => {
            console.log("res", res);
            if (res === true){
                let form = document.createElement("form");
                form.target = "view";
                form.method = "POST";
                let urlToRedirect = '';
                let keyAuth = '';
                if (this.env === 'PRO' || this.env === 'DEVELOPMENT'){
                    urlToRedirect = "https://www.serviciosaxa.mx/axaPgWeb/web/menu";
                    keyAuth = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbGciOiJSUzI1NiIsImlzcyI6ImF4YS1zZWN1cml0eSIsInN1YiI6Ik1YUzAwMTAxNjU0QSIsImV4cCI6MTc1MjEwMzkxNS40NjYsImlhdCI6MTU5NDQwMzkxNS40NjYsInNjcCI6ImF1dE1lZGljYXMifQ.uqYan4O6gTAbkgwhlpdiZooODGiVK-HM-BGKkvfExTajjE34c9VFPDFC2gHytjHYtwWf8tH4KprIbpy_1fTgxuJt8Urpg2pwASgRDwCcGAZzc-KiYtqGYHYS47crvgaTiKE4yX-DD_Bbji1m23FpyVzBWq2J6gLrJehN0wcCXqfhts6TAsPjxUvWJtzFbMiTdBdUTOx9QV6yA5sviPWbHd9kl4jOr57b_KNpgfVmU01Dbq7RO5sUk5qTocIZpvCelMHL9gabs_ZXL1RRPc6Zhw8Vk2yFztwoDZ09xTPBmAB6bGOWTUor5cmEFNe4gi2m-p8SmJTlkfq-h_j74pNaHA";
                } else {
                    urlToRedirect = "https://qamwa.mitec.com.mx/axaPgWeb/web/menu";
                    keyAuth = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbGciOiJSUzI1NiIsImlzcyI6ImF4YS1zZWN1cml0eSIsInN1YiI6Ik1YUzAwMTAxNjU0QSIsImV4cCI6MTgxMDY4MTk3Mi4xMDQsImlhdCI6MTY1MzAwMTk3Mi4xMDQsInNjcCI6ImF1dE1lZGljYXMifQ.tfN8OKowDVYXnz8KlkN26_iqJ-PylYf6KrvrpWOcQ1bD2UgIkvdX0fPFMIMGWpAMSmN_3WEgX2naxdcZgTemlC0zEmso-tGOYj3-LWW_KDfPTLcpAThrUMzmzSh4oHQT3AgqOLb00PMHwD67JZS19clXOxJd2uUI_sFIr3uq72nKjqs_a3i3aiNX5V6gxYQJ-j_MgKD1jGLTfRHJUap8dG8xMPI64R25ZFPqsoJEOIqxlhJNpjSHpWGDU4eFDnu3oAxLSD4L-oBF9vP5l5E7ixgCW2n3w3BsabsMQxQ-u2aym6QDItbnc7-38ksy-Jsw6awoKAd_6i3vLk7p0j_0RQ'
                }
                form.action = urlToRedirect


                let inputAuth = document.createElement('input');
                inputAuth.type = 'hidden';
                inputAuth.name = "Authorization";
                inputAuth.value = keyAuth;
                form.appendChild(inputAuth);


                let inputSubmit = document.createElement('input');
                inputSubmit.type = 'subbmit';
                inputSubmit.id = "mit";
                inputSubmit.value = "Tokenizador";
                form.appendChild(inputSubmit);

                document.body.appendChild(form);
                form.submit();
                window.open('', 'view');
      
            }
            modalReference.close();
        });

    }

    downloadFormat() {
        let link = document.createElement("a");
        link.download = "cargo_automatico_autorizacion.pdf";
        link.href = "assets/files/tkn/cargo_automatico_autorizacion.pdf";
        link.click();
    }

    /**
     * Abre el explorador de archivos
     */
    uploadDocReq() {
        console.log("se subira documento");
        this.fileInputDocReq.nativeElement.click();
    }

    /**
     * Abre el explorador de archivos
     */
    uploadDocId() {
        console.log("se subira documento");
        this.fileInputDocId.nativeElement.click();
    }

    sendInfo() {
        console.log("Se enviara informacion a correo this.fileDocReqForm.value", this.fileDocReqForm.value);
        console.log("Se enviara informacion a correo this.fileDocReqForm", this.fileDocReqForm.get('fileInputDocReq').value);

        console.log("Se enviara informacion a correo this.fileDocIdForm.value", this.fileDocIdForm.value);
        this.loader.star();
        let bodyEmail:any = {
            "idUser": this.env === 'PRO' ? 6 : 2,
            "tokenUser": this.env === 'PRO' ? '5cb96a4ac79da30ab4c5c023fe44464' : '1A6c9d7310f8cb96aXvsfake0pLa2c4',
            "idMessage" : " ",
            "idService" : "1",
            "idTemplate" : "193",
            "subject" : "AXA Keralty | Cambio de token",
            "sender" : "notificaciones@axakeralty.mx",
            "targets" : `${this.env === 'PRO' ? 'operacionsalud@axa.com.mx' : this.env === 'DEVELOPMENT' ? 'nodier.momox.consultor@axa.com.mx' : 'gisela.lopez.consultor@axa.com.mx'}`,
            "attachments": [
                {
                    file: this.fileDocReqForm.get('file_fileInputDocReq').value,
                    name: this.fileDocReqForm.get('fileInputDocReq').value
                },
                {
                    file: this.fileDocIdForm.get('file_fileInputDocId').value,
                    name: this.fileDocIdForm.get('fileInputDocId').value
                }
            ] 
          };    
        console.log("bodyEmail", bodyEmail);
        
        // this.email.sendEmail()
        this.email.sendEmail(bodyEmail).subscribe(res => {
            if (res){
                  if (res['soap:Envelope']['soap:Body'][0]['ns2:sendEmailUseTemplateIDResponse'][0]['return'][0]['statusCode'][0] == "0"){
                    this.loader.stop(); 
                    const modalReference = this.modalService.open(ModalGeneralV2Component);
                    modalReference.componentInstance.modalTitle = 'Tu solicitud se envió con éxito';
                    modalReference.componentInstance.modalMessage = 'La solicitud de cambio de token se encuentra en validación, en cuanto sea aceptada te informaremos al correo que proporcionaste en la solicitud. <br> <br> Si tienes alguna duda, escríbenos a: <br> hola@axakeralty.mx';
                    modalReference.componentInstance.familyIcon = 'axa-icon';
                    modalReference.componentInstance.modalIcon = 'axa-check';
                    modalReference.componentInstance.button.subscribe((res) => {
                        modalReference.close();
                    });
                   } else {
                    this.loader.stop();
                    this.modalService.errorModal('Hubo un error al enviar correo. Intente mas tarde');
                   }              
            } else {
              this.loader.stop();
              this.modalService.errorModal('Hubo un error al enviar correo. Intente mas tarde');
             }     
          }, err => {
            this.loader.stop()
            this.modalService.errorModal('Hubo un error al enviar correo. Intente mas tarde');
            
          });   


        // const modalReference = this.modalService.open(ModalGeneralV2Component);
        // modalReference.componentInstance.modalTitle = 'Tu solicitud se envió con éxito';
        // modalReference.componentInstance.modalMessage = 'En breve recibirás la confirmación en tu correo electrónico';
        // modalReference.componentInstance.familyIcon = 'axa-icon';
        // modalReference.componentInstance.modalIcon = 'axa-check';
        // modalReference.componentInstance.button.subscribe((res) => {
        //     modalReference.close();
        // });
    }

    /** Trigger de cambio en la selección de archivo para CV */
    selectedFileReq(event, nameInput: string) {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const control = nameInput;
            this.fileDocReqForm.controls[control].setValue(event.target.files[0].name);
            this.fileDocReqForm.controls[`file_${nameInput}`].setValue((<string>reader.result).split(',')[1]);
            this.fileDocReqFormBoolean = true;
            this.errorReq = false;
            console.log("this.fileDocReqFormBoolean", this.fileDocReqFormBoolean);
            
        }
    }

    /** Trigger de cambio en la selección de archivo para CV */
    selectedFileId(event, nameInput: string) {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            const control = nameInput;
            this.fileDocIdForm.controls[control].setValue(event.target.files[0].name);
            this.fileDocIdForm.controls[`file_${nameInput}`].setValue((<string>reader.result).split(',')[1]);
            this.fileDocIdFormBoolean = true;
            this.errorId = false;
            console.log("this.fileDocIdFormBoolean", this.fileDocIdFormBoolean);
            
        }
    }
    showError() {
        if (!this.fileDocReqFormBoolean) {
            this.errorReq = true;
        }
        if (!this.fileDocIdFormBoolean) {
            this.errorId = true;
        }
    }
}
