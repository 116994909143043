import { Injectable } from '@angular/core';
/** OPERADORES */
import { Subject, BehaviorSubject } from 'rxjs';
/**
 * Servicio de lodaer genérico
 *
 * @export
 * @class LoaderService
 */
@Injectable({ providedIn: 'root' })
export class LoaderService {
  /** Asignar valor para mostrar/ocultar cargador */
  /** Asignar valor para mostrar/ocultar cargador */
  message!: string;
  description!: string;
  private loader = true;
  /** Asignar estatus del cargador */
  status: Subject<boolean> = new Subject();
  planInfo = new BehaviorSubject<any>({});
  /**
   * Obtener el valor actual del cargador
   */
  get loading(): boolean {
    return this.loader;
  }

  /**
   * Asignar estatus al cargador -> true/false
   * @param {boolean} value
   */
  set loading(value: boolean) {
    this.loader = value;
    this.status.next(value);
  }

  /**
   * Función para mostrar el cargador
   */
  start() {
    this.loading = true;
  }

  /**
   * Función para cerrar el cargador
   */
  stop() {
    this.loading = false;
    this.message = '';
  }
}
