import { IEventGTM } from 'src/app/interfaces/gtmEvent.interface';
/** import Component, OnInit*/
import { Component, OnInit, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
/** import FormBuilder, FormGroup, FormControl, Validators*/
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
/** import defineLocale*/
import { defineLocale } from 'ngx-bootstrap/chronos';
/** import esLocale*/
import { esLocale } from 'ngx-bootstrap/locale';
/** import BsLocaleService*/
import {  BsLocaleService } from 'ngx-bootstrap/datepicker';
/** import PRIVACY_NOTICE_URL, TERMS_CONDITIONS_URL, MASK_PATTERNS, STATES*/
import { PRIVACY_NOTICE_URL, TERMS_CONDITIONS_URL, MASK_PATTERNS, STATES } from '../../constants/index';
/** import ModalService*/
import { ModalService } from 'src/app/services/modal/modal.service';
/** import LoaderService*/
import { LoaderService } from 'src/app/services/loader/loader.service';
/** import ContactService*/
import { ContactService } from 'src/app/services/contact-service/contact.service';
import { finalize, filter, map } from 'rxjs/operators';

/** Import captcha service */
import { RecaptchaService } from 'src/app/services/recaptcha/recaptcha.service';
import { isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
/**
 * ContactUsComponent
 */
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  /** Constante con listado de estados de la república */
  STATES = STATES;
  /** Constante con aviso de privacidad  */
  PRIVACY = PRIVACY_NOTICE_URL;
  /** Constante con términos y condiciones */
  TERMS = TERMS_CONDITIONS_URL;
  /** Contante con NAME_PATTERN*/
  NAME_PATTERN = MASK_PATTERNS.NAME_PATTERN;
  /** FormGroup: contactForm */
  contactForm: UntypedFormGroup;
  /** Validación nameBlur */
  nameBlur: boolean;
  /** Validación emailBlur*/
  emailBlur: boolean;
  dateToday = new Date();
  /** Validación lastNameBlur*/
  lastNameBlur: boolean;
  /** Validación lastNameSecondBlur*/
  lastNameSecondBlur: boolean;
  /** Validación phoneBlur*/
  phoneBlur: boolean;
  /** Validación dateBlur*/
  dateBlur: boolean;
  /**variable para saber si abrió un select*/
  selectedOption=false;
  /**variable para saber si abrió un select*/
  selectedOptionTwo=false;
  /** Variable para saber si se esta enviando el formulario */
  sendingForm: boolean = false;

  clientID: string
  /**
   *Creates an instance of ContactUsComponent.
   * @param {FormBuilder} fb
   * @param {ModalService} modalService
   * @param {BsLocaleService} localeService
   * @param {LoaderService} loaderService
   * @param {ContactService} contactService
   * @param {RecaptchaService} captchaService
   * @memberof ContactUsComponent
   */
  constructor(
    private fb: UntypedFormBuilder,
    private modalService: ModalService,
    private localeService: BsLocaleService,
    private loaderService: LoaderService,
    private contactService: ContactService,
    public captchaService: RecaptchaService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    defineLocale('es', esLocale);
    esLocale.invalidDate = 'fecha inválida';
    defineLocale('custom locale', esLocale);
    this.localeService.use('custom locale');
    if (isPlatformBrowser(this.platformId)) {
      this.captchaService.tokenGenerate();
    }
  }

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.localeService.use('es');
    this.localeService.use('custom locale');
    this.initForm();
    this.replaceScript();
  }

  /**
   * funcion inicio de formulario
   */
  initForm() {
    this.contactForm = this.fb.group({
      name: new UntypedFormControl('', { validators: [Validators.required, Validators.pattern("^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙñÑ.-]+")] }),
      lastName: new UntypedFormControl('', { validators: [Validators.required, Validators.pattern("^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙñÑ.'-]+")] }),
      lastNameSecond: new UntypedFormControl('', { validators: [Validators.required, Validators.pattern("^[ A-Za-zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙñÑ.'-]+")] }),
      email: new UntypedFormControl('', {
        validators: [Validators.required,  Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      }),
      phone: new UntypedFormControl('', {
        validators: [Validators.required,  Validators.pattern('^[0-9]*$'), Validators.maxLength(10),
          Validators.minLength(10)],
      }),
      birthday: new UntypedFormControl('', [Validators.required]),
      bornState: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      privacyNotice: [false, Validators.pattern('true')],
      termsConditions: [false, Validators.pattern('true')]
    });
  }

  /**
   * Función para enviar datos del formulario a servicio
   * @param contactData
   */
  sendContact(contactData: any){
    this.sendingForm = true;
    
    if (isPlatformBrowser(this.platformId)) {
    this.captchaService.tokenGenerate();
    let subs = this.captchaService.token.pipe(
      filter(validToken => validToken !== undefined),
      map((token: string) => token)
    ).subscribe(tokenResponse => {
        const eventGtm: IEventGTM = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'btnContact',
          eventLabel: 'btn_contact'
        }

        window.dataLayer.push(eventGtm);

        this.loaderService.star();
        const { name,lastName,
        lastNameSecond,email,
        phone,birthday,
        bornState,gender } = this.contactForm.value;

        const obj = {
          name,
          fatherSurname: lastName,
          motherSurname: lastNameSecond,
          birthday: new Date(birthday),
          gender: gender.toUpperCase(),
          role: 0,
          state: bornState,
          contact: [{ type: 'phone', value: phone }, { type: 'email', value: email }],
          origin: 'CONTACT',
          token: tokenResponse
        };
        
        if (tokenResponse){
          this.contactService.saveProspectInfo(obj).pipe(finalize(() => this.loaderService.stop()))
          .subscribe(response => {
            this.loaderService.stop();
            if(response){
              this.sendingForm = false;
              if (this.modalService.isHide()) { this.modalService.close() };
              this.modalService.successModal('¡Gracias!');
              subs.unsubscribe();
              this.initForm();
              this.captchaService.tokenGenerate();

            }else{
              this.sendingForm = false;
              if (this.modalService.isHide()) { this.modalService.close() };
              this.modalService.errorModal('Hubo un error al enviar los datos. Intente mas tarde');
            }
          }, e => {
            console.log('Error', e);
            this.sendingForm = false;
            this.loaderService.stop();
            if (this.modalService.isHide()) { this.modalService.close() };
            this.modalService.errorModal('Hubo un error al enviar los datos. Intente mas tarde');
  
          }); 
        } else {
          this.sendingForm = false;
          if (this.modalService.isHide()) { this.modalService.close() };
          this.modalService.errorModal('Hubo un error al enviar los datos. Intente mas tarde');
        }
    });
  }
  }
  /**
   * Función cambiar color en select al click
   *
   */
  clickEvent(){
    this.selectedOption=true;
  }
  /**
   * Función cambiar color en select al click
   *
   */
  clickEvents(){
    this.selectedOptionTwo=true;
  }

  sendTag(element: string) {
    let gtm: IEventGTM;

    switch(element) {
      case 'nombre':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'contact_Name',
          eventLabel: 'form_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'appat':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'contact_LastNameF',
          eventLabel: 'form_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'apmat':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'contact_LastNameM',
          eventLabel: 'form_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'fechaNac':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'contact_BirthDate',
          eventLabel: 'form_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'correo':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'contact_Email',
          eventLabel: 'form_contact'
        }

        window.dataLayer.push(gtm);
        break;

      default:
        this.tagsContinue(element);
        break;
    }

  }

  tagsContinue(section: string) {
    let gtm: IEventGTM;

    switch(section) {
      case 'tel':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'contact_Phone',
          eventLabel: 'form_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'estadoNac':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'contact_State',
          eventLabel: 'form_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'sexo':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'contact_Gender',
          eventLabel: 'form_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'chkPrivacidad':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'check_conditionsTerms',
          eventLabel: 'chk_contact_conditions'
        }

        window.dataLayer.push(gtm);
        break;

      case 'chkTerminos':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'check_privacy',
          eventLabel: 'chk_contact_privacy'
        }

        window.dataLayer.push(gtm);
        break;

      default:
        this.tagsFin(section);
        break;
    }

  }

  tagsFin(action: string) {
    let gtm: IEventGTM;

    switch(action) {
      case 'linkPrivacidad':
        gtm = {
          event: 'eventgtm', 
          eventCategory: 'contactLinks', 
          eventAction: 'goToPrivacy',
          eventLabel: 'link_conditionsTerms'
        }

        window.dataLayer.push(gtm);
        break;

      case 'linkTerminos':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactLinks',
          eventAction: 'goToConditionsTerms',
          eventLabel: 'link_conditionsTerms'
        }

        window.dataLayer.push(gtm);
        break;

      case 'mailContacto':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'contact_SendEmail',
          eventLabel: 'form_contact'
        }

        window.dataLayer.push(gtm);
        break;

      case 'llamadaContacto':
        gtm = {
          event: 'eventgtm',
          eventCategory: 'contactButtons',
          eventAction: 'contact_CallUs',
          eventLabel: 'form_contact'
        }

        window.dataLayer.push(gtm);
        break;
    }
  }

  replaceScript() {
    try{
      if(document.cookie.indexOf('_ga=')>-1){
          const gaCookie = document.cookie.split('_ga=')[1].split(';')[0].split('.');
          this.clientID = gaCookie[2] + '.' + gaCookie[3];
      }
    } catch(e){
      return 'Error al obtener el id de Google Analytics';
    }

    if (this.router.url === '/contact-us'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        login: 'no',
        clientId: `${this.clientID}`,
        pageType: 'home',
        pageCategory: `${this.router.url}`,
        pageURLCategory: `${window.location.href}`,
        device: `${this.getDevice()}`,
        numeroCotizacion: 'NA',
        folioPago: 'NA',
        numeroSolicitud: 'NA',
        numeroPoliza: 'NA'
      });
    }
  }

  getDevice() {
    if(this.deviceService.isDesktop()) {
       return 'desktop';
    } else if(this.deviceService.isMobile()) {
      return 'mobile';
    } else if(this.deviceService.isTablet()) {
      return 'tablet';
    } else {
      return 'other';
    }
  }

}
