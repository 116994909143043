import { Component, OnInit } from '@angular/core';
// INTERFACES
import { ICoveragesPlan } from 'src/app/interfaces';
// CONSTANTS
import { SERVICE_PLANS } from 'src/app/constants';
import { IEventGTM } from 'src/app/interfaces/gtmEvent.interface';

@Component({
  selector: 'app-infographic-planmed',
  templateUrl: './infographic-planmed.component.html',
  styleUrls: ['./infographic-planmed.component.scss']
})
export class InfographicPlanmedComponent implements OnInit {
  /** Variable para definir que plan selecciona */
  isPlanOptimo: boolean = true;
  /** Asignar tab de plan activo */
  planIdSelected: string = 'optimo';
  /** Planes con coberturas */
  PLANS: ICoveragesPlan[] = SERVICE_PLANS;
  
constructor() {}

  ngOnInit(): void {}

  redirectToCot(planId: string) {

    //Envío de tag a dataLayer
    let gtm: IEventGTM;
    console.log('id plan =>', planId);
    if(planId === 'optimo') {
      gtm = {
        event: 'eventgtm',
        eventCategory: 'homeButtons',
        eventAction: 'homePage_GoCotizamed',
        eventLabel: 'btnMiddle_homePage_LoQuieroOptimo'
      }

      window.dataLayer.push(gtm);
    } else {
      gtm = {
        event: 'eventgtm',
        eventCategory: 'homeButtons',
        eventAction: 'homePage_GoCotizamed',
        eventLabel: 'btnMiddle_homePage_LoQuieroOptimoMix'
      }

      window.dataLayer.push(gtm);
    }


    window.open(`${window.location.protocol}//${window.location.host}/cotizador-planmed/`);

    
  }
  
  /**
   * Identificar plan seleccionado
   * @param select 
   * @param planIdSelected 
   */
  plamedSelect(select: boolean, planId: string) {
    this.isPlanOptimo = select;
    this.planIdSelected = planId;

    //Envío de tag a dataLayer
    let gtm: IEventGTM;
    if(planId === 'optimo') {
      gtm = {
        event: 'eventgtm',
        eventCategory: 'homeButtons',
        eventAction: 'homePage_GoCotizamed',
        eventLabel: 'btnTop_homePage_PlanmedOptimo'
      }

      window.dataLayer.push(gtm);
    } else {
      gtm = {
        event: 'eventgtm',
        eventCategory: 'homeButtons',
        eventAction: 'homePage_GoCotizamed',
        eventLabel: 'btnTop_homePage_PlanmedOptimoMix'
      }

      window.dataLayer.push(gtm);
    }
  }
}
