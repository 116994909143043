/** Angular Core */
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ModalGeneralV2Component } from 'src/app/shared/modal-general-v2/modal-general-v2.component';
/** INTERFACES */
import { IModalOptions } from '../../interfaces';

/**
 * Servicio de modal genérico
 *
 * @export
 * @class ModalService
 */
@Injectable({ providedIn: 'root' })
export class ModalService {
  /** Toma de referencia el modal activo */
  reference: NgbModalRef;
  closeBehavior = new BehaviorSubject<any>(null);

  /**
   * Crea una instancia de ModalService.
   * @param {NgbModal} modal
   * @memberof ModalService
   */
  constructor(private modal: NgbModal) {}

  /**
   * Lanzar modal con template de componente
   * @param {object} component
   * @param options
   */
  open(component: object, options: IModalOptions = {}): NgbModalRef {
    // Centrar modal en vista
    options.centered = true;
    this.reference = this.modal.open(component, options);
    return this.reference;
  }

  /**
   * Mostrar modal genérico para errores
   * @param {string} message
   * @param {string} [title]
   * @returns
   * @memberof ModalService
   */
  errorModal(message: string, title?: string, iconProperty = { icon: 'keralty-car_1', family: 'keralty-icon'}) {
    this.reference = this.modal.open(ModalGeneralV2Component, {
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
    this.reference.componentInstance.familyIcon = iconProperty.family;
    this.reference.componentInstance.modalIcon = iconProperty.icon;
    this.reference.componentInstance.modalTitle = title || '¡Ups! Algo ha salido mal';
    this.reference.componentInstance.modalMessage = message;
    this.reference.componentInstance.button.subscribe(() => {
      this.closeBehavior.next(true);
      this.reference.close()
    });
    return this.reference;
  }

  successModal(message: string) {
    this.reference = this.modal.open(
      ModalGeneralV2Component, {centered: true, keyboard: false, backdrop: 'static'});
    this.reference.componentInstance.familyIcon = 'keralty-icon';
    this.reference.componentInstance.modalIcon = 'keralty-Thumbs';
    this.reference.componentInstance.modalTitle = 'Éxito';
    this.reference.componentInstance.modalMessage = message;
    this.reference.componentInstance.button.subscribe(() => {
      this.closeBehavior.next(true);
      this.reference.close();
    });
    return this.reference;
  }

  /**
   * Cerrar modal dentro del componente activo
   */
  close() {
    this.reference.close();
    this.reference = undefined;
  }
}
