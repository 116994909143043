<div class="background pb-5 pt-3">
    <div class="container pay-policy-success-wrapper">
        <div class="row">
            <div class="col-12 col-md-5 image-wrapper">
                <img src="assets/searchpolicy/Exito.png" alt="">
            </div>
            <div class="col-12 col-md-7 component-wrapper pt-4">
                <h1 class="title">¡Felicidades!</h1>
                <p class="success-message mt-5">El pago de la póliza <b>{{policy}}</b> se realizó con éxito. Recuerda que puedes descargarla desde tu portal.</p>
                <p class="folio-text mt-4"><img src="assets/searchpolicy/validar_2.svg" alt=""> <b>Folio de pago</b>: {{folio}}</p>
                <p class="mt-5 button-wrapper">
                    <button type="button" class="btn" (click)="returnToHome($event)">Finalizar</button>
                </p>
                <p class="contact-info mt-5 mb-5">Si tienes alguna duda, comunícate con nosotros al <b><a href="tel:(55) 5169 3080">(55) 5169 3080</a></b> o escríbenos a <a href="mailto:hola@axakeralty.mx"><b>hola@axakeralty.mx</b></a> donde con gusto te apoyaremos.</p>
            </div>
        </div>
    </div>
</div>