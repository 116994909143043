import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, } from 'rxjs';

@Injectable()
export class HttpEndpointInterceptor implements HttpInterceptor {

    prefix = 'ak-connect';
    regAK = /\/ak-connect/;

    services = 'https://ak-njs-001-ker-dig-preprod-axa-seg-mx.axa-seg-mx-preprod-hpl-int.gold.eastus2.azure.openpaas.axa-cloud.com';

    constructor() { }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let { url } = req;

        if ((url as string).includes(this.prefix)) {
            // /ak-connect  =>  url de servicios
            return next.handle( req.clone({ url: url.replace(this.regAK, this.services) }));
        }

        return next.handle(req);
    }
}

export const httpEnpointProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpEndpointInterceptor,
    multi: true
};
