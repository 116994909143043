/**
 * Constantes utilizadas para configuración de sliders en cuestionario de edad metabólica
 */

export const SLIDER_OPTIONS = {
  /** SLIDER DE PROGRESO */
  PROGRESS: {
    floor: 1,
    ceil: 9,
    hideLimitLabels: true,
    hidePointerLabels: true,
    readOnly: true,
    showSelectionBar: true
  },
  /** SLIDER VERTICAL DE ALTURA */
  HEIGHT: {
    floor: 100,
    ceil: 200,
    hideLimitLabels: true,
    showSelectionBar: true,
    vertical: true,
    translate: (value: number): string => {
      return value + 'cm';
    }
  },
  /** SLIDER DE CIGARROS FUMADOS AL DIA */
  CIGARETTES: { floor: 0, ceil: 20, showTicks: false, hideLimitLabels: false, showSelectionBar: true },
  /** SLIDER DE EJERCICIO AL DÍA */
  EXERCISE: {
    floor: 0,
    ceil: 5,
    hideLimitLabels: true,
    showSelectionBar: true,
    ticksArray: [0, 5],
    translate: (value: number): string => {
      switch (true) {
        case value === 0:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Hombre" src="assets/images/test_health/man.png" class="answer__icon_man"/>
                </div>`;
        case value < 2:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Hombre" src="assets/images/test_health/man.png" class="answer__icon_man"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        case value < 5:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Corriendo" src="assets/images/test_health/man_running.png"
                  class="answer__icon_man running"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        default:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Corriendo" src="assets/images/test_health/man_running.png"
                  class="answer__icon_man running"/>
                </div>`;
      }
    },
    getLegend: (value: number): string => {
      return `<em>${value}</em>`;
    }
  },
  /** SLIDER DE ESTRÉS SOMETIDO */
  STRESS: {
    floor: 0,
    ceil: 10,
    hideLimitLabels: true,
    showSelectionBar: true,
    ticksArray: [0, 10],
    translate: (value: number): string => {
      switch (true) {
        case value === 0:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres1" src="assets/images/test_health/grade_1.png"
                  class="answer__icon_borderless level1"/>
                </div>`;
        case value < 2:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres1" src="assets/images/test_health/grade_1.png"
                  class="answer__icon_borderless level1"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        case value === 2:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres2" src="assets/images/test_health/grade_2.png"
                  class="answer__icon_borderless"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        case value === 3:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres3" src="assets/images/test_health/grade_3.png"
                  class="answer__icon_borderless level3"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        case value === 4:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres4" src="assets/images/test_health/grade_4.png"
                  class="answer__icon_borderless level3"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        default:
          return SLIDER_OPTIONS.STRESS.extendedTranslate(value);
      }
    },
    extendedTranslate: (value: number): string => {
      switch (true) {
        case value === 5:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres5" src="assets/images/test_health/grade_5.png"
                  class="answer__icon_borderless level5"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        case value === 6:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres6" src="assets/images/test_health/grade_6.png"
                  class="answer__icon_borderless level6"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        case value === 7:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres7" src="assets/images/test_health/grade_7.png"
                  class="answer__icon_borderless level7"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        case value === 8:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres8" src="assets/images/test_health/grade_8.png"
                  class="answer__icon_borderless level1"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        case value === 9:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres9" src="assets/images/test_health/grade_9.png"
                  class="answer__icon_borderless level1"/>
                  <span class="custom-bubble_value">${value}</span>
                </div>`;
        default:
          return `<div class="custom-bubble_wrapper">
                  <img alt="Estres10" src="assets/images/test_health/grade_10.png"
                  class="answer__icon_borderless level1"/>
                </div>`;
      }
    },
    getLegend: (value: number): string => {
      if (value === 0) {
        return `<p class="mb-0"><em>${value}</em></p><p>Nada</p>`;
      } else if (value === 10) {
        return `<p class="mb-0"><em>${value}</em></p><p>Mucho</p>`;
      } else {
        return `<p><em>${value}</em></p>`;
      }
    }
  }
};

/** Objeto para rutas de los iconos mostrados en preguntas y respuesta */
export const ICONS_PATH = {
  DATE: 'assets/images/test_health/date.png',
  GENDER: {
    MALE: {
      SELECTED: 'assets/images/test_health/gender_man_white.png',
      UNSELECTED: 'assets/images/test_health/gender_man.png'
    },
    FEMALE: {
      SELECTED: 'assets/images/test_health/gender_woman_white.png',
      UNSELECTED: 'assets/images/test_health/gender_woman.png'
    }
  },
  HEIGHT: 'assets/images/test_health/man_height.png',
  WEIGHT: 'assets/images/test_health/scale.png',
  CIGARETTES: {
    SINGLE: 'assets/images/test_health/cigarette.png',
    PACK: 'assets/images/test_health/cigarettes_pack.png'
  },
  VEGETABLES: {
    YES: { SELECTED: 'assets/images/test_health/fruit_white.png', UNSELECTED: 'assets/images/test_health/fruit.png' },
    NO: {
      SELECTED: 'assets/images/test_health/fruit_cross_white.png',
      UNSELECTED: 'assets/images/test_health/fruit_cross.png'
    }
  },
  FAMILY: {
    MOTHER: {
      SELECTED: 'assets/images/test_health/mother_white.png',
      UNSELECTED: 'assets/images/test_health/mother.png'
    },
    FATHER: {
      SELECTED: 'assets/images/test_health/father_white.png',
      UNSELECTED: 'assets/images/test_health/father.png'
    },
    SON: { SELECTED: 'assets/images/test_health/son_white.png', UNSELECTED: 'assets/images/test_health/son.png' },
    DAUGHTER: {
      SELECTED: 'assets/images/test_health/daughter_white.png',
      UNSELECTED: 'assets/images/test_health/daughter.png'
    },
    NONE: { SELECTED: 'assets/images/test_health/cross_white.png', UNSELECTED: 'assets/images/test_health/cross.png' }
  },
  RESULTS: {
    CHILD: 'assets/images/test_health/age_01.png',
    YOUNG: 'assets/images/test_health/age_02.png',
    ADULT: 'assets/images/test_health/age_03.png',
    OLD: 'assets/images/test_health/age_04.png'
  }
};

/** Constante para validación de rango de PESO aceptado en  */
export const WEIGHT_RANGE = {
  MIN: 40,
  MAX: 200
};

/** Objeto con arreglo para rangos de edades y sus íconos correspondientes */
export const AGE_RANGES = {
  VALUES: [0, 20, 30, 40],
  ICONS: [ICONS_PATH.RESULTS.CHILD, ICONS_PATH.RESULTS.YOUNG, ICONS_PATH.RESULTS.ADULT, ICONS_PATH.RESULTS.OLD]
};
