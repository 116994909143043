/**
 * Template: Info del paso 2
 */
const INFO_STEP02 = `Acude a tu <a href="/#/faq/3/2" class="paragraph-link"> check up inicial. </a>`;
/**
 * Ilustración - línea.
 */
const ILLUSTRATION_LINE = '/assets/images/illustration-line_1.png';
/**
 * Constantes para iconos del componente "Cómo funciona"
 */
export const WORKS = {
  /**
   * Pasos del servicio
   */
  STEPS: [
    {
      info: 'Cotiza tu plan',
      content: [
        {img: '/assets/images/illustration-paper_2.png', class: 'animation-paper'},
        {img: '/assets/images/illustration-clip.png', class: 'animation-clip'},
        {img: '/assets/images/illustration-cross.png', class: 'animation-cross'},
        {img: ILLUSTRATION_LINE, class: 'animation-line_1'},
        {img: ILLUSTRATION_LINE, class: 'animation-line_2'},
        {img: ILLUSTRATION_LINE, class: 'animation-line_3'},
      ],
    },
    {
      info: INFO_STEP02,
      content: [
        {img: '/assets/images/illustration-stethoscope.png', class: 'animation-stethoscope'},
        {img: '/assets/images/illustration-outlined_heart.png', class: 'animation-beat'}
      ],
    },
    {
      info: 'Acude a tus consultas de seguimiento',
      content: [
        {img: '/assets/images/illustration-heart_2.png', class: 'animation-heart_1'},
        {img: '/assets/images/illustration-heart_2.png', class: 'animation-heart_2'}
      ],
    },
    {
      info: 'Disfruta de todos los beneficios de Planmed®',
      content: [
        {img: '/assets/images/illustration-nurse_head.png', class: 'animation-nurse_head'},
        {img: '/assets/images/illustration-nurse_body.png', class: 'animation-nurse_body'}
      ],
    }
  ]
};
