import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

/** SERVICIOS */
import { LoaderService } from 'src/app/sharedv2/loader/loader.service';

/**
 * Componente "Loader"
 *
 * @export
 * @class LoaderComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ak-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  /** Mostrar/Ocultar el loader */
  loader = false;
  /** Suscripción para obtener el estatus del cargador */
  /** Suscripción para obtener el estatus del cargador */
  loaderSubs: Subscription = new Subscription;
  message: string | undefined;
  description: string | undefined;

  /**
   * Crea una instancia de LoaderComponent
   */
  constructor(private loaderService: LoaderService) {
    this.message = 'Ya estamos terminando...';
  }

  /**
   * Un hook de ciclo de vida que se llama después de que Angular haya
   * inicializado todas las propiedades vinculadas a datos de una directiva
   *
   * @memberof MedPassComponent
   */
  ngOnInit() {
    this.statusLoader();
    console.log('enttrro a loader');
    
  }

  /**
   * Obtener el valor asignado al cargador para mostrar/ocultar
   */
  statusLoader() {
    this.loaderSubs = this.loaderService.status.subscribe((status: any) => {
      this.message = this.loaderService.message;
      this.description = this.loaderService.description;
      this.loader = status;
    });
  }

  /**
   * Destruir suscripción o valores asignados
   */
  ngOnDestroy() {
    this.loaderSubs.unsubscribe();
    this.loaderService.message = '';
    this.loaderService.description = '';
  }
}
